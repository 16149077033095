import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";

//css
import "../../css/Common.css";
import "../../css/Board.css";
import "../../css/Head.css";
import "../../css/Company.css";
import "../../css/CompanyMy.css";
import "../../css/Main.css";

//components
/* import HeaderHeadHunterC from '../../components/Layout/HeaderHeadHunterC'; */
import HeaderCompany from "../../components/Layout/HeaderCompany";
import Footer from "../../components/Layout/Footer";
import CompanyName from "../../components/MainCompany/Name";
import UseVoucher from "../../components/MainCompany/UseVoucher";
import Announcement from "../../components/MainCompany/Announcement";
import Recommend from "../../components/MainCompany/Recommend";
import NewApply from "../../components/MainCompany/NewApply";
import Shortlist from "../../components/MainCompany/Shortlist";
// import SearchResources from "../../components/MainCompany/SearchResources";
import NewResources from "../../components/MainCompany/NewResources";
import BannerBottom from "components/Banner/BannerBottom";

/*마이페이지>정보관리(개인)*/
function MainCompany() {
  /* const head_hunter_type = Number(localStorage.getItem("mem_hunter_type")); */
  //console.log(head_hunter_type)
  const navigator = useNavigate();
  //로그인 전이면
  if (localStorage.getItem("memlogin") === null) {
    localStorage.setItem("memlogin", false);
    localStorage.setItem("mem_idx", 0);
    localStorage.setItem("mcm_idx", 0);
    localStorage.setItem("mem_sub", 0);
  }

  const [varCompanyInfo, setCompanyInfo] = useState({});
  const [varCompanyVoucher, setCompanyVoucher] = useState({
    mcc_sending: 0,
    mcc_edate: 0,
  });


  const [varSearchValues, setSearchValues] = useState({
    searchString: "",
    vwc_idx: 0,
    vwd_idx: 0,
    vwc_name: "",
    vwd_name: "",
    vr_code: 0,
    vr_name: "",
    startCareer: -1,
    endCareer: -1,
    careerChkbox: [0,0], //0은 신입, 1은 경력무관
    positions: [0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    /* user_idx, mcm_idx, mem_sub 를 useEffect 넣지 안고 돌리면 무한 루프 발생  */
    const mcm_idx = Number(localStorage.getItem("mcm_idx"));
    if (mcm_idx === 0) {
      navigator("/Login/Company");
    } else {
      getCompanyInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //회사정보 읽기
  const getCompanyInformation = async () => {
    try {
      const mcm_idx = localStorage.getItem("mcm_idx");
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_company_information`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            const datas = res.data.results[0];
            console.log(datas);
            setCompanyInfo(datas);
          } else {
            setCompanyInfo({});
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="company_main_body">
      <HeaderCompany
        varSearchValues={varSearchValues}
        setSearchValues={setSearchValues}
      />
      <div className="MainCompany">
        <div className="page_company_main">
          <section className="subtop_info_area">
            <ul className="center_wrap company">
              <CompanyName
                varCompanyInfo={varCompanyInfo}
                setCompanyInfo={setCompanyInfo}
              />
              {/*회사이름/로고*/}
              <UseVoucher
                varCompanyVoucher={varCompanyVoucher}
                setCompanyVoucher={setCompanyVoucher}
              />
              {/*사용중인 이용권*/}
            </ul>
          </section>
          <div className="center_wrap main_center">
            <section className="container1">
              <Announcement />
              {/*진행중 공고*/}
              <Recommend />
              {/*추천인재*/}
            </section>
            <section className="container2">
              <NewApply />
              {/*새로운 지원자*/}
              <Shortlist />
              {/*후보자 관리*/}
            </section>
            {/*인재를 직접 찾아보세요*/}
            {/* <SearchResources/> */}
            <section className="container3">
              <NewResources />
              {/*새로등록한 인재*/}
            </section>
          </div>
        </div>
      </div>
      <BannerBottom />
      <Footer />
    </div>
  );
}

export default MainCompany;
