import React from 'react';
import { Link } from 'react-router-dom';


function Tabbar({tabType}){
    const type = Number(localStorage.getItem('type'));
    // console.log('서브!!!!!!');
    // console.log(localStorage.getItem('preferences')); 
    // console.log(localStorage.getItem('member_management')); 
    // console.log(localStorage.getItem('notice_management')); 
    // console.log(localStorage.getItem('board_management')); 
    // console.log(localStorage.getItem('pass_management')); 
    // console.log(localStorage.getItem('search_management')); 
    // console.log(localStorage.getItem('statistics')); 

    const preferences=  Number(localStorage.getItem('preferences'));
    const member_management= Number(localStorage.getItem('member_management'));
    const notice_management= Number(localStorage.getItem('notice_management'));
    const board_management= Number(localStorage.getItem('board_management'));
    const pass_management= Number(localStorage.getItem('pass_management'));
    const search_management= Number(localStorage.getItem('search_management'));
    const statistics= Number(localStorage.getItem('statistics'));
    return(
        <div className="top-menu">
            {type === 0 ?
            <>
            <Link to="/admin/dashboard" className={tabType==="dashboard"?"selected":""}>Dash board</Link>
            <Link to={`/admin/${localStorage.getItem('id')}`} className={tabType==="pref"?"selected":""}>환경설정</Link>
            <Link to="/admin/userlist" className={tabType==="users"?"selected":""}>회원관리</Link>
            <Link to="/admin/announcement_list" className={tabType==="announcement"?"selected":""}>공고관리</Link>
            <Link to="/admin/boardlist" className={tabType==="board"?"selected":""}>게시판 관리</Link>
            <Link to="/admin/paymentlist" className={tabType==="using_pass"?"selected":""}>이용권 구매관리</Link>
            <Link to="/admin/employvariables" className={tabType==="variables"?"selected":""}>선택항목관리</Link>
            
            </>
            :
            <>
            {preferences === 1 ?<Link to={`/admin/${localStorage.getItem('id')}`} className={tabType==="pref"?"selected":""}>환경설정</Link> : '' }
            {member_management === 1 ? <Link to="/admin/userlist" className={tabType==="users"?"selected":""}>회원관리</Link> : '' }
            {notice_management === 1 ?  <Link to="/admin/announcement_list" className={tabType==="announcement"?"selected":""}>공고관리</Link> : '' }
            {board_management === 1 ? <Link to="/admin/boardlist" className={tabType==="board"?"selected":""}>게시판 관리</Link> : '' }
            {pass_management === 1 ? <Link to="/admin/paymentlist" className={tabType==="using_pass"?"selected":""}>이용권 구매관리</Link> : '' }
            {search_management === 1 ? <Link to="/admin/employvariables" className={tabType==="variables"?"selected":""}>선택항목 관리</Link> : '' }
            {statistics === 1 ? '' : '' }
            
            </>
            }
            
        </div>
    )
}

export default Tabbar;



