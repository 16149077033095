/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const CertificationCareer = ({highLookup}) => {

  return (
              // <p className="info_field career">
              <>
                {highLookup?.certification_experience_year ? `경력 ${highLookup?.certification_experience_year}년 `: ''}
                {highLookup?.certification_experience_date ? `${!highLookup?.certification_experience_year?'경력 ':''}${highLookup?.certification_experience_date} 개월`: null}
              </>
              // </p>
  );
};

export default CertificationCareer;
