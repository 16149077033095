import React, { useState } from "react";
import ChatList from './ChatList';
import ChatRoom from './ChatRoom';

function Message({roomIdx}) {

  //채팅 내역을 불러오기 위한 데이터를 담고있는 state
  const [send_mem_info, set_Send_Mem_Info] = useState([]);
    
  return (
     <div className="box">
        {/*채팅방목록*/}
         <ChatList set_Send_Mem_Info={set_Send_Mem_Info} param_idx={roomIdx}/> 
          {/*채팅방*/}
         <ChatRoom roomIdx={roomIdx} send_mem_info={send_mem_info}/>
    </div>
  );
}

export default Message;
