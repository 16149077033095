import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CONFIG from '../../configs/configs';
import axios from "axios";
//2023-02-17 ColleRepuR -> ColleRepuR2 KSR
//import ColleRepuR from 'components/Career/ColleRepuR'
import ColleRepuR2 from 'components/Career/ColleRepuR2'
import PopColleRepuLogin from "components/Career/PopColleRepuLogin";
import PopColleRepuOK from 'components/Career/PopColleRepuOK'
import PopColleRepuFail from 'components/Career/PopColleRepuFail'

const CareerReputation = () => {
	const memIdx = localStorage.getItem('mem_idx');
	const [matchPerson, setMatchPerson] = useState(false); // 동일한 직장동료 팝업
	const [unmatchPerson, setUnmatchPerson] = useState(false); //  동일하지 않은 직장동료 팝업
	const { repuCode } = useParams();
	const userName = useRef('');
	const userCellphone = useRef('');
	const navigator = useNavigate();

	// 동료평판- 건강보험 인증경력 있는 사람만 이용 가능
	useEffect(() => {
		const getAuthenticationHistory = async () => {
			const params = new URLSearchParams();
			params.append('mem_idx', memIdx)
			await axios.get(`${CONFIG.SERVER_HOST}/career-reputation/authentication-history`, { params }).then((res) => {
				if (res.data?.cnt === 0) {
					alert('이력서 인증경력을 업데이트해주세요.');
					navigator('../../MyResume');
				}
			}).catch((e) => {
				console.debug('Exception  ', e)
			})
		};

		if (memIdx > 0) {
			getAuthenticationHistory();
		}
	}, [memIdx, navigator]);

	// 알림톡으로 동료평판 페이지 이동한 경우
	useEffect(() => {
		// 평판대상자와 평판한 사람의 직장 일치 여부 체크
		const isColleague = () => {
			const params = new URLSearchParams();
			params.append('my_idx', memIdx);
			params.append('colleague_repuCode', repuCode);
			axios(`${CONFIG.SERVER_HOST}/career-reputation/same-career`, { params })
				.then((res) => {
					if (res.data.retvalue) {
						const count = res.data.cnt;
						if (count) {
							userName.current = res.data.mem_name;
							userCellphone.current = res.data.mem_mobile;
							setMatchPerson(prev => prev = true);
						} else {
							setUnmatchPerson(prev => prev = true);
						}
					}
				})
				.catch((e) => console.debug('Exception  ', e))
		}

		// 로그인 후 re-direct로 페이지 이동한 경우
		if (repuCode && (memIdx > 0)) {
			isColleague();
			// localStorage.setItem('repu_code', repuCode);
		}

	}, [memIdx, repuCode])

	return (
		<div className="container">
			{memIdx > 0 ? <ColleRepuR2 /> : <PopColleRepuLogin />}
			{matchPerson && <PopColleRepuOK toMemberName={userName.current} toMemberMobile={userCellphone.current} repuCode={repuCode} />}
			{unmatchPerson && <PopColleRepuFail />}
		</div>
	)
}

export default CareerReputation