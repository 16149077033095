import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//컴포넌트
import HedingBookmarkTab from 'components/Company/HedingBookmarkTab';
import MyBookmarkList from 'components/My/MyBookmarkList';
import { resetNavSubMenuForHeding } from "components/Common/Common";

function MyHeding11({ getSubItem }) {
  const mem_idx = localStorage.getItem("mem_idx");
  const location = useLocation();
  const [varTabs,setTabs] = useState(0);
  const [varEmployList, setEmployList] = useState([]);

  const uNavigate = useNavigate();
  const uri_path = window.location.pathname;
  useEffect(() => {
    if (!mem_idx || Number(mem_idx)===0) {
      localStorage.setItem("re_url", uri_path);
      alert("로그인 후 이용 가능합니다.");
      uNavigate("/Login");
    }
  },[mem_idx, uri_path, uNavigate]);
  
  useEffect(() => {
    //console.log(varTabs);
    setEmployList([]);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[varTabs]);

  useEffect(() => {
    console.log(location);
    if(location !== null && location.state !== null && location.state.pmode !== null) {
      const pmode = location.state.pmode;
      setTabs(pmode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  useEffect(() => {
    getSubItem(11);
    resetNavSubMenuForHeding(11);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(varTabs);

  return (
    <div className="container page_bookmark">
        <HedingBookmarkTab varTabs={varTabs} setTabs={setTabs}/>
        <MyBookmarkList varTabs={varTabs} varEmployList={varEmployList} setEmployList={setEmployList}/>
    </div>
  );
}

export default MyHeding11;