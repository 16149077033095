//css
//import '../../css/board/List.css';
//components
//이미지
import resumeSearchButton from '../../img/sub/icon_tip2.png'

/*이용권구매>유의사항 안내*/
function ResumeInfo({ varUserName }) {
  return (
      <div className="myresume_tip">
        <h3 className="title_area"><img src={resumeSearchButton} alt=""/>이력서 등록 장점</h3>
        <ul className="list">
          <li>헤드헌터가 이력서를 확인해서, 나의 조건에 잘 맞는 회사를 
  추천해줍니다.</li>
          <li>기업에서 이력서를 확인 후, 입사 권유를 받을 수 있습니다.</li>
          <li>근무 중인 기업 담당자는 <span className="point">{varUserName}</span>&nbsp;회원님의 이력서를 볼 수 없습니다.</li>
        </ul>
      </div>
  );
}

export default ResumeInfo;
