import React from 'react'
import 'react-quill/dist/quill.snow.css';

function TalkInfo({ title, comment, writer, rdate, hits, recommends, handleRecommend, varTcount }) {
  return (
    <section className="view">
      <article className="top">
        <div className="field_title">{title}</div>
        <div className="field_info">
          <p>작성자 : {writer}</p>
          <p>등록일 : {rdate}</p>
          <p>조회수 : {hits}</p>
          <p>추천수 : {recommends}</p>
        </div>
      </article>
      <article className="content" style={{whiteSpace: "pre-wrap" }}>
        <div className="ql-editor" style={{ padding: 0 }} dangerouslySetInnerHTML={{ __html: comment} } />
        <div className="btn_recommend_area"> {/* active 클래스로 on/off */}
          <button type="button" className={`btn_recommend ${varTcount > 0 ? `active`:`` }`} onClick={handleRecommend}>추천하기<i className="icon"></i></button>
        </div>
      </article>
    </section>
  )
}

export default TalkInfo
