import React, { useEffect} from "react";

//css
import "css/Board.css";

//컴포넌트
import ComNoticeList from 'components/Board/ComNoticeList';
import { resetNavSubMenuForHeding } from "components/Common/Common";

function MyHeding13({ getSubItem }) {

  useEffect(() => {
    getSubItem(13);
    resetNavSubMenuForHeding(13);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <ComNoticeList />
    </div>
  );
}

export default MyHeding13;