import React from "react";
import AcqRepuC from 'components/Career/AcqRepuC'	//하단 블라인드, 선행조건 알림
import AcqRepuR from 'components/Career/AcqRepuR'

const AcquaintanceReputation = () => {
	const repuMyYN = localStorage.getItem('repuMy_YN'); //내가 보는 나 작성 여부

	return (
		<div className="container">
			{repuMyYN === 'Y' ? <AcqRepuR /> : <AcqRepuC />}
		</div>
	);
}

export default AcquaintanceReputation
