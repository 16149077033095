import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function CompanyMenu({ varSubItems, getSubItem }) {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const mem_sub = Number(localStorage.getItem("mem_sub"));

  //const [varSubMember,setSubMember] = useState(false);
  let varSubMember = false;
  if (mem_idx !== mem_sub) {
    varSubMember = true;
    //setSubMember(true);
  }

  let menus = [];

  //헤드헌터의 경우
  //if (mem_hunter_type === 1) {
  if (mem_mode === 2) {
    if(mem_idx === mem_sub) {//헤드헌터 > 메인
      menus = [
        { id: 1, menu: '정보 관리', active: false },
        { id: 2, menu: '서브 계정 관리', active: false },
        // { id: 10, menu: '추천 현황', active: false },
        { id: 3, menu: '제안 현황', active: false },
        { id: 5, menu: '이용권 관리', active: false },
        // { id: 11, menu: '관심 공고 관리', active: false },
        { id: 12, menu: '이직/구인톡 관리', active: false },
        { id: 6, menu: '헤딩 머니', active: false },
        { id: 7, menu: '알림내역', active: false },
        { id: 9, menu: 'HR 담당자 소개', active: false },
      ];
    }else{//--------------------헤드헌터 > 서브
      menus = [
        { id: 1, menu: '정보 관리', active: false },
        // { id: 10, menu: '추천 현황', active: false },
        { id: 3, menu: '제안 현황', active: false },
        { id: 5, menu: '이용권 관리', active: false },
        // { id: 11, menu: '관심 공고 관리', active: false },
        { id: 12, menu: '이직/구인톡 관리', active: false },
        { id: 6, menu: '헤딩 머니', active: false },
        { id: 7, menu: '알림내역', active: false },
        { id: 9, menu: 'HR 담당자 소개', active: false },
      ];
    }
  }else{//----- 기업회원 --------------------------------------------
    if(mem_idx === mem_sub) {//기업 > 메인
      menus = [
        { id: 1, menu: '정보 관리', active: false },
        { id: 2, menu: '서브 계정 관리', active: false },
        { id: 3, menu: '제안 현황', active: false },
        { id: 5, menu: '이용권 관리', active: false },
        { id: 12, menu: '이직/구인톡 관리', active: false },
        { id: 6, menu: '헤딩 머니', active: false },
        { id: 7, menu: '알림내역', active: false },
        { id: 9, menu: 'HR 담당자 소개', active: false },
      ];
    }else{//--------------------기업 > 서브계정
      menus = [
        { id: 1, menu: '정보 관리', active: false },
        { id: 3, menu: '제안 현황', active: false },
        { id: 5, menu: '이용권 관리', active: false },
        { id: 12, menu: '이직/구인톡 관리', active: false },
        { id: 6, menu: '헤딩 머니', active: false },
        { id: 7, menu: '알림내역', active: false },
        { id: 9, menu: 'HR 담당자 소개', active: false },
      ];
    }

  }

  const [companyItems, setCompanyItems] = useState(menus);

  //좌측메뉴 선택 처리
  function setMenus(ids) {
    setCompanyItems(varItems =>
      varItems.map(item => {
        if (item.id === ids) {
          return { ...item, active: true }
        } else {
          return { ...item, active: false }
        }
      })
    )
  }

  const onSelectMyMenuHandler = (e, id) => {
    setMenus(id); //객체변경
    getSubItem(id); //부모에 메뉴값 전달
  }

  useEffect(() => {
    setMenus(varSubItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSubItems])

  return (
    <aside id="my_lnb">
      <p className="selected"></p>
      <ul className="menu">
        {companyItems.map((lMenus, i) => (
          <li key={lMenus.id}>
            <Link to={(varSubMember && lMenus.id === 2) ? '/Company/ComHeding/MyHeding1sub' : '/Company/ComHeding/MyHeding' + lMenus.id}
              className={lMenus.active ? 'active' : ''}
              onClick={e => onSelectMyMenuHandler(e, lMenus.id)}
            >{lMenus.menu}</Link>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default CompanyMenu
