/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect }from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import Paging from '../../../components/Layout/Paging'

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';


function ResumeListTip(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    //이력서 TIP 리스트
    const [careerTipList, setCareerTipList] = useState([]);
    const [varCheckedList, setCheckedList] = useState([]);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const BoardList = async () => {
      try {
        await axios
          .post(`${CONFIG.SERVER_HOST}/resume_career_tip_lists`)
          .then((res) => {
            const datas = res.data.results.map((data, i) => ({
              order: i + 1,
              career_idx: data.career_idx,
              career_tip_title: data.career_tip_title,
              career_order: data.career_order,
              career_tip_status: data.career_tip_status,
            }));
            setTotalCount(res.data.totalCount);
            setCareerTipList(datas)
            // setPageList(datas);
          });
      } catch (error) {
        console.log(error);
      }
    }

    //페이지가 바뀌면
    useEffect(() => {
      BoardList(varPageNum);
    },[varPageNum]);

    //전체 선택
    const onCheckedAll = (e) => {
        if (e.currentTarget.checked) {
            const checkedListArray = [];
            careerTipList.forEach((board) => checkedListArray.push(board));
            setCheckedList(checkedListArray);
        } else {
            setCheckedList([]);
        }
    }
    //개별선택
    const onCheckedElement = (e, board) => {
        if (e.currentTarget.checked) {
            setCheckedList([...varCheckedList, board]);
        } else {
            setCheckedList(varCheckedList.filter((el) => el !== board));
        }
    }

    // //선택 항목 삭제
    // const deleteFaqList = (e) =>{
    //     const delitems = varCheckedList.length;
    //     if(delitems === 0) {
    //         window.alert('삭제할 정보가 선택되어 있지 않습니다.'); return;
    //     }
    //     if(!window.confirm(delitems+'개의 선택된 정보를 삭제합니까?')) return;
    //     const mwt_idx = varCheckedList.map((e) => e.mwt_idx );
    //     const params = new URLSearchParams();
    //     params.append("mwt_idx", mwt_idx);
    //     axios.post(`${CONFIG.SERVER_HOST}/_admin_delete_talk`,params, CONFIG.header).then((res) => {
    //         if(res.data.retvalue === 1) {
    //           BoardList(varPageNum);
    //         } else {
    //             window.alert('오류가 발생했습니다.');
    //         }
    //     });
    // }


    return(
        <>
        <Titlebar/>
        <Tabbar tabType="board"/>
        <Menu menuType="board" menu="resumetiplist"/>
        <div id="contents" className="ad_contents">
            <div className="tit">이력서 TIP 관리
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>이력서 TIP 관리<img src={arrImage} alt=""/>이력서 TIP 관리
                </div>
            </div>
            <div className="content">
                <div className="main-box2">
                    <div className="btn-right">
                        {/* <button onClick={deleteFaqList} className="btn">삭제</button> */}
                    </div>
                </div>
                <div className="main-box ">
                    <table className="list">
                        <colgroup>
                            <col style={{width:"8%"}} />
                            <col style={{width:"8%"}} />
                            <col style={{width:"10%"}}/>
                            <col style={{width:"64%"}} />
                            <col style={{width:"10%"}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="board-list">
                                <input type="checkbox" onChange={(e)=>onCheckedAll(e)} id="deleteItems"
                                checked={varCheckedList.length===0?false:varCheckedList.length===careerTipList.length?true:false} />
                                </th>
                                <th>번호</th>
                                <th>순서</th>
                                <th>제목</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {careerTipList.map((board,i) => (
                                <tr key={board.order}>
                                    <td className="board-list">
                                        <input type="checkbox"
                                        id={"deleteItem"+i}
                                        onChange={(e)=>onCheckedElement(e,board)}
                                        checked={varCheckedList.includes(board)?true:false}/>
                                    </td>
                                    <td><p>{ varTotalCount - (varPageNum - 1) * varPageSize - i}</p></td>
                                    <td className="ellipsis"><p>{board.career_order}</p></td>
                                    <td className="ellipsis"><p>{board.career_tip_title}</p></td>
                                    <td><Link to={`/admin/resumetiplistdetail/${board.career_idx}`} state={{career_idx:board.career_idx}} className="btn line black">상세</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                </div>
            </div>
        </div>
        </>
    )
}

export default ResumeListTip;