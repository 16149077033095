/* eslint-disable no-self-assign */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CONFIG from "configs/configs";
//css

//components
import {
  ChangeMoneyUnit,
  remainDays,
  em_titleLength
} from "../Common/Common";
//이미지
import won from "img/main/won.png";
import wonImage from "img/main/won_m.png";


/*채용 지원 현황*/
function MainBookmarkList({
  varHeadHunting,
  varEmployType,
  varSearchString,
  varExecuteSearch,
  setExecuteSearch,
}) {
  const user_idx = localStorage.getItem("mem_idx");
  const [varMode] = useState(varHeadHunting); //헤드헌터전용관(0:사용안함/1:사용)
  const [varPageNum] = useState(1); //페이지 번호
  const varPageSize = 8; //한번에 보여주는 데이터 수

  //전체 채용공고
  const [varRecruitLists, setRecruitLists] = useState([]);
  // 디폴트 이미지.
  const defaultCompanyLogo = `${CONFIG.SERVER_HOST}/mcm_image/default_company.png`;

  const getJobPosting = async () => {
    try {
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("pageNum", varPageNum);
      params.append("pageSize", varPageSize);
      params.append("mode", varMode);
      params.append("employType", varEmployType);
      params.append("searchString", varSearchString);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_employ_lists_page`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            const datas = res.data.results;
            //console.log(datas);
            //채용공고전체
            setRecruitLists(datas);
          } else {
            setRecruitLists([]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getJobPosting();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varEmployType]);
  // 검색 버튼 선택시
  useEffect(() => {
    if (varExecuteSearch === 1) {
      getJobPosting();
      setExecuteSearch(0); // 검색버튼 초기화
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varExecuteSearch]);

  //관심기업 등록/해제
  const onSelectEmployInterestHandler = (e, em_idx, mie_status) => {
    const ichecked = mie_status === 0 || mie_status === null ? 1 : 0;
    console.log("value", em_idx, user_idx, mie_status, ichecked);
    const param = new URLSearchParams();
    param.append("user_idx", user_idx);
    param.append("em_idx", em_idx);
    param.append("checked", ichecked);
    axios
      .post(`${CONFIG.SERVER_HOST}/_update_my_interest_employ`, param)
      .then((res) => {
        alert(res.data.message);
        if (res.data.retvalue === 1) {
          const finsIndex = varRecruitLists.findIndex(
            (element) => element.em_idx === em_idx
          );
          let copyData = [...varRecruitLists];
          if (res.data.insertMode === 1) {
            const idx = res.data.insert_id;
            copyData[finsIndex] = {
              ...copyData[finsIndex],
              mie_em_idx: em_idx,
              mie_idx: idx,
              mie_mem_idx: user_idx,
              mie_status: ichecked,
            };
          } else {
            copyData[finsIndex] = {
              ...copyData[finsIndex],
              mie_status: ichecked,
            };
          }
          console.log(finsIndex, copyData);
          setRecruitLists(copyData);
        }
      });
  };
  console.log(varRecruitLists)

  return (
    <div className="recruit_card_wrap">
      {varRecruitLists.map((jobPosting, index) => (
        <article
          className="recruit_card"
          key={"mainRL_" + varHeadHunting + "_" + index}
        >
          <div
            className={
              jobPosting.mie_idx === null || jobPosting.mie_status === 0
                ? "btn_bookmark m_resize"
                : "btn_bookmark m_resize active"
            }
            onClick={(e) =>
              onSelectEmployInterestHandler(
                e,
                jobPosting.em_idx,
                jobPosting.mie_status
              )
            }
          ></div>
          <Link
            to={`/MyRecruit/MainView/${jobPosting.em_idx}/${jobPosting.em_headhunter}`}
            state={{ from: "main" }}
            className="link_wrapper"
          >
            {/* 비공개 채용일 경우 이미지를 기본 이미 보이게 */}
            <div className="image_wrap">
              {/* {(function () {
                if (jobPosting.mcm_image === 0 || jobPosting.mcm_ufile === undefined) {
                  return <img src={`${defaultCompanyLogo}`} alt="" />
                } else if (jobPosting.mcm_image === 1 || jobPosting.mcm_ufile !== undefined) {
                  return <img src={`${CONFIG.SERVER_HOST}/mcm_image/${jobPosting.mcm_ufile}`} alt="" />
                } else if (jobPosting.em_image === 1 || jobPosting.em_ufile !== "" || jobPosting.em_ufile !== undefined) {
                  return <img src={`${CONFIG.SERVER_HOST}/em_image/${jobPosting.em_ufile}`} alt="" />
                }
              })()} */}
              {(function () {
                if (jobPosting.em_image === 1 && jobPosting.em_type !== 2) {
                  return <img src={`${CONFIG.SERVER_HOST}/em_image/${jobPosting.em_ufile}`} alt="" />
                } else {
                  if (jobPosting.mcm_image === 0) {
                    return <img src={`${defaultCompanyLogo}`} alt="" />
                  } else if (jobPosting.em_type === 2 || jobPosting.mcm_image === 1) {
                    return <img src={`${CONFIG.SERVER_HOST}/mcm_image/${jobPosting.mcm_ufile}`} alt="" />
                  }
                }
              })()}
            </div>
            <div className="content_wrap">
              <div className="type_wrap">
                <span className="type">
                  {jobPosting.em_type === 1 ? "공개채용" : ""}
                  {jobPosting.em_type === 2 ? "비공개채용" : ""}
                  {jobPosting.em_type === 3 ? "실시간 채용관" : ""}
                  {jobPosting.em_type === 4 ? "헤드헌터 전용관" : ""}
                </span>
                {remainDays(jobPosting.mie_rdate) < 10 ? (
                  <span className="new_badge"></span>
                ) : null}
              </div>
              <h1 className="title">{em_titleLength(jobPosting.em_title, 14, '...')}</h1>
              <h2 className="recruit_info" style={{ marginBottom: "17px" }}>
                <span className="company">{jobPosting.em_type === 2 ? jobPosting.mcm_name : jobPosting.com_name}</span>
                &nbsp;ㆍ&nbsp;{jobPosting.vr_name}&nbsp;ㆍ&nbsp;
                {jobPosting.em_careers === 0 ? "경력 무관" :
                  jobPosting.em_careers === 1 ? "신입" :
                    `경력 ${jobPosting.em_career_year}년`}
              </h2>
              <div className="compensation">
                {jobPosting.em_type === 3 ? null :
                  <>
                    <div className="left_wrap">
                      <img className="view_pc" src={won} alt="won" />
                      <img
                        className="view_m"
                        src={wonImage}
                        srcSet="../../img/main/won_m.png 1x, ../../img/main/won_m@2x.png 2x"
                        alt=""
                      />
                      총 보상금
                    </div>
                    <div className="right_wrap">
                      {jobPosting.em_type === 4 ? ChangeMoneyUnit(jobPosting.emh_reward_recommend / 10000) : ChangeMoneyUnit(jobPosting.em_reward_recommend / 10000)}
                    </div>
                  </>
                }
              </div>
            </div>
          </Link>
        </article>
      ))}
    </div>
  );
}

export default MainBookmarkList;
