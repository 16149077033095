/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import { Link, useNavigate, useParams } from 'react-router-dom'

import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

function PushDetail() {
  const { idx } = useParams();
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [Data, setData] = useState({
    title: '',
    body: '',
    common_url: '',
    enterprise_url: '',
    status: ''
  })

  useEffect(() => {

    const sendData = {
      'idx': idx
    }

    axios.post(`${CONFIG.SERVER_HOST}/getPushTempleData`, sendData, CONFIG.header).then((res) => {
      if (res.data.result) {
        setData({
          title: res.data.result[0].title,
          body: res.data.result[0].body,
          common_url: res.data.result[0].common_url,
          enterprise_url: res.data.result[0].enterprise_url,
          status: res.data.result[0].status
        })
      }
    });
  }, [])

  useEffect(() => {
    if (adminlogin === undefined || adminlogin === null || adminlogin === "false") {
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const handleUpdate = (e) => {

    const sendData = {
      'idx': idx,
      'body': Data.body,
      'common_url': Data.common_url,
      'enterprise_url': Data.enterprise_url,
      'status': Data.status
    };

    axios.post(`${CONFIG.SERVER_HOST}/PushTempleUpdate`, sendData, CONFIG.header).then((res) => {
      if (res) {
        console.log(res);
        if (res.data.retvalue === 1) {
          alert('수정되었습니다.');
          navigator('/admin/push');

        }
      }
    });
  }

  const changeChk = (e) => {
    const { checked } = e.target;
    setData({ ...Data, status: checked === true ? 'Y' : 'N' })
  }

  const changeText = (e) => {
    const { name, value } = e.target;
    setData({ ...Data, [name]: value })
  }

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="auto" />
      <div id="contents" className="ad_contents">
        <div className="tit">자동 PUSH관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />PUSH 관리<img src={arrImage} alt="" />자동 PUSH관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">PUSH</div> */}
          <div className="main-box">
            <table className="write">
              <tr>
                <th>PUSH 발송 관리</th>
                <td colspan="3">
                  <div className="write-radio">
                    <input type="text" value={Data.title} readOnly />
                  </div>

                </td>
              </tr>
              <tr>
                <th>사용여부</th>
                <td colSpan="3">
                  <div className="write-radio">
                    <input type="checkbox" name="type1" id="all" checked={Data.status === 'Y' ? true : false} value={Data.status} onClick={changeChk} /> <label htmlFor="all">{Data.status}</label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>일반회원</th>
                <td colspan="3"><input type="text" name="common_url" value={Data.common_url} onChange={changeText} /></td>
              </tr>
              <tr>
                <th>기업/헤드헌터 회원</th>
                <td colspan="3"><input type="text" name="linkurl" value={Data.enterprise_url} onChange={changeText} /></td>
              </tr>
              <tr>
                <th>PUSH 내용</th>
                <td colspan="3"><textarea type="text" name="body" value={Data.body} onChange={changeText} /></td>
              </tr>
            </table>
          </div>
          <div className="btn-center">
            <button className="btn" onClick={() => handleUpdate()}>수정</button>
            <Link to={'/admin/push'} className="btn line" >취소</Link>

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PushDetail;