/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import CONFIG from '../../../configs/configs'

import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'

function SubAccount() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varInitData, setInitData] = useState([]);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/subaccountlist`).then(res => {
      console.log(res)
      const data = res.data.map((data, i) => ({
        order: i + 1,
        id: data.id,
        admin_id: data.admin_id,
        admin_name: data.admin_name,
        email: data.email,
        preferences: data.preferences,
        member_management: data.member_management,
        notice_management: data.notice_management,
        board_management: data.board_management,
        pass_management: data.pass_management,
        search_management: data.search_management,
        statistics: data.statistics

      }))
      setInitData(data);
    })
  }, [])

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="subaccount" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          부관리자 계정 관리
          <div className="path">
            <img src={iconhome} alt="" />HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />운영자 관리<img src={arrImage} alt="" />부관리자 계정 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">부관리자 계정 관리</div> */}
          <div className="main-box2">
            <div className="btn-right">
              <button className="btn-danger">
                <Link to={`/admin/subregister`} style={{ color: '#fff', width: '100%' }} >등록</Link>
              </button>
            </div>
          </div>
          <div className="main-box">
            <table className="list">
              <colgroup>
                <col width="8%"></col>
                <col width="12%"></col>
                <col width="12%"></col>
                <col width="24%"></col>
                <col width="40%"></col>
                <col width="8%"></col>
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>메뉴권한</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {varInitData.map((data, i) => (
                  <tr key={i}>
                    <td><p>{data.order}</p></td>
                    <td><p>{data.admin_id}</p></td>
                    <td>
                      <p>{data.admin_name}</p>
                    </td>
                    <td>{data.email ? data.email : "-"}
                    </td>
                    <td>

                      {data.preferences === 0 ? '': '환경설정'}
                      {data.member_management === 0 ? '': ' / 회원관리'}
                      {data.notice_management === 0 ? '': ' / 공고관리'}
                      {data.board_management === 0 ? '': ' / 게시판관리'}
                      {data.pass_management === 0 ? '': ' / 이용권관리'}
                      {data.search_management === 0 ? '': ' / 검색관리'}
                      {data.statistics === 0 ? '': ' / 통계관리'}


                    </td>
                    <td>
                        <Link to={`/admin/subupdate/${data.id}`} className="btn line black">수정</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubAccount
