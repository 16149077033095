/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';
import Paging from "components/Layout/Paging";
import { usePrevState } from 'components/Common/usePrevState';

/*희망직무*/
function PopCompany({ popAccessRestrictions, setPopAccessRestrictions, saeobja }) {
  const navigate = useNavigate();
  const mem_idx = localStorage.getItem('mem_idx');
  const [varSearchString, setSearchString] = useState('');
  const [varSearchSelectString, setSearchSelectString] = useState('');
  const [varSearchSelectBizNo, setSearchSelectBizNo] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [varSearchingCompanyData, setSearchingCompanyData] = useState([]);

  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(5);
  const [varPageNum, setPageNum] = useState(1);


  const [varChkSearch, setChkSearch] = useState(false);
  const [SPAGE, setSPAGE] = useState(1);
  const [EPAGE, setEPAGE] = useState(10);
  const prevCount = usePrevState(varPageNum);

  const onSearchStringHandler = (e) => {
    console.debug('first  ', e.target.value);
    console.debug('second  ', e.currentTarget.value);
    setSearchString(e.target.value);
  }

  const onSearchingCompanyHandler = async () => {
    if (varSearchString === "") {
      alert("검색할 문자를 먼저 입력하십시오.");
      return false;
    }

    setSearchingCompanyData([]);
    setSearchLoading(true);
    await axios
      .get(`${CONFIG.SERVER_HOST}/_search_companies_all/${encodeURIComponent(varSearchString)}/${SPAGE}/${EPAGE}`)
      .then((r) => {
        // const resData = r.data;
        if (r.data.retvalue === 1) {
          const results = r.data.results;
          setTotalCount(r.data.totalcount);
          setSearchingCompanyData(results.map((company) => {
            return {
              corpName: company.TRCO_CMNM,
              bizNo: company.BIZ_NO,
              address: company.ADDRESS,
            }
          }));
        }

      }).catch((e) => {
        const errData = e;
        setSearchingCompanyData([]);
        console.debug('error ', errData);
      });

    setSearchLoading(false);
    setChkSearch(false);
  }

  const onSelectCompanyHandler = (comp) => {
    const { corpName, bizNo } = comp;
    setSearchSelectString(corpName);
    setSearchSelectBizNo(bizNo);
  }

  const onSelectedCompanyHandler = async () => {
    if (varSearchSelectString === "") {
      alert("등록할 기업을 선택해 주세요.");
      return false;
    }
    // corpName
    // bizNo

    const params = new URLSearchParams();
    params.append("user_idx", mem_idx);
    params.append("corp_idx", varSearchSelectBizNo);
    params.append("corp_name", varSearchSelectString);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_limit_company`, params).then((res) => {
      //alert(res.data.message);
      if (res.data.retvalue === 1) {
        setPopAccessRestrictions('popup');
      }
    });

  }

  const onChangeCloseCompanyHandler = () => {
    setTotalCount(0);
    // setPageNum(1);
    setPopAccessRestrictions('popup');
  }


  useEffect(() => {
    if (!popAccessRestrictions) {
      setSearchString('');
      setSearchSelectString('');
      setSearchLoading(false);
      setSearchingCompanyData([]);
    }

  }, [popAccessRestrictions]);

  useEffect(() => {
    if (varChkSearch) {
      onSearchingCompanyHandler();
    }
  }, [varChkSearch]);

  //saeobja
  useEffect(() => {
    const onSearchStringHandler2 = (str) => {
      setSearchString(str);
    }

    if (saeobja) {
      onSearchStringHandler2(saeobja);
    }
  }, [saeobja]);

  //검색실행
  const onSearchingString = (e) => {
    setPageNum(1);
    setSPAGE(1);
    setEPAGE(10);
    setChkSearch(true);
  }

  useEffect(() => {
    if (searchLoading === false) {
      if (prevCount !== varPageNum) {
        setSPAGE(Number(`${varPageNum === 1 ? `` : varPageNum - 1}1`));
        setEPAGE(Number(`${varPageNum}0`));
        setChkSearch(true);
      }
    }
  }, [varPageNum, searchLoading]);

  console.debug(varSearchSelectString);

  return (
    <section className={popAccessRestrictions}>
      <article className="popup_wrap popup01">
        <div className="title_wrap">
          <h1>열람 제한 기업 추가</h1>
        </div>
        <div className="content_wrap company_search">
          <section className="search_area" style={{ display: "flex", justifyContent: "space_between" }}>
            <input
              type="text"
              name="search_name"
              value={varSearchString}
              onChange={(e) => onSearchStringHandler(e)}
              placeholder="회사명을 입력해주세요."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearchingString(e)
                }
              }}
            />
            <button
              className="btn"
              onClick={(e) => onSearchingString(e)}
              style={{ width: "90px" }}
            >
              {searchLoading ? `조회중` : `조회`}
            </button>
          </section>
          <section className="company_list_area style_scroll">
            {varSearchingCompanyData?.length === 0 ? (
              <div className="nodata">검색된 회사가 없습니다.</div>
            ) : (
              varSearchingCompanyData.map((company, i) => (
                <div key={"cmo_" + i}>
                  <input
                    type="radio"
                    name="radio_company"
                    id={`radio_company${i + 1}`}
                    onClick={() => onSelectCompanyHandler(company)}
                    disabled={searchLoading}
                  />
                  <label htmlFor={`radio_company${i + 1}`}>
                    {`${company.corpName} - ${company.address}`}
                  </label>
                </div>
              ))
            )}
          </section>
          <div className="paging">
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
          </div>
        </div>
        <div className="button_wrap">
          <div className="big_button_wrap one">
            <button className="btn" onClick={(e) => onSelectedCompanyHandler(e)}>
              선택하기
            </button>
          </div>
          <div className="close_button_wrap">
            <button onClick={(e) => onChangeCloseCompanyHandler(e, false)}>
              닫기 X
            </button>
          </div>
        </div>
      </article>
    </section>
  );
}

export default PopCompany;
