/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';
import { Link } from 'react-router-dom';

//2022-04-05 KSR
import { socket, SOCKET_EVENT } from "../Chat/socket";
import ChatForm from "./ChatForm";
import ChatMsg from "./ChatMsg";
//import {MaskingString} from 'components/Common/Common';
//이미지
import ProfileImg from 'img/sub/profile.jpg';

/*채팅*/
function ChatRoom({ send_mem_info, roomIdx }) {
  // const [varFileImage,setFileImage] = useState(ProfileImg);

  /*
  const [Em_Data, set_Em_Data] = useState({
      em_idx : 0 , 
      em_title : '' ,
      crs_re_idx: 0,
      mcm_idx : 0 , 
      com_idx : 0 , 
      mcm_name : '',
      mem_mobile:'',
      mem_id : ''
  });
  */

  const [suggData, setSuggData] = useState([]);
  const [yourInfo, setYourInfo] = useState([]);
  const [yourImg, setYourImg] = useState();

  //ST.2022-04-05 KSR -----------------------------------------------------------
  console.log("send_mem_info", send_mem_info);

  //2022-11-01 KSR
  //const nickname = send_mem_info.mem_idx; //회원idx
  //const roomno = send_mem_info.room_idx;  //채팅방 번호

  const nickname = Number(localStorage.getItem('mem_idx'));
  const roomno = roomIdx;  //채팅방 번호
  const crs_status = send_mem_info.crs_status;
  //const yourImg = send_mem_info.profile_img;  //상대방 프로필 이미지 2022-10-08 KSR
  console.log('yourImg :', yourImg);

  const mem_mode = Number(localStorage.getItem('mem_mode'));

  //상대방 프로필 이미지
  //let youImgUrl = "";

  useEffect(() => {
    if (yourInfo) {
      console.log("yourInfo", yourInfo);
      if (yourInfo.ufile === null || yourInfo.ufile === "null") {
        setYourImg(ProfileImg);
      } else {
        mem_mode === 0 ?
          setYourImg(`${CONFIG.SERVER_HOST}/mcm_image/${yourInfo.ufile}`)
          :
          suggData[0]?.crs_status !== 2 ?
            setYourImg(ProfileImg)
            :
            setYourImg(`${CONFIG.SERVER_HOST}/user_photo/${yourInfo.ufile}`)
          ;
      }
    }
  }, [yourInfo]);

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.emit(SOCKET_EVENT.JOIN_ROOM, { roomno, nickname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomno, nickname]);
  //ED.2022-04-05 KSR -----------------------------------------------------------

  //axios :: 채팅방 정보 가져오기 ----------
  useEffect(async () => {
    if (roomIdx > 0) {
      const param = new URLSearchParams();
      param.append('room_idx', roomIdx);
      param.append('mem_mode', Number(localStorage.getItem('mem_mode')));
      param.append('mem_idx', Number(localStorage.getItem('mem_idx')));
      await axios.post(`${CONFIG.SERVER_HOST}/_get_chat_info`, param).then((res) => {
        if (res.data?.retvalue === 1) {
          if (res.data) {
            console.log(res.data?.yourInfo);
            setYourInfo(res.data?.yourInfo[0]);
          }

        }
      });
    }
  }, [roomIdx]);

  /* 2022-11-06 KSR
  useEffect( async()=>{
    const param = new URLSearchParams();
    param.append('room_idx', roomIdx);
    param.append('mem_mode', Number(localStorage.getItem('mem_mode')));
    param.append('mem_sub', Number(localStorage.getItem('mem_mode')) === 0?0:Number(localStorage.getItem('mem_sub')));
    await axios.post(`${CONFIG.SERVER_HOST}/_get_chat_company_data`, param).then((res) => {
        //console.log(res);
        if(res.data?.retvalue === 1){
            const data = res.data?.results[0];
            set_Em_Data({
                crs_em_idx : data?.crs_em_idx ,      //채용공고 idx
                crs_em_title : data?.crs_em_title ,  //채용공고 제목
                crs_status: data?.crs_status,
                crs_re_idx: data?.crs_re_idx,
                mcm_idx : data?.mcm_idx ,    //회사 idx
                com_idx : data?.com_idx ,    //회사회원 idx
                mcm_name : data?.mcm_name,   //회사명
                mem_mobile: data?.mem_mobile,
                mem_id : data?.mem_id        //지원자 idx
                
            })
        }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomIdx]);
  */

  //제안 상태 가져오기
  useEffect(async () => {
    const param = new URLSearchParams();
    param.append('room_idx', roomIdx);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_chat_suggestion_data`, param).then((res) => {
      if (res.data?.retvalue === 1) {
        const data = res.data?.results;
        setSuggData(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomIdx]);
  //console.log(Em_Data);

  return (
    <>
      <div className="chat_area">
        <div className="top">
          {mem_mode === 0 ? //일반회원 
            <>
              <div className="img"><img src={yourImg} alt="" /></div>
              <article className="offerer_info">
                <p className="nickname">{yourInfo?.nickname} {yourInfo?.mem_name ? `(${yourInfo?.mem_name})` : ``}
                  <span className="company">{/*추가정보*/}</span>
                </p>
                <p className="recruit_title">{/*추가정보*/}</p>
              </article>

              {suggData?.length === 0 || (suggData?.length > 0 && !suggData?.some(item => item.crs_status === 2)) ?
                <Link className="btn white" to={'/MyHeding/MyHeding3'} >제안 수락이 필요</Link>
                : yourInfo?.nickname ? <div className="btn white">{yourInfo?.mem_mobile}</div> : ``
              }
            </>
            : //------------- 기업회원
            <>
              <div className="img"><img src={yourImg} alt="" /></div>
              <article className="offerer_info">
                {/* 제안 수락한 인재 클릭 시 해당 이력서 이동 추가 */}
                {suggData?.length > 0 && suggData?.some(item => item.crs_status === 2) ?
                  <Link to={`/Company/ComResource/SearchView/${suggData[0]?.crs_re_idx}`}>
                    <p className="nickname">{yourInfo?.nickname} {yourInfo?.mem_name ? `(${yourInfo?.mem_name})` : ``}
                      <span className="company">{/*추가정보*/}</span>
                    </p>
                  </Link>
                  :
                  <p className="nickname">{yourInfo?.nickname} {yourInfo?.mem_name ? `(${yourInfo?.mem_name})` : ``}
                    <span className="company">{/*추가정보*/}</span>
                  </p>
                }
                {/* 제안 수락한 인재 클릭 시 해당 이력서 이동 추가 */}
                <p className="recruit_title">{/*추가정보*/}</p>
              </article>
              {suggData?.length === 0 || (suggData?.length > 0 && !suggData?.some(item => item.crs_status === 2)) ?
                <Link className="btn white" to={'/Company/ComHeding/MyHeding3'} >제안 수락이 필요</Link>
                : <div className="btn white">{yourInfo?.mem_mobile}</div>
              }
            </>
          }

        </div>
        {/** 2022-04-05 KSR
           * 채팅 메시지 영역(챗방 목록 우측) 
          **************************************/}
        <div className="content">
          <ChatMsg myNickname={nickname} crs_status={crs_status} yourImg={yourImg} />
        </div>
        <ChatForm roomno={roomno} nickname={nickname} />
      </div>
    </>
  );
}

export default ChatRoom;
