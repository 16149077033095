import React, { useState,useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { resetNavMenu,resetNavSubMenu } from "components/Common/Common";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import BannerBottom from "components/Banner/BannerBottom";
// import SubVisual from "../../components/Layout/SubVisual";

import MainList from "../Recruit/MainList";
import MainList2 from "../Recruit/MainList2";
import MainView from "../Recruit/MainView";

//css
import "css/MyRecruit.css";
import "css/Sub.css";
import "css/Common.css";
import "css/MyHeadHunter.css";

const MyRecruit = () => {
  const PageFolder = "/MyRecruit/";
  const [varHeadHunter, setHeadHunter] = useState(0);

  useEffect(() => {
    resetNavMenu(0);
    //resetNavSubMenu(0,0);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('varHeadHunter '+varHeadHunter);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<MainList varHeadHunter={0} setHeadHunter={setHeadHunter} PageFolder={PageFolder}/>}/>
        <Route path="/MainList" element={<MainList varHeadHunter={0} setHeadHunter={setHeadHunter} PageFolder={PageFolder}/>}/>

        {/*2022-11-20 채용구분/페이징/검색어 param 처리 */}
        <Route path="/MainList/:cate/:page/:shIdx" element={<MainList varHeadHunter={0} setHeadHunter={setHeadHunter} PageFolder={PageFolder}/>}/>

        {/*2022-11-23 채용목록 신규 작업중 - KSR */}
        <Route path="/MainList2/:cate/:page/:pagesize/:sort" element={<MainList2 PageFolder={PageFolder} resetNavSubMenu={resetNavSubMenu}/>}/>

        <Route path="/MainList/HeadHunter" element={<MainList varHeadHunter={1} setHeadHunter={setHeadHunter} PageFolder={PageFolder}/>}/>
        <Route path="/MainView/:em_idx/:em_headhunter" element={<MainView varHeadHunter={varHeadHunter} setHeadHunter={setHeadHunter} PageFolder={PageFolder}/>}/>
      </Routes>
      <BannerBottom />
      <Footer />
    </>
  );
};

export default MyRecruit;
