/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { ChangeSearchingDateFormat } from '../Common/Common';
import CustomDatePicker from "components/Common/CustomDatePicker";

/*채용 지원 현황*/
function MyApplySearch({ varSearchData, setSearchData }) {
  //문자형 날짜를 date형으로 변경
  const [varStartDate, setStartDate] = useState(varSearchData.sdate === null ? null : new Date(varSearchData.sdate));
  const [varEndDate, setEndDate] = useState(varSearchData.edate === null ? null : new Date(varSearchData.edate));
  const [varSearchGroup, setSearchGroup] = useState({ ...varSearchData });

  const onSelectedStateHandler = (e) => {
    setSearchGroup({ ...varSearchGroup, status: Number(e.currentTarget.value) });
    setSearchData({ ...varSearchData, status: Number(e.currentTarget.value) });
  };

  const onSearchOptionHandler = (e) => {
    setSearchGroup({ ...varSearchGroup, searchoption: e.currentTarget.value });
  };
  const onSearchStringHandler = (e) => {
    setSearchGroup({ ...varSearchGroup, searchstring: e.currentTarget.value });
  };
  const onExecuteSearchingHandler = (e) => {
    e.preventDefault();
    setSearchData(varSearchGroup);
  };

  const onChangeDateHandler = (ino, date) => {
    const dbDate = ChangeSearchingDateFormat(date);  //date format -> 문자형 '2022-10-12'으로
    if (ino === 0) {
      setStartDate(date);
      setSearchGroup({ ...varSearchGroup, sdate: dbDate });
    } else {
      setEndDate(date);
      setSearchGroup({ ...varSearchGroup, edate: dbDate });
    }
  };

  return (
    <>
      {/*진행상태*/}
      <div className="wrap">
        <p className="title_field">진행상태</p>
        <select className="style_select" value={varSearchGroup.status} onChange={(e) => onSelectedStateHandler(e)}>
          <option value={100}>전체</option>
          <option value={0}>미열람</option>
          <option value={1}>검토중</option>
          <option value={2}>서류합격</option>
          <option value={3}>면접합격</option>
          <option value={4}>최종합격</option>
          <option value={-2}>불합격</option>
          <option value={5}>입사</option>
          <option value={-3}>조기퇴사</option>
        </select>
      </div>
      {/*지원일자*/}
      <div className="wrap">
        <p className="title_field">지원일자</p>
        <CustomDatePicker
          data={varSearchGroup}
          varStartDate={varStartDate}
          varEndDate={varEndDate}
          onChangeDateHandler={onChangeDateHandler}
        />
      </div>
      {/*검색*/}
      <fieldset className="list_search_area">
        <select
          className="style_select"
          value={varSearchGroup.searchoption}
          onChange={(e) => onSearchOptionHandler(e)}
        >
          <option value={"all"}>전체</option>
          <option value={"em_title"}>채용 제목</option>
          <option value={"com_name"}>회사명</option>
          <option value={"er_name"}>추천인</option>
        </select>
        <div className="search">
          <input
            type="text"
            value={varSearchGroup.searchstring}
            onChange={(e) => onSearchStringHandler(e)}
            placeholder="검색어를 입력해주세요"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onExecuteSearchingHandler(e)
              }
            }}
          />
        </div>
        <button onClick={(e) => onExecuteSearchingHandler(e)} className="btn">
          조회
        </button>
      </fieldset>
    </>
  );
}

export default MyApplySearch;