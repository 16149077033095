import React, { useState } from "react";
import axios from "axios";
import CONFIG from "configs/configs";

function PopRefund({varOrderIndex, setShowModal, varPageVoucherList, setPageVoucherList }) {
  //환불사유
  const [varReason,setReason] = useState('');
  const onChangeHandler = (e) => {
    setReason(e.currentTarget.value);
  }

  //환불요청 접수 -> db정리 & 배열정리
  const onSubmitRefundHandler = async (e) => {
    try {
      const params = new URLSearchParams();
      params.append("or_idx", varOrderIndex);
      params.append("or_comment", varReason);
      await axios.post(`${CONFIG.SERVER_HOST}/voucherRefund`, params).then((res) => {
        if(res.data.retvalue === 1) {
          //목록에서 결제완료->환불요청으로 변경
          const newData = varPageVoucherList.map((element)=>{
            if(element.or_idx === varOrderIndex) {
              const newElement = {...element,or_status:3,canRefund:false};
              return newElement;
            } else {
              return element;
            }
          });
          //변경배열재저장
          setPageVoucherList(newData);
          alert(res.data.message);
          //창닫기
          setShowModal(false);
        } else {
          alert(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="Popup" style={{ display: "block" }}>
      <div className="Wrap">
        <div className="Box">
          <div className="Top">
            <h3 className="Title">환불요청</h3>
          </div>
          <div className="PopContents" style={{overflowY:'hidden'}}>
            <div className="Section">
              <ul className="ListDot">
                <li>해당 상품의 환불 요청 사유를 작성해주세요.</li><br/>
              </ul>
              <textarea className="textarea_h150" value={varReason} onChange={(e)=>onChangeHandler(e)}></textarea>
            </div>
            <div className="ButtonBottom">
              <div className="btn white" onClick={()=>setShowModal(false)}>취소</div>
              <div onClick={()=>onSubmitRefundHandler()} className="btn">확인</div>
            </div>
          </div>
          <div className="PopBottom">
            <div className="Button" style={{cursor:"pointer"}} onClick={()=>setShowModal(false)}>닫기 X</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopRefund;
