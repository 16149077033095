import React, { useState,useEffect } from 'react'
import { Routes, Route } from "react-router-dom";

import { resetNavMenu,resetNavSubMenu } from "../../components/Common/Common";

import Header from '../../components/Layout/Header'
/* import SubVisual from '../../components/Layout/SubVisual' */
import Footer from '../../components/Layout/Footer'

/* import Tab from '../../components/Tab/TalkTab' */
import TalkList from '../Talk/TalkList'
import TalkView from '../Talk/TalkView'
import TalkWrite from '../Talk/TalkWrite'
import TalkUpdate from '../Talk/TalkUpdate'
import BannerBottom from 'components/Banner/BannerBottom';

function MyTalk() {

  const siteMode = Number(localStorage.getItem('siteMode'));

  const [varInitData, setInitData] = useState([]);
  const [varTalkInfo, setTalkInfo] = useState({
    mwt_idx: 0,
    mwt_title: "",
    mem_nickname: "",
    mwt_comment: "",
    mwt_hits: 0,
    mwt_rdate: "",
    mwt_mem_idx: 0
  });

  useEffect(() => {
    resetNavMenu(siteMode === 1 ?1:3);
    resetNavSubMenu(siteMode === 1 ?1:3,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div>
      <Header />
      {/* <SubVisual PageTitle="이직Talk" SubVisual_className="type4"/> */}
      <Routes>
            <Route path="/" element={<TalkList/>} /> 
            <Route path="/TalkList" element={<TalkList/>} /> 
            <Route path="/TalkView" element={<TalkView 
                varInitData={varInitData}
                varTalkInfo={varTalkInfo} setTalkInfo={setTalkInfo}
            />} /> 
            <Route path="/TalkWrite" element={<TalkWrite 
                varInitData={varInitData} setInitData={setInitData}
                varTalkInfo={varTalkInfo} setTalkInfo={setTalkInfo}
            />} />
            <Route path="/TalkUpdate" element={<TalkUpdate
                varInitData={varInitData} setInitData={setInitData}
                varTalkInfo={varTalkInfo} setTalkInfo={setTalkInfo}
            />} />
      </Routes>
      <BannerBottom />
      <Footer />
    </div>
  )
}

export default MyTalk
