/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css"

import { ChangeSearchingDateFormat } from '../Common/Common'
import CustomDatePicker from "components/Common/CustomDatePicker";

function CompanyVoucherUsedSearch({ varMemberList, onSearchHandler, varUseSearchDate, setuseSearchDate, varUseSearchIdx, setuseSearchIdx }) {
  //문자형 날짜를 date형으로 변경
  const [varStartDate, setStartDate] = useState(varUseSearchDate.sdate === null ? null : new Date(varUseSearchDate.sdate));
  const [varEndDate, setEndDate] = useState(varUseSearchDate.edate === null ? null : new Date(varUseSearchDate.edate));

  //검색일자 변경
  const onChangeDate = (ino, date) => {
    let dbDate = ChangeSearchingDateFormat(date);
    if (ino === 0) {
      setStartDate(date);
      setuseSearchDate({ ...varUseSearchDate, sdate: dbDate });
    } else {
      setEndDate(date);
      setuseSearchDate({ ...varUseSearchDate, edate: dbDate });
    }
  };

  //직원선택시
  const ChangeUser = (e) => {
    const { value } = e.currentTarget;
    setuseSearchIdx(value);
  }

  return (
    <div className="list_top">
      <form className="search_wrap">
        <div className="wrap">
          <p className="title_field">사용자</p>
          <select className="style_select" value={varUseSearchIdx} onChange={ChangeUser}>
            <option value={0}>전체</option>
            {varMemberList?.map((item, key) => (
              <option key={key} value={item?.mem_idx}>{item?.mem_id}</option>
            ))}
          </select>
        </div>
        <div className="wrap">
          <p className="title_field">사용일자</p>
          <CustomDatePicker
            data={varUseSearchDate}
            varStartDate={varStartDate}
            varEndDate={varEndDate}
            onChangeDateHandler={onChangeDate}
          />
          <button type="button" onClick={(e) => onSearchHandler(e)} className="btn">조회</button>
        </div>
      </form>
    </div>
  );
}

export default CompanyVoucherUsedSearch;
