import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ResumeViewC from "../../components/Announcement/ResumeViewC";

//css

const ResumeView = ({ setPageTitle }) => {
  const location = useLocation();
  const [varResumeIndex, setResumeIndex] = useState(0);
  useEffect(() => {
    // setPageTitle("공고/지원자 관리");
    if (location !== undefined && location.state !== undefined) {
      setResumeIndex(location.state.re_idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setPageTitle]);

  return varResumeIndex === 0 ? null : <ResumeViewC re_idx={varResumeIndex} />;
};

export default ResumeView;
