import React from "react";


//components
import Header from "./Header";

//css
import "css/guide.css";

//이미지
import guide0101 from "img/sub/guide0101.jpg";
import guide0102 from "img/sub/guide0102.jpg";
import guide0103 from "img/sub/guide0103.jpg";
import guide0104 from "img/sub/guide0104.jpg";
import guide0105 from "img/sub/guide0105.jpg";
import guide0101_m from "img/sub/guide0101_m.jpg";
import guide0102_m from "img/sub/guide0102_m.jpg";
import guide0103_m from "img/sub/guide0103_m.jpg";
import guide0104_m from "img/sub/guide0104_m.jpg";
import guide0105_m from "img/sub/guide0105_m.jpg";
import BannerBottom from "components/Banner/BannerBottom";
import Footer from "./Footer";

function Guide() {

	return (
		<>
		<Header/>
			<div className="center_wrap page_guide">
        <h2 className="pg_tit">사용자 가이드</h2>
        <ul className="tab01 blue">
          <li className="active">
            <a href="Guide">일반회원</a>
          </li>
          <li>
            <a href="GuideCompany">기업회원</a>
          </li>
          <li>
            <a href="GuideHeadHunter">헤드헌터/서치펌</a>
          </li>
        </ul>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">일반회원서비스</p>
            <p className="tit">회원가입</p>
            <div className="txt">헤딩 일반회원으로 서비스를 사용할 경우<br/>
              ① SNS 간편 로그인 혹은 ‘아직 헤딩 회원이 아니신가요?’ 버튼을 클릭하여 일반 회원가입을 할 수 있습니다.
              <a href="/JoinUs/User" className="button">회원가입 하러가기</a>
            </div>
          </article>
          <article className="img_area">
            <img src={guide0101} alt="회원가입" className="pc"/>
            <img src={guide0101_m} alt="회원가입" className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">일반회원서비스</p>
            <p className="tit">헤딩 이력서 작성</p>
            <div className="txt">상단 메뉴에서 HEIDNG 이력서 클릭 후 이력서 등록을 시작합니다.<br/>
              ① 이름, 성별, 생년월일, 연락처, 이메일의 기본 정보는 회원가입 시 등록된 기본 정보로 자동으로 완성됩니다.<br/>
              ② 간편 인증 진행 시 국민건강보험공단 자격득실 확인서를 기준으로 인증된 경력이 자동으로 추가됩니다.
            </div>
            <p className="sub_tit">일반회원서비스</p>
            <p className="tit">경력 불러오기</p>
            <div className="txt">③ 간편 인증 요청을 통해 자동으로 경력이 추가됩니다.<br/>
              <a href="/MyResume" className="button">이력서 등록하러가기</a>
            </div>
          </article>
          <article className="img_area">
            <img src={guide0102} alt="이력서작성,경력불러오기" className="pc"/>
            <img src={guide0102_m} alt="이력서작성,경력불러오기" className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">일반회원서비스</p>
            <p className="tit">대표 이력서</p>
            <div className="txt">이력서 작성 후 HEDING 이력서 관리에서 대표 이력서를 관리할 수 있습니다.<br/>
목록에서 이력서 선택 후 대표 이력서 지정 버튼 클릭 시 해당 이력서를 대표 이력서로 지정할 수 있습니다.
            </div>
          </article>
          <article className="img_area">
            <img src={guide0103} alt="대표 이력서" className="pc"/>
            <img src={guide0103_m} alt="대표 이력서" className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">일반회원서비스</p>
            <p className="tit">이력서 다운로드</p>
            <div className="txt">HEDING 이력서 관리에서 이력서를 PDF로 다운로드할 수 있습니다.
            </div>
          </article>
          <article className="img_area">
            <img src={guide0104} alt="이력서 다운로드" className="pc"/>
            <img src={guide0104_m} alt="이력서 다운로드" className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">일반회원서비스</p>
            <p className="tit">헤딩 머니</p>
            <div className="txt">My HEDING에서 헤딩 머니 메뉴 클릭 시 보유 중인 헤딩 머니와 환전 가능한 헤딩 머니가 보입니다.<br/>
① 환전하기 클릭 후 환전 계좌 정보(은행명, 계좌번호, 예금주, 주민번호)를 입력합니다.<br/>
(회원명과 예금주가 동일해야 합니다.)
② 환전하고자 하는 환전 요청금액을 입력 후 환전 요청하기 버튼 클릭합니다.<br/><br/>

매달 1일~말일까지 환전 신청한 내역은 익월 10일에 현금 입금됩니다.<br/>
환전 시 수수료와 소득세가 제외된 실 지급액으로 입금됩니다.<br/>
(중도 퇴사 시 지급된 헤딩 머니는 회수됩니다)
            </div>
          </article>
          <article className="img_area">
            <img src={guide0105} alt="헤딩 머니" className="pc"/>
            <img src={guide0105_m} alt="헤딩 머니" className="mobile"/>
          </article>
        </section>
			</div>
			<BannerBottom/>
			<Footer/>
		</>

	)
}

export default Guide;