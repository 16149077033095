/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import { Link } from "react-router-dom";
import { chkOS2 } from "../Common/Common";
import CONFIG from 'configs/configs'

//이미지
import main_bottom_link01 from "img/main/main_bottom_link01.png";
import main_bottom_link02 from "img/main/main_bottom_link02.png";
import main_bottom_link03 from "img/main/main_bottom_link03.png";

/*메인>아이콘배너*/
function MainIconBanner({ setAppPopupShow, appPopupShow }) {
    //   const mcm_idx = localStorage.getItem('mcm_idx');
    const uAgent = navigator.userAgent.toLocaleLowerCase();

    // const AppPopupOpen = () => {
    //     setAppPopupShow(!appPopupShow);
    // };

    /**모바일 앱, 스토어 실행 */
    const OpenApp = () => {
        let isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod');
        let isAndroid = navigator.userAgent.match('Android');

        let visitedAt = (new Date()).getTime(); // 방문 시간

        if (isAndroid) {
                window.location.href = CONFIG.Android_Deeplink;
        } else if (isiOS) {

            setTimeout(function () {
                if ((new Date()).getTime() - visitedAt < 2000) {
                    window.location.href = CONFIG.Ios_ItunesId;
                }
            }, 500);
            setTimeout(function () {
                window.location.href = CONFIG.Iod_Deeplink;
            }, 0);

        };
    };

    const AndroidStore = () => {
        window.location.href = CONFIG.GooGlePlayStore;
    };

    const IosStore = ()=>{
        window.location.href = CONFIG.AppStore;
    };

    return (
        <section className="main_bottom_link center_wrap">
            <article className="wrap">
                <div className="img_field">
                    {/* 2022-11-23 관리자쪽에 볼 수 있는 화면 없어서 우선 외부 링크
                    <Link to="/Email">
                        <img src={main_bottom_link01} alt="main_bottom_link01" />
                    </Link>
                    */}
                    <button onClick={() => window.open('https://tally.so/r/wo994b', '_blank')}>
                        <img src={main_bottom_link01} alt="main_bottom_link01" />
                    </button>
                </div>
                <div className="info_area">
                    <p className="text_field">문의사항을 보내주세요</p>
                    <p className="title_field">이메일문의</p>
                </div>
            </article>
            {/**앱일경우 미노출 khs 2022-11-18 */}
            {uAgent.search("heding") === -1 ?
                <article className="wrap">
                    <div className="img_field"><img src={main_bottom_link02} alt="main_bottom_link02" /></div>
                    <div className="info_area">
                        <p className="title_field">App 다운로드</p>
                        <div className="btn_area">
                            {/* {alert(chkOS(window.navigator.userAgent))} */}
                            {chkOS2() === 2 ?
                                <>
                                    <button onClick={IosStore} className="button">APP STORE</button>
                                    <button onClick={AndroidStore} className="button">PLAY STORE</button>
                                </>
                                :
                                chkOS2() === 1 ? <button onClick={() => {OpenApp()}} className="button">PLAY STORE</button> : <button onClick={() => {OpenApp()}} className="button">APP STORE</button>

                            }
                        </div>
                    </div>
                </article>
                :
                null

            }

            <article className="wrap">
                <div className="img_field">
                    <button onClick={() => window.open('https://tally.so/r/wo994b', '_blank')}>
                    <img src={main_bottom_link03} alt="main_bottom_link03" />
                    </button>
                </div>
                <div className="info_area">
                    <p className="title_field">고객센터</p>
                    <p className="text_field">Am09:00~Pm18:00</p>
                </div>
            </article>
        </section>
    );
}

export default MainIconBanner;
