import BannerBottom from "components/Banner/BannerBottom"
import Footer from "components/Layout/Footer"
import Header from "components/Layout/Header"

const Layout = ({ children, className }) => {
    return (
        <div className={className}>
            <Header />
            {children}
            <BannerBottom />
            <Footer />
        </div>)
}

export default Layout