// import React from "react";
import { useEffect } from "react";
import axios from "axios";
// import api from "axios/axios";
const { Kakao } = window;

const Auth = () => {

  const REST_API_KEY = "a262b69b9dfcf75e039ba42c186f1789";
  const REDIRECT_URI = "http://localhost:3000/oauth/kakao/callback";
  // calllback으로 받은 인가코드
 // const code = new URL(window.location.href).searchParams.get("code");
  //const history = useHistory();
  const getToken = async () => {

   
    let params = new URL(document.location.toString()).searchParams;
    let code = params.get("code");
    let grant_type = "authorization_code";

    console.log(code);
        axios
        .post(
          `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${code}`,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((res) => {
          console.log(res);
          Kakao.Auth.setAccessToken(res.data.access_token);
          Kakao.API.request({
            url: "/v2/user/me",
            success: function (response) {
              console.log(response);
            },
            fail: function (error) {
              console.log(error);
            },
          });
        });
    //   // access token 가져오기
    //   const res = await axios.post(
    //     "https://kauth.kakao.com/oauth/token",
    //     payload
    //   ).catch((err) => {
    //     console.log(err)
    //   });
      
    //   // Kakao Javascript SDK 초기화
    //   window.Kakao.init(REST_API_KEY);
    //   // access token 설정
    //   window.Kakao.Auth.setAccessToken(res.data.access_token);
      //history.replace("/profile");

    
  };
  useEffect(() => {
    getToken();
  }, []);
  return null;
};
export default Auth;