import React, { useState, useEffect } from "react";
import { useParams, Route, Routes, useNavigate } from 'react-router-dom';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import CorporateMemberDtail from '../user/Corporate_Member_Dtail';
import VoucherOrderList from './Voucher_Order_List';
import SubAccount from '../user/Sub_Account';
import SubAccountDetail from '../user/Sub_Acount_Detail';
import NotificationApplicationManagement from "./Notification_Application_Management";
import DetailMenu from "../inc/Detail_Menu";

function Corporate_Member_Info() {
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const { mcm_idx, mem_idx } = useParams();
    const [varCategory, setCategory] = useState(1);
    
    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="users" />
            <Menu menuType="users" menu="corporate_member_list" />
            <div id="contents" className="ad_contents">
                <div className="tit">기업 회원 관리
                    <div className="path">
                        <img src={iconhome} alt=""/>
                        HOME<img src={arrImage}  alt=""/>회원관리<img src={arrImage}  alt=""/>기업회원 관리
                    </div>
                </div>
                <div className="content">
                    {/* <div className="tit">기업회원 상세</div>*/}
                    {/* 내부 메뉴 */}
                    <DetailMenu mode={1} user_idx={mcm_idx} mem_idx={mem_idx} varCategory={varCategory} setCategory={setCategory}/>
                    <Routes>
                        <Route path="/" element={<CorporateMemberDtail mcm_idx={mcm_idx} mem_idx={mem_idx} setCategory={setCategory}/>} />
                        <Route path="voucher_list" element={<VoucherOrderList mcm_idx={mcm_idx} mem_idx={mem_idx} setCategory={setCategory}/>} />
                        <Route path="sub_account" element={<SubAccount mcm_idx={mcm_idx} mem_idx={mem_idx} setCategory={setCategory}/>} />
                        <Route path="sub_acount_detail" element={<SubAccountDetail mcm_idx={mcm_idx} mem_idx={mem_idx} setCategory={setCategory}/>} />
                        <Route path="notification_application" element={<NotificationApplicationManagement mcm_idx={mcm_idx} mem_idx={mem_idx} setCategory={setCategory}/>} />
                    </Routes>
                </div>
            </div>
        </React.Fragment>
    )

}

export default Corporate_Member_Info;