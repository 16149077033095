/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
//css
import SearchIcon_pc from "img/sub/search.png";
import SearchIcon_m from "img/sub/search_m.png";
import resetInitImage from "../../img/sub/icon_reset_blue.png";
import $ from "jquery";

const RecruitTopSearch = ({
  varWorkingList,
  setWorkingList,
  varRegionList,
  setRegionList,
  varCareerList,
  setCareerList,
  varSearchOption,
  setSearchOption,
  varSearchString,
  setSearchString,
  varEmployType,
  setEmployType,
  varSortMode,
  setSortMode,
  varPageSize,
  setPageSize,
  onSearchingHandler,
  varHeadHunter,
  getEmploySearchLists,
  varCareerDirectList,
  setCareerDirectList,
  // varPageNum
}) => {
  const [varTopOption, setTopOption] = useState("");
  const [varWorkCategoryFiltered, setWorkCategoryFiltered] = useState([]);
  const [varWorkCategoryList, setWorkCategoryList] = useState([]);
  const [varWorkCategoryDetailList, setWorkCategoryDetailList] = useState([]);
  const [careerDirect, setCareerDirect] = useState({
    careerstart: '',
    careerend: ''
  });
  const [careerDirectShow, setCareerDirectShow] = useState(false);
  const [clicked, setClicked] = useState({
    task: false,
    region: false,
    career: false
  })

  //검색조건
  // const categoryFormat = { cid: 0, cname: "" };
  const categoryFormat = useMemo(() => ({ cid: 0, cname: "" }), []);
  const workingFormat = { sid1: 0, sid2: 0, sname: "" };
  const careerFormat = { sid: 0, sname: "경력무관" };
  const [varCategory, setCategory] = useState(categoryFormat);
  const [varRegionArray, setRegionArray] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [varCareerLists] = useState([
    { sid: 0, sname: "경력무관" },
    { sid: 1, sname: "신입" },
    { sid: 2, sname: "경력자" },
  ]);

  //페이지 최초 호출시
  useEffect(() => {
    if (varRegionArray.length === 0) {
      axios.post(`${CONFIG.SERVER_HOST}/_get_recruit_options`).then((res) => {
        //직무
        setWorkCategoryList(res.data.workCategorys);
        //상세직무
        setWorkCategoryDetailList(res.data.workCategoryDetails);
        //지역
        setRegionArray(res.data.regionLists);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //상단부의 분류 선택시
  const onSelectTopHandler = (e, sname) => {
    e.preventDefault();
    //이전에 선택된 지역 --> 선택 취소
    let topList = document.querySelectorAll(".depth01");
    for (var i = 0; i < topList.length; i++) {
      topList[i].classList.remove("active");
    }
    //선택된 지역 --> 선택 표시
    e.currentTarget.className = "depth01 active";
    setTopOption(sname);
    if (sname === "직무") {
      setClicked((prev) => ({
        ...prev,
        task: !prev.task
      }));
      // 선택 해제
      if (clicked.task) {
        e.currentTarget.className = "depth01";
      }
    } else if (sname === "지역") {
      setClicked((prev) => ({
        ...prev,
        region: !prev.region
      }));
      // 선택 해제
      if (clicked.region) {
        e.currentTarget.className = "depth01";
      }
    } else if (sname === "경력") {
      setClicked((prev) => ({
        ...prev,
        career: !prev.career
      }));
      // 선택 해제
      if (clicked.career) {
        e.currentTarget.className = "depth01";
      }
    }
  };


  // 상단부 분류 선택 취소
  const clearTop = () => {
    let topList = document.querySelectorAll(".depth01");
    for (var i = 0; i < topList.length; i++) {
      topList[i].classList.remove("active");
    }
    setTopOption(""); // 상단바 초기화
  }

  //직무 전체 선택 취소
  const clearWorkCategory = useCallback(() => {
    let worksList = document.querySelectorAll(".category");
    for (var i = 0; i < worksList.length; i++) {
      worksList[i].classList.remove("active");
    }
    setCategory(categoryFormat); // 직무 초기화
  }, [categoryFormat]);
  //직무세부 전체 선택 취소
  const clearWorkCategoryDetail = () => {
    let worksDetailList = document.querySelectorAll(".categorydetail");
    for (var i = 0; i < worksDetailList.length; i++) {
      worksDetailList[i].classList.remove("active");
    }
  };

  //지역 전체 선택 취소
  const clearRegion = () => {
    let regionsList = document.querySelectorAll(".region");
    for (var i = 0; i < regionsList.length; i++) {
      regionsList[i].classList.remove("active");
    }
  }
  // 경력 전체 선택 취소
  const clearCareer = () => {
    let careersList = document.querySelectorAll(".career");
    for (var i = 0; i < careersList.length; i++) {
      careersList[i].classList.remove("active");
    }
  }

  //상단항목선택시
  useEffect(() => {
    //모든 하단메뉴 회면에서 삭제

    //상단메뉴선택시 해당 항목 활성화
    if (varTopOption === "직무") {
      setTopOption(varTopOption);
    } else if (varTopOption === "지역") {
      setTopOption(varTopOption);
    } else if (varTopOption === "경력") {
      setTopOption(varTopOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTopOption]);

  //직무 변경시 직무세부항목 재조정
  useEffect(() => {
    //세부직무 선택 클리어
    clearWorkCategoryDetail();
    //세부직무 필터링
    const newList = varWorkCategoryDetailList.filter(
      (element) => element.vwc_idx === varCategory.cid
    );
    setWorkCategoryFiltered(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCategory]);

  //직무 선택시
  const onSelectWorkCategoryHandler = (e, vwc_idx, vwc_name) => {
    e.preventDefault();
    //이전에 선택된 지역 --> 선택 취소
    clearWorkCategory();

    //선택된 지역 --> 선택 표시
    e.currentTarget.className = "category active";
    //직무명
    const newCategory = { ...categoryFormat };
    newCategory.cid = vwc_idx;
    newCategory.cname = vwc_name;

    setCategory(newCategory);
  };

  //세부직무
  const onSelectWorkCategoryDetailHandler = (e, vwd_idx, vwd_name) => {
    e.preventDefault();
    const findIndex = varWorkingList.findIndex(
      (element) => element.sid2 === vwd_idx
    );
    if (e.currentTarget.className === "categorydetail" && findIndex === -1) {
      e.currentTarget.className = "categorydetail active";
      //검색할 직무 배열추가
      const newWorking = { ...workingFormat }; //Object.assign({}, WorkingFormat);
      newWorking.sid1 = varCategory.cid;
      newWorking.sid2 = vwd_idx;
      newWorking.sname = varCategory.cname + ">" + vwd_name;
      setWorkingList(varWorkingList.concat(newWorking));
    } else {
      e.currentTarget.className = "categorydetail";
      setWorkingList(
        varWorkingList.filter((element) => element.sid2 !== vwd_idx)
      );
    }
  };

  const onRemoveWorkingHandler = (e, vwd_idx) => {
    e.preventDefault();
    setWorkingList(
      varWorkingList.filter((element) => element.sid2 !== vwd_idx)
    );
    clearWorkCategory();
    clearWorkCategoryDetail();
  };

  //지역선택시
  const onSelectRegionHandler = (e, vr_code) => {
    e.preventDefault();
    if (e.currentTarget.className === "region") {
      //미선택되어 있는 경우
      const newRegion = varRegionArray.filter(
        (element) => element.vr_code === vr_code
      );
      if (vr_code === 0) {
        //전체
        const regionBlock = document.querySelectorAll(".region");
        for (var i = 0; i < regionBlock.length; i++) {
          if (regionBlock[i].classList.contains("active")) {
            regionBlock[i].classList.remove("active");
          }
        }
        e.currentTarget.className = "region active";
        setRegionList(newRegion);
      } else {
        //지역
        var copyData = [...varRegionList];
        const finsIndex = copyData.findIndex(
          (element) => element.vr_code === 0
        );
        if (finsIndex >= 0) {
          copyData = copyData.filter((element) => element.vr_code !== 0);
          document.getElementById("region_0").classList.remove("active");
        }
        e.currentTarget.className = "region active";
        setRegionList(copyData.concat(newRegion));
      }
    } else {
      //선택되어 있는 경우
      e.currentTarget.className = "region";
      //검색할 지역 배열 제거
      setRegionList(
        varRegionList.filter((element) => element.vr_code !== vr_code)
      );
    }
  };
  //지역제외시
  const onRemoveRegionHandler = (e, vr_code) => {
    e.preventDefault();
    document.getElementById("region_" + vr_code).classList.remove("active");
    setRegionList(
      varRegionList.filter((element) => element.vr_code !== vr_code)
    );
  };

  //경력선택
  const onSelectCareerHandler = (e, idx, cname) => {
    e.preventDefault();
    if (e.currentTarget.className === "career") {
      e.currentTarget.className = "career active";
      //검색할 경력 배열추가
      const newCareer = { ...careerFormat }; //Object.assign({}, WorkingFormat);
      newCareer.sid = idx;
      newCareer.sname = cname;
      setCareerList(varCareerList.concat(newCareer));
    } else {
      e.currentTarget.className = "career";
      //검색할 지역 배열 제거
      setCareerList(varCareerList.filter((element) => element.sid !== idx));
    }
  };

  const onRemoveCareerHandler = (e, sid) => {
    e.preventDefault();
    document.getElementById("career_" + sid).classList.remove("active");
    setCareerList(varCareerList.filter((element) => element.sid !== sid));
  };

  //검색기준------------
  //정렬순서
  const onSelectSortModeHandler = (e) => {
    setSortMode(Number(e.currentTarget.value));
  };


  //페이지 표시목록수
  const onSelectPageSizeHandler = (e) => {
    setPageSize(Number(e.currentTarget.value));
  };

  //검색
  // const onSearchOptionHandler = (e) => {
  //   setSearchOption(e.currentTarget.value);
  // };
  const onSearchStringHandler = (e) => {
    setSearchString(e.currentTarget.value);
  };
  // 초기화
  const onInitializeOptions = useCallback(() => {
    clearTop(); // 상단부 active 해제
    clearWorkCategory(); // 직무 active 해제
    clearRegion(); // 지역 active 해제
    clearCareer(); // 경력 active 해제
    clearWorkCategoryDetail(); // 세부직무 active 해제
    setWorkingList([]); // 검색조건 직무 리스트 초기화
    setRegionList([]); // 검색조건 지역 리스트 초기화
    setCareerList([]); // 검색조건 경력 리스트 초기화
    setClicked({}); // setClicked 초기화
    setSearchString('');
    sessionStorage.removeItem("params");
    setTimeout(() => { setIsReset(true); }, 100);
    // eslint-disable-next-line
  }, [clearWorkCategory, setCareerList, setRegionList, setSearchString, setWorkingList])

  // 경력 직접 입력 클릭.
  const handleClickCareerDirect = () => {
    setCareerDirectShow(true);
  }
  // 경력 직접 입력.
  const handleChangeCareerDirect = (e) => {
    const { name, value } = e.target

    setCareerDirect((prev) => ({
      ...prev,
      [name]: String(value)
    }));
  }

  // 경력 직접 입력 추가 버튼 클릭
  const handleClickCareerDirectAddButton = () => {
    const chIndex = varCareerDirectList.findIndex((el) => el.start === careerDirect.careerstart && el.end === careerDirect.careerend);
    if (chIndex === -1) {
      setCareerDirectList((prev) => [...prev, { start: careerDirect.careerstart, end: careerDirect.careerend }]);
    }

    setCareerDirect({
      careerstart: '',
      careerend: ''
    });
  };


  // 경력 직접 입력 삭제 버튼 클릭
  const handleClickCareerDirectDelButton = (start, end) => {
    var tempList = [...varCareerDirectList];
    const chIndex = tempList.findIndex((el) => el.start === start && el.end === end);
    if (chIndex !== -1) {
      tempList.splice(chIndex, 1);
      setCareerDirectList(tempList);
      if (tempList.length === 0) {
        setCareerDirectShow(false);
      }
    }
  }

  // 채용정보 헤드헌터전용관 이동시 초기화
  useEffect(() => {
    onInitializeOptions()
  }, [onInitializeOptions, varHeadHunter, varEmployType]);

  useEffect(() => {
    if (isReset) {
      getEmploySearchLists().then(setIsReset(false));
    }
  }, [getEmploySearchLists, isReset]);

  const [btnSearchText, setText] = useState('상세검색 열기');
  useEffect(() => {
    $(".btn_search_open").click(function() {
      if($(".btn_search_open").hasClass("active")) {
        setText('상세검색 닫기');
        $(this).removeClass("active");
        // $(".myrecruit_detail_search").slideDown({
        //   start: function () {
        //     $(this).css({
        //       display: "flex"
        //     })
        //   }
        // });
        $(".myrecruit_detail_search").attr("style", "display: flex;");
      } else {
        setText('상세검색 열기');
        $(this).addClass("active");
        // $(".myrecruit_detail_search").slideUp();
        $(".myrecruit_detail_search").attr("style", "display: none;");
      }
    });
  }, []);


  return (
    <>
      <div className="sub_content_title mainRecruitBlue">
        <button type="button" className="btn_search_open active">
          <img className="view_pc" src={SearchIcon_pc} alt="" /><img className="view_m" src={SearchIcon_m} alt=""/>
          {btnSearchText}
        </button>
      </div>
      <section className="myrecruit_detail_search">
        <article className="detail_wrap">
          <h1 className="depth01" onClick={(e) => { onSelectTopHandler(e, "직무"); }} >
            직무
          </h1>
          <div className="col2" >
            <ul className="depth02 style_scroll" style={clicked.task === true ? { display: "" } : { display: "none" }}>
              {varWorkCategoryList.map((workCategory, index) => (
                <li key={"wc_" + index} onClick={(e) => { onSelectWorkCategoryHandler(e, workCategory.vwc_idx, workCategory.vwc_name); }} className="category" >
                  {workCategory.vwc_name}
                </li>
              ))}
            </ul>
            <ul className={varCategory.cname ? "depth02 style_scroll" : ""} style={varCategory !== "" || varCategory !== undefined ? { display: "" } : { display: "none" }}>
              {varWorkCategoryFiltered.map((workCategoryFiltered, index) => (
                <li className="categorydetail" key={"wd_" + index} onClick={(e) => onSelectWorkCategoryDetailHandler(e, workCategoryFiltered.vwd_idx, workCategoryFiltered.vwd_name)} >
                  {workCategoryFiltered.vwd_name}
                </li>
              ))}
            </ul>
          </div>
        </article>
        <article className="detail_wrap">
          <h1 className="depth01" onClick={(e) => onSelectTopHandler(e, "지역")} >
            지역
          </h1>
          <ul className="depth02 style_scroll" style={clicked.region === true ? { display: "" } : { display: "none" }}>
            {varRegionArray.map((regionItem, index) => (
              <li className="region" key={"regions_" + index} id={"region_" + regionItem.vr_code} onClick={(e) => onSelectRegionHandler(e, regionItem.vr_code)} >
                {regionItem.vr_name}
              </li>
            ))}
          </ul>
        </article>
        <article className="detail_wrap">
          <h1 className="depth01" onClick={(e) => onSelectTopHandler(e, "경력")} >
            경력
          </h1>
          <ul className="depth02 style_scroll " style={clicked.career === true ? { display: "" } : { display: "none" }}>
            {varCareerLists.map((careerList, index) => (
              <li className="career" key={"careers_" + index} id={"career_" + careerList.sid} onClick={(e) => onSelectCareerHandler(e, careerList.sid, careerList.sname)} >
                {careerList.sname}
              </li>
            ))}
            <li className={careerDirectShow ? "career active" : "career"} onClick={() => handleClickCareerDirect()} >
              직접입력 {careerDirectShow &&
                <>
                  <input className="careerstart" type="text" name="careerstart" value={careerDirect.careerstart} onChange={(e) => handleChangeCareerDirect(e)} />&nbsp;~&nbsp;<input className="careerstart" type="text" name="careerend" value={careerDirect.careerend} onChange={(e) => handleChangeCareerDirect(e)} />&nbsp;년<button style={{ cursor: "pointer" }} className="btn blue" onClick={() => handleClickCareerDirectAddButton()}>추가</button>
                </>
              }
            </li>
          </ul>
        </article>
        <article className="detail_wrap">
          <div className="criteria_wrap">
            <h1 className="label">검색조건 :</h1>
            {varWorkingList.map((working, index) => (
              <article key={"work_" + index} className="criteria">
                {working.sname}
                <button className="btn_criteria_delete" onClick={(e) => onRemoveWorkingHandler(e, working.sid2)} ></button>
              </article>
            ))}
            {varRegionList.map((regionItem, index) => (
              <article key={"region_" + index} className="criteria">
                {regionItem.vr_name}
                <button className="btn_criteria_delete" onClick={(e) => onRemoveRegionHandler(e, regionItem.vr_code)} ></button>
              </article>
            ))}
            {varCareerList.map((career, index) => (
              <article key={"career_" + index} className="criteria">
                {career.sname}
                <button className="btn_criteria_delete" onClick={(e) => onRemoveCareerHandler(e, career.sid)} ></button></article>
            ))}
            {varCareerDirectList.map((el, index) => (
              <article key={"career_" + index} className="criteria">
                {`${el.start}~${el.end}년`}
                <button className="btn_criteria_delete" onClick={() => handleClickCareerDirectDelButton(el.start, el.end)} ></button> </article>
            ))}
          </div>
          <div className="search_wrap">
            <div className="left_wrap">
              {/* <select
                className="style_select"
                value={varSearchOption}
                onChange={(e) => onSearchOptionHandler(e)}
              >
                <option value="">전체</option>
                <option value="em_title">공고명</option>
                <option value="mcm_name">회사명</option>
              </select> */}
              <article className="search_input_wrap">
                <input type="text" placeholder="검색어를 입력해주세요." value={varSearchString === undefined ? "" : varSearchString} onChange={(e) => onSearchStringHandler(e)}
                  onKeyDown={(e) => { if (e.key === 'Enter') getEmploySearchLists() }} />
                <button style={{ cursor: "pointer" }} className="btn_search" type="button" onClick={() => onSearchingHandler()}> <img className="view_pc" src={SearchIcon_pc} alt="" />
                  <img className="view_m" src={SearchIcon_m} alt="" />
                </button>
              </article>
              <button className="btn white recruit_top_search_reset" onClick={() => { onInitializeOptions(); }}><img src={resetInitImage} alt="" />초기화</button>
            </div>
            <div className="right_wrap">
              <select value={varSortMode} onChange={(e) => onSelectSortModeHandler(e)} >
                <option value="0">최신 등록순</option>
                <option value="1">보상금 순</option>
              </select>
              <select value={varPageSize} onChange={(e) => onSelectPageSizeHandler(e)}  >
                <option value="20">20개</option>
                <option value="40">40개</option>
                <option value="60">60개</option>
                <option value="80">80개</option>
                <option value="100">100개</option>
              </select>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default RecruitTopSearch;
