/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CONFIG from "configs/configs";

import { resetNavSubMenuForHeding,CheckDuplicationObject } from '../Common/Common'
import Leave from "html/User/Leave";
import ProfileSub from "components/Company/ProfileSub";
import ProfileInfoSub from "components/Company/ProfileInfoSub";
import "css/CompanyMy.css";

function MyHeding1sub({ getSubItem }) {
  //const navigator = useNavigate();

  //로컬스토리지에서 읽기
  const user_idx = localStorage.getItem("mem_idx");
  const mcm_idx = localStorage.getItem("mcm_idx");
  //const mem_mode = localStorage.getItem("mem_mode");

  // const uNavigate = useNavigate();
  // const uri_path = window.location.pathname;
  // useEffect(() => {
  //   if (!mcm_idx || Number(mcm_idx)===0) {
  //     localStorage.setItem("re_url", uri_path);
  //     alert("로그인 후 이용 가능합니다.");
  //     uNavigate("/Login");
  //   }
  // },[mcm_idx, uri_path, uNavigate]);

  //정보 저장
  const [varCompanyInfo, setCompanyInfo] = useState({});
  //점검사항
  const [varAuth, setAuth] = useState({
    oldEmail:'',
    oldMobile:'',
    oldNickname:'',
    email: true,
    mobile: true,
    nickname: true
  });

  //회원탈퇴 팝업창
  const [isLeave, setIsLeave] = useState(false);

  //최초 실행부 - 회원정보 불러오기
  const getCompanyInfo = async () => {
    try {
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/companyInfo`, params).then((res) => {
          if (res.data.retvalue === 1 && res.data.results.length > 0) {
            const datas = res.data.results[0];
            setCompanyInfo(datas);
            setAuth({...varAuth,oldEmail:datas.mem_email,oldMobile:datas.mem_mobile,oldNickname:datas.mem_nickname});
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //최초실행부 - 메뉴설정 / 정보읽는 함수 호출
  useEffect(() => {
    getSubItem(2);
    resetNavSubMenuForHeding(2);
    getCompanyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //회원정보 저장하기
  const onUpdateHandler = async () => {
    try {
      // if (!varCompanyInfo.mcm_zipcode || !varCompanyInfo.mcm_address1 || !varCompanyInfo.mcm_address2) {
      //   alert("상세주소 및 우편번호를 입력해주세요.");
      // } else if (varCompanyInfo.mem_nickname === "") {
      //   alert("닉네임을 입력해주세요.");
      // } else 
      if (varCompanyInfo.mem_email === "") {
        alert("이메일을 입력해주세요.");
      } else if (varCompanyInfo.mem_mobile === "") {
        alert("휴대폰번호를 입력해주세요.");
      } else if (varAuth.nickname !== true && varAuth.oldNickname !== varCompanyInfo.mem_nickname) {
        alert("닉네임 중복확인을 해주세요.");
      } else if (varAuth.email !== true && varAuth.oldEmail !== varCompanyInfo.mem_email) {
        alert("이메일 중복확인을 해주세요.");
      } else if (varAuth.mobile !== true && varAuth.oldMobile !== varCompanyInfo.mem_mobile) {
        alert("휴대폰 인증을 해주세요.");
      } else {
          const params = new URLSearchParams();
          params.append("companyInfo", JSON.stringify(varCompanyInfo));
          await axios.post(`${CONFIG.SERVER_HOST}/companySubInfoUpdate`,params).then(async (res) => {
            alert(res.data.message);
          });
      }
    } catch (error) {
      alert("등록 실패");
      console.log(error);
    }
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.currentTarget;
    if (name === "mem_nickname") {
      if(varAuth.nickname) { 
        setAuth({ ...varAuth, nickname: false });
        CheckDuplicationObject('check_nickname',false);
      }
      if(varAuth.oldNickname === value) {
        setAuth({ ...varAuth, nickname: true });
        CheckDuplicationObject('check_nickname',true);
      } 
    }
    setCompanyInfo({...varCompanyInfo,[name]: value});
  };
      
  //회원탈퇴
  const onChangeModeHandler = () => {
    setIsLeave(!isLeave);
  };

  return isLeave ? (
    <Leave onSetIsLeave={onChangeModeHandler} />
  ) : (
    <div className="container page_com_myheding01 sub_account">
        <div>
            <section className="box box_content">
                <h3 className="my_title">정보관리</h3>
                <ProfileSub varCompanyInfo={varCompanyInfo} setCompanyInfo={setCompanyInfo}/>
                <ProfileInfoSub
                    varCompanyInfo={varCompanyInfo} setCompanyInfo={setCompanyInfo}
                    varAuth={varAuth} setAuth={setAuth} onChangeHandler={onChangeHandler}
                /> 
            </section>
            <div className="btn_bottom_area">
              <button onClick={()=>onUpdateHandler()} className="btn">수정완료</button>
              <button onClick={()=>onChangeModeHandler()} className="btn_leave">회원탈퇴</button>
            </div>
        </div>
    </div>
  );
}

export default MyHeding1sub;
