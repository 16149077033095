/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CONFIG from 'configs/configs'
import _ from 'lodash';
import { useNavigate, useParams } from "react-router-dom";
import QRCode from 'qrcode'
import $ from "jquery";

import { changeMobileFormat, ChangeDateString, numberToKorean2, ChangeMoneyUnitNoText, ChangeDateMonth, onDownloadFile, getMonthDiff, split_1, split_2, split_2_2, getMonthDiff2, monthDiff2  } from '../../components/Common/Common'
import qrImage from "../../img/sub/qr.jpg";
import MainLogoIcon from "img/common/logo.png";

import moment from 'moment';
import Loading from 'components/Common/Loading';
import CertificationCareerView from "./CertificationCareer";
import AddCareerView from "./AddCareer";

const resumeInitializ = {
	languageStudy: [],
	resume: [],
	resumeAdditionalCareer: [],
	resumeAwards: [],
	resumeCertificate: [],
	resumeCertificationCareer: [],
	resumeEducation: [],
	resumePerfection: [],
	resumePortfolio: [],
	employment_preference: []
};

const toDay2 = moment(new Date()).format('YYYY-MM-DD');


const ResumePdfView = () => {

	console.log("ResumePdfView");

	const navigate = useNavigate();
	const { re_code, type } = useParams();
	const [resumeData, setResumeData] = useState(resumeInitializ);
	const lastYear = new Date().getFullYear() - 1;
	const [varQRCode, setQRCode] = useState(qrImage);
	const [varLanguageLevel, setLanguageLevel] = useState([]);
	//고용형태 조회
	const [variableEmployType, setVariableEmployType] = useState([]);
	const [varWon, setWon] = useState();
	const [Load, setLoad] = useState(false);
	const [LoadText, setLoadText] = useState('다운로드 준비중입니다.');

	const [ced, setCED]  = useState({
		certification_experience_year :'',
		certification_experience_date :''
	});

	const [aed, setAED]  = useState({
		additional_career_year :'',
		additional_career_date :''
	});


	useEffect(() => {

		QRCode.toDataURL(`${CONFIG.PdfBaseUri}/${re_code}/qr`).then((data) => {
			setQRCode(data);
		});
		axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type`).then((res) => {
			setVariableEmployType(res.data);
		});
		axios.post(`${CONFIG.SERVER_HOST}/var_language_level`).then((res) => {
			setLanguageLevel(res.data);
		});
	}, []);

	// 이력서 데이터 가지고 오기.
	useEffect(() => {

		// 이력서 정보 설정.
		const funcSetResumeData = async () => {
			await axios.get(`${CONFIG.SERVER_HOST}/resume/0/${re_code}/detail/qr`, {})
				.then((response) => {
					const { data } = response;
					console.log(data);
					setResumeData(data);
				}).catch((err) => {
					const { message } = err.response.data;
					if (_.isEmpty(message)) {
						alert(`처리중 문제가 발생했습니다.`);
					} else {
						alert(message);
					}
					return;
				});

		}
		if (re_code !== undefined && re_code !== null) {
			funcSetResumeData();
		}

	}, [re_code]);

	useEffect(() => {
		if (resumeData.resume.re_income_hope > 0) {
			const won = numberToKorean2(resumeData.resume.re_income_hope);
			setWon(won.indexOf('원') === -1 ? `${!won ? 0 : won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원` : `${won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
		} else {
			setWon('-');
		}

	}, [resumeData]);


	//언어레벨 세팅 함수
	const getLanguageCode = (level) => {
		const LanguageLevel = varLanguageLevel.filter((language) => language.lal_code === Number(level));
		return LanguageLevel[0]?.lal_name;
	}

	const text = (re_employ_hope) => {
		const textList = variableEmployType.filter((el) => el.emt_idx === re_employ_hope);
		return !textList[0]?.emt_name ? '-' : textList[0]?.emt_name;
	}

	//! 이력서 사이즈를 위한 스크립트
	$(window).on('resize',function() {
		// $(".p_resume").attr("style", `--w: ${($("html").outerWidth() + 90)}px; --w2: ${$("html").outerWidth()}px`);
		$(".p_resume").attr("style", `--w: ${$("#resume_page").outerWidth()}px; --w2: ${$("#resume_page").outerWidth()}px`);
	}).trigger('resize');



	useEffect(()=>{
		console.log('00000');
		if(resumeData.resume){
			console.log('1111111');
			if(resumeData.resumeCertificationCareer.length > 0){
				console.log('2222222');
				if(!resumeData?.resume.certification_experience_year && !resumeData?.resume.certification_experience_date){
					monthDiff_1(resumeData.resumeCertificationCareer);
				}
			}
			if(resumeData.resumeAdditionalCareer.length > 0){
				if(!resumeData?.resume.additional_career_year && !resumeData?.resume.additional_career_date){
					console.log('33333333');
					monthDiff_2(resumeData.resumeAdditionalCareer);
				}
			}
			
		}
	}, [resumeData.resume]);
	

	const monthDiff_1 = (arr) => {
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rec_sdate !== null){
				const data1 = item.rec_sdate.split('-');
				const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        console.log('t1 : ', item.rec_sdate, item.rec_edate, t1);
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

    console.log(quotient+"."+remainder);
			setCED({
				certification_experience_year : quotient,
				certification_experience_date : remainder
			});
  };

	const monthDiff_2 = (arr) => {
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rac_sdate !== null){
				const data1 = item.rac_sdate.split('-');
				const data2 = item.rac_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rac_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        console.log('t1 : ', item.rac_sdate, item.rac_edate, t1);
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

    console.log(quotient+"."+remainder);
		setAED({
			additional_career_year : quotient,
			additional_career_date : remainder
		});

  };


	const ShowView =(certification_experience_year , certification_experience_date, highLookup)=>{
    console.log(certification_experience_year , certification_experience_date, highLookup);
    if(certification_experience_year > 0){
      return  <CertificationCareerView highLookup={highLookup} />;
    }else{
      if(certification_experience_date > 0){
        return  <CertificationCareerView highLookup={highLookup} />;
      }else{
        return <AddCareerView highLookup={highLookup} />;
      }
    }
  };


	return (
		<>
			{Load === true ? <Loading text={LoadText} /> : null}
			<div id="sub_wrap" className="page_myresume_view p_resume_style">

				<div id="resume_page" className="myresume_view center_wrap">
					<h1 className="logo" style={{marginLeft:'auto', marginRight:'0'}}>
						<img src={MainLogoIcon} alt="" />
						{type === 'web' ?
							<p className="qr"><img className="qr-image" src={varQRCode} alt="" /></p>
							:
							null
						}
					</h1>
					<section className="resume_title">
						<h2 className="tit_field">{resumeData.resume.re_title}</h2>
					</section>
					<section className="profile_area">
						{resumeData.resume.re_photo === 0 || resumeData.resume.re_ufile === undefined ?
							null
							:
							<article className="profile_img" >
								<img src={(`${CONFIG.SERVER_HOST}/resume/${resumeData.resume.re_ufile}`)} alt="이력서 대표사진" />
							</article>
						}

						{/* ▼▼▼ 작은 이미지 확인용 */}
						{/* <article className="profile_img" style={{backgroundImage:"url(https://picsum.photos/100/100/?image=58)"}}>
						</article> */}
						<div className="profile_area__txt_wrap">
							<p className="name">{resumeData.resume.mem_name}</p>
							<article className="info_area">
								<ul>
									<li>생년월일:&nbsp;{ChangeDateString(resumeData.resume.re_birthday)}</li>
									<li>이메일:&nbsp;{resumeData.resume.re_email}</li>
									<li>근로소득:&nbsp;[{resumeData.resume.re_income_lyear === null ? lastYear : resumeData.resume.re_income_lyear}]{
										resumeData.resume.re_income_last === 0 ? "-" : ChangeMoneyUnitNoText(resumeData.resume.re_income_last)}
									</li>
								</ul>
								<ul>
									<li>성별:&nbsp;{resumeData.resume.re_gender === 0 ? "남성" : "여성"}</li>
									<li>연락처:&nbsp;{changeMobileFormat(resumeData.resume.mem_mobile)}</li>
									<li>현재연봉:&nbsp;{resumeData.resume.re_income_this === 0 ? "-" : ChangeMoneyUnitNoText(resumeData.resume.re_income_this)}</li>
								</ul>
							</article>
							<p className="tip_field">※ 근로소득은 국세청 원천징수영수증 기준으로 인증된 금액입니다.</p>
						</div>
					</section>
					{resumeData.resumeEducation.length > 0 ?
						<section className="section sec_school has_no_red">
							<h3 className="title_area">학력</h3>
							<div className="sec_detail_wrap">
							{resumeData.resumeEducation.map((education, index) => (
								<article className="info_area" key={'edu_' + index}>
									<p className="title_field">{education?.reh_name} <span className="part_field">{education?.reh_major}  {education?.reh_level === 10 ? CONFIG.Master : education?.reh_level === 11 ? CONFIG.Doc : ''}</span></p>
									<p className="date_field">{education?.reh_state === -2 ? "" : ChangeDateMonth(education?.reh_sdate, 1)}{education?.reh_edate === null ? ""
										: " ~ " + ChangeDateMonth(education?.reh_edate, 1)}
										{education?.reh_state === -2 ? "" :
											((education?.reh_name === null || education?.reh_major === null) ? "" : education?.reh_state === 1 ? " (졸업)" : education?.reh_state === 2 ? " (졸업예정)" : education?.reh_state === 3 ? " (재학중)" : education?.reh_state === 4 ? " (중퇴)" : education?.reh_state === 5 ? " (수료중)" : education?.reh_state === 6 ? " (휴학)" : "")
										}
									</p>
								</article>
							))}
							</div>
						</section>
						: null}
					{resumeData.resumeCertificationCareer.length ?
						<section className="section sec_career">
							<p className="tip_field c_red">
								※ 인증 경력은 국민건강보험공단 자격득실확인서 기준으로 인증된 경력입니다.
							</p>
							<h3 className="title_area has_total">
								인증경력
								{/* {resumeData?.re_career_year ?
									<>
										<span className="c_red total_career_text">
											총 경력 : {split_1(resumeData?.re_career_year)}년{" "}{split_2_2(resumeData?.re_career_year, resumeData.resume.re_decimal_data1) === undefined ? "" : split_2_2(resumeData?.re_career_year, resumeData.resume.re_decimal_data1) + "개월"}
										</span>
									</>
									:
									null
								} */}
							{ced.certification_experience_year || ced.certification_experience_date ?
							<p className="align_end c_red total_career_text">
								총 경력 : {`${ced.certification_experience_year} 년 `}{`${ced.certification_experience_date}개월`}
							</p>
							:
							resumeData?.resume.certification_experience_year || resumeData?.resume.certification_experience_date ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {`${resumeData?.resume.certification_experience_year} 년 `}{`${resumeData?.resume.certification_experience_date}개월`}
                </p>
								:
								null
							}
							</h3>
							<div className="sec_detail_wrap">
								{resumeData.resumeCertificationCareer.length > 0 && resumeData.resumeCertificationCareer.map((career, index) => {
									const recDetail = [];
									let tmpLoop = [1, 2, 3, 4, 5];
									const tempCareer = Object.assign({}, career);
									tmpLoop.forEach(function (value) {
										if (!_.isEmpty(tempCareer[`rec_note${value}`])) {
											recDetail.push({
												note: tempCareer[`rec_note${value}`],
												detail: tempCareer[`rec_detail${value}`],
											});
										}
									});
									return (
										<article className="info_area" key={"career_" + index}>
											<p className="title_field">
												{career?.rec_company}
												<span className="part_field">
													{!career?.rec_department || career?.rec_department === 'null' ? '' : career?.rec_department} {!career?.rec_position || career?.rec_position === 'null' ? '' : career?.rec_position}
												</span>
											</p>
											<p className="date_field">
												<span className="left_wrap">
													{ChangeDateMonth(career?.rec_sdate, 1) + ' ~ ' + ChangeDateMonth(career?.rec_edate, 1)}
													{career?.rec_state === 1 ? " 재직중" : ""}
												</span>
												{career?.rec_sdate ?
													<span className="align_end">
														{getMonthDiff2(career?.rec_sdate, career?.rec_state === 1 ? toDay2:career?.rec_edate)}
													</span>
												:
													null
												}
											</p>
											{recDetail.map((elements, ii) => {
												return (elements.note === null || elements.note === '' ? null :
													<div key={"careerdes_" + index + "_" + ii} className="career_detail">
														<p className="subtitle_field text-lines">{elements.note}</p>
														{/* <p className="text">{elements.detail===null||elements.detail===''?'':'(업무 상세 설명)'+elements.detail}</p> */}
													</div>
												)
											})}
										</article>
									);
								})}
							</div>
						</section>
						: null}
					{resumeData.resumeAdditionalCareer.length ?
						<section className="section sec_career2">
							<h3 className="title_area has_total">
								추가경력
								{/* {resumeData?.re_careeradd_year ?
                  <span className="align_end c_red total_career_text">
                    총 경력 : {split_1(resumeData?.re_careeradd_year)}년{" "}{split_2_2(resumeData?.re_careeradd_year, resumeData.resume.re_decimal_data2) === undefined ? "" : split_2_2(resumeData?.re_careeradd_year, resumeData.resume.re_decimal_data2) + "개월"}
                  </span>
									:
									null
								} */}
							{aed.additional_career_year || aed.additional_career_date ?
								<p className="align_end c_red total_career_text">
									총 경력 : {`${aed.additional_career_year} 년 `}{`${aed.additional_career_date}개월`}
								</p>
							:
							resumeData?.resume.additional_career_year || resumeData?.resume.additional_career_date ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {`${resumeData?.resume.additional_career_year} 년 `}{`${resumeData?.resume.additional_career_date}개월`}
                </p>
								:
								null
							}
							</h3>
							<div className="sec_detail_wrap">
								{resumeData.resumeAdditionalCareer.map((career, index) => {
									const recDetail = [];
									let tmpLoop = [1, 2, 3, 4, 5];
									const tempCareer = Object.assign({}, career);
									tmpLoop.forEach(function (value) {
										if (!_.isEmpty(tempCareer[`rac_note${value}`])) {
											recDetail.push({
												note: tempCareer[`rac_note${value}`],
												detail: tempCareer[`rac_detail${value}`],
											});
										}
									});
									return (
										<article className="info_area" key={"careeradd_" + index}>
											<p className="title_field">{career?.rac_company}<span className="part_field">{career?.rac_department + ' ' + career?.rac_position}</span></p>
											<p className="date_field">
												<span className="left_wrap">
													{ChangeDateMonth(career?.rac_sdate, 1) + ' ~ ' + ChangeDateMonth(career?.rac_edate, 1)}
													{career?.rac_state === 1 ? " 재직중" : ""}
												</span>
												{career?.rac_sdate ?
													<span className="align_end">
														{getMonthDiff(career?.rac_sdate, career?.rac_state === 1 ? toDay2:career?.rac_edate)}
													</span>
												:
													null
												}
											</p>
											{recDetail.map((elements, ii) => {
												return (elements.note === null || elements.note === '' ? null :
													<div className="career_detail" key={"careeradddes_" + index + "_" + ii}>
														<p className="subtitle_field text-lines">{elements.note}</p>
														{/* <p className="text">{elements.detail===null||elements.detail===''?'':'(업무 상세 설명)'+elements.detail}</p> */}
													</div>
												)
											})}
										</article>
									)
								})}
							</div>
						</section>
						: null}
					{resumeData.languageStudy.length ?
						<section className="section has_no_red">
							<h3 className="title_area">어학</h3>
							<div className="sec_detail_wrap">
							{resumeData.languageStudy.map((study, index) => (
								<article className="info_area" key={'edu_' + index}>
									<p className="title_field">{study.language_name} <span className="part_field">{getLanguageCode(study.language_level)}</span></p>
								</article>
							))}
							</div>
						</section>
						: null}
					{resumeData.resumeCertificate.length ?
						<section className="section has_no_red">
							<h3 className="title_area">자격증</h3>
							<div className="sec_detail_wrap">
							{resumeData.resumeCertificate.map((element, index) => (
								<article className="info_area" key={'edu_' + index}>
									<p className="title_field">{element.rc_name} <span className="part_field">{element.rc_issuer}</span></p>
									<p className="date_field">{element?.rc_gdate === null ? "" : ChangeDateMonth(element?.rc_gdate, 1)}
									</p>
								</article>
							))}
							</div>
						</section>
						: null}
					{resumeData.resumeAwards.length ?
						<section className="section has_no_red">
							<h3 className="title_area">수상이력</h3>
							<div className="sec_detail_wrap">
							{resumeData.resumeAwards.map((element, index) => (
								<article className="info_area" key={'edu_' + index}>
									{console.log("11 ", element.ra_comment)}
									<p className="title_field">{element.ra_comment} <span className="part_field">{element.ra_issuer === "NULL" || element.ra_issuer === null ? "" : element.ra_issuer}</span></p>
									<p className="date_field">{element?.ra_gdate === null ? "" : ChangeDateMonth(element?.ra_gdate, 1)}
									</p>
								</article>
							))}
							</div>
						</section>
						: null}
					{resumeData.resume.re_career_description ?
						<section className="section">
							<h3 className="title_area">경력기술서</h3>
							<article className="info_area text-lines ql-editor">
								<div dangerouslySetInnerHTML={{ __html: resumeData.resume?.re_career_description }}></div>
							</article>
						</section>
						: null}
					{resumeData.resume.re_comment ?
						<section className="section">
							<h3 className="title_area">자기소개서</h3>
							<article className="info_area text-lines ql-editor">
								<div dangerouslySetInnerHTML={{ __html: resumeData.resume?.re_comment }}></div>
							</article>
						</section>
						: null}
					{resumeData.resumePortfolio.length || resumeData.resume.re_site ?
						<section className="section portfolio has_no_red">
							<h3 className="title_area">포트폴리오</h3>
							<div className="sec_detail_wrap">
								<article className="info_area">
									{resumeData.resumePortfolio.map((portfolio, index) => (
									<p key={"port_" + index} onClick={() => onDownloadFile('port_folio', portfolio.rcp_ufile)}>첨부파일: {portfolio.rcp_ufile}</p>
									))}
									<p>사이트 링크: <a style={{ fontSize: '15px', marginLeft: '10px', cursor: 'pointer' }}>{resumeData.resume.re_site}</a></p>
								</article>
							</div>
						</section>
						: null}
					{/* <section className="section work_condition has_no_red">
						<h3 className="title_area">근무희망조건</h3>
						<div className="sec_detail_wrap">
							<article className="info_area">
								<p><span className="title_field2">고용형태</span>: &nbsp;{text(resumeData.resume.re_employ_hope)}</p>
								<p><span className="title_field2">희망연봉</span>: &nbsp;{resumeData.resume.re_after_meet === 1 ? "면접후 결정" : varWon}</p>
								<p><span className="title_field2">희망 근무지</span>: &nbsp;{resumeData.resume?.re_place_hope ? resumeData.resume?.re_place_hope?.replaceAll(/,/g, ', ') : "-"}</p>
								<p><span className="title_field2">희망 직종</span>: &nbsp;{resumeData.resume?.re_task_hope ? resumeData.resume?.re_task_hope?.replaceAll(/,/g, ', ') : "-"} </p>
								<p><span className="title_field2">관심 기업</span>: &nbsp;{resumeData.resume?.re_company_hope ? resumeData.resume?.re_company_hope?.replaceAll(/,/g, ', ') : "-"}</p>
							</article>
						</div>
					</section> */}
					{resumeData.employment_preference?.length ?
						<section className="section has_no_red">
							<h3 className="title_area">취업우대사항</h3>
							<div className="sec_detail_wrap">
								{resumeData.employment_preference.map((element, index) => (
									<article className="info_area" key={'edu_' + index}>
										<p className="title_field"> {element.vcep_content} <span className="part_field">{element.content}</span></p>
									</article>
								))}
							</div>
						</section>
						: null}
				</div>
			</div>
			<br />
			<br />
		</>
	)
}

export default ResumePdfView