import React, { useEffect, useState } from "react";
import { useParams, Route, Routes, useNavigate } from 'react-router-dom';

import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import UserDetail from '../user/UserDetail';
import UserResume from '../user/UserResume';
import UserResumeDetail from '../user/UserResumeDetail';
import UserRecruitmentsupport from './UserRecruitmentsupport';
import UserProposalReceived from "./UserProposal_Received";
import UserRecommendAcquaintance from "./UserRecommend_Acquaintance";
import DetailMenu from "../inc/Detail_Menu";

function UserInfo({ history }) {
    const { user_idx } = useParams();
    const [varCategory, setCategory] = useState(1);
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    // 2022-12-22 컨설팅 관리 => 이력서 보기 이동 시 상단 탭 active 위해 추가
    useEffect(() => {
        if (window.location.pathname.includes("resume")) {
            setCategory(2);
        }
    }, [])

    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="users" />
            <Menu menuType="users" menu="freeuserList" />
            <div id="contents" className="ad_contents">
                <div className="tit">일반 회원 관리
                    <div className="path">
                        <img src={iconhome} alt="" />
                        HOME<img src={arrImage} alt="" />회원관리<img src={arrImage} alt="" />일반회원 관리
                    </div>
                </div>
                <div className="content">
                    {/* <div className="tit">일반회원 상세</div> */}
                    {/* 내부 메뉴 */}
                    <DetailMenu mode={0} user_idx={user_idx} varCategory={varCategory} setCategory={setCategory} />
                    <Routes>
                        <Route path="/" element={<UserDetail user_idx={user_idx} />} />
                        <Route path="resume" element={<UserResume user_idx={user_idx} setCategory={setCategory} />} />
                        <Route path="resume_Detail/:re_idx" element={<UserResumeDetail user_idx={user_idx} />} />
                        <Route path="recruitment_support" element={<UserRecruitmentsupport user_idx={user_idx} setCategory={setCategory} />} />
                        <Route path="proposal_received" element={<UserProposalReceived user_idx={user_idx} setCategory={setCategory} />} />
                        <Route path="recommend_acquaintance" element={<UserRecommendAcquaintance user_idx={user_idx} setCategory={setCategory} />} />
                    </Routes>
                    {/* <div children={comp} /> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserInfo;