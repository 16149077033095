/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from "react-router-dom";
import axios from 'axios'
import CONFIG from '../../configs/configs'
import _ from 'lodash';
import profileImage from '../../img/sub/profile.gif';
import moment from 'moment';

import { ChangeDateString, ChangeDateMonth, chkEmpty, numberToKorean2, split_1, split_2, getMonthDiff, changeMobileFormat, ChangeMoneyUnitNoText, getMonthDiff2, monthDiff2 } from '../Common/Common'

const SearchViewC = ({ varUsingPass, varResumeIndex, setResumeIndex, ResetUsingPass, setShowPopupSuggest, setShowPopupCandidate, onCallChating, gotoLastListPage, varTabPage, varSuggestionAllowed, setSuggestionAllowed, setSugEmIndex }) => {
	const { state } = useLocation();
	const { re_idx } = useParams();
	const lastYear = new Date().getFullYear() - 1;
	const user_idx = localStorage.getItem('mem_idx');
	const mcm_idx = localStorage.getItem('mcm_idx');
	const mem_idx = localStorage.getItem('mem_idx');
	//const resumeIndex = localStorage.getItem('resumeIndex');
	const imgPhotoFolder = `${CONFIG.SERVER_HOST}/resume/`; //서버의 회원사진 저장 폴더
	//서버로부터 읽어오는 정보
	const [varResumeInfo, setResumeInfo] = useState({}); // 개인정보
	const [varResumeEducations, setResumeEducations] = useState([]); // 학력
	const [varResumeCareers, setResumeCareers] = useState([]); // 인증경력
	const [varResumeAddCareers, setResumeAddCareers] = useState([]); // 추가경력
	const [varResumePortfolios, setResumePortfolios] = useState([]); // 포트폴리오
	// const [varResumeSelfDoc,setResumeSelfDoc] = useState('');
	const [varResumeLanguage, setResumeLanguage] = useState([]); // 어학
	const [varResumeCertificate, setResumeCertificate] = useState([]); // 자격증
	const [varResumeAward, setResumeAward] = useState([]); // 수상 이력
	const [employmentPreference, setEmploymentPreference] = useState([]); // 취업우대사항

	const [varApplyEmploy, setApplyEmploy] = useState([]);
	const [varSuggestEmploy, setSuggestEmploy] = useState([]);
	const [varWon, setWon] = useState();
	const toDay2 = moment(new Date()).format('YYYY-MM-DD');

	//loading 유무
	const [varLoadingFinshed, setLoadingFinshed] = useState(1);


	//제안유무 2022-10-30 상위(ComResource.js) 컨포넌트로 뺌(팝업에서 제안 후 버튼 변경 때문에...) - KSR
	//const [varSuggestionAllowed, setSuggestionAllowed] = useState(-1);

	//관심인재등록여부
	const [varInterestAllowed, setInterestAllowed] = useState(0);

	//어학코드 state
	const [varLanguageLevel, setLanguageLevel] = useState([]);

	const [ced, setCED] = useState({
		certification_experience_year: '',
		certification_experience_date: ''
	});

	const [aed, setAED] = useState({
		additional_career_year: '',
		additional_career_date: ''
	});

	useEffect(() => {
		axios.post(`${CONFIG.SERVER_HOST}/var_language_level`).then((res) => {
			setLanguageLevel(res.data);
		});
	}, []);

	useEffect(() => {
		setResumeIndex(re_idx);
		setSugEmIndex(0);//2022-11-14 인재DB > 후보자관리 갔다왔을 경우 때문에 본 화면에서는 무조건 초기화 필요 - KSR
	}, [re_idx, setResumeIndex, setSugEmIndex]);

	/*2022-10-30 KSR
	useEffect(() => {
		if (location !== null && location.state !== null) {
			console.log('location', location.state.re_idx);
			setResumeIndex(Number(re_idx));
			ResetUsingPass();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);
	*/

	//최초 모든 이력서 가져오기
	const getResumeInformation = async () => {
		try {
			const params = new URLSearchParams();
			params.append("re_idx", re_idx);
			params.append("mcm_idx", mcm_idx);
			params.append("mem_idx", mem_idx);

			// console.log("re_idx", re_idx);
			// console.log("mcm_idx", mcm_idx);

			//setResumeIndex(re_idx);//2022-10-30 KSR

			await axios.post(`${CONFIG.SERVER_HOST}/_get_resume_information`, params).then((res) => {
				console.log("_get_resume_information", res.data);
				if (res.data.retvalue === 1) {
					//console.log("_get_resume_information", res.data);
					setResumeInfo(res.data.resumeinfo[0]);
					setResumeEducations(res.data.educations);
					setResumeCareers(res.data.careers);
					setResumeAddCareers(res.data.addcareers);
					setResumePortfolios(res.data.portfolios);
					setEmploymentPreference(res.data.employment_preference);

					//제안유무
					console.log(res.data.suggestInfo);
					if (res.data.suggestInfo > 0) {
						const suggestionData = res.data.suggestInfo;
						setSuggestionAllowed(suggestionData);
					} else {
						setSuggestionAllowed(-1);
					}
					//관심인재 등록여부
					if (res.data.interests.length > 0) {
						setInterestAllowed(1);
					}

					setApplyEmploy(res.data.applys);
					setSuggestEmploy(res.data.suggestions);
					setResumeLanguage(res.data.language);
					setResumeCertificate(res.data.certificate);
					setResumeAward(res.data.awards);

					//조회 디비 저장
					UpdateLookupResume();

				} else {
					setResumeInfo({});
					setResumeEducations([]);
					setResumeCareers([]);
					setResumeAddCareers([]);
					setResumePortfolios([]);
					// setResumeSelfDoc(''); 
					setApplyEmploy([]);
					setSuggestEmploy([]);
				}
				setLoadingFinshed(0);
			});
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		getResumeInformation();
		document.getElementById('sub_wrap').className = 'page_talent_search';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//기업-이력서 열람 기록 등록 및 이력서 열람수 증가
	const UpdateLookupResume = async () => {
		try {
			const params = new URLSearchParams();
			params.append("re_idx", varResumeIndex);
			params.append("mcm_idx", mcm_idx);
			params.append("user_idx", user_idx);
			await axios.post(`${CONFIG.SERVER_HOST}/_update_lookup_resume`, params).then((res) => {
				console.log(res.data.message);
			});
		} catch (error) {
			console.log(error);
		}
	}

	//포트폴리오 다운로드
	const onDwonloadFile = (fileName) => {

	}
	//우측메뉴-팝업띄우기----------
	//제안보내기 
	const onSendingSuggestion = (e) => {
		e.preventDefault();
		if (varUsingPass.mcc_sending <= 0) {
			window.alert('보유한 제안발송건수가 없습니다. 제안발송을 하시려면 이용권을 먼저 구입하십시오.'); return;
		}
		setShowPopupSuggest('popup active');
	}
	//후보자추가하기
	const onAddCandidate = (e) => {
		e.preventDefault();
		setShowPopupCandidate('popup active');
	}
	//관심인재등록하기 
	const onRegisterInterestTalent = (e) => {
		if (!window.confirm('관심인재로 등록합니까?')) {
			return;
		}
		try {
			const params = new URLSearchParams();
			params.append("mcm_idx", mcm_idx);
			params.append("user_idx", user_idx);
			params.append("re_idx", varResumeIndex);
			axios.post(`${CONFIG.SERVER_HOST}/_register_interest_talent`, params).then((res) => {
				window.alert(res.data.message);
			});
		} catch (error) {
			console.log(error);
		}
	}

	const showCareerDescription = (elements) => {
		//console.log('aaa');
		let descArray = [];
		for (var i = 1; i <= 5; i++) {
			if (elements['rec_note' + i] !== null && elements['rec_note' + i] !== "") {
				descArray.push(<><p key={'note_' + i} className="subtitle_field">{elements['rec_note' + i]}</p><p className="text">{elements['rec_detail' + i]}</p></>);
			}
		}
		return descArray;
	}

	//언어레벨 세팅 함수
	const getLanguageCode = (level) => {
		const LanguageLevel = varLanguageLevel.filter((language) => language.lal_code === Number(level));
		return LanguageLevel[0]?.lal_name;
	}

	useEffect(() => {
		if (varResumeInfo.re_income_hope > 0) {
			const won = numberToKorean2(varResumeInfo.re_income_hope);
			setWon(won.indexOf('원') === -1 ? `${!won ? 0 : won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원` : `${won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
		} else {
			setWon('-');
		}

	}, [varResumeInfo]);

	// 사이트 링크 클릭
	const handleSiteListClick = () => {
		window.open(`${varResumeInfo.re_site}`); //새탭에서 열기
	}


	useEffect(() => {
		console.log('00000');
		if (varResumeCareers.length > 0) {
			console.log('2222222');
			if (!varResumeInfo?.certification_experience_year && !varResumeInfo?.certification_experience_date) {
				monthDiff_1(varResumeCareers);
			}
		}
	}, [varResumeCareers]);


	useEffect(() => {
		console.log('00000');
		if (varResumeAddCareers > 0) {
			if (!varResumeInfo?.additional_career_year && !varResumeInfo?.additional_career_date) {
				console.log('33333333');
				monthDiff_2(varResumeAddCareers);
			}
		}
	}, [varResumeAddCareers]);


	const monthDiff_1 = (arr) => {
		let monthArr = [];
		let total = 0;
		arr.map(item => {
			if (item.rec_sdate !== null) {
				const data1 = item.rec_sdate.split('-');
				const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

				console.log('t1 : ', item.rec_sdate, item.rec_edate, t1);
				total += t1;
			}

		});
		const quotient = parseInt(total / 12); //몫
		const remainder = total % 12; //나머지
		const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
		monthArr.push(quotient, remainder)

		let strCareer = ``;
		if (Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
		if (Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

		console.log('quotient :', quotient + "." + remainder);
		setCED({
			certification_experience_year: quotient,
			certification_experience_date: remainder
		});
	};

	const monthDiff_2 = (arr) => {
		let monthArr = [];
		let total = 0;
		arr.map(item => {
			if (item.rac_sdate !== null) {
				const data1 = item.rac_sdate.split('-');
				const data2 = item.rac_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rac_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

				console.log('t1 : ', item.rac_sdate, item.rac_edate, t1);
				total += t1;
			}

		});
		const quotient = parseInt(total / 12); //몫
		const remainder = total % 12; //나머지
		const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
		monthArr.push(quotient, remainder)

		let strCareer = ``;
		if (Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
		if (Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

		console.log(quotient + "." + remainder);
		setAED({
			additional_career_year: quotient,
			additional_career_date: remainder
		});

	};
	console.log(' ced :', ced);
	return (
		varLoadingFinshed === 1 ? null :
			<div className="page_talent_view">
				<div className="container center_wrap">
					<div className="myresume_view">
						{/* <!--이력서 제목--> */}
						<section className="resume_title">
							<h2 className="title_field">{varResumeInfo?.re_title}</h2>
						</section>
						{/* <!--프로필--> */}
						<section className="profile_area">
							{varResumeInfo?.re_photo === 0 || varResumeInfo?.re_ufile === undefined ? <article className="profile_img"></article>
								: <img className="profile_img" src={`${imgPhotoFolder + varResumeInfo?.re_ufile}`} alt="이력서 대표사진" />
							}
							{/* // <article className="profile_img" style={{ backgroundImage: `url(${imgPhotoFolder + varResumeInfo?.mem_ufile})` }}></article> */}
							{/* <!--제안수락후--> */}
							<article className="info_area">
								<ul className="top">
									<li className="name_field" style={
										{ color: "black" }}>{varResumeInfo?.mem_name}</li>
									<li><span className="title_field">생년월일</span>
										{ChangeDateString(varResumeInfo?.re_birthday === null || varResumeInfo?.re_birthday === "" ? varResumeInfo?.mem_birthday : varResumeInfo?.re_birthday)}
									</li>
									<li><span className="title_field">성별</span>{varResumeInfo?.mem_gender === 0 ? "남성" : "여성"}</li>
									<li><span className="title_field">연락처</span>{changeMobileFormat(varResumeInfo?.mem_mobile)}</li>
									<li><span className="title_field">근로소득[{varResumeInfo.re_income_lyear === null ? lastYear : varResumeInfo.re_income_lyear}]</span>&nbsp;{varResumeInfo?.re_income_last === "0" || varResumeInfo?.re_income_last === 0 ? "-" : isNaN(varResumeInfo?.re_income_last) ? varResumeInfo?.re_income_last : ChangeMoneyUnitNoText(varResumeInfo?.re_income_last)}</li>
									<li><span className="title_field">현재연봉</span>&nbsp;{isNaN(varResumeInfo?.re_income_this) ? varResumeInfo?.re_income_this : varResumeInfo?.re_income_this === 0 || varResumeInfo?.re_income_this === "0" ? "-" : ChangeMoneyUnitNoText(varResumeInfo?.re_income_this)}</li>
									<li><span className="title_field">이메일</span>{varResumeInfo?.mem_email}</li>
								</ul>
							</article>
							<p className="tip_field">{varSuggestionAllowed === 2 ? '※ 근로소득은 국세청 원천징수영수증 기준으로 인증된 금액입니다.' : '※ 블라인드된 정보는 제안 수락 시 공개됩니다.'}</p>
						</section>
						{/* <!--인증경력--> */}
						{varResumeCareers.length > 0 ?
							<section className="section sec_career">
								<p className="tip_field">※ 인증 경력은 국민건강보험공단 자격득실확인서 기준으로 인증된 경력입니다. </p>
								{/* {varResumeInfo?.re_career_year ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {split_1(varResumeInfo?.re_career_year)}년{" "}{split_2(varResumeInfo?.re_career_year) === undefined ? "" : split_2(varResumeInfo?.re_career_year) + "개월"}
                </p>
								:
								null
							} */}
								{ced.certification_experience_year || ced.certification_experience_date ?
									<p className="align_end c_red total_career_text">
										총 경력 : {`${ced.certification_experience_year} 년 `}{`${ced.certification_experience_date}개월`}
									</p>
									:
									varResumeInfo?.certification_experience_year || varResumeInfo?.certification_experience_date ?
										<p className="align_end c_red total_career_text">
											총 경력 : {`${varResumeInfo?.certification_experience_year} 년 `}{`${varResumeInfo?.certification_experience_date}개월`}
										</p>
										:
										null
								}
								<h3 className="title_area">인증 경력</h3>
								{varResumeCareers.map((career, index) => {
									const recDetail = [];
									let tmpLoop = [1, 2, 3, 4, 5];
									const tempCareer = Object.assign({}, career);
									tmpLoop.forEach(function (value) {
										if (!_.isEmpty(tempCareer[`rec_note${value}`])) {
											recDetail.push({
												note: tempCareer[`rec_note${value}`],
												detail: tempCareer[`rec_detail${value}`],
											});
										}
									});
									return (
										<article className="info_area" key={'career_' + index}>
											<p className="title_field">{career.rec_company}
												<span className="part_field">{!chkEmpty(career.rec_department) ? '' : career.rec_department} {!chkEmpty(career.rec_position) ? '' : career.rec_position}</span></p>
											<p className="date_field">
												<span className="left_wrap">{ChangeDateMonth(career.rec_sdate, 1) + ' ~ ' + ChangeDateMonth(career.rec_edate, 1)}
													{career?.rec_state === 1 ? " (재직중)" : " (퇴사)"}
												</span>
												{career?.rec_sdate ?
													<span className="align_end">
														{getMonthDiff2(career?.rec_sdate, career?.rec_state === 1 ? toDay2 : career?.rec_edate)}
													</span>
													:
													null
												}
											</p>
											{recDetail.map((elements, ii) => {
												return (!chkEmpty(elements.note) ? null :
													<div key={"careerdes_" + index + "_" + ii}>
														<p className="subtitle_field text-lines">{elements.note}</p>
														{/* <p className="text">{elements.detail === null || elements.detail === '' ? '' : '(업무 상세 설명)' + elements.detail}</p> */}
													</div>
												)
											})}
										</article>
									);
								})}
							</section>
							:
							null


						}

						{/* <!--추가경력--> */}
						{varResumeAddCareers.length > 0 ?
							<section className="section">
								{/* {varResumeInfo?.re_careeradd_year ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {split_1(varResumeInfo?.re_careeradd_year)}년{" "}{split_2(varResumeInfo?.re_careeradd_year) === undefined ? "" : split_2(varResumeInfo?.re_careeradd_year) + "개월"}
                </p>
								:
								null
							} */}

								{aed.additional_career_year || aed.additional_career_date ?
									<p className="align_end c_red total_career_text">
										총 경력 : {`${aed.additional_career_year} 년 `}{`${aed.additional_career_date}개월`}
									</p>
									:
									varResumeInfo.additional_career_year || varResumeInfo.additional_career_date ?
										<p className="align_end c_red total_career_text">
											총 경력 : {`${varResumeInfo.additional_career_year} 년 `}{`${varResumeInfo.additional_career_date}개월`}
										</p>
										:
										null
								}
								<h3 className="title_area">추가 경력</h3>
								{varResumeAddCareers.map((career, index) => {
									const recDetail = [];
									let tmpLoop = [1, 2, 3, 4, 5];
									const tempCareer = Object.assign({}, career);
									tmpLoop.forEach(function (value) {
										if (!_.isEmpty(tempCareer[`rac_note${value}`])) {
											recDetail.push({
												note: tempCareer[`rac_note${value}`],
												detail: tempCareer[`rac_detail${value}`],
											});
										}
									});
									return (
										<article className="info_area" key={'careeradd_' + index}>
											<p className="title_field">{career.rac_company} <span className="part_field">{!chkEmpty(career.rac_department) ? "" : career.rac_department} {!chkEmpty(career.rac_position) ? "" : career.rac_position}</span></p>
											{career?.rac_sdate === null ? "" :
												(
													<p className="date_field">
														<span className="left_wrap">
															{ChangeDateMonth(career.rac_sdate, 1) + `${career?.rac_edate ? ` ~ ` : ``}` + ChangeDateMonth(career.rac_edate, 1)}
															{career?.rac_sdate === 1 ? "(재직중)" : "(퇴사)"}
														</span>
														{career?.rac_sdate ?
															<span className="align_end">
																{getMonthDiff(career?.rac_sdate, career?.rac_state === 1 ? toDay2 : career?.rac_edate)}
															</span>
															:
															null
														}
													</p>)}

											{recDetail.map((elements, ii) => {
												return (!chkEmpty(elements.note) ? null :
													<div key={"careerdes_" + index + "_" + ii}>
														<p className="subtitle_field text-lines">{elements.note}</p>
														{/* <p className="text">{elements.detail === null || elements.detail === '' ? '' : '(업무 상세 설명)' + elements.detail}</p> */}
													</div>
												)
											})}
										</article>
									)

								})}
							</section>
							:
							null

						}

						{/* <!--학력--> */}
						{varResumeEducations && varResumeEducations.length > 0 ?

							<section className="section school">
								<h3 className="title_area">학력</h3>
								{varResumeEducations.map((education, index) => (
									<article className="info_area" key={'school_' + index}>
										<p className="title_field">{education.reh_name} <span className="part_field">{education.reh_major}  {education?.reh_level === 10 ? CONFIG.Master : education?.reh_level === 11 ? CONFIG.Doc : ''}</span></p>
										<p className="date_field">{education?.reh_state === -2 ? "" : ChangeDateMonth(education?.reh_sdate, 1)}{education?.reh_edate === null ? ""
											: " ~ " + ChangeDateMonth(education?.reh_edate, 1)}
											{education?.reh_state === -2 ? "" :
												((education?.reh_name === null || education?.reh_major === null) ? "" : education?.reh_state === 1 ? " (졸업)" : education?.reh_state === 2 ? " (졸업예정)" : education?.reh_state === 3 ? " (재학중)" : education?.reh_state === 4 ? " (중퇴)" : education?.reh_state === 5 ? " (수료중)" : education?.reh_state === 6 ? " (휴학)" : "")
											}
										</p>

									</article>
								))}
							</section>
							:
							// <p>-----</p>
							null
						}

						{/* <!--어학--> */}
						{varResumeLanguage && varResumeLanguage.length ?
							<section className="section">
								<h3 className="title_area">어학</h3>
								{varResumeLanguage.map((study, index) => (
									<article className="info_area" key={'edu_' + index}>
										<p className="title_field"> {study.language_name}<span className="part_field">{getLanguageCode(study.language_level)}</span></p>
										<p className="date_field">
											{study?.ls_rdate === null ? "" : ChangeDateMonth(study?.ls_rdate, 1)}
										</p>
									</article>
								))}
							</section>
							:
							null

						}

						{/* <!--자격증--> */}
						{varResumeCertificate && varResumeCertificate.length ?
							<section className="section">
								<h3 className="title_area">자격증</h3>
								{varResumeCertificate.map((certify, index) => (
									<article className="info_area" key={'certify_' + index}>
										<p className="title_field">{certify.rc_name}<span className="part_field">{certify.rc_issuer}</span></p>
										<p className="date_field">
											{certify?.rc_gdate === null ? "" : ChangeDateMonth(certify?.rc_gdate, 1)}
										</p>
									</article>
								))}
							</section>
							:
							null

						}

						{/* <!--수상이력--> */}
						{varResumeAward && varResumeAward.length ?
							<section className="section">
								<h3 className="title_area">수상이력</h3>
								{varResumeAward.map((award, index) => (
									<article className="info_area" key={'award_' + index}>
										<p className="title_field"> {award.ra_comment}<span className="part_field">{award.ra_issuer}</span></p>
										<p className="date_field">{award?.ra_gdate === null ? "" : ChangeDateMonth(award?.ra_gdate, 1)}
										</p>
									</article>
								))}
							</section>
							:
							null

						}

						{/* <!--경력기술서--> */}
						{varResumeInfo?.re_career_description ?
							<section className="section">
								<h3 className="title_area">경력기술서</h3>
								<article className="info_area text-lines ql-editor"><div dangerouslySetInnerHTML={{ __html: varResumeInfo?.re_career_description }}></div></article>
							</section>
							:
							null

						}

						{/* <!--자기소개서--> */}
						{varResumeInfo?.re_comment ?
							<section className="section">
								<h3 className="title_area">자기소개서</h3>
								<article className="info_area text-lines ql-editor">
									<div dangerouslySetInnerHTML={{ __html: varResumeInfo?.re_comment }}></div></article>
							</section>
							:
							null
						}

						{/* <!--포트폴리오--> */}
						{varResumePortfolios && varResumePortfolios.length ?
							<section className="section">
								<h3 className="title_area">포트폴리오</h3>
								<article className="info_area">
									{varResumePortfolios.map((portfolio, index) => (
										<p key={"port_" + index} onClick={(e) => onDwonloadFile(portfolio.rcp_ufile)}>첨부파일 : {portfolio.rcp_ufile}</p>
									))}
									<p>사이트 링크 : <a onClick={() => handleSiteListClick()} style={{ fontSize: '15px', marginLeft: '10px', cursor: 'pointer' }}>{varResumeInfo?.re_site}</a></p>
								</article>
							</section>
							:
							null

						}

						{/* <!--근무희망조건--> */}
						<section className="section">
							<h3 className="title_area">근무희망조건</h3>
							<article className="info_area">
								<p><span className="title_field2">고용형태</span> : &nbsp;{varResumeInfo?.emt_name}</p>
								<p><span className="title_field2">희망연봉</span> : &nbsp;{varResumeInfo?.re_after_meet === 1 ? "면접후 결정" : varWon}</p>
								{/* <p><span className="title_field2">희망 근무지</span> : &nbsp;{varResumeInfo?.re_place_hope ? (varResumeInfo?.re_place_hope).replaceAll(/,/g, ', ') : "선택안함"}</p>
								<p><span className="title_field2">희망 직종</span> : &nbsp;{varResumeInfo?.re_task_hope ? (varResumeInfo?.re_task_hope).replaceAll(/>/g, ' > ').replaceAll(/,/g, ', ') : "선택안함"}</p>
								<p><span className="title_field2">관심 기업</span> : &nbsp;{varResumeInfo?.re_company_hope ? (varResumeInfo?.re_company_hope).replaceAll(/,/g, ', ') : "선택안함"} </p> */}
								<p><span className="title_field2">희망 근무지</span> : &nbsp;{varResumeInfo?.re_place_hope ? (varResumeInfo?.re_place_hope).replaceAll(/,/g, ', ') : "-"}</p>
								<p><span className="title_field2">희망 직종</span> : &nbsp;{varResumeInfo?.re_task_hope ? (varResumeInfo?.re_task_hope).replaceAll(/>/g, ' > ').replaceAll(/,/g, ', ') : "-"}</p>
								<p><span className="title_field2">관심 기업</span> : &nbsp;{varResumeInfo?.re_company_hope ? (varResumeInfo?.re_company_hope).replaceAll(/,/g, ', ') : "-"} </p>
							</article>
						</section>
						{/* <!--이직 희망 여부--> */}
						{/* <section className="section change_jobs">
							<p className="title_area">이직 희망 여부</p>
							<article className="input_area">{varResumeInfo?.re_move_hope === 0 ? '의향없음' : '의향있음'}</article>
						</section> */}
						{employmentPreference?.length ?
							<section className="section">
								<h3 className="title_area">취업우대사항</h3>
								{employmentPreference.map((element, index) => (
									<article className="info_area" key={'edu_' + index}>
										<p className="title_field"> {element.content} <span className="part_field">{element.vcep_content}</span></p>
									</article>
								))}
							</section>
							: null}
					</div>
					<aside className="aside">
						<button onClick={(e) => onSendingSuggestion(e)} className="btn">제안 보내기</button>

						{/* {varSuggestionAllowed === 2 ? <button onClick={(e) => onSendingSuggestion(e)} className="btn">제안 보내기</button>
							: varSuggestionAllowed === -1 ? <button onClick={(e) => onSendingSuggestion(e)} className="btn">제안 보내기</button>
								: varSuggestionAllowed === 0 ? <button className="btn" style={{ pointerEvents: "none", backgroundColor: "#eee" }}>제안 거절됨</button>
									: <button className="btn" style={{ pointerEvents: "none", backgroundColor: "#eee" }}>제안발송 후 대기중</button>} */}


						{varTabPage === 1 ? null : <button onClick={(e) => onAddCandidate(e)} className="btn white">후보자 추가하기</button>}
						{varTabPage === 2 ? null : <button onClick={(e) => onRegisterInterestTalent(e)} className="btn white">관심 인재등록</button>}
						<button onClick={(e) => onCallChating(varResumeInfo?.mem_idx, varResumeInfo.re_idx)} className="btn gray">1:1 채팅</button>
						{varApplyEmploy.length === 0 ? null :
							<div className="section">
								<h3 className="title_field">지원한 채용 공고</h3>
								<ul className="list_dot basics">
									{varApplyEmploy.map((applys, index) => (
										<li key={"apply_" + index}>
											<Link to={`/Company/ComRecruit/MainView/${applys.em_idx}/${applys.em_headhunter}`}>{applys.em_title}</Link>
										</li>
									))}
								</ul>
							</div>
						}
						{varSuggestEmploy.length === 0 ? null :
							<div className="section">
								<h3 className="title_field">받은 제안 이력</h3>
								<ul className="list_dot basics">
									{varSuggestEmploy.map((suggests, index) => (
										<li key={"suggest_" + index}>
											<Link to={`/Company/ComRecruit/MainView/${suggests.crs_em_idx}/${suggests.em_headhunter}`}>{suggests.crs_em_title}</Link>
										</li>
									))}
								</ul>
							</div>}
					</aside>
					<div className="btn_bottom_area center_wrap">
						<button onClick={() => gotoLastListPage(state)} className="btn line">목록으로</button>
					</div>
				</div>
			</div>
	)
}

export default SearchViewC
