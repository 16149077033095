import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//컴포넌트
import MyBookmarkTab from 'components/My/MyBookmarkTab';
import MyBookmarkList from 'components/My/MyBookmarkList';
import { resetNavSubMenu } from "../../components/Common/Common";

function MyHeding6({ getSubItem }) {
  const location = useLocation();
  const [varTabs,setTabs] = useState(0);
  const [varEmployList, setEmployList] = useState([]);

  const mem_mode = Number(localStorage.getItem('mem_mode'));

   //검색실행
   const [varExecuteSearch, setExecuteSearch] = useState(0);
   
  useEffect(() => {
    //console.log(varTabs);
    setEmployList([]);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[varTabs]);

  useEffect(() => {
    console.log(location);
    if(location !== null && location.state !== null && location.state.pmode !== null) {
      const pmode = location.state.pmode;
      setTabs(pmode);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  useEffect(() => {
    getSubItem(6);
    if(mem_mode=== 0){
      resetNavSubMenu(4,5);
    }else{
      resetNavSubMenu(2,5);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="container page_bookmark">
        <MyBookmarkTab varTabs={varTabs} setTabs={setTabs}/>
        <MyBookmarkList varTabs={varTabs} varEmployList={varEmployList} setEmployList={setEmployList} varExecuteSearch={varExecuteSearch} setExecuteSearch={setExecuteSearch} />
    </div>
  );
}

export default MyHeding6;