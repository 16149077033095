/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import { changeMobileFormat } from '../../../components/Common/Common'
import Paging from '../../../components/Layout/Paging';

function Sub_Account({ mcm_idx,mem_idx,setCategory }) {
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);
    
    const [varMemberlist, setMemberlist] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    useEffect( async() => {
        setCategory(3);
        const params = new URLSearchParams();
        params.append('mcm_idx', mcm_idx);
        axios.post(`${CONFIG.SERVER_HOST}/sub_account`,params).then((res) => {
          console.log(res.data)
          if(res.data.retvalue === 1) {
            const datas = res.data.results;
            setTotalCount(datas.length);
            setMemberlist(datas);
          }
        });
    }, []);

    //페이지별 표시 - 결제내역
    const getPageLists = () => {
      const startNum = (varPageNum-1)*varPageSize;
      const endNum = startNum + varPageSize;
      const copyData = varMemberlist.slice(startNum,endNum);
      setPagelist(copyData);
    }

    useEffect(() => {
      getPageLists();
    },[varPageNum,varMemberlist]);

    return (
        <React.Fragment>
            <p className="table_title" style={{marginTop:"20px"}}>- 서브계정 정보</p>
            <div className="main-box ">
                <table className="list">
                  <colgroup>
                    <col style={{width:"8%"}} />
                    <col style={{width:"11%"}} />
                    <col style={{width:"11%"}} />
                    <col style={{width:"11%"}} />
                    <col style={{width:"8%"}} />
                    <col style={{width:"8%"}} />
                    <col style={{width:"12%"}} />
                    <col style={{width:"12%"}} />
                    <col style={{width:"12%"}} />
                    <col style={{width:"7%"}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>아이디</th>
                      <th>이름</th>
                      <th>닉네임</th>
                      <th>부서</th>
                      <th>직책</th>
                      <th>휴대폰</th>
                      <th>최근 접속일</th>
                      <th>계정 추가일</th>
                      <th>관리</th>
                      </tr>
                  </thead>
                  <tbody>
                  {varPagelist.map((user, i) => (
                      <tr key={i}>
                          <td>{varTotalCount-(varPageNum-1)*varPageSize-i}</td>
                          <td className="ellipsis">{user.mem_id}</td>
                          <td className="ellipsis">{user.mem_name}</td>
                          <td className="ellipsis">{user.mcm_name}</td>
                          <td className="ellipsis">{user.cmm_department === null || user.cmm_department === "" ? "-":user.cmm_department}</td>    {/*부서*/}
                          <td className="ellipsis">{user.cmm_position === null || user.cmm_position === "" ? "-":user.cmm_position}</td>    {/*직책*/}
                          <td className="ellipsis">{changeMobileFormat(user.mem_mobile)}</td>
                          <td className="ellipsis">{user.mem_ldate===null?"":user.mem_ldate.substr(0,10)}</td>
                          <td className="ellipsis">{user.mem_rdate===null?"":user.mem_rdate.substr(0,10)}</td>
                          <td>
                              <Link className="btn line black" to={"../sub_acount_detail"} state={{user_idx:user.mem_idx}}>상세</Link>
                          </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
            </div>
            <div>
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </React.Fragment>

    )

}

export default Sub_Account;