//2022-12-27 WEM  DatePicker Custom 위해 컴포넌트 분리

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';


const CustomDatePicker = ({ Data, varStartDate, varEndDate, onChangeDateHandler }) => {

  const CustomInputComponent = (d) => {

    // 년-월 표시
    const date = new Date(d);
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}년 ${`0${monthIndex}`.slice(-2)}월`;
  };

  return (

    <>
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll"></div>
            <button type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Month">
              <span style={{ alignItems: "center" }} className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} >"Previous Month</span>
            </button>
            <div className="react-datepicker__current-month"> {CustomInputComponent(date)}</div>
            <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month">
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next" onClick={increaseMonth} disabled={nextMonthButtonDisabled} >"Next Month"</span>
            </button>
          </>
        )}
        id="startDate" className="datepicker" locale={ko} dateFormat="yyyy.MM.dd"
        selected={varStartDate} selectStart
        onChange={(date) => onChangeDateHandler(0, date)}
      />
      ~
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll"></div>
            <button type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Month">
              <span style={{ alignItems: "center" }} className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} >"Previous Month</span>
            </button>
            <div className="react-datepicker__current-month"> {CustomInputComponent(date)}</div>
            <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month">
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next" onClick={increaseMonth} disabled={nextMonthButtonDisabled} >"Next Month"</span>
            </button>
          </>
        )}
        id="endDate" className="datepicker" locale={ko} dateFormat="yyyy.MM.dd"
        selected={varEndDate} selectEnd minDate={varStartDate}
        onChange={(date) => onChangeDateHandler(1, date)} disabled={Data?.em_deadline ? true : false}
      />
    </>
  );
};

export default CustomDatePicker;