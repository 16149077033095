import { useEffect, useRef, useState } from "react";
import CONFIG from "../../configs/configs";
import axios from "axios";
import "css/CompanyMy.css";
//이미지

/*마이페이지(기업회원)>프로필이미지*/
function Profile({ varCompanyInfo, setCompanyInfo }) {
  const user_idx = localStorage.getItem("mem_idx");
  //이미지 선택관련
  const photoInput = useRef();
  const [varPreview, setPreview] = useState('');

  useEffect(() => {
    if (varCompanyInfo !== undefined && varCompanyInfo !== null) {
      if (varCompanyInfo.mem_photo === 1 && varCompanyInfo.mem_ufile !== undefined) {
        setPreview(`${CONFIG.SERVER_HOST}/user_photo/${varCompanyInfo.mem_ufile}`);
      } else {
        setPreview(`${CONFIG.SERVER_HOST}/user_photo/default_company.png`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCompanyInfo]);

  //이미지선택버튼 실행
  const handleClickThumbnail = () => {
    photoInput.current.click();
  };

  //이미지 화면에 표시
  const onChangeThumbnailHandler = (e) => {
    const reader = new FileReader();
    if (e.currentTarget.files[0]) {
      reader.readAsDataURL(e.currentTarget.files[0]);
      //서버로 전송
      uploadImageFile(e.currentTarget.files[0]);
    }
    reader.onloadend = () => {
      const previewImgUrl = reader.result;
      setPreview(previewImgUrl);
    };
  };

  //이미지 업로드/파일명 저장
  const uploadImageFile = (imageFile) => {
    console.log(imageFile);

    const formData = new FormData();
    formData.append("user_photo", imageFile);
    axios.post(`${CONFIG.SERVER_HOST}/user_photo`, formData).then((res) => {
      const newFile = res.data.fileName;
      console.log(newFile);
      if (newFile !== null) {
        console.log(newFile);
        setCompanyInfo({ ...varCompanyInfo, mem_photo: 1, mem_ufile: newFile });
        const param = new URLSearchParams();
        param.append("mem_idx", user_idx);
        param.append("filename", newFile);
        axios.post(`${CONFIG.SERVER_HOST}/company_user_img_update`, param).then((res) => {
          if(res.data.retvalue === 1){
            console.log(res.data.message);
          }else{
            alert(res.data.message);
          }
        });
      }
    });
  };

  return varCompanyInfo === null ? null : (
    <>
      <section className="left_wrap">
        <figure>
          <img src={varPreview} alt="preview" />
        </figure>
        <div className="wrap">
          <input type="file" accept={"image/*"} ref={photoInput} onChange={(e) => onChangeThumbnailHandler(e)} style={{ display: "none" }} />
          <button type="button" className="btn line black" onClick={(e) => handleClickThumbnail(e)} style={{ cursor: "pointer" }} >사진 등록</button>
          <p className="guide_text txt_red">
            이미지 크기:157 x 200px<br />(jpg, png만 가능)
          </p>
        </div>
      </section>
    </>
  );
}

export default Profile;
