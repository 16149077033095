import React, { useState } from "react";
import 'admin/css/layout.css';
import axios from "axios";
import CONFIG from "../../../configs/configs";
import { useNavigate } from "react-router-dom";


function AdminLogin() {
  const navigate = useNavigate();

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const onEmailHandler = (e) => {
    setEmail(e.currentTarget.value);
  };
  const onPasswordHanlder = (e) => {
    setPassword(e.currentTarget.value);
  };

  const handleLogin = () => {
    const params = new URLSearchParams();
    params.append("admin_id", Email);
    params.append("password", Password);

    axios.post(`${CONFIG.SERVER_HOST}/adminlogin`, params).then((res) => {
      console.log("adminlogin", res.data);
      if (res.data.retvalue === 1) {
        localStorage.setItem("id", Number(res.data.result[0].id));
        localStorage.setItem("adminlogin", true);
        localStorage.setItem("type", Number(res.data.type[0]?.type));
        console.log(res.data);
        if (res.data.type[0]?.type !== 0) {
          console.log('서브', res.data);
          const idx = res.data.type[0]?.id;
          const param = new URLSearchParams();
          console.log(idx);
          param.append("sub_idx", idx);

          axios.post(`${CONFIG.SERVER_HOST}/subaccountinfo`, param).then((res) => {
            if (res.data.retvalue === 1) {
              console.log('서브!!!!!!!');
              const data = res.data.results[0];
              console.log("data", data);
              console.log("data", data.preferences);

              localStorage.setItem("preferences", data.preferences);
              localStorage.setItem("member_management", data.member_management);
              localStorage.setItem("notice_management", data.notice_management);
              localStorage.setItem("board_management", data.board_management);
              localStorage.setItem("pass_management", data.pass_management);
              localStorage.setItem("search_management", data.search_management);
              localStorage.setItem("statistics", data.statistics);

              if (data.preferences === 1) {
                document.location.href = "/admin";
              } else if (data.member_management === 1) {
                document.location.href = "/admin/userlist";
              } else if (data.notice_management === 1) {
                document.location.href = "/admin/announcement_list";
              } else if (data.board_management === 1) {
                document.location.href = "/admin/boardlist";
              } else if (data.pass_management === 1) {
                document.location.href = "/admin/paymentlist";
              } else if (data.search_management === 1) {
                document.location.href = "/admin/employvariables";
              }
              //document.location.href = "/admin_main";
            }


          });

        } else {
          console.log('메인');
          //document.location.href = "/admin";
          if(localStorage.getItem("id")){
            // navigate(`/admin/${localStorage.getItem("id")}`);
            navigate(`/admin/dashboard`);
          }
          
        }
        // document.location.href = "/admin";
      } else {
        alert("정확한 정보를 입력해주세요");
      }
    });
  };

  return (
    <div className="login-wrap-box">
      <div className="login-wrap">
        <p>헤딩 Management System</p>
        <b>관리자 로그인</b>
        <span>
          사이트 관리자 페이지로 접속하기 위한 로그인 페이지입니다.
          <br />
          아이디와 비밀번호를 입력하여 주시기기 바랍니다.
        </span>
        <div className="login-form-box">
          <input
            type="Text"
            value={Email}
            name="Email"
            onChange={onEmailHandler}
          />
          <input
            type="password"
            value={Password}
            name="Password"
            onChange={onPasswordHanlder}
            className="ml-none"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogin(e)
              }
            }}
          />
          <input type="button" value="로그인" onClick={handleLogin} />
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
