/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import CONFIG from '../../../configs/configs'

import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'
import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

import { realThousandPoint,removeThousandPoint } from '../../../components/Common/Common'

const Reword = () => {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varApplicantReward, setApplicantReward] = useState([]);
  // const [varRecommendReward, setRecommendReward] = useState([]);
  // const today = new Date().toISOString().substr(0, 10).replace('T', '');

  // const [varApplicantAdd,setApplicantAdd] = useState(false);
  // const [varRecommendAdd,setRecommendAdd] = useState(false);
  // const [passAward, setPassAward] = useState()

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //보상금 정보 읽어오기
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/get_rewards`).then(res => {
      if(res.data.retvalue === 1) {

                // const employed = res.data.employed.sort(function(a, b)  {
        //   return b.vre_price - a.vre_price;
        // });

        // const recommend = res.data.recommend.sort(function(a, b)  {
        //   return b.vrr_price - a.vrr_price;
        // });
        setApplicantReward(res.data.employed[0]);
        // setRecommendReward(res.data.recommend);
      } else {
        alert(res.data.message);
      }
    })
  }, [])

  //합격자 보상금 삭제
  // const onDeleteApplicantHandler = idx => {
  //   if (!window.confirm('삭제하시겠습니까?')) {
  //     return false;
  //   }
  //   if (idx > 0) {
  //       const params = new URLSearchParams();
  //       params.append("vre_idx", idx);
  //       axios.post(`${CONFIG.SERVER_HOST}/reward_employed_d`,params).then(res => {
  //           alert('삭제되었습니다.');
  //       });
  //   } 
  //   setApplicantReward(varApplicantReward.filter(data => data.vre_idx !== idx));
  // }
   //합격자 보상금 수정
   const onUpdateApplicantHandler = idx => {
    const params = new URLSearchParams();
    params.append("options", JSON.stringify(varApplicantReward));
    axios.post(`${CONFIG.SERVER_HOST}/reward_employed_c`, params).then(res => {
      console.log(res.data)
      if (res.data.retvalue === 1) {
      }
      alert(res.data.message);
    })
  }

  //추천인 보상금 삭제
  // const onDeleteRecommendHandler = idx => {
  //   if (!window.confirm('삭제하시겠습니까?')) {
  //     return false;
  //   }
  //   if (idx > 0) {
  //       const params = new URLSearchParams();
  //       params.append("vrr_idx", idx);
  //       axios.post(`${CONFIG.SERVER_HOST}/reward_recommend_d`,{vrr_idx:idx}).then(res => {
  //           alert('삭제되었습니다.');
  //       });
  //   } 
  //   setRecommendReward(varRecommendReward.filter(data => data.vrr_idx !== idx));
  // }

  //합격자 보상금 추가
  // const AddApplicantReward = () => {
  //   if(varApplicantAdd) {
  //     alert('추가한 합격자 보상금을 먼저 저장하신 후에 다시 후보자 보상금을 진행하십시오.');
  //     return false;
  //   }
  //   const todo = {order:varApplicantReward.length+1,vre_idx:0,vre_price:0,vre_rdate:today};
  //   setApplicantReward(varApplicantReward.concat(todo));
  //   setApplicantAdd(true);
  // }

  //추천인 보상금 추가
  // const AddRecommendReward = () => {
  //   if(varRecommendAdd) {
  //     alert('추가한 추천인 보상금을 먼저 저장하신 후에 다시 추천자 보상금을 진행하십시오.');
  //     return false;
  //   }
  //   const todo = {order:varRecommendReward.length+1,vrr_idx:0,vrr_price:0,vrr_rdate:today};
  //   setRecommendReward(varRecommendReward.concat(todo));
  //   setRecommendAdd(true);
  // }
  //합격자 보상금 저장
  // const onSubmitApplicantHandler = () => {
  //   const finsIndex = varApplicantReward.findIndex(element => element.vre_idx === 0);
  //   if(finsIndex === -1) {
  //     alert('추가된 합격자 보상금이 없습니다.');
  //     return false;
  //   }
  //   console.log(finsIndex);
  //   const newData = varApplicantReward.filter(element => element.vre_idx === 0)[0];
  //   console.log(newData);
  //   const params = new URLSearchParams();
  //   params.append("options", JSON.stringify(newData));
  //   axios.post(`${CONFIG.SERVER_HOST}/reward_employed_c`, params).then(res => {
  //     if (res.data.retvalue === 1) {
  //       //배열 재저장 - 걔좌 고유번호 지정
  //       let copyData = [...varApplicantReward];
  //       copyData[finsIndex]={...copyData[finsIndex],vre_idx:res.data.vre_idx};
  //       setApplicantReward(copyData);    
  //       //계좌추가버튼 활성화
  //       setApplicantAdd(false);
  //     }
  //     alert(res.data.message);
  //   })
  // }
  //추천자 보상금 저장  
  // const onSubmitRecommendHandler = () => {
  //   const finsIndex = varRecommendReward.findIndex(element => element.vrr_idx === 0);
  //   if(finsIndex === -1) {
  //     alert('추가된 추천인 보상금이 없습니다.');
  //     return false;
  //   }
  //   console.log(finsIndex);
  //   const newData = varRecommendReward.filter(element => element.vrr_idx === 0)[0];
  //   const params = new URLSearchParams();
  //   params.append("options", JSON.stringify(newData));
  //   axios.post(`${CONFIG.SERVER_HOST}/reward_recommend_c`, params).then(res => {
  //     if (res.data.retvalue === 1) {
  //       //배열 재저장 - 걔좌 고유번호 지정
  //       let copyData = [...varRecommendReward];
  //       copyData[finsIndex]={...copyData[finsIndex],vrr_idx:res.data.vrr_idx};
  //       setRecommendReward(copyData);    
  //       //계좌추가버튼 활성화
  //       setRecommendAdd(false);
  //     }
  //     alert(res.data.message);
  //   })
  // }
  //합격자 입력값 저장
  const onChangeApplicantHandler = (e) => {
    const {name, value} = e.target;
    let values = removeThousandPoint(value);

    setApplicantReward({...varApplicantReward, [name]: values});
  }
  //추천인 입력값 저장
  // const onChangeRecommendHandler = (e,vrr_idx) => {
  //   if(vrr_idx > 0) {  
  //     return false;
  //   }
  //   //입력내용 읽기
  //   const {name, value} = e.currentTarget;
  //   //배열에서 추가계좌 배열 찾기
  //   const finsIndex = varRecommendReward.findIndex(element => element.vrr_idx === 0);
  //   let copyData = [...varRecommendReward];
  //   let values = removeThousandPoint(value);
  //   //입력값 변경
  //   copyData[finsIndex]={...copyData[finsIndex],[name]:values};
  //   //배열 제설정
  //   setRecommendReward(copyData);
  // }
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="compensation" />
      <div id="contents" className="ad_contents">
        <div className="tit">보상금 관리
          <div className="path">
            <img src={iconhome} alt=""/>
            HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt="" />운영관리<img src={arrImage} alt=""/>보상금 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">보상금 관리</div> */}
          <div className="main-box">
            <table className="write">
              <thead>
                <tr><th>합격자 보상금</th><td className="th"></td></tr>
              </thead>
              {/* <tbody>
              {varApplicantReward.map((data, i) => (
                <tr key={'applicant_'+data.vre_idx}>
                  <th>금액 {i+1}</th>
                  <td>
                    <input type="text" name="vre_price" 
                      value={realThousandPoint(data.vre_price)}
                      onChange={(e)=>onChangeApplicantHandler(e,data.vre_idx)}
                      // style={data.vre_idx>0?{pointerEvents:"none",backgroundColor:"#ddd",width:"150px",textAlign:"right"}:{width:"150px",textAlign:"right"}}
                      style={data.vre_idx>0?{width:"150px",textAlign:"right"}:{width:"150px",textAlign:"right"}}

                    />
                    <button  className="btn line black" style={{marginLeft:'10px'}} onClick={()=>onDeleteApplicantHandler(data.vre_idx)}>삭제</button>
                  </td>
                </tr>
              ))}
              </tbody> */}
              <tbody>
              
                <tr >
                  <th>금액 </th>
                  <td>
                    <input type="text" name="vre_price" 
                      value={realThousandPoint(Number(varApplicantReward.vre_price))}
                      onChange={(e)=>onChangeApplicantHandler(e)}
                      // style={data.vre_idx>0?{pointerEvents:"none",backgroundColor:"#ddd",width:"150px",textAlign:"right"}:{width:"150px",textAlign:"right"}}
                      style={{width:"150px",textAlign:"right"}}
                    
                    />
                    
                    {/* <button  className="btn line black" style={{marginLeft:'10px'}} onClick={()=>onDeleteApplicantHandler()}>삭제</button> */}
                    <button className="btn line black" style={{marginLeft:'10px'}} onClick={()=>onUpdateApplicantHandler()}>수정</button>
                  </td>
                </tr> 
              </tbody>
            </table>
          </div>
          {/* <div className="btn-center">
            <button className="btn" onClick={()=>AddApplicantReward()}>옵션추가</button>
            <button className="btn" onClick={()=>onSubmitApplicantHandler()}>저장</button>
          </div> */}
          {/* <div className="main-box" style={{ marginTop: '100px' }}>
            <table className="write">
              <thead>
                <tr><th>추천인 보상금</th><td className="th"></td></tr>
              </thead>
              <tbody>
              {varRecommendReward.map((data, i) => (
                <tr key={"recommend_"+data.vrr_idx}>
                  <th>금액 {i+1}</th>
                  <td>
                    <input type="text" name="vrr_price" 
                      value={realThousandPoint(data.vrr_price)}
                      onChange={(e)=>onChangeRecommendHandler(e,data.vrr_idx)}
                      style={data.vrr_idx>0?{pointerEvents:"none",backgroundColor:"#ddd",width:"150px",textAlign:"right"}:{width:"150px",textAlign:"right"}}
                    />
                    <button className="btn line black" style={{marginLeft:'10px'}} onClick={()=>onDeleteRecommendHandler(data.vrr_idx)}>삭제</button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className="btn-center">
            <button className="btn" onClick={() => AddRecommendReward()}>옵션추가</button>
            <button className="btn" onClick={()=>onSubmitRecommendHandler()}>저장</button>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  )
}
export default Reword
