/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import CONFIG from '../../../configs/configs';
import axios from 'axios';

import { changeMobileFormat } from '../../../components/Common/Common'
import Paging from '../../../components/Layout/Paging';

function Withdrawal_Member_List() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [startDate, setStartDate] = useState(""); //date picker 날짜
  const [endDate, setEndDate] = useState(""); //date picker 날짜
  const [varSearch, setSearch] = useState("none");
  const [varSearchInput, setSearchInput] = useState("")
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageNum, setPageNum] = useState(1);
  const [varPagelist, setPagelist] = useState([]);
  const [selectChange, setSelectChange] = useState(false);
  const [startPageNum, setStartPageNum] = useState(1);
  const [endPageNum, setEndPageNum] = useState(10);
  const [varPageSize, setPageSize] = useState(10);
  const [varReset, setReset] = useState(false);
  const [mem_edate, setMemedate] = useState(""); //date picker 날짜

  useEffect(() => {
    if (adminlogin === undefined || adminlogin === null || adminlogin === "false") {
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserlist();
  }, []);

  const getUserlist = async () => {

    console.log('varSearch', varSearch);
    const sendData = {
      'startDate': startDate,
      'endDate': endDate,
      'option': varSearch,
      'resume': varSearch.resume,
      'exposure': varSearch.exposure,
      'varSearchInput': varSearchInput,
      'pageSize': varPageSize,
      'startPageNum':startPageNum === 1 ? 0 : startPageNum,
      'endPageNum': endPageNum,
      'mem_edate': mem_edate
    }

    await axios.post(`${CONFIG.SERVER_HOST}/withdrawallist`, sendData).then((res) => {
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        const datas = res.data.results.map((data, i) => (
          {
            mem_idx: data.mem_idx,
            mem_id: data.mem_id,
            mem_mode: data.mem_mode,
            mem_email: data.mem_email,
            mem_name: data.mem_name,
            mem_nickname: data.mem_nickname,
            mem_approval: data.mem_approval,
            mem_hunter_type: data.mem_hunter_type,
            mem_ldate: data.mem_ldate,
            mem_rdate: data.mem_rdate,
            mem_edate: data.mem_edate,
            mem_resume: data.mem_resume,
            mem_mobile: data.mem_mobile
          }
        ));
        setSelectChange(false);
        setPagelist(datas);
        setReset(false);
      }
    });

  };

  // const handleType = (e) => {
  //   setSearch(e.currentTarget.value);
  // };
  // const handleInput = (e) => {
  //   setSearchInput(e.currentTarget.value);
  // };

  //초기화
  const handleSearchClear = () => {
    setStartDate("");
    setEndDate("");
    setSearch("");
    setSearchInput("");
    setMemedate("");
    setPageNum(1);
    setReset(true);
  };

  useEffect(() => {
    if (varReset) {
      getUserlist();
    }
  }, [varReset]);

  //검색
  const handleSearchExecute = () => {
    getUserlist();
    setPageNum(1);
  };

  const handlestartdate = (e) => {
    setStartDate(e.currentTarget.value);
  };

  const handleenddate = (e) => {
    setEndDate(e.currentTarget.value);
  };

  const setEdate = (e) => {
    setMemedate(e.target.value);
  }

  useEffect(() => {
    if (selectChange) {
      getUserlist();
      setPageNum(1);
    }
  }, [selectChange]);

  useEffect(() => {
    getUserlist();
  }, [startPageNum]);

  useEffect(() => {
    console.log(selectChange);
    if (selectChange === false) {
      setStartPageNum((varPageNum - 1) * varPageSize === 1 ? 10 : varPageSize * (varPageNum - 1));
      setEndPageNum((varPageNum - 1) * varPageSize === 1 ? varPageSize : varPageSize * varPageNum);
    }
  }, [varPageNum]);

  const settingPageSize = (e) => {
    const { value } = e.target;
    setPageSize(Number(value));
    setSelectChange(true);
  }
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="users" />
      <Menu menuType="users" menu="withdrawal_list" />
      <div id="contents" className="ad_contents">
        <div className="tit">탈퇴 회원 관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />회원관리<img src={arrImage} alt="" />회원목록<img src={arrImage} alt="" />탈퇴 회원 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <div className="search-box">
              {/* <p>검색건수 : {varTotalCount}
                <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                  <option value="10">10개</option>
                  <option value="30">30개</option>
                  <option value="50">50개</option>
                  <option value="100">100개</option>
                </select>
              </p>
              <br /> */}
              <div className="day-box">
                <p>기간검색</p>
                <input type="date" name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                ~
                <input type="date" name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
              </div>
              <div className="f-right">
                {/* <select style={{ width: "120px" }} name="search" value={varSearch} onChange={handleType}>
                  <option value="all">전체</option>
                  <option value="mem_id">아이디</option>
                  <option value="mem_mode">회원유형</option>
                </select>
                <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{ width: "270px" }} onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e) }} /> */}
                <p>탈퇴일자</p>
                <input type="date" name="mem_edate" style={{marginRight : '10px'}} value={mem_edate} onChange={setEdate} id="datePicker2" />
              </div>
              <div className="btn_area">
                <button onClick={handleSearchExecute} className="btn">검색</button>
                <button onClick={handleSearchClear} className="btn black" >초기화</button>
              </div>
            </div>
          </div>
          <div className="list_top">
            <p>검색건수 : {varTotalCount}
              <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                <option value="10">10개</option>
                <option value="30">30개</option>
                <option value="50">50개</option>
                <option value="100">100개</option>
              </select>
            </p>
          </div>
          <div className="main-box ">
            <table className="list">
              <colgroup>
                <col style={{ width: "6%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "8%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>닉네임</th>
                  <th>이름</th>
                  <th>회원유형</th>
                  <th>휴대폰</th>
                  <th>이메일</th>
                  <th>가입일</th>
                  <th>탈퇴일</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {varPagelist.map((user, i) => (
                  <tr key={i}>
                    <td>{varTotalCount - (varPageNum - 1) * varPageSize - i}</td>
                    <td className="ellipsis">{user.mem_nickname}</td>
                    <td className="ellipsis">{user.mem_name}</td>
                    <td>
                      {isNaN(user.mem_mode) ? user.mem_mode : user.mem_mode === 0 ? "일반" : user.mem_mode === 1 ? "기업" : user.mem_mode === 2 ? "헤드헌터" : "-"}
                    </td>
                    <td className="ellipsis">{changeMobileFormat(user.mem_mobile)}</td>
                    <td className="ellipsis">{user.mem_email}</td>
                    <td>{user.mem_rdate === null ? "" : user.mem_rdate.substr(0, 10)}</td>
                    <td>{user.mem_edate === null ? "" : user.mem_edate.substr(0, 10)}</td>
                    <td>
                      <Link
                        to={`/admin/withdrawal_member_detail/${user.mem_idx}/${user.mem_hunter_type + 2}`
                        }>
                        <button className="btn line black">상세</button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Withdrawal_Member_List;