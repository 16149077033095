import React, { useMemo, useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../css/React-Quill.css';

export const TalkWriteEditor = ({defaultValue, handleOnChange}) => {
  const QuillRef = useRef();
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }, { color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          [],
        ],
      },
    }),
    []
  );

  return (
    <ReactQuill
      ref={(element) => {
        if (element !== null) {
          QuillRef.current = element;
        }
      }}
      value={defaultValue}
      onChange={handleOnChange}
      modules={modules}
      theme="snow"
      placeholder="내용을 입력해주세요."
    />
  );
}