/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
/* import styled from 'styled-components';
const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 14.1%;
  }
`; */
//functions
import { ChangeSearchingDateFormat } from "../Common/Common";
//image
import plusImage from "../../img/sub/btn_plus.png";
import minusImage from "../../img/sub/btn_minus.png";
import deleteImage2 from "../../img/sub/btn_delete02.png";
//datepicker
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko'; // 한국어적용
registerLocale("ko", ko) // 한국어적용

function AddCareerHistory({ index, career, varInputs, setInputs, varPosition, setTotalAddWork, setCheckNoAddWork, resumeAddCareerList, setResumeAddCareerList }) {
  const [startDate, setStartDate] = useState(career?.rac_sdate === null || career?.rac_sdate === "" ? null : new Date(career?.rac_sdate));
  const [endDate, setEndDate] = useState(career?.rac_edate === null || career?.rac_edate === "" ? null : new Date(career?.rac_edate));
  // const [varState, setState] = useState(career?.rac_state);
  //경력조합체 
  // const [careerAddData, setCareerAddData] = useState(career);
  const [varInfoNumber, setInfoNumber] = useState(0); //eslint-disable-line no-unused-vars
  const [varInfoNumberList, setInfoNumberList] = useState([]); //eslint-disable-line no-unused-vars
  let z = 0;
  let x = 0;

  useEffect(() => {
    let newArray = [];
    career?.rac_descript.map((item) => {
      if (item.note !== null) {
        newArray.push(item.id);
      }
    });

    setInfoNumber(newArray.length);
    setInfoNumberList(newArray);
  }, [career]);

  //입력창 변경
  const onChangeHandler = (e) => {
    var { value, name } = e.target;
    setResumeAddCareerList(
      resumeAddCareerList.map(resume =>
        resume.idx === career?.idx ? {
          ...resume,
          [name]: name === 'rac_state' ? Number(value) : value
        } : resume
      ));
  }


  //입력창 - 주요업무 & 업무상세
  const onChangeNoteHandler = (e, idx) => {
    var { value, name } = e.target;

    const Array = career?.rac_descript;
    const findIndex = Array.findIndex(element => element.id === idx);
    let copyArray = [...Array];
    if (findIndex !== -1) {
      copyArray[findIndex] = { ...copyArray[findIndex], [name]: value };
    };

    setResumeAddCareerList(
      resumeAddCareerList.map(resume =>
        resume.idx === career.idx ? {
          ...resume,
          rac_descript: copyArray
        } : resume
      ));
  }

  //날짜변경
  const onChangeDate = (i, date) => {
    const ndate = ChangeSearchingDateFormat(date); //날짜 변경----configs.js
    if (i === 0) {
      setStartDate(date);
      setResumeAddCareerList(
        resumeAddCareerList.map(resume =>
          resume.idx === career?.idx ? {
            ...resume,
            rac_sdate: ndate
          } : resume
        ));
    } else {
      setEndDate(date);
      setResumeAddCareerList(
        resumeAddCareerList.map(resume =>
          resume.idx === career?.idx ? {
            ...resume,
            rac_edate: ndate
          } : resume
        ));
    }
  }


  const onAddWorkingHandler = () => {
    if (career.rac_descript.length > Number(varInfoNumber)) {
      z=0;
      x=0;

      const newArr1 = [];
      const newArr2 = [];

      career.rac_descript.map((item, i) => {
        if (item.note !== null) {
          newArr1.push({ id: z, note: item.note, detail: item.detail });
          z += 1;
        }
      });

      career.rac_descript.map((item, i) => {
        if (item.note === null) {
          if (x === 0) {
            newArr2.push({ id: z, note: '', detail: null });
          } else {
            newArr2.push({ id: z, note: item.note, detail: item.detail });
          }
          x += 1;
          z += 1;
        }
      });

      const newArr = newArr1.concat(newArr2).map((item, i)=>{
        return { id: i, note: item.note, detail: item.detail };
      });

      setInfoNumber(Number(varInfoNumber + 1));
      setInfoNumberList(newArr);
      setResumeAddCareerList(
        resumeAddCareerList.map(resume =>
          resume.idx === career.idx ? {
            ...resume,
            rac_descript: newArr
          } : resume
        ));
    }

    // if (career?.rac_descript.length > varInfoNumber) {
    //   const Array = varInfoNumberList;
    //   const findIndex = Array.findIndex(element => element === Number(varInfoNumber));
    //   if (findIndex === -1) {
    //     let newArray = [...varInfoNumberList, Number(varInfoNumber) === 0 ? 0 : varInfoNumber];
    //     setInfoNumber(Number(varInfoNumber + 1));
    //     setInfoNumberList(newArray);
    //     const Array = career?.rac_descript;
    //     const findIndex = Array.findIndex(element => element.id === varInfoNumber);
    //     let copyArray = [...Array];
    //     if (findIndex !== -1) {
    //       copyArray[findIndex] = { ...copyArray[findIndex], note: '', detail: '' };
    //     }
    //     setResumeAddCareerList(
    //       resumeAddCareerList.map(resume =>
    //         resume.idx === career.idx ? {
    //           ...resume,
    //           rac_descript: copyArray
    //         } : resume
    //       ));
    //   }
    // }
  }


  const onDeleteWorkingHandler = (idx) => {
    if(z > 0){
      z = 0;
    }

    const newArr1 = [];
    const newArr2 = [];


    console.log(z);

    career.rac_descript.map((item, i) => {
      if (item.id !== idx) {
        if (item.note !== null) {
          newArr1.push({ id: z, note: item.note, detail: null });
        }
        z += 1;
      }
    });

    career.rac_descript.map((item, i) => {
      if (item.id === idx) {
        newArr2.push({ id: z, note: null, detail: null });
        z += 1;
        
      }else{
        if (item.note === null) {
          newArr2.push({ id: z, note: null, detail: null });
          z += 1;
        }
      }
    });

    const newArr = newArr1.concat(newArr2).map((item, i)=>{
      return { id: i, note: item.note, detail: item.detail };
    });

    setResumeAddCareerList(
      resumeAddCareerList.map(resume =>
        resume.idx === career.idx ? {
          ...resume,
          rac_descript: newArr
        } : resume
      ));

  }


  //경력삭제
  const onRemoveHandler = (idx) => {
    if (!window.confirm('선택한 경력을 삭제합니까?')) return;
    const addCareers = resumeAddCareerList.filter(element => element.idx !== idx);

    setResumeAddCareerList(addCareers);
    if (addCareers.length === 0) {
      setCheckNoAddWork(true);
    }
  }


  const showList = career.rac_descript.map((el, idx) => {
    if (el.note !== null) {
      return (
        <>
          <div key={'careerad_' + idx} className="career-descriptions">
            {/* <input type="text" className={"input_subtitle" + (el.note ? ' bg_on' : '')} name="note" placeholder="주요 업무 내용을 입력해주세요."
              value={el.note === null ? "" : el.note} onChange={(e) => onChangeNoteHandler(e, idx)} /> */}
              <textarea
              className={"content" + (el.note ? ' bg_on' : '')} id="content" name="note" placeholder="주요 업무 내용을 입력해주세요."
              value={el.note === null ? "" : el.note}
              onChange={(e) => onChangeNoteHandler(e, el.id)}
            ></textarea>
          </div>
          <a className="btn_add" onClick={() => onDeleteWorkingHandler(el.id)}><img src={minusImage} alt="" />삭제하기</a>
        </>
      );
    }
  });

  return (
    <div className="input_area" key={"reca_" + index}>
      <button className="btn_del" onClick={() => onRemoveHandler(index)}><img src={deleteImage2} alt="" style={{ cursor: "pointer" }} /></button>
      <input type="text" className={"input_title" + (career?.rac_company ? ' bg_on' : '')} name="rac_company" placeholder="회사명" value={career.rac_company} onChange={(e) => onChangeHandler(e)} />
      <input type="text" className={"input_part" + (career?.rac_department ? ' bg_on' : '')} name="rac_department" placeholder="부서명" value={career.rac_department} onChange={(e) => onChangeHandler(e)} />
      <input type="text" className={"input_part2" + (career?.rac_position ? ' bg_on' : '')} name="rac_position" placeholder="직급명" value={career?.rac_position === 'null' || career?.rac_position === null ? '' : career?.rac_position} onChange={(e) => onChangeHandler(e)} />
      <DatePicker className="input_date datepicker" locale={ko} showMonthYearPicker /* withPortal */
        dateFormat="yyyy.MM" selected={startDate} selectStart onChange={(date) => onChangeDate(0, date)}
      />
      <DatePicker className="input_date datepicker" locale={ko} showMonthYearPicker minDate={startDate}
        dateFormat="yyyy.MM" selected={endDate} selectEnd onChange={(date) => onChangeDate(1, date)}
      />{/* </DatePickerStyled> */}
      <select className={"input_state" + (career.rac_state !== null ? ' bg_on' : '')} name="rac_state" value={career.rac_state} onChange={(e) => onChangeHandler(e)}>
        <option value="0">퇴사</option>
        <option value="1">재직</option>
      </select>
      <button className="btn_add" id="add_working" onClick={(e) => onAddWorkingHandler(e)}><img src={plusImage} alt="" />업무내용 추가하기</button>
      {showList}
    </div>
  );
}

export default AddCareerHistory;