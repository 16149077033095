/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CONFIG from "configs/configs";

//components
import MyOfferSearch from "./MyOfferSearch";
import Paging from "components/Layout/Paging";
import { ChangeMoneyUnit, em_titleLength } from "../Common/Common";

/*채용 지원 현황*/
function MyOfferList({
  setSelectOffer,
  onSetPopup,
  varReloading,
  setReloading,
  jobPosting,
  crsIdx,
  setPopup
}) {
  const user_idx = localStorage.getItem("mem_idx");
  //const cDate = new Date();
  const [suggestionNum, setSuggestionNum] = useState(0);
  const [acceptNum, setAcceptNum] = useState(0);
  const [refuseNum, setRefuseNum] = useState(0);

  const [varSearchData, setSearchData] = useState({
    sdate: null,
    edate: null,
    searchoption: "all",
    searchstring: "",
  });
  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //지원목록
  const [varOfferList, setOfferList] = useState([]);
  const [varFilteredOfferList, setFilteredOfferList] = useState([]);
  const [varPageOfferList, setPageOfferList] = useState([]);

  const readOfferList = () => {
    if (varReloading) {
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      axios
        .post(`${CONFIG.SERVER_HOST}/_get_received_suggestion`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            const datas = res.data.results;
            setTotalCount(datas.length);
            setOfferList(datas); //원본 - 전체
            setFilteredOfferList(datas); //필터링 배열
            setReloading(false);
            setSuggestionNum(res.data.allCount);
            setAcceptNum(res.data.acceptCount);
            setRefuseNum(res.data.refuseCount);
          }
        });
    }
  };

  //2023-04-22 제안 링크(카톡) 타고 왔을 때 자동 팝업
  useEffect(() => {
    if(crsIdx > 0){
      console.log("제안수락 팝업~");
      setSelectOffer(crsIdx);
      //jobPosting.current.crsMemIdx = crs_mem_idx;
      setPopup(true);
    }
  }, [crsIdx, setSelectOffer, setPopup]);

  useEffect(() => {
    readOfferList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varReloading]);

  //필터링- 검색버튼 클릭시
  const filteringOfferData = () => {
    var copyData = [...varOfferList];
    //지원일자
    if (varSearchData.sdate !== null) {
      copyData = copyData.filter(
        (element) => new Date(element.crs_rdate) >= new Date(varSearchData.sdate)
      );
    }
    if (varSearchData.edate !== null) {
      copyData = copyData.filter(
        (element) => new Date(element.crs_rdate) <= new Date(varSearchData.edate).setDate(new Date(varSearchData.edate).getDate
          () + 1)
      );
    }
    //검색부분
    if (varSearchData.searchstring !== "") {
      if (varSearchData.searchoption === "all") {
        copyData = copyData.filter((element) =>
          element.em_title.toLowerCase().includes(varSearchData.searchstring.toLowerCase()) || element.mcm_name.toLowerCase().includes(varSearchData.searchstring.toLowerCase()) || element.mem_name?.includes(varSearchData.searchstring) || element.mem_id.toLowerCase().includes(varSearchData.searchstring.toLowerCase())
        );
      } else if (varSearchData.searchoption === "em_title") {
        copyData = copyData.filter((element) =>
          element.em_title.toLowerCase().includes(varSearchData.searchstring.toLowerCase())
        );
      } else if (varSearchData.searchoption === "mcm_name") {
        copyData = copyData.filter((element) =>
          element.mcm_name.toLowerCase().includes(varSearchData.searchstring.toLowerCase())
        );
      } else if (varSearchData.searchoption === "mem_id") {
        copyData = copyData.filter((element) =>
          element.mem_id.toLowerCase().includes(varSearchData.searchstring.toLowerCase()) || element.mem_name.includes(varSearchData.searchstring)
        );
      } else {
        copyData = copyData.filter(
          (element) =>
            element.em_title.toLowerCase().includes(varSearchData.searchstring.toLowerCase()) || element.mcm_name.toLowerCase().includes(varSearchData.searchstring.toLowerCase()) || element.mem_id.toLowerCase().includes(varSearchData.searchstring.toLowerCase() || element.mem_name?.includes(varSearchData.searchstring))
        );
      }
    }
    //필터링된 전체 페이지
    //console.log(copyData);
    setTotalCount(copyData.length);
    setFilteredOfferList(copyData);
    setPageNum(1);
    //getDataPerPage(copyData);
  };
  useEffect(() => {
    filteringOfferData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSearchData, varOfferList]);

  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varFilteredOfferList.length === 0) {
      setPageOfferList([]);
      return;
    }
    var copyData = [...varFilteredOfferList];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    //console.log(copyData);
    setPageOfferList(copyData);
  };
  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varFilteredOfferList]);

  //상세보기/제안수락
  const onManageOfferHandler = (e, crs_idx, crs_mem_idx) => {
    e.preventDefault();
    setSelectOffer(crs_idx);
    jobPosting.current.crsMemIdx = crs_mem_idx;
    onSetPopup(e, true);
  };

  let copySuggestion = [...varOfferList]
  const accept = copySuggestion.filter((el) => el.crs_status === 2);
  const refuse = copySuggestion.filter((el) => el.crs_status === 0);

  const onExecuteSearchingHandler = (e) => {
    const { name } = e.target;
    e.preventDefault();

    if (name === "all") {
      setPageOfferList(varOfferList)
    } else if (name === "accept") {
      setPageOfferList(accept)
    } else if (name === "refuse") {
      setPageOfferList(refuse)
    }
  };

  // 세부직무만 분리 2023-01-03 세부직무 카테고리 전체와 나머지 구분
  const onlyDetailTasks = (tasks) => {
    let arr = []
    tasks.map((el) => {
      if (el.includes("전체")) {
        return arr.push(el.split(">")[0])
      } else {
        return arr.push(el.split(">")[1])
      }
    })
    return arr.join()
  }

  return (
    <>
      <section className="box box_content">
        <h3 className="my_title">제안 현황</h3>
        <ul className="state_wrap">
          <li className="state_box">
            <h4 className="title">받은 제안</h4>
            <p className="num"><button onClick={(e) => onExecuteSearchingHandler(e)} name="all" style={{ color: "#3d7df2", fontSize: "18px", cursor: "pointer" }}>{suggestionNum}</button></p>
          </li>
          <li className="state_box">
            <h4 className="title">수락</h4>
            <p className="num"><button onClick={(e) => onExecuteSearchingHandler(e)} name="accept" style={{ color: "#3d7df2", fontSize: "18px", cursor: "pointer" }}>{acceptNum}</button></p>
          </li>
          <li className="state_box">
            <h4 className="title">거절</h4>
            <p className="num" ><button onClick={(e) => onExecuteSearchingHandler(e)} name="refuse" style={{ color: "#d02928", fontSize: "18px", cursor: "pointer" }}>{refuseNum}</button></p>
          </li>
        </ul>
      </section>
      <MyOfferSearch
        varSearchData={varSearchData}
        setSearchData={setSearchData}
        filteringOfferData={filteringOfferData}
      />
      {/*pc리스트*/}
      <table className="table_list01">
        <colgroup>
          <col width="32%" />
          <col width="12%" />
          <col width="14%" />
          <col width="8%" />
          <col width="14%" />
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <tr>
            <th className="th_title">채용제목</th>
            <th>회사명</th>
            <th>직무</th>
            <th>보상금</th>
            <th>담당자</th>
            <th className="th_date">지원일자</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {varTotalCount !== 0 ? varPageOfferList?.map((offers, i) => (
            <tr key={"page_" + i}>
              <td className="td_title with_btn">
                {offers?.crs_em_idx === 0 ?
                  <a
                    onClick={(e) => onManageOfferHandler(e, offers?.crs_idx, offers?.crs_mem_idx)}
                  >
                    {offers?.crs_em_title ? offers?.crs_em_title : offers?.em_title}
                  </a>
                  :
                  <Link
                    to={`/MyRecruit/MainView/${offers.em_idx}/${offers.em_headhunter}`} state={{ em_idx: offers.em_idx }}
                  >
                    {offers?.crs_em_title ? offers?.crs_em_title : offers?.em_title}
                  </Link>
                }
              </td>
              <td className="td_info">{em_titleLength(offers?.mcm_name, 15, '...')}</td>
              <td className="td_info">{offers?.crs_em_tasks === "" ? "-" : offers?.crs_em_idx === 0 ? em_titleLength(offers?.crs_em_tasks, 10, "...") : em_titleLength(onlyDetailTasks(offers?.crs_em_tasks.split(",")), 10, "...")}</td>
              <td className="td_info">
                {offers?.crs_em_idx === 0 ? "-" : ChangeMoneyUnit(offers?.em_reward_applicant / 10000)}
              </td>
              <td className="td_info">
                {/*2022-11-19 수락 후에는 담당자 이름 - KSR */}
                {offers?.crs_status === 2 ? offers?.mem_name : offers?.mem_id}
              </td>
              <td className="td_info">{offers?.crs_rdate.substr(0, 10)}</td>
              <td className="td_info last">
                {offers?.crs_status === 2 ? (
                  <span>제안수락</span>
                ) : offers?.crs_status === 1 ? (
                  <button
                    onClick={(e) => onManageOfferHandler(e, offers?.crs_idx, offers?.crs_mem_idx)}
                    className="td_button last txt_blue"
                  >
                    상세보기
                  </button>
                ) : (
                  <span className="txt_red">제안거절</span>
                )}
              </td>
            </tr>
          ))
            :
            <tr>
              <td className="no_data" colSpan={7}>불러올 데이터가 없습니다.</td>
            </tr>
          }
        </tbody>
      </table>
      <Paging
        totalCount={varTotalCount}
        pageSize={varPageSize}
        curPage={varPageNum}
        setPage={setPageNum}
      />
    </>
  );
}

export default MyOfferList;
