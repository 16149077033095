import React, { useEffect } from 'react';

//컴포넌트
import HRWrite from 'components/My/HRWrite';
import { resetNavSubMenu } from "components/Common/Common";

function MyHeding11({ getSubItem }) {
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  useEffect(() => {
    getSubItem(10);
    if(mem_mode=== 0){
      resetNavSubMenu(4,10);
    }else{
      resetNavSubMenu(2,10);
    }
    // resetNavSubMenu(10,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container page_my_hr_pic">
      <HRWrite />
    </div>
  )
}

export default MyHeding11