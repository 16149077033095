import React from "react";
import { Routes, Route } from "react-router-dom";

import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import FindID from "./FindID";
import FindIDOK from "./FindIDOK";
import FindPW from "./FindPW";
import EditPW from "./EditPW";
import JoinNormalOK from "./JoinNormalOK";
import JoinCompanyOK from "./JoinCompanyOK";
import BannerBottom from "components/Banner/BannerBottom";

//css

const MemberNormal = () => {
  return (
    <>
      <Header />
      <div className="p_myinfo">
        <div id="SubWrap">
          <div className="CenterWrap">
            <Routes>
              <Route path="/FindID" element={<FindID />} />
              <Route path="/FindIDOK" element={<FindIDOK />} />
              <Route path="/FindPW" element={<FindPW />} />
              <Route path="/EditPW" element={<EditPW />} />
              <Route path="/JoinNormalOK" element={<JoinNormalOK />} />
              <Route path="/JoinCompanyOK/:mem_typ" element={<JoinCompanyOK />} />
            </Routes>
          </div>
        </div>
      </div>
      <BannerBottom />
      <Footer />
    </>
  );
};

export default MemberNormal;
