/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import CONFIG from "../../../configs/configs";
import axios from "axios";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";

import arrImage from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";

import "admin/css/board.css";

function BoardDetail() {
  const { board_idx } = useParams();
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  //심사상태--------------------------------------------------------------------------------------------
  const [varBoardDetailIdx, setVarBoardDetailIdx] = useState(0);
  const [varBoardDetailTitle, setVarBoardDetailTitle] = useState("");
  const [varBoardDetailRead, setVarBoardDetailRead] = useState("1");
  const [varBoardDetailComment, setVarBoardDetailComment] = useState("");
  //제목입력시
  const handleBoardDetailTitle = (e) => {
    setVarBoardDetailTitle(e.target.value);
  };
  //내용입력시
  const handleBoardDetailComment = (e) => {
    setVarBoardDetailComment(e.target.value);
  };
  //필독선택시
  const handleBoardDetailRead = (e) => {
    setVarBoardDetailRead(e.target.value);
  };

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  //최초에 실행
  useEffect(() => {
    setVarBoardDetailIdx(board_idx);
    if (board_idx > 0) {
      const params = new URLSearchParams();
      params.append("board_idx", board_idx);
      axios.post(`${CONFIG.SERVER_HOST}/board_detail`, params).then((res) => {
        if (res.data.retvalue === 1) {
          setVarBoardDetailTitle(res.data.results[0].board_title);
          setVarBoardDetailComment(res.data.results[0].board_comment);
          setVarBoardDetailRead(String(res.data.results[0].board_read));
        }
      });
    }
  }, []);

  //저장하기
  const updateBoardDetail = () => {
    const params = new URLSearchParams();
    params.append("board_idx", varBoardDetailIdx);
    params.append("board_title", varBoardDetailTitle);
    params.append("board_read", varBoardDetailRead);
    params.append("board_comment", varBoardDetailComment);
    axios.post(`${CONFIG.SERVER_HOST}/board_update`, params).then((res) => {
      if (res.data.retvalue === 1) document.location.href = "/admin/boardlist";
      else window.alert("정보 저장중에 오류가 발생했습니다.");
    });
  };

  //표시-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="boarddetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          공지사항 등록/수정
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arrImage} alt="" />
            게시판 관리
            <img src={arrImage} alt="" />
            공지사항 등록/수정
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="write">
              <tbody>
                <tr>
                  <th>제목</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varBoardDetailTitle}
                      onChange={handleBoardDetailTitle}
                    />
                  </td>
                </tr>
                <tr>
                  <th>필독쓰기</th>
                  <td className="write-radio">
                    <input
                      type="radio"
                      name="board_read"
                      value="1"
                      onChange={handleBoardDetailRead}
                      checked={varBoardDetailRead === "1"}
                    />
                    &nbsp;Y&nbsp;&nbsp;
                    <input
                      type="radio"
                      name="board_read"
                      value="0"
                      onChange={handleBoardDetailRead}
                      checked={varBoardDetailRead === "0"}
                    />
                    &nbsp;N
                  </td>
                </tr>
                <tr>
                  <th>내용</th>
                  <td className="write-textarea">
                    <textarea
                      value={varBoardDetailComment}
                      onChange={handleBoardDetailComment}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/boardlist" className="btn line">
                취소
              </Link>
              <button onClick={updateBoardDetail} className="btn">
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BoardDetail;
