import React from "react";
import FindIDOKC from "../../components/Member/FindIDOKC";
import { useLocation } from "react-router-dom";

// css
import "css/Common.css";

const FindIDOK = () => {
  // const getId = localStorage.getItem("found_id");

  const location = useLocation();
  console.log("state", location.state.found_id);
  // const [getId, setgetId] = useState(location && location.state.found_id);
  const getId = location.state.found_id;

  return <FindIDOKC getId={getId} />;
};

export default FindIDOK;
