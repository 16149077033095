//css
//components
//이미지


function HRTop() {
  return (
     <>
        <h3 className="my_title">HR 담당자 소개</h3>
        <p className="my_subtitle">소개해주신 기업의 인사담당자가 HEDING에 채용의뢰를 요청하여 계약 체결 시 소개해주신 헤딩 회원분께 10만원의 헤딩머니를 지급해드립니다.</p>
     </>
  );
}

export default HRTop;
