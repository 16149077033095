import React, { useState, useEffect } from "react";
import { useParams, Route,  Routes, useNavigate } from 'react-router-dom';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import HeadhunterFreeDtail from './Headhunter_Free_Dtail';
import HeadhuntetRecruitmentSupport from './Headhuntet_Recruitment_Support';
import HeadhunterSubAccount from './Headhunter_Sub_Account';
import HeadhunterSubDtail from './Headhunter_Sub_Dtail';
import HeadhunterApplicationManagement from './Headhunter_Application_Management';
import DetailMenu from "../inc/Detail_Menu";

function Headhunter_Info() {
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const { mem_idx, mode, mcm_idx } = useParams();
    const [varCategory, setCategory] = useState(1);
    
    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
      <React.Fragment>
        <Titlebar />
        <Tabbar tabType="users" />
        <Menu menuType="users" menu="headhunter_list" />
        <div id="contents" className="ad_contents">
            <div className="tit">헤드헌터 회원 관리
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>헤드헌터 회원 관리
                </div>
            </div>
            <div className="content">
                  {/* 내부 메뉴 */}
                  <DetailMenu mode={mode} user_idx={mcm_idx} mem_idx={mem_idx}  varCategory={varCategory} setCategory={setCategory} />
                  <Routes>
                      <Route path="/" element={<HeadhunterFreeDtail mem_idx={mem_idx} setCategory={setCategory} mode={mode} mcm_idx={mcm_idx}/>} />
                      <Route path="headhunter_sub_account" element={ <HeadhunterSubAccount
                          mem_idx={mem_idx} setCategory={setCategory} mode={mode} mcm_idx={mcm_idx} />} />
                      <Route path="headhunter_sub_dtail" element={ <HeadhunterSubDtail
                          mem_idx={mem_idx} setCategory={setCategory} mode={mode} mcm_idx={mcm_idx}/>} />
                      <Route path="headhunter_application_management" element={<HeadhunterApplicationManagement
                          mem_idx={mem_idx} setCategory={setCategory} mode={mode} mcm_idx={mcm_idx}/>} />
                      <Route path="headhuntet_recruitment_support" element={<HeadhuntetRecruitmentSupport
                          mem_idx={mem_idx} setCategory={setCategory} mode={mode} mcm_idx={mcm_idx}/>} />
                  </Routes>
            </div>
        </div>
      </React.Fragment>
    )

}

export default Headhunter_Info;