import React from "react";
import { TalkWriteEditor } from "html/Talk/TalkWriteEditor";

function Introduce({ resumeSelfDocument, setResumeSelfDocument, setPerfection, varPerfection }) {
    const varStrLength = resumeSelfDocument.length;
    //자기소개서 입력분---
    const onChangeHandler = ({ value }) => {
      setResumeSelfDocument(value);
      
      if(value.length > 10){
        if(varPerfection.selfdoc !== 1){
          const completed = varPerfection.completed +25;
          const slasses = 'diagram step' + parseInt(completed / 25);
  
          setPerfection({...varPerfection,
            selfdoc: 1,
            classes: slasses,
            completed: completed
          })
        }

      }else{
        if(varPerfection.selfdoc === 1){
          const completed = varPerfection.completed -25;
          const slasses = 'diagram step' + parseInt(completed / 25);
  
          setPerfection({...varPerfection,
            selfdoc: 0,
            classes: slasses,
            completed: completed
          })
        }
      }
    }

  return (
    <section className="section">
      <h3 className="title_area">자기소개서</h3>
      <form className="input_area">
          {/* <textarea rows="10" onChange={(e)=>{onChangeHandler(e)}} value={resumeSelfDocument} className={"textarea_h400"+(resumeSelfDocument ? ' bg_on' : '')} placeholder="자기소개를 입력해주세요."></textarea> */}
            <TalkWriteEditor
              defaultValue={resumeSelfDocument || ""}
              handleOnChange={(text) => onChangeHandler({value: text})}
            />
          <p className="textarea_tip">※ 5000 자 이내로 작성 해주세요.</p>
          <p className="textarea_tip type2">({varStrLength} / 5000)</p>
      </form>
  </section>
  );
}

export default Introduce;
