/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const AddCareer = ({ highLookup }) => {
  console.log('addddd!!!!', highLookup);


  const ShowView2 =(additional_career_year , additional_career_date, highLookup)=>{
    console.log(additional_career_year , additional_career_date);
    if(additional_career_year > 0){
      if(additional_career_date > 0){
        return `경력 ${highLookup?.additional_career_year}년 ${highLookup?.additional_career_date} 개월`;
      }else{
        return `경력 ${highLookup?.additional_career_year}년`;
      }
    }else{
      if(additional_career_date > 0){
        return `경력 ${highLookup?.additional_career_date} 개월`;
      }else{
        return `신입`;
      }
    }
  };

  return (
    <>
    {ShowView2( highLookup?.additional_career_year, highLookup?.additional_career_date, highLookup)}
      {/* {
        highLookup?.additional_career_year && highLookup?.additional_career_date ?
          <>
            {highLookup?.additional_career_year>0 ? `경력 ${highLookup?.additional_career_year}년 ` : ''}
            {highLookup?.additional_career_date>0 ? `${!highLookup?.additional_career_year ? '경력 ' : ''}${highLookup?.additional_career_date} 개월` : null}
          </>
          :
          <>
            신입
          </>
      } */}
    </>
  );
};

export default AddCareer;
