/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useSearchParams } from "react-router-dom";
import SearchLeft from './SearchLeft2'
import SearchC from './SearchC2'
import SerachResultC from './SearchResultC2'
import SearchLeftUsingPass from './SearchLeftUsingPass2';
import { resetNavSubMenu } from "../Common/Common"; 
import Loading from 'components/Common/Loading';

const SearchMain = ({ setTabPage, varUsingPass, varPageMode = 1, setShowPopupCandidate, onViewResumePage, setResumeIndex }) => {
  const uselocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [Load, setLoad] = useState(false);
  const [LoadText, setLoadText] = useState('데이터를 가져오는 중입니다.');
  const param1 = searchParams.get('param1'); /**검색어 */
  const param2 = searchParams.get('param2'); /**검색 옵션 */
  const param3 = searchParams.get('param3'); /**카테고리 리스트 */
  const param4 = searchParams.get('param4'); /**선택한 카테고리 리스트 */
  const param5 = searchParams.get('param5'); /**페이지 번호 */


  useEffect(() => {
    setTabPage(0);
    resetNavSubMenu(2,0);
    document.getElementById('sub_wrap').className = 'page_talent_search';
  },[setTabPage]);

  useEffect(() => {
    // 비동기 작업 시작
  
    // 정리 함수를 반환
    return () => {
      // 비동기 작업 취소 또는 정리 작업 수행
    };
  }, [setLoad]);

  return (
      <>
        <SearchLeftUsingPass varUsingPass={varUsingPass}/>
        <SearchLeft param1={param1} param3={param3} param4={param4}/>
        {/* <!--조회수 높은 인재--> */}
         {!param1 ?
         <>
          {Load === true ? <Loading text={LoadText} /> : null}
          <SearchC
            onViewResumePage={onViewResumePage} setLoad={setLoad}
          />
         </>
          :
          <>
            {Load === true ? <Loading text={LoadText} /> : null}
            <SerachResultC
              setShowPopupCandidate={setShowPopupCandidate}
              onViewResumePage={onViewResumePage}
              searchCategoryParams={param2}
              searchString={param1}
              setResumeIndex={setResumeIndex}
              param3={param3}
              param4={param4}
              param5={param5}
              setLoad={setLoad}
              setLoadText={setLoadText}
            />
          </>
        }

      </>
  )
}

export default SearchMain
