/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from "react-router-dom";

import botn_info from '../../img/botn-info.png';
import user_icon from '../../img/uesr-icon.png';
import botn_logout from '../../img/botn-logout.png';

function Tabbar({tabType}){

  const type = Number(localStorage.getItem('type'));
  const handleLogout = () =>{
      localStorage.setItem('adminlogin', false);
      localStorage.removeItem("type");
      localStorage.removeItem("id");
      localStorage.removeItem("preferences");
      localStorage.removeItem("member_management");
      localStorage.removeItem("notice_management");
      localStorage.removeItem("board_management");
      localStorage.removeItem("pass_management");
      localStorage.removeItem("search_management");
      localStorage.removeItem("statistics");
      document.location.href = '/admin/login'
  }
  // console.log(type);
  return(
    <div id="head-wrap">
        <div className="center-wrap">
            <div className="f-rightt">
              <div className="info">
                  <img src={user_icon} alt=""/>Welcome <span>Admin</span>
              </div>
              {type === 0 ?
                <Link to="/admin">
                  <span className="btn-s"><img src={botn_info} alt=""/>관리자정보</span>
                </Link> 
                :
                ''
              }
                
                <a className="btn-s" target="_blank" href="/main"><img src={botn_info} alt=""/>웹사이트 이동</a>
                <button onClick={handleLogout} className="btn-s"><img src={botn_logout} alt=""/>로그아웃</button>
            </div>
        </div>
    </div>
  )
}

export default Tabbar;
