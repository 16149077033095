
/******************************************************************
 * POP - '친구가 보는 나' 요청하기
 *****************************************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import icon_repu_pop_write from "img/sub/repu_pop_write.png";

const PopAcqRepuRequest2 = ({ setEnablePop2 }) => {

	const cancel = () => {
		setEnablePop2(false);
	}

	return (
		<div className={"popup active"}>
			<div className="popup_wrap popup01 pop_repu">
				<section className="title_wrap">
					<h1>'친구가 보는 나' 요청하기</h1>
				</section>
				<section className="content_wrap">
					<article className="notice_area">
						<div className="icon"><img src={icon_repu_pop_write} alt="icon_pw" /></div>
						<p className="title_field">“내가 보는 나”를 먼저 진행하셔야</p>
						<p className="title_field">“친구가 보는 나”를 진행할 수 있습니다.</p>
					</article>
				</section>
				<section className="button_wrap">
					<div className="big_button_wrap two">
						<button className="btn white" onClick={()=> cancel()}>취소</button>
						<Link to={'../acquaintance-reputation/submit'} className="btn">내가 보는 나 시작하기</Link>
					</div>
				</section>
			</div>
		</div>
	)
}

export default PopAcqRepuRequest2