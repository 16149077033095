/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";

const Term = ({ varInputs,setInputs,onSubmitHandler }) => {
  // 팝업창 오픈
  const [ConditionOpen, setConditionOpen] = useState(false);
  const [PrivacyOpen, setPrivacyOpen] = useState(false);
  const [SelfAgreeOpen, setSelfAgreeOpen] = useState(false);
  const [PrivacyPeriodOpen, setPrivacyPeriodOpen] = useState(false);
  const [PushAgreeOpen, setPushAgreeOpen] = useState(false);
  //약관 저장 객체
  const [varConditionData, setConditionData] = useState({});
  //전체선택
  const [varIsAllChecked, setIsAllChecked] = useState(true);

  //약관들 가져오기
  const getConditions = async () => {
    console.log('getConditions');
    await axios.post(`${CONFIG.SERVER_HOST}/_get_conditions`).then((res) => {
      if (res.data.retvalue === 1) {
        setConditionData(res.data.results);
      }
    });
  }

  useEffect(() => {
    getConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //약관 전체버튼 클릭시
  const checkAllHandler = (e) => {
    console.log(e.currentTarget.checked);
    const bchecked = e.currentTarget.checked;
    setIsAllChecked(bchecked);
    if (bchecked) {
      setInputs({ ...varInputs, condition: 1, privacy: 1, selfagree: 1, pushagree: 1 });
    } else {
      setInputs({ ...varInputs, condition: 0, privacy: 0, selfagree: 0, pushagree: 0 });
    }
  };

  //약관 동의 처리
  const agreeHandler = (e) => {
    const { name, value } = e.target;   /* eslint-disable-line no-unused-vars */
    const bchecked = e.target.checked;
    if (bchecked) {
      setInputs({ ...varInputs, [name]: 1 });
    } else {
      setInputs({ ...varInputs, [name]: 0 });
    }

    //전체체크박스 조정
    const checkAgrees = document.querySelectorAll('.agree');
    let icount = 0;
    if(bchecked === false){
      setIsAllChecked(false);
    }else{
      for(let i=0; i < checkAgrees.length; i++) {
        if(checkAgrees[i].checked) icount++;
      }
      if(icount === checkAgrees.length) {
        setIsAllChecked(true);
      }
    }
  };

  //회뤈 탈퇴 동의
  const retireAgreeHandler = (e) => {
    const bchecked = e.target.checked;
    if (bchecked) {
      setInputs({ ...varInputs, expiration: 1 });
    } else {
      setInputs({ ...varInputs, expiration: 0 });
    }
  };
  return (
    <>
      <div className="agree_form">
        <div className="agree_all">
          <input type="checkbox" name="checkall" id="all_chk" className="chk" onChange={(e) => checkAllHandler(e)} checked={varIsAllChecked} />
          <label htmlFor="all_chk">전체동의</label>
        </div>
        <ul className="agree_list">
          <li>
            <input type="checkbox" name="condition" id="agree00" className="chk agree" checked={varInputs.condition} onChange={(e)=>agreeHandler(e)}/>
            <label htmlFor="agree00">사이트 이용약관 <span className="must_input">(필수)</span></label>
            <button className="button" onClick={()=>setConditionOpen(true)} style={{cursor:"pointer"}}>자세히</button>
          </li>
          <li>
            <input type="checkbox" name="privacy" id="agree01" className="chk agree" checked={varInputs.privacy} onChange={(e)=>agreeHandler(e)}/>
            <label htmlFor="agree01">개인정보취급방침 <span className="must_input">(필수)</span></label>
            <button className="button" onClick={()=>setPrivacyOpen(true)} style={{cursor:"pointer"}}>자세히</button>
          </li>
          <li>
            <input type="checkbox" name="selfagree" id="agree02" className="chk agree" checked={varInputs.selfagree} onChange={(e)=>agreeHandler(e)}/>
            <label htmlFor="agree02">본인확인 서비스 고유식별정보 처리 동의<span className="must_input">(필수)</span></label>
            <button className="button" onClick={()=>setSelfAgreeOpen(true)} style={{cursor:"pointer"}}>자세히</button>
          </li>
          <li>
            <input type="checkbox" name="pushagree" id="agree03" className="chk agree" checked={varInputs.pushagree} onChange={(e)=>agreeHandler(e)}/>
            <label htmlFor="agree03">PUSH 약관 동의<span className="must_input">(선택)</span></label>
            <button className="button" onClick={()=>setPushAgreeOpen(true)} style={{cursor:"pointer"}}>자세히</button>
          </li>
        </ul>
      </div>
      <div className="agree_form2">
        <div className="title_field">
          개인정보 유효기간<i className="tip_icon" onClick={()=>setPrivacyPeriodOpen(true)} style={{cursor:"pointer"}}>?</i>
        </div>
        <div className="input_field">
          <input type="checkbox" id="radio01" className="chk" defaultChecked={varInputs.expiration} onChange={(e)=>retireAgreeHandler(e)}/>
          <label htmlFor="radio01">회원 탈퇴시</label>
        </div>
      </div>
      {/* </div> */}
      <div className="btn_bottom_area">
        <button className="btn" type="submit" onClick={()=>onSubmitHandler()}>가입완료</button>
      </div>
      
      {/*사이트 이용약관 */}
      <section className={`popup${ConditionOpen?" active":""}`}>
        <article className="popup_wrap popup01 terms_pop_type">
          <div className="title_wrap">
            <h1>사이트 이용약관</h1>
          </div>
          <div className="content_wrap">
            <div dangerouslySetInnerHTML={{__html:varConditionData.conditions}} className="terms_area"></div>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button className="btn" onClick={()=>setConditionOpen(false)}>확인</button>
            </div>
            <div className="close_button_wrap">
              <button onClick={()=>setConditionOpen(false)}>닫기 X</button>
            </div>
          </div>
        </article>
      </section>

      {/*개인정보취급방침 */}
      <section className={`popup${PrivacyOpen?" active" : ""}`}>
        <article className="popup_wrap popup01 terms_pop_type">
          <div className="title_wrap">
            <h1>개인정보취급방침</h1>
          </div>
          <div className="content_wrap">
            <div dangerouslySetInnerHTML={{__html:varConditionData.privacy}} className="terms_area"></div>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button className="btn" onClick={() => setPrivacyOpen(false)}>확인</button>
            </div>
            <div className="close_button_wrap">
              <button onClick={() => setPrivacyOpen(false)}>닫기 X</button>
            </div>
          </div>
        </article>
      </section>

      {/*본인확인 서비스 처리 동의 */}
      <section className={`popup${SelfAgreeOpen? " active" : ""}`}>
        <article className="popup_wrap popup01 terms_pop_type">
          <div className="title_wrap">
            <h1>본인확인 서비스 고유식별정보 처리 동의</h1>
          </div>
          <div className="content_wrap">
            <div dangerouslySetInnerHTML={{__html:varConditionData.selfconfirm}} className="terms_area"></div>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button className="btn" onClick={() => setSelfAgreeOpen(false)}>확인</button>
            </div>
            <div className="close_button_wrap">
              <button onClick={() => setSelfAgreeOpen(false)}>닫기 X</button>
            </div>
          </div>
        </article>
      </section>

      {/*개인정보 유효기간 */}
      <section className={`popup${PrivacyPeriodOpen?" active" : ""}`}>
        <article className="popup_wrap popup01 pop_privacy_time terms_pop_type">
          <div className="title_wrap">
            <h1>개인정보 유효기간</h1>
          </div>
          <div className="content_wrap">
            <div dangerouslySetInnerHTML={{__html:varConditionData.personalterm}} className="terms_area"></div>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button className="btn" onClick={() => setPrivacyPeriodOpen(false)}>확인</button>
            </div>
            <div className="close_button_wrap">
              <button onClick={() => setPrivacyPeriodOpen(false)}>닫기 X</button>
            </div>
          </div>
        </article>
      </section>
      {/*PUSH 약관 동의 */}
      <section className={`popup${PushAgreeOpen?" active" : ""}`}>
        <article className="popup_wrap popup01 terms_pop_type">
          <div className="title_wrap">
            <h1>PUSH 약관 동의</h1>
          </div>
          <div className="content_wrap">
            <div dangerouslySetInnerHTML={{__html:varConditionData.push}} className="terms_area"></div>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button className="btn" onClick={() => setPushAgreeOpen(false)}>확인</button>
            </div>
            <div className="close_button_wrap">
              <button onClick={() => setPushAgreeOpen(false)}>닫기 X</button>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Term;
