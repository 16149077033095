import React, { useState, useEffect, useCallback } from 'react'

import '../../css/Reputation.css';
import axios from 'axios';
import CONFIG from '../../configs/configs';

import ColleRpuRblind from "components/Career/ColleRpuRblind";
//import PopAcqRepuR from "components/Career/PopAcqRepuR"; //POP '친구가 보는 나' 요청하기
import PopAcqRepuR2 from "components/Career/PopAcqRepuR2"; //POP '친구가 보는 나' 요청하기 - 수정

import Loading from 'components/Common/Loading';
import { onDownloadUrl } from '../Common/Common';
import moment from 'moment';
import ConvertApi from 'convertapi-js'; //PDF 변환 외부 API - KSR

const ColleRepuR = () => {
	const [enablePopRequR, setEnablePopRequR] = useState(false); //팝업사용 여부
	const [category, setCategory] = useState([]); // 분류: 관계, 업무스타일, ... 
	const [question, setQuestion] = useState([]); // 분류 내 질문, 질문 내 선택지
	const [colleagueAnswer, setColleagueAnswer] = useState(new Map()); // 동료 평판 결과
	const [colleagueList, setColleagueList] = useState([]); // 익명의 평판작성일자, 근무기간, 회사, 직급
	const [colleague, setColleague] = useState(null); //선택한 평판(셀박스에서...)
	const [answerCount, setAnswerCount] = useState(0);
	const [colleagueInfo, setColleagueInfo] = useState({ company: '', position: '' });
	const [buttonId, setButtonId] = useState('my');

	const [Load, setLoad] = useState(false);
  const [LoadText, setLoadText] = useState('다운로드 준비중입니다.');

  //2023-02-18 함께근무한 기간, 관계 답변한 내용으로 사용
  //const [corpPeriod, SetCorpPeriod] = useState("");
  //const [corpRelation, SetCorpRelation] = useState("");

	const myName = localStorage.getItem("mem_name");
	const myIdx = localStorage.getItem("mem_idx");

	const toDay = moment(new Date()).format('YYYY.MM_DD');

	const onChangeColleague = useCallback((e) => {
		const index = e.target.selectedIndex;
		setColleague(index);
	}, []);

	//평판 목록 가져오기 --------------------
	useEffect(() => {
		const tgField = buttonId === "your" ? "mem_idx" : "to_mem_idx";
		const params = new URLSearchParams([
			['tg_field', tgField],
			['mem_idx', myIdx]
		]);

		const getColleagueList = () => {
			axios.get(`${CONFIG.SERVER_HOST}/career-reputation/colleagues`, { params })
				.then((res, err) => {
					if (!err) {
						if (res.data.colleage_list?.length) {
							setColleagueList(res.data.colleage_list);
						} else {
							setColleagueList([]);
						}
					}
				}).catch((e) => {
					console.debug('Exception at getColleagueList  ', e);
				})
		};

		getColleagueList();
	}, [myIdx, buttonId]);

	useEffect(() => {
		if (colleagueList.length) {
			setColleague(0);
		}
	}, [colleagueList, setColleague]);

	useEffect(() => {
		//평판 응답지(답안) 가져오기 ---------------------------------------
		const getColleagueAnswer = () => {
			if (colleagueList?.length > 0 && colleague !== null && colleague !== undefined) {
				const params = new URLSearchParams([
					['mem_idx', colleagueList[colleague]?.mem_idx],
					['answer_no', colleagueList[colleague]?.answer_no],
				]);
				axios.get(`${CONFIG.SERVER_HOST}/career-reputation/answer`, { params })
					.then((res) => {
						if (res.data.answer_list?.length) {
							const result = res.data.answer_list;
							const map = new Map();
							result.forEach((item) => {
								map.set(item.cate_no + "" + item.repu_no, { ex_no: item.ex_no, remark: item.remark });
							});
							setColleagueAnswer(result);
						} else {
							console.log("답안지조회:결과없음");
						}
					}).catch((e) => console.debug('Exception at getColleagueAnswer  ', e));
			}
		};

		const getColleagueInfo = () => {
			if (colleagueList?.length > 0 && colleague !== null && colleague !== undefined) {
				const params = new URLSearchParams([
          ['buttonId', buttonId],
					['mem_idx', buttonId === 'my' ? colleagueList[colleague]?.mem_idx : colleagueList[colleague]?.to_mem_idx],
					['to_mem_idx', myIdx],
          ['answer_no', colleagueList[colleague]?.answer_no],
				]);
				//console.log(params)
        //2023-02-19 동료정보 답안지에서 가져오기
        /*
				axios.get(`${CONFIG.SERVER_HOST}/career-reputation/colleague-info`, { params })
					.then((res) => {
						if (res.data.result?.length) {
							setColleagueInfo((prev) => { return { ...prev, company: res.data.result[0].comp2, position: res.data.result[0].rec_position } })
						} else {
							setColleagueInfo((prev) => { return { ...prev, company: '', position: '' } })
						}
					}).catch((e) => console.debug('Error ', e));
        */
        axios.get(`${CONFIG.SERVER_HOST}/career-reputation/colleague-info2`, { params })
        .then((res) => {
          if (res.data.result?.length) {
            const terms = res.data.result[0].repu_a;
            const relation = res.data.result[1].ex_no === 5 ?  res.data.result[1].remark : res.data.result[1].repu_a;
            setColleagueInfo((prev) => { return { ...prev, terms: terms, relation: relation } })
          } else {
            setColleagueInfo((prev) => { return { ...prev, company: '', position: '' } })
          }
        }).catch((e) => console.debug('Error ', e));
			}
		};

		getColleagueAnswer();
		getColleagueInfo();
	}, [colleague, colleagueList, myIdx, buttonId]);

	//평판 문항(내용) 가져오기------------------------------------
	useEffect(() => {
		const getCategory = () => {
			axios.get(`${CONFIG.SERVER_HOST_REPUTATION}/reputation/RepuPeerCate`)
				.then((res) => setCategory(res.data))
				.catch((e) => console.debug('Error  ', e));
		};

		const getQuestion = () => {
			axios.get(`${CONFIG.SERVER_HOST_REPUTATION}/reputation/RepuPeerQsn`)
				.then((res) => setQuestion(res.data.data))
				.catch((e) => console.debug('Error  ', e));
		};
		if (colleagueList.length > 0) {
			getCategory();
			getQuestion();
		}
	}, [colleagueList]);

	// 타인 평판 여부
	useEffect(() => {
		const getMyAnswerCount = () => {
			const params = new URLSearchParams([
				['mem_idx', myIdx],
			]);
			axios.get(`${CONFIG.SERVER_HOST}/career-reputation/myanswer/count`, { params })
				.then((res, err) => {
					if (!err) {
						setAnswerCount(res.data.cnt)
					}
				}).catch((e) => {
					console.debug('Exception at getMyAnswerCount  ', e);
				})
		};

		if (myIdx) {
			getMyAnswerCount();
		}

	}, [myIdx]);

	//2024-06-01 동료평판(PDF) 다운로드 외부 API 사용 - KSR
	const handleClickRepuDnPdf = async () => {
		console.log('handleClickRepuDnPdf');
		const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
		const params = convertApi.createParams();
		const pdfUri = `${CONFIG.PdfReputation1Uri}/${myIdx}`;
		params.add('url', pdfUri);
		params.add('ConversionDelay', '3');
		params.add('MarginTop', '15');
		params.add('MarginBottom', '15');
		params.add('MarginRight', '0');
		params.add('MarginLeft', '0');
		params.add('ViewportWidth', '1400');
		//params.add('ShowElements', '.container');

		console.log("pdfUri", pdfUri);

		setLoadText('동료평판 다운로드 준비중입니다...');
		setLoad(true);

		console.log("PDF생성을 시작합니다.");
		convertApi
			.convert('web', 'pdf', params)
			.then((result) => {
				const url = result.files[0].Url;
				console.log("PDF URL", url);
				if (!window.ReactNativeWebView) {
					onDownloadUrl(url, `동료평판_${myName}_${toDay}.pdf`);
				} else {
					//앱으로 전달
					const file_name = `동료평판_${myName}_${toDay}.pdf`;
					const file_url = url;
					const params = {
						file_name,
						file_url
					};
					const paramsToString = JSON.stringify(params);
					window.ReactNativeWebView.postMessage(paramsToString);
				}
			})
			.catch((err) => {
				alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
				console.log("PDF error", err);
				setLoad(false);
			})
			.finally(() => {
				setLoad(false);
				//document.documentElement.style.cursor = 'default'
			})
	}

	return (
		<>
			{Load === true ? <Loading text={LoadText} /> : null}
			{enablePopRequR && <PopAcqRepuR2 setEnablePopRequR={setEnablePopRequR} buttonId={buttonId} setButtonId={setButtonId} />}
			{(colleagueList.length < 1 ) ?	//받은 평판 유무
        /* 시작. 받은병판 없다 */
				<section className="com_reputation com_repu_w_r p_colle_r center_wrap p_repu_r">
          <ul className="tab02 p_colle_r">
            <li className={buttonId === "my" ? 'active' : ''}>
              <button className='tab' onClick={()=>{setButtonId('my')}}>동료가 작성한 내 평판</button>
            </li>
            <li className={buttonId === "your" ? 'active' : ''}>
              <button className='tab' onClick={()=>{setButtonId('your')}}>내가 작성한 동료 평판</button>
            </li>
          </ul>

					<article className="repu_writer_info_wrap">
            {buttonId === 'my' ?
              <div className='notice_area'>
                <h1>
                  <span className="txt_blue">{myName}</span>
                  님은 {`${colleagueList?.length}`}명의 동료에게 평판을 받았습니다.
                 </h1>
                <p className='txt'>더 많은 동료에게 평판 요청하기를 진행해서 동료 평판 커리어를 쌓아보세요.</p>
                <button type="button" id={buttonId} className="btn" onClick={() => setEnablePopRequR(true)}>동료에게 평판 요청하기</button>
              </div>
              :
              <div className='notice_area'>
                <h1>
                  <span className="txt_blue">{myName}</span>
                  님은 {`${colleagueList?.length}`}명의 동료의 평판을 작성하였습니다.
                </h1>
                <p className='txt'>다른 동료 평판을 추가로 작성해 보세요!</p>
                <button type="button" id={buttonId} className="btn" onClick={() => setEnablePopRequR(true)}>동료평판 작성하기</button>
              </div>
            }
					</article>
				</section>
        /* 끝. 받은 평판 없다 */
				:
        /* 시작. 받은 평판 있다 */
				<section className="com_reputation com_repu_w_r p_colle_r center_wrap p_repu_r">
          
          {/*선택 카테고리 버튼*/}
          <ul className="tab02 p_colle_r">
            <li className={buttonId === "my" ? 'active' : ''}>
              <button className='tab' onClick={()=>{setButtonId('my')}}>동료가 작성한 내 평판</button>
            </li>
            <li className={buttonId === "your" ? 'active' : ''}>
              <button className='tab' onClick={()=>{setButtonId('your')}}>내가 작성한 동료 평판</button>
            </li>
          </ul>

					{buttonId === "my" && (
						<div id="sub_wrap" className="page_myresume_view">
							<div className="pdf-area center_wrap">
								<button className="btn upperline" onClick={() => handleClickRepuDnPdf()}>동료평판 다운받기</button>
							</div>
						</div>
					)}

					<article className="repu_writer_info_wrap">
            {buttonId === 'my' ?
              <div className='notice_area'>
                <h1>
                  <span className="txt_blue">{myName}</span>
                  님은 {`${colleagueList?.length}`}명의 동료에게 평판을 받았습니다.
                </h1>
                <p className='txt'>더 많은 동료에게 평판 요청하기를 진행해서 동료 평판 커리어를 쌓아보세요.</p>
                <button type="button" id={buttonId} className="btn" onClick={() => setEnablePopRequR(true)}>동료에게 평판 요청하기</button>
              </div>
              :
              <div className='notice_area'>
                <h1>
                  <span className="txt_blue">{myName}</span>
                  님은 {`${colleagueList?.length}`}명의 동료의 평판을 작성하였습니다.
                </h1>
                <p className="txt">다른 동료 평판을 추가로 작성해 보세요!</p>
                <button type="button" id={buttonId} className="btn" onClick={() => setEnablePopRequR(true)}>동료평판 작성하기</button>
              </div>
            }
					</article>
					<article className="repu_result_info_wrap">
						<select onChange={onChangeColleague}>
							{
								colleagueList?.map((element, idx) => {
									const optName = buttonId === "your" ? element.to_mem_name : element.mem_name;
									return (<option key={idx}>{optName}</option>);
								})
							}
						</select>
						<ul>
							<li>평판작성일자<b>{colleagueList[colleague]?.reg_dtm}</b></li>
							<li>함께 일한 근무 기간<b>{colleagueInfo?.terms}</b></li>
              {/*
							<li>함께 일한 근무 기간<b>{question[5]?.ex[colleagueAnswer[5]?.ex_no - 1]?.repu_a}</b></li>
							<li>함께 일했던 회사<b>{colleagueInfo?.company}</b></li>
              */}
							<li>관계<b>{colleagueInfo?.relation}</b></li>
						</ul>
					</article>

					<article className="com_repu_gray_container">
						<form>
							{question?.filter((content) => content.cate_no >= 6)
								.map((content) => {
									const fAnswer = colleagueAnswer.length ? colleagueAnswer?.filter((a) => a.cate_no === content.cate_no && a.repu_no === content.repu_no) : [];
									return (
										<div className="repu_question" key={`${content.cate_no}`}>
											<h1>{content.repu_q}</h1>
											<ul className="repu_answer">
												{content?.ex.map((el) => {
													const chk1 = fAnswer.length ? fAnswer[0]?.ex_no === el.ex_no ? true : false : false;
													return (
														<li className={el.repu_a === "기타" ? "has_textbox" : ""}
															key={`${content.cate_no}${el.ex_no}`}
														>
															<input
																type="radio"
																className="chk square"
																name={`${content.cate_no}${content.repu_no}`}
																id={`${content.cate_no}${content.repu_no}${el.ex_no}`}
																checked={chk1}
																readOnly={true}
															/>
															<label htmlFor={`${content.cate_no}${content.repu_no}${el.ex_no}`}>
																{el.repu_a}
															</label>
															{el.repu_a === "기타" &&
																<input
																	type="text"
																	name={`${content.cate_no}${content.repu_no}`}
																	id={`${content.cate_no}${content.repu_no}${el.ex_no}`}
																	value={fAnswer[0]?.remark}
																	readOnly={true}
																/>
															}
														</li>
													);
												})}
											</ul>
										</div>
									);
								})}
							{category?.map((title) => {
								return (
									<React.Fragment key={`${title.cate_no}`}>
										<article className="repu_question_wrap horizon2 has_section">
											<h1 className="repu_question_title">
												{title.cate_nm_kr}<span>{title.cate_nm_en}</span>
											</h1>
											{question?.filter((content) => content.cate_no === title.cate_no && content.cate_no !== 5)
												.map((content) => {

													const fAnswer = colleagueAnswer.length ? colleagueAnswer?.filter((a) => a.cate_no === content.cate_no && a.repu_no === content.repu_no) : [];

													return (
														<section className="left_section" key={`${title.cate_no}${content.repu_no}`}>
															<div className="repu_question align_v">
																<h1>{content.repu_q}</h1>
																<ul className="repu_answer">
																	{content?.ex.map((el) => {

																		const chk = fAnswer.length ? fAnswer[0]?.ex_no === el.ex_no ? true : false : false;

																		return (
																			<li key={`${title.cate_no}${content.repu_no}${el.ex_no}`}>
																				<input type="radio"
																					className="chk square wide"
																					name={`${title.cate_no}${content.repu_no}`}
																					id={`${title.cate_no}${content.repu_no}${el.ex_no}`}
																					checked={chk}
																					readOnly={true}
																				/>
																				<label htmlFor={`${title.cate_no}${content.repu_no}${el.ex_no}`}><span>{el.repu_a}</span></label>
																			</li>
																		);
																	})}
																</ul>
															</div>
														</section>
													);
												})}
											{question?.filter((content) => content.cate_no === title.cate_no && content.cate_no === 5)
												.map((content) => {
													const fAnswer = colleagueAnswer.length ? colleagueAnswer?.filter((a) => a.cate_no === content.cate_no && a.repu_no === content.repu_no) : [];
													return (
														<React.Fragment key={`${title.cate_no}${content.repu_no}`}>
															{content.repu_no < 4 &&
																<section className="left_section">
																	<div className="repu_question align_v">
																		<h1>{content.repu_q}</h1>
																		<ul className="repu_answer">
																			{content?.ex.map((el) => {
																				const chk = fAnswer.length ? fAnswer[0]?.ex_no === el.ex_no ? true : false : false;
																				return (
																					<li key={`${title.cate_no}${content.repu_no}${el.ex_no}`}>
																						<input type="radio"
																							className="chk square wide"
																							name={`${title.cate_no}${content.repu_no}`}
																							id={`${title.cate_no}${content.repu_no}${el.ex_no}`}
																							checked={chk}
																							readOnly={true}
																						/>
																						<label htmlFor={`${title.cate_no}${content.repu_no}${el.ex_no}`}><span>{el.repu_a}</span></label>
																					</li>
																				)
																			})}
																		</ul>
																	</div>
																</section>
															}
															{content.repu_no === 4 &&
																<section className="right_section">
																	<div className="repu_question align_v">
																		<h1>{content.repu_q}</h1>
																		<ul className="repu_answer long_answer">
																			{content?.ex.map((el) => {
																				const chk = fAnswer.length ? fAnswer[0]?.ex_no === el.ex_no ? true : false : false;
																				return (
																					<li key={`${title.cate_no}${content.repu_no}${el.ex_no}`}>
																						<input
																							type="radio"
																							className="chk square"
																							name={`${title.cate_no}${content.repu_no}`}
																							id={`${title.cate_no}${content.repu_no}${el.ex_no}`}
																							checked={chk}
																							readOnly={true}
																						/>
																						<label
																							htmlFor={`${title.cate_no}${content.repu_no}${el.ex_no}`}>{el.repu_a}<br /></label>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</section>
															}
														</React.Fragment>
													);
												})}
										</article>
										{question?.filter((content) => content.cate_no === title.cate_no && content.cate_no === 5)
											.map((content) => {
												const fAnswer = colleagueAnswer.length ? colleagueAnswer?.filter((a) => a.cate_no === content.cate_no && a.repu_no === content.repu_no) : [];
												return (
													content.cate_no === 5 && content.repu_no > 4 &&
													<div className="repu_question align_v only_txtbox" key={`${title.cate_no}${content.repu_no}`}>
														<h1>{content.repu_q}<span>*</span></h1>
														<ul className="repu_answer">
															<li>
																<input
																	type="text"
																	name={`${title.cate_no}${content.repu_no}`}
																	id={`${title.cate_no}${content.repu_no}1`}
																	value={fAnswer[0]?.remark}
																	readOnly={true}
																/>
															</li>
														</ul>
													</div>
												);
											})}
									</React.Fragment>
								);
							})}
						</form>
					</article>

				</section>
        /* 끝. 받은 평판 있다 */
			}
			{!answerCount && < ColleRpuRblind buttonId={buttonId} setButtonId={setButtonId} setEnablePopRequR={setEnablePopRequR} />}
		</>
	)
}

export default ColleRepuR