/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import CONFIG from "configs/configs";
import $ from "jquery";
import { removeLocalstorageItem } from "../Common/Common";

//이미지
import MainLogoIcon from "img/common/logo.png";
import gnb_myheding_m from "img/common/gnb_myheding_m.png";
import mainButtonBar from "img/common/btn_bar.png";

//컴포넌트
import NavCompany from "./NavCompany";
import LoginMenuCompany from "./LoginMenuCompany";
import MainAppDownPopup from "components/Main/MainAppDownPopup";
/* import BannerTop from "components/Banner/BannerTop"; */

const HeaderCompany = ({ NumberReset, setNumberReset }) => {
  const uselocation = useLocation();
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const Navigator = useNavigate();
  //앱 팝업창 관리 스테이트
  const [appPopupShow, setAppPopupShow] = useState(false);

  const [varPersonal, setPersonal] = useState({});
  const [varSearchValues, setSearchValues] = useState({
    searchString: "",
    vwc_idx: 0,
    vwd_idx: 0,
    vwc_name: "",
    vwd_name: "",
    vr_code: 0,
    vr_name: "",
    startCareer: 0,
    endCareer: 0,
    positions: [0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    const uAgent = navigator.userAgent.toLocaleLowerCase();

    if (uAgent.match(/iPhone|iPod|Android/i)) {
      if (uAgent.search("heding") === -1) {
        // 처리 내용
        console.log(sessionStorage.getItem('appChk'));
        if (Boolean(sessionStorage.getItem('appChk')) !== true) {
          setAppPopupShow(true);
        }

      }
    }

  }, []);

  //로그인 유무 확인
  let loginState = false;
  if (localStorage.getItem("memlogin") && localStorage.getItem("mem_idx") > 0)
    loginState = true;
  const [varIsLogin, setIsLogin] = useState(loginState);

  const LogoutHandler = () => {
    if (!window.confirm("로그아웃하시겠습니까?")) {
      return false;
    }
    if (localStorage.getItem("memlogin") === false) {
      //localStorage.clear();
      removeLocalstorageItem();
      /* alert("로그아웃 되었습니다."); */
    } else {
      localStorage.clear();
    }
    setIsLogin(false);
    Navigator("/Login");
  };

  /*   const onCloseBannerHandler = () => {
    setIsOpen(false);
    sessionStorage.setItem("banner", false);
  };
  const Timestamp = new Date();
  const Tomorrow = new Date(Timestamp.setDate(Timestamp.getDate() + 1));
  const onDayCloseBannerHandler = () => {
    localStorage.setItem("onCloseBannerHandler", Timestamp);
    sessionStorage.setItem("banner", false);
    setIsOpen(false);
  }; */

  /*   useEffect(() => {
    if (Timestamp === Tomorrow) {
      localStorage.removeItem("onCloseBannerHandler");
    }
    const banner = sessionStorage.getItem("banner");
    if (banner === "true") {
      setIsOpen(true);
    } else if (banner === "false" && localStorage.getItem("onCloseBannerHandler")) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  /*   const onSetIsMenuOpenCom = (e,boption) => {
    e.preventDefault();
    setIsMenuOpenCom(boption);
  } */
  useEffect(() => {
    if (
      localStorage.getItem("memlogin") &&
      localStorage.getItem("mem_idx") > 0
    ) {
      setIsLogin(true);
      try {
        const params = new URLSearchParams();
        params.append("user_idx", localStorage.getItem("mem_idx"));
        params.append("mem_mode", mem_mode);
        axios
          .post(`${CONFIG.SERVER_HOST}/_get_user_information`, params)
          .then((res) => {
            if (res.data.retvalue === 1) {
              const datas = res.data.results;
              setPersonal(datas);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsLogin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("memlogin")]);

  //헤드헌터 로그인 시 navbar색 변경
  const setBodyHunter = () => {
    const bodyItem = document.querySelector("body");
    if (mem_mode === 2) {
      if (!bodyItem.classList.contains("head_hunter_body"))
        bodyItem.classList.add("head_hunter_body");
    } else {
      if (bodyItem.classList.contains("head_hunter_body"))
        bodyItem.classList.remove("head_hunter_body");
    }
  };
  //화면크기
  useEffect(() => {
    setBodyHunter();

    if ($(window).width() < 1204) {
      $("#head .nav").each(function () {
        var MinusHeight =
          $(this).siblings("#head .mobile_content").outerHeight(true) || 0;
        $(this).css({ height: "calc(100vh - " + MinusHeight + "px)" });
      }); //삼선메뉴 높이
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //통합검색
  const onChangeSearchStringHandler = (e) => {
    let sString = e.currentTarget.value;
    setSearchValues({ ...varSearchValues, searchString: sString });
  };

  const onOpenMenuUser = (e) => {
    e.preventDefault();
    $("#head .nav_wrap").show(); //열기
    $("#head .nav").each(function () {
      //nav 높이
      var MinusHeight =
        $(this).siblings("#head .mobile_content").outerHeight(true) || 0;
      $(this).css({ height: "calc(100vh - " + MinusHeight + "px)" });
    });
  };

  const handleOnSearch = () => {
    if (varSearchValues === "") {
      alert('검색어를 입력해 주세요.');
      return;
    }

    setTimeout(function () {
      Navigator(`/Company/ComResource/SearchMain`, { state: { searchMode: 0, searchString: varSearchValues.searchString } })
    }, 100);
  }

  useEffect(() => {
    if (uselocation.state?.searchString) {
      setSearchValues((prev) => ({
        ...prev,
        searchString: ''
      }));
    }
  }, [uselocation]);

  useEffect(() => {
    const _Member_withdrawal_check = () => {
      const params = new URLSearchParams();
      params.append("user_idx", localStorage.getItem("mem_idx"));

      axios
        .post(`${CONFIG.SERVER_HOST}/_Member_withdrawal_check`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            if (res.data.results === 0) {
              //console.log('탈퇴한 회원입니다.', localStorage.getItem("mem_idx"));
              localStorage.clear();
              Navigator('/');
            }
          }
        });
    };

    if (localStorage.getItem("mem_idx") !== null) {
      _Member_withdrawal_check()
    }
    // return () =>
    // _Member_withdrawal_check()
  });

  return varSearchValues === undefined ||
    varSearchValues.searchString === undefined ||
    varSearchValues.searchString === null ? null : (
    <>
      <aside id="ribbon" className="center_wrap">
        {/*         {localStorage.getItem("onCloseBannerHandler") === undefined || localStorage.getItem("onCloseBannerHandler") === null ? (
            <BannerTop mode={1} varIsOpen={varIsOpen}
                onCloseBannerHandler={onCloseBannerHandler}
                onDayCloseBannerHandler={onDayCloseBannerHandler}
            />
        ) : null} */}
        <div className="logo">
          <Link to={"/Company"}>
            <img src={MainLogoIcon} alt="" />
          </Link>
        </div>
        <LoginMenuCompany
          varIsLogin={varIsLogin}
          LogoutHandler={LogoutHandler}
          NumberReset={NumberReset}
          setNumberReset={setNumberReset}
        />
        {/* 일반 사용자 페이지 */}
        {/* {varIsLogin? <Link to="/" className="button">메인서비스</Link>
        : <Link to={"/JoinUs"} className="btn white">회원가입</Link>
        } */}
        {varIsLogin ? (
          ""
        ) : (
          <Link to={"/JoinUs"} className="link">
            회원가입
          </Link>
        )}
        <Link to={"/main"} className="btn_my">
          <img src={gnb_myheding_m} alt="" />
        </Link>
        {varIsLogin ? (
          <Link to="/main" className="button">
            메인 서비스
          </Link>
        ) : (
          ""
        )}
        <button onClick={(e) => onOpenMenuUser(e)} className="btn_bar">
          <img src={mainButtonBar} alt="" />
        </button>
      </aside>

      <header id="head">
        <div className="center_wrap">
          <NavCompany
            varIsLogin={varIsLogin}
            varPersonal={varPersonal}
            LogoutHandler={LogoutHandler}
          />
          <div>
            <fieldset className="search_area">
              {/* <select>
                <option>통합검색</option>
              </select> */}
              <input
                type="text"
                name="searchString"
                value={varSearchValues.searchString}
                placeholder="직무/지역/회사를 입력하세요"
                onChange={(e) => onChangeSearchStringHandler(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleOnSearch()
                  }
                }}
              />
              <button style={{ cursor: "pointer" }} className="button" onClick={(e) => handleOnSearch()}>{``}</button>
            </fieldset>
          </div>
        </div>
      </header>
      <MainAppDownPopup setAppPopupShow={setAppPopupShow} appPopupShow={appPopupShow} />
    </>
  );
};

export default HeaderCompany;