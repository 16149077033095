/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';

import PopEditProfile from 'components/My/PopEditProfile';
import ProfileSub from "components/Company/ProfileSub";
import ProfileInfoSub from "components/Company/ProfileInfoSub";
import { bizNoFormat } from 'components/Common/Common';

function ProfileInfo({
  varCompanyInfo, setCompanyInfo,
  varAuth, setAuth,
  onChangeHandler,
  onChangeOpenPost,
  setTexFile,
  varTexFile,
  user_idx,
  mem_sub
}) {

  //사업자등록증 찾기
  const fileInput = useRef();

  //팝업창
  const [varPopup, setPopup] = useState(0);
  const [varMemberData, setMemberData] = useState({});

  //팝업창 번호
  const onSetPopup = (e, num) => {
    //e.preventDefault();
    setPopup(num);
  }

  //파일선택
  const handleClickTexfile = () => {
    fileInput.current.click();
  };
  //선택된 파일 저장 - 사업자등록증
  const onChangeTexFile = (e) => {
    const { files } = e.currentTarget;
    //jpg, png 파일만 등록 가능하게
    if((files[0].name?.split('.').reverse()[0] !== 'jpg') && (files[0].name?.split('.').reverse()[0] !== 'pdf') && (files[0].name?.split('.').reverse()[0] !== 'png')) {
      alert('jpg, pdf, png만 가능합니다.');
      return false;
    }
    setTexFile(files[0]);
    document.getElementById('selected_file').value = files[0].name;
  }

  return (
    <>
      {/* {user_idx === mem_sub ?  */}
      <>
        <h3 className="my_title">기업정보</h3>
        <ul className="table table_view01">
          {/* <li className="tr">
            <div className="th">기업형태</div>
            <div className="td">
              <input type="text" value={varCompanyInfo?.vct_name} readOnly />
            </div>
          </li> */}
          <li className="tr">
            <div className="th">
              사업자
              <br />
              등록번호
            </div>
            <div className="td">
              <input type="text" value={bizNoFormat(varCompanyInfo?.mcm_tax_id) || ""} className="read-only" readOnly />
            </div>
          </li>
          <li className="tr">
            <div className="th">회사명</div>
            <div className="td">
              <input type="text" value={varCompanyInfo?.mcm_name || ""} className="read-only" readOnly />
            </div>
          </li>
          <li className="tr">
            <div className="th">대표자 명</div>
            <div className="td">
              <input type="text" value={varCompanyInfo?.mcm_ceo || ""} className="read-only" readOnly />
            </div>
          </li>
          <li className="tr">
            <div className="th">회사주소</div>
            <div className="td flex_wrap">
              <div className="input_wrap">
                <input id="mcm_address1" name="mcm_address1" type="text"
                  value={varCompanyInfo?.mcm_zipcode === null ? "" : `(${varCompanyInfo?.mcm_zipcode})` + varCompanyInfo?.mcm_address1}
                />
                <button onClick={() => onChangeOpenPost()} className="btn white">주소찾기</button>
              </div>
              <input type="text" placeholder="상세주소를 입력해주세요." id="mcm_address2" name="mcm_address2"
                value={varCompanyInfo?.mcm_address2 === null ? "" : varCompanyInfo?.mcm_address2}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
          </li>
          <li className="tr">
            <div className="th">
              사업자등록증 <br />
              첨부
            </div>
            <div className="td flex_wrap">
              <div className="input_wrap">
                {/*               <input type="file" style={{display: "none"}} ref={fileRef}/> */}
                <input type="text" id="selected_file" readOnly value={varTexFile ? varTexFile.name : varCompanyInfo?.mcm_tax_ufile} />
                <button className="btn white" onClick={() => handleClickTexfile()}>파일찾기</button>
                <input type="file" id="tex_file" accept={"*.jpg,*.png,*.pdf"} ref={fileInput} onChange={onChangeTexFile} style={{ display: 'none' }} value={undefined || ""} />
              </div>
              <p className="guide_text">※ jpg, pdf, png 파일만 Upload 가능</p>
            </div>
          </li>
          <li className="tr">
            <div className="th">회사 홈페이지</div>
            <div className="td">
              <input type="text" value={varCompanyInfo?.homepage === null ? "" : varCompanyInfo?.homepage} placeholder="https://" name="homepage" onChange={(e) => onChangeHandler(e)} />
            </div>
          </li>
          <li className="tr">
            <div className="th">회사소개</div>
            <div className="td">
              <textarea className="comheding_profile_textarea" value={varCompanyInfo?.company_intro === null ? "" : varCompanyInfo?.company_intro} name="company_intro" onChange={(e) => onChangeHandler(e)}></textarea>
            </div>
          </li>
          <li className="tr">
            <div className="th">혜택 및 복지</div>
            <div className="td">
              <textarea className="comheding_profile_textarea" value={varCompanyInfo?.company_welfare === null ? "" : varCompanyInfo?.company_welfare} name="company_welfare" onChange={(e) => onChangeHandler(e)}></textarea>
            </div>
          </li>
        </ul>
      </>
      {/* : null } */}
      <div className="page_com_myheding01 sub_account">
        <section className="box_content">
          <ProfileSub varCompanyInfo={varCompanyInfo} setCompanyInfo={setCompanyInfo} />
          <ProfileInfoSub
            varCompanyInfo={varCompanyInfo} setCompanyInfo={setCompanyInfo}
            varAuth={varAuth} setAuth={setAuth} onChangeHandler={onChangeHandler}
          />
        </section>
      </div>
      {/*프로필수정팝업*/}
      <PopEditProfile varPopup={varPopup} onSetPopup={onSetPopup} varMemberData={varMemberData} setMemberData={setMemberData} setPopup={setPopup} />
    </>
  );
}

export default ProfileInfo;
