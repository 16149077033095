/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import CONFIG from "configs/configs";
import { Link } from "react-router-dom";

//image
import moreArrowImage from "img/sub/icon_arrow2.png";
import detailShowImage from "img/sub/icon_arrow.png";

import CertificationCareerView from "./CertificationCareer";
import AddCareerView from "./AddCareer";


const SearchC = ({
  onViewResumePage,
  setLoad,
  setLoadText
}) => {
  const mcm_idx = Number(localStorage.getItem("mcm_idx"));
  const mcm_name = localStorage.getItem('mcm_name');

  //loading 유무
  const [varLoadingFinshed, setLoadingFinshed] = useState(1);

  //서버의 저장폴더
  const ImageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;
  const [varHighLookupLists, setHighLookupLists] = useState([]);
  const [varRecentLookupLists, setRecentLookupLists] = useState([]);
  const [varRecentRegisterLists, setRecentRegisterLists] = useState([]);

  const getLookupLists = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mcm_name", mcm_name);
      setLoadingFinshed(1);
      setLoad(true);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_search_basepage`, params).then((res) => {
        console.log(res);
        setLoadingFinshed(0);
        if (res.data.retvalue === 1) {
          /* setMemberLists(res.data.members); */
          setHighLookupLists(res.data.highLookups);
          setRecentLookupLists(res.data.recentLookups);
          setRecentRegisterLists(res.data.recentRegisters);
        } else {
          /* setMemberLists([]); */
          setHighLookupLists([]);
          setRecentLookupLists([]);
          setRecentRegisterLists([]);
        }
        setLoadingFinshed(0);
      });
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoadingFinshed(0);
    }
  }, [mcm_idx, mcm_name, setLoad]);

  useEffect(() => {
    if (mcm_idx > 0) {
      console.log('값이 큼');
      getLookupLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mcm_idx]);


  useEffect(() => {
    return () => setLoadingFinshed(0);
  }, []);


  const ShowView =(certification_experience_year , certification_experience_date, highLookup)=>{
    console.log(certification_experience_year , certification_experience_date, highLookup);
    if(certification_experience_year > 0){
      return  <CertificationCareerView highLookup={highLookup} />;
    }else{
      if(certification_experience_date > 0){
        return  <CertificationCareerView highLookup={highLookup} />;
      }else{
        return <AddCareerView highLookup={highLookup} />;
      }
    }
  };

  return varLoadingFinshed === 1 ? null : (
    <>
      {/* <!--조회수 높은 인재--> */}
      <section className="talent_short">
        <h3 className="title_area">
          조회수 높은 인재
        </h3>
        {varHighLookupLists.map((highLookup, index) => (
          <article key={"hr_" + index} className="item" onClick={(e) => onViewResumePage(e, highLookup?.re_idx)} style={{ cursor: 'pointer' }}>
            <div className="profile">
              {highLookup.mem_photo === 0 || highLookup.mem_ufile === undefined ? (
                <p className="img"></p>
              ) : (
                <p className="img" style={{ backgroundImage: `url(${ImageFoler + highLookup.mem_ufile})` }}></p>
              )}
              <p className="name">{highLookup?.mem_name}</p>
            </div>
            <div className="info_area">
              <p className="info_field career">
                {/* {
                  highLookup?.certification_experience_year===0 && highLookup?.certification_experience_date===0 ?
                    <AddCareerView highLookup={highLookup} />
                    :
                    <CertificationCareerView highLookup={highLookup} />
                } */}
                {
                  ShowView(highLookup?.certification_experience_year, highLookup?.certification_experience_date, highLookup)
                }
              </p>


              {highLookup?.rec_company ?
                <><span className="division_bar"></span>
                  <p className="info_field company">{highLookup?.rec_company}</p></>
                : highLookup?.rac_company ?
                <><span className="division_bar"></span>
                  <p className="info_field company">{highLookup?.rac_company}</p></>
                : null
              }

              {highLookup?.rec_position ?
                <><span className="division_bar"></span>
                  <p className="info_field">
                    {highLookup?.rec_department}&nbsp;{highLookup?.rec_position}
                  </p></>
                : highLookup?.rac_position ?
                <><span className="division_bar"></span>
                  <p className="info_field">
                    {highLookup?.rec_department}&nbsp;{highLookup?.rac_position}
                  </p></>
                : null

              }

              {highLookup?.reh_major ?
                <><span className="division_bar"></span>
                  <p className="info_field school">
                    {highLookup?.reh_name}&nbsp;{highLookup?.reh_major} {(highLookup?.reh_name === null || highLookup?.reh_major === null) ? "" : highLookup?.reh_state === 1 ? "졸업" : highLookup?.reh_state === 2 ? "졸업예정" : highLookup?.reh_state === 3 ? "재학중" : highLookup?.reh_state === 4 ? "중퇴" : highLookup?.reh_state === 5 ? "수료" : highLookup?.reh_state === 6 ? "휴학" : ""}
                  </p></>
                : null
              }

            </div>
            <button className="button">
              자세히보기<img src={detailShowImage} alt="" />
            </button>
          </article>
        ))}
      </section>
      {/* <!--최근 조회 인재--> */}
      <section className="talent_short">
        <h3 className="title_area">
          최근 조회 인재
          <Link to={'/Company/ComResource/Latest'} className="button">더보기<img src={moreArrowImage} alt="" /></Link>
        </h3>
        {varRecentLookupLists.map((recentLookup, index) => (
          <article className="item" key={"recent_" + index} style={{ cursor: 'pointer' }} onClick={(e) => {
            onViewResumePage(e, recentLookup?.re_idx);
          }}>
            <div className="profile">
              {recentLookup?.mem_photo === 0 ? (
                <p className="img"></p>
              ) : (
                <p
                  className="img"
                  style={{
                    backgroundImage: `url(${ImageFoler + recentLookup?.mem_ufile
                      })`,
                  }}
                ></p>
              )}
              <p className="name">{recentLookup?.mem_name}</p>
            </div>
            <div className="info_area">
              <p className="info_field career">
                {/* {
                  !recentLookup?.certification_experience_year && !recentLookup?.certification_experience_year ?
                    <AddCareerView highLookup={recentLookup} />
                    :
                    <CertificationCareerView highLookup={recentLookup} />
                } */}
                {
                  ShowView(recentLookup?.certification_experience_year, recentLookup?.certification_experience_date, recentLookup)
                }
              </p>
              {recentLookup?.rec_company ?
                <><span className="division_bar"></span>
                  <p className="info_field company">{recentLookup?.rec_company}</p></>
                : recentLookup?.rac_company ?
                <><span className="division_bar"></span>
                  <p className="info_field company">{recentLookup?.rac_company}</p></>
                : null
              }

              {recentLookup?.rec_department ?
                <><span className="division_bar"></span>
                  <p className="info_field">
                    {recentLookup?.rec_department}&nbsp;{recentLookup?.rec_position}
                  </p></>
                : recentLookup?.rac_department ?
                <><span className="division_bar"></span>
                  <p className="info_field">
                    {recentLookup?.rac_department}&nbsp;{recentLookup?.rac_position}
                  </p></>
                : null
              }

              {recentLookup?.reh_major ?
                <><span className="division_bar"></span>
                  <p className="info_field school">
                    {recentLookup?.reh_name}&nbsp;{recentLookup?.reh_major} {(recentLookup?.reh_name === null || recentLookup?.reh_major === null) ? "" : recentLookup?.reh_state === 1 ? "졸업" : recentLookup?.reh_state === 2 ? "졸업예정" : recentLookup?.reh_state === 3 ? "재학중" : recentLookup?.reh_state === 4 ? "중퇴" : recentLookup?.reh_state === 5 ? "수료" : recentLookup?.reh_state === 6 ? "휴학" : ""}
                  </p></>
                : null
              }

            </div>
            <button className="button">
              자세히보기
              <img src={moreArrowImage} alt="" />
            </button>
          </article>
        ))}
      </section>
      {/* <!--최근 등록 인재--> */}
      <section className="talent_short">
        <h3 className="title_area">
          최근 등록 인재
          {/*  <a className="button" onClick={(e)=>onMoreDataPage(e,3)}>더보기<img src={moreArrowImage} alt=""/></a> */}
        </h3>
        {varRecentRegisterLists.map((recentRegister, index) => (
          <article className="item" key={"register_" + index} onClick={(e) => {
            onViewResumePage(e, recentRegister?.re_idx);
          }} style={{ cursor: 'pointer' }}>
            <div className="profile">
              {recentRegister?.mem_photo === 0 ? (
                <p className="img"></p>
              ) : (
                <p
                  className="img"
                  style={{
                    backgroundImage: `url(${ImageFoler + recentRegister?.mem_ufile
                      })`,
                  }}
                ></p>
              )}
              <p className="name">{recentRegister?.mem_name}</p>
            </div>
            <div className="info_area">
              <p className="info_field career">
                {/* {
                  !recentRegister?.certification_experience_year && !recentRegister?.certification_experience_year ?
                    <AddCareerView highLookup={recentRegister} />
                    :
                    <CertificationCareerView highLookup={recentRegister} />
                } */}

                {
                  ShowView(recentRegister?.certification_experience_year, recentRegister?.certification_experience_date, recentRegister)
                }
              </p>
              {recentRegister?.rec_company ?
                <><span className="division_bar"></span>
                  <p className="info_field company">{recentRegister?.rec_company}</p></>
                : recentRegister?.rac_company ?
                <><span className="division_bar"></span>
                  <p className="info_field company">{recentRegister?.rac_company}</p></>
                : null
              }
              {recentRegister?.rec_department ?
                <><span className="division_bar"></span>
                  <p className="info_field">
                    {recentRegister?.rec_department}&nbsp;
                    {recentRegister?.rec_position}
                  </p></>
                : recentRegister?.rac_department ?
                <><span className="division_bar"></span>
                  <p className="info_field">
                    {recentRegister?.rac_department}&nbsp;
                    {recentRegister?.rac_position}
                  </p></>
                  : null
              }
              {recentRegister?.reh_name ?
                <><span className="division_bar"></span>
                  <p className="info_field school">
                    {recentRegister?.reh_name}&nbsp;{recentRegister?.reh_major}
                    {(recentRegister?.reh_name === null || recentRegister?.reh_major === null) ? "" : recentRegister?.reh_state === 1 ? "졸업" : recentRegister?.reh_state === 2 ? "졸업예정" : recentRegister?.reh_state === 3 ? "재학중" : recentRegister?.reh_state === 4 ? "중퇴" : recentRegister?.reh_state === 5 ? "수료" : recentRegister?.reh_state === 6 ? "휴학" : ""}
                  </p></>
                : null

              }


            </div>
            <button className="button">
              자세히보기
              <img src={moreArrowImage} alt="" />
            </button>
          </article>
        ))}
      </section>
    </>
  );
};

export default SearchC;
