import BannerBottom from "components/Banner/BannerBottom";
import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Footer from '../../components/Layout/Footer'
import HeaderCompany from '../../components/Layout/HeaderCompany'
import { resetNavMenu, resetNavSubMenu } from "components/Common/Common";
/* import SubVisual from '../../components/Layout/SubVisual' */

import MainList from "../Recruit/MainList";
import MainView from "../Recruit/MainView";

//css

const ComRecruit = () => {
  const PageFolder = "/Company/ComRecruit/";
  const location = useLocation();
  const [varHeadHunter, setHeadHunter] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (
      location !== null &&
      location.state !== null &&
      location.state.em_headhunter !== null
    ) {
      //console.log("location22222 :", location.state.em_headhunter);
      setHeadHunter(location.state.em_headhunter);
    }
  });
  useEffect(() => {
    /* setPageTitle("공고/지원자 관리"); */
    resetNavMenu(1);
    resetNavSubMenu(1,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(varHeadHunter);
  return (
    <>
      <HeaderCompany />
      <div className="p_myinfo">
        {/* <SubVisual PageTitle={"채용정보"} SubVisual_className={"type1"}/> */}
        <div id="SubWrap">
          <div className="CenterWrap">
            <Routes>
                <Route path="/" element={<MainList varHeadHunter={varHeadHunter} setHeadHunter={setHeadHunter} PageFolder={PageFolder}/>} /> 
                <Route path="/MainList" element={<MainList varHeadHunter={varHeadHunter} setHeadHunter={setHeadHunter} PageFolder={PageFolder} />} /> 
                <Route path="/MainView/:em_idx/:em_headhunter" element={<MainView varHeadHunter={varHeadHunter} setHeadHunter={setHeadHunter} PageFolder={PageFolder} />} /> 
            </Routes>
          </div>
        </div>
      </div>
      <BannerBottom />
      <Footer />
    </>
  );
};

export default ComRecruit;
