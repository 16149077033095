/* eslint-disable no-new-object */
import axios from "axios";
import CONFIG from "../../configs/configs";
import $ from "jquery";
import { Config } from "aws-sdk";
import download from "downloadjs";
import moment from "moment";

export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
export function ChangeSearchingDateFormat(sdate) {
  let ndate = "";
  if (sdate === "" || sdate === null) {
    ndate = null;
  } else {
    let year = sdate?.getFullYear();
    let month = ("0" + (sdate?.getMonth() + 1)).slice(-2);
    let day = ("0" + sdate?.getDate()).slice(-2);
    ndate = year + "-" + month + "-" + day;
  }
  return ndate;
}
export function ChangeDateFormat(sdate) {
  if (sdate === "") sdate = new Date();
  let year = sdate?.getFullYear();
  let month = ("0" + (sdate?.getMonth() + 1)).slice(-2);
  let day = ("0" + sdate?.getDate()).slice(-2);
  const ndate = year + "-" + month + "-" + day;
  return ndate;
}
export function exceptTimeFromDate(sdate) {
  if (sdate === null || sdate === "") return "";
  return sdate.substr(0, 10);
}
export function ChangeDateFormatToKorean(sdate) {
  if (sdate === "") sdate = new Date();
  let year = sdate.getFullYear();
  let month = sdate.getMonth() + 1;
  let day = sdate.getDate();
  const ndate = year + "년 " + month + " 월" + day + " 일";
  return ndate;
}
export function ChangeDateTimeFormat(sdate) {
  if (sdate === "") sdate = new Date();
  let year = sdate.getFullYear();
  let month = ("0" + (sdate.getMonth() + 1)).slice(-2);
  let day = ("0" + sdate.getDate()).slice(-2);

  let hours = ("0" + sdate.getHours()).slice(-2);
  let minutes = ("0" + sdate.getMinutes()).slice(-2);
  let seconds = ("0" + sdate.getSeconds()).slice(-2);

  const ndate =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  return ndate;
}
export function ChangeDateMonth(sdate, imode = 0) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    let sdateString = sdate.substr(0, 7);
    if (imode === 1) sdateString = sdateString.replace(/-/g, ".");
    return sdateString;
  }
}
export function ChangeDateString(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    return sdate.replace(/-/g, ".");
  }
}
export function ChangeDateStringOnlyDate(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    sdate = sdate.substr(0, 10);
    return sdate.replace(/-/g, ".");
  }
}
export function ChangeDateStringToKorean(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    const dateArray = sdate.split("-");
    const ndate =
      dateArray[0] +
      "년 " +
      Number(dateArray[1]) +
      " 월 " +
      Number(dateArray[2]) +
      " 일";
    return ndate;
  }
}
export function ChangeDateTimeString(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    const dateArray = sdate.split(" ");
    return dateArray[0].replace(/-/g, ".") + " " + dateArray[1];
  }
}

export function ChoiceDate(sdate1, sdate2) {
  if (sdate1 !== null && sdate2 !== null) {
    return new Date(sdate1);
  } else if (sdate1 !== null) {
    return new Date(sdate1);
  } else if (sdate2 !== null) {
    return new Date(sdate2);
  } else {
    return new Date();
  }
}

export function CalculateCareers(fnum1, fnum2) {
  const fnums = fnum1 + fnum2;
  const sterms = fnums.toString();
  if (sterms === "0") return "-";
  if (sterms.indexOf(".") > 0) {
    const termsArray = sterms.split(".");
    if (Number(termsArray[0]) > 0) {
      return termsArray[0] + " 년 " + termsArray[1] + " 개월";
    } else {
      return termsArray[1] + " 개월";
    }
  } else {
    return sterms + " 년";
  }
}

export function ChangeMoneyUnit(smoney) {
  if (smoney === null || smoney === undefined || smoney === 0) {
    return "없음";
  } else {
    return addThousandPoint(smoney) + " 만원";
  }
}

export function ChangeMoneyUnitNoText(smoney) {
  if (smoney === null || smoney === undefined || smoney === 0) {
    return "없음";
  } else {
    return addThousandPoint(smoney) + " 원";
  }
}

export function remainDays(expdate) {
  if (
    expdate === undefined ||
    expdate === null ||
    expdate === "" ||
    expdate === 0
  )
    return 0;
  if (expdate.indexOf(" ") > 0) {
    let expdate_temp = expdate.split(" ");
    expdate = expdate_temp[0];
  }
  const expArray = expdate.split("-");
  const dday = new Date(
    Number(expArray[0]),
    Number(expArray[1] - 1),
    Number(expArray[2])
  );

  const today = new Date();
  let year = today.getFullYear();
  let month = ("0" + today.getMonth()).slice(-2);
  let day = ("0" + today.getDate()).slice(-2);
  const tday = new Date(Number(year), Number(month), Number(day));
  const gap = dday.getTime() - tday.getTime();
  let remaindays = Math.ceil(gap / (1000 * 60 * 60 * 24));
  if (remaindays < 0) remaindays = 0;
  return remaindays;
}
//1000단위 실시간 점검
export function realThousandPoint(snum) {
  if (snum === undefined || snum === null || snum === "") return 0;
  let snum2 = snum.toString().replace(/[^\d]+/g, "");
  return parseInt(snum2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//천단위 콤마찍기--
export function addThousandPoint(snum) {
  if (snum === undefined || snum === null || snum === "") return 0;
  return parseInt(snum)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//천단위 콤마지우기
export function removeThousandPoint(snum) {
  return snum.replace(/[^\d]+/g, "");
}
//전화번호에 - 넣기
export function changeMobileFormat(mbNumber) {
  if (mbNumber === undefined || mbNumber === "" || mbNumber === null) return "";
  return mbNumber.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    "$1-$2-$3"
  );
}
export function changeMobileNumber(mbNumber) {
  if (mbNumber === undefined || mbNumber === "" || mbNumber === null) return "";
  if (mbNumber.length > 13) {
    mbNumber = mbNumber.slice(0, -1);
  }
  return mbNumber.replace(/-/g, "");
}
//아이디 문자열 점검
//2022-08-17 고객사 요청으로 숫자조합 제거 - KSR
export function checkIDString(asValue) {
  //let regExp = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,25}$/; //  6자 이상 영문 + 숫자 조합
  let regExp = /^[a-z|A-Z|0-9|]{6,25}$/; //영문,숫자 6자 이상
  if (!regExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//2022-08-17 고객사 요청으로 닉네임은 한글도 포함 - KSR 추가
export function checkNickString(asValue) {
  let regExp = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{3,25}$/; //영문,한글,숫자 6자 이상
  if (!regExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//전화번호 검색
export function isPhone(asValue) {
  let regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
  let regExp2 = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
  if (regExp.test(asValue)) {
    return regExp.test(asValue);
  } else if (regExp2.test(asValue)) {
    return regExp2.test(asValue);
  }
}
//이메일 문자열 점검
export function checkEmailString(eString) {
  let regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  if (eString.match(regExp) != null) return true;
  else return false;
}
//비밀번호 형식 검사
export function isPassword(asValue) {
  let regExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/; //  8 ~ 16자 영문, 숫자, 특수문자 조합
  if (!regExp.test(asValue)) {
    return false;
  }
  return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}
//숫자 체크 정규식
export function onlyNumber(str) {
  return str.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
}

//사업자등록번호
export function checkCorporateRegiNumber(number) {
  number = number.replace(/\s/gi, "");
  let numberMap = number
    .replace(/-/gi, "")
    .split("")
    .map(function (d) {
      return parseInt(d, 10);
    });

  if (numberMap.length === 10) {
    let keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;

    keyArr.forEach(function (d, i) {
      chk += d * numberMap[i];
    });

    chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
  }

  return false;
}

//사업자등록번호
export function isTax(asValue) {
  //let regExp = /^(?=.*[-])(?=.*[0-9]).{8,16}$/;
  let regExp = /^(\d{3,3})+[-]+(\d{2,2})+[-]+(\d{5,5})$/;

  regExp.test("asValue");
  if (regExp.test(asValue)) {
    return regExp.test(asValue);
  } else {
    return false;
  }
}
export function isEmptyObj(obj) {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
}
//경력 숫자를 문자로 - 검색시
export function resetCareerForSearching(fterms) {
  if (fterms === undefined || fterms === "" || fterms == null) return "신입";
  if (fterms === 0) {
    return "신입";
  } else {
    const sterms = fterms.toString();
    if (sterms.indexOf(".") >= 0) {
      const termsArray = sterms.split(".");
      if (Number(termsArray[0]) > 0) {
        return "경력 " + termsArray[0] + "년 " + termsArray[1] + "개월";
      } else {
        return "경력 " + termsArray[1] + "개월";
      }
    } else {
      return "경력 " + sterms + "년";
    }
  }
}
//경력 숫자를 문자로
export function resetCareerFromNumbers(fterms) {
  if (fterms === undefined || fterms === "" || fterms == null) return "신입";
  if (fterms === 0) {
    return "신입";
  } else {
    const sterms = fterms.toString();
    if (sterms.indexOf(".") >= 0) {
      const termsArray = sterms.split(".");
      if (Number(termsArray[0]) > 0) {
        return termsArray[0] + " 년 " + termsArray[1] + " 개월";
      } else {
        return termsArray[1] + " 개월";
      }
    } else {
      return sterms + " 년";
    }
  }
}
export function resetCareerFromMonths(smonths) {
  if (smonths === undefined || smonths === "" || smonths === null)
    return "없음";
  if (smonths === 0) {
    return "없음";
  } else {
    const years = Math.floor(smonths / 12);
    const months = smonths % 12;
    let sterms = "";
    if (years > 0) {
      sterms = years + " 년";
    }
    if (months > 0) {
      if (sterms !== "") sterms += " ";
      sterms += months + " 개월";
    }
    return sterms;
  }
}
export function CalculateCareerBetweenMonths(edate, sdate) {
  if (sdate === undefined || sdate === "" || sdate === null) {
    sdate = ChangeDateFormat("");
  }
  if (edate === undefined || edate === "" || edate === null) {
    edate = ChangeDateFormat("");
  }

  const expArray1 = sdate.split("-");
  if (expArray1.length === 2) expArray1[2] = "01";
  const startday = new Date(
    Number(expArray1[0]),
    Number(expArray1[1] - 1),
    Number(expArray1[2])
  );
  const expArray2 = edate.split("-");
  if (expArray2.length === 2) expArray2[2] = "01";
  const endday = new Date(
    Number(expArray2[0]),
    Number(expArray2[1] - 1),
    Number(expArray2[2])
  );
  let months;
  months = (endday.getFullYear() - startday.getFullYear()) * 12;
  months -= startday.getMonth();
  months += endday.getMonth();
  if (months < 0) months = 0;
  return Number(months);
}
export function resetCareerBetweenDays(edate, sdate) {
  if (edate === undefined || edate === "" || edate === null) return "신입";
  if (sdate === undefined || sdate === "" || sdate === null) return "신입";
  const expArray1 = sdate.split("-");
  const startday = new Date(
    Number(expArray1[0]),
    Number(expArray1[1] - 1),
    Number(expArray1[2])
  );
  const expArray2 = edate.split("-");
  const endday = new Date(
    Number(expArray2[0]),
    Number(expArray2[1] - 1),
    Number(expArray2[2])
  );
  let years, months;
  months = (endday.getFullYear() - startday.getFullYear()) * 12;
  months -= startday.getMonth();
  months += endday.getMonth();
  if (months < 0) months = 0;
  years = Math.floor(months / 12);
  months = months % 12;

  if (years === 0 && months === 0) {
    return "신입";
  } else {
    if (years === 0) {
      return "경력  " + months + "개월";
    } else {
      if (months > 0) {
        return "경력  " + years + "년 " + months + "개월";
      } else {
        return "경력  " + years + "년";
      }
    }
  }
}
export function careerBetweenDays(edate, sdate) {
  if (edate === undefined || edate === "" || edate === null) return "재직중";
  const expArray1 = sdate.split("-");
  const startday = new Date(
    Number(expArray1[0]),
    Number(expArray1[1] - 1),
    Number(expArray1[2])
  );
  const expArray2 = edate.split("-");
  const endday = new Date(
    Number(expArray2[0]),
    Number(expArray2[1] - 1),
    Number(expArray2[2])
  );
  let years, months;
  months = (endday.getFullYear() - startday.getFullYear()) * 12;
  months -= startday.getMonth();
  months += endday.getMonth();
  if (months < 0) months = 0;
  years = Math.floor(months / 12);
  months = months % 12;

  if (years === 0 && months === 0) {
    return "";
  } else {
    if (years === 0) {
      return months + "개월";
    } else {
      if (months > 0) {
        return years + "년 " + months + "개월";
      } else {
        return years + "년";
      }
    }
  }
}
export function getCareerBetweenDays(edate, sdate) {
  if (edate === undefined || edate === "" || edate === null) return "신입";
  if (sdate === undefined || sdate === "" || sdate === null) return "신입";

  const expArray1 = sdate.split("-");
  const startday = new Date(
    Number(expArray1[0]),
    Number(expArray1[1] - 1),
    Number(expArray1[2])
  );
  const expArray2 = edate.split("-");
  const endday = new Date(
    Number(expArray2[0]),
    Number(expArray2[1] - 1),
    Number(expArray2[2])
  );
  let years, months;
  months = (endday.getFullYear() - startday.getFullYear()) * 12;
  months -= startday.getMonth();
  months += endday.getMonth();
  if (months < 0) months = 0;
  years = Math.floor(months / 12);
  months = months % 12;

  if (years === 0 && months === 0) {
    return "신입";
  } else {
    if (months > 0) {
      return years + "년 " + months + "개월";
    } else {
      return years + "년";
    }
  }
}
//회원구분
export function ChangeMemberTypeString(mode, type) {
  if (mode === 0) {
    return "일반회원";
  } else if (mode === 1) {
    return "기업회원";
  } else {
    if (type === 0) {
      return "헤드헌터(프리랜서)";
    } else {
      return "헤드헌터(서치폼)";
    }
  }
}

//주민등록번호 마킹
export function MaskingCivilCode(scode) {
  if (scode === undefined || scode === null || scode === "") {
    return "";
  }
  let ninArray = scode.split("-");
  let retString =
    ninArray[0] + "-" + ninArray[1].replace(/([0-9]{6})$/gi, "******");
  return retString;
}

//문자열 마킹
export function MaskingString(sString, ist, showString) {
  if (sString === undefined || sString === null || sString === "") {
    return "";
  }
  let newString = "";
  let ileng = sString.length;
  if (ist > 0) {
    newString = sString.substr(0, ist);
  }
  ileng = ileng - ist;
  for (let i = ist; i <= ileng; i++) {
    newString += showString;
  }
  return newString;
}

//포트폴리오 파일 저장 - status=0:임시저장/status=1:정식저장
export const file_upload = async (file, re_idx, status) => {
  if (file) {
    const formData = new FormData();
    formData.append("port_folio", file);
    let apiName = "port_folio";
    if (status === 0) {
      apiName = "port_tmp_folio";
    }

    //이미지 업로드
    await axios
      .post(`${CONFIG.SERVER_HOST}/${apiName}`, formData)
      .then((res) => {
        const { fileName } = res.data;
        // return fileName;
        file_upload_insert(fileName, re_idx, status);
      });
  }
};
export const move_file_to = async (filename, re_idx, status) => {
  const param = new URLSearchParams();
  param.append("filename", filename);
  param.append("rcp_re_idx", re_idx);
  param.append("toWhere", status);
  //이미지 업로드
  await axios.post(`${CONFIG.SERVER_HOST}/move_file_to`, param).then((res) => {
    if (res.data.retvalue === 1) {
    } else {
      console.log("move Fail");
    }
  });
};

//파일 업로드
export const file_upload1 = async (api, file, re_idx, status) => {
  const formData = new FormData();

  if (file) {
    formData.append(api, file);

    // //이미지 업로드
    await axios
      .post(`${CONFIG.SERVER_HOST}/${api}`, formData)
      .then(async (res) => {
        const { fileName } = res.data;
        //return fileName;
        resume_ufile_update(fileName, re_idx, status);
        return fileName;
      });
  }
};

//파일 업로드
export const file_upload2 = async (api, file, re_idx) => {
  const formData = new FormData();
  if (file) {
    formData.append(api, file);
    // //이미지 업로드
    await axios
      .post(`${CONFIG.SERVER_HOST}/port_folio`, formData)
      .then((res) => {
        //const { fileName } = res.data;
        //  file_upload_insert(fileName, re_idx);
      });
  }
};

//정식 status = 1/임시 저장 status = 0
export const file_upload_insert = async (rcp_ufile, re_idx, status) => {
  const param = new URLSearchParams();
  param.append("rcp_re_idx", re_idx);
  param.append("rcp_ufile", rcp_ufile);
  param.append("status", status);
  param.append("user_idx", Number(localStorage.getItem("mem_idx")));

  await axios
    .post(`${CONFIG.SERVER_HOST}/port_folio_file_insert`, param)
    .then((res) => {
      console.log(res);
    });
};

//temp_resume_ufile_update
export const resume_ufile_update = async (re_ufile, re_idx, status) => {
  const param = new URLSearchParams();
  param.append("re_ufile", re_ufile);
  param.append("re_photo", 1);
  param.append("re_idx", re_idx);
  param.append("status", status);

  await axios
    .post(`${CONFIG.SERVER_HOST}/resume_ufile_update`, param)
    .then((res) => {
      console.log(res);
    });
};

//첨부파일 확장자 체크
export function checkFileName(str) {
  let ext = str.split(".");
  let ext1 = "." + ext[1].toLowerCase();
  let reg = CONFIG.fileExtOption;

  if (ext1.match(reg)) {
    return true;
  } else {
    return false;
  }
}

//코드생성 - 랜덤함수
export function randomString(stringLength) {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";

  let randomstring = "";
  for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }
  return randomstring;
}

//공통 테이블 조회 함수
export const common_select_function = (column, table, where, option) => {
  const param = new URLSearchParams();
  //column
  param.append("column", column);
  param.append("table", table);
  param.append("where", where);
  param.append("option", option);

  // console.log('테이블 조회 함수 !!!!11111', table);
  const data = axios
    .post(`${CONFIG.SERVER_HOST}/_get_select_table`, param)
    .then((res) => {
      console.log(res);
      // console.log('테이블 조회 함수 !!!!222222');
      if (res.data.retvalue === 1) {
        // console.log('테이블 조회 함수 !!!!333333');

        console.log(res.data);
        return res.data.result;
      } else {
        return 0;
      }
    });
  return data;
};

export const send_push = async (title, content) => {
  const FCM_KEY =
    "AAAAWg86CJw:APA91bEg9ruDA-NE9K-fm2pPlwKF9Ty84QPIV1EVk8jaAiw8SYdUjdagZtfY21styfbKCifOejgvRYdCqwJWIWVpZWcPkaUa3Srzls29PhUR8sJ-cGmg2gsbU7b2QIOzqOoi6u9LXww-";
  /* const FCM_URL = "https://fcm.googleapis.com/fcm/send"; */
  const FCM_TOPIC = "survey";

  // const token = await axios.get(`${CONFIG.SERVER_HOST_V2}/api/v2/front/getUserToken?user_id=${localStorage.getItem('user_id')}`).then((response) => {
  //   console.log("reviewCount1", response.data[0]);
  //   return  response.data[0].use_count;
  // });
  // const title = await axios.get(`${CONFIG.SERVER_HOST_V2}/api/v2/front/getPushTitle?id=2`).then((response) => {
  //   console.log("reviewCount1", response.data[0]);
  //   return  response.data[0].use_count;
  // });

  // const content = await axios.get(`${CONFIG.SERVER_HOST_V2}/api/v2/front/getPushContent?id=2`).then((response) => {
  //   console.log("reviewCount1", response.data[0]);
  //   return  response.data[0].use_count;
  // });

  /*  let key = ''; */

  // let to =token;
  let to = `/topics/${FCM_TOPIC}`;
  let notification = {
    title: title,
    body: content,
    icon: "아이콘",
    click_action: "url",
    android_channel_id: "survey",
  };

  fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST",
    headers: {
      Authorization: "key=" + FCM_KEY,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      notification: notification,
      to: to,
    }),
  })
    .then(function (response) {
      console.log("fetch success");
      console.log(response);
      alert("PUSH 발송 되었습니다");
    })
    .catch(function (error) {
      console.error(error);
      alert("fetch error");
    });
};

export function ChangeDate(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    const dateArray = sdate.split(" ");
    return dateArray[0];
  }
}

export const resetNavMenu = (imenu) => {
  //console.log(imenu);
  let navBlock = document.querySelector(".nav");
  let navItems = navBlock.querySelectorAll(".depth1");
  // console.log(navBlock)
  // console.log(navItems)
  // console.log(imenu)
  for (let i = 0; i < navItems.length; i++) {
    if (navItems[i].classList.contains("active")) {
      navItems[i].classList.remove("active");
    }
  }
  navItems[imenu].classList.add("active");
};

export const resetNavSubMenu = (main_idx, sub_idx) => {
  let navBlock = document.querySelector(".nav");
  let navItems = navBlock.querySelectorAll(".depth2 > li");

  //console.log(navBlock);
  //console.log(navItems);
  for (let i = 0; i < navItems.length; i++) {
    if (navItems[i].classList.contains("active")) {
      navItems[i].classList.remove("active");
    }
  }
  if (sub_idx < 0) {
    return;
  }
  const depth1Boxes = document.querySelectorAll(".depth1");
  //console.log(depth1Boxes);
  const depth2Boxes = depth1Boxes[main_idx].querySelector(".depth2");
  const liBoxes = depth2Boxes.querySelectorAll("li");
  if (!liBoxes[sub_idx].classList.contains("active")) {
    liBoxes[sub_idx].classList.add("active");
  }
};
//Company - Heding
export const resetNavSubMenuForHeding = (sub_idx) => {
  let navBlock = document.querySelector(".nav");
  let navItems = navBlock.querySelectorAll(".depth2 > li");

  // console.log(navBlock);
  // console.log(navItems);
  for (let i = 0; i < navItems.length; i++) {
    if (navItems[i].classList.contains("active")) {
      navItems[i].classList.remove("active");
    }
  }
  if (sub_idx < 0) {
    return;
  }
  const depth1Boxes = document.querySelectorAll(".depth1");

  // console.log(depth1Boxes);
  const depth2Boxes =
    depth1Boxes[depth1Boxes.length - 1].querySelector(".depth2");
  console.log(depth2Boxes);
  const liBoxes = depth2Boxes.querySelectorAll("li");
  // console.log(liBoxes);
  for (let j = 0; j < liBoxes.length; j++) {
    if (liBoxes[j].getAttribute("id") === "heding_" + sub_idx) {
      if (!liBoxes[j].classList.contains("active")) {
        liBoxes[j].classList.add("active");
      }
      break;
    }
  }
};
//화면조정
export const mobileWrap = () => {
  if (window.innerWidth < 890) {
    let tr = $(".tr");
    if (!tr.children().hasClass("m_wrap")) {
      tr.wrapInner("<div class='m_wrap'>");
      for (let i = 0; i < tr.length; i++) {
        let mWrap = $(tr[i]).children(".m_wrap");
        mWrap.after($(mWrap).children(".row_btn"));
      }
    }
  } else {
    let mWrap = $(".m_wrap");
    mWrap.children().unwrap();
  }
};

export const mobileWrapSearchTalent = () => {
  if (window.innerWidth < 1024) {
    let tr = document.querySelectorAll(".tr");
    if (!$(tr).children().hasClass("m_colgroup")) {
      for (let i = 0; i < tr.length; i++) {
        $(tr[i])
          .find(".m_group")
          .wrapAll("<div class='m_colgroup group1'></div>");
        $(tr[i])
          .find(".row_sort")
          .wrapAll("<div class='m_colgroup group2'></div>");
      }
    }
  } else {
    $(".m_colgroup").children().unwrap();
  }
};

export const addDateDBFormat = (sdate, adddays) => {
  let fdate = new Date();
  if (sdate !== null && sdate !== "" && sdate !== "null") {
    fdate = new Date(sdate);
  }
  if (adddays === 0) return sdate;
  fdate.setDate(fdate.getDate() + adddays);
  return ChangeDateFormat(fdate);
};

//날짜형식변경 - 인증경력
export const dateToString = (date) => {
  return (
    date.getFullYear() +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    date.getDate().toString().padStart(2, "0")
  );
};

//모바일 인증
export function isReactNative() {
  if (isMobile()) return true;
  return false;
}
export function isMobile() {
  let UserAgent = navigator.userAgent;
  if (
    UserAgent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null ||
    UserAgent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  } else {
    return false;
  }
}

//휴대폰 인증 - 회원가입시 ------------------------------------------------------------------------
export const Certification = (
  letInputs,
  setInputs,
  letAuth,
  setAuth,
  imode
) => {
  console.log("Common::Certification");
  const IMP = window.IMP;
  const userCode = "imp42964606";
  const data = {
    // param
    merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
    company: "HEDING",
    carrier: "",
    name: "",
    phone: "",
    m_redirect_url: `${Config.m_redirect_url}/JoinUs`, // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
    popup: true, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
  };
  const data2 = {
    // param
    merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
    company: "HEDING",
    carrier: "",
    name: "",
    phone: "",
  };
  const data3 = {
    // param
    merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
    company: "HEDING",
    carrier: "",
    name: "",
    phone: "",
    // m_redirect_url: `${Config.m_redirect_url}/JoinUs`, // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
    popup: true, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
  };

  let certData = { ...data3 };
  if (isReactNative()) {
    // 5. 리액트 네이티브 환경에 대응하기
    if (window.ReactNativeWebView) {
      const params = {
        userCode, // 가맹점 식별코드
        data2, // 본인인증 데이터
        type: "certification", // 결제와 본인인증 구분을 위한 필드
      };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
      return false;
    }
  } else {
    certData = { ...data };
  }
  IMP.init(userCode);
  IMP.certification(certData, (rsp) => {
    if (rsp.success === true) {
      const params = new URLSearchParams();
      params.append("imp_uid", rsp.imp_uid);
      axios.post(`${CONFIG.SERVER_HOST}/certifications`, params).then((res) => {
        console.log(res.data);
        const mobile = res.data.phone;
        const name = res.data.name;
        const birthday = res.data.birthday;
        const gender = res.data.gender === "male" ? 0 : 1;
        if (imode === 0) {
          setInputs({
            ...letInputs,
            mobile: mobile,
            certification: true,
            name: name,
            gender: gender,
            birthDay: birthday,
          });
        } else if (imode === "company") {
          setInputs({ ...letInputs, mobile: mobile, certification: true });
        } else {
          //임의 인증(강제) 인듯...??
          setInputs({ ...letInputs, mem_mobile: mobile, mem_certification: 1 });
        }
        setAuth({ ...letAuth, mobile: true });
      });
    } else {
      alert(`인증에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
    }
  });
}; //끝.휴대폰 인증 - 회원가입시

//휴대폰 인증 - 배열안에서 -----------------------------------------------------------------------------
export const CertificationList = (
  letSubMemberList,
  setSubMemberList,
  letSubAuthList,
  setSubAuthList,
  mem_idx
) => {
  //배열에서 변경할 순서 찾기
  const findIndex1 = letSubMemberList.findIndex(
    (element) => element.mem_idx === 0
  );
  const findIndex2 = letSubAuthList.findIndex(
    (element) => element.mem_idx === 0
  );

  const IMP = window.IMP;
  const userCode = "imp42964606";
  const data = {
    // param
    merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
    company: "HEDING",
    carrier: "",
    name: "",
    phone: "",
    m_redirect_url: `${Config.m_redirect_url}/JoinUs`, // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
    popup: true, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
  };
  const data2 = {
    // param
    merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
    company: "HEDING",
    carrier: "",
    name: "",
    phone: "",
  };
  const data3 = {
    // param
    merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
    company: "HEDING",
    carrier: "",
    name: "",
    phone: "",
    // m_redirect_url: `${Config.m_redirect_url}/JoinUs`, // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
    popup: true, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
  };

  let certData = { ...data3 };
  if (isReactNative()) {
    // 5. 리액트 네이티브 환경에 대응하기
    if (window.ReactNativeWebView) {
      const params = {
        userCode, // 가맹점 식별코드
        data2, // 본인인증 데이터
        type: "certification", // 결제와 본인인증 구분을 위한 필드
      };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
      return;
    }
  } else {
    certData = { ...data };
  }
  IMP.init(userCode);
  IMP.certification(certData, (rsp) => {
    if (rsp.success === true) {
      const params = new URLSearchParams();
      params.append("imp_uid", rsp.imp_uid);
      axios.post(`${CONFIG.SERVER_HOST}/certifications`, params).then((res) => {
        console.log(res.data);
        const mobile = res.data.phone;
        //전번변경
        let copyData1 = [...letSubMemberList];
        copyData1[findIndex1] = {
          ...copyData1[findIndex1],
          mem_mobile: mobile,
          mem_certification: 1,
        };
        setSubMemberList(copyData1);
        //점검 확인
        let copyData2 = [...letSubAuthList];
        copyData2[findIndex2] = { ...copyData2[findIndex2], mobile: true };
        setSubAuthList(copyData2);
      });
    } else {
      alert(`인증에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
    }
  });
};

//문자열에 공백이 있는 경우
export function blank_pattern(asValue) {
  let blankStr = /[\s]/g;
  if (blankStr.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//문자열에 특수문자가 있는 경우
export function special_pattern(asValue) {
  let specialStr = /[`~!@#$%^&*|\\\'\";:\/?]/gi; //eslint-disable-line
  if (specialStr.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//문자열에 한글, 영문만 입력 가능
export function name_pattern(asValue) {
  let nameRegExp = /^[가-힣a-zA-Z]+$/;
  if (nameRegExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//문자열에 숫자가 있는 경우
export function include_num(asValue) {
  let numRegExp = /^[0-9]+$/;
  if (numRegExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//접속자 IP 주소 가져오기
export const getIPData = async (letInputs, setInputs, sname) => {
  await axios.get("https://geolocation-db.com/json/").then((res) => {
    if (sname === "") {
      setInputs(res.data.IPv4);
    } else {
      setInputs({ ...letInputs, [sname]: res.data.IPv4 });
    }
  });
};

//이메일중복검사 버튼 표시 결정
export const CheckDuplicationObject = (objID, bmode) => {
  //중복확인버튼 표시 유무
  const objBotton = document.getElementById(objID);
  console.log(objBotton);
  console.log(bmode);
  if (!bmode) {
    if (objBotton.classList.contains("hide"))
      objBotton.classList.remove("hide");
  } else {
    if (!objBotton.classList.contains("hide")) objBotton.classList.add("hide");
  }
};
//파일 input을 리셋
export const resetFileForm = (elFileForm) => {
  let orgParent = elFileForm.parentNode;
  let orgNext = elFileForm.nextSibling;

  let tmp = document.createElement("form");
  tmp.appendChild(elFileForm);

  tmp.reset();
  orgParent.insertBefore(elFileForm, orgNext);
};

//문자열 변환 —— 데이타베이스 저장
export const escapeHtml = (stext) => {
  if (stext === undefined || stext === null || stext === "") return "";
  return stext
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};
export const unescapeHtml = (stext) => {
  if (stext === undefined || stext === null || stext === "") return "";
  return stext
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
};

//파일 크기 제한
export const CheckLimitFileSize = (selFile, kbSize) => {
  const fileSize = selFile.size;
  const maxSize = kbSize * 1024;
  if (fileSize > maxSize) return false;
  else return true;
};

//br 처리
export const changeTextArea = (text) => {
  console.log(typeof text);
  let br_text = "";
  br_text = text.replaceAll(/(\n|\r\n)/g, "<br>");
  // br_text = text?.replace(/&lt;/g, '<');
  // br_text = text?.replace(/&gt;/g,'>');
  // br_text = text?.replace(/&nbsp;/g,' ');
  // br_text = text?.replace(/&amp;/g, '&');
  // br_text = text?.replace(/&quot;/g, '"');
  console.log(br_text);
  return br_text;
};

//총경력계산
export const ResetTotalCareer = (
  resumeAddCareerList,
  letInputs,
  setInputs,
  setTotalAddWork
) => {
  const icount = resumeAddCareerList.length;
  let imonth = 0;
  for (let i = 0; i < icount; i++) {
    let sdate = resumeAddCareerList[i].rec_sdate;
    let edate = resumeAddCareerList[i].rec_edate;
    console.log(edate, sdate, CalculateCareerBetweenMonths(edate, sdate));
    imonth += CalculateCareerBetweenMonths(edate, sdate);
  }
  const fyear = Math.floor(imonth / 12);
  const fmonth = imonth - fyear * 12;
  //const numTerms = fyear+'.'+fmonth;
  const numTerms = `${fyear}.${fmonth}`;
  setInputs({ ...letInputs, careeradd_year: numTerms });
  let strCareer = "";
  if (fyear > 0) {
    strCareer = fyear + " 년";
  }
  if (fmonth > 0) {
    strCareer += " " + fmonth + " 개월";
  }
  setTotalAddWork(strCareer);
};

export const IfNotNull = (input) => {
  if (input !== null && input !== undefined && input !== "") {
    return true;
  }
  return false;
};

export const chkEmpty = (data) => {
  if (data === undefined) {
    return false;
  } else if (data === null) {
    return false;
  } else if (data === "null") {
    return false;
  } else {
    return true;
  }
};

export const chkEmpty2 = (data) => {
  if (data === undefined) {
    return false;
  } else if (data === null) {
    return false;
  } else if (data === "null") {
    return false;
  } else if (data === "") {
    return false;
  } else {
    return true;
  }
};

// export const TenThousandUnits= (input)=>{
//   return input.toString().replace(/\B(?<!\.\d*)(?=(\d{4})+(?!\d))/g, ",");
// }

//공고명  ... 처리
export const em_titleLength = (txt, len, lastTxt) => {
  if (len === "" || len === null) {
    // 기본값
    len = 5;
  }
  if (lastTxt === "" || lastTxt === null) {
    // 기본값
    lastTxt = "...";
  }
  if (txt.length > len) {
    txt = txt.substr(0, len) + lastTxt;
  }
  return txt;
};

//문자열이 숫자인지 여부 확인
export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};

//시작.앱 로그인 성공시 fcm 토큰 저장을 위한 아이디 전송 ------------------------------------------------------------------------
export const AppLoginSuccess = (idx) => {
  if (isReactNative()) {
    if (window.ReactNativeWebView) {
      //리액트 네이티브 환경 체크
      const params = { mem_idx: idx };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
      return;
    }
  }
}; //끝.앱 로그인 성공시 fcm 토큰 저장을 위한 아이디 전송

// 사업자 번호 자동 하이픈 추가.
export function bizCodeAutoHypen(companyNum) {
  companyNum = companyNum.replace(/[^0-9]/g, "");
  let tempNum = "";

  if (companyNum.length < 4) {
    return companyNum;
  } else if (companyNum.length < 6) {
    tempNum += companyNum.substr(0, 3);
    tempNum += "-";
    tempNum += companyNum.substr(3, 2);
    return tempNum;
  } else if (companyNum.length < 11) {
    tempNum += companyNum.substr(0, 3);
    tempNum += "-";
    tempNum += companyNum.substr(3, 2);
    tempNum += "-";
    tempNum += companyNum.substr(5);
    return tempNum;
  } else {
    tempNum += companyNum.substr(0, 3);
    tempNum += "-";
    tempNum += companyNum.substr(3, 2);
    tempNum += "-";
    tempNum += companyNum.substr(5);
    return tempNum;
  }
}

//os 체크
export const chkOS = () => {
  let UserAgent = navigator.userAgent;
  if (UserAgent.match(/iPhone|iPod/i)) {
    return false;
  } else if (UserAgent.match(/Android/i)) {
    return false;
  } else {
    return true;
  }
};

export const chkOS2 = () => {
  let UserAgent = navigator.userAgent;
  if (UserAgent.match(/iPhone|iPod/i)) {
    return 0;
  } else if (UserAgent.match(/Android/i)) {
    return 1;
  } else {
    return 2;
  }
};

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberToKorean(number) {
  let inputNumber = number < 0 ? false : number;
  let unitWords = ["", "만", "억", "조", "경"];
  let splitUnit = 10000;
  let splitCount = unitWords.length;
  let resultArray = [];
  let resultString = "";

  for (let i = 0; i < splitCount; i++) {
    let unitResult =
      (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString =
      String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
  }

  return resultString;
}

//전년대비 근로소득 차이 구하기
//previncome : 선택한 년도의 전년도 근로소득
//income : 선택한 년도의 근로소득
export function year_over_year(previncome, income) {
  const val = Math.ceil(((income - previncome) / previncome) * 100);

  if (val >= 0) {
    return `+ ${val}%`;
  } else {
    return `- ${Math.abs(val)}%`;
  }
}

export function getDatesStartToLast(startYear, lastYear) {
  console.log(startYear <= lastYear);
  let result = [];
  let curDate = startYear;
  while (curDate <= lastYear) {
    result.push(curDate);
    curDate += 1;
  }

  return result;
}

export const onDownloadFile = async (path, filename) => {
  const res = await fetch(
    `${CONFIG.SERVER_HOST}/resume_file_down?path=${path}&file_name=${filename}`
  );
  const blob = await res.blob();
  download(blob, filename);
};

export const onDownloadUrl = async (Url, filename) => {
  const res = await fetch(`${Url}`);
  const blob = await res.blob();
  download(blob, filename);
};

export const onDownloadFilePDF = async (path, filename, realfilename) => {
  const res = await fetch(
    `${CONFIG.SERVER_HOST}/resume_file_down?path=${path}&file_name=${filename}`
  );
  const blob = await res.blob();
  download(blob, realfilename);
  onFileDelete("pdf", filename);
};

export const onFileDelete = async (path, filename) => {
  const sendData = { path: `${path}`, filename: `${filename}` };
  await axios
    .post(`${CONFIG.SERVER_HOST}/delFile`, sendData, CONFIG.header)
    .then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        console.log("삭제 성공");
      } else {
        console.log("삭제 실패");
      }
    })
    .catch((error) => {
      console.log(error);
      alert("파일 삭제 실패!");
      // 예외 처리
    });
};

export const countNumber = (number) => {
  console.log(number < 0 ? false : number);
  let val = "";
  if (number > 0) {
    const len = getDigit(number);
    console.log(len);
    val =
      (number / 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 만원";
  }
  return val;
};

function getDigit(num) {
  num = num.toString();
  var i = 0;
  while (num[i]) {
    i++;
  }
  return i;
}

export function numberFormat2(x) {
  if (x > 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export function numberToKorean2(pWon) {
  let won = (pWon + "").replace(/,/g, "");
  let arrWon = ["원", " 만", "억"];
  let changeWon = "";
  let pattern = /(-?[0-9]+)([0-9]{4})/;
  while (pattern.test(won)) {
    won = won.replace(pattern, "$1,$2");
  }

  // console.log(won);
  let arrCnt = won.split(",").length - 1;
  // console.log(arrCnt);
  for (let ii = 0; ii < won.split(",").length; ii++) {
    if (arrWon[arrCnt] === undefined) {
      console.log("값의 수가 너무 큽니다.");
      break;
    }
    let tmpwon = 0;
    for (let i = 0; i < won.split(",")[ii].length; i++) {
      let num1 = won.split(",")[ii].substring(i, i + 1);
      tmpwon = tmpwon + Number(num1);
    }

    // console.log(tmpwon);

    if (tmpwon > 0) {
      // console.log(changeWon);
      changeWon += won.split(",")[ii] + arrWon[arrCnt]; //55억0000만0000원 이런 형태 방지 0000 다 짤라 버린다
    }
    arrCnt--;
  }

  // console.log(changeWon);
  return changeWon;
}

//로컬스토리지 지우기
export function removeLocalstorageItem() {
  for (let key in localStorage) {
    if (!localStorage.hasOwnProperty(key)) {
      continue;
    }
    localStorage.removeItem(key);
  }
}

export function bizNoFormat(data) {
  var formatData = "";
  try {
    if (data?.length === 10) {
      formatData = data.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
    }
  } catch (e) {
    console.log(e);
    formatData = data;
  }
  return formatData;
}

export function numberToKorean3(number) {
  let inputNumber = number < 0 ? false : number;
  let str = String(inputNumber).slice(-4);
  let newStr = String(inputNumber).replace(str, "0000");

  console.log(newStr);
  let NewNum = Number(newStr);

  let unitWords = ["", "만", "억", "조", "경"];
  let splitUnit = 10000;
  let splitCount = unitWords.length;
  let resultArray = [];
  let resultString = "";

  for (let i = 0; i < splitCount; i++) {
    let unitResult =
      (NewNum % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString =
      String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
  }

  return resultString;
}

//시작.앱 Topic 알림을 위한 회원 모드 전송 ------------------------------------------------------------------------
export const AppTopicSetting = (str) => {
  if (isReactNative()) {
    if (window.ReactNativeWebView) {
      //리액트 네이티브 환경 체크
      const params = {
        push_YN: str,
        mem_mode: localStorage.getItem("mem_mode"),
      };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
      return;
    }
  }
}; //끝.앱 Topic 알림을 위한 회원 모드 전송

//mem_mode에 따라 회원 구분
export const changeEmMode = (element) => {
  if (element.mem_mode === 0) element.mem_mode = "일반";
  if (element.mem_mode === 1) element.mem_mode = "기업";
  if (element.mem_mode === 2) element.mem_mode = "헤드헌터";
};

export const googleSignOut = () => {
  if (window.gapi.auth2?.getAuthInstance()) {
    const auth2 = window.gapi.auth2?.getAuthInstance();
    if (auth2 !== null) {
      auth2
        .signOut()
        .then(auth2.disconnect().then((res) => console.log(res)))
        .catch((e) => console.log(e));
    }
  }
};

export const split_1 = (str) => {
  // console.log(String(str));

  const arr1 = String(str).split(".");

  return arr1[0];
};

export const split_2 = (str) => {
  // console.log(str);
  const arr2 = String(str).split(".");

  return arr2[1];
};

export const split_2_2 = (str, bool) => {
  // console.log(str);
  const arr2 = String(str).split(".");
  let month = "";

  if (bool === "1" && arr2[1].length <= 1) {
    month = `${arr2[1]}0`;
  } else if (bool === "1" && arr2[1].length > 1) {
    month = arr2[1];
  } else {
    month = arr2[1];
  }

  return month;
};

export const getMonthDiff = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  const currDay = 24 * 60 * 60 * 1000;
  const currMonth = currDay * 30;
  const currYear = currMonth * 12;

  const diffDate = date2.getTime() - date1.getTime();
  const yy = parseInt(diffDate / currYear);
  let mm = parseInt(diffDate / currMonth);

  if (yy > 0) {
    if (mm === 12) {
      mm = 0;
    } else {
      mm = mm % 12;
    }
  }

  if (yy > 0 && mm === 0) {
    return `${yy <= 0 ? `` : `${yy}년`}`;
  } else if (mm === 0) {
    return `0개월`;
  } else {
    return `${yy <= 0 ? `` : `${yy}년`} ${mm}개월`;
  }
};

export const getMonthDiff2 = (d1, d2) => {
  const data1 = d1.split("-");
  const data2 =
    d2 === null
      ? moment(new Date()).format("YYYY-MM-DD").split("-")
      : d2.split("-");
  const t1 = monthDiff2(
    new Date(data1[0], data1[1]),
    new Date(data2[0], data2[1])
  );

  let yy;
  let mm;

  if (t1 >= 12) {
    yy = parseInt(t1 / 12);
    mm = t1 % 12;
  } else {
    yy = 0;
    mm = t1;
  }

  if (yy > 0 && mm === 0) {
    return `${yy <= 0 ? `` : `${yy}년`}`;
  } else if (mm === 0) {
    return `0개월`;
  } else {
    return `${yy <= 0 ? `` : `${yy}년`} ${mm}개월`;
  }
};

export const monthDiff2 = (dateFrom, dateTo) => {
  //console.log(dateTo.getMonth() , dateFrom.getMonth(), dateTo.getFullYear() , dateFrom.getFullYear());
  const numberOfMonths =
    (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
    (dateTo.getMonth() - dateFrom.getMonth());
  //if(numberOfMonths>12){
  //console.log("달별 range 최대 12개월 :",numberOfMonths);
  //}
  //return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) //잘못됨 계산식
  return numberOfMonths;
};

//로컬스토리지 지우기
export function appLogout() {
  const logout = true;
  const params = {
    logout,
  };
  const paramsToString = JSON.stringify(params);
  window.ReactNativeWebView.postMessage(paramsToString);
}

//카드번호
export const cardSerialNumberFormatter = (serialnumber) => {
  if (serialnumber.length > 19) {
    serialnumber = serialnumber.slice(0, -1);
  }
  return serialnumber.match(/[0-9●]{1,4}/g)?.join("-") || serialnumber;
};

//사업자등록번호 하이픈 & 자릿수
export const licenseNum = (str) => {
  str = str.replace(/[^0-9]/g, "");
  let tmp = "";
  if (str.length < 4) {
    return str;
  } else if (str.length < 6) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 2);
    tmp += "-";
    tmp += str.substr(5);
    return tmp;
  }
};

//숫자와 하이픈만 허용
export const numHyphen = (val) => {
  let numHyphen = /[^0123456789-]/g;
  // 숫자와 하이픈이 아닌 기타 문자가 들어있는 경우
  if (numHyphen.test(val)) {
    return false;
  } else {
    return true;
  }
};

//숫자와 영어만 허용
export const numEnglish = (val) => {
  let numHyphen = /^[A-Za-z0-9]+$/;
  // 숫자와 영어가 아닌 기타 문자가 들어있는 경우
  if (numHyphen.test(val)) {
    return false;
  } else {
    return true;
  }
};

//앱으로 회원 idx 전송 ------------------------------------------------------------------------
export const AppSendIdx = () => {
  if (isReactNative()) {
    if (window.ReactNativeWebView) {
      //리액트 네이티브 환경 체크
      const params = { chat_online_idx: localStorage.getItem("mem_idx") };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
      return;
    }
  }
}; //끝.앱으로 회원 idx 전송

//한글만 허용
export const onlyKorean = (val) => {
  // eslint-disable-next-line
  let regexp = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
  if (regexp.test(val)) {
    return false;
  } else {
    return true;
  }
};

//
export const checkElapsedDay = function (fromDay, toDay) {
  var elapsed = toDay.getTime() - fromDay.getTime();
  var elapsedDay = elapsed / 1000 / 60 / 60 / 24; // 두날짜의 차이 일수
  console.log("총 지난 일수 : " + elapsedDay + "일");

  var year;
  var month;
  var day;

  if (elapsedDay < 30) {
    day = elapsedDay;
    return day + "일 지났습니다.";
  } else if (elapsedDay < 365) {
    month = Math.floor(elapsedDay / 30);
    day = elapsedDay - month * 30;
    return month + "개월 " + day + "일 지났습니다";
  } else {
    year = Math.floor(elapsedDay / 365);
    month = Math.floor((elapsedDay - year * 365) / 30);
    day = elapsedDay - year * 365 - month * 30;
    return year + "년 " + month + "개월 " + day + "일 지났습니다.";
  }
};

export const getMaxLength = (arr) => {
  const arrData = arr?.career_tip_content.split(/(?:\r\n|\r|\n)/g);
  const leng = find_longest_word(arrData);
  const maxValue = Math.max.apply(null, leng);
  console.log(maxValue);
  const num = Number(`${maxValue}0`) + 10;

  return num;
};

const find_longest_word = (arr) => {
  let wordLength = [];
  for (let i = 0; i < arr?.length; i++) {
    wordLength.push(arr[i].split("")?.length);
  }
  return wordLength;
};

export const save_downloads_count = async (column) => {
  // 컬럼명 정의
  // resume
  // reputation_of_acquaintances
  // peer_reputation
  const sendData = {
    column: column,
    param: 1,
  };
  await axios
    .post(`${CONFIG.SERVER_HOST}/_save_download_count`, sendData, CONFIG.header)
    .then((res) => {
      console.log(res.data); //성공 or 실패에 대한 메시지 방출
    })
    .catch((error) => {
      console.log(error);
    });
};
