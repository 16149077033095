import React, { useEffect,useState } from 'react';
import { useNavigate } from "react-router-dom";

const TossPayFailed = () => {
  const navigator = useNavigate();
  const [varPayments,setPayments] = useState({
    code:"",
    message:"",
    orderId:""
  });
  console.log(window.location.href);
  useEffect(() => {
    if(window.location !== undefined && window.location.href !== null) {
      const getUrl = window.location.href;
      console.log(getUrl);
      let temp = getUrl.split('?'); //전달값 전체
      let data = temp[1].split('&');  //전달변수들 항목으로 분리
      console.log(data);

      let newForms = {};
      let orderCode = ""; 
      let errMessage = "";
      for(let i=0; i < data.length; i++) {
        let opt = data[i].split('=');       
        if(opt[0] === "code") { 
          Object.assign(newForms,{code:opt[1]});
        } else if(opt[0] === "message") { 
          errMessage = opt[1];
          Object.assign(newForms,{message:opt[1]});
        } else if(opt[0] === "orderId") { 
          orderCode = opt[1];
          Object.assign(newForms,{orderId:opt[1]});
        }
      }
      setPayments(newForms);
      window.alert('결제오류\n결제코드 : '+orderCode+'\n메시지 : '+errMessage);
      navigator('/Company/ComVoucher/VoucherBuy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.href]);
  return (
    <div>
      <p id="output">{"결제오류:"+varPayments.message}</p>
    </div>
  );
}
export default TossPayFailed;