/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { ChangeDateString, ChangeMoneyUnit, resetNavMenu, em_titleLength } from "../../components/Common/Common";
import _ from "lodash";

import WonLogoIcon_pc from "img/main/won.png";
import WonLogoIcon_m from "img/main/won_m.png";

import RecruitViewInfo from "../../components/Recruit/RecruitViewInfo";
import RecruitViewTit from "../../components/Recruit/RecruitViewTit";
import RecruitViewCompany from "../../components/Recruit/RecruitViewCompany";
import RecruitViewPage from "../../components/Recruit/RecruitViewPage";

const MainView = ({ varHeadHunter, setHeadHunter, PageFolder }) => {

  //useLocation -> useParams 변경 2022-08-02 khs
  //채용공고 idx
  const { em_idx, em_headhunter } = useParams();
  //const location = useLocation();
  const navigator = useNavigate();

  let user_idx = 0;
  if (localStorage.getItem("mem_idx") !== undefined && localStorage.getItem("mem_idx") !== null) {
    user_idx = localStorage.getItem("mem_idx");
  }
  //const user_idx = localStorage.getItem("mem_idx") ? ;



  let HedingPage = "/MyHeding/MyHeding6";
  let ResumePage = "/MyResume/ResumeView";
  if (PageFolder !== "/MyRecruit/") {
    HedingPage = "";
    ResumePage = "";
  }

  const [varEmIdx, setEmIdx] = useState(0); //채용공고번호
  useEffect(() => {
    if (em_idx !== 0) {
      console.log('0000000');
      // var em_idx = location.state.em_idx;
      // var em_headhunter = Number(em_headhunter);
      // console.log(location, em_headhunter);
      const HeadHunter = Number(em_headhunter);
      const emIdx = Number(em_idx);
      setHeadHunter(HeadHunter);
      setEmIdx(emIdx);
      resetNavMenu(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [em_idx, em_headhunter, varHeadHunter]);

  //보여줄 채용공고 세부정보 가져오기
  const [varEmployInfo, setEmployInfo] = useState({});
  const [varSimilarEmployList, setSimilarEmployList] = useState([]);

  useEffect(() => {
    if (varEmIdx > 0) {
      window.scrollTo(0, 0);
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("em_idx", varEmIdx);
      console.log("em_idx", varEmIdx, user_idx);
      axios
        //2022-08-20 비회원도  상세화면 접근 가능 - KSR
        .post(`${CONFIG.SERVER_HOST}/_get_employ_information`, params)
        //.post(`${CONFIG.SERVER_HOST}/_get_employ_information2`, params)
        .then((res) => {
          console.log("/_get_employ_information::results", res.data.results);
          if (res.data.retvalue === 1) {
            console.log("/_get_employ_information::simEmploys", res.data.simEmploys);
            setEmployInfo(res.data.results);
            setSimilarEmployList(res.data.simEmploys);
          } else {
            alert(res.data.message);
            navigator(-1);
          }
        });
    }
    setHeadHunter(varHeadHunter);
    /* 2022-08-20 비회원도 상세보기 가능 - KSR
    if (
      localStorage.getItem("mem_idx") === undefined ||
      localStorage.getItem("mem_idx") === null
    ) {
      console.log(localStorage.getItem("mem_idx"));
      navigator("/Login");
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varEmIdx, varHeadHunter, navigator, user_idx]);

  console.log(varSimilarEmployList)

  //관심채용공고등록및 해재 - 0:현재공고/1:관련채용공고
  const onSelectEmployInterestHandler = (e, imode, em_idx) => {
    if (user_idx > 0) {
      let ichecked = 1;
      if (e.currentTarget.classList.contains("active")) {
        ichecked = 0;
      }

      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("em_idx", em_idx);
      params.append("checked", ichecked);

      axios.post(`${CONFIG.SERVER_HOST}/_update_my_interest_employ`, params).then((res) => {
        window.alert(res.data.message);
        if (res.data.retvalue === 1) {
          if (imode === 0) {
            //console.log(res.data.insertMode,res.data.insert_id);
            if (res.data.insertMode === 1) {
              setEmployInfo({ ...varEmployInfo, mie_idx: res.data.insert_id, mie_status: ichecked });
            } else {
              setEmployInfo({ ...varEmployInfo, mie_status: ichecked });
            }
          } else {
            const finsIndex = varSimilarEmployList.findIndex(element => element.em_idx === em_idx);
            let copyData = [...varSimilarEmployList];
            if (res.data.insertMode === 1) {
              copyData[finsIndex] = { ...copyData[finsIndex], mie_idx: res.data.insert_id, mie_status: ichecked };
            } else {
              copyData[finsIndex] = { ...copyData[finsIndex], mie_status: ichecked };
            }
            setSimilarEmployList(copyData);
          }
        }
      });
    } else {
      alert("로그인 후 이용가능합니다.");
    }
  };

  useEffect(() => {
    const bodyItem = document.querySelector("body");
    const mainBlock = document.querySelector(".myrecruit");
    //const headhunterItem = document.getElementById('headhunter_item');
    if (varHeadHunter === 0) {
      if (bodyItem.classList.contains("headhunter_view"))
        bodyItem.classList.remove("headhunter_view");
      if (mainBlock.classList.contains("myheadhunter"))
        mainBlock.classList.remove("myheadhunter");
      //if(headhunterItem.classList.contains('active')) headhunterItem.classList.remove('active');
    } else {
      if (!bodyItem.classList.contains("headhunter_view"))
        bodyItem.classList.add("headhunter_view");
      if (!mainBlock.classList.contains("myheadhunter"))
        mainBlock.classList.add("myheadhunter");
      //if(!headhunterItem.classList.contains('active')) headhunterItem.classList.add('active');
    }
    return () => {
      //페이지 종료시 메뉴부분  재설정
      if (bodyItem.classList.contains("headhunter_view"))
        bodyItem.classList.remove("headhunter_view");
    };
  }, [varHeadHunter]);

  // 헤드헌터 전용관은 상단 헤드헌터 전용관 헤더 보이게.
  useEffect(() => {
    if (!_.isEmpty(varEmployInfo) && varEmployInfo.em_headhunter && varEmployInfo.em_headhunter === 1) {
      const bodyItem = document.querySelector("body");
      const mainBlock = document.querySelector(".myrecruit");
      if (!bodyItem.classList.contains("headhunter_view")) {
        bodyItem.classList.add("headhunter_view");
      }
      if (!mainBlock.classList.contains("myheadhunter")) {
        mainBlock.classList.add("myheadhunter");
      }
    }
  }, [varEmployInfo]);

  return varEmployInfo === undefined || varEmployInfo === null || varEmployInfo.mcm_idx === null ? null : (
    <main className="center_wrap myrecruit">
      <div className="page_myrecruit_view">
        <section className="myrecruit_view_top">
          {" "}
          {/* 채용공고 정보 */}
          <RecruitViewTit varEmployInfo={varEmployInfo} />
          {/* 관심기업 스크랩 / 최근 본 채용공고 */}
          <RecruitViewInfo HedingPage={HedingPage} varEmployInfo={varEmployInfo} />
        </section>
        <section className="myrecruit_view_left">
          <RecruitViewPage setEmIdx={setEmIdx} varEmployInfo={varEmployInfo} varSimilarEmployList={varSimilarEmployList} />
          <h2 className="sub_content_title">관련직군 채용정보</h2>
          <section className="another">
            {varSimilarEmployList.map((employs, index) => (
              <div className="another_box" key={"employs_" + index}>
                <i className={employs.mie_status === 1 ? "btn_bookmark m_resize active" : "btn_bookmark m_resize"}
                  onClick={(e) => onSelectEmployInterestHandler(e, 1, employs.em_idx)}
                >{}</i>
                <div className="left_wrap">{employs.em_type === 2 ? em_titleLength(employs.mcm_name, 16, '...') : em_titleLength(employs.com_name, 16, '...')}</div>
                <div className="right_wrap">
                  <h2 className="title_field">
                    <Link className="link" to={`${PageFolder}MainView/${employs.em_idx}/${employs.em_headhunter}`}>
                      {em_titleLength(employs.em_title, 34, '...')}
                    </Link>
                    <span className="table_notice_badge">
                      {employs.em_type === 1
                        ? "공개채용"
                        : employs.em_type === 2
                          ? "비공개채용"
                          : employs.em_type === 3
                            ? "실시간 채용관"
                            : "헤드헌터 전용관"
                      }
                    </span>
                  </h2>
                  <div className="info_field">
                    {employs.vr_name}
                    <hr />{" "}
                    {employs.em_careers === 0
                      ? "경력무관"
                      : employs.em_careers === 1
                        ? "신입"
                        : `경력 ${employs.em_career_year}년`}
                  </div>
                  <ul className="bottom_wrap">
                    <li className="compensation">
                      <div className="total">
                        <img className="view_pc" src={WonLogoIcon_pc} alt="" />
                        <img className="view_m" src={WonLogoIcon_m} alt="" />총
                        보상금
                      </div>
                      <div className="amount">
                        {employs.em_headhunter === 1 ? ChangeMoneyUnit(employs.emh_reward_recommend / 10000) : ChangeMoneyUnit(employs.em_reward_recommend / 10000)}
                      </div>
                    </li>
                    <li className="date">
                      {ChangeDateString(employs.em_sdate)}&nbsp;~&nbsp;
                      {employs.em_edate === null ? "채용시 마감" : ChangeDateString(employs.em_edate)}
                    </li>
                  </ul>
                </div>
                <div className="info01"></div>
              </div>
            ))}
          </section>
        </section>
        <RecruitViewCompany
          varEmIdx={varEmIdx}
          varEmployInfo={varEmployInfo}
          varHeadHunter={varHeadHunter}
          onSelectEmployInterestHandler={onSelectEmployInterestHandler}
          ResumePage={ResumePage}
        />
      </div>
    </main>
  );
};

export default MainView;
