/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Routes, Route } from 'react-router-dom';
import DashList from "./DashBoardList";
import MemberStatisticsList from "./MemberStatisticsList";
import JobPostingStatisticsList from "./JobPostingList";

function index() {
  return (
    <>
      <Routes>
        <Route path="/" element={<DashList />} />
        <Route path="/member_statistics" element={<MemberStatisticsList />} />
        <Route path="/job_posting_statistics" element={<JobPostingStatisticsList />} />
      </Routes>
    </>
    // <React.Fragment>
    //   <DashList />
    // </React.Fragment>
  )

}

export default index;