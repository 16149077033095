import React from 'react'
import { Link } from "react-router-dom";

/*메인>컨텐츠 타이틀*/
function MainContentTitle(props) {
  return (
    <>
      {/* <p>{props.MainTitle}<span className="PointText">{props.MainTitle02}</span>{props.MainTitle03}</p> */}
      <h2 className="title_area ">{props.MainTitle}{props.MainTitle02+" "}{props.MainTitle03}</h2>
      <Link to={props.MainTitleLink} state={{[props.MainStatusKey]:props.MainStatusValue}}></Link>
    </>
  );
}

export default MainContentTitle;
