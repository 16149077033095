/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Paging from '../../../components/Layout/Paging'

function Popup({setModalOpen, getHedingMoneyList}){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [varSearchInput, setSearchInput] = useState("");

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(6);
    const [varPageNum,setPageNum] = useState(1);

    const [startPageNum,setStartPageNum] = useState(1);
    const [endPageNum,setEndPageNum] = useState(10);

    const [varPagelist, setPagelist] = useState([]);
    const [selectChange,setSelectChange] = useState(false);
    const [varReset, setReset] = useState(false);
    const [info, setInfo] = useState(0);
    const [reserve, setReserves] = useState(3);
    const [money, setMoney] = useState(0);
    const [transformation, setTransformation] = useState(0);
    const [mhpaid, setMhpaid] = useState(0);

    const [varUserData, setUserData] = useState({
      mem_id: "",
      mem_pwd: "",
      mem_name: "",
      mem_gender: 0,
      mem_nickname: "",
      mem_mobile: "",
      mem_email: "",
      mem_type: 0,
      mem_mode: 0,
      mem_ldate: "",
      mem_rdate: "",
      mem_ufile: "",
      mem_expiration: 0,
      change_mem_pwd: "",
    });
    const [varUserAccount, setUserAccount] = useState([]);
  
    const [prevData, setPrevData] = useState({
      mem_nickname: "",
      mem_email: "",
      heding_money: "",
    });
  
    const [varUserHmoney, setUserHmoney] = useState([]);
    const [hedingMoney, setHedingMoney] = useState("");
    const [currentHedingMoney, setCurrentHedingMoney] = useState(0);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
      getUserList();
    },[]);

    useEffect(() => {
      getUserList();
    }, [varPageSize]);

    const getUserList = async() =>{
      const sendData = {
        'varSearchInput':varSearchInput,
        'pageSize':varPageSize ,
        'startPageNum':startPageNum === 1 ? 0 : startPageNum,
        'endPageNum':endPageNum
      }
      await axios.post(`${CONFIG.SERVER_HOST}/allUserData`, sendData, CONFIG.header).then((res) => {
        console.log(res);
        setTotalCount(res.data.totalcount);
        const datas = res.data.result.map((data,i)=>(
            {  
                ROWNUM:data.ROWNUM,
                mem_idx:data.mem_idx,
                mem_id:data.mem_id,
                mem_name:data.mem_name,
                mem_mode:data.mem_mode,
                mh_paid: data.mh_paid
            }
        ))
        setPagelist(datas);
        setReset(false);
        console.log(selectChange === true);
        if(selectChange === true){
          setSelectChange(false);
        }
    });
    }

  
    const handleInput = (e) =>{
        setSearchInput(e.currentTarget.value);
    };

  

    useEffect(()=>{
      if(varReset){
        getUserList();
      }
    }, [varReset]);

    //검색
    const handleSearchExecute = () => {
      
      setSelectChange(true);
      
    }

    useEffect(() => {
      getUserList();
    }, []);

    useEffect(() => {
      if(selectChange){
        getUserList();
        setPageNum(1);
      }
    }, [selectChange]);

    useEffect(() => {
      getUserList();
    }, [startPageNum]);

    useEffect(() => {
      console.log(selectChange);
      if(selectChange === false){
        setStartPageNum((varPageNum-1)*varPageSize === 1 ? 10 : varPageSize* (varPageNum-1));
        setEndPageNum((varPageNum-1)*varPageSize === 1 ? varPageSize : varPageSize*varPageNum);
      }
    },[varPageNum]);

    const userMode = (type) => {
      switch (type) {
        case 0:
          return `일반회원`;
        case 1:
          return `기업회원`;
        case 2:
          return `헤드헌터 회원`;
        default:
          alert("오류");
      }
    };

    const handleReservee = (e) => {

      setReserves(Number(e.target.value));
    };

    const handleMoney = (e) => {
      const {value} = e.target;
      setMoney(value);
    }

    const handleUser = async(mem_idx, mh_paid) =>{

      if(info === mem_idx){
        setInfo(0);
        setMhpaid(0);
        return false;
      }
      setMhpaid(mh_paid);
      setInfo(mem_idx);
      const params = new URLSearchParams();
      params.append("mem_idx", mem_idx);
      await axios
        .post(`${CONFIG.SERVER_HOST}/getUserDetail`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            if (res.data.members.length > 0) {
              const datam = res.data.members[0];
              setPrevData({
                mem_nickname: datam.mem_nickname,
                mem_email: datam.mem_email,
              })
              //회원정보
              // setUserData({
              //   mem_id: datam.mem_id,
              //   mem_pwd: datam.mem_pwd,
              //   mem_name: datam.mem_name,
              //   mem_gender: datam.mem_gender,
              //   mem_approval: datam.mem_approval,
              //   mem_nickname: datam.mem_nickname,
              //   mem_mobile: changeMobileFormat(datam.mem_mobile),
              //   mem_email: datam.mem_email,
              //   mem_type: datam.mem_type,
              //   mem_mode: datam.mem_mode,
              //   mem_ldate: datam.mem_edate,
              //   mem_rdate: datam.mem_rdate,
              //   mem_ufile: datam.mem_ufile,
              //   mem_expiration: datam.mem_expiration,
              //   change_mem_pwd: "",
              // });
            }
            //회원계좌정보
            if (res.data.accounts.length > 0) {
              const dataa = res.data.accounts[0];
              setUserAccount([{
                mc_idx: dataa.mc_idx,
                bl_idx: dataa.bl_idx,
                bl_name: dataa.bl_name,
                mc_account: dataa.mc_account,
                mc_owner: dataa.mc_owner,
                mc_balance: dataa.mc_balance,
                mc_rdate: dataa.mc_rdate,
                mc_status: dataa.mc_status,
              }]);
            }
            //회원 헤딩머니
            if (res.data.hedingmoneys.length > 0) {
              setUserHmoney(res.data.hedingmoneys);
            }
          }
        });


    }

    //mh_reason : reserve
    //mh_type: reserve === 0 ? 3 :
    const accumulate = async()=>{
     // setTransformation(reserve === 0 ? 0 :4);
     if(info === 0){
      alert('회원을 선택해주세요.');
      return false;
     }
     if (!money) {
      alert('금액을 입력해주세요.');
      return false;
    }

    if(Number(reserve) === 0){
      if (Number(currentHedingMoney) < Number(hedingMoney)) {
        alert('보유중인 헤딩머니보다 큰 금액입니다');
        return false;
      }
    }

    const sendData = {
      "mem_idx": info,
      "mh_reason": reserve,
      "mh_paid": money,
      "mh_balance": currentHedingMoney === undefined ||  currentHedingMoney === 0 ? money : (Number(reserve) === 0 ? Number(currentHedingMoney)-Number(money) : Number(currentHedingMoney)+Number(money) ),
      "bl_idx": varUserAccount[0]?.bl_idx === undefined ? "" : varUserAccount[0].bl_idx,
      "mh_account": varUserAccount[0]?.mc_account === undefined ? "" : varUserAccount[0]?.mc_account,
      "mh_owner": varUserAccount[0]?.mc_owner === undefined ? "" : varUserAccount[0]?.mc_owner
    }

    await axios
      .post(`${CONFIG.SERVER_HOST}/insertHedingMoney`, sendData, CONFIG.header)
      .then((res) => {
        if(res.data.retvalue === 1){
          alert(res.data.message);
          //setUserHmoney(res.data.results)
          getUserList();
        }else{
          alert(res.data.message);
        }
      });
      setMoney("");
      setReserves(3);
    }

    useEffect(()=>{
      console.log(transformation);

    }, [transformation]);


    const closePopup = () => {
      setModalOpen(false);
      getHedingMoneyList();
    }
    return(
        <React.Fragment>
          <div className="Popup pop_admin_money">
            <div className="Wrap">
              <div className="Box">
                <div className="Top">
                  <h3 className="Title">헤딩머니 적립하기</h3>
                  <button onClick={()=> closePopup()} className="btn_close"></button>
                </div>
                <div className="PopContents">
                  <div className="list_top">
                    <div className="search-box">
                      <div className="wd100">
                        <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e)}}/>
                        <button onClick={handleSearchExecute} className="btn">검색</button>       
                      </div>
                    </div>
                  </div>
                  <div className="main-box ">
                    <table className="list">
                      <colgroup>
                        <col style={{width:"8%"}} />
                        <col />
                        <col style={{width:"10%"}} />
                        <col style={{width:"20%"}} />
                        <col style={{width:"20%"}} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>선택</th>
                          <th>아이디</th>
                          <th>이름</th>
                          <th>회원유형</th>
                          <th>헤딩머니</th>
                        </tr>
                      </thead>
                      <tbody>
                      {varPagelist.map((user,i) => (
                        <tr key={i} onClick={() => handleUser(user.mem_idx, user.mh_paid)}>
                          <td><input type="checkbox" className="admin_chk" value={0} checked={info === user.mem_idx ? true : false} /></td>
                          <td>{user.mem_id}</td>
                          <td className="ellipsis">{user.mem_name}</td>
                          <td>{userMode(user.mem_mode)}</td>
                          <td>{user.mh_paid}원</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                  <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                  <div className="main-box write">
                    <h4 className="sec-tit">적립 구분</h4>
                    <div className="write-radio"> 
                      <p className="radio_wrap">
                        <input type="radio" name="type1" id="HR" value={3} checked={reserve === 3 ? true : false} onChange={handleReservee} className="admin_chk" /> <label htmlFor="HR">HR담당자 소개 보상금</label>
                      </p>
                      <p className="radio_wrap">
                       <input type="radio" name="type1" id="reserves" value={4} checked={reserve === 4 ? true : false} onChange={handleReservee} className="admin_chk" /> <label htmlFor="reserves">기타 적립금</label>
                      </p>
                      <p className="radio_wrap">
                        <input type="radio" name="type1" id="exchange" value={0} checked={reserve === 0 ? true : false} onChange={handleReservee} className="admin_chk" /> <label htmlFor="exchange">환전</label>
                      </p>
                    </div>
                    <h4 className="sec-tit">적립 금액</h4>
                    <div className="search-box">
                      <div className="wd100">
                        <input type="number" value={money} onChange={handleMoney}/>
                        <button className="btn" onClick={()=> accumulate()}>지급하기</button> 
                        {/* <button className="btn" onClick={()=> collect()}>회수하기</button>        */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </React.Fragment>

    )

}

export default Popup;