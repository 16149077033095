import React from "react";
import { Link } from "react-router-dom";

//image
import searchImage from "../../img/sub/search.png";

function TalkSearch({
  totalCount,
  varSearchOption,
  varSearchString,
  onSearchStringHandler,
  onSelectSearchOptionHandler,
  onFilteringHandler,
}) {
  return (
    <section className="list_top">
      <p className="total_count">
        총&nbsp;<span>{totalCount}</span>&nbsp;건
      </p>
      <form className="search_wrap">
        <fieldset className="list_search_area">
          <select
            className="style_select"
            name="searchoption"
            value={varSearchOption}
            onChange={(e) => onSelectSearchOptionHandler(e)}
          >
            <option value="all">전체</option>
            <option value="mem_id">작성자</option>
            <option value="mwt_title">제목</option>
          </select>
          <div className="search">
            <input
              type="text"
              placeholder="검색어를 입력하세요."
              value={varSearchString}
              onChange={(e) => onSearchStringHandler(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFilteringHandler(e)
                }
              }}
            />
            <a
              href={() => false}
              className="btn_search"
              onClick={(e) => onFilteringHandler(e)}
            >
              <img src={searchImage} alt="" />
            </a>
          </div>
          <Link to={`/MyTalk/TalkWrite`} state={{ mwt_idx: 0 }} className="btn">
            등록하기
          </Link>
        </fieldset>
      </form>
    </section>
  );
}

export default TalkSearch;
