/*회원탈퇴*/
function LeaveInput({ varInputs,onChangeHandler, showPasswordFlag }) {
  return (
    <div>
        <ul className="input_form">
            <li>
                <p className="title_field">탈퇴사유</p>
                <p>
                    {/* <select className="style_select">
                        <option>회원탈퇴 사유를 선택하세요</option>
                    </select> */}
                    <textarea rows="8" placeholder="회원탈퇴 사유를 입력해주세요. (500자 이내)"
                    name="reason" value={varInputs.reason} onChange={(e)=>onChangeHandler(e)} className="textarea_h200"></textarea>
                </p>
            </li>
            {showPasswordFlag &&
                    <li>
                        <p className="title_field">비밀번호</p>
                        <p><input type="password" name="password" value={varInputs.password} onChange={(e)=>onChangeHandler(e)}/></p>
                    </li>
            }
        </ul>
    </div>
  );
}

export default LeaveInput;
