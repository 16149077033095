import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
//css

//components
import HeaderCompany from '../../components/Layout/HeaderCompany';
/* import SubVisual from '../../components/Layout/SubVisual'; */
import Footer from '../../components/Layout/Footer';

import ResumeList from '../Resume/ResumeList';
import ResumeView from '../Resume/ResumeView';
import ResumeDetail from '../Resume/ResumeDetail';
import BannerBottom from "components/Banner/BannerBottom";

//이미지

/*개인회원>이직Talk*/
function ComResume() {
    const PageFolder = '/Company/ComResume/';
    const [varSaveMode,setSaveMode] = useState(0); //0:원본, 1:임시저장
    const [varShowResume,setShowResume] = useState(false);  //대표이력서 검색 가능유무

  return (
    <div className="p_resume">
        <HeaderCompany />
        {/* <SubVisual PageTitle="HEDING 이력서" SubVisual_className="type2"/> */}
        <div id="SubWrap">
          <Routes>
            <Route path="/" element={<ResumeList varShowResume={varShowResume} setShowResume={setShowResume} PageFolder={PageFolder} />} /> 
            <Route path="/ResumeList" element={<ResumeList varShowResume={varShowResume} setShowResume={setShowResume} PageFolder={PageFolder} />} /> 
            <Route path="/ResumeView" element={<ResumeView varShowResume={varShowResume} varSaveMode={varSaveMode} setSaveMode={setSaveMode} PageFolder={PageFolder}/>} /> 
            <Route path="/ResumeDetail/:re_idx/:type" element={<ResumeDetail/>} PageFolder={PageFolder} /> 
          </Routes>
        </div>
        <BannerBottom />
        <Footer/>
    </div>
  );
}

export default ComResume;
