/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState, useCallback } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios'
import CONFIG from 'configs/configs'

import stopImg from 'img/main/btn_stop.png'
import btn_down from 'img/main/btn_down.png'
import btn_up from 'img/main/btn_up.png'
import btn_arrow_off from 'img/main/btn_arrow_off.png';

function MainNotice(props) {
  const [Loding, setLoading] = useState(false);
  const [varBoardMode,setBoardMode] = useState(0);
  const [varBoardLists,setBoardLists] = useState([]);
  const [varBoardIndex,setBoardIndex] = useState(-1);
  const [varBoardCount,setBoardCount] = useState(0);
  const [varBoardInx,setBoardInx] = useState(0);
  const [varBoardTitle,setBoardTitle] = useState('');
  const [varBoardDate,setBoardDate] = useState('');
  const [varLinkString,setLinkString] = useState('');
  const [autoStart, setAutoStart] = useState(true);

  //최초 게시판 정보 가져오기
  const getBoardLists = async (boardType) => {
    try {
      setLoading(true);
      await axios.post(`${CONFIG.SERVER_HOST}/${boardType}`).then((res) => {
          if(res.data.retvalue === 1) {
            const datas = res.data.results;
            console.log(datas);
            setBoardLists(datas);
            setBoardCount(datas.length);
            setBoardIndex(0);
          } else {
            setBoardLists([]);
            setBoardCount(0);
          }
          setLoading(false);
      });
    } catch (error) {
        console.log(error);
        setLoading(false);
    }
  }
  useEffect(() => {

    if(Loding === false){
      var boardType = '_get_notice_lists';  //공지
      if(props.Title === "FAQ") {
        setLinkString('/Faq');
        boardType = '_get_faq_lists';
        setBoardMode(1);
      } else if(props.Title === "공지") {
        setLinkString('/Notice');
        setBoardMode(0);
      }
      getBoardLists(boardType);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(varBoardLists.length === 0) return;
    //console.log(varBoardIndex);
    const board = varBoardLists[varBoardIndex];
    //console.log(board);
    setBoardInx(board.board_idx);
    if(varBoardMode === 0) {
      setBoardTitle(board.board_title);
    } else {
      setBoardTitle(board.board_question);
    }
    setBoardDate(board.board_rdate);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[varBoardIndex]);

  const onNextHandler = useCallback((e) => {
    if(varBoardIndex === varBoardCount-1) {
      setBoardIndex(0);
    } else {
      setBoardIndex(varBoardIndex+1);
    }
  }, [varBoardCount, varBoardIndex]);

  const onPrevHandler = (e) => {
    if(varBoardIndex === 0) {
      setBoardIndex(varBoardCount-1);
    } else {
      setBoardIndex(varBoardIndex-1);
    }
  }

  useEffect(() => {
      let timer = setInterval(() => {
        if(autoStart) {
          onNextHandler();
        }
      }, 3000);

    return () => clearInterval(timer)
  }, [autoStart, onNextHandler]);

  return (
    <>
        <article className="wrap">
            <div className="title_area" style={{marginBottom: "0px"}}>{props.Title}</div>
            <div className="list_area">
                <Link to={varLinkString} state={{board_idx:varBoardInx}}>
                  {/* {console.log("varLinkString ",varLinkString, "varBoardInx ",varBoardInx)} */}
                    <span className="field_title">{varBoardTitle}</span>
                    <span className="field_date">{varBoardDate.substring(0,10)}</span>
                </Link>
            </div>
            <div className="btn_area" style={{marginTop: "0px"}}>
              {autoStart ?
                <a><img src={stopImg} alt="stopImg" onClick={() => {
                  setAutoStart(false);
                }}/></a>
                :
                <img src={btn_arrow_off} onClick={()=> setAutoStart(true)} alt="" />
              }
                <button className="btn_down" onClick={(e)=> {
                  setAutoStart(false);
                  onPrevHandler(e);
                }}><img src={btn_down} alt="btn_down"/></button>
                <button className="btn_up" onClick={(e) => {
                  setAutoStart(false);
                  onNextHandler(e)
                }}><img src={btn_up} alt="btn_up"/></button>
            </div>
        </article>
    </>
  );
}

export default MainNotice;
