/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs";
import {addThousandPoint,changeMobileNumber,bizNoFormat} from "../../../components/Common/Common";
import "admin/css/board.css";

function Corporate_Member_Dtail({ setCategory }) {
  const { mcm_idx, mem_idx } = useParams();
  const navigate = useNavigate();

  const [varUserData, setUserData] = useState({
    mem_idx: 0,
    mem_id: "",
    mem_pwd: "",
    mem_name: "",
    mem_nickname: "",
    mem_mobile: "",
    mem_expiration: 0,
    mem_mode: 1,
    mem_hunter_type: 0,
    mem_approval: 0,
    mcm_approval: 0,
    mem_gender: 0,
    mem_email: "",
    mem_ldate: "",
    mem_rdate: "",
    mcm_tax_id: "",
    mcm_name: "",
    mcm_ceo: "",
    mcm_location: "",
    mcm_tax_ufile: "",
    mcm_bank_idx: "",
    change_mem_pwd: "",
  });

  const [hedingMoney, setHedingMoney] = useState("");
  const [currentHedingMoney, setCurrentHedingMoney] = useState(0);
  const [hedingMoneyStatus, setHedingMoneyStatus] = useState(1);
  const [bankData, setBankData] = useState([]);
  const [prevData, setPrevData] = useState({mem_email: ""});
  const [varUserHmoney, setUserHmoney] = useState([]);
  const [subHedingmoney, setSubHedingMoney] = useState([]);
  const [image, setImage] = useState({ preview: '', data: '' });
  const [MemNicknameChk , setMemNicknameChk] = useState(false);//eslint-disable-line no-unused-vars
  const [MemEmailChk , setMemEmailChk] = useState(false);
  const prevNickNameRef = useRef();
  const prevMemEmailRef = useRef();


  //const prevNickName = prevNickNameRef.current;
  //const prevMemEmail = prevMemEmailRef.current;

  useEffect(async () => {
    setCategory(1);
    if (mcm_idx > 0) {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);

      await axios
        .post(`${CONFIG.SERVER_HOST}/getCorporate_Member`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            if (res.data.members.length > 0) {
              const datam = res.data.members[0];
              const compnayInfo = res.data.compnayInfo[0];
              setPrevData({
                mem_email: datam.mem_email,
              })
              setUserData({
                mem_idx: datam.mem_idx,
                mem_id: datam.mem_id,
                mem_pwd: datam.mem_pwd,
                mem_name: datam.mem_name,
                mem_nickname: datam.mem_nickname,
                mem_mobile: datam.mem_mobile,
                mem_expiration: datam.mem_expiration,
                mem_mode: datam.mem_mode,

                mem_hunter_type: datam.mem_hunter_type,
                mem_email: datam.mem_email,
                mem_gender: datam.mem_gender,
                mem_approval: datam.mem_approval,
                mcm_approval: datam.mem_approval,
                mem_ldate: datam.mem_ldate,
                mem_rdate: datam.mem_rdate,
                mcm_tax_id: datam.mcm_tax_id,
                mcm_name: datam.mcm_name,
                mcm_ceo: datam.mcm_ceo,
                mcm_location: datam.mcm_location,
                mcm_tax_ufile: datam.mcm_tax_ufile,
                mcm_bank_idx: datam.mcm_bank_idx,
                change_mem_pwd: "",
                cmm_department: compnayInfo.cmm_department,
                cmm_position: compnayInfo.cmm_position,
              });
            }
            if(res.data.bankInfo.length > 0){
              const bankInfo = res.data.bankInfo[0];
              setBankData([{
                bl_idx: bankInfo.bl_idx,
                bl_name: bankInfo.bl_name,
                mc_account: bankInfo.mc_account,
                mc_owner: bankInfo.mc_owner,
              }])
            }
            //회원 헤딩머니
            if (res.data.hedingmoneys.length > 0) {
              setUserHmoney(res.data.hedingmoneys);
            }
            //서브계정 헤딩머니
            if (res.data.subHedingMoneys.length > 0) {
              setSubHedingMoney(res.data.subHedingMoneys);
            }
          }
        });
    }
  }, []);

  useEffect(()=>{
    prevNickNameRef.current = varUserData.mem_nickname;
    prevMemEmailRef.current = varUserData.mem_email;
  })

  const handleSubmit = async () => {

    if(prevData.mem_email !== varUserData.mem_email && MemEmailChk === false){
      alert('이메일 중복체크를 진행해주세요');
      return false;
    }

    const params = new URLSearchParams();
    params.append("mem_idx", varUserData.mem_idx);
    params.append('file', image.data);
    params.append("mem_pwd", varUserData.mem_pwd);
    params.append("mem_gender", varUserData.mem_gender);
    params.append("mem_nickname", varUserData.mem_nickname);
    params.append("mem_mobile", changeMobileNumber(varUserData.mem_mobile));
    params.append("mem_approval", varUserData.mem_approval);
    params.append("mcm_approval", varUserData.mem_approval);
    params.append("mem_hunter_type", varUserData.mem_hunter_type);
    params.append("mem_email", varUserData.mem_email === "undefined" || varUserData.mem_email === "" ? "" : varUserData.mem_email);
    params.append("mem_ufile", varUserData.mem_ufile === "undefined" || varUserData.mem_ufile === "" ? "" : varUserData.mem_ufile);
    params.append("change_mem_pwd", varUserData.change_mem_pwd);

    await axios.post(`${CONFIG.SERVER_HOST}/company_user_update`, params).then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        console.log(res.data);
        alert(res.data.message);
        navigate("/admin/corporate_member_list");
      }
      setMemEmailChk(false);
    });
  };

  //파일 선택시 정보저장
  const handleFileChange = (e) => {
    /*       const img = {
          preview: URL.createObjectURL(e.currentTarget.files[0]),
          data: e.currentTarget.files[0],
      }
      setImage(img); */

      const file = e.currentTarget.files[0];

      const formData = new FormData();
      formData.append("user_photo", file);
      axios.post(`${CONFIG.SERVER_HOST}/user_photo`,formData).then((res) => {
        const newFile = res.data.fileName;
        //console.log(newFile);
        if(newFile !== null){
          //console.log(newFile);
          const img = {
            preview: URL.createObjectURL(file),
            data: newFile,
          };
          //console.log(img);
          setImage(img);
        }
      });
  };
  //회원정보 객체에 저장
  const handleChangeInfos = (e) => {
    const { name, value } = e.currentTarget;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };
  //닉네임 중복체크 함수
  const handleCheckDuplication = async (e) => {
    const params = new URLSearchParams();
    params.append("mem_idx", varUserData.mem_idx);
    if (e.currentTarget.id === "mem_nickname") {
      params.append("mem_nickname", varUserData.mem_nickname);
    } else {
      params.append("mem_email", varUserData.mem_email);
    }
    await axios
      .post(`${CONFIG.SERVER_HOST}/${e.currentTarget.id}`, params)
      .then((res) => {

        if(res.data.retvalue === 1){
          if (e.target.id === "mem_nickname") {
            setMemNicknameChk(true);
          } else {
            setMemEmailChk(true);
          }
          alert(res.data.message);
        }else if(res.data.retvalue === 3){
          if (e.target.id === "mem_nickname") {
            setMemNicknameChk(false);
          } else {
            setMemEmailChk(false);
          }
          alert(res.data.message);
        }
      });
  };

  const [varExpireReason, setExpireReason] = useState("");
  const [varPopupExpireReason, setPopupExpireReason] = useState(false);
  //회원탈퇴
  const popupExpiredMember = (bstate) => {
    setPopupExpireReason(bstate);
  };
  const onChangeExpireReason = (e) => {
    setExpireReason(e.currentTarget.value);
  };
  const handleExpiredMember = async (e) => {
    if (varExpireReason === "") {
      alert("먼저 탈퇴 사유를 입력하세요.");
      return false;
    }
    if (window.confirm("현재 회원을 탈퇴시키겠습니까?")) {
      const params = new URLSearchParams();
      params.append("mode", 1);
      params.append("mem_idx", varUserData.mem_idx);
      params.append("reason", varExpireReason);
      await axios
        .post(`${CONFIG.SERVER_HOST}/expiredMember`, params)
        .then((res) => {
          alert(res.data.message);
          if (res.data.retvalue === 1) {
            window.location.href = "/admin/corporate_member_list";
          }
        });
    }
  };

  const memApprovalChange = (e) => {
    const { name, value } = e.target;
    
    //회원가입 후 승인받기 전, 회원등급 프리미엄으로 변경 방지
    if(name === "mem_hunter_type"){ 
      if(Number(value) === 1){
        if(varUserData.mem_approval === 0){
          alert("승인상태 먼저 변경해주세요")
          return false;
        }
      }
    }

    setUserData({
      ...varUserData,
      [name]: Number(value),
    });
  };

  useEffect(()=> {
    if(varUserData.mem_approval === 0) {
      setUserData({...varUserData, mem_hunter_type: 0})
    }else {
      setUserData({...varUserData, mem_hunter_type:varUserData.mem_hunter_type})
    }
  },[varUserData.mem_approval])

  //헤딩머니 상태 변경
  const handleMhReason = (e) => {
    const { value } = e.target;
    setHedingMoneyStatus(value);
  };

  //헤딩머니 핸들러
  const handleHedingMoneyInfos = (e) => {
    const { value } = e.target;
    setHedingMoney(value);
  };


  const haneldMoneyChange = async() => {
    if (hedingMoney === "") {
      alert('금액을 입력해주세요');
      return false;
    }

    if(Number(hedingMoneyStatus) === 0){
      if (Number(currentHedingMoney) < Number(hedingMoney)) {
        alert('보유중인 헤딩머니보다 큰 금액입니다');
        return false;
      }
    }

    // if(bankData.length === 0){
    //   alert('등록된 계좌정보가 없습니다.');
    //   return false;
    // }

    const sendData = {
      "mem_idx": mem_idx,
      "mh_reason": hedingMoneyStatus,
      "mh_paid": hedingMoney,
      "mh_balance": varUserHmoney[0]?.mh_balance === undefined ? hedingMoney : (Number(hedingMoneyStatus) === 0 ? Number(currentHedingMoney)-Number(hedingMoney) : Number(currentHedingMoney)+Number(hedingMoney) ),
      "bl_idx": bankData[0]?.bl_idx === undefined ? "" : bankData[0]?.bl_idx,
      "mh_account": bankData[0]?.mc_account === undefined ? "" : bankData[0]?.mc_account,
      "mh_owner": bankData[0]?.mc_owner === undefined ? "" : bankData[0]?.mc_owner
    }

    await axios
      .post(`${CONFIG.SERVER_HOST}/insertHedingMoney`, sendData, CONFIG.header)
      .then((res) => {
        if(res.data.retvalue === 1){
          alert(res.data.message);
          setUserHmoney(res.data.results)
        }else{
          alert(res.data.message);
        }
      });
      setHedingMoney("");
      setHedingMoneyStatus(1);
  }
  
  //보유중인 헤딩머니 가져오기
  useEffect(async() => {
    const params = new URLSearchParams();
    params.append("mem_idx", mem_idx);

    await axios
      .post(`${CONFIG.SERVER_HOST}/_get_heding_money`, params)
      .then((res) => {
        if(res.data.retvalue === 1){
          setCurrentHedingMoney(res.data.results[0]?.mh_paid)
        }
      });
  },[varUserHmoney])
  
  return (
    <React.Fragment>
      <p className="table_title" style={{ marginTop: "20px" }}>- 담당자정보</p>
      <div className="main-box" style={{marginBottom:"5%"}}>
        <table className="write">
          <thead></thead>
          <tbody>
            <tr>
              <th>아이디</th>
              <td style={{ textAlign: "left", width: "300px" }}>
                {varUserData.mem_id}
              </td>
              <th>비밀번호</th>
              <td>
                <input
                  type="password"
                  name="change_mem_pwd"
                  onChange={handleChangeInfos}
                  value={varUserData.change_mem_pwd || ""}
                  className="wd200"
                />
              </td>
            </tr>
            <tr>
              <th>담당자명</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mem_name}
              </td>
            </tr>
            <tr>
              <th>프로필 이미지</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData?.mem_ufile ? <a>{varUserData.mem_ufile}</a> : ""}
                <input
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                ></input>
              </td>
            </tr>
            {/* <tr>
              <th>닉네임</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <input
                  type="text"
                  name="mem_nickname"
                  onChange={handleChangeInfos}
                  value={varUserData.mem_nickname}
                  className="wd200"
                />
                <button className="btn" id="mem_nickname" onClick={handleCheckDuplication}>
                  중복체크
                </button>
              </td>
            </tr> */}
            <tr>
              <th>휴대폰</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <input
                  type="text"
                  name="mem_mobile"
                  onChange={handleChangeInfos}
                  value={varUserData.mem_mobile}
                  className="wd200"
                ></input>
              </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <input
                  type="text"
                  name="mem_email"
                  onChange={handleChangeInfos}
                  value={varUserData.mem_email}
                  className="wd300"
                />
                {prevData.mem_email !== varUserData.mem_email && <button className="btn" id="mem_email" onClick={handleCheckDuplication}>
                  중복체크
                </button>}
              </td>
            </tr>
            <tr>
              <th>회원유형</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mem_mode === 1 ? "기업회원" : ""}
              </td>
            </tr>
            <tr>
              <th>승인상태</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <select
                  name="mem_approval"
                  value={varUserData.mem_approval}
                  onChange={memApprovalChange}
                  className="short_select"
                >
                  <option value={0}>미승인</option>
                  <option value={1}>승인</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>회원등급</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <select
                  name="mem_hunter_type"
                  value={varUserData.mem_hunter_type}
                  onChange={memApprovalChange}
                  className="short_select"
                >
                  <option value={0}>일반</option>
                  <option value={1}>프리미엄</option>
                </select>
              </td>
            </tr>
            {/* <tr>
              <th>부서</th>
              <td style={{ textAlign: "left" }}>
                {varUserData.cmm_department}
              </td>
              <th>직책</th>
              <td style={{ textAlign: "left" }}>{varUserData.cmm_position}</td>
            </tr> */}
            <tr>
              <th>개인정보 유효기간 선택</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mem_expiration === 0 ? "회원 탈퇴시" : "선택 안함"}
              </td>
            </tr>
            <tr>
              <th>최근 접속일</th>
              <td style={{ textAlign: "left" }}>
                {varUserData.mem_ldate === null ? "" : varUserData.mem_ldate}
              </td>
              <th>가입일자</th>
              <td style={{ textAlign: "left" }}>{varUserData.mem_rdate}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="table_title">- 기업정보</p>
      <div className="main-box">
        <table className="write">
          <thead></thead>
          <tbody>
            <tr>
              <th>사업자등록번호</th>
              <td style={{ textAlign: "left" }}>{bizNoFormat(varUserData.mcm_tax_id)}</td>
            </tr>
            <tr>
              <th>회사명</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mcm_name}
              </td>
            </tr>
            <tr>
              <th>대표자 명</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mcm_ceo}
              </td>
            </tr>
            <tr>
              <th>회사주소</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mcm_location}
              </td>
            </tr>
            <tr>
              <th>사업자등록증</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
              <a className="mr20" href={`${CONFIG.SERVER_HOST}/file_down3?file_name=${varUserData.mcm_tax_ufile}&file_url=tax_file`} download>{varUserData.mcm_tax_ufile}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-center" style={{marginBottom:"3%"}}>
        <Link className="btn line" to="/admin/corporate_member_list">목록</Link>
        <button className="btn" onClick={() => handleSubmit()}>수정</button>
        <button className="btn black" onClick={(e) => popupExpiredMember(true)}>회원탈퇴</button>
      </div>

      <div
        className="main-box"
        id="expireMember"
        style={
          varPopupExpireReason ? { display: "block" } : { display: "none" }
        }
      >
        <div style={{ padding: "10px 20px" }}>
          <div className="FieldTitle">탈퇴사유</div>
          <div>
            <textarea
              rows="8"
              placeholder="회원탈퇴 사유를 입력해주세요. (500자 이내)"
              name="reason"
              value={varExpireReason}
              onChange={(e) => onChangeExpireReason(e)}
            ></textarea>
          </div>
          <div className="btn-center">
            <button type="submit" onClick={handleExpiredMember}>
              회원탈퇴
            </button>
            <button onClick={(e) => popupExpiredMember(false)}>취소</button>
          </div>
        </div>
      </div>
      {bankData && bankData.map((data, i) => {
        return (
          <div key={i}>
            <p className="table_title">- 환전 계좌 정보</p>
            <div className="main-box" style={{marginBottom:"5%"}}>
              <table className="write">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>은행명</th>
                    <td style={{ textAlign: "left" }}>
                      {data.bl_name}
                    </td>
                  </tr>
                  <tr>
                    <th>계좌번호</th>
                    <td style={{ textAlign: "left" }}>
                      {data.mc_account}
                    </td>
                  </tr>
                  <tr>
                    <th>예금주</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {data.mc_owner}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )
      })}
     
      <div>
          <p className="table_title" >- 헤딩머니 정보</p>
          <div className="main-box">
              <table className="write">
                <tbody>
                  <tr>
                    <th>보유중인 헤딩머니</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {addThousandPoint(currentHedingMoney) + " 원"}
                    </td>
                  </tr>
                  <tr> 
                    <th>헤딩머니 설정</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      <select
                        value={hedingMoneyStatus}
                        onChange={handleMhReason}
                        className="short_select"
                        style={{marginRight:"2%"}}
                      >
                        <option value={"0"}>환전</option>
                        <option value={"1"}>추천인 보상금</option>
                        <option value={"2"}>합격자 보상금</option>
                        <option value={"3"}>HR 담당자 소개 보상금</option>
                        <option value={"4"}>기타 적립금</option>
                        <option value={"5"}>보너스</option>
                      </select>
                      <input type="text" name="heding_money"
                        onChange={(e)=>handleHedingMoneyInfos(e)}
                        value={hedingMoney}
                        className="wd200"
                      />
                      <button className="btn" id="heding_money" onClick={haneldMoneyChange}>
                        머니 수정
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={varUserHmoney.length === 0 ? "main-box2" : "main-box"} style={{marginBottom:"5%"}}>
            <table className="write">
            {varUserHmoney.length !== 0 &&  
              <thead>
                <tr>
                  <th>번호</th>
                  <th>변동일시</th>
                  <th>변동내용</th>
                  <th>변동머니</th>
                  <th>남은머니</th>
                </tr>
              </thead>
            }
            {varUserHmoney && varUserHmoney.map((data, i) => (
              <tbody key={"hmoney" + i}>
                <tr>
                  <td>{varUserHmoney.length - i}</td>
                  <td>{data.mh_rdate}</td>
                  <td>
                    {data.vhs_name}{" "}
                    {data.mh_reason !== 0
                      ? ""
                      : data.mh_status === -1
                        ? " (환전취소)"
                        : data.mh_status === 1
                          ? " (환전신청)"
                          : data.mh_status === 2
                            ? " (환전승인완료)"
                            : data.mh_status === 3
                              ? " (환전완료)"
                              : ""}
                  </td>
                  <td>{data.vhs_name === "환전" ? "" : "+ " }{isNaN(String(data.mh_paid).substring(0,1)) ? String(data?.mh_paid)?.replace("-","- ") + " 원" : addThousandPoint(data.mh_paid) + " 원"}</td>
                  <td>{addThousandPoint(data.mh_balance) + " 원"}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        {subHedingmoney.length !== 0 &&  
        <p className="table_title">- 서브계정 헤딩머니 정보</p> }
        <div className={subHedingmoney.length === 0 ? "main-box2" : "main-box"}>
            <table className="write">
            {subHedingmoney.length !== 0 &&  
              <thead>
                <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>이름</th>
                  <th>부서</th>
                  <th>직책</th>
                  <th>보유 헤딩머니</th>
                </tr>
              </thead>
             }
              {subHedingmoney && subHedingmoney.map((data, i) => (
                <tbody key={"hmoney" + i}>
                  <tr>
                    <td>{subHedingmoney.length - i}</td>
                    <td>{data.mem_id}</td>
                    <td>
                      {data.vhs_name}
                      {data.mem_name}
                    </td>
                    <td>{data.cmm_department === "" ? "미입력" : data.cmm_department}</td>
                    <td>{data.cmm_position === "" ? "미입력" : data.cmm_position}</td>
                    <td>{addThousandPoint(data.mh_balance) + " 원"}</td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
    </React.Fragment>
  );
}

export default Corporate_Member_Dtail;
