
/******************************************************************
 * POP - 동료평판 요청 레이어 
 *****************************************************************/
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import CONFIG from '../../configs/configs';
import { useNavigate } from 'react-router-dom';
import img_repu_main from "img/sub/repu_main.png";

const PopAcqRepuR = ({ setEnablePopRequR, buttonId, setButtonId }) => {
  const navigator = useNavigate();
  //const location = useLocation();
  const nameRef = useRef();
  const secondRef = useRef();
  const lastRef = useRef();

  const [reviewer, setReviewer] = useState({
    name: "",
    first: "010",
    second: "",
    last: "",
  });

  const [carrerReputationText, setCarrerReputationText] = useState({
    title: '동료에게 평판 요청하기',
    contentsTop: '현재 같이 일하거나 과거에 같이 일했던 동료에게 내 평판을 요청하고 싶으신가요?',
    contentsTop2: null,
    contentsBottom: '동료의 휴대번호를 입력하시면 내 평판 작성을 요청할 수 있습니다.',
    buttonText: '동료에게 평판 요청하기',
    buttonId: buttonId,
  })



  const handleNumChange = useCallback((e) => {
    const { name, value } = e.target;
    setReviewer({ ...reviewer, [name]: value });
  }, [reviewer]);

  const limitLength = (e) => {
    const { value } = e.target;
    if (value.length >= 4) {
      e.preventDefault();
    }
  };

  //BTN :: 요청 or 진행
  const onSubmit = useCallback((e) => {
    const isValidate = () => {
      if (!reviewer.name) {
        alert("동료의 이름을 입력하세요.");
        nameRef.current.focus();
        return false;
      }

      if (!reviewer.second) {
        alert("휴대전화 번호 가운데 4자리를 입력하세요.");
        secondRef.current.focus();
        return false;
      } 
      if(reviewer.second){
        if (reviewer.second.length !== 4) {
          alert("휴대전화 번호 형식에 맞지 않습니다.\n가운데 4자리를 다시 입력하세요.");
          secondRef.current.focus();
          return false;
        }
      }
      if (!reviewer.last) {
        alert("휴대전화 번호 마지막 4자리를 입력하세요.");
        lastRef.current.focus();
        return false;
      }
      if(reviewer.last){
        if (reviewer.last.length !== 4) {
          alert("휴대전화 번호 형식에 맞지 않습니다.\n마지막 4자리를 다시 입력하세요.");
          lastRef.current.focus();
          return false;
        }
      }
      return true;
    };

    const sendAlimtalk = () => {
      const params = new URLSearchParams([
        ['mem_name', localStorage.getItem("mem_name")],
        ['to_mem_name', nameRef.current.value],
        ['cellphone_number', `010${secondRef.current.value}${lastRef.current.value}`],
        ['url', `${CONFIG.URL}/MyCareer/career-reputation`],
        // ['url', `http://localhost:3000/MyCareer/career-reputation`],
        ['repu_code', localStorage.getItem('repuCode')],
      ]);

      axios.get(`${CONFIG.SERVER_HOST}/career-reputation/alimtalk`, { params })
        .then((res) => {
          if (res.data?.retvalue) {
            alert(res.data.message);
          }
        }).catch((e) => console.debug('Exception  ', e));
      // eslint-disable-next-line
    };

    if (isValidate()) {
      const id = e.target.id
      if (id === 'my') {//--- 동료에게 평판 요청하기
        sendAlimtalk();
      } else {//------------- 동료평판 진행하기
        navigator('./submit', { state: { to_mem_mobile: `${reviewer.first}-${reviewer.second}-${reviewer.last}`, to_mem_name: reviewer.name } });
      }
    }
  }, [reviewer, navigator]);//끝.BTN :: 요청 or 진행

  useEffect(() => {
    //const btnType = location.state?.buttonType;
    if (buttonId === 'your') {//동료평판 작성하기
      setCarrerReputationText((prev) => {
        return {
          ...prev,
          title: '동료 평판 작성하기',
          contentsTop: '평판을 작성하고 싶으셨던 동료가 있으신가요?',
          contentsTop2: 'HEDING 동료 평판을 통해서 작성된 내용을 동료에게 공유해 드립니다.',
          contentsBottom: '동료의 이름과 휴대폰번호를 입력 후 평판을 작성해 보세요',
          buttonText: '동료 평판 작성하기',
          buttonId: buttonId,
        }
      });
      //setButtonId(prev => prev = btnType);
    } else {//동료평판 요청
      setCarrerReputationText((prev) => {
        return {
          ...prev,
          title: '동료에게 평판 요청하기',
          contentsTop: '현재 같이 일하거나 과거에 같이 일했던 동료에게 내 평판을 요청하고 싶으신가요?',
          contentsTop2: null,
          contentsBottom: '동료의 이름과 휴대폰번호를 입력하시면 내 평판 작성을 요청할 수 있습니다.',
          buttonText: '동료에게 평판 요청하기',
          buttonId: buttonId,
        }
      });
    }
  }, [buttonId]);


  return (
    <div className={"popup active"}>
      <div className="popup_wrap popup01 pop_repu">
        <section className="com_reputation com_repu_main com_repu_gray_container p_colle_repu st_popup">
          <div className="btn-right-box">
            <button onClick={() => setEnablePopRequR(false)} className="btn-pop-close" />
          </div>

          <article className="repu_top_info_wrap">
            <img src={img_repu_main} alt="평판 이미지" />
            {/* 2023-02-17 주석 - KSR
            <div className="repu_go_btn_wrap">
              <button className="btn" id='my' onClick={onSwitchText}>내 평판</button>
              <button className="btn navy" id='your' onClick={onSwitchText}>동료 평판</button>
            </div>
            */}
            <h1 className="requtation_txt blue bold">{carrerReputationText.title}</h1>
            <p className="requtation_txt black">
              {carrerReputationText.contentsTop}<br />
              {carrerReputationText.contentsTop2 ? carrerReputationText.contentsTop2 : ""}
              {carrerReputationText.contentsTop2 ? <br /> : ""}
              <br />
              {carrerReputationText.contentsBottom}
            </p>
          </article>

          <article className="repu_request_form_wrap">
            <form>
              <div className="field">
                <input type="text" placeholder="동료의 이름을 입력해주세요." name="name" onChange={handleNumChange} ref={nameRef} />
              </div>
              <div className="field type_tel">
                <select className="" name="first" onChange={handleNumChange}>
                  <option value="010">010</option>
                </select>
                <span className="between_txt"  >-</span>
                {/* <input type="number" name="second" onChange={handleNumChange} onKeyDown={blockInvalidChar} /> */}
                <input type="number" name="second" onChange={handleNumChange} onKeyPress={limitLength} ref={secondRef} />

                <span className="between_txt">-</span>
                {/* <input type="number" name="last" onChange={handleNumChange} onKeyDown={blockInvalidChar} /> */}
                <input type="number" name="last" onChange={handleNumChange} onKeyPress={limitLength} ref={lastRef} />
              </div>
              <div className="btn_wrap">
                <button type="button" id={buttonId} className="btn" onClick={onSubmit}>{carrerReputationText?.buttonText}</button>
              </div>
            </form>
          </article>
        </section>
      </div>
    </div>
  )
}

export default PopAcqRepuR