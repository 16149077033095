/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
//css

/*프로필*/
function PopSchool({
  varSchoolIndex,
  schoolLevelList,
  varPopSchool,
  setPopSchool,
  resumeSchoolList,
  setResumeSchoolList,
}) {
  const [varSchoolName, setSchoolName] = useState("");
  const [varSchoolNameList, setSchoolNameList] = useState([]);
  const [varSearchOption, setSearchOption] = useState("0");
  const [varSearchString, setSearchString] = useState("");
  const [SchoolTypeToggle, setSchoolTypeToggle] = useState(true);


  //학교명 입력시
  const onSearchStringHandler = (vsh_name) => {
    setSchoolName(vsh_name);
    setSearchString(vsh_name);
  }
  //학교선택시
  const onSelectSchoolHandler = (e, vsh_name) => {
    e.preventDefault();
    //이전에 선택된 학교 --> 선택 취소
    let schools = document.querySelectorAll(".button");
    for (var i = 0; i < schools.length; i++) {
      schools[i].classList.remove("active");
    }
    //선택된 학교 --> 선택 표시
    e.target.className = "button active";
    setSchoolName(vsh_name);
  };

  // 국내 해외 선택
  const onSearchingRadioHandler = (e) => {
    if(e === "out"){
      setSchoolTypeToggle(false)
    }else{
      setSchoolTypeToggle(true)
    }
  }

  //팝업창 닫기
  const onClosePopSchoolHandler = (e) => {
    e.preventDefault();
    setPopSchool("popup");
  };

  //'추가'버튼 클릭 : 학교명을 배열에 저장한 후 창닫기
  const onSelectedPopSchoolHandler = (e) => {
    if (varSearchString === "") {
      window.alert("학교명을 먼저 입력하세요.");
      return;
    }
    e.preventDefault();
    console.log("done", varSchoolName);
    console.log(resumeSchoolList);
    //최상단 배열에서 현재 위치 찾기
    const finsIndex = resumeSchoolList.findIndex(
      (element) => element.idx === varSchoolIndex
    );
    console.log(varSchoolIndex, finsIndex);
    //최상단 배열 복사
    let copyData = [...resumeSchoolList];
    //현재 선택된 학교 바꾸기
    copyData[finsIndex] = {
      ...copyData[finsIndex],
      reh_level: Number(varSearchOption),
    };
    copyData[finsIndex] = { ...copyData[finsIndex], reh_name: varSchoolName };
    //최상단배열 재설정
    setResumeSchoolList(copyData);
    console.log(copyData);
    setSearchString("");  //학교 검색어  초기화
    setSchoolNameList([]);//검색결과(학교목록 초기화)
    setPopSchool("popup"); //팝업닫기
  };


  //학교명 검색
//   const onSearchingHandler2 = (e) => {
//     if (varSearchString === "") {
//       window.alert("검색어를 먼저 입력하세요.");
//       return;
//     }
//     console.log("varSearchOption")
//     console.log(varSearchOption)

//     if(varSearchOption === "1" || varSearchOption === "0" || varSearchOption === ""){
//       //고등학교 or 전체 일 경우

//       axios
//       .post(`${CONFIG.schoolApiUrl}?KEY=${CONFIG.schoolApiKey}&SCHUL_NM=${varSearchString}&pSize=100&Type=json&SCHUL_KND_SC_NM=고등학교`)
//       .then((res) => {
//         //console.log("res.schoolInfo")
//         //console.log(res.status)
//         //console.log(res.data.schoolInfo[1].row)
//         if (res.status === 200) {
//           const data = res.data.schoolInfo[1].row.map((data, i) => ({
//             vsh_idx: "1",
//             vsh_name: data.SCHUL_NM,
//           }));
//           setSchoolNameList(data);
//         } else {
//           setSchoolNameList([]);
//           //window.alert('검색된 정보가 없습니다.');
//         }
//       });

//     }else{

//       //학교명 가져오기
//       const params = new URLSearchParams();
//       params.append("vsh_idx", varSearchOption);
//       params.append("searchString", varSearchString);
//       axios
//         .post(`${CONFIG.SERVER_HOST}/_get_schools_list`, params)
//         .then((res) => {
//           if (res.data.retvalue === 1) {
//             const data = res.data.results.map((data, i) => ({
//               vsh_idx: data.vsh_idx,
//               vsh_name: data.vsh_name,
//             }));
//             console.log(data);
//             setSchoolNameList(data);
//           } else {
//             setSchoolNameList([]);
//             //window.alert('검색된 정보가 없습니다.');
//           }
//         });
//     }
//   };

    //학교명 검색
  const onSearchingHandler = async (e) => {
    if (varSearchString === "") {
        window.alert("검색어를 먼저 입력하세요.");
        return;
    }

    var gubun = "";

    if(varSearchOption === "1" || varSearchOption === "0" || varSearchOption === ""){
        //고등학교 or 전체 일 경우
        gubun = "high_list";
    }else{
        gubun = "univ_list";
    }

    await axios
    .get(`${CONFIG.schoolSearchApiURL}?apiKey=${CONFIG.schoolSearchApiKey}&svcType=api&svcCode=SCHOOL&contentType=Json&gubun=${gubun}&perPage=100&searchSchulNm=${varSearchString}`)
    .then((res) => {
      console.log( res.data);
        const { dataSearch : {content} } = res.data;
        setSchoolNameList(content.filter((e) => e.schoolName).map((e) => {
            return {
                vsh_idx: "1",
                vsh_name: `${e.schoolName}`,
                // vsh_name: `${e.schoolName} (${e.region})`,
            }
        }));
    }).catch((err) => {
        // 에러 처리.
        setSchoolNameList([]);
        console.debug(err);
    });
  }


  return (
    <section className={varPopSchool}>
      <article className="popup_wrap popup01">
        <div className="title_wrap">
          <h1>학력 선택</h1>
        </div>
        <div className="content_wrap">
          <div className="input_wrap radio-box">
            <input type="radio" name="school_type" id="in" className="chk" onClick={e=>onSearchingRadioHandler(e.target.value)} value="in" checked={SchoolTypeToggle === true}/>
            <label htmlFor="in">국내</label>
            <input type="radio" name="school_type" id="out" className="chk" onClick={e=>onSearchingRadioHandler(e.target.value)} value="out" checked={SchoolTypeToggle === false} />
            <label htmlFor="out">해외</label>
          </div>
          {/*검색*/}

          <div className="input_wrap">
            <select
              name="reh_level"
              value={varSearchOption}
              onChange={(e) => setSearchOption(e.target.value)}
              className="wd-fix-wd30"
            >
                {/* api 에서 전체를 지원해주지 않아서 주석 처리 */}
              {/* <option value="0">전체</option> */}
              {schoolLevelList}
            </select>
            <input
              type="text"
              placeholder="학교명을 입력해주세요."
              name="searching_string"
              value={varSearchString}
              onChange={(e) => onSearchStringHandler(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearchingHandler(e)
                }
              }}
            />
            {(SchoolTypeToggle === true) ?
            <button
              className="btn"
              onClick={(e) => {
                onSearchingHandler(e);
              }}
            >
              조회
            </button>
            : ""}
          </div>
          {(SchoolTypeToggle === true) ?
          <div className="select_box">
            <div className="wrap scroll">
              {varSchoolNameList.map((schools, index) => (
                <button
                  key={"psch" + index}
                  className="button"
                  onClick={(e) => onSelectSchoolHandler(e, schools.vsh_name)}
                >
                  {schools.vsh_name}
                </button>
              ))}
            </div>
          </div>
          : ""}
        </div>
        <div className="button_wrap">
          <div className="big_button_wrap one">
            <button onClick={(e) => onSelectedPopSchoolHandler(e)} className="btn">
              추가
            </button>
          </div>
          <div className="close_button_wrap">
            <button onClick={(e) => onClosePopSchoolHandler(e)}>닫기 X</button>
          </div>
        </div>
      </article>
    </section>
  );
}

export default PopSchool;
