import React from 'react'

//이미지
import icon_repu_pop_sad from "img/sub/repu_pop_sad.png";
import { Link } from 'react-router-dom';

const PopColleRepuOK = ({ toMemberName, toMemberMobile, repuCode }) => {
	return (
		<div className={"popup active"}>
			<div className="popup_wrap popup01">
				<section className="title_wrap">
					<h1>동료평판하기</h1>
				</section>
				<section className="content_wrap">
					<article className="notice_area">
						<div className="icon"><img src={icon_repu_pop_sad} alt="icon_pw" /></div>
						<p className="title_field">회원님과 동료분이 <span className="point blue">일치하는 <br />직장이 있으므로</span> 동료 평판 대상자 입니다.</p>
					</article>
				</section>
				<section className="button_wrap">
					{/* <div className="big_button_wrap two"> */}
					<div className="big_button_wrap one">
						{/* <a className="btn white">취소</a> */}
						<Link className="btn" to={'../career-reputation/submit'} state={{ to_mem_name: toMemberName, to_mem_mobile: toMemberMobile, repuCode: repuCode}}>동료 평판 진행하기</Link>
					</div>
					{/* <div className="close_button_wrap">
							<a>닫기 X</a>
						</div> */}
				</section>
			</div>
		</div>
	)
}

export default PopColleRepuOK