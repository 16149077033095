/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import iconhome from '../../img/icon-home.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arr from '../../img/arr.png';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import Clock from './Colck';
import moment from "moment";
import { useNavigate } from "react-router-dom";

function Dash_board_list({ handleCategory }) {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  var toDay = new Date();
  const [time, setTime] = useState(new Date());
  const [Counts, setCounts] = useState({
    today_join_count: 0,
    today_new_notice: 0,
    wating_notice: 0,
    unapproved_count: 0,
    wating_payment: 0,
    wating_hr: 0,
    today_consulting: 0,
    exchange_request: 0,
    accumulate: 0,
    collect: 0,
    certification_experience: 0,
    resume_down: 0,
    reputation_of_acquaintances_down: 0,
    peer_reputation_down: 0
  });

  const timeout = () => {
    //현재 시간부터 자정까지 남은 시간 계산
    console.log(new Date())
    var a = moment().add(1, 'days').hours('00').minutes('00').seconds('00');
    var b = moment();
    const time = a.diff(b) // 86400000

    console.log(a.diff(b));

    setTimeout(() => {
      getDashBoard();
    }, time);
  };

  useEffect(() => {
    timeout();
    return () => {
      // clear 해줌
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    getDashBoard();

  }, []);

  const getDashBoard = () => {
    axios.post(`${CONFIG.SERVER_HOST}/getDashboardCount`, CONFIG.header).then((res) => {
      console.log(res);
      const { data } = res;
      setCounts({
        ...Counts,
        today_join_count: data.today_join_count,
        today_new_notice: data.today_new_notice,
        wating_notice: data.wating_notice,
        unapproved_count: data.unapproved_count,
        wating_payment: data.wating_payment,
        wating_hr: data.wating_hr,
        today_consulting: data.today_consulting,
        exchange_request: data.exchange_request,
        accumulate: data.accumulate,
        collect: data.collect,
        certification_experience: data.certification_experience,
        resume_down: data.resume_down,
        reputation_of_acquaintances_down: data.reputation_of_acquaintances_down,
        peer_reputation_down: data.peer_reputation_down

      });
    });
  };

  const movePage = (type) => {
    switch (type) {
      case 0:
        navigator('/admin/userlist');
        break;
      case 1:
        navigator('/admin/announcement_list');
        break;
      case 2:
        navigator('/admin/announcement_list');
        break;
      case 3:
        navigator('/admin/corporate_member_list');
        break;
      case 4:
        navigator('/admin/paymentlist');
        break;
      case 5:
        navigator('/admin/hrintrolist');
        break;
      case 6:
        navigator('/admin/consultlist');
        break;
      case 7:
        navigator('/admin/exchangelist');
        break;
      case 8:
        navigator('/admin/accumulatelist');
        break;
      case 9:
        navigator('/admin/quit_member_list');
        break;
      default:
        break;
    }

  }
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="dashboard" />
      <Menu menuType="dashboard" menu="dashboard_list" />
      <div id="contents" className="ad_contents">
        <div className="tit">Dash board
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arr} alt="" />Dash board<img src={arr} alt="" />Dash board
          </div>
        </div>
        <div className="content p_dashboard">
          <div className="date_sec">
            <span>Today :</span><span className="date"><Clock /></span>
          </div>
          <div className="dashboard">
            <div className="main-box pointer" onClick={()=>movePage(0)}>
              <p className="tit_field">오늘 가입한 회원</p>
              <p className="num_field">{Counts.today_join_count}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(1)}>
              <p className="tit_field">오늘 신규 등록 공고</p>
              <p className="num_field">{Counts.today_new_notice}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(2)}>
              <p className="tit_field">대기중 채용 공고</p>
              <p className="num_field">{Counts.wating_notice}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(3)}>
              <p className="tit_field">기업/헤드헌터 회원<br />미승인 건수</p>
              <p className="num_field">{Counts.unapproved_count}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(4)}>
              <p className="tit_field">이용권 구매 신청자<br />결재 대기 건수</p>
              <p className="num_field">{Counts.wating_payment}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(5)}>
              <p className="tit_field">HR 담당자 소개 대기 건수 </p>
              <p className="num_field">{Counts.wating_hr}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(6)}>
              <p className="tit_field">오늘 헤딩 컨설팅 신청 건수</p>
              <p className="num_field">{Counts.today_consulting}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(7)}>
              <p className="tit_field">헤딩머니 환전 요청 건수</p>
              <p className="num_field">{Counts.exchange_request}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(8)}>
              <p className="tit_field">오늘 헤딩머니 적립이<br />필요한 건수</p>
              <p className="num_field">{Counts.accumulate}</p>
            </div>
            <div className="main-box pointer" onClick={()=>movePage(9)}>
              <p className="tit_field">헤딩머니 회수 건수</p>
              <p className="num_field">{Counts.collect}</p>
            </div>
            <div className="main-box">
              <p className="tit_field">오늘 인증 경력 진행 수</p>
              <p className="num_field">{Counts.certification_experience}</p>
            </div>
            <div className="main-box">
              <p className="tit_field">오늘 이력서 PDF<br />다운로드 수</p>
              <p className="num_field">{Counts.resume_down}</p>
            </div>
            <div className="main-box">
              <p className="tit_field">오늘 지인평판 PDF<br />다운로드 수</p>
              <p className="num_field">{Counts.reputation_of_acquaintances_down}</p>
            </div>
            <div className="main-box">
              <p className="tit_field">오늘 동료평판 PDF<br />다운로드 수</p>
              <p className="num_field">{Counts.peer_reputation_down}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

}

export default Dash_board_list;