import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import '../../css/Reputation.css';
//이미지
import icon_write from "img/sub/repu_write.png";
import CONFIG from "../../configs/configs"
import axios from 'axios';

const ColleRepuW2 = () => {
	const navigator = useNavigate();
	const location = useLocation();

	const [category, setCategory] = useState([]); // 분류: 관계, 업무스타일, ... 
	const [question, setQuestion] = useState([]); // 분류 내 질문, 질문 내 선택지
	const [isBlind, setIsBlind] = useState('Y');
	//const [userInfo, setUserInfo] = useState({ department: '', position: '' })

  const myName = localStorage.getItem("mem_name");

  const repuCode = location.state?.repuCode;  //2023-02-19 KSR

	const targets = useRef({});
	let tempArray = useRef();

	const onChange = useCallback((e) => {
		const { id, name, value } = e.target;

		const temp = id.split('');
		const index = tempArray.current.findIndex(element => element.name === name);
		// tempArray.current[index].cate_no = parseInt(temp[0], 10);
		// tempArray.current[index].repu_no = parseInt(temp[1], 10);
		tempArray.current[index].ex_no = parseInt(temp[2], 10);
		tempArray.current[index].remark = value;

		// 업무상 관계 기타 선택했을 때만 활성화
		if (temp[0] === '7') {
			if (temp[2] === '5') {
				targets.current[name].disabled = false;
				targets.current[name].focus();
			} else {
				targets.current[name].disabled = true;
			}
		}
	}, []);

	// 동료평판 제출
	const submitReputation = useCallback(() => {
		// 유효성 검사
		const isValidate = () => {
			const length = tempArray.current.length;
			const checkArray = tempArray.current;
			console.debug(tempArray);
			for (let i = 0; i < length; i++) {
				if (checkArray[i].ex_no === undefined) {
					// 					alert(`${category[checkArray[i].cate_no - 1]?.cate_nm_kr || ''}  '${question[i].repu_q}
					// 문항을 작성해주세요.`);
					alert('빈 문항을 선택 및 작성해주세요');
					// targets.current[`${checkArray[i].cate_no}${checkArray[i].repu_no}`].focus();
					// targets.current[`${checkArray[i].cate_no}${checkArray[i].repu_no}`].scrollIntoView({ behavior: 'smooth', block: 'start' });
					const offsetTop = document.getElementById(`${checkArray[i].cate_no}${checkArray[i].repu_no}1`).parentElement.offsetTop;
					const height = window.innerHeight;
					const offset = height / 3;
					window.scrollTo({ behavior: 'smooth', top: offsetTop - offset })
					return false;
				}
			}
			return true;
		};

		if (!isValidate()) return;

		const targetName = location.state?.to_mem_name;
		const targetMobile = location.state?.to_mem_mobile;

		if (!(targetName && targetMobile)) {
			alert('평가대상에 대한 정보가 없습니다. ');
			return;
		}

		const sendData = {
			// to_mem_idx: localStorage.getItem("mcm_idx"),
			to_mem_name: targetName,
			to_mem_mobile: targetMobile,
			mem_idx: localStorage.getItem("mem_idx"),
			mem_name: localStorage.getItem("mem_name"),
			url: `${CONFIG.URL}/MyCareer/career-reputation`,
			reputation: tempArray.current,
			isBlind: isBlind
		};

		axios.put(`${CONFIG.SERVER_HOST}/career-reputation`, sendData) // CONFIG.header
			.then((res) => {
				if (res.data.retvalue === 1) {
					alert('동료 평판을 작성 완료했습니다.\n(동료분이 회원가입 후 재직기간이 같을 경우 해당 내용을 확인 할 수 있습니다.)'); // 기입 완료 후 설명 필요, 해당 평판은 ~~~ 님에게 보여지며 등(고객사 요청)
				} else {
					console.debug(res);
					alert(res.data?.message);
				}
				navigator("/MyCareer/career-reputation", { state: { buttonType: 'your' } });
			})
	}, [navigator, location.state, isBlind]);//끝. 동료평판 제출(const submitReputation)

	// 익명 실명 toggle
	const toggle = useCallback(() => {
		setIsBlind(prev => prev === 'Y' ? 'N' : 'Y');
	}, [])

	useEffect(() => {
		const getTitle = () => {
			axios.get(`${CONFIG.SERVER_HOST_REPUTATION}/reputation/RepuPeerCate`).then((res) => {
				setCategory(res.data);
			}).catch((e) => console.debug('Error  ', e));
		};

		const getContent = () => {
			axios.get(`${CONFIG.SERVER_HOST_REPUTATION}/reputation/RepuPeerQsn`).then((res) => {
				let result = res.data.data;
				tempArray.current = result.map((item) => {
					return { name: item.cate_no + "" + item.repu_no, cate_no: item.cate_no, repu_no: item.repu_no, ex_no: undefined, remark: '' }
				})
				setQuestion(result);
			}).catch((e) => console.debug('Error  ', e));
		}

		getTitle();
		getContent();
	}, []);

	useEffect(() => {
		/**
		 * 동료평판 작성할 수 있는 방법
		 * 1. 동료평판 페이지에서 상단 동료 평판하기 -> 동료 평판 진행하기
		 * 2. 회원이 동료에게 알림톡을 보내고 해당 링크를 통해 접속했고 동일한 직장일 경우(repuCode 존재)
		 * 
		 * 1번 방법은 평판 대상에 대한 정보가 이름과 전화번호 뿐인데 해당 정보로는 unique한 mem_idx를 알 수 없음
		 * member 테이블
		 *  - SNS/일반회원 가입하는 경우 동일한 이름과 전화번호를 가질 수 있음
		 *  - 일반회원 가입하는 경우 동일한 이름과 전화번호를 가질 수 있음
		 * --> 평판 대상에 대한 정보인 이름과 전화번호로 unique한 mem_idx를 추출할 수 없음
		 * 
		 * 2번 방법은 unique한 mem_idx 추출 가능
		 * 
		 * ***************************************************************************************************
		 * 회원 정보 조회는 2번방법으로만 진행, 1번 방법은 table 구조 및 제약조건이 변경되어 
		 * 전화번호와 이름만 가지고 회원에 대한 unique한 정보 얻을 수 있을 때 수정예정
		 */

    /* 2023-02-18 사용 안함 - KSR
		const getUserInfo = (repuCode) => {
			const params = new URLSearchParams();
			params.append('to_mem_idx', localStorage.getItem('mem_idx'));
			params.append('repu_code', repuCode);
			axios.get(`${CONFIG.SERVER_HOST}/career-reputation/colleague-info`, { params })
				.then((res) => {
					console.debug(res);
					setUserInfo((prev) => prev = {
						...prev, department: res.data.result[0]?.rec_department, position: res.data.result[0]?.rec_position
						, name: res.data.result[0]?.mem_name, mobile: res.data.result[0]?.mem_mobile
					})
				})
				.catch(e => console.debug('Error  ', e));
		}
		const repuCode = localStorage.getItem('repuCode');
		// localStorage.removeItem('repu_code');
		if (repuCode) {
			getUserInfo(repuCode);
		}
    */
	}, [])

	return (
		<section className="com_reputation com_repu_w_r p_colle_w center_wrap">
			<article className="repu_writer_info_wrap">
				<div>
					<h1>평판 대상자 : {location.state?.to_mem_name} </h1>

          {/* 알림톡 링크 타고왔을 때는 평판 대상자 폰번호 숨김 PPT.23P */}
          {repuCode ? "" :
            <>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <h1>평판 대상자 연락처 : {location.state?.to_mem_mobile}</h1>
            </>
          }

          &nbsp;&nbsp;/&nbsp;&nbsp;
          <h1>평판 작성자 : {myName}</h1>

					<div className="setting">
						<input type="checkbox" id="btnToggle1" value={isBlind} onClick={toggle} />
					</div>&nbsp;&nbsp;으로 진행하기
            {/*            
            <ul className="info_text_area">
              <li>소속<b>{userInfo?.department}</b></li>
              <li>직급<b>{userInfo?.position}</b></li>
              <li>연락처<b>{location.state?.to_mem_mobile}</b></li>
            </ul>
            */}
			  </div>
			</article>
			<article className="repu_write_title">
				<img src={icon_write} alt="평판 작성 아이콘" />동료분과 근무하실 때 어떤 느낌이 들으셨는지 평판해주세요.<span className="red small">* 익명으로 진행 시 작성자의 이름이 익명으로 표시됩니다.</span>
			</article>
			<article className="com_repu_gray_container">
				<form>
					{question?.filter((content) => content.cate_no >= 6)
						.map((content) => {
							return (
								<div className="repu_question" key={`${content.cate_no}`}>
									<h1>{content.repu_q}</h1>
									<ul className="repu_answer">
										{content.ex.map((el) => {
											return (
												<li className={el.repu_a === "기타" ? "has_textbox" : ""}
													key={`${content.cate_no}${el.ex_no}`}
												>
													<input
														type="radio"
														className="chk square"
														name={`${content.cate_no}${content.repu_no}`}
														value=""
														id={`${content.cate_no}${content.repu_no}${el.ex_no}`}
														onChange={onChange}
													// ref={(item) => targets.current[`${content.cate_no}${content.repu_no}`] = item}
													/>
													<label
														htmlFor={`${content.cate_no}${content.repu_no}${el.ex_no}`}
													>
														{el.repu_a}
													</label>
													{el.repu_a === "기타" &&
														<input
															type="text"
															name={`${content.cate_no}${content.repu_no}`}
															id={`${content.cate_no}${content.repu_no}${el.ex_no}`}
															ref={(item) => targets.current[`${content.cate_no}${content.repu_no}`] = item}
															onChange={onChange}
														/>
													}
												</li>
											);
										})}
									</ul>
								</div>
							);
						})}
					{category?.map((title) => {
						return (
							<React.Fragment key={`${title.cate_no}`}>
								<article className="repu_question_wrap horizon2 has_section">
									<h1 className="repu_question_title">
										{title.cate_nm_kr}<span>{title.cate_nm_en}</span>
									</h1>
									{question?.filter((content) => content.cate_no === title.cate_no && content.cate_no !== 5)
										.map((content) => {
											return (
												<section className="left_section" key={`${title.cate_no}${content.repu_no}`}>
													<div className="repu_question align_v">
														<h1>{content.repu_q}</h1>
														<ul className="repu_answer">
															{content?.ex.map((el) => {
																return (
																	<li key={`${title.cate_no}${content.repu_no}${el.ex_no}`}>
																		<input type="radio"
																			className="chk square wide"
																			name={`${title.cate_no}${content.repu_no}`}
																			id={`${title.cate_no}${content.repu_no}${el.ex_no}`}
																			value=""
																			onChange={onChange}
																		// ref={(item) => targets.current[`${content.cate_no}${content.repu_no}`] = item}
																		/>
																		<label htmlFor={`${title.cate_no}${content.repu_no}${el.ex_no}`}><span>{el.repu_a}</span></label>
																	</li>
																);
															})}
														</ul>
													</div>
												</section>
											);
										})}
									{question?.filter((content) => content.cate_no === title.cate_no && content.cate_no === 5)
										.map((content) => {
											return (
												<React.Fragment key={`${title.cate_no}${content.repu_no}`}>
													{content.repu_no < 4 &&
														<section className="left_section">
															<div className="repu_question align_v">
																<h1>{content.repu_q}</h1>
																<ul className="repu_answer">
																	{content?.ex.map((el) => {
																		return (
																			<li key={`${title.cate_no}${content.repu_no}${el.ex_no}`}>
																				<input type="radio"
																					className="chk square wide"
																					name={`${title.cate_no}${content.repu_no}`}
																					value=""
																					id={`${title.cate_no}${content.repu_no}${el.ex_no}`}
																					onChange={onChange}
																				// ref={(item) => targets.current[`${content.cate_no}${content.repu_no}`] = item}
																				/>
																				<label htmlFor={`${title.cate_no}${content.repu_no}${el.ex_no}`}><span>{el.repu_a}</span></label>
																			</li>
																		)
																	})}
																</ul>
															</div>
														</section>
													}
													{content.repu_no === 4 &&
														<section className="right_section">
															<div className="repu_question align_v">
																<h1>{content.repu_q}</h1>
																<ul className="repu_answer long_answer">
																	{content.ex.map((el) => {
																		return (
																			<li key={`${title.cate_no}${content.repu_no}${el.ex_no}`}>
																				<input
																					type="radio"
																					className="chk square"
																					name={`${title.cate_no}${content.repu_no}`}
																					value=""
																					id={`${title.cate_no}${content.repu_no}${el.ex_no}`}
																					onChange={onChange}
																				// ref={(item) => targets.current[`${content.cate_no}${content.repu_no}`] = item}
																				/>
																				<label
																					htmlFor={`${title.cate_no}${content.repu_no}${el.ex_no}`}>{el.repu_a}<br /></label>
																			</li>
																		);
																	})}
																</ul>
															</div>
														</section>
													}
												</React.Fragment>
											);
										})}
								</article>
								{question?.filter((content) => content.cate_no === title.cate_no && content.cate_no === 5)
									.map((content) => {
										return (
											content.cate_no === 5 && content.repu_no > 4 &&
											<div className="repu_question align_v only_txtbox" key={`${title.cate_no}${content.repu_no}`}>
												<h1>{content.repu_q}<span>*</span></h1>
												<ul className="repu_answer">
													<li>
														<input
															type="text"
															onChange={onChange}
															name={`${title.cate_no}${content.repu_no}`}
															id={`${title.cate_no}${content.repu_no}1`}
														// ref={(item) => targets.current[`${content.cate_no}${content.repu_no}`] = item}
														/>
													</li>
												</ul>
											</div>
										);
									})}
							</React.Fragment>
						);
					})}
				</form>
			</article>
			<div className="btn_wrap">
				<button type="button" className="btn" onClick={submitReputation}>제출하기</button>
			</div>
		</section>
	)
}

export default ColleRepuW2