/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import CONFIG from "../../../configs/configs";
import axios from "axios";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";

import arrImage from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";

import "admin/css/board.css";

function FaqSeriousDetail() {
  const { vfs_idx } = useParams();
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //심사상태--------------------------------------------------------------------------------------------
  const [varFaqSeriousDetailIdx, setVarFaqSeriousDetailIdx] = useState(0);
  const [varFaqSeriousDetailMode, setVarFaqSeriousDetailMode] = useState("");
  const [varFaqSeriousDetailName, setVarFaqSeriousDetailName] = useState("");
  const [varFaqSeriousDetailUse, setVarFaqSeriousDetailUse] = useState("");
  const [varFaqSeriousDetailIndex, setVarFaqSeriousDetailIndex] = useState("");

  //분류코드
  const handleFaqSeriousDetailMode = (e) => {
    setVarFaqSeriousDetailMode(e.target.value);
  };
  //유형명
  const handleFaqSeriousDetailName = (e) => {
    setVarFaqSeriousDetailName(e.target.value);
  };
  //노출순서
  const handleFaqSeriousDetailIndex = (e) => {
    setVarFaqSeriousDetailIndex(e.target.value);
  };
  //사용유무
  const handleFaqSeriousDetailUse = (e) => {
    setVarFaqSeriousDetailUse(e.target.value);
  };

  //최초에 실행
  useEffect(() => {
    setVarFaqSeriousDetailIdx(vfs_idx);
    //컨설팅 상세
    if (vfs_idx >= 0) {
      const params = new URLSearchParams();
      params.append("vfs_idx", vfs_idx);
      axios
        .post(`${CONFIG.SERVER_HOST}/faqserious_detail`, params)
        .then((res) => {
          console.log(res.data.results[0]);
          setVarFaqSeriousDetailIdx(res.data.results[0].vfs_idx);
          setVarFaqSeriousDetailName(res.data.results[0].vfs_name);
          setVarFaqSeriousDetailMode(res.data.results[0].vfs_mode);
          setVarFaqSeriousDetailIndex(res.data.results[0].vfs_index);
          setVarFaqSeriousDetailUse(String(res.data.results[0].vfs_use));
        });
    }
  }, []);

  //저장하기
  const updateFaqSeriousDetail = () => {
    const params = new URLSearchParams();
    params.append("vfs_idx", varFaqSeriousDetailIdx);
    params.append("vfs_mode", varFaqSeriousDetailMode);
    params.append("vfs_name", varFaqSeriousDetailName);
    params.append("vfs_index", varFaqSeriousDetailIndex);
    params.append("vfs_use", varFaqSeriousDetailUse);
    axios
      .post(`${CONFIG.SERVER_HOST}/faqserious_update`, params)
      .then((res) => {
        if (res.data.retvalue === 1)
          document.location.href = "/admin/faqseriouslist";
        else window.alert("오류가 발생했습니다.");
      });
  };

  //표시-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="faqseriousdetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          FAQ 유형 관리 상세
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arrImage} alt="" />
            FAQ 관리
            <img src={arrImage} alt="" />
            FAQ 유형 관리 상세
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="write">
              <thead></thead>
              <tbody>
                <tr>
                  <th>분류코드</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varFaqSeriousDetailMode}
                      onChange={handleFaqSeriousDetailMode}
                      style={{ width: "30%" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>분류명</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varFaqSeriousDetailName}
                      onChange={handleFaqSeriousDetailName}
                      style={{ width: "30%" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>노출순서</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varFaqSeriousDetailIndex}
                      onChange={handleFaqSeriousDetailIndex}
                      style={{ width: "30%" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>사용여부</th>
                  <td className="write-radio">
                    <label>
                    <input
                      type="radio"
                      name="vfs_use"
                      value="1"
                      onChange={handleFaqSeriousDetailUse}
                      checked={varFaqSeriousDetailUse === "1"}
                    />사용
                    </label>
                    <label>
                    <input
                      type="radio"
                      name="vfs_use"
                      value="0"
                      onChange={handleFaqSeriousDetailUse}
                      checked={varFaqSeriousDetailUse === "0"}
                    />
                    미사용
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/faqseriouslist" className="btn line">
                취소
              </Link>
              <button onClick={updateFaqSeriousDetail} className="btn">
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FaqSeriousDetail;
