import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "configs/configs";

//components
import Header from "./Header";
import BannerBottom from "components/Banner/BannerBottom";
import Footer from "./Footer";


function Email() {
    const user_idx = Number(localStorage.getItem("mem_idx"));
    const navigate = useNavigate();
    const [varInputs, setInputs] = useState({
        title: '',
        email1: '',
        email2: '',
        inquiry: '',
      });

   

    //입력창 변경시
    const onChangeHandler = (e) => {
      const { value, name } = e.currentTarget;
      //console.log({ value, name });
      if (name === "title") {
        setInputs({ ...varInputs, title: value });
      } else if (name === "email1") {
        setInputs({ ...varInputs, email1: value });
      } else if (name === "email2") {
        setInputs({ ...varInputs, email2: value });
      } else {
        setInputs({ ...varInputs, [name]: value });
      }
    };


     //메일서버선택
    const onChangeMailServerHandler = (e) => {
      let eserver = e.currentTarget.value;
      let serverAddress = document.getElementById("email2");
      if (eserver === "") {
        serverAddress.value = "";
        serverAddress.focus();
      } else {
        setInputs({ ...varInputs, email2: eserver });
      }
    };

    //문의 내용 저장
    const onUpdateHandler = async (e) => {
      try {
        if (!varInputs.title) {
          alert("제목을 입력해주세요.");
        } else if (!varInputs.email1 || !varInputs.email2) {
          alert("이메일을 입력해주세요.");
        } else if (!varInputs.inquiry) {
          alert("문의내용 입력해주세요.");
        } else {
          const param = new URLSearchParams();
          param.append("title", varInputs.title);
          param.append("email", varInputs.email1 + "@" + varInputs.email2);
          param.append("inquiry", varInputs.inquiry);
          param.append("mem_idx", user_idx);

          await axios
            .post(`${CONFIG.SERVER_HOST}/email_inquiry`, param)
            .then((res) => {
              if(res.data.retvalue === 1){
                alert(res.data.message);
                navigate('/main');
              }else{
                alert(res.data.message);
              }
            });
          }
        } catch (error) {
            alert("이메일 문의 접수 실패");
            console.log(error);
        }
    };

    return (
        <>
            <Header/>
            <div className="center_wrap board_write">
                <h2 className="page_title">이메일문의</h2>
                <div className="box write">
                    <section className="section">
                        <div className="title_field">제목</div>
                        <div className="input_field"><input type="text" placeholder="제목을 입력해주세요" name="title" value={varInputs.title} onChange={(e) => onChangeHandler(e)}/></div>
                    </section> 
                    <section className="section">
                        <div className="title_field">이메일</div>
                        <div className="input_field">
                            <div className="input_wrap email_field">
                                <input type="text" name="email1" id="email1" value={varInputs.email1} onChange={(e) => onChangeHandler(e)}/><span className="text">@</span><input type="text" name="email2" id="email2" value={varInputs.email2} onChange={(e) => onChangeHandler(e)}/>
                                <select onChange={(e) => onChangeMailServerHandler(e)}>
                                    <option value={""}>직접입력</option>
                                    <option value={"naver.com"}>naver.com</option>
                                    <option value={"gmail.com"}>gmail.com</option>
                                    <option value={"hanmail.net"}>hanmail.net</option>
                                    <option value={"hotmail.com"}>hotmail.com</option>
                                    <option value={"icloud.com"}>icloud.com</option>
                                </select>
                            </div>
                        </div>
                    </section> 
                    <section className="section">
                        <div className="title_field">문의 내용</div>
                        <div className="input_field">
                            <textarea placeholder="문의 내용을 입력해주세요" name="inquiry" value={varInputs.text}onChange={(e) => onChangeHandler(e)}/>
                        </div>
                    </section>
                </div>
                <div className="btn_bottom_area">
                    <Link to={"/main"}className="btn white">취소</Link>
                    <button className="btn" onClick={(e) => onUpdateHandler(e)}>문의하기</button>
                </div>
            </div>
            <BannerBottom/>
            <Footer/>
        </>
       
    )

}

export default Email;