import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';
import CertificationCareerView from "../Resources/CertificationCareer";
import AddCareerView from "../Resources/AddCareer";

/*메인(기업)>추천인재*/
function Recommend() {
  const mcm_idx = localStorage.getItem('mcm_idx');
  const mem_idx = localStorage.getItem('mem_idx');
  // const mem_sub = localStorage.getItem('mem_sub');
  const [varRecommendLists, setRecommendLists] = useState([]);
  //서버의 저장폴더
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;
  //새로운 지원자 
  const getCompanyApply = async () => {
    try {

      console.log('_get_recommend_talent :');
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      // params.append("mem_sub", mem_sub);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_recommend_talent`, params).then((res) => {
        console.log(res.data);
        if (res.data.retvalue === 1) {
          const datas = res.data.results; //결과값 = ['웹프로그래머','응용프로그래머']
          console.log(datas);
          setRecommendLists(datas);
        } else {
          setRecommendLists([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCompanyApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article>
      <hgroup className="sub_content_title_wrap spacebetween">
        <h1 className="sub_content_title indent">추천인재</h1>
        <Link to={"/Company/ComResource/SearchMain"} className="btn line gray">전체보기<span className="nanumGothic">&gt;</span></Link>
      </hgroup>
      <div className="list_box">
        <ul className="talent_list">
          {varRecommendLists.map((recommends, index) => (
            <li key={"recommend_" + index} className="talent_card">
              <Link className="link_wrap" to={`/Company/ComResource/SearchView/${recommends.re_idx}`} state={{ re_idx: recommends.re_idx/* ,mem_idx:recommends.mem_idx */ }}>
                <div className="pic_name_wrap">
                  {recommends.mem_photo === 0 ? <figure></figure>
                    : <figure style={{ backgroundImage: `url(${imageFoler + recommends.mem_ufile})` }}></figure>
                  }
                  <b className="ellipsis">{recommends.mem_name}</b>
                </div>
                <hgroup className="style2">
                  <h1 className="txt_blue ellipsis">
                    {/* {resetCareerForSearching(recommends.re_career_year)} */}
                    {
                      !recommends?.certification_experience_year && !recommends?.certification_experience_year ?
                        <AddCareerView highLookup={recommends} />
                        :
                        <CertificationCareerView highLookup={recommends} />
                    }
                  </h1>
                  {recommends.rec_company ?
                    <h2 className="txt_black ellipsis">{recommends.rec_company}</h2>
                    :
                    null
                  }

                  {recommends.rec_department ?
                    <h3 className="ellipsis">{recommends.rec_department}&nbsp;{recommends.vps_name}</h3>
                    :
                    null

                  }
                  {recommends.reh_name ?
                    <h4 className="ellipsis">{recommends.reh_name}&nbsp;{recommends.reh_major}</h4>
                    :
                    null

                  }

                </hgroup>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
}

export default Recommend;
