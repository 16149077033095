import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';

//components
import Header from "./Header"
import Footer from "./Footer"
import BannerBottom from "components/Banner/BannerBottom"

//css
import "css/Common.css"
import "css/Head.css"
import "css/Footer.css"
import "css/Sub.css"
import "css/Terms.css"
import 'react-quill/dist/quill.snow.css';
import '../../css/React-Quill.css';

function Agreement() {
    const [varContent, setContent] = useState('');

    //이용약관 읽어오기
    useEffect(() => {
        axios.post(`${CONFIG.SERVER_HOST}/_get_conditions`).then(res => {
            setContent(res.data.results.conditions);
        })
    }, [])    


    return (
        <>
          <Header/>
          <main className="center_wrap" >
            <h2 className="page_title">이용약관</h2>
            <div dangerouslySetInnerHTML={{__html:varContent}} className="terms_area ql-editor"></div>
          </main>
          <BannerBottom/>
          <Footer/>
        </>
    )
}

export default Agreement;