
/******************************************************************
 * POP - '친구가 보는 나' 요청하기
 *****************************************************************/
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import CONFIG from '../../configs/configs'

//이미지
import icon_repu_pop_write from "img/sub/repu_pop_write.png";
import axios from 'axios';

const PopAcqRepuRequest = ({ setEnablePop }) => {
	// const repuCode = localStorage.getItem("repuCode"); //내꺼 평판 코드
	// localStorage.removeItem('repuCode');
	//const shareURL = `https://${window.location.hostname}/MyCareer/acquaintance-reputation/submit/?repu_code=${encodeURIComponent(repuCode)}`;

	//2022-10-25 URI 디코드
	// const shareURL = `https://${window.location.hostname}/MyCareer/acquaintance-reputation/submit/${encodeURIComponent(localStorage.getItem("repuCode"))}`;

	// 2022.11.18 jskim
	const shareURL = `${CONFIG.URL}/MyCareer/acquaintance-reputation/submit/${encodeURIComponent(localStorage.getItem("repuCode"))}`;
	const [shortURL, setShortURL] = useState('');

	const handleLinkCopy = () => {
		// navigator.clipboard.writeText(shareURL);
		alert("링크가 복사되었습니다.");
		setEnablePop(false);
	}


	useEffect(() => {
		const getShortURL = () => {
			const params = new URLSearchParams([['url', shareURL]]);
			axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/short-url`, { params }).then((res) => {
				if (res.data?.url) {
					setShortURL(res.data.url);
				} else {
					setShortURL(shareURL);
				}
			}).catch((e) => {
				console.debug(e);
			});
		};
		getShortURL();
	}, [shareURL]);

	return (
		<div className={"popup active"}>
			<div className="popup_wrap popup01 pop_repu">
				<section className="title_wrap">
					<h1>'친구가 보는 나' 요청하기</h1>
				</section>
				<section className="content_wrap">
					<article className="notice_area">
						<div className="icon"><img src={icon_repu_pop_write} alt="icon_pw" /></div>
						<p className="title_field">친구들에게 Url 공유하고 테스트 요청하세요.</p>
					</article>
					<div className="field">
						<input type="text" value={shortURL} readOnly />
					</div>
				</section>
				<section className="button_wrap">
					<div className="big_button_wrap two">
						<button onClick={() => setEnablePop(false)} className="btn white">닫기</button>
						<CopyToClipboard className="btn" text={shortURL} onCopy={handleLinkCopy}>
							<button className="btn">링크 복사하기</button>
						</CopyToClipboard>
					</div>
				</section>
			</div>
		</div>
	)
}

export default PopAcqRepuRequest