import React, { useState } from 'react';
// import { useLocation } from "react-router-dom";
//css
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/MyResume.css'; 
import '../../css/Davehan.css';

//compenent
import ResumeCompanies from '../../components/Resume/ResumeCompanies';
import ResumeLimitCompanies from '../../components/Resume/ResumeLimitCompanies';

const ResumeLimit = () => {
  //const location = useLocation();
  const userName = localStorage.getItem('mem_name');
  
  //열람제한기업목록 재설정
  const [varChangeLimitList,setChangeLimitList] = useState(true);
  //검색한 회사 목록 배열
  const [varCompanyList,setCompanyList] = useState([]);
  //열람제한 기업 목록 배열
  const [varLimitCompanyList,setLimitCompanyList] = useState([]);

  return (
    <main id="sub_wrap" className="center_wrap page_restricted">
      <ResumeCompanies varCompanyList={varCompanyList} setCompanyList={setCompanyList} setChangeLimitList={setChangeLimitList} userName={userName}/>
      <ResumeLimitCompanies varLimitCompanyList={varLimitCompanyList} setLimitCompanyList={setLimitCompanyList} 
          varChangeLimitList={varChangeLimitList} setChangeLimitList={setChangeLimitList} userName={userName}/>
    </main>
  );
}

export default ResumeLimit