import React from 'react';
import ConsultingWrite from 'components/My/ConsultingWrite';

function ConsultingTop({varInput, onCheckedHandler, varPopupResumeList, onSelectedResumeHandler, onChangeHandler}) {

  return (
    <div className="box">
       <section className="box_top">
            <h3 className="my_title">헤딩 이직 & 이력서 컨설팅</h3>
            <div className="my_subtitle">이직, 어디서 부터 어떻게 시작해야 할지 어려우시죠? 처음부터 제대로 컨설팅해드립니다.</div>
            <h3 className="my_title">국내 MBA 컨설팅</h3>
            <div className="my_subtitle">국내 MBA 도전을 생각하시나요? 국내 MBA 관련 전문 상담해드립니다.</div>
        </section>
        <ConsultingWrite varInput={varInput} onCheckedHandler={onCheckedHandler} varPopupResumeList={varPopupResumeList} onSelectedResumeHandler={onSelectedResumeHandler} onChangeHandler={onChangeHandler} />
    </div>
  );
}

export default ConsultingTop;
