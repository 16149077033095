import React from "react";


//components
import Header from "./Header";

//css
import "css/AboutUs.css";

//이미지
import aboutus from "img/sub/aboutus.jpg"
import BannerBottom from "components/Banner/BannerBottom";
import Footer from "./Footer";

function AboutUs() {

	return (
		<>
		<Header/>
			<div className="center_wrap page_aboutus">
				{/* <h2 className="page_title">회사소개</h2> */}
				<section className="title_area">
					<p>헤딩은 좋은 인재, 좋은 기업을 연결해 더 나은 세상을 만듭니다.</p>
					<p className="point">HEDING = <span className="normal_text">FULL A</span>HE<span className="normal_text">A</span>D&nbsp;+&nbsp;ING</p>
				</section>
				<section className="img_area"><img src={aboutus} alt="aboutus"/></section>
				<section className="text_area">Full ahead는 항해 중 낼 수 있는 최고 속력과 힘으로 전진한다는 뜻을 가지고 있습니다.<br/>
				<span className="point_txt">헤딩은 더 멀리 나아가는 모두의 여정을 함께한다는 의미로 만들어졌습니다.</span><br/>
				항해와도 같은 커리어 여정에서 여러분을 도울 수 있는 든든한 바람이 되겠습니다.<br/>
				</section>
			</div>
			<BannerBottom/>
			<Footer/>
		</>

	)
}

export default AboutUs;