/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import arr from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";
import CONFIG from "../../../configs/configs";
import axios from "axios";

import "admin/css/board.css";
import "../../../css/Common.css";


function ResumeTipListDetail() {
  const { career_idx } = useParams();
  const navigate = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [careerTitle, setCareerTitle] = useState("");
  const [careerContent, setCareerContent] = useState("");
  
  //페이지
  const [varTotalCount, setTotalCount] = useState(0); //eslint-disable-line no-unused-vars

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigate("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titleOnchangeHandler = (e) => {
    setCareerTitle(e.target.value);
  }

  const contentOnchangeHandler = (e) => {
    setCareerContent(e.target.value);
  }

  const onclickUpdateButton = async () => {
    const params = new URLSearchParams();
    params.append("career_idx", career_idx);
    params.append("career_tip_title", careerTitle);
    params.append("career_tip_content", careerContent);
    axios.post(`${CONFIG.SERVER_HOST}/career_tip_update`, params).then((res) => {
      if(res.data.retvalue === 1){
        alert(res.data.message);
        navigate("/admin/resumetiplist");
      }else{
        alert(res.data.message);
      }
    })
    .catch((err) => {
      alert('처리중 문제가 발생했습니다.');
      console.debug(err);
    });
  }


  useEffect(() => {
    if (Number(career_idx) > 0) {
      const params = new URLSearchParams();
      params.append("career_idx", career_idx);
      axios.post(`${CONFIG.SERVER_HOST}/resume_career_tip_detail`, params).then((res) => {
        setCareerTitle(res.data.results[0]?.career_tip_title)
        setCareerContent(res.data.results[0]?.career_tip_content)
      });
    }
  }, [career_idx]);



  return (
    <>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="resumetipdetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          이력서 TIP 상세
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arr} alt="" />
            게시판 관리
            <img src={arr} alt="" />
            이력서 TIP 관리
          </div>
        </div>
        <div className="content" >
        <p style={{ marginTop: "20px" }} className="table_title">- 이력서 TIP 정보</p>
          <div className="main-box" >
            <table className="write">
              <tbody>
                <tr>
                  <th>제목</th>
                  <td colSpan={3} style={{ textAlign: "left" }}>
                    <input
                      type="text"
                      name = "title"
                      value={careerTitle}
                      onChange={(e) => titleOnchangeHandler(e)}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>내용</th>
                  <td colSpan={3}>
                    <textarea
                        type="text"
                        className="textarea_h250"
                        value={careerContent}
                        onChange={(e) => contentOnchangeHandler(e)}
                      />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/resumetiplist" className="btn line"> 취소 </Link>
              <button onClick={() => onclickUpdateButton()} className="btn"> 수정 </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumeTipListDetail;
