import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

//css
// import "css/Company.css";
// import "css/CompanyMy.css";
// import "css/Common.css";
// import "css/Sub.css";
// import "css/Board.css";
// import "css/My.css";

import { resetNavMenu } from "components/Common/Common";
//components
import HeaderCompany from 'components/Layout/HeaderCompany';
import Footer from 'components/Layout/Footer';
import CompanyMenu from 'components/Layout/CompanyMenu';
import MyHeding1 from 'components/Company/MyHeding1';
import MyHeding1sub from 'components/Company/MyHeding1sub';
import MyHeding2 from 'components/Company/MyHeding2';
import MyHeding3 from 'components/Company/MyHeding3';
import MyHeding4 from 'components/Company/MyHeding4';
import MyHeding5 from 'components/Company/MyHeding5';
import MyHeding6 from 'components/Company/MyHeding6';
import MyHeding7 from 'components/Company/MyHeding7';
import MyHeding8 from 'components/Company/MyHeding8';
import MyHeding9 from 'components/Company/MyHeding9';
import MyHeding10 from 'components/Company/MyHeding10';
import MyHeding11 from 'components/Company/MyHeding11';
import MyHeding12 from "components/Company/MyHeding12";
import MyHeding13 from "components/Company/MyHeding13_Com";
import MyMoneyChangeWrite from "components/HedingMoney/MyMoneyChangeWrite";
import MyMoneyChangeView from "components/HedingMoney/MyMoneyChangeView";
import MyMoneyView from "components/HedingMoney/MyMoneyView";
import BannerBottom from 'components/Banner/BannerBottom';
import ComNoticeView from "components/Board/ComNoticeView";


function ComHeding() {
  const mem_idx = localStorage.getItem("mem_idx");
  const mem_sub = localStorage.getItem("mem_sub");
  const memlogin = localStorage.getItem("memlogin")
  const navigate = useNavigate();
  //현재 사용중인 마이헤딩 좌측메뉴 번호 저장 변수
  const [varSubItems, setSubItems] = useState(1);
  //현재 사용중인 마이헤딩 좌측메뉴 번호를 지정하는 함수
  const getSubItem = (varSubItems) => {
    setSubItems(varSubItems);
  };
  useEffect(() => {
    // resetNavMenu(5);
    mem_idx === mem_sub ? resetNavMenu(5) : resetNavMenu(4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
  if(memlogin === undefined || memlogin === null || memlogin === false ){
    alert("로그인 후 이용 가능합니다.");
    navigate("/Login");
  } // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


const [NumberReset, setNumberReset] = useState(false);

const NumReset = () =>{
  setNumberReset(!NumberReset);
};

  return (
    <>
      <HeaderCompany NumberReset={NumberReset} setNumberReset={setNumberReset}/>
      {/* <SubVisual PageTitle={"MyHEDING"} SubVisual_className={"company6"} /> */}
      {/*전달하는 문자+백이미지로 상부에 표시*/}
      <div id="sub_wrap" className="center_wrap layout_my">
        {/*전체 페이지*/}
        <h2 className="sub_content_title">My HEDING</h2>
        <CompanyMenu varSubItems={varSubItems} getSubItem={getSubItem} />
        {/*좌측메뉴*/}
        <Routes>
          <Route path="/" element={<MyHeding1 getSubItem={getSubItem} />} />
          <Route path="MyHeding1" element={<MyHeding1 getSubItem={getSubItem} />} />
          <Route path="MyHeding1sub" element={<MyHeding1sub getSubItem={getSubItem} />} />
          <Route path="MyHeding2" element={<MyHeding2 getSubItem={getSubItem} />} />
          <Route path="MyHeding3" element={<MyHeding3 getSubItem={getSubItem} />} />
          <Route path="MyHeding4" element={<MyHeding4 getSubItem={getSubItem} />} />
          <Route path="MyHeding5" element={<MyHeding5 getSubItem={getSubItem} />} />
          <Route path="MyHeding6" element={<MyHeding6 getSubItem={getSubItem} />} />
          <Route path="MyHeding7" element={<MyHeding7 getSubItem={getSubItem} NumReset={NumReset} />} />
          <Route path="MyHeding8" element={<MyHeding8 getSubItem={getSubItem} />} />
          <Route path="MyHeding9" element={<MyHeding9 getSubItem={getSubItem} />} />
          <Route path="MyHeding10" element={<MyHeding10 getSubItem={getSubItem} />} />
          <Route path="MyHeding11" element={<MyHeding11 getSubItem={getSubItem} />} />
          <Route path="MyHeding12" element={<MyHeding12 getSubItem={getSubItem} />} />
          <Route path="MyHeding13" element={<MyHeding13 getSubItem={getSubItem} />} />
          <Route path="MyHeding13/NoticeView/:board_idx" element={<ComNoticeView getSubItem={getSubItem} />} />
          <Route path="MyMoneyChangeWrite" element={<MyMoneyChangeWrite getSubItem={getSubItem} backPage={6} />} />
          <Route path="MyMoneyChangeView" element={<MyMoneyChangeView getSubItem={getSubItem} backPage={6} />} />
          <Route path="MyMoneyView" element={<MyMoneyView getSubItem={getSubItem} backPage={6} />} />
        </Routes>
      </div>
      <BannerBottom />
      <Footer /> {/*푸터공통*/}
    </>
  )
}

export default ComHeding
