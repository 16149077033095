//css

//components
import Header from '../../components/Layout/HeaderCompany';
import SubVisual from '../../components/Layout/SubVisual';
import Footer from '../../components/Layout/Footer';
import MyMenu from '../../components/Layout/MyMenuCompany';
import Profile from '../../components/Company/Profile';
import ProfileInfo from '../../components/Company/ProfileInfo';
import BannerBottom from 'components/Banner/BannerBottom';
//이미지

/*마이페이지(기업회원)>정보관리*/
function My() {
  return (
    <div className="LayoutMy">
        <Header />
        <SubVisual PageTitle="MyHEDING" />
        <div id="SubWrap">
            <div className="CenterWrap">
                <div className="container">
                    <MyMenu SelectedMy="정보관리" Menu1="Active"/>
                    <div className="MyContents SectionBox p_mycompany">
                        <h3 className="SectionTitle Col2">
                            정보관리    
                            <a href="javasctipr:;" className="btn">회원탈퇴</a>
                        </h3>
                        <div className="Profile">
                            <Profile/>
                            <ProfileInfo/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BannerBottom />
        <Footer/>
    </div>
  );
}

export default My;
