/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import './Variables.css';

function OtherVariables() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  //컨설팅 신청 항목--------------------------------------------------------------------------------------------
  const [varConsultApp, setVarConsultApp] = useState([]);
  const [varConsultAppAdd, setVarConsultAppAdd] = useState("");
  const [selectedConsultApp, setSelectedConsultApp] = useState("");
  //항목선택시
  const handleConsultApp = (e) => {
    setSelectedConsultApp(e.target.value);
  };
  //항목입력시
  const handleConsultAppAdd = (e) => { 
    setVarConsultAppAdd(e.target.value);
  };
  //로그인 여부 확인
  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //최초에 실행
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_consult_app`).then((res) => {
        setVarConsultApp(res.data);
    });
  },[]);
  //선택항목 삭제
  const deleteConsultApp = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vca_idx', selectedConsultApp);
    axios.post(`${CONFIG.SERVER_HOST}/variable_consult_app_delete`,params).then((res) => {
      setVarConsultApp(res.data);
      setSelectedConsultApp("");
    }); 
  }
  //선택항목 추가
  const addConsultApp = (e) => {
    if(varConsultAppAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vca_name', varConsultAppAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_consult_app_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarConsultAppAdd("");
        setVarConsultApp(res.data.results);
        setSelectedConsultApp(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
      /*
      if(res.data === '') {
        alert('이미 존재하므로 추가할 수 없습니다.'); return;
      }
      //console.log(res.data[0]);
      setVarConsultAppAdd("");
      setVarConsultApp(res.data[1].results);
      setSelectedConsultApp(res.data[0].newid);
      */
    }); 
  }
  
  //표시--------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="variables" />
      <Menu menuType="variables" menu="othervariables" />
      <div id="contents" className="ad_contents">
        <div className="tit">기타 항목 관리
          <div className="path">
            <img src={iconhome} alt=""/>
            HOME<img src={arrImage} alt=""/>선택항목관리<img src={arrImage} alt=""/>기타 항목 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="select-list">
              <tr>
                <th>컨설팅 신청 항목</th>
                <td className="select-items">
                  <select size="5" onChange={(e)=>handleConsultApp(e)} value={selectedConsultApp}>
                    { varConsultApp.length > 0 ? varConsultApp.map((data,i) => (
                      <option key={'cons'+i} value={data.vca_idx}>{data.vca_name}</option>     
                    )) : null }
                  </select>
                </td>
                <td className="select-addition">
                  <button onClick={(e)=>deleteConsultApp(e)} className="select-btn-delete">삭제</button>
                </td>
                <td className="select-addition sel-label"><label>추가항목</label></td>
                <td className="select-input">
                  <input type="text" onChange={(e)=>handleConsultAppAdd(e)} value={varConsultAppAdd} onKeyDown={(e) => { if (e.key === 'Enter') addConsultApp(e)}}/>
                </td>
                <td className="select-addition"><button onClick={(e)=>addConsultApp(e)} className="select-btn-add">추가하기</button></td>
              </tr> 
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OtherVariables;