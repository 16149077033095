import React from "react";
import { Link } from "react-router-dom";
import { numberToKorean2 } from "components/Common/Common";

/*채용 지원 현황*/
function HedingRecommendList({ varRecommendList }) {

    return (
        <>
            <table className="table_list01">
                <colgroup>
                    <col /><col width="130" /><col width="120" /><col width="90" /><col width="90" /><col width="110" /><col width="110" /><col width="90" />
                </colgroup>
                <thead>
                    <tr>
                        <th className="th_title">채용제목</th>
                        <th>회사명</th>
                        <th>직무</th>
                        <th>보상금</th>
                        <th>후보자</th>
                        <th className="th_date">추천일자</th>
                        <th className="th_date">지원일자</th>
                        <th>진행상태</th>
                    </tr>
                </thead>
                <tbody>
                    {varRecommendList.length > 0 ? varRecommendList.map((recommends, i) => (
                        <tr key={"recommend_" + i}>
                            <td className="td_title with_btn">
                                <Link to={`/MyRecruit/MainView/${recommends.em_idx}/${recommends.em_headhunter}`}>{recommends.title}</Link>
                            </td>
                            <td className="td_info ellipsis"><span className="field_tit">회사명 : </span>{recommends.cname}</td>
                            <td className="td_info ellipsis">
                                <span className="field_tit">직무 : </span>
                                {recommends?.vwd_name ? recommends.vwd_name :
                                    recommends?.vwc_name ? recommends.vwc_name : '-'}
                            </td>
                            <td className="td_info">
                                <span className="field_tit">보상금 : </span>
                                {recommends?.em_headhunter ? (recommends?.emh_reward_recommend === 0 || recommends?.emh_reward_recommend === undefined ? "-" : numberToKorean2(recommends?.emh_reward_recommend - recommends?.emh_reward_applicant) + "원")
                                    : (recommends?.em_reward_recommend === 0 ? "-" : numberToKorean2(recommends?.em_reward_recommend - recommends?.em_reward_applicant) + "원")}
                            </td>
                            <td className="td_info ellipsis"><span className="field_tit">후보자 : </span>{recommends.recommend}</td>
                            <td className="td_info"><span className="field_tit">추천일자 : </span>{recommends.er_rdate}</td>
                            <td className="td_info last">
                                <span className="field_tit">지원일자 : </span>
                                {recommends.ea_rdate ? recommends.ea_rdate : '-'}
                            </td>
                            <td className="td_state">
                                <span className={recommends.ea_idx ? "state3" : "state1"}>
                                    {recommends.ea_idx ? recommends.ems_name : '미지원'}
                                </span>
                            </td>

                        </tr>
                    ))
                        : <tr><td colSpan="8" className="no_data">불러올 데이터가 없습니다.</td></tr>
                    }
                    
                </tbody>
            </table>
        </>
    );
}

export default HedingRecommendList;
