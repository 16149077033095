/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { useLocation, useNavigate } from "react-router-dom";
import { AppLoginSuccess, blank_pattern } from "../Common/Common";

const GoogleCallback = () => {
  const navigator = useNavigate();
  const location = useLocation();

  useEffect( async()=>{
    try {
     
      // const urlparams = new URLSearchParams(window.location.search);
      // let email = urlparams.get("email");
    
      let getParameter = (key) => {
        return new URLSearchParams(window.location.search).get(key);
      };
      let email = getParameter("email");
      let sub = getParameter("sub");
  
      console.log(email);
      console.log(sub);

      if(!email && !sub){
        alert('구글로그인 인증에 실패했습니다. 다시 시도해 주세요');
        navigator('/Login');
        return false;
      }
			const params = new URLSearchParams();
			params.append("mem_id", sub);
			params.append("mem_type", 3);

			await axios.post(`${CONFIG.SERVER_HOST}/snsLogin`, params).then((res) => {
				console.log(res);
				if (res.data.retvalue === 1) {

					const data = res.data.results;
					//AppLoginSuccess(data.mem_idx);
					sessionStorage.setItem("isLogin", true)
					localStorage.setItem("memlogin", true);
					localStorage.setItem("mem_id", data.mem_id);
					localStorage.setItem("mem_idx", data.mem_idx);
					localStorage.setItem("mem_nickname", data.mem_nickname);
					localStorage.setItem("mem_name", data.mem_name);
					localStorage.setItem("mem_mode", 0); //0:일반/1:기업/2:헤드헌터
					localStorage.setItem("mem_hunter_type", 0); //0:프리랜서/1:서치폼 (헤드헌터)
					localStorage.setItem("mem_type", data.mem_type); //0:일반/1:Naver/2:Kakao/3:Google
					localStorage.setItem("mcm_idx", 0);
					localStorage.setItem("mem_approval", data.mem_approval);
					localStorage.setItem("auto_login", localStorage.getItem("google_auto"))
					//2022-10-15 지인평한 관련 - KSR
					localStorage.setItem("repuMy_YN", data.refu_self_YN);	//자기자신 평판 여부
					localStorage.setItem("repuCode", data.refu_code);	//평판 코드
					localStorage.removeItem("sns_id");
					localStorage.removeItem("sns_type");
					localStorage.removeItem("new");

					// 동료평판, jskim 2022.11.02
					const reDirectURL = localStorage.getItem("re_url");
					localStorage.removeItem('re_url');

					const params2 = new URLSearchParams();
					params2.append("mem_idx", data.mem_idx);
					axios.post(`${CONFIG.SERVER_HOST}/updateLastLogin`, params2).then((res) => {console.log(res)});

					if (!reDirectURL) {
						navigator("/main");
					} else {
						if (location?.state?.reputation) {
							/** reputation
							 * 1. 미로그인 상태에서 동료평판 페이지 첫 접속
							 * 2. 동료 평판 페이지의 미로그인 상태일때 나오는 팝업창 버튼 클릭 후 로그인 페이지로 이동
							 * 3. 로그인 성공 후 다시 re-direct로 동료평판 페이지 이동한 경우, reputation: true
							 */
							// navigator(reDirectURL, { state: { reputation: true } });
							navigator(reDirectURL);
						}
						navigator(reDirectURL);
					}
				} else {
					//localStorage.setItem("new", true);
					navigator("/JoinUs/User", { state: { sns_id: sub, sns_type: 3, email: email} });
				}
			});
		} catch (error) {
			console.log(error);
		}

  }, []);
  return null; 
};
export default GoogleCallback;