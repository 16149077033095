import React from "react";
import LoadingImg from 'img/common/loading.gif';

function Loading({text}) {
    return (
      <div className="loading" id="loadingTag" style={{display: 'block'}}>
        <div className="loading_wrap">
          <img src={LoadingImg} alt="로딩중" />
          <div >{text}</div>
        </div>
      </div>
    );
}
export default Loading;