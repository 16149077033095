import React from "react";
import AcqRepuRFriend from 'components/Career/AcqRepuRFriend'

const AcquaintanceReputationFriend = () => {
	//평판 변수 전달
	// const location = useLocation();
	// useEffect(() => {
	// 	console.log(location);
	// 	if (location !== null && location.state !== null) {
	// 		const cp_mode = location.state.cp_mode;
	// 		if (cp_mode === 0) {
	// 			setCurrentTab(2);
	// 			resetNavSubMenu(2, 1);
	// 		} else {
	// 			setCurrentTab(3);
	// 			resetNavSubMenu(2, 2);
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [location, setCurrentTab]);

	return (
		<div className="container">
			<AcqRepuRFriend />
		</div>
	)
}

export default AcquaintanceReputationFriend
