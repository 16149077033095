import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';

import CertificationCareerView from "../Resources/CertificationCareer";
import AddCareerView from "../Resources/AddCareer";

/*메인(기업)>후보자관리*/
function Shortlist() {
  const mcm_idx = localStorage.getItem('mcm_idx');
  const mem_idx = localStorage.getItem('mem_idx');
  const com_idx = localStorage.getItem('com_idx');

  //서버의 저장폴더
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;

  const [varCandidateLists, setCandidateLists] = useState([]);

  //새로운 지원자 
  const getCandidateLists = async () => {
    try {
      console.log("mcm_idx", mcm_idx);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      params.append("com_idx", com_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_company_candidate`, params).then((res) => {
        console.log(res.data);
        if (res.data.retvalue === 1) {
          const datas = res.data.results;
          console.log(datas);
          setCandidateLists(datas);
        } else {
          setCandidateLists([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCandidateLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article>
      <hgroup className="sub_content_title_wrap spacebetween">
        <h1 className="sub_content_title indent">후보자 관리</h1>
        <Link to={"/Company/ComResource/Candidate"} className="btn line gray">전체보기<span className="nanumGothic">&gt;</span></Link>
      </hgroup>
      <div className="list_box">
        <ul className="talent_list">
          {varCandidateLists.map((candidate, index) => (
            <li key={"candidate_" + index} className="talent_card">
              <Link className="link_wrap" to={`/Company/ComResource/SearchView/${candidate.re_idx}`} state={{ re_idx: candidate.re_idx/* ,mem_idx:candidate.mem_idx */ }}>
                <div className="pic_name_wrap">
                  {candidate.mem_photo === 0 ? <figure></figure>
                    : <figure style={{ backgroundImage: `url(${imageFoler + candidate.mem_ufile})` }}></figure>
                  }
                  <b className="ellipsis">{candidate.mem_name}</b>
                </div>
                <hgroup className="style2">
                  <h1 className="txt_blue ellipsis">
                    {/* {resetCareerForSearching(candidate.re_career_year)} */}
                    {
                      !candidate?.certification_experience_year && !candidate?.certification_experience_year ?
                        <AddCareerView highLookup={candidate} />
                        :
                        <CertificationCareerView highLookup={candidate} />
                    }

                  </h1>
                  {candidate.rec_company ?
                    <h2 className="txt_black ellipsis">{candidate.rec_company}</h2>
                    : null
                  }
                  {candidate.rec_department ?
                    <h3 className="ellipsis">{candidate.rec_department}&nbsp;{candidate.vps_name}</h3>
                    : null
                  }
                  {candidate.reh_name ?
                    <h4 className="ellipsis">{candidate.reh_name}&nbsp;{candidate.reh_major}</h4>
                    : null
                  }

                </hgroup>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
}

export default Shortlist;
