/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//css
//components
//이미지


  /*이용권 구매 탭*/

function VoucherTab({ varMode, setMode, onSetMode }) {

 
  return (
    <ul className="tab01">
        <li className={varMode === 0 ? "active" : ""}><a onClick={(e)=>onSetMode(e,0)}>이용권 결제내역</a></li>
        <li className={varMode === 0 ? "" : "active"}><a onClick={(e)=>onSetMode(e,1)} className={varMode === 1 ? "active" : ""}>이용권 사용내역</a></li>   
    </ul>
  );
}

export default VoucherTab;
