import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import axios from 'axios';
import CONFIG from '../../../configs/configs';

function SubAccountRegister() {
  const { sub_idx } = useParams();
  const navigate = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');

  const [varInsertData, setInsertData] = useState({
    Admin_id: '',
    Admin_name: '',
    Admin_password: '',
    confirmPassword: '',
    Admin_email: '',
    preferences: 0,
    member_management: 0,
    notice_management: 0,
    board_management: 0,
    pass_management: 0,
    search_management: 0,
    statistics: 0,
    p_idx: 0
  });

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigate("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sub_idx !== undefined) {
      const idx = sub_idx;
      const param = new URLSearchParams();
      param.append("sub_idx", idx);

      axios.post(`${CONFIG.SERVER_HOST}/subaccountinfo`, param).then((res) => {
        if (res.data.retvalue === 1) {
          const data = res.data.results[0];
          setInsertData({
            Admin_id: data.admin_id,
            Admin_name: data.admin_name,
            Admin_password: '',
            confirmPassword: '',
            Admin_email: data.email,
            preferences: data.preferences,
            member_management: data.member_management,
            notice_management: data.notice_management,
            board_management: data.board_management,
            pass_management: data.pass_management,
            search_management: data.search_management,
            statistics: data.statistics, 
            p_idx: data.p_idx
          });
        }
      });
    }

  }, [sub_idx]);

  const onSubmitHandler = () => {

    if (varInsertData.Admin_id === '') {
      alert('아이디를 입력해 주세요');
      return false;
    } else if (varInsertData.Admin_name === '') {
      alert('이름을 입력해 주세요');
      return false;
    } else if (varInsertData.Admin_password === '') {
      alert('비밀번호를 입력해 주세요');
      return false;
    } else if (varInsertData.confirmPassword === '') {
      alert('비밀번호 확인란을 입력해주세요 입력해 주세요');
      return false;
    } if (varInsertData.Admin_password !== varInsertData.confirmPassword) {
      alert('비밀번호와 비밀번호 확인 란의 데이터가 일치 하지 않습니다.');
      return false;
    }


    const param = new URLSearchParams();
    param.append("admin_id", varInsertData.Admin_id);
    param.append("admin_name", varInsertData.Admin_name);
    param.append("password", varInsertData.Admin_password);
    param.append("email", varInsertData.Admin_email);
    param.append("type", 1);
    param.append("preferences", varInsertData.preferences);
    param.append("member_management", varInsertData.member_management);
    param.append("notice_management", varInsertData.notice_management);
    param.append("board_management", varInsertData.board_management);
    param.append("pass_management", varInsertData.pass_management);
    param.append("search_management", varInsertData.search_management);
    param.append("statistics", varInsertData.statistics);

    axios.post(`${CONFIG.SERVER_HOST}/subaccountinsert`, param).then((res) => {
      console.log(res);
      if (res.data.retvalue === 1) {
        alert("회원정보가 저장되었습니다");
      }
    });

  }


  const onUpdateHandler = () => {

    const param = new URLSearchParams();
    param.append("id", sub_idx);
    param.append("admin_id", varInsertData.Admin_id);
    param.append("admin_name", varInsertData.Admin_name);
    param.append("password", varInsertData.Admin_password);
    param.append("email", varInsertData.Admin_email);
    param.append("type", 1);
    param.append("preferences", varInsertData.preferences);
    param.append("member_management", varInsertData.member_management);
    param.append("notice_management", varInsertData.notice_management);
    param.append("board_management", varInsertData.board_management);
    param.append("pass_management", varInsertData.pass_management);
    param.append("search_management", varInsertData.search_management);
    param.append("statistics", varInsertData.statistics);
    param.append("p_idx", varInsertData.p_idx);

    axios.post(`${CONFIG.SERVER_HOST}/subaccountupdate`, param).then((res) => {
      console.log(res);
      if (res.data.retvalue === 1) {
        alert("회원정보가 저장되었습니다");
        navigate("/admin/subadmin");
      }
    });

  }


  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;

    setInsertData({
      ...varInsertData,
      [name]: value
    });
  };

  const changeHandlerPrivileges = (e) => {
    const { name, checked } = e.currentTarget;

    if (checked === true) {
      setInsertData({
        ...varInsertData,
        [name]: 1
      });
    } else {
      setInsertData({
        ...varInsertData,
        [name]: 0
      });
    }

  };


  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="subaccount" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          부관리자 계정 관리
          <div className="path">
            <img src={iconhome} alt="" />HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />운영자 관리<img src={arrImage} alt="" />부관리자 계정 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="write">
              <tbody>
                <tr>
                  <th>아이디</th>
                  <td><input type="text" name="Admin_id" value={varInsertData.Admin_id} onChange={onChangeInputHandler} maxLength="20" /></td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td><input type="text" name="Admin_name" value={varInsertData.Admin_name} onChange={onChangeInputHandler} maxLength="20" /></td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td> <input type="password" name="Admin_password" value={varInsertData.Admin_password} onChange={onChangeInputHandler} maxLength="20" /></td>
                </tr>
                <tr>
                  <th>비밀번호 확인</th>
                  <td><input type="password" name="confirmPassword" value={varInsertData.confirmPassword} onChange={onChangeInputHandler} maxLength="20" /></td>
                </tr>
                <tr>
                  <th>이메일 주소</th>
                  <td><input type="text" name="Admin_email" value={varInsertData.Admin_email} onChange={onChangeInputHandler} maxLength="20" /></td>
                </tr>
                <tr>
                  <th>권한설정</th>
                  <td><div className="write-radio">
                    <input type="checkbox" id="chk1" className="amin_input" name="preferences" checked={varInsertData.preferences === 1 ? true : false} value={varInsertData.preferences} onChange={changeHandlerPrivileges} />
                    <label for="chk1" >환경설정</label>
                    <input type="checkbox" id="chk2" className="amin_input" name="member_management" checked={varInsertData.member_management === 1 ? true : false} value={varInsertData.member_management === 1 ? true : false} onChange={changeHandlerPrivileges} />
                    <label for="chk2" >회원관리</label>
                    <input type="checkbox" id="chk3" className="amin_input" name="notice_management" checked={varInsertData.notice_management === 1 ? true : false} value={varInsertData.notice_management === 1 ? true : false} onChange={changeHandlerPrivileges} />
                    <label for="chk3" >공고관리</label>
                    <input type="checkbox" id="chk4" className="amin_input" name="board_management" checked={varInsertData.board_management === 1 ? true : false} value={varInsertData.board_management === 1 ? true : false} onChange={changeHandlerPrivileges} />
                    <label for="chk4" >게시판 관리</label>
                    <input type="checkbox" id="chk5" className="amin_input" name="pass_management" checked={varInsertData.pass_management === 1 ? true : false} value={varInsertData.pass_management === 1 ? true : false} onChange={changeHandlerPrivileges} />
                    <label for="chk5" >이용권 구매관리</label>
                    <input type="checkbox" id="chk6" className="amin_input" name="search_management" checked={varInsertData.search_management === 1 ? true : false} value={varInsertData.search_management === 1 ? true : false} onChange={changeHandlerPrivileges} />
                    <label for="chk6" >검색관리</label>
                    <input type="checkbox" id="chk7" className="amin_input" name="statistics" checked={varInsertData.statistics === 1 ? true : false} value={varInsertData.statistics === 1 ? true : false} onChange={changeHandlerPrivileges} />
                    <label for="chk7" >통계</label>
                    </div></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn-center">
            {sub_idx !== undefined ?
              <button className="btn-danger" onClick={() => onUpdateHandler()}>수정</button>
              :
              <button className="btn-danger" onClick={() => onSubmitHandler()}>저장</button>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubAccountRegister;