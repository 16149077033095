/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";

import "../../css/Reputation.css";
//import resumeSearchButton from "../../img/sub/icon_tip2.png";
//import img_repu_main from "img/sub/repu_main.png";

//import PopAcqRepuRequest from "components/Career/PopAcqRepuRequest"; //POP '친구가 보는 나' 요청하기
import Chart from "react-apexcharts";
import axios from "axios";
import CONFIG from "../../configs/configs";
//import { useNavigate } from "react-router-dom";
//import { reputationMatchResultList } from '../../global/global_career';
//import PopRepuDel from './PopRepuDel';
//import ArrList from "./ArrList";
//import ArrLabel from "./ArrLabel";

const AcqRepuRPdf = ({userIdx}) => {
  //const memlogin = localStorage.getItem('memlogin');
  //const navigator = useNavigate();
  //const [enablePop, setEnablePop] = useState(false); //팝업사용 여부
  const [chartMy, setChartMy] = useState([{}]); // '내가 보는 나', 그래프 데이터
  //const [chartFriend, setChartFriend] = useState([{}]); //'타인이 보는 나', 그래프 데이터
  const [options, setOptions] = useState({
    chart: {
      height: 200,
      type: "radar",
      dropShadow: { enabled: true, blur: 1, left: 1, top: 1, },
    },
    title: { text: "",},
    stroke: { width: 1,},
    fill: { opacity: 0.2,},
    markers: { size: 5,},
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: ['#ffd966', '#a9d08e', '#f4b084', '#00b0f0', '#c65911'],
          fontSize: "15px",
          fontWeight: "700"
        }
      }
    },
    yaxis: { show: false, min: 0, max: 15, tickAmount: 3, },
    plotOptions: { radar: { polygons: { fill: {}, } } },
    tooltip: { enabled: false, }
  }); // 그래프 옵션
  const [modifyDate, setModifyDate] = useState(""); // '내가 보는 나', 수정일자
  //const [active, setActive] = useState("who_see_area_options"); // '타인이 보는 나', 클래스 명
  //const [friends, setFriends] = useState([]); // '타인이 보는 나', 타인 목록
  //const [selectedFriend, setSelectedFriend] = useState({ answerNo: "", name: "" }); // '타인이 보는 나', 선택된 타인
  //const [matchPercentage, setMatchPercentage] = useState(0); // 타인과 나의 성향 일치도
  //const [statusBarColor, setStatusBarColor] = useState("step1"); // 성향 일치도 progress bar color
  //const [matchResult, setMatchResult] = useState(""); // 성향 일치도 결과 내용
  //const [download, setDownload] = useState(false) // 지인평판 다운로드 동의여부
  //const [delPopup, setDelPopup] = useState(false) // 친구가 보는나 삭제 팝업 여부
  //const [NweselectedFriend, setNewSelectedFriend] = useState([]) // 친구가 보는나 삭제 팝업 여부

  const [repuAll, setRepuAll] = useState([]); //성향 전체
  const [myName, setMyName] = useState(null); //평판 대상자 정보

  const memIdx = userIdx; //localStorage.getItem('mem_idx'); // 357

  //일치도 계산
  /*
  const calcMatchProg = (async(friend, type) => {
    console.log('calcMatchProg::friend', friend);
    let extraversionDiff = 0; // 외향성
    let hardworkingDiff = 0; // 성실성
    let opennessDiff = 0; // 개방성
    let friendlyDiff = 0; // 친화성
    let sensitivityDiff = 0; // 신경성

    extraversionDiff = Math.abs(chartMy[0]?.data[0] - friend[0]?.data[0])
    hardworkingDiff = Math.abs(chartMy[0]?.data[1] - friend[0]?.data[1])
    opennessDiff = Math.abs(chartMy[0]?.data[2] - friend[0]?.data[2])
    friendlyDiff = Math.abs(chartMy[0]?.data[3] - friend[0]?.data[3])
    sensitivityDiff = Math.abs(chartMy[0]?.data[4] - friend[0]?.data[4])

    const extraversion = (10 - extraversionDiff) * 2;
    const hardworking = (10 - hardworkingDiff) * 2;
    const openness = Math.round((15 - opennessDiff) * (4 / 3));
    const friendly = Math.round((15 - friendlyDiff) * (4 / 3));
    const sensitivity = (10 - sensitivityDiff) * 2;

    const total = (extraversion + hardworking + openness + friendly + sensitivity);
    let className = "";
    if (total <= 25) {
      className = "step1"
    } else if (total <= 50) {
      className = "step2"
    } else if (total <= 75) {
      className = "step3"
    } else {
      className = "step4"
    }

    if(type === "class"){
      return className;
    } else {
      return total;
    }
    
  },[]);
  */

  //차트 데이터 변환 2024-06-02 KSR
  
  const convertGraphData = ( (data) => {
    //let rtnData = []
    const scores = data?.map((item) => {
      if (item?.score) {
        //console.log(item.score);
        if (item?.q_type === 3 || item?.q_type === 4) {
          //150 * 10 / 100
          return (15 * (Number(`${item?.score}`) / 15 * 100) / 100).toFixed();
        } else {
          return (15 * (Number(`${item?.score}`) / 10 * 100) / 100).toFixed();
        }
      }
    });
    return [
      {
        name: "점수",
        data: scores,
      },
    ]
    
  });

  useEffect(() => {
    console.log('chartMy', chartMy);
  },[chartMy])

  useEffect(() => {
    //지인평판 전체 - KSR
    const getReputationAll = () => {
      axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/all/${memIdx}`, {}).then((res) => {
        if (res.data?.friends?.length > 0) {
          setRepuAll(res.data?.friends);
          setMyName(res.data?.mem_info?.mem_name);
        }
      }).catch((e) => {
        console.debug(e);
      });
    }

    //내 그래프______________________________
    const setMyGraph = () => {
      const params = new URLSearchParams([
        ['mem_idx', memIdx],
        ['myself', 'Y'],
      ]);

      axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/graph`, { params }).then((res) => {
        if (res.data?.retvalue) {
          const result = res?.data?.scores;
          const myGraphData2 = result?.map((item) => {
            if (item?.score) {
              //console.log(item.score);
              if (item?.q_type === 3 || item?.q_type === 4) {
                //150 * 10 / 100
                return (15 * (Number(`${item?.score}`) / 15 * 100) / 100).toFixed();
              } else {
                return (15 * (Number(`${item?.score}`) / 10 * 100) / 100).toFixed();
              }

            }
          }
          );
          setChartMy(prev => prev = [
            {
              name: "점수",
              data: myGraphData2,
            },
          ]);
          setModifyDate((prev) => prev = result[0]?.date);
          setOptions((prev) => {
            return { ...prev, xaxis: { categories: result?.map((item) => item?.label) } };
          });
        }
      }).catch((e) => {
        console.debug(e);
      });
    };
    getReputationAll(); //동료평판 전체 - KSR
    setMyGraph();
  }, []);

  return (
    <>
      <section className="com_reputation com_repu_w_r p_colle_r center_wrap p_repu_r">
      <article className="repu_writer_info_wrap">
          <div className='notice_area'>
            <h1>
              <span className="txt_blue">{myName}</span>
              님은 {`${repuAll?.length}`}명의 지인에게 평판을 받았습니다.
            </h1>
          </div>
        </article>
      </section>
      <section className="com_reputation com_repu_main p_acq_repu_r center_wrap">

        {//chartMy[0]?.data?.length > 0 ? 
         
          repuAll?.map((repuData, key) => {
            //const FriendData = repuData;
            const chartData = convertGraphData(repuData?.anwser_data);
            console.log('chartData', chartData);
            //const calcuPer = calcMatchProg(chartData, "per");
            //const perColorBar = calcMatchProg(chartData, "class");
            const repuWriter = repuData?.anonymous ? repuData?.anonymous : repuData?.anwser_writer;
            return(
              <React.Fragment key={`${key}`}>
                <article className="com_repu_gray_container">
                  {/* 지인꺼 _________________*/}
                  <section className="left_section">
                    <div className="who_see_container">
                      <div className="who_see_area">
                        <span className="txt_blue">{repuWriter}</span>님이 보는 나<i className="icon_arrow" onClick=""></i>
                      </div>
                    </div>
                    <div className="acq_repu_graph">
                      <Chart options={options} series={chartData} type="radar" height={350} />
                    </div>
                  </section>
                  {/* 내꺼 _________________*/}
                  <section className="right_section">
                    <div className="who_see_area">
                      <span className="txt_blue">내가&nbsp;</span>보는 나<span className="update_date">수정일자: {modifyDate}</span>
                    </div>
                    <div className="acq_repu_graph">
                      <Chart options={options} series={chartMy} type="radar" height={350} />
                    </div>
                  </section>
                </article>

              </React.Fragment>
            );
          })

        }

        {/* 설명문 */}
        <article className="repu_review_explain">
          <h1>상황별 설명</h1>
          <table>
            <colgroup>
              <col width="12%" />
              <col width="38%" />
              <col width="25%" />
              <col width="25%" />
            </colgroup>
            <tr>
              <th>요인</th>
              <th>측정내용</th>
              <th>낮을 수록</th>
              <th>높을 수록</th>
            </tr>
            <tr>
              <td>외향성</td>
              <td>대인관계에서의 상호작용 정도와 강도를 측정. 즉, 활동수준, 자극에 대한 욕구, 즐거움 능력 등을 측정</td>
              <td>사람들과 어울리지 않고 조용함</td>
              <td>사람들과 잘 어울리며 열정적임</td>
            </tr>
            <tr>
              <td>친화성</td>
              <td>사고, 감정, 행동측면에서 동정심 부터 적대감까지의 연속선상에 개인의 대인관계 지향성을 측정</td>
              <td>비협조적이며 타인에게 적대적임</td>
              <td>공감능력이 뛰어나며, 다른 사람들을 잘 믿는편임</td>
            </tr>
            <tr>
              <td>성실성</td>
              <td>목표에 대한 자발적 행동에서의 조직, 끈기, 동기를 부여하는 정도를 측정</td>
              <td>충동적인 경향이 강하며 부주의함</td>
              <td>일처리에 있어 체계적이며 자발적인 경향이 있음</td>
            </tr>
            <tr>
              <td>신경성</td>
              <td>적응과 정서적 불안정을 측정, 심리적 스트레스 비현실적인 이상을 측정</td>
              <td>안정적인 정서를 지니고 있으며 감정적으로 차분함</td>
              <td>스트레스를 잘 받고 걱정을 많이 함</td>
            </tr>
            <tr>
              <td>개방성</td>
              <td>자신의 경험을 주도적으로 추구하고 평라하는지 여부를 측정</td>
              <td>실용적이며 보수적이고 전통적임</td>
              <td>창조적이며 독창적임</td>
            </tr>
          </table>
        </article>
      </section>
    </>
  );
};

export default AcqRepuRPdf;
