import { resetNavMenu, resetNavSubMenu } from 'components/Common/Common';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";

const CareerTop = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0);
  const tabIndex = 2; // 상단 탭 index 전역 변수로 관리하는게 어떤지..?

  useEffect(() => {
    const pathName = location.pathname;
    let index = 0; // 경력증명서

    if (pathName.includes('acquaintance-reputation')) index = 1; // 지인평판          
    if (pathName.includes('career-reputation')) index = 2; // 동료평판
    if (pathName.includes('CareerIncome')) index = 3; // 소득

    resetNavMenu(tabIndex);
    resetNavSubMenu(tabIndex, index);
    setIndex(index);
  }, [location.pathname]);

  return (
    <ul className="tab01 blue col4 center_wrap">
      <li className={index === 0 ? 'active' : ''}><Link to={`/MyCareer/web/0`}>경력증명서</Link></li>
      <li className={index === 1 ? 'active' : ''}><Link to={`/MyCareer/acquaintance-reputation`}>지인 평판</Link></li>
      <li className={index === 2 ? 'active' : ''}><Link to={`/MyCareer/career-reputation`}>동료 평판</Link></li>
      <li className={index === 3 ? 'active' : ''}><Link to={`/MyCareer/CareerIncome`}>소득</Link></li>
    </ul>
  );
}
export default CareerTop