/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { common_select_function } from '../Common/Common';
import CONFIG from "../../configs/configs";



/*프로필*/
function PopTown({ varPopTown, setPopTown, varInputs, setInputs }) {
  const [varTown, setTown] = useState('');
  const [varTownList, setTownList] = useState([]);
  const [varTownCode, setTownCode] = useState();

  useEffect(async () => {
    console.log('vkqdjq!!!!!!!!!!!', varTownList.length);
    if (varTownList.length === 0) {

      const column = ` vr_idx, vr_code, vr_name `;
      const table = ` var_region `;
      const where = ` vr_code != 0`;
      const option = ` ORDER BY vr_idx asc`;
      await common_select_function(column, table, where, option).then(function (res) {

        // const List = res.reverse();
        console.log('res!!!!', res);
        setTownList(res);


      }).catch(function (err) {
        console.error(err); // Error 출력  
      });
    }

  }, [varTownList]);

  //지역선택시
  const onSelectTownHandler = (vr_code) => {
    if (vr_code === varTownCode) {
      setTownCode();
      setTown('');
      return false;
    }
    if (vr_code === CONFIG.vr_code) {
      setTownCode(vr_code);
    } else {
      const Town = varTownList.find(x => x.vr_code === vr_code);
      setTownCode(Town.vr_code);
      setTown(Town.vr_name);
    }

  }

  //지역선택후 추가버튼 
  const onSelectedPopTownHandler = (e) => {
    e.preventDefault();
    var newone = varInputs.place_hope;
    if (newone.indexOf(varTown) === -1) {
      if (newone === "") {
        newone = varTown;
      } else {
        newone += ',' + varTown;
      }
      setInputs({ ...varInputs, place_hope: newone });
      console.log(varInputs);
      let townsList = document.querySelectorAll('.button');
      for (var i = 0; i < townsList.length; i++) {
        townsList[i].classList.remove('active');
      }
      setTown('');
      setPopTown('popup');
    } else {
      if (varTown !== '') {
        alert('중복선택은 불가합니다.');
      } else {
        alert('근무지를 선택해 주세요.');
      }
    }
  }


  //팝업창 닫기
  const onClosePopTownHandler = (e) => {
    e.preventDefault();
    let townsList = document.querySelectorAll('.button');
    for (var i = 0; i < townsList.length; i++) {
      townsList[i].classList.remove('active');
    }
    setTown('');
    setPopTown('popup');
  }

  //input 입력창 onchange
  const onInputOnchange = (e) => {
    const { value } = e.target;

    setTown(value);
  }

  return (
    <section className={varPopTown}>
      <article className="popup_wrap popup01">
        <div className="title_wrap">
          <h1>희망근무지 추가</h1>
        </div>
        <div className="content_wrap">
          <div className="select_box inline">
            <div className="wrap scroll">
              {varTownList && varTownList.map((item, i) => {
                if (item.vr_code !== 99) {
                  return (
                    <button key={i} onClick={() => { onSelectTownHandler(item.vr_code) }} className={`button ${varTownCode === item.vr_code ? `active` : ``}`}>{item.vr_name}</button>
                  )
                } else {
                    return (
                      <button key={i} onClick={() => { onSelectTownHandler(item.vr_code) }} className={`button ${varTownCode === item.vr_code ? `active` : ``}`}>{item.vr_name}</button>
                    )
                }
              })}
            </div>
          </div>
          <input placeholder="해외 지역을 입력해주세요." value={varTownCode === 99 ? varTown : ''} style={{ display: varTownCode === 99 ? 'block' : 'none' }} className="frm-input-box mt1rem" onChange={onInputOnchange} />
        </div>
        <div className="button_wrap">
          <div className="big_button_wrap one">
            <button onClick={(e) => onSelectedPopTownHandler(e)} className="btn">추가</button>
          </div>
          <div className="close_button_wrap">
            <button onClick={(e) => onClosePopTownHandler(e)}>닫기 X</button>
          </div>
        </div>
      </article>
    </section>
  );
}

export default PopTown;
