/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs"; 
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import { ChangeMoneyUnit,changeMobileFormat } from '../../../components/Common/Common'

function EmployeeDetail() {
  const navigator = useNavigate();
  const location = useLocation();
  const { state } = location
  const { mem_idx } = useParams();
  const [taxBill, setTaxBill] = useState("");
  const [applyInfo, setApplyInfo] = useState(""); //지원자 정보
  const [applyIDate, setApplyIDate] = useState(""); //지원자 입사날짜
  const [recommendInfo, setRecommendInfo] = useState(""); //추천인 정보
  const [applyExchangeInfo, setApplyExchangeInfo] = useState(""); //추천인 환전정보
  const adminlogin = localStorage.getItem('adminlogin');

  let curDate = new Date();  
  let year = curDate.getFullYear();
  let month = ('0' + (curDate.getMonth() + 1)).slice(-2);
  let day = ('0' + curDate.getDate()).slice(-2);
  const today = year + '-' + month  + '-' + day;

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //셀렉트박스
  const exchangeHandler = (e) => {
    const {name, value} = e.target;
    // console.log("name ",name,"value ",value)
    if(name === "taxBill"){
      setTaxBill(value);
    }else if(name === "apply_sdate"){
      setApplyIDate(value)
    }


  }

  //추천인 정보
  const getInfo = async() => {
      const params = new URLSearchParams();
      params.append("mem_idx", mem_idx);
      params.append("em_idx", state?.em_idx);
      params.append("recommend_idx", state?.recommend_idx);
  
      await axios.post(`${CONFIG.SERVER_HOST}/employee_exchange`, params).then((res) => {
        if(res.data.retvalue === 1) {
          setApplyInfo(res.data?.applyInfo[0])
          setApplyExchangeInfo(res.data?.applyExchange[0]);
          setApplyIDate(res.data?.applyInfo[0]?.ea_idate)
          setTaxBill(res.data?.applyInfo[0]?.tax_bill_YN); //입사자 계산서 발행
          setRecommendInfo(res.data?.recommendInfo[0]) //추천인 정보
          // setApplyExchangeDate(res.data?.applyExchange[0].mh_pdate);
        }
      })
  }

  useEffect(async () => {
    await getInfo();
  }, []);

  //수정버튼
  const onUpdateHandler = async() => {
    const param = new URLSearchParams();
    param.append("tax_bill_YN", taxBill); //계산서 발행
    param.append("em_idx", state?.em_idx); //공고번호
    param.append("apply_mem_idx", state?.mem_idx); //지원자 idx
    param.append("apply_mh_pdate", applyInfo?.ea_pdate); //지원자 환전 예정일
    param.append("recommend_mem_idx", recommendInfo?.mem_idx ? recommendInfo?.mem_idx : ""); //추천인 idx
    param.append("recommend_mh_pdate", recommendInfo?.mh_pdate); //추천인 환전 예정일
    param.append("ea_idate", applyIDate);

    await axios.post(`${CONFIG.SERVER_HOST}/apply_update`, param).then((res) => {
        //const data = res.data;
        console.log(res.data);
        if (res.data.retvalue === 1) {
            alert(res.data.message);
        } else {
            alert(res.data.message);
        }
    });
    getInfo();
  }

  return (
      <>
          <Titlebar />
          <Tabbar tabType="users"/>
          <Menu menuType="users" menu="employee_detail"/>
          <div id="contents" className="ad_contents">
            <div className="tit">입사자 관리 상세
              <div className="path">
                  <img src={iconhome} alt=""/>
                  HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>헤딩머니<img src={arrImage} alt=""/>입사자 관리 상세
              </div>
            </div>
            <div className="content">
                <p style={{ marginTop: "20px" }} className="table_title">- 채용 정보</p>
                <div className="main-box">
                  <table className="write"> 
                    <colgroup>
                      <col style={{width:"15%"}}/>
                      <col style={{width:""}}/>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>채용제목</th>
                        <td style={{ textAlign: "left" }}>{state?.em_title}</td> 
                      </tr>
                      <tr>
                        <th>회사명</th>
                        <td style={{ textAlign: "left" }}>
                          {state?.com_name}
                        </td>
                      </tr>  
                      <tr>
                        <th>총 보상금</th>
                        <td style={{ textAlign: "left" }}>{state?.mem_mobile}</td>
                      </tr> 
                      <tr>
                        <th >계산서 발행</th>
                        <td style={{ textAlign: "left" }}>
                        <select style={{width:"120px"}} name="taxBill" value={taxBill} onChange={exchangeHandler}> 
                          <option value="N">미발행</option>
                          <option value="Y">발행</option>
                        </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> 
              </div>
              <div className="content">
                <p style={{ marginTop: "20px" }} className="table_title">- 입사자 정보</p>
                <div className="main-box">
                  <table className="write"> 
                    <colgroup>
                      <col style={{width:"15%"}}/>
                      <col style={{width:""}}/>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>이름</th>
                        <td style={{ textAlign: "left" }}>{state?.mem_name}</td> 
                      </tr>
                      <tr>
                        <th>휴대폰번호</th>
                        <td style={{ textAlign: "left" }}>
                          {changeMobileFormat(state?.mem_mobile)}
                        </td>
                      </tr>  
                      <tr>
                        <th>입사일자</th>
                        <td style={{ textAlign: "left" }}>
                          <input type="date" name="apply_sdate" value={applyIDate || ""} onChange={exchangeHandler}/>
                          </td>
                      </tr>
                      {state?.mh_pdate === null ? "" :
                      <>
                        <tr>
                          <th>합격자 보상금</th>
                          <td style={{ textAlign: "left" }}>{state?.emtype === 4 ? (state?.recommend_name === null ? 0 : ChangeMoneyUnit(state.emh_reward_applicant/10000)) : (state?.recommend_name === null ? 0 : ChangeMoneyUnit(state.em_reward_applicant/10000))}</td>
                        </tr> 
                        <tr>
                          <th>환전상태</th>
                            <td>{applyExchangeInfo?.mh_pdate > today ? "환전대기" : (applyExchangeInfo?.mh_status === -1 ? "환전취소" : applyExchangeInfo?.mh_status === 1 ? "환전신청" : applyExchangeInfo?.mh_status === 2 ? "승인완료" : applyExchangeInfo?.mh_status === 3 ? "환전완료" : "환전대기")}</td>
                        </tr>
                        <tr>
                          <th>환전 예정일</th>
                          <td style={{ textAlign: "left" }}>
                          {applyInfo?.ea_pdate}
                          {/* {applyExchangeDate} */}
                          </td>
                        </tr>
                      </>}
                    </tbody>
                  </table>
                </div> 
              </div>  
              {recommendInfo ?
              <div className="content">
                <p style={{ marginTop: "20px" }} className="table_title">- 추천인 정보</p>
                <div className="main-box">
                  <table className="write"> 
                    <colgroup>
                      <col style={{width:"15%"}}/>
                      <col style={{width:""}}/>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>이름</th>
                        <td style={{ textAlign: "left" }}>{recommendInfo?.mem_name === null ? "-" : recommendInfo?.mem_name}</td> 
                      </tr>
                      <tr>
                        <th>휴대폰번호</th>
                        <td style={{ textAlign: "left" }}>
                          {recommendInfo?.mem_mobile === null ? "-" : changeMobileFormat(recommendInfo?.mem_mobile)}
                        </td>
                      </tr>  
                      <tr>
                        <th>추천인 보상금</th>
                        <td style={{ textAlign: "left" }}>{state?.emtype === 4 ? (state?.recommend_name === null ? 0 : ChangeMoneyUnit((state.emh_reward_recommend - state.emh_reward_applicant)/10000)) : (state?.recommend_name === null ? 0 : ChangeMoneyUnit((state.em_reward_recommend - state.em_reward_applicant)/10000))}</td>
                      </tr> 
                      <tr>
                        <th>환전상태</th>
                          <td>{recommendInfo?.mh_pdate > today ? "환전대기" : (recommendInfo?.mh_status === -1 ? "환전취소" : recommendInfo?.mh_status === 1 ? "환전신청" : recommendInfo?.mh_status === 2 ? "승인완료" : recommendInfo?.mh_status === 3 ? "환전완료" : "환전대기")}</td>
                      </tr>
                      <tr>
                        <th>환전 예정일</th>
                        <td style={{ textAlign: "left" }}>
                          {recommendInfo?.mh_pdate}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> 
              </div> : ""}
            <div className="btn-center">
              <Link className="btn line" to={"/admin/employee_list"} style={{ color: "#fff" }}>목록</Link>
              <button className="btn" onClick={onUpdateHandler}>수정</button>
            </div>                    
          </div>
      </>
  )
}

export default EmployeeDetail;