/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import moment from 'moment'; 

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import Paging from '../../../components/Layout/Paging'
import { ChangeMoneyUnit, /*changeEmMode*/ } from '../../../components/Common/Common'
import ExcelButton from 'admin/pages/board/ExcelDownloadButton';

function EmployeeList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜
    const [varSearch, setSearch] = useState("none");
    const [tax, setTax] = useState(-1);
    const [varSearchInput, setSearchInput] = useState("");

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageNum,setPageNum] = useState(1);
    const [varFilterlist, setFilterlist] = useState([]); 
    const [varPageSize, setPageSize] = useState(10);
    const [selectChange, setSelectChange] = useState(false);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //입사자 리스트 가져오기
    const getEmployeeList = async (iMode,tax,cPage) => {
      let sendData;
      if(iMode !== 1){
        sendData = {
          'currentPage': varPageNum,
          'pageSize': varPageSize,
          'sdate': startDate ? moment(startDate).format(CONFIG.formatStr) : "",
          'edate':startDate ? moment(endDate).format(CONFIG.formatStr) : "",
          'search_option': varSearch,
          'search_string': varSearchInput,
          'search_tax' : tax
        }
      }else{
        sendData = {
          'currentPage': varPageNum,
          'pageSize': varPageSize,
          'sdate': "",
          'edate': "",
          'search_option': "none",
          'search_string': "",
          'search_tax' : -1,
        }
      }
      try {
        await axios.post(`${CONFIG.SERVER_HOST}/employeeList`,sendData, CONFIG.header).then((res) => {
            if(res.data.retvalue === 1) {
                setTotalCount(res.data.totalcount); 
                setFilterlist(res.data.results);
                if(selectChange){
                  setSelectChange(false);
                  setPageNum(1);
                }
            }
        });
      } catch (error) {
        console.log(error);
      }
    }

    const handleType = (e) =>{
      const {name, value} = e.target;
      if(name === "tax"){
        setTax(value)
      }else{
        setSearch(value);
      }
    };
    const handleInput = (e) =>{
        setSearchInput(e.currentTarget.value);
    };

    useEffect(()=>{
      getEmployeeList(0,tax,varPageNum);
    },[tax])

    //검색-초기화
    const handleSearchClear =()=>{
        setStartDate("");
        setEndDate("");
        setSearch("none");
        setSearchInput("");
        setPageNum(1);
        getEmployeeList(1,tax,varPageNum);
        setTax(-1)
    }

    useEffect(() => {
      getEmployeeList(0,tax,varPageNum);
    },[varPageNum]);

    useEffect(() => {
      getEmployeeList(0,tax,varPageNum);
    },[varPageSize]);
    
    const handlestartdate =(e)=>{
        setStartDate(e.currentTarget.value);
    }
    const handleenddate =(e)=>{
        setEndDate(e.currentTarget.value);
    }

    //검색
    const handleSearchPaymentList = () =>{
      getEmployeeList(0,tax,varPageNum);
  }

  const settingPageSize = (e) =>{
    const {value} = e.target;
    setPageSize(Number(value));
    setSelectChange(true);
  }
    return(
        <>
        <Titlebar/>
        <Tabbar tabType="users"/>
        <Menu menuType="users" menu="employee_list"/>
        <div id="contents" className="ad_contents">
        <div className="tit">입사자 관리
            <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>헤딩머니<img src={arrImage} alt=""/>입사자 관리
            </div>
        </div>
        <div className="content">
            <div className="main-box">		
                <div className="search-box">
                    {/* <p>검색건수 : {varTotalCount}
                      <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                        <option value="10">10개</option>
                        <option value="30">30개</option>
                        <option value="50">50개</option>
                        <option value="100">100개</option>
                      </select>
                    </p> */}
                    <div className="f-right">		
                      <div className="day-box">
                          <p>입사일자</p>
                          <input type="date"  name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                          ~ 
                          <input type="date"  name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
                      </div>
                    </div> 
                    <div className="wd100">
                      <p>계산서 발행 : </p>
                          <select style={{width:"120px"}} name="tax" value={tax} onChange={handleType}> 
                              <option value="-1">전체</option>
                              <option value="0">미발행</option>
                              <option value="1">발행</option>
                          </select>						
                    </div>
                  </div>
                  <div className="search-box division_line">
                    <div className="wd100">
                        <select style={{width:"120px"}} name="search" value={varSearch} onChange={handleType}> 
                            <option value="none">전체</option>
                            <option value="em_title">채용제목</option>
                            <option value="com_name">회사명</option>
                            <option value="m.mem_name">입사자</option>
                        </select>
                        <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') getEmployeeList(e)}} />
                        <button onClick={handleSearchPaymentList} className="btn">검색</button>
                        <button onClick={handleSearchClear} className="btn black">초기화</button>				
                    </div>
                </div>
            </div>
            <div className="list_top">
             <p>검색건수 : {varTotalCount}
                <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                  <option value="10">10개</option>
                  <option value="30">30개</option>
                  <option value="50">50개</option>
                  <option value="100">100개</option>
                </select>
              </p>
              <ExcelButton 
              startDate={startDate}
              endDate={endDate}
              option={varSearch}
              varSearchInput={varSearchInput}
              tax={tax}
              type={4}
              />
            </div>
            
            <div className="main-box ">
                <table className="list">
                    <colgroup>
                      <col style={{width:"8%"}} />
                      <col style={{width:"24%"}} />
                      <col style={{width:"12%"}} />
                      {/* <col style={{width:"10%"}}/> */}
                      <col style={{width:"9%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"10%"}}/>
                      <col style={{width:"9%"}}/>
                      <col style={{width:"8%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>채용제목</th>
                            <th>회사명</th>
                            {/* <th>헤드헌터 명</th> */}
                            <th>총 보상금</th>
                            <th>입사자</th>
                            <th>입사 일자</th>
                            <th>계산서 발행</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    {varFilterlist?.map((item,i) => (
                      <tr key={i}>
                        {/* <td>{varTotalCount-(varPageNum-1)*varPageSize -i}</td> */}
                        <td>{item.ROWNUM}</td>
                        <td className="ellipsis">{item.em_title}</td> 
                        <td className="ellipsis">{item.com_name}</td> 
                        {/* <td className="ellipsis">{item.candidate_name === null ? "-" : item.candidate_name}</td>  */}
                        {/* <td className="ellipsis">{item.mh_paid === null ? "-" : addThousandPoint(item.mh_paid)+"원"}</td>  */}
                        <td className="ellipsis">{item.mem_mode === 4 ? `${ChangeMoneyUnit(item.emh_reward_recommend / 10000)}` : `${ChangeMoneyUnit(item.em_reward_recommend / 10000)}` }</td> 
                        <td className="ellipsis">{item.mem_name}</td> 
                        <td className="ellipsis">{item.ea_idate === null ? "-" : item.ea_idate.substr(0,10)}</td>
                        <td className="ellipsis">{item.tax_bill_YN === 'Y' ? "발행" : "미발행"}</td>
                        <td><Link className="btn line black"to={`/admin/employee_detail/${item.mem_idx}`}state={item}>상세</Link></td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </div>
    </div>
    </>

    )

}

export default EmployeeList;