import React from "react";
import { useParams } from "react-router-dom";
import AcqRepuRPdf from 'components/Career/AcqRepuRPdf';  //지인평판 > PDF

const CareerReputationPdf = () => {
	const { userIdx } = useParams();

	return (
		<div className="container">
			<AcqRepuRPdf userIdx={userIdx} />
		</div>
	)
}

export default CareerReputationPdf