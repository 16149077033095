/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
//functions
import { ChangeSearchingDateFormat, common_select_function } from "../Common/Common";
//image
import deleteSchoolImage2 from "../../img/sub/btn_delete02.png";
//datepicker
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko"; // 한국어적용
registerLocale("ko", ko); // 한국어적용

function SchoolHistory({
  index,
  school,
  schoolLevelList,
  resumeSchoolList,
  setResumeSchoolList,
}) {
  //학력조합체
  const [varSchoolData, setSchoolData] = useState(school);
  const [startDate, setStartDate] = useState(school.reh_sdate===null||school.reh_sdate==="" ? null : new Date(school.reh_sdate));
  const [endDate, setEndDate] = useState(school.reh_edate===null||school.reh_edate==="" ? null : new Date(school.reh_edate));

  const [varEducationalStatus, setEducationalStatus] = useState([]);

  //최초 호출시 한번????
  useEffect(() => {
    if (school.reh_name !== "") {
      setSchoolData({
        idx: school.idx,
        reh_idx: school.reh_idx,
        reh_level: school.reh_level,
        vel_name: school.vel_name,
        reh_name: school.reh_name,
        reh_major: school.reh_major,
        reh_sdate: school.reh_sdate,
        reh_edate: school.reh_edate,
        reh_state: school.reh_state,
      });
    }
      const getEducationalStatus = async() =>{
        const column = ` ves_idx, ves_name `;
        const table = ` var_educational_status `;
        const where = ` ves_status = 1`;
        const option = ` ORDER BY ves_idx asc`;
        await common_select_function(column, table, where, option).then(function (res) {
            
           // const List = res.reverse();
            setEducationalStatus(res);

            
        }).catch(function (err) {
            console.error(err); // Error 출력  
        });
      };

      getEducationalStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeSchoolList]);

  //입력값 저장
  const onChangeHandler = (e) => {
    var { value, name } = e.target;

    if(name === 'reh_level' || name === 'reh_state'){
      value = Number(value);
    }
    //reh_name
    setSchoolData({ ...varSchoolData, [name]: value });
    setObjectList(name, value );
  };

  //학력배열로 저장
  const setObjectList = (name, value) => {
    //console.log(resumeSchoolList);
    const finsIndex = resumeSchoolList.findIndex(
      (element) => element.idx === varSchoolData.idx
    );
    let copyData = [...resumeSchoolList];
    copyData[finsIndex] = { ...copyData[finsIndex], [name]: value };
    setResumeSchoolList(copyData);
    //console.log(copyData);
  };
  //날짜변경
  const onChangeDate = (i, date) => {
    const ndate = ChangeSearchingDateFormat(date); //날짜 변경----configs.js
    if (i === 0) {
      setStartDate(date);
      setSchoolData({ ...varSchoolData, reh_sdate: ndate });
      setObjectList("reh_sdate", ndate);
    } else {
      setEndDate(date);
      setSchoolData({ ...varSchoolData, reh_edate: ndate });
      setObjectList("reh_edate", ndate);
    }
  };
  //학력삭제
  const onRemoveHandler = (e) => {
    e.preventDefault();
    if (!window.confirm("선택한 학력을 삭제합니까?")) return;
    setResumeSchoolList(
      resumeSchoolList.filter(
        (element) => element.idx !== varSchoolData.idx
      )
    );
  };

  return (
    <div className="input_area" key={"resch_" + index}>
      <button className="btn_del" onClick={(e) => onRemoveHandler(e)}>
        <img src={deleteSchoolImage2} alt="" />
      </button>
      <select
        className={"input_title style_select wd-xs"+(varSchoolData.reh_level !== null ? ' bg_on' : '')}
        name="reh_level"
        value={varSchoolData.reh_level}
        onChange={(e) => onChangeHandler(e)}
      >
        {schoolLevelList}
      </select>
      <input
        type="text"
        className={"input_part"+(varSchoolData.reh_name ? ' bg_on' : '')}
        name="reh_name"
        value={varSchoolData.reh_name}
        placeholder="학교명을 입력해주세요"
        onChange={(e) => onChangeHandler(e)}
      />
      <input
        type="text"
        className={"input_part2"+(varSchoolData.reh_major ? ' bg_on' : '')}
        name="reh_major"
        value={varSchoolData.reh_major}
        placeholder="전공명을 입력해주세요"
        onChange={(e) => onChangeHandler(e)}
      />
      <DatePicker
        className="input_date datepicker"
        locale={ko}
        showMonthYearPicker
        dateFormat="yyyy.MM"
        selected={startDate}
        selectStart
        onChange={(date) => onChangeDate(0, date)}
      />
      <DatePicker
        className="input_date datepicker"
        locale={ko}
        showMonthYearPicker
        minDate={startDate}
        dateFormat="yyyy.MM"
        selected={endDate}
        selectEnd
        onChange={(date) => onChangeDate(1, date)}
      />
      <select
        className={"input_state style_select"+(varSchoolData.reh_state > 0 ? ' bg_on' : '')}
        name="reh_state"
        value={varSchoolData.reh_state}
        onChange={(e) => onChangeHandler(e)}
      >
        <option value={-2}>선택</option>
        {varEducationalStatus && varEducationalStatus?.map((item, i)=>{
          return(
            <option key={i} value={item.ves_idx}>{item.ves_name}</option>
          )
        })}
      </select>
    </div>
  );
}

export default SchoolHistory;
