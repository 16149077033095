/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams,Link } from 'react-router-dom';

import axios from 'axios';
import CONFIG from '../../../configs/configs';
import { ChangeMoneyUnit, em_titleLength } from '../../../components/Common/Common';
import Paging from '../../../components/Layout/Paging'
import { DLM } from "aws-sdk"; //eslint-disable-line no-unused-vars

function UserRecruitmentsupport({ setCategory }) {
    const { user_idx } = useParams();

    const [varCounts,setCounts] = useState({
      unread:0,
      review:0,
      documents:0,
      interview:0,
      passed:0,
      joined:0,
      failed:0,
      earlyout:0
    });

    const [varRecruitment, setRecruitment] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    useEffect(async () => {
      setCategory(3);
      if(user_idx > 0) {
          const params = new URLSearchParams();
          params.append('mem_idx', user_idx);
          await axios.post(`${CONFIG.SERVER_HOST}/employ_recommond_list`, params).then((res) => {
            console.log("1111111111 ",res.data.results)
            if(res.data.retvalue === 1) {
              const datas = res.data.results;
              console.log(datas.em_tasks_info);
              setTotalCount(datas.length);
              setRecruitment(datas);
              setCountering(datas);    
            } else {
              alert(res.data.message);
            }
        });
      }
    }, []);

    //진행사항 카운팅
    const setCountering = (datas) => {
      const unread = datas.filter(recruitment => recruitment.ea_checking === 0);
      const review = datas.filter(recruitment => recruitment.ea_checking === 1);
      const documents = datas.filter(recruitment => recruitment.ea_checking === 2);
      const interview = datas.filter(recruitment => recruitment.ea_checking === 3);
      const passed = datas.filter(recruitment => recruitment.ea_checking === 4);
      const joined = datas.filter(recruitment => recruitment.ea_checking === 5);
      const failed = datas.filter(recruitment => recruitment.ea_checking === -2);
      const earlyout = datas.filter(recruitment => recruitment.ea_checking === -3);
      setCounts({unread:unread.length,review:review.length,documents:documents.length,interview:interview.length,
        passed:passed.length,joined:joined.length,failed:failed.length,earlyout:earlyout.length});
    }

    //페이지별 표시
    const getPageLists = () => {
      const startNum = (varPageNum-1)*varPageSize;
      const endNum = startNum + varPageSize;
      const copyData = varRecruitment.slice(startNum,endNum);
      setPagelist(copyData);
    }

    useEffect(() => {
      getPageLists();
    },[varPageNum,varRecruitment]);

    return (
      <React.Fragment>
        <p className="table_title" style={{marginTop:"20px"}}>- 채용 지원 현황</p>
        <div className="main-box" >
          <div style={{padding:"0px 20px"}}>
            <table className="list">
              <thead>
                <tr>
                  <th>미열람</th>
                  <th>검토중</th>
                  <th>서류합격</th>
                  <th>면접합격</th>
                  <th>최종합격</th>
                  <th>불합격</th>
                  <th>입사</th>
                  <th>조기퇴사</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{varCounts.unread}</td>
                  <td>{varCounts.review}</td>
                  <td>{varCounts.documents}</td>
                  <td>{varCounts.interview}</td>
                  <td>{varCounts.passed}</td>
                  <td>{varCounts.failed}</td>
                  <td>{varCounts.joined}</td>
                  <td>{varCounts.earlyout}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="main-box ">
          <table className="list">
          <colgroup>
              <col width="8%"></col>
              <col width="26%"></col>
              <col width="12%"></col>
              <col width="12%"></col>
              <col width="8%"></col>
              <col width="12%"></col>
              <col width="8%"></col>
              <col width="7%"></col>
              <col width="7%"></col>
          </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>채용제목</th>
                <th>회사명</th>
                <th>직무</th>
                <th>보상금</th>
                <th>추천인</th>
                <th>지원일자</th>
                <th>진행상태</th>
                <th>채용 상태</th>
              </tr>
            </thead>
            <tbody>
            {varPagelist.map((resume, i) => (
              <tr key={i}>
                <td>{varTotalCount-(varPageNum-1)*varPageSize-i}</td>
                <td>{em_titleLength(resume.em_title,25,'...')}</td>
                <td>{em_titleLength(resume.mcm_name,13,'...')}</td>
                <td>{resume.em_tasks_info === null ? "-" : resume.em_tasks_info}</td>
                <td>{ChangeMoneyUnit(resume.em_reward_recommend/10000)}</td>
                <td>{resume.er_mem_id === null ? "-" : resume.er_mem_type === 0 ? resume.er_mem_id : resume.er_mem_nickname }</td>
                <td>{resume.ea_rdate===null||resume.ea_rdate===""?"":resume.ea_rdate.substr(0,10)}</td>
                <td>{resume.ems_name}</td>
                <td><Link to={resume.em_headhunter === 0 ? `/admin/announcement_modify/${resume.ea_em_idx}`:`/admin/headhunter_dedicated_hall_modify/${resume.ea_em_idx}`} >
                  <button style={{padding:"10px 15px"}}>상세</button>
                </Link></td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} /> 
        </div>
      </React.Fragment>
    )
}

export default UserRecruitmentsupport;