import React from "react";
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

function AdminMain() {

 

    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="pref" />
            <Menu menuType="" menu="" />
            <div id="contents" className="ad_contents">
         
            </div>
        </React.Fragment>
    )
}

export default AdminMain;