
import { Link } from 'react-router-dom';//css
//components

//이미지

import btn_next from "img/sub/btn_next.png"
import main_top_link03 from "img/main/main_top_link03.png"
import main_top_link02 from "img/main/main_top_link02.png"
import main_top_link01 from "img/main/main_top_link01.png"
import main_top_link04 from "img/main/main_top_link04.png"


/*메인>서브페이지바로가기*/
function MainLink() {
    const LoginState = localStorage.getItem('memlogin') ? true : false;
    return (
        <section className="main_link center_wrap">
            <h2 className="title_area">쉽고 간편하게 이직을 준비하는 방법</h2>
            <ul className="link">
                <li>
                    <Link to={'/MyRecruit/MainList2/1/1/20/0'} state={{ em_headhunter: 0, em_type: 1 }} >
                        <p className="title_field">나에게 맞는 이직 정보와 <b>이직 보상금까지 확인하기!</b></p>
                        <p className="txt_field">자세히 보기<img src={btn_next} alt="btn_next" /></p>
                        <p className="img_field"><img src={main_top_link03} alt="main_top_link03" /></p>
                    </Link>
                </li>
                <li>
                    <Link to={LoginState ? "/MyResume" : "/Login"}>
                        <p className="title_field">HEDING 이력서 작성 후 <b>편리하게 신청하세요!</b></p>
                        <p className="txt_field">자세히 보기<img src={btn_next} alt="btn_next" /></p>
                        <p className="img_field"><img src={main_top_link02} alt="main_top_link02" /></p>
                    </Link>
                </li>
                <li>
                    <Link to={"/MyCareer/acquaintance-reputation"} >
                        <p className="title_field">친구나 직장동료들한테 <b>내 평판은 어떨지 한번 알아보세요</b></p>
                        <p className="txt_field">자세히 보기<img src={btn_next} alt="btn_next" /></p>
                        <p className="img_field"><img src={main_top_link01} alt="main_top_link01" /></p>
                    </Link>
                </li>
                <li>
                    <Link to={"/MyTalk"}>
                        <p className="title_field">다양한 이직 정보를 <b>회원끼리 공유해보세요!</b></p>
                        <p className="txt_field">자세히 보기<img src={btn_next} alt="btn_next" /></p>
                        <p className="img_field"><img src={main_top_link04} alt="main_top_link04" /></p>
                    </Link>
                </li>
            </ul>
        </section>
    );
}

export default MainLink;
