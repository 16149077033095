import React from 'react'
import img_colle_preview from "img/sub/colle_preview.png";
import img_colle_preview_m from "img/sub/colle_preview_m.png";

const ColleRpuRblind = ({buttonId, setButtonId, setEnablePopRequR}) => {
	
	const showRepuW = () => {
		setEnablePopRequR(true);
		setButtonId("your");
	}
	
	return (
		<section className="com_reputation com_repu_main com_repu_gray_container p_colle_repu center_wrap active">
			<article className="repu_preview_wrap">
				<img src={img_colle_preview} className="view_pc" alt="평판 미리보기 이미지" />
				<img src={img_colle_preview_m} className="view_m" alt="평판 미리보기 이미지" />
				<div className="bg_wrap">
					<h1>
						동료 평판을 작성하시면, <br />
						동료가 나를, 내가 동료 평판 작성한 내용을 보실 수 있습니다. <br />
						<button type="button" id={buttonId} className="btn" style={{width:'300px', marginTop:'25px'}} onClick={showRepuW}>동료평판 작성하기</button>
					</h1>
				</div>
			</article>
		</section>
	)
}

export default ColleRpuRblind