import React from "react";
//css

//components
//이미지

/*이용권구매>유의사항 안내*/
function VoucherInfo() {
  return (
    <div className="notice">
      <h1><i className="icon_mark">!</i>구매 시 유의사항</h1>
      <ul>
        <li>상기 금액은 부가가치세가 포함되지 않은 금액입니다.</li>
        <li>이용권을 구매한 날부터 이용권의 이용기간이 시작됩니다.</li>
        <li>유료상품 이용 종료 7일 후, 고객정보 보호기준에 따라 기존에 발송된 제안 수발신 이력은 모두 삭제되며 기존에 발송된 제안도 수락이 불가합니다.</li>
        <li>받은 제안 인재가 제안에 수락할 경우에만 이름과 연락처가 공개되며, 제안의 수락 여부에 관계없이 이용 건수는 소진됩니다.</li>
        <li>인재검색 One-Time 상품은 추가 구매가 가능하며 이용기간이 먼저 만료되는 것부터 우선 소진됩니다.</li>
        <li>개인정보보호법 제19조에 근거하여 인재검색 서비스는 채용을 목적으로 하는 경우에만 이용할 수 있으며, 기업의 영업, 마케팅 등 채용 이외의 용도로는 이용할 수 없습니다.<br/>채용 외 목적으로 이용하실 경우, 동법 제71조 2항에 의거 5년 이하의 징역 또는 5,000만원 이하의 벌금에 처할 수 있습니다.</li>
        <li>비정상적 검색이나 제안 발송이 감지될 경우 서비스 이용이 제한될 수 있습니다. 이용이 제한된 기간만큼 이용기간 연장은 불가능합니다.</li>
        <li>구매일을 포함하여 7일 이내에 환불을 요청하신 경우, 제안을 1건도 보내지 않은 경우에 한하여 전액 환불이 가능합니다. <br/>(구매 후 7일이 지났거나, 제안을 1건이라도 보내신 경우 환불이 불가능합니다.)</li>
      </ul>
    </div>
  );
}

export default VoucherInfo;
