/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import axios from "axios";
import CONFIG from "configs/configs";

import {isPassword} from 'components/Common/Common';

/*프로필*/
function PopEditSubPwd({varPopup,setPopup, varMemberIndex}) {

  //회원정보
  const [varInputs, setInputs] = useState({
    oldPwd: "",
    newPwd: "",
    pwdCheck: ""
  });

  //입력창 변경시
  const onChangeHandler = (e) => {
    const { value, name } = e.currentTarget;
    if (name === "oldPwd") {
      setInputs({ ...varInputs, oldPwd: value });
    } else if (name === "newPwd") {
      setInputs({ ...varInputs, newPwd: value });
    } else {
      setInputs({ ...varInputs, pwdCheck: value });
    }
  };

  //비밀번호 재저장
  const onUpdatePwdHandler = async (e) => {
    e.preventDefault();
    try {
      if (!varInputs.oldPwd) {
        alert("기존 비밀번호를 입력해주세요.");
      } else if (varInputs.oldPwd === varInputs.newPwd) {
        alert("새 비밀번호는 기존의 비밀번호와 달라야 합니다.");
      }else if (!varInputs.newPwd) {
        alert("새 비밀번호를 입력해주세요.");
      } else if(!isPassword(varInputs.newPwd)) {
        alert("비밀번호가 형식에 맞지 않습니다");
      } else if (!varInputs.pwdCheck) {
        alert("비밀번호 확인을 위해 한 번 더 입력해주세요.");
      } else if(varInputs.pwdCheck !== varInputs.newPwd) {
        alert("새 비밀번호가 일치하지 않습니다");
      } else {
        const param = new URLSearchParams();
        // console.log(varInputs)
        param.append("mem_oldPwd", varInputs.oldPwd);
        param.append("mem_idx", varMemberIndex);

        await axios.post(`${CONFIG.SERVER_HOST}/checkPwd`, param).then((res) => {
            if (res.data.tf === true) {
                const param = new URLSearchParams();
                console.log(varInputs.oldPwd)
                param.append("mem_newPwd", varInputs.newPwd);
                param.append("mem_idx", varMemberIndex);
                axios.post(`${CONFIG.SERVER_HOST}/changePw`, param).then((res) => {
                  alert(res.data.message)
                  if (res.data.retvalue === 1) {
                      setPopup(false);
                  }
                })   
            } else {
              alert('기존 비밀번호가 일치하지 않아 비밀번호 변경에 실패했습니다.\n처음부터 다시 입력해주세요.')
            }
        });
        setInputs({ oldPwd: "", newPwd: "", pwdCheck: ""});
      }
    } catch (error) {
      //alert("등록 실패");
      console.log(error);
    } 
  };

  return (
    <>
        <div className={varPopup ? "popup active" : "popup"}>
          <div className="popup_wrap popup01">
            <section className="title_wrap">
              <h1>비밀번호 변경</h1>
            </section>
            <div className="content_wrap">
              <fieldset>
                <div className="field">
                  <label className="label">기존 비밀번호</label>
                  <input type="password" placeholder="기존 비밀번호를 입력해주세요" name="oldPwd" value={varInputs?.oldPwd} onChange={(e) => onChangeHandler(e)}/>
                </div>
                <div className="field">
                  <label className="label">새 비밀번호</label>
                  <input type="password" placeholder="변경할 비밀번호를 입력해주세요" name="newPwd" value={varInputs?.newPwd} onChange={(e) => onChangeHandler(e)}/>
                </div>
                <div className="field">
                  <label className="label">새 비밀번호 확인</label>
                  <input type="password" placeholder="변경할 비밀번호를 한 번 더 입력해주세요" name="pwdCheck" value={varInputs?.pwdCheck} onChange={(e) => onChangeHandler(e)}/>
                </div>
              </fieldset>
            </div>
            <section className="button_wrap">
              <div className="big_button_wrap two">
                <button className="btn white" onClick={() => setPopup(false)}>취소</button>
                <button className="btn" onClick={(e) => onUpdatePwdHandler(e)}>확인</button>
              </div>
              <div className="close_button_wrap">
                <button onClick={() => setPopup(false)}>닫기 X</button>
                </div>
            </section>
          </div>
        </div>
    </>
  );
}

export default PopEditSubPwd;
