import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { useNavigate } from "react-router-dom";
import PopAcqRepuStart from "components/Career/PopAcqRepuStart"; //'친구가 보는 나' 시작하기(이름입력)
import AnonymousCheckPop from "./PopAnonymousCheck";
//이미지
import "../../css/Reputation.css";
import icon_write from "img/sub/repu_write.png";

// 내가 하는 평판과 지인이 하는 평판 공통 페이지입니다.
const AcqRepuW = ({ repu_code }) => {
  const navigator = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [repuList, setRepulist] = useState([]);
  const [writer, setWriter] = useState("");   //작성자(지인 이름)
  const [evaluator, setEvaluator] = useState({}); //피평가자
  const [enablePop, setEnablePop] = useState(false);   //팝업사용 여부
  const selfYn = useRef(''); // 내가쓰기 여부
  const memIdx = useRef(''); // 평가 대상자
  const [anonymousPop, setAnonymousPop ] = useState(false);   //익명사용 팝업
  const [anonymousYN, setAnonymousYN ] = useState(false);   //익명사용 여부

  const onChange = useCallback((event) => {
    setQuestions((prev) =>
      prev.map((item) => {
        return String(item.question) === event.target.name ? { ...item, id: event.target.id, option: parseInt(event.target.id.slice(-1), 10), value: parseInt(event.target.value, 10) } : item;
      })
    );
  }, []);

  // API :: 평판 제출 **********************************************************************
  const submitReputation = () => {
    console.log(memIdx.current, selfYn.current);
    const requsetBody = {
      mem_idx: memIdx.current,
      self_yn: selfYn.current,
      writer: writer,
      done_yn: "Y", // 필요한 컬럼인지..?  jskim 2022.11.14
      reputation: questions,
      repu_code: repu_code,  //지인이 평판 시 대상(회원) 평판코드(member 테이블 => refu_code)
      anonymousYN: anonymousYN ? "Y":"N"
    };

    axios.put(`${CONFIG.SERVER_HOST}/acquaintance-reputation`, requsetBody, CONFIG.header).then((res) => {
      if (res?.data?.retvalue === 1) {
        if (selfYn.current === 'Y') {//나 자신 평가
          localStorage.setItem("repuMy_YN", selfYn.current);
          localStorage.setItem("repuCode", res.data?.data.repuCode);
          navigator("/MyCareer/acquaintance-reputation");
        } else {//------------ 지인이 평가
          let names;
          const nameList = res.data.nameList;
          const length = res.data.nameList.length;
          if (length) {
            names = {
              mem_idx: nameList[length - 1][0].mem_idx,
              me: nameList[length - 1][0].me,
              friend: nameList[length - 1][0].friend,
              answer_no: nameList[length - 1][0].answer_no,
              anonymous: nameList[length - 1][0].anonymous,
            };
          }
          alert(res.data.message);
          navigator("/MyCareer/acquaintance-reputation/friends", { state: { mem_idx: names.mem_idx, me: names.me, friend: anonymousYN ? names.anonymous : names.friend, answer_no: names.answer_no } });
        }
      } else {
        console.debug(res.data);
        alert(res.data?.message);
      }
    });
  };

  useEffect(() => {
    // 피평가자 이름 할당
    const setEvaluatorName = () => {
      const params = new URLSearchParams([
        ['repu_code', repu_code]
      ]);
      axios
        .get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/evaluator`, { params })
        .then((res) => {
          if (res.data.retvalue) {
            console.log(res);
            const result = res.data.evaluator[0];
            setEvaluator((prev) => { return { ...prev, mem_idx: result?.mem_idx, mem_name: result?.mem_name } });
            memIdx.current = result?.mem_idx;
          }
        })
        .catch((e) => {
          console.debug(e);
        });
    };

    // 평판 문항
    const setReputationData = () => {
      const tempList = [];
      const tempQuestionState = [];

      axios
        .get(`${CONFIG.SERVER_HOST}/acquaintance-reputation`)
        .then((res) => {
          if (res.data.retvalue) {
            const result = res.data.reputationList;
            result.forEach((element) => {
              // console.log(typeof tempList[element.repu_no]);
              if (typeof tempList[element.repu_no] === "undefined") {
                tempList[element.repu_no] = [];
                tempQuestionState.push({ question: element.repu_no, id: element.repu_no + "" + element.repu_ex_no, option: element.repu_ex_no, value: element.repu_score });
              }
              tempList[element.repu_no].push(element);
            });
            // console.log("RESULT   ", tempList.slice(1));
            // console.log(tempList);
            setRepulist(tempList.splice(1));
            setQuestions([...tempQuestionState]);
          }
        })
        .catch((e) => {
          console.debug(e);
        });
    };

    if (repu_code) {
      setEvaluatorName();
      // 지인이 평가
      selfYn.current = 'N';
      setEnablePop(true);
    } else {
      // 나 자신이 평가
      selfYn.current = 'Y';
      memIdx.current = localStorage.getItem("mem_idx");
      setEnablePop(false);
    }
    setReputationData();
  }, [repu_code]);

  return (
    <>
      <section className="com_reputation com_repu_w_r p_acq_w center_wrap">
        <article className="com_repu_gray_container">
          <article className="repu_write_title">
            <img src={icon_write} alt="평판 작성 아이콘" />총 12문항이며, 많은&nbsp;<span className="txt_blue">생각보다는 바로 선택</span>해주세요.
          </article>
          {selfYn.current === 'N' && <article className="repu_write_title">
            <img src={icon_write} alt="평판 작성 아이콘" />
            <span className="txt_blue">{writer}</span>님이 보는 <span className="txt_blue">{evaluator?.mem_name}</span>님은 어떤 사람인가요?
          </article>}
          <form>
            {repuList?.map((element) => {
              return (
                <div className="repu_question" key={`${element[0].repu_no}`}>
                  <h1>
                    {element[0].repu_no}.&nbsp;{element[0].repu_q}
                  </h1>
                  <ul className="repu_answer" key={`${element[0].repu_no}`}>
                    {element.map((e) => {
                      return (
                        <li key={`${element[0].repu_no}${e.repu_ex_no}`}>
                          <input
                            type="radio"
                            className="chk square"
                            id={`${element[0].repu_no}${e.repu_ex_no}`}
                            name={`${element[0].repu_no}`}
                            value={e.repu_score}
                            checked={questions[element[0].repu_no - 1]?.id === `${element[0].repu_no}${e.repu_ex_no}`}
                            onChange={onChange}
                          />
                          <label htmlFor={`${element[0].repu_no}${e.repu_ex_no}`}>{e.repu_a}</label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </form>
          <div className="btn_wrap">
            <button type="button" className="btn" onClick={submitReputation}>
              제출하기
            </button>
          </div>
        </article>
      </section>
      {enablePop && <PopAcqRepuStart writer={writer} setWriter={setWriter} setEnablePop={setEnablePop} setAnonymousPop={setAnonymousPop} />}
      {anonymousPop && <AnonymousCheckPop setAnonymousPop={setAnonymousPop} setAnonymousYN={setAnonymousYN} />}
    </>
  );
};

export default AcqRepuW;
