/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import moment from 'moment'; 

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import Paging from '../../../components/Layout/Paging'
import { ChangeMoneyUnit, /*changeEmMode*/ } from '../../../components/Common/Common'
import ExcelButton from 'admin/pages/board/ExcelDownloadButton';

function QuitMemberList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [startDate, setStartDate] = useState(""); //입사날짜
    const [startDate2, setStartDate2] = useState(""); //퇴사날짜
    const [endDate, setEndDate] = useState(""); //입사날짜
    const [endDate2, setEndDate2] = useState(""); //퇴사날짜
    const [varSearch, setSearch] = useState("none");
    const [varSearchInput, setSearchInput] = useState("");
    const [varPageSize, setPageSize] = useState(10);
    const [selectChange, setSelectChange] = useState(false);

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageNum,setPageNum] = useState(1);
    const [varFilterlist, setFilterlist] = useState([]); 

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //입사자 리스트 가져오기
    const getQuitMemberList = async (iMode) => {
      let sendData;
      if(iMode !== 1){
        sendData = {
          'currentPage': varPageNum,
          'pageSize': varPageSize,
          'sdate': startDate ? moment(startDate).format(CONFIG.formatStr) : "",
          'edate':startDate ? moment(endDate).format(CONFIG.formatStr) : "",
          'sdate2': startDate2 ? moment(startDate2).format(CONFIG.formatStr) : "",
          'edate2':startDate2 ? moment(endDate2).format(CONFIG.formatStr) : "",
          'search_option': varSearch,
          'search_string': varSearchInput
        }
      }else{
        sendData = {
          'currentPage': varPageNum,
          'pageSize': varPageSize,
          'sdate': "",
          'edate': "",
          'sdate2': "",
          'edate2': "",
          'search_option': "none",
          'search_string': ""
        }
      }
      try {
        await axios.post(`${CONFIG.SERVER_HOST}/quit_member_list`,sendData, CONFIG.header).then((res) => {
          console.log("res.data ",res.data)
            if(res.data.retvalue === 1) {
                setTotalCount(res.data.totalcount); 
                setFilterlist(res.data.results);
                if(selectChange){
                  setSelectChange(false);
                  setPageNum(1);
                }
            }
        });
      } catch (error) {
        console.log(error);
      }
    }

    const handleType = (e) =>{
        setSearch(e.currentTarget.value);
    };
    const handleInput = (e) =>{
        setSearchInput(e.currentTarget.value);
    };

    //검색-초기화
    const handleSearchClear =()=>{
        setStartDate("");
        setEndDate("");
        setStartDate2("");
        setEndDate2("");
        setSearch("none");
        setSearchInput("");
        setPageNum(1);
        getQuitMemberList(1);
    }

    useEffect(() => {
      getQuitMemberList();
    },[varPageNum]);

    useEffect(() => {
      getQuitMemberList();
    },[varPageSize]);
    
    const handlestartdate =(e)=>{
      const {name, value} = e.target;
      if(name === "startdate"){
        setStartDate(value);
      }else{
        setStartDate2(value);
      }     
    }
    const handleenddate =(e)=>{
      const {name, value} = e.target;
      if(name === "enddate"){
        setEndDate(value);
      }else{
        setEndDate2(value);
      }     
    }

    const settingPageSize = (e) =>{
      const {value} = e.target;
      setPageSize(Number(value));
      setSelectChange(true);
    }

    return(
        <>
        <Titlebar/>
        <Tabbar tabType="users"/>
        <Menu menuType="users" menu="quit_member_list"/>
        <div id="contents" className="ad_contents">
        <div className="tit">보증기간 내 퇴사 관리
            <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>헤딩머니<img src={arrImage} alt=""/>보증기간 내 퇴사 관리
            </div>
        </div>
        <div className="content">
            <div className="main-box">		
                <div className="search-box left">
                    <div className="f-right">		
                      <div className="day-box">
                          <p>입사일자</p>
                          <input type="date"  name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                          ~ 
                          <input type="date"  name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
                      </div>
                    </div> 
                    <div className="f-right">	
                      <div className="day-box">
                          <p>퇴사일자</p>
                          <input type="date"  name="startdate2" value={startDate2} onChange={handlestartdate} id="datePicker" />
                          ~ 
                          <input type="date"  name="enddate2" value={endDate2} onChange={handleenddate} id="datePicker2" />
                      </div>
                    </div> 
                  </div>
                  <div className="search-box division_line">		
                    <div>						
                      <select style={{width:"120px"}} name="search" value={varSearch} onChange={handleType}> 
                          <option value="none">전체</option>
                          <option value="em_title">채용제목</option>
                          <option value="com_name">회사명</option>
                          <option value="m.mem_name">입사자</option>
                      </select>
                      <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') getQuitMemberList(e)}} />
                      <button onClick={getQuitMemberList} className="btn">검색</button>
                      <button onClick={handleSearchClear} className="btn black">초기화</button>				
                    </div>
                  </div>
            </div>
            <div className="list_top">
              <p>검색건수 : {varTotalCount}
                <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                    <option value="10">10개</option>
                    <option value="30">30개</option>
                    <option value="50">50개</option>
                    <option value="100">100개</option>
                </select>
              </p>
              <ExcelButton 
                startDate={startDate}
                endDate={endDate}
                startDate2={startDate2}
                endDate2={endDate2}
                option={varSearch}
                varSearchInput={varSearchInput}
                type={5}
                />
            </div>
            <div className="main-box ">
                <table className="list">
                    <colgroup>
                      <col style={{width:"8%"}} />
                      <col style={{width:"24%"}} />
                      <col style={{width:"12%"}} />
                      {/* <col style={{width:"10%"}}/> */}
                      <col style={{width:"8%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"10%"}}/>
                      <col style={{width:"10%"}}/>
                      <col style={{width:"8%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>채용제목</th>
                            <th>회사명</th>
                            <th>총 보상금</th>
                            <th>입사자</th>
                            <th>입사 일자</th>
                            <th>퇴사 일자</th>
                            <th>계산서 발행</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody>
                    {varFilterlist?.map((item,i) => (
                      <tr key={i}>
                        {/* <td>{varTotalCount-(varPageNum-1)*varPageSize -i}</td> */}
                        <td>{item.ROWNUM}</td>
                        <td className="ellipsis">{item.em_title}</td> 
                        <td className="ellipsis">{item.com_name}</td> 
                        <td className="ellipsis">{item.mem_mode === 4 ? `${ChangeMoneyUnit(item.emh_reward_recommend / 10000)}` : `${ChangeMoneyUnit(item.em_reward_recommend / 10000)}`}</td> 
                        <td className="ellipsis">{item.mem_name}</td> 
                        <td className="ellipsis">{item.ea_idate === null ? "-" : item.ea_idate?.substr(0,10)}</td>
                        <td className="ellipsis">{item.ea_edate === null ? "-" : item.ea_edate?.substr(0,10)}</td>
                        <td className="ellipsis">{item.tax_bill_YN === 'Y' ? "발행" : "미발행"}</td>
                        <td><Link className="btn line black"to={`/admin/quit_member_detail/${item.mem_idx}`}state={item}>상세</Link></td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </div>
    </div>
    </>

    )

}

export default QuitMemberList;