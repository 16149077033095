/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useRef } from "react";
import CONFIG from "../../configs/configs";
import { useLocation } from "react-router-dom";

const { naver } = window;

const NaverLoginButton = ({ onNaverLogin }) => {
  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: "qFcIolc_Ea63bfEX3nHF",
      callbackUrl: `https://${window.location.hostname}/Login`,
      isPopup: false, // popup 형식으로 띄울것인지 설정
      loginButton: { color: "white", type: 3, height: "50" }, //버튼의 스타일, 타입, 크기를 지정
    });
    naverLogin.init();
  };  

  const location = useLocation();

  const getUserInfo = async () => {
    try {
      if (!location.hash) return;
      const token = location.hash.split("=")[1].split("&")[0];
      await axios
        .get(`${CONFIG.SERVER_HOST}/naver_member?token=${token}`)
        .then((res) => {
          if (res.status === 200) {
            // sessionStorage.setItem("sns_id", res.data.response.id);
            // sessionStorage.setItem("sns_type", 1);
            // localStorage.setItem("sns_id", res.data.response.id);
            // localStorage.setItem("sns_type", 1);
            onNaverLogin(res.data.response.id, 1, '');
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const naverRef = useRef();

  const handleClick = () => {
    naverRef.current.children[0].click();
  };

  useEffect(() => {
    initializeNaverLogin();
    getUserInfo();
    document.querySelector("#naverIdLogin_loginButton").removeAttribute('href');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div ref={naverRef} id="naverIdLogin" style={{ display: "none" }}></div>
      <button className="btn naver" onClick={handleClick}>
        {/* <img src={require("../../img/sub/member_sns01.png").default} alt="" /> */}
        네이버 이용하기
      </button>
    </>
    // <div id="naverIdLogin">
    //   <button id="naverIdLogin_loginButton">
    //     <img src={require("../../img/sub/member_sns01.png").default} alt="" />
    //     네이버 로그인
    //   </button>
    // </div>
  );
};

export default NaverLoginButton;
