import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios'
import CONFIG from 'configs/configs'

/*메인>이직 톡*/
function MainTalkLists() {
  const [Loding, setLoading] = useState(false);
  const [varMoveWorkingLists, setrMoveWorkingLists] = useState([]);
  //이지톡정보 읽기

  useEffect(() => {
    const getMoveWorkingLists = async () => {
      try {
        setLoading(true);
        await axios.post(`${CONFIG.SERVER_HOST}/_get_move_working_talk`).then((res) => {
          console.log(res.data);
          if (res.data.retvalue === 1) {
            const datas = res.data.results;
            //채용공고전체
            setrMoveWorkingLists(datas);
          } else {
            setrMoveWorkingLists([]);
          }

          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        //setLoading(true);
      }
    }
    if (Loding === false) {
      getMoveWorkingLists();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => setLoading(false); // cleanup function을 이용
  }, []);

  return Loding === false ? (
    <ul className="list">
        {varMoveWorkingLists.map((moveWorking, index) => (
        <li key={'talk_' + index}>
            <Link to={'/MyTalk/TalkView'} state={{ mwt_idx: moveWorking.mwt_idx }}>
            <p className="title_field">{moveWorking.mwt_title}</p>
            <p className="txt_field">{moveWorking.mwt_comment.replace(/&nbsp;/gi, '').replace( /(<([^>]+)>)/ig, '')}</p>
            </Link>
        </li>
        ))}
    </ul>
  )
  :
  (<div>Loading ...</div>)

  ;
}

export default MainTalkLists;
