import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../../configs/configs";
import $ from "jquery";
import "css/Head.css";
import { isReactNative } from 'components/Common/Common';


//image
import btn_home_m from "img/common/btn_home_m.png";
import btn_close from "img/common/btn_close.png";
import axios from "axios";


function NavCompany({ varIsLogin, varPersonal, LogoutHandler }) {
  const navigator = useNavigate();
  
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_sub = Number(localStorage.getItem("mem_sub"));
  const isMobile = isReactNative();
  //console.log(mem_idx,mem_sub);
  //const [varSubMember,setSubMember] = useState(false);
  const [varNickname, setNickname] = useState(
    localStorage.getItem("mem_nickname")
  );
  useEffect(() => {
    if (varIsLogin) {
      const mem_nickname = localStorage.getItem("mem_nickname");
      setNickname(mem_nickname);
    } else {
      setNickname("Nobody");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varIsLogin]);

  let varSubMember = false;
  if (mem_idx !== mem_sub) {
    varSubMember = true;
    //setSubMember(true);
  }

  let menus = [];
  //헤드헌터의 경우
  //2022-11-05 mem_hunter_type 컬럼 안씀, 헤드헌터는 회원 구분으로 - KSR
  //if (mem_hunter_type === 1) {
  if (mem_mode === 2) {/********** 헤드 헌터 ******************************************/
    if (mem_idx === mem_sub) {//헤드헌터 > 메인 계정
      menus = [
        { id: 1, menu: "정보 관리", active: false },
        { id: 2, menu: "서브 계정 관리", active: false },
        // { id: 10, menu: "추천 현황", active: false },
        { id: 3, menu: "제안 현황", active: false },
        { id: 5, menu: "이용권 관리", active: false },
        // { id: 11, menu: "관심 공고 관리", active: false },
        { id: 12, menu: "이직/구인톡 관리", active: false },
        { id: 6, menu: "헤딩 머니", active: false },
        { id: 7, menu: "알림내역", active: false },
        // { id: 8, menu: "FAQ", active: false },
        // { id: 13, menu: "공지사항", active: false },
        { id: 9, menu: "HR 담당자 소개", active: false },
      ];
    } else {//--------------------헤드헌터 > 서브 계정
      menus = [
        { id: 1, menu: "정보 관리", active: false },
        // { id: 10, menu: "추천 현황", active: false },
        { id: 3, menu: "제안 현황", active: false },
        { id: 5, menu: "이용권 관리", active: false },
        // { id: 11, menu: "관심 공고 관리", active: false },
        { id: 12, menu: "이직/구인톡 관리", active: false },
        { id: 6, menu: "헤딩 머니", active: false },
        { id: 7, menu: "알림내역", active: false },
        // { id: 8, menu: "FAQ", active: false },
        // { id: 13, menu: "공지사항", active: false },
        { id: 9, menu: "HR 담당자 소개", active: false },
      ];
    }
  } else {//**************** 기업회원 **************************************
    if (mem_idx === mem_sub) {//기업 > 메인 계정
      menus = [
        { id: 1, menu: "정보 관리", active: false },
        { id: 2, menu: "서브 계정 관리", active: false },
        { id: 3, menu: "제안 현황", active: false },
        { id: 5, menu: "이용권 관리", active: false },
        // { id: 11, menu: "관심 공고 관리", active: false },
        { id: 12, menu: "이직/구인톡 관리", active: false },
        { id: 6, menu: "헤딩 머니", active: false },
        { id: 7, menu: "알림내역", active: false },
        // { id: 8, menu: "FAQ", active: false },
        // { id: 13, menu: "공지사항", active: false },
        { id: 9, menu: "HR 담당자 소개", active: false },
      ];
    } else {//--------------------기업 > 서브 계정
      menus = [
        { id: 1, menu: "정보 관리", active: false },
        { id: 3, menu: "제안 현황", active: false },
        { id: 5, menu: "이용권 관리", active: false },
        // { id: 11, menu: "관심 공고 관리", active: false },
        { id: 12, menu: "이직/구인톡 관리", active: false },
        { id: 6, menu: "헤딩 머니", active: false },
        { id: 7, menu: "알림내역", active: false },
        // { id: 8, menu: "FAQ", active: false },
        // { id: 13, menu: "공지사항", active: false },
        { id: 9, menu: "HR 담당자 소개", active: false },
      ];
    }

  }
  const [companyItems] = useState([...menus]);

  const headObject = document.getElementById("head");
  const depth1Boxes = document.querySelectorAll(".depth1");

  useEffect(() => {
    (() => {
      for (var i = 0; i < depth1Boxes.length; i++) {
        depth1Boxes[i].addEventListener("mouseenter", () => {
          if (!headObject.classList.contains("on"))
            headObject.classList.add("on");
        });
        depth1Boxes[i].addEventListener("mouseleave", () => {
          if (headObject.classList.contains("on"))
            headObject.classList.remove("on");
        });
      }
    })();
    return () => { };
  });

  //모바일 메뉴 닫기
  const onCloseMenuUser = () => {
    $("#head .nav_wrap").hide();
  };

  // const movePage = async(url)=>{
  //   await navigate(url);
  //   await onCloseMenuUser();
  // }


  const moveChat = () => {

    let sendData = {
      "user_idx": mem_idx,
      "type": mem_mode,
    }

    axios.post(`${CONFIG.SERVER_HOST}/_getChatLastIdx`, sendData, CONFIG.header).then((res) => {
      console.log(res.data.results)
      const { results, retvalue } = res.data;
      if (retvalue === 1) {
        if (results) {
          navigator(`/Company/ComMessage/${results}`);
        } else {
          navigator(`/Company/ComMessage`);
        }
      }
    });
  };

  return (
    /* 모바일 */
    <nav className="nav_wrap">
      <section className="mobile_content">
        <article className="top_area">
          <h2 className="title_field">전체메뉴</h2>
          <a href="/Company" className="button">
            <img src={btn_home_m} alt="" />
          </a>
          <button
            className="BtnClose button"
            onClick={(e) => onCloseMenuUser(e)}
          >
            <img src={btn_close} alt="" />
          </button>
        </article>
        <article className="profile_area">
          {varPersonal[0]?.mcm_image === 0 || !varIsLogin ?
            <div
              className="profile_img"
            /> :
            <div
              className="profile_img"
              style={{ backgroundImage: `url(${CONFIG.SERVER_HOST}/mcm_image/${varPersonal[0]?.mcm_ufile})` }}
            />
          }
          {varIsLogin ? (
            <p className="id_field">{varNickname}님</p>
          ) : null}
          {varIsLogin ? (
            <button className="button" onClick={() => LogoutHandler()}>
              로그아웃
            </button>
          ) : null}
        </article>
        {/* <!--로그인전--> */}
        <article className="member_area">
          {varIsLogin ? null : (
            <p className="title_field">
              <span className="point">로그인</span> 후 나의 정보를 확인해보세요!
            </p>
          )}
        </article>
        <article className="btn_area">
          {varIsLogin ? null : (
            <Link to={"/Login/Company"} state={"Company"} className="btn">
              로그인
            </Link>
          )}
          {varIsLogin ? null : (
            <Link to={"/JoinUs"} className="btn white">
              회원가입
            </Link>
          )}
        </article>
      </section>
      <ul className="nav">
        {/*해당페이지 활성화 시 li에 클래스 Active 추가*/}
        <li className="depth1">
          {isMobile ?
            <span className="menu">공고등록</span>
            :
            <Link
              className="menu"
              to={"/Company/ComAnnouncement/AnnouncementWrite"}
              state={{ ComAnnouncement: 0 }}
            >
              공고등록
            </Link>
          }

          <ul className="depth2">
            <li>
              {/* <a onClick={() => movePage('/Company/ComAnnouncement/AnnouncementWrite')}>공고등록</a> */}
              <Link to={"/Company/ComAnnouncement/AnnouncementWrite"} onClick={onCloseMenuUser}>
                공고등록
              </Link>
            </li>
          </ul>
        </li>
        <li className="depth1">
          {isMobile ?
            <span className="menu">공고/지원자 관리</span>
            :
            <Link
              className="menu"
              to={"/Company/ComAnnouncement/AnnouncementList"}
              state={{ ComAnnouncement: 1 }}
            >
              공고/지원자 관리
            </Link>
          }

          <ul className="depth2">
            <li>
              <Link to={"/Company/ComAnnouncement/AnnouncementList"} onClick={onCloseMenuUser}>
                공고 관리
              </Link>
            </li>
            <li>
              <Link to={"/Company/ComAnnouncement/SupportList"} onClick={onCloseMenuUser}>
                지원자 관리
              </Link>
            </li>
          </ul>
        </li>
        <li className="depth1">
          {isMobile ?
            <span className="menu">인재DB</span>
            :
            <Link className="menu" to={"/Company/ComResource/SearchMain"}>
              인재DB
            </Link>
          }

          <ul className="depth2">
            <li>
              <Link to={"/Company/ComResource/SearchMain"} onClick={onCloseMenuUser}>인재 검색하기</Link>
            </li>
            <li>
              <Link to={"/Company/ComResource/Candidate"} onClick={onCloseMenuUser}>후보자 관리</Link>
            </li>
            <li>
              <Link to={"/Company/ComResource/Interest"} onClick={onCloseMenuUser}>관심 인재</Link>
            </li>
            <li>
              <Link to={"/Company/ComResource/Latest"} onClick={onCloseMenuUser}>최근 조회 인재</Link>
            </li>
          </ul>
        </li>
        <li className="depth1">
          {isMobile ?
            <span className="menu">메시지</span>
            :
            // <Link className="menu" to={"/Company/ComMessage"}>
            //   메시지
            // </Link>
            <Link className="menu" onClick={() => moveChat()}>메시지</Link>
          }

          <ul className="depth2">
            <li>
              {/* <Link to={"/Company/ComMessage"} onClick={onCloseMenuUser}>메시지</Link> */}
              <Link onClick={() => moveChat()}>메시지</Link>
            </li>
          </ul>
        </li>
        {mem_idx === mem_sub ? 
          <li className="depth1">
          {isMobile ?
            <span className="menu">이용권구매</span>
            :
            <Link className="menu" to={"/Company/ComVoucher"}>
              이용권구매
            </Link>
          }
          <ul className="depth2">
            <li>
              <Link to={"/Company/ComVoucher"} onClick={onCloseMenuUser}>이용권 구매</Link>
            </li>
          </ul>
        </li> 
         : ""}
        <li className="depth1 my">
          {isMobile ?
            <span className="menu">My HEDING</span>
            :
            <Link
              to={varIsLogin ? "/Company/ComHeding" : "/Login"}
              className="menu"
            >
              My HEDING
            </Link>
          }

          <ul className="depth2">
            {companyItems.map((lMenus, i) => (
              <li key={"memu_" + i} id={"heding_" + lMenus.id}>
                <Link
                  to={
                    !varIsLogin
                      ? "/Login"
                      : varSubMember && lMenus.id === 2
                        ? "/Company/ComHeding/MyHeding1sub"
                        : "/Company/ComHeding/MyHeding" + lMenus.id
                  }
                  onClick={onCloseMenuUser}
                >
                  {lMenus.menu}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
}

export default NavCompany;
