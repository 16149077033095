import React, { useState, useEffect } from 'react'

import '../../css/Reputation.css';
import axios from 'axios';
import CONFIG from '../../configs/configs';

//import ColleRpuRblind from "components/Career/ColleRpuRblind";
//import PopAcqRepuR2 from "components/Career/PopAcqRepuR2"; //POP '친구가 보는 나' 요청하기 - 수정

const ColleRepuRPdf = ({userIdx}) => {
	//const [enablePopRequR, setEnablePopRequR] = useState(false); //팝업사용 여부
	const [category, setCategory] = useState([]); // 분류: 관계, 업무스타일, ... 
	//const [question, setQuestion] = useState([]); // 분류 내 질문, 질문 내 선택지
	//const [colleagueAnswer, setColleagueAnswer] = useState(new Map()); // 동료 평판 결과
	//const [colleagueList, setColleagueList] = useState([]); // 익명의 평판작성일자, 근무기간, 회사, 직급
	//const [colleague, setColleague] = useState(null); //선택한 평판(셀박스에서...)
	//const [answerCount, setAnswerCount] = useState(0);
	//const [colleagueInfo, setColleagueInfo] = useState({ company: '', position: '' });
	//const [buttonId, setButtonId] = useState('my');

	const [reputationAll, setReputationAll] = useState([]); //전체 평판
	const [myName, setMyName] = useState(null); //전체 평판
	
	//const myName = "홍길동";//localStorage.getItem("mem_name");
	const myIdx = userIdx;//localStorage.getItem("mem_idx");

	//const buttonId = "my";


	//전체 평판 가져오기 -------------------
	useEffect(() => {
		console.log('useEffect::getAllReputation');
		const params = new URLSearchParams([
			['tg_field', "to_mem_idx"],
			['mem_idx', myIdx]
		]);

		//API::GET
		const getAllReputation = () => {
			axios.get(`${CONFIG.SERVER_HOST}/career-reputation-all/${myIdx}`, { params })
				.then((res, err) => {
					if (!err) {
						console.log('getAllReputation:', res.data?.answer_list);
						if (res.data.answer_list?.length) {
							setMyName(res.data.mem_info?.mem_name);
							setReputationAll(res.data.answer_list);
						} else {
							setReputationAll([]);
						}
					}
				}).catch((e) => {
					console.debug('Exception at getColleagueList  ', e);
				})
		};

		getAllReputation();
	}, [myIdx]);


	//카테고리 가져오기------------------------------------
	useEffect(() => {
		const getCategory = () => {
			axios.get(`${CONFIG.SERVER_HOST_REPUTATION}/reputation/RepuPeerCate`)
				.then((res) => setCategory(res.data))
				.catch((e) => console.debug('Error  ', e));
		};

		if (reputationAll.length > 0) {
			getCategory();
		}
	}, [reputationAll]);
	

	return (
		<>
			
			{(reputationAll.length < 1 ) ?	//받은 평판 유무
        /* 시작. 받은병판 없다 */
				<section className="com_reputation com_repu_w_r p_colle_r center_wrap p_repu_r">
					<article className="repu_writer_info_wrap">
              <div className='notice_area'>
                <h1>
                  <span className="txt_blue">{myName}</span>
                  &nbsp;님은 동료에게 받은 평판이 없습니다.
                 </h1>
              </div>
					</article>
				</section>
        /* 끝. 받은 평판 없다 */
				:
        /* 시작. 받은 평판 있다 */
				<section className="com_reputation com_repu_w_r p_colle_r center_wrap p_repu_r">
					<article className="repu_writer_info_wrap">
						<div className='notice_area'>
							<h1>
								<span className="txt_blue">{myName}</span>
								님은 {`${reputationAll?.length}`}명의 동료에게 평판을 받았습니다.
							</h1>
						</div>
					</article>

					<article className="com_repu_gray_container">
						{
							reputationAll?.map((raputation, idx) => {
								return(
									<form key={idx}>

										<article className="repu_result_info_wrap">
											<ul>
												<li>작성자 :<b>{raputation.writer?.blind === 'Y' ? "익명" : raputation.writer?.writer_name}</b></li>
												<li>평판작성일자 :<b>{raputation.writer?.reg_dtm}</b></li>
											</ul>
										</article>

										{/*>> 기간 및 관계 ----------------------------------------------------------*/}
										{raputation?.list?.filter((list) => list.cate_no >= 6).map((content) => {
											return(
												<div className="repu_question" key={`${content.cate_no}`}>
													<h1>{content.repu_q}</h1>
													<ul className="repu_answer">
														<li className={"has_textbox"}key={`${content.cate_no}`}>
															{content.repu_a}
														</li>
													</ul>
												</div>
											);
										})}

										{/*>> 카테고리 별 ----------------------------------------------------------*/}
										{category?.map((cateMap) => {
											return (
												<React.Fragment key={`${cateMap.cate_no}`}>
													<article className="repu_question_wrap horizon2 has_section">
														<h1 className="repu_question_title">
															{cateMap.cate_nm_kr}<span>{cateMap.cate_nm_en}</span>
														</h1>
														{/* 문항 반복 - 카테고리 5가 아닌것 */}
														{raputation?.list?.filter((content) => content.cate_no === cateMap.cate_no && content.cate_no !== 5).map((qustion) => {
															return (
																<section className="left_section" key={`${cateMap.cate_no}${qustion.repu_no}`}>
																	<div className="repu_question align_v">
																		<h1>Q. {qustion.repu_q}</h1>
																		<ul className="repu_answer">
																			{/*보기 반복 */}
																			{qustion?.ex_list?.map((el, key) => {
																				const chk = qustion.ex_no === el.ex_no ? true : false;
																				return (
																					<li key={`${cateMap.cate_no}${qustion.repu_no}${key}`} >
																						<input type="radio"
																							className="chk square wide"
																							name={`${cateMap.cate_no}${qustion.repu_no}`}
																							id={`${cateMap.cate_no}${qustion.repu_no}${el.ex_no}`}
																							checked={chk}
																							readOnly={true}
																						/>
																						<label htmlFor={`${cateMap.cate_no}${qustion.repu_no}${el.ex_no}`}><span>{el.repu_a}</span></label>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</section>
															);
														})}

														{/* 문항 반복 - 카테고리 5인것 */}
														{raputation?.list?.filter((content) => content.cate_no === cateMap.cate_no && content.cate_no === 5).map((qustion) => {
															//const fAnswer = colleagueAnswer.length ? colleagueAnswer?.filter((a) => a.cate_no === content.cate_no && a.repu_no === content.repu_no) : [];
															return (
																<React.Fragment key={`${cateMap.cate_no}${qustion.repu_no}`}>
																	{qustion.repu_no < 4 &&
																		<section className="left_section">
																			<div className="repu_question align_v">
																				<h1>Q. {qustion.repu_q}</h1>
																				<ul className="repu_answer">
																					{qustion?.ex_list?.map((el, key) => {
																						const chk = qustion.ex_no === el.ex_no ? true : false;
																						return (
																							<li key={`${cateMap.cate_no}${qustion.repu_no}${key}`}>
																								<input type="radio"
																									className="chk square wide"
																									name={`${cateMap.cate_no}${qustion.repu_no}`}
																									id={`${cateMap.cate_no}${qustion.repu_no}${el.ex_no}`}
																									checked={chk}
																									readOnly={true}
																								/>
																								<label htmlFor={`${cateMap.cate_no}${qustion.repu_no}${el.ex_no}`}><span>{el.repu_a}</span></label>
																							</li>
																						)
																					})}
																				</ul>
																			</div>
																		</section>
																	}
																	{qustion.repu_no === 4 &&
																		<section className="right_section">
																			<div className="repu_question align_v">
																				<h1>Q. {qustion.repu_q}</h1>
																				<ul className="repu_answer long_answer">
																					<li >
																						<label >A. {qustion.repu_a}</label>
																					</li>
																				</ul>
																			</div>
																		</section>
																	}
																</React.Fragment>
															);
														})}		
													</article>

													{raputation?.list?.filter((content) => content.cate_no === cateMap.cate_no && content.cate_no === 5)
													.map((qustion) => {
														//const fAnswer = colleagueAnswer.length ? colleagueAnswer?.filter((a) => a.cate_no === content.cate_no && a.repu_no === content.repu_no) : [];
														return (
															qustion.cate_no === 5 && qustion.repu_no > 4 &&
															<div className="repu_question align_v only_txtbox" key={`${cateMap.cate_no}${qustion.repu_no}`}>
																<h1>{qustion.repu_q}<span>*</span></h1>
																<ul className="repu_answer">
																	<li>
																		<input
																			type="text"
																			name={`${cateMap.cate_no}${qustion.repu_no}`}
																			id={`${cateMap.cate_no}${qustion.repu_no}1`}
																			value={qustion.remark}
																			readOnly={true}
																		/>
																	</li>
																</ul>
															</div>
														);
													})}


												</React.Fragment>
											);
										})}
									</form>
								)
							})
						}
					</article>

				</section>
        /* 끝. 받은 평판 있다 */
			}
			
		</>
	)
}

export default ColleRepuRPdf