import React from "react";
import { useParams } from "react-router-dom";
import ColleRepuR2Pdf from 'components/Career/ColleRepuR2Pdf'

const CareerReputationPdf = () => {
	const { userIdx } = useParams();

	return (
		<div className="container">
			<ColleRepuR2Pdf userIdx={userIdx} />
		</div>
	)
}

export default CareerReputationPdf