import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from 'configs/configs';
import Leave from 'html/User/Leave';

import { resetNavSubMenu } from "components/Common/Common";

import Profile from 'components/My/Profile';
import Rsum from 'components/My/Rsum';
import HedingMoney from 'components/My/HedingMoney';
import MyApply from 'components/My/MyApply';
import MyShare from 'components/My/MyShare';
import PopEditProfile from 'components/My/PopEditProfile';
import PopEditPwd from 'components/My/PopEditPwd';

function MyHeding1({ getSubItem }) {
  //로컬스토리지에서 읽기
  const user_idx = localStorage.getItem('mem_idx');
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  console.log("user_idx :", user_idx);
  //팝업창 열기/닫기
  const [varPopup, setPopup] = useState(0);
  //회원탈퇴창 열기/닫기
  const [isLeave, setIsLeave] = useState(false);
  console.log(isLeave);
  //회원 기본 정보
  const [varMemberData, setMemberData] = useState({}); 
  

  //회원정보 불러오기
  useEffect(() => {
    getSubItem(1);
    if(mem_mode=== 0){
      resetNavSubMenu(4,0);
    }else{
      resetNavSubMenu(2,0);
    }
    
    // console.log('user_idx  : ',user_idx);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    axios.post(`${CONFIG.SERVER_HOST}/_get_member_profile`,params).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          setMemberData(res.data.results[0]);
        } else {
          setMemberData({});
        }
    });  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[varPopup]); 

  //팝업창 조정 함수 : num=3:비밀번호인증/1:프로필 수정하기/2:비밀번호 변경
  const onSetPopup = (e,num) => {
    //e.preventDefault();
    setPopup(num);
  }
  //회원탈퇴페이지
  const onSetIsLeave = (e,bpop) => {
    e.preventDefault();
    setIsLeave(bpop);
  }
  
  return isLeave ?  <Leave onSetIsLeave={onSetIsLeave} />
    : 
    <>
        <div className='container page_my_info'>
            <div className='box'>
                <Profile onSetPopup={onSetPopup} varMemberData={varMemberData} setMemberData={setMemberData} />  {/*프로필*/}
                <div className="my_profile_right">
                  <Rsum /> {/*이력서*/}
                  <HedingMoney /> {/*헤딩머니*/}
                </div>
                <MyApply /> {/*채용 지원 현황*/}
                <MyShare /> {/*추천 현황*/} 
            </div>
            <button className="btn_leave" onClick={(e)=>onSetIsLeave(e,true)}>회원탈퇴</button> 
        </div>
        {/*프로필수정팝업*/}
        <PopEditProfile varPopup={varPopup} onSetPopup={onSetPopup} varMemberData={varMemberData} setMemberData={setMemberData} setPopup={setPopup} /> 
        <PopEditPwd varPopup={varPopup} onSetPopup={onSetPopup} setPopup={setPopup} /> 
    </>
}

export default MyHeding1