/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../../../configs/configs";
import axios from "axios";
import moment from "moment";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import Paging from "../../../components/Layout/Paging";

import arrImage from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";

import "admin/css/board.css";

function BoardList() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  //검색부-------------------------------------------------------------------------------------------
  const [startDate, setStartDate] = useState(""); //date picker 날짜
  const [endDate, setEndDate] = useState(""); //date picker 날짜
  const [varSearch, setSearch] = useState("all");
  const [varSearchInput, setSearchInput] = useState("");

  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  const [varMode, setVarMode] = useState(0);

  //목록표시부-------------------------------------------------------------------------------------------
  const [varBoardList, setBoardList] = useState([]);
  const [varCheckedList, setCheckedList] = useState([]);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //게시판 정보 전체 가져오기
  function readBoardList(iMode, cPage) {
    // console.log(iMode,cPage )

    let sendData = {
      "mode" : iMode, 
      "currentPage" : cPage,
      "pageSize" : varPageSize,
    }

    if (iMode) {
      sendData = {
        "mode" : iMode, 
        "currentPage" : cPage,
        "pageSize" : varPageSize,
        "sdate" : moment(startDate).format(CONFIG.formatStr),
        "edate" : moment(endDate).format(CONFIG.formatStr),
        "search_option" : varSearch,
        "search_string" : varSearchInput
      }
    }

    axios.post(`${CONFIG.SERVER_HOST}/boardlist`, sendData, CONFIG.header).then((res) => {
      console.log(res.data)
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        const pmax = res.data.totalcount - (varPageNum - 1) * varPageSize;
        const datas = res.data.results.map((data, i) => ({
          board_idx: data.board_idx,
          order: data.board_read === 1 ? "[필독]" : pmax - i,
          board_title: data.board_title,
          mem_idx: data.mem_idx,
          mem_name: data.mem_name,
          board_rdate: data.board_rdate,
          board_hits: data.board_hits,
        }));
        setBoardList(datas);
      } else {
        setTotalCount(0);
        setBoardList([]);
        window.alert("검색된 정보가 없습니다.");
      }
    });
  }

  //페이지가 바뀌면
  useEffect(() => {
    readBoardList(varMode, varPageNum);
  }, [varPageNum]);

  //검색-------------------------------------------------------------------------------------------
  const handleType = (e) => {
    setSearch(e.currentTarget.value);
  };
  const handleInput = (e) => {
    setSearchInput(e.currentTarget.value);
  };
  const handleStartDate = (e) => {
    setStartDate(e.currentTarget.value);
  };
  const handleEndDate = (e) => {
    setEndDate(e.currentTarget.value);
  };

  //초기화
  const handleSearchClear = () => {
    setStartDate("");
    setEndDate("");
    setSearch("all");
    setSearchInput("");

    setVarMode(0);
    setPageNum(1);
    readBoardList(0, 1);
  };
  //검색
  const handleSearchBoardList = () => {
    setVarMode(1);
    setPageNum(1);
    readBoardList(1, 1);
  };

  //전체 선택
  const onCheckedAll = (e) => {
    if (e.currentTarget.checked) {
      const checkedListArray = [];
      varBoardList.forEach((board) => checkedListArray.push(board));
      setCheckedList(checkedListArray);
    } else {
      setCheckedList([]);
    }
  };

  //개별선택
  const onCheckedElement = (e, board) => {
    if (e.currentTarget.checked) {
      setCheckedList([...varCheckedList, board]);
    } else {
      setCheckedList(varCheckedList.filter((el) => el !== board));
    }
    console.log(varCheckedList);
  };

  //선택 항목 삭제
  const deleteBoardList = (e) => {
    const delitems = varCheckedList.length;
    if (delitems === 0) {
      window.alert("삭제할 정보가 선택되어 있지 않습니다.");
      return;
    }
    if (!window.confirm(delitems + "개의 선택된 정보를 삭제합니까?")) return;

    //console.log(varCheckedList);
    const params = new URLSearchParams();
    params.append("delitems", JSON.stringify(varCheckedList));
    axios.post(`${CONFIG.SERVER_HOST}/board_delete`, params).then((res) => {
      if (res.data.retvalue === 1) {
        readBoardList(varMode, varPageNum);
      } else {
        window.alert(res.data.message);
      }
    });
  };

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="boardlist" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          공지사항 관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arrImage} alt="" />
            게시판 관리
            <img src={arrImage} alt="" />
            공지사항 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <div className="search-box">
              <div className="day-box">
                <p>기간검색</p>
                <input
                  type="date"
                  name="startdate"
                  value={startDate}
                  onChange={handleStartDate}
                  id="datePicker"
                />
                ~
                <input
                  type="date"
                  name="enddate"
                  value={endDate}
                  onChange={handleEndDate}
                  id="datePicker2"
                />
              </div>
              <div className="f-right">
                <select
                  style={{ width: "120px", marginRight: "5px" }}
                  name="search"
                  value={varSearch}
                  onChange={handleType}
                >
                  <option value="all">전체</option>
                  <option value="board_title">제목</option>
                </select>
                <input
                  type="text"
                  name="strsearch"
                  value={varSearchInput}
                  onChange={handleInput}
                  style={{ width: "270px", marginRight: "5px" }}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleSearchBoardList(e)}}
                />
                <button onClick={handleSearchBoardList} className="btn">
                  검색
                </button>
                <button onClick={handleSearchClear} className="btn black">
                  초기화
                </button>
              </div>
            </div>
          </div>
          <div className="main-box2">
            <div className="btn-right">
              <Link to="/admin/boarddetail/0" className="btn">
                등록
              </Link>
              <button onClick={deleteBoardList} className="btn">
                삭제
              </button>
            </div>
          </div>
          <div className="main-box ">
            <table className="list">
              <colgroup>
                <col style={{width:"5%"}} />
                <col style={{width:"8%"}} />
                <col style={{width:"49%"}} />
                <col style={{width:"12%"}}/>
                <col style={{width:"10%"}}/>
                <col style={{width:"8%"}}/>
                <col style={{width:"8%"}}/>
              </colgroup>
              <thead>
                <tr>
                  <th className="board-list">
                    <input
                      type="checkbox"
                      onChange={(e) => onCheckedAll(e)}
                      id="deleteItems"
                      checked={
                        varCheckedList.length === 0
                          ? false
                          : varCheckedList.length === varBoardList.length
                          ? true
                          : false
                      }
                    />
                  </th>
                  <th>번호</th>
                  <th>제목</th>
                  <th>작성자</th>
                  <th>작성일자</th>
                  <th>조회수</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {varBoardList.map((board, i) => (
                  <tr key={board.board_idx}>
                    <td className="board-list">
                      <input
                        type="checkbox"
                        id={"deleteItem" + i}
                        onChange={(e) => onCheckedElement(e, board)}
                        checked={varCheckedList.includes(board) ? true : false}
                      />
                    </td>
                    <td>{board.order}</td>
                    <td className="ellipsis">{board.board_title}</td>
                    <td className="ellipsis">{board.mem_idx === 0 ? "관리자" : "부관리자"}</td>
                    <td>
                      {moment(board.board_rdate).format(CONFIG.formatStr)}
                    </td>
                    <td>{board.board_hits}</td>
                    <td>
                      <Link
                        to={`/admin/boarddetail/${board.board_idx}`}
                        className="btn line black"
                      >
                        상세
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <Paging
              totalCount={varTotalCount}
              pageSize={varPageSize}
              curPage={varPageNum}
              setPage={setPageNum}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BoardList;
