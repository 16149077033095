/* eslint-disable jsx-a11y/anchor-is-valid */
// //css

//components
//이미지

/*관심채용관리 탭*/
function MyBookmarkTab({ varTabs,setTabs }) {
  const onSetTabs = (e,index) => {
    e.preventDefault();
    setTabs(index);
  }
  return (
      <div className="Tab">
          <a  className={varTabs===0?"Active":""} onClick={(e)=>onSetTabs(e,0)}>관심 채용 공고 관리</a>
          <a  className={varTabs===1?"Active":""} onClick={(e)=>onSetTabs(e,1)}>최근 본 채용 공고 관리</a>
      </div>
  );
}

export default MyBookmarkTab;
