import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/configs';

//css
import "css/Board.css";
import "css/My.css";

import { resetNavSubMenu } from "../../components/Common/Common";
import MyOfferList from '../../components/My/MyOfferList';
import PopMyOfferView from '../../components/My/PopMyOfferView';

function MyHeding3({ getSubItem }) {
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  //2023-04-22 제안 자동 팝업 때문에... - KSR
  const { crsIdx } = useParams();
  
  useEffect(() => {
    getSubItem(3);
    if (mem_mode === 0) {
      resetNavSubMenu(4, 2);
    } else {
      resetNavSubMenu(2, 2);
    }
    resetNavSubMenu(4, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [varSelectOffer, setSelectOffer] = useState(0);
  const [varPopup, setPopup] = useState(false);
  const [varReloading, setReloading] = useState(true);
  const jobPosting = useRef({ hostMobile: '', title: '', task: '', crsMemIdx: '' });

  const onSetPopup = (e, bpop) => {
    e.preventDefault();
    setPopup(bpop);
  }

  const onChangeSuggestStatus = (e, index) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("crs_idx", varSelectOffer);
    params.append("crs_status", index); // 0:거절  2:수락
    params.append("mem_name", localStorage.getItem('mem_name'));
    params.append("host_mobile", jobPosting.current.hostMobile);
    params.append("em_title", jobPosting.current.title);
    params.append("em_task", jobPosting.current.task);
    params.append("url", `${CONFIG.URL}/Company/ComMessage`);
    params.append("crs_mem_idx", jobPosting.current.crsMemIdx);
    axios.post(`${CONFIG.SERVER_HOST}/_update_suggest_offer`, params).then((res) => {
      // console.log(res.data);
      alert(res.data.message);
      setPopup(false);
      setReloading(true);
    });
  }

  return (
    <div className="container page_suggest">
      <p className="sub_content_text">인사담당자 또는 헤드헌터가 회원님의 대표 이력서를 확인 후 직접 보낸 제안입니다. </p>
      <MyOfferList setSelectOffer={setSelectOffer} onSetPopup={onSetPopup} varReloading={varReloading} setReloading={setReloading} jobPosting={jobPosting} crsIdx={crsIdx} setPopup={setPopup} />
      <PopMyOfferView varPopup={varPopup} onSetPopup={onSetPopup} varSelectOffer={varSelectOffer} onChangeSuggestStatus={onChangeSuggestStatus} jobPosting={jobPosting} /> {/*제안받은내용보기팝업*/}
    </div>
  );
}

export default MyHeding3;