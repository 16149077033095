/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from '../../configs/configs';

//image
import searchIcon from "../../img/sub/search.png";
//component
import Paging from "components/Layout/Paging";
import {usePrevState} from 'components/Common/usePrevState';
import Loading from 'components/Common/Loading';

const ResumeCompanies = ({ varCompanyList,setCompanyList,setChangeLimitList }) => {
  const user_idx = Number(localStorage.getItem('mem_idx'));
  //검색문자열
  const [varSerachingString,setSerachingString] = useState("");

  //pages
  const [varTotalCount,setTotalCount] = useState(0);
  const [varPageSize] = useState(5);
  const [varPageNum, setPageNum] = useState(1);

  const [SPAGE, setSPAGE] = useState(1);
  const [EPAGE, setEPAGE] = useState(10);

  const [varChkSearch, setChkSearch] = useState(false);
  const prevCount = usePrevState(varPageNum);

  const [Load, setLoad] = useState(false);

  //회사검색
  const searchingCompany = async () => {
      await axios
      .get(`${CONFIG.SERVER_HOST}/_search_companies_all/${encodeURIComponent(varSerachingString)}/${SPAGE}/${EPAGE}`)
      .then((res) => {
      if(res.data.retvalue === 1) {

        const results= res.data.results;
        setTotalCount(res.data.totalcount);
        setCompanyList(results);
        setLoad(false);
        if(varChkSearch === true){
          setChkSearch(false);
        }
        
      }
    });
  }
  useEffect(() => {
    //if(varApiLoading === true){
      searchingCompany();
    //}
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SPAGE, EPAGE]);

  useEffect(() => {
      searchingCompany();
  }, [varChkSearch]);

  //표시할 페이지가 바뀌면
  useEffect(() => {
    if(varChkSearch === false){
      if(prevCount !== varPageNum){
        setSPAGE(Number(`${varPageNum === 1?``:varPageNum-1}1`));
        setEPAGE(Number(`${varPageNum}0`));
        setLoad(true);
      }
    }
  }, [varPageNum, varChkSearch]);

  //검색문자입력
  const onChangeSearchString = (e) => {
    setSerachingString(e.currentTarget.value);
  }

  //검색실행
  const onSearchingString = (e) => {
    setPageNum(1);
    setSPAGE(1);
    setEPAGE(10);
    setLoad(true);
    setChkSearch(true);
  }
  //열람제한 기업 추가
  const onAddLimitCompany = async (e,corp_idx,corp_name) => {
    e.preventDefault();

    console.log(corp_idx, corp_name);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("corp_idx", corp_idx);
    params.append("corp_name", corp_name);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_limit_company`, params).then((res) => {
      console.log(res);      
      if(res.data.retvalue === 1) {
        //리미트 재표시
        setChangeLimitList(true);
      }
    });
  }

  return (
    <>
    {Load === true ? <Loading text={'조회중입니다.'} /> : null}
      <section className="list_top">
        <h2 className="sub_content_title">열람 제한 기업</h2>
        <div className="search_wrap">
            <fieldset className="list_search_area">
                <div className="search">
                    <input type="text" value={varSerachingString} placeholder="열람 제한할 회사명을 입력하세요." onChange={(e)=>onChangeSearchString(e)} onKeyPress={(e) => { if (e.key === 'Enter'){onSearchingString()}}}/>
                    <a className="btn_search" onClick={()=>onSearchingString()}><img src={searchIcon} alt=""/></a>
                </div>
            </fieldset>
        </div>
      </section>
      <table className="table_list01">
        <thead></thead>
        <tbody>
        {varCompanyList.length===0?<tr><td className="td_title with_btn">{'검색된 기업이 없습니다.'}</td></tr>:
          varCompanyList.map((company,i) => (
            <tr key={'company_'+i}>
              <td className="td_title with_btn"><div className="ellipsis">{company.TRCO_CMNM} {company.ADDRESS}</div></td>
              <td className="td_button">
                <a href={() => false} className="btn line" onClick={(e)=>onAddLimitCompany(e,company.BIZ_NO,company.TRCO_CMNM)}>열람 제한 추가</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="paging">
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
      </div>
    </>
  );
  
}

export default ResumeCompanies