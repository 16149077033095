/* eslint-disable jsx-a11y/anchor-is-valid */
//데이트피커
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import CONFIG from "configs/configs";
import moment from "moment";
//css
import "react-datepicker/dist/react-datepicker.css";

function NoticeSearch({ varSearchData, setSearchData, onSearchOptionHandler }) {
  const [varStartDate, setStartDate] = useState(
    varSearchData?.sdate === null ? null : varSearchData?.sdate
  );

  const [varEndDate, setEndDate] = useState(
    varSearchData?.edate === null ? null : varSearchData?.edate
  );

  const onChangeDateHandler = (ino, date) => {
    if (date === null || date === "") {
      if (ino === 0) {
        setStartDate(null);
      } else {
        setEndDate(null);
      }
      return;
    }
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    const curDate = year + "-" + month + "-" + day;
    const ndate = new Date(curDate);
    // //console.log(ndate);
    if (ino === 0) {
      setStartDate(ndate);
      setSearchData({ ...varSearchData, sdate: moment(ndate).format(CONFIG.formatStr) })
    } else {
      setEndDate(ndate);
      setSearchData({ ...varSearchData, edate: moment(ndate).format(CONFIG.formatStr) })
    }
  };

  const onSelectHandler = (e) => {
    setSearchData({ ...varSearchData, searchoption: e.target.value });
  }
  const onSearchStringHandler = (e) => {
    setSearchData({ ...varSearchData, searchstring: e.target.value });
  };

  return (
    <div className="list_top">
      <div className="search_wrap">
        {/*지급일자*/}
        <div className="wrap">
          <p className="title_field">작성일</p>
          <DatePicker className="datepicker" dateFormat="yyyy.MM.dd" selected={varStartDate} selectStart onChange={(date) => onChangeDateHandler(0, date)} />
          ~
          <DatePicker className="datepicker" dateFormat="yyyy.MM.dd" selected={varEndDate} selectEnd minDate={varStartDate} onChange={(date) => onChangeDateHandler(1, date)} />
        </div>
        {/*검색*/}
        <div className="list_search_area">
          <select
            className="style_select" onChange={(e) => onSelectHandler(e)}>
            <option value={"all"}>전체</option>
            <option value={"mwt_title"}>제목</option>
            <option value={"mwt_comment"}>내용</option>
          </select>
          <div className="search">
            <input type="text" placeholder="검색어를 입력해주세요" onChange={(e) => onSearchStringHandler(e)} onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearchOptionHandler(e)
              }
            }} />
            {/* <button className="btn_search"><img src={search} alt="search"/></button> */}
          </div>
          <button className="btn" onClick={onSearchOptionHandler}>조회</button>
        </div>
      </div>
    </div>
  );
}

export default NoticeSearch;
