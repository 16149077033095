/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React from "react";

//component
import LanguageStudyHistory from "./LanguageStudyHistory";
//image
import addSchoolImage from "../../img/sub/btn_plus_blue.png";

function School({
	setPopLanguage,
	languageStudyList,
	setLanguageStudyList,
  careerTipList
}) {
	const onHandlerAddLanguageHistory = (e) => {
		e.preventDefault();
		// if (!window.confirm("어학을 추가합니까?")) return;
		setPopLanguage("popup active");
	};

   //어학 작성 TIP
   let copyData = [...careerTipList]
   const tipData = copyData.filter((el) => el.career_order === 3)


	return (
		<section className="section">
			<div className="title_area has_guide_secret">
        <h3 className="title"> 어학</h3>
        <button
					className="btn txt"
					onClick={(e) => onHandlerAddLanguageHistory(e)}
				>
					<img src={addSchoolImage} alt="" />
					추가
				</button>
        {/*작성팁*/}
        <div className="guide">
          <p className="guide_btn"><strong className="tit">작성 TIP</strong><span className="icon" ></span></p>
          <div className="guide_secret">
            <p className="guide_secret_tit">{tipData[0]?.career_tip_title}</p>
            <div className="guide_secret_txt text-lines">{tipData[0]?.career_tip_content}</div>
          </div>
        </div>
      </div>
			{languageStudyList
				? languageStudyList.map((language, index) => (
					<LanguageStudyHistory
						key={"scharray_" + index}
						index={index}
						language={language}
						languageStudyList={languageStudyList}
						setLanguageStudyList={setLanguageStudyList}
					/>
				))
				: null}
		</section>
	);
}

export default School;
