/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CONFIG from 'configs/configs';

function Token() {
  const { mem_idx, Token } = useParams();


  useEffect(() => {
    try {
      const sendData = {
        'mem_idx': mem_idx,
        'Token': Token
      };
      
      axios.post(`https://heding-api.awsome-app.kr:8007/api/fcm_save`,sendData, CONFIG.header ).then((res) => {
        console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <></>
   
  );
}

export default Token;
