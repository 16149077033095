/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React from "react";
import deleteImage02 from "../../img/sub/btn_delete02.png";

function Content({ data, varEmploymentPreference, setEmploymentPreference, varCompEmploymentPreference }) {

  const setSelectedEmploymentPreference = (e) => {
    const { value } = e.target;
    setEmploymentPreference(
      varEmploymentPreference.map(ep =>
        ep.idx === data?.idx ? {
          ...ep,
          vcep_idx: Number(value)
        } : ep
      ));
  }


  const onChangeContent = (e, idx) => {
    const { value } = e.target;
    setEmploymentPreference(
      varEmploymentPreference.map(ep =>
        ep.idx === Number(idx) ? {
          ...ep,
          content: value
        } : ep
      ));
  };

  const onDeleteContent = (idx) => {
    let z = 0;

    const newArr = [];
    const NewList = varEmploymentPreference.filter((item) => item.idx !== idx);
    NewList.map((item, i) => {
      newArr.push({ idx: z, content: item.content, vcep_idx: item.vcep_idx });
      z += 1;
    });


    setEmploymentPreference(newArr);
  }

  return (

    <div className="input_area">
      <select className={data.vcep_idx ? 'input_part bg_on' : ''} onChange={setSelectedEmploymentPreference} value={data.vcep_idx}>
        <option value={0}>선택</option>
        {varCompEmploymentPreference.length ? varCompEmploymentPreference.map((data, i) => (
          <option key={"workd" + i} value={data.vcep_idx} >{data.vcep_content}</option>
        )) : null}
      </select>
      <input
        type="text"
        className={"input_part3"+(data.content ? ' bg_on' : '')}
        name="varContent"
        value={data.content}
        placeholder="우대사항 내용을 입력해주세요."
        onChange={(e) => onChangeContent(e, data.idx)}
      />
      <button className="btn_del" onClick={() => onDeleteContent(data.idx)} style={{ cursor: "pointer" }}>
        <img src={deleteImage02} alt="" />
      </button>
    </div>
  );
}

export default Content;
