/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs";
import { addThousandPoint, changeMobileFormat, changeMobileNumber, googleSignOut } from "../../../components/Common/Common";
import "admin/css/board.css";
import { GoogleLogout } from 'react-google-login'


function UserDetail() {
  const { user_idx } = useParams();
  const navigate = useNavigate();
  const [varUserData, setUserData] = useState({
    mem_id: "",
    mem_pwd: "",
    mem_name: "",
    mem_gender: 0,
    mem_nickname: "",
    mem_mobile: "",
    mem_email: "",
    mem_type: 0,
    mem_mode: 0,
    mem_ldate: "",
    mem_rdate: "",
    mem_ufile: "",
    mem_expiration: 0,
    change_mem_pwd: "",
  });
  const [varUserAccount, setUserAccount] = useState([]);

  const [prevData, setPrevData] = useState({
    mem_nickname: "",
    mem_email: "",
    heding_money: "",
  });

  const [varUserHmoney, setUserHmoney] = useState([]);
  const [hedingMoney, setHedingMoney] = useState("");
  const [currentHedingMoney, setCurrentHedingMoney] = useState(0);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [MemNicknameChk, setMemNicknameChk] = useState(false);//eslint-disable-line 
  const [MemEmailChk, setMemEmailChk] = useState(false);//eslint-disable-line 
  const [hedingMoneyStatus, setHedingMoneyStatus] = useState(1);
  const prevNickNameRef = useRef();
  const prevMemEmailRef = useRef();
  // const prevNickName = prevNickNameRef.current;
  // const prevMemEmail = prevMemEmailRef.current;

  //회원정보 호출
  useEffect(async () => {
    if (user_idx > 0) {
      const params = new URLSearchParams();
      params.append("mem_idx", user_idx);
      await axios
        .post(`${CONFIG.SERVER_HOST}/getUserDetail`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            if (res.data.members.length > 0) {
              const datam = res.data.members[0];
              setPrevData({
                mem_nickname: datam.mem_nickname,
                mem_email: datam.mem_email,
              })
              //회원정보
              setUserData({
                mem_id: datam.mem_id,
                mem_pwd: datam.mem_pwd,
                mem_name: datam.mem_name,
                mem_gender: datam.mem_gender,
                mem_approval: datam.mem_approval,
                mem_nickname: datam.mem_nickname,
                mem_mobile: changeMobileFormat(datam.mem_mobile),
                mem_email: datam.mem_email,
                mem_type: datam.mem_type,
                mem_mode: datam.mem_mode,
                mem_ldate: datam.mem_edate,
                mem_rdate: datam.mem_rdate,
                mem_ufile: datam.mem_ufile,
                mem_expiration: datam.mem_expiration,
                change_mem_pwd: "",
              });
            }
            //회원계좌정보
            if (res.data.accounts.length > 0) {
              const dataa = res.data.accounts[0];
              setUserAccount([{
                mc_idx: dataa.mc_idx,
                bl_idx: dataa.bl_idx,
                bl_name: dataa.bl_name,
                mc_account: dataa.mc_account,
                mc_owner: dataa.mc_owner,
                mc_balance: dataa.mc_balance,
                mc_rdate: dataa.mc_rdate,
                mc_status: dataa.mc_status,
              }]);
            }
            //회원 헤딩머니
            if (res.data.hedingmoneys.length > 0) {
              setUserHmoney(res.data.hedingmoneys);
            }
          }
        });
    }
  }, []);

  useEffect(() => {
    prevNickNameRef.current = varUserData.mem_nickname;
    prevMemEmailRef.current = varUserData.mem_email;
  })

  //회원 정보 재저장
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (prevData.mem_nickname !== varUserData.mem_nickname && MemNicknameChk === false) {
      alert('닉네임 중복체크를 진행해주세요');
      return false;
    }

    if (prevData.mem_email !== varUserData.mem_email && MemEmailChk === false) {
      alert('이메일 중복체크를 진행해주세요');
      return false;
    }

    const params = new URLSearchParams();
    params.append("mem_idx", user_idx);
    params.append("file", image.data);
    params.append("mem_pwd", varUserData.mem_pwd);
    params.append("mem_gender", varUserData.mem_gender);
    params.append("mem_nickname", varUserData.mem_nickname);
    params.append("mem_mobile", changeMobileNumber(varUserData.mem_mobile));
    params.append("mem_email", varUserData.mem_email);
    params.append("mem_ufile", varUserData.mem_ufile);
    params.append("change_mem_pwd", varUserData.change_mem_pwd);
    params.append("mem_approval", varUserData.mem_approval);


    await axios
      .post(`${CONFIG.SERVER_HOST}/userUpdate`, params)
      .then((res) => {
        alert(res.data.message);
        setPrevData({
          ...prevData,
          mem_nickname: varUserData.mem_nickname,
          mem_email: varUserData.mem_email,
        })
        setMemNicknameChk(false);
        setMemEmailChk(false);
        navigate("/admin/userlist");
      });
  };
  //파일 선택시 정보저장
  const handleFileChange = (e) => {
    const file = e.currentTarget.files[0];

    const formData = new FormData();
    formData.append("user_photo", file);
    axios.post(`${CONFIG.SERVER_HOST}/user_photo`, formData).then((res) => {
      const newFile = res.data.fileName;
      //console.log(newFile);
      if (newFile !== null) {
        //console.log(newFile);
        const img = {
          preview: URL.createObjectURL(file),
          data: newFile,
        };
        //console.log(img);
        setImage(img);
      }
    });


  };
  //회원정보 객체에 저장
  const handleChangeInfos = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  //헤딩머니 핸들러
  const handleHedingMoneyInfos = (e) => {
    const { value } = e.target;
    setHedingMoney(value);
  };


  //헤딩머니 상태 변경
  const handleMhReason = (e) => {
    const { value } = e.target;
    setHedingMoneyStatus(value);
  };

  //닉네임 중복체크 함수
  const handleCheckDuplication = async (e) => {
    if (prevData.mem_nickname === varUserData.mem_nickname && prevData.mem_email === varUserData.mem_email) {
      return false;
    }
    const params = new URLSearchParams();
    params.append("mem_idx", user_idx);
    if (e.target.id === "mem_nickname") {
      params.append("mem_nickname", varUserData.mem_nickname);
    } else {
      params.append("mem_email", varUserData.mem_email);
    }
    await axios
      .post(`${CONFIG.SERVER_HOST}/${e.target.id}`, params)
      .then((res) => {
        if (res.data.retvalue === 1) {
          if (e.target.id === "mem_nickname") {
            setMemNicknameChk(true);
          } else {
            setMemEmailChk(true);
          }
          alert(res.data.message);
        } else if (res.data.retvalue === 3) {
          if (e.target.id === "mem_nickname") {
            setMemNicknameChk(false);
          } else {
            setMemEmailChk(false);
          }
          alert(res.data.message);
        }
      });
  };

  const [varExpireReason, setExpireReason] = useState("");
  const [varPopupExpireReason, setPopupExpireReason] = useState(false);
  //회원탈퇴
  const popupExpiredMember = (bstate) => {
    setPopupExpireReason(bstate);
  };
  const onChangeExpireReason = (e) => {
    setExpireReason(e.currentTarget.value);
  };
  const handleExpiredMember = async (e) => {
    if (varExpireReason === "") {
      alert("먼저 탈퇴 사유를 입력하세요.");
      return false;
    }
    if (window.confirm("현재 회원을 탈퇴시키겠습니까?")) {
      const params = new URLSearchParams();
      params.append("mode", 0);
      params.append("mem_idx", user_idx);
      params.append("reason", varExpireReason);
      await axios
        .post(`${CONFIG.SERVER_HOST}/expiredMember`, params)
        .then((res) => {
          alert(res.data.message);
          if (res.data.retvalue === 1) {
            googleSignOut();
            window.location.href = "/admin/userlist";
          }
        });
    }
  };

  const haneldMoneyChange = async() => {
    if (hedingMoney === "") {
      alert('금액을 입력해주세요');
      return false;
    }

    if(Number(hedingMoneyStatus) === 0){
      if (Number(currentHedingMoney) < Number(hedingMoney)) {
        alert('보유중인 헤딩머니보다 큰 금액입니다');
        return false;
      }
    }

    // if(varUserAccount.length === 0){
    //   alert('등록된 계좌정보가 없습니다.');
    //   return false;
    // }

    const sendData = {
      "mem_idx": user_idx,
      "mh_reason": hedingMoneyStatus,
      "mh_paid": hedingMoney,
      "mh_balance": currentHedingMoney === undefined ||  currentHedingMoney === 0 ? hedingMoney : (Number(hedingMoneyStatus) === 0 ? Number(currentHedingMoney)-Number(hedingMoney) : Number(currentHedingMoney)+Number(hedingMoney) ),
      "bl_idx": varUserAccount[0]?.bl_idx === undefined ? "" : varUserAccount[0].bl_idx,
      "mh_account": varUserAccount[0]?.mc_account === undefined ? "" : varUserAccount[0]?.mc_account,
      "mh_owner": varUserAccount[0]?.mc_owner === undefined ? "" : varUserAccount[0]?.mc_owner
    }

    await axios
      .post(`${CONFIG.SERVER_HOST}/insertHedingMoney`, sendData, CONFIG.header)
      .then((res) => {
        if(res.data.retvalue === 1){
          alert(res.data.message);
          setUserHmoney(res.data.results)
        }else{
          alert(res.data.message);
        }
      });
      setHedingMoney("");
      setHedingMoneyStatus(1);
  }
  
  //보유중인 헤딩머니 가져오기
  useEffect(async() => {
    const params = new URLSearchParams();
    params.append("mem_idx", user_idx);

    await axios
      .post(`${CONFIG.SERVER_HOST}/_get_heding_money`, params)
      .then((res) => {
        if(res.data.retvalue === 1){
          setCurrentHedingMoney(res.data.results[0]?.mh_paid)
        }
      });
  },[varUserHmoney])


  return (
    <React.Fragment>
      <p style={{ marginTop: "20px" }} className="table_title">- 회원 정보</p>
      <div className="main-box">
        <table className="write">
          <thead></thead>
          <tbody>
            <tr>
              <th>아이디</th>
              <td style={{ textAlign: "left" }}>{varUserData.mem_id}</td>
              <th>비밀번호</th>
              <td>
                <input
                  type="password"
                  name="change_mem_pwd"
                  onChange={handleChangeInfos}
                  value={varUserData.change_mem_pwd}
                  className="wd200"
                  maxLength="30"
                />
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mem_name}
              </td>
            </tr>
            <tr>
              <th>프로필 이미지</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData?.mem_ufile !== 'null' && varUserData?.mem_ufile !== null
                  ?
                  <a className="mr20" href={`${CONFIG.SERVER_HOST}/file_down3?file_name=${varUserData.mem_ufile}&file_url=user_photo`} download >{varUserData.mem_ufile}</a>
                  :
                  ""
                }
                <input
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                ></input>
              </td>
            </tr>
            <tr>
              <th>성별</th>
              <td colSpan={3} style={{ textAlign: "left" }}><div className="write-radio">
                <input
                  type="radio"
                  id="m"
                  name="mem_gender"
                  value={0}
                  checked={Number(varUserData.mem_gender) === 0}
                  onChange={handleChangeInfos}
                />
                <label htmlFor="m">남</label>
                <input
                  type="radio"
                  id="w"
                  name="mem_gender"
                  value={1}
                  checked={Number(varUserData.mem_gender) === 1}
                  onChange={handleChangeInfos}
                />
                <label htmlFor="w">여</label>
              </div></td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <input
                  type="text"
                  name="mem_nickname"
                  onChange={handleChangeInfos}
                  value={varUserData.mem_nickname}
                  className="wd200"
                />{prevData.mem_nickname !== varUserData.mem_nickname && <button className="btn" id="mem_nickname" onClick={handleCheckDuplication}>
                  중복체크
                </button>}
              </td>
            </tr>
            <tr>
              <th>휴대폰</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <input
                  type="text"
                  name="mem_mobile"
                  onChange={handleChangeInfos}
                  value={varUserData.mem_mobile}
                  className="wd200"
                />
              </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                <input
                  type="text"
                  name="mem_email"
                  onChange={handleChangeInfos}
                  value={varUserData.mem_email}
                  className="wd300"
                />{prevData.mem_email !== varUserData.mem_email && <button className="btn" id="mem_email" onClick={handleCheckDuplication}>
                  중복체크
                </button>}
              </td>
            </tr>
            <tr>
              <th>가입유형</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mem_type === 0
                  ? "일반"
                  : varUserData.mem_type === 1
                    ? "네이버"
                    : varUserData.mem_type === 2 
                      ? "카카오" : "구글"
                } 
              </td>
            </tr>
            <tr>
              <th>개인정보 유효기간 선택</th>
              <td colSpan={3} style={{ textAlign: "left" }}>
                {varUserData.mem_expiration === 0 ? "회원탈퇴시" : "선택 안함"}
              </td>
            </tr>
            <tr>
              <th>최근 접속일</th>
              <td style={{ textAlign: "left" }}>
                {varUserData.mem_ldate === null ? "" : varUserData.mem_ldate}
              </td>
              <th>가입일자</th>
              <td style={{ textAlign: "left" }}>{varUserData.mem_rdate}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-center" style={{marginBottom:"3%"}}>
        <Link className="btn line" to="/admin/userlist">목록</Link>
        <button className="btn" onClick={(e) => handleSubmit(e)}>수정</button>
        <button className="btn black" onClick={(e) => popupExpiredMember(true)}>회원탈퇴</button>
      </div>

      <div
        className="main-box"
        id="expireMember"
        style={
          varPopupExpireReason ? { display: "block" } : { display: "none" }
        }
      >
        <div style={{ padding: "10px 20px" }}>
          <div className="FieldTitle">탈퇴사유</div>
          <div>
            <textarea
              rows="8"
              placeholder="회원탈퇴 사유를 입력해주세요. (500자 이내)"
              name="reason"
              value={varExpireReason}
              onChange={(e) => onChangeExpireReason(e)}
            ></textarea>
          </div>
          <div className="btn-center">
            <GoogleLogout
              clientId={CONFIG.GoogleClientId}
              buttonText="Logout"
              render={(renderProps) => (
                <button type="submit" onClick={renderProps.onClick}>
                  회원탈퇴
                </button>
              )}
              onLogoutSuccess={handleExpiredMember}
            >
            </GoogleLogout>

            <button onClick={(e) => popupExpiredMember(false)}>취소</button>
          </div>
        </div>
      </div>
      {varUserAccount && varUserAccount.map((data, i) => {
        return (
          <div key={i} style={{marginBottom:"5%"}}>
            <p className="table_title" >- 환전 계좌 정보</p>
            <div className="main-box">
              <table className="write">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>은행명</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {data.bl_name}
                    </td>
                  </tr>
                  <tr>
                    <th>계좌번호</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {data.mc_account}
                    </td>
                  </tr>
                  <tr>
                    <th>예금주</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {data.mc_owner}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )
      })}
        <div>
          <p className="table_title" >- 헤딩머니 정보</p>
          <div className="main-box">
              <table className="write">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>보유중인 헤딩머니</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      {addThousandPoint(currentHedingMoney) + " 원"}
                    </td>
                  </tr>
                  <tr> 
                    <th>헤딩머니 설정</th>
                    <td colSpan={3} style={{ textAlign: "left" }}>
                      <select
                        value={hedingMoneyStatus}
                        onChange={handleMhReason}
                        className="short_select"
                        style={{marginRight:"2%"}}
                      >
                        <option value={"0"}>환전</option>
                        <option value={"1"}>추천인 보상금</option>
                        <option value={"2"}>합격자 보상금</option>
                        <option value={"3"}>HR 담당자 소개 보상금</option>
                        <option value={"4"}>기타 적립금</option>
                        <option value={"5"}>보너스</option>
                      </select>
                      <input type="text" name="heding_money"
                        onChange={(e)=>handleHedingMoneyInfos(e)}
                        value={hedingMoney}
                        className="wd200"
                      />
                      <button className="btn" id="heding_money" onClick={haneldMoneyChange}>
                        머니 수정
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={varUserHmoney.length === 0 ? "main-box2" : "main-box"}>
            <table className="write">
            {varUserHmoney.length !== 0 &&  
              <thead>
                <tr>
                  <th>번호</th>
                  <th>변동일시</th>
                  <th>변동내용</th>
                  <th>변동머니</th>
                  <th>남은머니</th>
                </tr>
              </thead>
            }
            {varUserHmoney && varUserHmoney.map((data, i) => (
              <tbody key={"hmoney" + i}>
                <tr>
                  <td>{varUserHmoney.length - i}</td>
                  <td>{data.mh_rdate}</td>
                  <td>
                    {data.vhs_name}{" "}
                    {data.mh_type === 3 ? "(회수)" : ""}
                    {data.mh_reason !== 0
                      ? ""
                      : data.mh_status === -1
                        ? " (환전취소)"
                        : data.mh_status === 1
                          ? " (환전신청)"
                          : data.mh_status === 2
                            ? " (환전승인완료)"
                            : data.mh_status === 3
                              ? " (환전완료)"
                              : ""}
                  </td>
                  <td>{data.mh_type ===1 ? "+ " : ""}
                  {data.mh_type === 2 || data.mh_type === 3 ? (isNaN(String(data.mh_paid).substring(0,1)) ? String(addThousandPoint(data?.mh_paid))?.replace("-","- ") + " 원" : ""): addThousandPoint(data.mh_paid) + " 원"}</td>
                  <td>{addThousandPoint(data.mh_balance) + " 원"}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
    </React.Fragment>
  );
}

export default UserDetail;
