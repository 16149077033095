/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../configs/configs';
//css

/*희망직무*/
function PopJob({ varPopJob, setPopJob, varInputs, setInputs }) {

    const [varMainJob, setMainJob] = useState('');
    const [varSubJob, setSubJob] = useState('');

    const [varMainJobList, setMainJobList] = useState([]);
    const [varSubJobList, setSubJobList] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams();
        params.append("mode", 0);
        params.append("vwc_idx", 0);
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_task_hope`, params).then((res) => {
            if (res.data.retvalue === 1) {
                const data = res.data.results.map((data, i) => (
                    {
                        vwc_idx: data.vwc_idx,
                        vwc_name: data.vwc_name,
                    }
                ));
                console.log(data);
                setMainJobList(data);
            } else {
                setMainJobList([]);
                //window.alert('검색된 정보가 없습니다.');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //직무선택시
    const onSelectMainJobHandler = (e, vwc_idx, vwc_name) => {
        e.preventDefault();
        //이전에 선택된 지역 --> 선택 취소
        let mainJobsSecter = document.querySelector('.mainlist');
        let mainJobsList = mainJobsSecter.querySelectorAll('.button');
        console.log(mainJobsList);
        for (var i = 0; i < mainJobsList.length; i++) {
            mainJobsList[i].classList.remove('active');
        }
        //선택된 지역 --> 선택 표시
        e.target.className = "button active";
        setMainJob(vwc_name);
        setSubJob('');

        //세부직무 목록 가져오기
        const params = new URLSearchParams();
        params.append("mode", 1);
        params.append("vwc_idx", vwc_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_task_hope`, params).then((res) => {
            if (res.data.retvalue === 1) {
                const data = res.data.results.map((data, i) => (
                    {
                        vwd_idx: data.vwd_idx,
                        vwd_name: data.vwd_name,
                    }
                ));
                console.log(data);
                setSubJobList(data);
            } else {
                setSubJobList([]);
                //window.alert('검색된 정보가 없습니다.');
            }
        });
    }
    //세부직무선택시
    const onSelectSubJobHandler = (e, vwd_name) => {
        e.preventDefault();
        //이전에 선택된 지역 --> 선택 취소
        let subJobsSecter = document.querySelector('.sublist');
        let subJobsList = subJobsSecter.querySelectorAll('.button');
        for (var i = 0; i < subJobsList.length; i++) {
            subJobsList[i].classList.remove('active');
        }
        //선택된 지역 --> 선택 표시
        e.target.className = "button active";
        setSubJob(vwd_name);
    }
    //직무선택후 추가버튼 
    const onSelectedPopJobHandler = (e) => {
        e.preventDefault();
        var newone = varInputs.task_hope;
        if (newone.indexOf(varSubJob) === -1) {
            if (newone === "") {
                newone = varMainJob + '>' + varSubJob;
            } else {
                newone += ',' + varMainJob + '>' + varSubJob;
            }
            console.log(newone);
            setInputs({ ...varInputs, task_hope: newone });
            console.log(varInputs);

            let mainJobsSecter = document.querySelector('.mainlist');
            let mainJobsList = mainJobsSecter.querySelectorAll('.button');
            for (var i = 0; i < mainJobsList.length; i++) {
                mainJobsList[i].classList.remove('active');
            }

            let subJobsSecter = document.querySelector('.sublist');
            let subJobsList = subJobsSecter.querySelectorAll('.button');
            for (var z = 0; z < subJobsList.length; z++) {
                subJobsList[z].classList.remove('active');
            }

            setMainJob('');
            setSubJob('');
            setSubJobList([]);
            setPopJob('popup');
        } else {
            if(varSubJob !== ''){
                alert('중복선택은 불가합니다.');
            }else{
                alert('직무를 선택해 주세요.');
            }
        }
    }

    //팝업창 닫기
    const onClosePopJobHandler = e => {
        e.preventDefault();
        let mainJobsSecter = document.querySelector('.mainlist');
        let mainJobsList = mainJobsSecter.querySelectorAll('.button');
        for (var i = 0; i < mainJobsList.length; i++) {
            mainJobsList[i].classList.remove('active');
        }

        let subJobsSecter = document.querySelector('.sublist');
        let subJobsList = subJobsSecter.querySelectorAll('.button');
        for (var z = 0; z < subJobsList.length; z++) {
            subJobsList[z].classList.remove('active');
        }

        setMainJob('');
        setSubJob('');
        setSubJobList([]);
        setPopJob('popup');
    }

    return (
        <section className={varPopJob}>
            <article className="popup_wrap popup01">
                <div className="title_wrap">
                    <h1>희망 직무 추가</h1>
                </div>
                <div className="content_wrap">
                    <div className="select_box col2">
                        <div className="wrap scroll mainlist">
                            {varMainJobList.map((jobs, index) => (
                                <button key={'pj' + index} onClick={(e) => onSelectMainJobHandler(e, jobs.vwc_idx, jobs.vwc_name)} className="button">{jobs.vwc_name}</button>
                            ))}
                        </div>
                        <div className="wrap scroll sublist">
                            {varSubJobList.map((sjobs, index) => (
                                <a key={'sj' + index} onClick={(e) => onSelectSubJobHandler(e, sjobs.vwd_name)} className="button">{sjobs.vwd_name}</a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="button_wrap">
                    <div className="big_button_wrap one">
                        <button onClick={(e) => onSelectedPopJobHandler(e)} className="btn">추가</button>
                    </div>
                    <div className="close_button_wrap">
                        <button onClick={(e) => onClosePopJobHandler(e)}>닫기 X</button>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default PopJob;
