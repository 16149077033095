/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect }from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

function FaqSeriousList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    //목록표시부-------------------------------------------------------------------------------------------
    const [faqSeriousList, setFaqSeriousList] = useState([]);
    const [varCheckedList, setCheckedList] = useState([]);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function readFaqSeriousList() {
        axios.post(`${CONFIG.SERVER_HOST}/faqseriouslist`).then((res) => {
            console.log(res.data);
            if(res.data.retvalue === 1) {
                //console.log(res.data);
                const data = res.data.results.map((data,i)=>(                
                    {  
                        vfs_idx: data.vfs_idx,
                        vfs_mode:data.vfs_mode,
                        vfs_name:data.vfs_name,
                        vfs_index:data.vfs_index,
                        vfs_use:data.vfs_use
                    }
                ))
                setFaqSeriousList(data);
            } else {
                setFaqSeriousList([]);
                window.alert('검색된 정보가 없습니다.');
            } 
        });
    }
    
    //페이지 호출시 게시판 목록 가져오기 --- 최초 1 번 실행
    useEffect(() => {
        readFaqSeriousList();
    },[]);
    
    //전체 선택
    const onCheckedAll = (e) => {
        if (e.currentTarget.checked) {
            const checkedListArray = [];
            faqSeriousList.forEach((board) => checkedListArray.push(board));
            setCheckedList(checkedListArray);
        } else {
            setCheckedList([]);
        }
    }

    const onCheckedElement = (e,board) => {
        if (e.currentTarget.checked) {
            setCheckedList([...varCheckedList, board]);
        } else {
            setCheckedList(varCheckedList.filter((el) => el !== board));
        }
        console.log(varCheckedList);
    }

    //선택 항목 삭제
    const deleteFaqSeriousList = (e) =>{
        const delitems = varCheckedList.length;
        if(delitems === 0) {
            window.alert('삭제할 정보가 선택되어 있지 않습니다.'); return;
        }
        if(!window.confirm(delitems+'개의 선택된 정보를 삭제합니까?')) return;

        //console.log(varCheckedList);
        const params = new URLSearchParams();
        params.append("delitems",JSON.stringify(varCheckedList));
        axios.post(`${CONFIG.SERVER_HOST}/faqserious_delete`,params).then((res) => {
            if(res.data.retvalue === 1) {
                readFaqSeriousList();              
            } else {
                window.alert('오류가 발생했습니다.');
            }
        });
    }

    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="board"/>
        <Menu menuType="board" menu="faqseriouslist"/>
        <div id="contents" className="ad_contents">
            <div className="tit">FAQ 유형 관리
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>FAQ 관리<img src={arrImage} alt=""/>FAQ 유형 관리
                </div>
            </div>
            <div className="content">      
                <div className="main-box2">
                    <div className="btn-right">
                        <Link to="/admin/faqseriousdetail/0" className="btn">분류추가</Link>
                        <button  onClick={deleteFaqSeriousList} className="btn">삭제</button>
                    </div>
                </div>                    
                <div className="main-box">       
                    <table className="list">
                        <colgroup>
                            <col style={{width:"4%"}} />
                            <col style={{width:"15%"}} />
                            <col style={{width:"15%"}} />
                            <col style={{width:"15%"}}/>
                            <col style={{width:"43%"}}/>
                            <col style={{width:"8%"}}/>
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="board-list">
                                <input type="checkbox" onChange={(e)=>onCheckedAll(e)} id="deleteItems"
                                checked={varCheckedList.length===0?false:varCheckedList.length===faqSeriousList.length?true:false} />
                            </th>
                            <th>순서</th>
                            <th>분류코드</th>
                            <th>사용여부</th>
                            <th>분류명</th>
                            <th>관리</th>
                          </tr>
                        </thead>
                        <tbody>
                            {faqSeriousList.map((board,i) => (
                                <tr key={board.vfs_idx}>
                                    <td className="board-list">
                                      <input type="checkbox" id={"deleteItem"+i}
                                      onChange={(e)=>onCheckedElement(e,board)} 
                                      checked={varCheckedList.includes(board)?true:false}/>
                                    </td>
                                    <td><p>{board.vfs_index}</p></td>
                                    <td><p>{board.vfs_mode}</p></td>
                                    <td><p>{board.vfs_use===1 ? 'Y' : 'N'}</p></td>
                                    <td className="ellipsis"><p>{board.vfs_name}</p></td>
                                    <td><Link to={`/admin/faqseriousdetail/${board.vfs_idx}`} className="btn line black">상세</Link></td>
                                </tr>
                            ))}
                        </tbody> 
                    </table>
                </div>
            </div>        
        </div>
        </React.Fragment>
    )
}

export default FaqSeriousList;