import React from "react";
import { Link } from "react-router-dom";
import { em_titleLength, numberToKorean2 } from "components/Common/Common";

/*채용 지원 현황*/
function MyRecommendList({ varTabs, varRecommendList }) {

    return (
        <>
            {/* 2022-11-07 루트로 뺌 - KSR
        <div className="list_top">
            <div className="search_wrap">
                <MyRecommendSearch varSearchData={varSearchData} setSearchData={setSearchData} filteringRecommendData={filteringRecommendData} varTabs={varTabs}/>
            </div>
        </div>
        */}
            <table className="table_list01">
                <colgroup><col style={{ 'width': '28%' }} /><col style={{ 'width': '20%' }} /><col /><col style={{ 'width': '12%' }} /><col /><col /><col /><col /></colgroup>
                <thead>
                    <tr>
                        <th className="th_title">채용제목</th>
                        <th>회사명</th>
                        <th>직무</th>
                        <th>보상금</th>
                        <th>{varTabs === 0 ? "후보자" : "추천인"}</th>
                        <th className="th_date">추천일자</th>
                        <th className="th_date">지원일자</th>
                        <th>진행상태</th>
                    </tr>
                </thead>
                <tbody>
                    {varRecommendList?.length > 0 ? varRecommendList?.map((recommends, i) => (
                        <tr key={"recommend_" + i}>
                            <td className="td_title with_btn">
                                <Link to={`/MyRecruit/MainView/${recommends.er_em_idx}/${recommends.em_headhunter}`}>{recommends.em_title ? recommends.em_title : "-"}</Link>
                            </td>
                            <td className="td_info">
                                <span className="field_tit">회사명 : </span>
                                {recommends?.mcm_name ? em_titleLength(recommends.mcm_name, 12, "...") : "-"}
                            </td>
                            <td className="td_info">
                                <span className="field_tit">직무 : </span>
                                {recommends?.vwd_name ? em_titleLength(recommends.vwd_name, 9, "...") :
                                    recommends?.vwc_name ? em_titleLength(recommends.vwc_name, 9, "...") : '-'}
                            </td>
                            <td className="td_info">
                                <span className="field_tit">보상금 : </span>
                                {recommends?.em_reward_applicant ? numberToKorean2(recommends?.em_reward_applicant) + "원" : "-"}
                            </td>
                            <td className="td_info">
                                <span className="field_tit">{varTabs === 0 ? "후보자 : " : "추천 인 : "}</span>
                                {varTabs === 0 ? recommends?.er_name ? recommends?.er_name : "-"
                                    :
                                    recommends?.recomm_name ? recommends?.recomm_name : "-"
                                }
                            </td>
                            <td className="td_info">
                                <span className="field_tit">추천일자 : </span>{recommends?.er_rdate ? recommends?.er_rdate : "-"}
                            </td>
                            <td className="td_info last">
                                <span className="field_tit">지원일자 : </span>{recommends?.ea_rdate ? recommends?.ea_rdate : "-"}
                            </td>
                            <td className="td_state">
                                <span className={recommends?.ea_idx ? "state3" : "state1"}>
                                    {recommends.ea_idx ? recommends.ems_name : '미지원'}
                                </span>
                            </td>
                        </tr>
                    ))
                        :
                        <tr>
                            <td className="no_data" colSpan={8}>불러올 데이터가 없습니다.</td>
                        </tr>
                    }

                </tbody>
            </table>
        </>
    );
}

export default MyRecommendList;
