/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import CONFIG from "configs/configs";

function FAQTab({ varCurrentTab, onSetChangeHandler }) {

  const [faqTitle, setFaqTitle] = useState([]);

  useEffect(()=> {
    axios
    .post(`${CONFIG.SERVER_HOST}/faqTabList`)
    .then((res) => {
      if (res.data.retvalue === 1){
        //console.log(res.data.results)
        setFaqTitle(res.data.results)
      }else {
        console.log(res.data.message)
      }
    });
  }, [])


  return (
    <ul className="tab01">
        {faqTitle.map((el, i) => (
           <li className={varCurrentTab=== el.vfs_idx?'active':''} key={el.vfs_idx} onClick={e=>onSetChangeHandler(e ,el.vfs_idx)}><a>{el.vfs_name}</a></li>
        ))}
    </ul>
  )
}

export default FAQTab
