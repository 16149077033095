import React, { useState } from "react";

/*이용권 구매>결제> 동의*/
function Agree({ varConditionData, varPaymentInfo, setPaymentInfo }) {
    //팝업창 열기/닫기
    const [varConditionOpen, setConditionOpen] = useState(false);
    //팝업창 표시정보 (0:이용약관/1:개인정보처리방침)
    const [varConditionComment, setConditionComment] = useState('');
     //전체선택
  const [allChecked, setIsAllChecked] = useState(false);

    const onSetConditionOpen = (e,inum) => {
      e.preventDefault();
      if(inum === 0) {  //이용약관
        setConditionComment(varConditionData.conditions);
      } else {
        setConditionComment(varConditionData.privacy);
      }
      setConditionOpen(true);
    }

    // const onChangeAllChecked = (e) => {
    //     const bchecked = e.currentTarget.checked;
    //     const ichecked = Number(bchecked);
    //     setPaymentInfo({...varPaymentInfo,or_agree_info:ichecked,or_agree_allow:ichecked});
    // }

    // const onChangeChekcBox = (e,ino) => {
    //     const bchecked = e.currentTarget.checked;
    //     const ichecked = Number(bchecked);
    //     if(ino === 1) {
    //         setPaymentInfo({...varPaymentInfo,or_agree_info:ichecked});
    //     } else {
    //         setPaymentInfo({...varPaymentInfo,or_agree_allow:ichecked});
    //     }
    // }

  //전체버튼 클릭시
  const checkAllHandler = (e) => {
    console.log(e.target.checked);
    const bchecked = e.target.checked;
    // console.log("============ ",bchecked);
    setIsAllChecked(bchecked);
    if (bchecked) {
      setPaymentInfo({ ...varPaymentInfo, agree_chk1: 1, agree_chk2: 1});
    } else {
      setPaymentInfo({ ...varPaymentInfo, agree_chk1: 0, agree_chk2: 0 });
    }
  };

  //동의 처리
  const agreeHandler = (e) => {
    const { name, value } = e.target; 
    console.log("name ",name, "value ",value)  /* eslint-disable-line no-unused-vars */
    const bchecked = e.target.checked;
    if (bchecked) {
      setPaymentInfo({ ...varPaymentInfo, [name]: 1 });
    } else {
      setPaymentInfo({ ...varPaymentInfo, [name]: 0 });
    }

    //전체체크박스 조정
    const checkAgrees = document.querySelectorAll('.agree');
    let icount = 0;
    // console.log("111111111 ",checkAgrees.length)
    if(bchecked === false){
      setIsAllChecked(false);
    }else{
      for(let i=0; i < checkAgrees.length; i++) {
        if(checkAgrees[i].checked) icount++;
      }
      if(icount === checkAgrees.length) {
        setIsAllChecked(true);
      }
    }
  };
  return (
  <>
    <h2 className="sub_content_title">
      <input type="checkbox" className="chk" name="checkall" id="agree_all_chk" onChange={(e)=>checkAllHandler(e)} checked={allChecked}/>
      <label htmlFor="agree_all_chk"> 전체동의</label>
    </h2>
    <ul className="agree_check">
      <li>
        <input type="checkbox" className="chk agree" name="agree_chk1" id="agree_chk1" checked={varPaymentInfo.agree_chk1} onChange={(e)=>agreeHandler(e)}/>
        <label htmlFor="agree_chk1"> 가격, 내용 등 서비스에 대한 안내를 확인하였으며, 이에 동의합니다.
          <a href={() => false} className="button" onClick={(e)=>onSetConditionOpen(e,0)}>[이용약관]</a>
        </label>
      </li>
      <li>
        <input type="checkbox" className="chk agree" name="agree_chk2" id="agree_chk2" checked={varPaymentInfo.agree_chk2} onChange={(e)=>agreeHandler(e)}/>
        <label htmlFor="agree_chk2"> 개인정보 수집 및 이용에 동의합니다.
          <a href={() => false} className="button" onClick={(e)=>onSetConditionOpen(e,1)}>[개인정보처리방침]</a>
        </label>
      </li>
    </ul>

    {/*이용약관/개인정보취급방침 */}
    <section className={`popup${varConditionOpen?" active" : ""}`}>
      <article className="popup_wrap popup01">
        <div className="content_wrap">
          <div className="tems_box scroll" dangerouslySetInnerHTML={{__html:varConditionComment}}></div>
        </div>
        <div className="button_wrap">
          <div className="big_button_wrap one">
            <a href={() => false} className="btn" onClick={() => setConditionOpen(false)}>확인</a>
          </div>
          <div className="close_button_wrap">
            <a href={() => false} onClick={() => setConditionOpen(false)}>닫기 X</a>
          </div>
        </div>
      </article>
    </section>
  </>
  );
}

export default Agree;
