/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

/*****************************************************************************
 * 2022-11-19 uri 에서 이력서 번호만 변경해도 타인꺼 보임, 관련하여 처리 - KSR
 *****************************************************************************/
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {useForceUpdate} from 'components/Common/useForceUpdate';
import axios from 'axios';
import CONFIG from '../../configs/configs';

//css
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/MyResume.css';
import '../../css/Davehan.css';

//components
import ResumPersonal from '../../components/Resume/Personal';
import ResumSchool from '../../components/Resume/School';
import ResumCareer from '../../components/Resume/Career';
import ResumAddCareer from '../../components/Resume/AddCareer';
import ResumLanguageStudy from '../../components/Resume/LanguageStudy';
import ResumCertificate from '../../components/Resume/Certificate';
import ResumAwards from '../../components/Resume/Awards';
import ResumCareerDescription from '../../components/Resume/CareerDescription';
import ResumIntroduce from '../../components/Resume/Introduce';
import ResumPortfolio from '../../components/Resume/Portfolio';
import ResumWorkingCondition from '../../components/Resume/WorkingCondition';
import ResumeAside from '../../components/Resume/ResumeAside';
/* import PopNotice from '../../components/Resume/PopNotice'; */
import PopSchool from '../../components/Resume/PopSchool';
import PopJob from '../../components/Resume/PopJob';
import PopTown from '../../components/Resume/PopTown';
import PopCompany from '../../components/Resume/PopCompany';
import PopLanguage from '../../components/Resume/PopLanguage';
import PopCertify from '../../components/PopUp/PopCertify';
import PopAccessRestrictions from '../../components/PopUp/PopAccessRestrictions';
import Loading from 'components/Common/Loading';

import { resetNavSubMenu, resetNavMenu, ChangeDateTimeFormat, changeMobileFormat, file_upload, file_upload1, move_file_to } from "../../components/Common/Common";

//이미지
//데이트피커
import "react-datepicker/dist/react-datepicker.css";

import profileImage from '../../img/sub/profile.gif';
import deleteImage from '../../img/sub/icon_del.png';

function ResumeView({ PageFolder }) {
  const user_idx = Number(localStorage.getItem('mem_idx'));
  //const location = useLocation();
  const { re_idx } = useParams();
  const curResumeIndex = Number(re_idx);
  const navigator = useNavigate();

  const forceUpdate = useForceUpdate();
  const photoPath = `${CONFIG.SERVER_HOST}/resume/`;
  const today = new Date();
  const lastYear = today.getMonth() <= 5 ?  today.getFullYear() - 2 : today.getFullYear() - 1;
  const [InsertIndex, setInsertIndex] = useState(0); //이력서 저장 성공 인덱스 번호
  const [varSaveMode, setSaveMode] = useState(0); //0:원본, 1:임시저장
  const [varShowResume, setShowResume] = useState(false);  //대표이력서 검색 가능유무

  const [varPopup, setPopup] = useState(false); //근로소득, 인증경력 팝업창 출력 유무
  const [Type, setType] = useState(0);

  //2022-11-19 이력서 소유자 여부 - KSR
  const [isMine, setIsMine] = useState(false);

  //2022-11-30 이력서 갯수 저장 state - khs
  const [listCount, setListCount] = useState(0);

  //2022-12-13 이력서 저장, 수정 로딩페이지 state - khs
  const [loadPage, setLoadPage] = useState(false);
  const [loadPage2, setLoadPage2] = useState(false);
  const [LoadText, setLoadText] = useState('');

  useEffect(() => {
    resetNavMenu(1);
    resetNavSubMenu(1, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [re_idx]);


  const [varAPI, setAPI] = useState(false);
  //POPUP 표시
  /*     const [varPopNotice,setPopNotice] = useState("popup"); */
  const [varPopSchool, setPopSchool] = useState("popup");
  const [varPopJob, setPopJob] = useState("popup");
  const [varPopTown, setPopTown] = useState("popup");
  const [varPopCompany, setPopCompany] = useState("popup");
  const [varPopLanguage, setPopLanguage] = useState("popup");
  const [varPopAccessRestrictions, setPopAccessRestrictions] = useState("popup"); //열람 제한 기업 팝업

  //신입여부
  const [varCheckNoWork, setCheckNoWork] = useState(false);
  const [varCheckNoAddWork, setCheckNoAddWork] = useState(true);

  //개인정보---------
  //회원사진
  const [varPhotoFile, setPhotoFile] = useState('');
  //직책
  const [varPosition, setPosition] = useState([]);

  //회원사진
  const photoFile = new FormData();
  photoFile.append('file', '');
  const [curUploadFile, setUploadFile] = useState(photoFile);

  //학력등급
  const [schoolLevelList, setSchoolLevelList] = useState([]);
  //학력목록
  const [resumeSchoolList, setResumeSchoolList] = useState([]);
  const [varSchoolIndex, setSchoolIndex] = useState(0);
  //경력목록 - 인증
  const [resumeCareerList, setResumeCareerList] = useState([]);
  //경력목록 - 추가
  const [resumeAddCareerList, setResumeAddCareerList] = useState([]);

  //어학 목록 
  const [languageStudyList, setLanguageStudyList] = useState([]);
  //자격증 목록 
  const [certificateList, setCertificateList] = useState([]);
  //자격증 목록 
  const [awardList, setAwardsList] = useState([]);
  //경력기술서
  const [resumeCareerDescription, setResumeCareerDescription] = useState('');
  //자기소개서
  const [resumeSelfDocument, setResumeSelfDocument] = useState('');
  //포트폴리오 목록
  const [resumePortfolioList, setResumePortfolioList] = useState([]);
  const [resumeTmpPortfolioList, setResumeTmpPortfolioList] = useState([]);
  const [resumePortfolioFiles, setResumePortfolioFiles] = useState([]);

  // 취업 우대사항 목록
  const [varEmploymentPreference, setEmploymentPreference] = useState([]);

  const [SaeobjaMyeongching, setSaeobjaMyeongching] =useState('');
  const varPageSize = 10;
  const [varPageNum,setPageNum] = useState(1);
  const [careerTipList, setCareerTipList] = useState([]);

  //회원정보
  const myBasicInfo = {
    title: '',
    code: '',
    id: 0,
    name: '',
    gender: 0,
    birthday: null,
    mobile: '',
    income_lyear: lastYear,
    income_last: 0,
    income_this: 0,
    income_hope: 0,
    after_meet: 0,
    employ_hope: 0,
    place_hope: '',
    task_hope: '',
    fix: 0,
    lookup: 0,
    rdate: ChangeDateTimeFormat(new Date()),
    perfection: 0,
    career_year: 0.0,
    careeradd_year: 0.0,
    company_hope: '',
    email1: '',
    email2: '',
    site: '',
    re_ufile: '',
    mem_ufile: '',
    re_photo: 0,
    move_hope: 1,
    certification_experience_year: 0,
    certification_experience_date: 0,
    additional_career_year: 0,
    additional_career_date: 0
  };
  const [varInputs, setInputs] = useState(myBasicInfo);

  //완성도 {/*0% => diagram / 25% => + step1 / 50% => + step2 / 75% => + step3 / 100% => + step4*/}
  const [varPerfection, setPerfection] = useState({
    personal: 0,
    education: 0,
    career: 0,
    selfdoc: 0,
    classes: 'diagram',
    completed: 0
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getResumeInformation = async () => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("re_idx", re_idx === 0 ? 0 : re_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_my_information`, params).then((res) => {
      console.log(res.data);
      if (res.data?.retvalue === 1) {
        //회원가입 후 조회한 근로소득
        const mei_income = res.data.mei_income[0].mei_income;

        //이력서 갯수 카운트
        const count = res.data.count;
        setListCount(count);
        if(count <= 1){
          setShowResume(true);
        }

        //직책목록
        setPosition(res.data?.positions);
        //이력서정보
        const mydata = res.data?.results[0];

        //2022-11-19 이력서 소유자 확인 - KSR
        if(mydata?.mem_idx === user_idx){
          setIsMine(true);
        }else{
          setIsMine(false);
          alert("접속 경로에 오류가 있습니다.\n경로를 확인하세요.");
          window.history.back(-1);
        }

        //email 점검 - 회원정보
        let emailArray = ['', '']
        if (mydata?.mem_email.indexOf('@') !== -1) {
          emailArray = mydata?.mem_email.split('@');
        }
        if (re_idx > 0) {
          //개인정보 설정
          var myInfo = {
            title: mydata?.re_title,
            code: mydata?.re_code,
            id: mydata?.re_idx,
            name: mydata?.mem_name,
            gender: mydata?.re_gender,
            birthday: mydata?.re_birthday === null || mydata?.re_birthday === "" ? mydata?.mem_birthday : mydata?.re_birthday,
            mobile: changeMobileFormat(mydata?.mem_mobile),
            income_lyear: mydata?.re_income_lyear === null ? lastYear :  today.getMonth() <= 5 ?  today.getFullYear() - 2 : mydata?.re_income_lyear,
            income_last: mei_income === 0 ? mydata?.re_income_last === undefined ? 0 : mydata?.re_income_last : mei_income,
            income_this: mydata?.re_income_this,
            income_hope: mydata?.re_income_hope,
            after_meet: mydata?.re_after_meet,
            employ_hope: mydata?.re_employ_hope === null ? '' : mydata?.re_employ_hope,
            place_hope: mydata?.re_place_hope === null ? '' : mydata?.re_place_hope,
            task_hope: mydata?.re_task_hope === null ? '' : mydata?.re_task_hope,
            fix: mydata?.re_fix,
            lookup: mydata?.re_lookup,
            rdate: mydata?.re_rdate,
            perfection: mydata?.re_perfection,
            career_year: mydata?.re_career_year,
            careeradd_year: mydata?.re_careeradd_year,
            company_hope: mydata?.re_company_hope === null ? '' : mydata?.re_company_hope,
            email1: emailArray[0],
            email2: emailArray[1],
            site: mydata?.re_site === null || mydata?.re_site === 'null' ? null : mydata?.re_site,
            re_ufile: mydata?.re_ufile,
            mem_ufile: mydata?.mem_ufile,
            re_photo: mydata?.re_photo,
            move_hope: Number(mydata?.re_move_hope)
          };
          if(count > 1){
            setShowResume(mydata?.re_show === 0 ? false : true);
          }
          
          //어학
          setLanguageStudyList(res.data?.language_study);
          //자격증
          //resume_certificate
          setCertificateList(res.data?.resume_certificate);

          //경력 기술서
          setResumeCareerDescription(mydata?.re_career_description);
          //자기소개서
          setResumeSelfDocument(mydata?.re_comment);
          //수상 경력
          const datac = res.data?.awards.map((data, i) => (
            {
              idx: i + 1,
              ra_comment: data.ra_comment,
              ra_gdate: data.ra_gdate,
              ra_issuer: data.ra_issuer
            }
          ));
          setAwardsList(datac);
          //희망연봉 & 면접후 결정에 따른 설정
          // const incomeHope = document.getElementById('income_hope');
          // if (mydata?.re_after_meet === 1) {
          //   incomeHope?.setAttribute('style', 'background-color:#eee;pointer-events:none;');
          // } else {
          //   incomeHope?.setAttribute('style', 'background-color:#fff;');
          // }
          setInputs(myInfo);

          //개인사진;
          //console.log(mydata?.re_photo);
          if (mydata?.re_photo === 1 && mydata?.re_ufile !== "") {
            setPhotoFile(photoPath + mydata?.re_ufile);
          } else {
            setPhotoFile(profileImage);
          }

          //완성도---
          if (res.data?.perfections?.length) {
            const perfectionData = res.data?.perfections[0];

            var personal = perfectionData?.rpc_selfinfo;
            var education = perfectionData?.rpc_school;
            var career = perfectionData?.rpc_career;
            var selfdoc = perfectionData?.rpc_selfdoc;
            var completed = 0

            if (personal === 1) completed += 25;
            if (education === 1) completed += 25;
            if (career === 1) completed += 25;
            if (selfdoc === 1) completed += 25;
            //0% => diagram / 25% => + step1 / 50% => + step2 / 75% => + step3 / 100% => + step4
            var slasses = 'diagram';
            if (completed > 0) slasses = 'diagram step' + parseInt(completed / 25);

            //완성도---
            setPerfection({
              personal: personal,
              education: education,
              career: career,
              selfdoc: selfdoc,
              classes: slasses,
              completed: completed
            });
          }

          //저장된 경력
          console.log(res.data?.career);
          if (res.data?.career?.length) {
            const datac = res.data?.career.map((data, i) => (
              {
                idx: i + 1,
                rec_idx: data.rec_idx,
                rec_company: data.rec_company,
                rec_department: data.rec_department,
                rec_position: data.rec_position,
                rec_sdate: data.rec_sdate,
                rec_edate: (data.rec_edate === null) ? null : data.rec_edate,
                rec_state: data.rec_state,
                rec_certification: 0,
                rec_descript: [
                  { id: 0, 'note': data.rec_note1, 'detail': data.rec_detail1 },
                  { id: 1, 'note': data.rec_note2, 'detail': data.rec_detail2 },
                  { id: 2, 'note': data.rec_note3, 'detail': data.rec_detail3 },
                  { id: 3, 'note': data.rec_note4, 'detail': data.rec_detail4 },
                  { id: 4, 'note': data.rec_note5, 'detail': data.rec_detail5 }]
              }
            ));
            setResumeCareerList(datac);
            setCheckNoWork(false);
            setCheckNoAddWork(false);
          }
          //저장된 추가경력
          if (res.data?.careeradd?.length) {
            const dataa = res.data?.careeradd.map((data, i) => (
              {
                idx: i + 1,
                rac_idx: data.rac_idx,
                rac_company: data.rac_company,
                rac_department: data.rac_department,
                rac_position: data.rac_position,
                rac_sdate: data.rac_sdate,
                rac_edate: (data.rac_edate === null) ? null : data.rac_edate,
                rac_state: data.rac_state,
                rac_certification: 0,
                rac_status: data.rac_status,
                rac_descript: [{ id: 0, 'note': data.rac_note1, 'detail': data.rac_detail1 }
                  , { id: 1, 'note': data.rac_note2, 'detail': data.rac_detail2 }
                  , { id: 2, 'note': data.rac_note3, 'detail': data.rac_detail3 }
                  , { id: 3, 'note': data.rac_note4, 'detail': data.rac_detail4 }
                  , { id: 4, 'note': data.rac_note5, 'detail': data.rac_detail5 }]
              }
            ));
            setResumeAddCareerList(dataa);
          }
          //학력
          if (res.data?.education?.length) {
            console.log('res.data?.education !!!!', res.data?.education);
            const datas = res.data?.education.map((data, i) => (
              {
                idx: i + 1,
                reh_idx: data.reh_idx,
                reh_level: data.reh_level,
                reh_name: data.reh_name,
                reh_major: data.reh_major,
                reh_sdate: data.reh_sdate,
                reh_edate: data.reh_edate,
                reh_state: data.reh_state,
                reh_status: data.reh_status
              }
            ));
            setResumeSchoolList(datas);
          }

          if(res.data?.employment_preference.length){
            const datas = res.data?.employment_preference.map((data, i) => (
              {
                idx : i , vcep_idx : data.vcep_idx, content: data.content
              }
            ));
            setEmploymentPreference(datas);

          }
        } else {
          //이력서가 없어 개인정보만 가져온 경우
          const myInfoOnly = {
            ...myBasicInfo,
            name: mydata?.mem_name,
            gender: mydata?.mem_gender,
            birthday: mydata?.mem_birthday,
            mobile: changeMobileFormat(mydata?.mem_mobile),
            email1: emailArray[0],
            email2: emailArray[1],
            income_last: mei_income === 0 ? mydata?.re_income_last === undefined ? 0 : mydata?.re_income_last : mei_income,
            income_this: 0,
            fix : Number(count) === 0 ? 1 : mydata?.re_fix ? mydata?.re_fix : 0,
          };
          setInputs(myInfoOnly);
          if (varPerfection.personal !== 1) {
            const completed = varPerfection.completed + 25;
            const slasses = 'diagram step' + parseInt(completed / 25);

            setPerfection({
              ...varPerfection,
              personal: 1,
              classes: slasses,
              completed: completed
            })
          }
          //직책목록
          setPosition(res.data?.positions);
          //저장된 경력
          if (res.data?.career?.length) {

            const datac = res.data?.career.map((data, i) => (
              {
                idx: i + 1,
                rec_idx: data.rec_idx,
                rec_company: data.rec_company,
                rec_department: data.rec_department,
                rec_position: data.rec_position,
                rec_sdate: data.rec_sdate,
                rec_edate: (data.rec_edate === null) ? null : data.rec_edate,
                rec_state: data.rec_state,
                rec_certification: 0,

                rec_descript: [
                  { id: 0, 'note': data.rec_note1, 'detail': data.rec_detail1 },
                  { id: 1, 'note': data.rec_note2, 'detail': data.rec_detail2 },
                  { id: 2, 'note': data.rec_note3, 'detail': data.rec_detail3 },
                  { id: 3, 'note': data.rec_note4, 'detail': data.rec_detail4 },
                  { id: 4, 'note': data.rec_note5, 'detail': data.rec_detail5 }]
              }
            ));


            setResumeCareerList(datac);
            setCheckNoWork(false);
            setCheckNoAddWork(false);
          }
          //저장된 추가경력
          if (res.data?.careeradd?.length) {
            const dataa = res.data?.careeradd.map((data, i) => (
              {
                idx: i + 1,
                rac_idx: data.rac_idx,
                rac_company: data.rac_company,
                rac_department: data.rac_department,
                rac_position: data.rac_position,
                rac_sdate: data.rac_sdate,
                rac_edate: (data.rac_edate === null) ? null : data.rac_edate,
                rac_state: data.rac_state,
                rac_certification: 0,
                rac_descript: [{ id: 0, 'note': data.rac_note1, 'detail': data.rac_detail1 }
                  , { id: 1, 'note': data.rac_note2, 'detail': data.rac_detail2 }
                  , { id: 2, 'note': data.rac_note3, 'detail': data.rac_detail3 }
                  , { id: 3, 'note': data.rac_note4, 'detail': data.rac_detail4 }
                  , { id: 4, 'note': data.rac_note5, 'detail': data.rac_detail5 }]
              }
            ));
            setResumeAddCareerList(dataa);
          }
          //학력
          if (res.data?.education?.length) {
            const datas = res.data?.education.map((data, i) => (
              {
                reh_idx: i + 1,
                reh_level: data.reh_level,
                reh_name: data.reh_name,
                reh_major: data.reh_major,
                reh_sdate: data.reh_sdate,
                reh_edate: data.reh_edate,
                reh_state: data.reh_state
              }
            ));
            setResumeSchoolList(datas);
          }
        }
      } else {

        setPhotoFile(profileImage);

      }
      // var newPortFile = new FormData();
      // newPortFile.append('idx', 0);
      // newPortFile.append('filename', '');
      // newPortFile.append('selectFile', null);
      
      const newArr1 = []; 
      const PortFile = {id:0, file:null, filename:null, show:true};
      // const newArr = newArr1.concat(PortFile);

      // console.log(newArr);

      setResumePortfolioFiles([...resumePortfolioFiles, PortFile]);
    });

    setAPI(true);

  };
  //console.log(varInputs);

  useEffect(() => {
    if (varAPI === false) {
      console.log('처음 실행!!!!');
      getResumeInformation();
    }
  }, []);

  useEffect(()=>{
    if(varPopup === false && SaeobjaMyeongching !== ''){
      setPopAccessRestrictions('popup active');
    }
  }, [SaeobjaMyeongching]);

  //console.log(varInputs);
  const checkInputDatas = () => {
    if (varInputs.title === '') {
      alert('이력서 제목을 입력해주세요');
      document.getElementById('title').focus();
      return false;
    }
    if (varInputs.name === '') {
      alert('인적사항의 이름을 입력해주세요');
      return false;
    }
    if (varInputs.gender === '') {
      alert('인적사항의 성별을 선택해주세요');
      return false;
    }

    if (varInputs.birthday === null || varInputs.birthday === '') {
      alert('인적사항의 생년월일을 선택해주세요');
      return false;
    }
    if (varInputs.mobile === '') {
      alert('인적사항의 연락처를 입력해주세요');
      return false;
    }
    if (varInputs.email1 === '' || varInputs.email2 === '') {
      alert('인적사항의 이메일을 입력해주세요');
      return false;
    }
    if(resumeSelfDocument.length > 5000){
      alert('자기소개서 내용이 5000자를 초과합니다.');
      return false;
    }

    return true;
  }



  //이럭서 저장
  const onSaveResume = async (save_status) => {
    if (save_status === 1) { //작성완료일 경우 체크
      if (!checkInputDatas()) return false;
    }

    if (!window.confirm(save_status === 0 ? CONFIG.temporary_saveMessage : CONFIG.saveMessage)) {
      
      return;
    };
   
    setLoadPage(true);
    setTimeout(()=>{
      setLoadText('이력서를 저장중입니다.');
    }, 2000);
  };

  useEffect(()=>{
    const onSaveResume2 = async () => {
      let mem_idx = user_idx;
      if(mem_idx === 0){
        mem_idx = Number(localStorage.getItem('mem_idx'));
      }
      const re_idx = curResumeIndex;
      const sendData = {
        'user_idx': mem_idx,
        're_idx': re_idx,
        're_show': varShowResume === true ? 1 : 0,
        'personal': varInputs,
        'schools': resumeSchoolList,
        'careers': resumeCareerList,
        'addcareers': resumeAddCareerList,
        'recareerdescription' : resumeCareerDescription,
        'selfdoc': resumeSelfDocument,
        'portfolios': resumePortfolioList,
        'language_study': languageStudyList,
        'certificateList': certificateList,
        'awardList': awardList,
        'varCheckNoWork': varCheckNoWork,
        'varCheckNoAddWork': varCheckNoAddWork,
        'varEmploymentPreference' : varEmploymentPreference,
        'save_status': 1
      }
  
      axios.post(`${CONFIG.SERVER_HOST}/_save_resume`, sendData, CONFIG.header).then((res) => {
        if (res.data?.retvalue === 1) {
          var personal = res.data?.rpc_selfinfo;
          var education = res.data?.rpc_school;
          var career = res.data?.rpc_career;
          var selfdoc = res.data?.rpc_selfdoc;
          var completed = res.data?.perfection;
          var insertId = Number(res.data?.insertId);
          var slasses = 'diagram';
          if (completed > 0) slasses = 'diagram step' + parseInt(completed / 25);
          //완성도---
          setPerfection({
            personal: personal,
            education: education,
            career: career,
            selfdoc: selfdoc,
            classes: slasses,
            completed: completed
          });
  
          //이력서 이미지 저장
          if (curUploadFile?.name !== undefined) {
            file_upload1('resume', curUploadFile, insertId, 1)
          }
  
          if (resumePortfolioFiles.length) {
            for (var j = 0; resumePortfolioFiles.length > j; j++) {
              if (resumePortfolioFiles[j].file !== null) {
                file_upload(resumePortfolioFiles[j].file, insertId, 1);
              }
            }
          };
  
          setInsertIndex(insertId);
          setLoadText(res.data?.message);
          navigator(`/MyResume`);
        }
      });
    };
    if(loadPage){
      onSaveResume2();
    }

  },  [loadPage]);


  //이력서 수정
  const onUpdateResume = async (save_status) => {
    if (save_status === 1) {
      if (!checkInputDatas()) return false;
    }
    if (!window.confirm('현재 내용을 저장하시겠습니까?')) {
      return;
    }

    setLoadPage2(true);
    setTimeout(()=>{
      setLoadText('이력서를 수정중입니다.');
    }, 1000);
  };

  useEffect(()=>{
    const onUpdateResume2 = async () => {
  
      const re_idx = InsertIndex !== 0 ? InsertIndex : curResumeIndex;
  
      let mem_idx = user_idx;
  
      if(mem_idx === 0){
        mem_idx = Number(localStorage.getItem('mem_idx'));
      }
  
      const sendData = {
        'user_idx': mem_idx,
        're_idx': re_idx,
        're_show': varShowResume === true ? 1 : 0,
        'personal': varInputs,
        'schools': resumeSchoolList,
        'careers': resumeCareerList,
        'addcareers': resumeAddCareerList,
        'language_study': languageStudyList,
        'certificateList': certificateList,
        'awardList': awardList,
        'recareerdescription' : resumeCareerDescription,
        'selfdoc': resumeSelfDocument,
        'portfolios': resumePortfolioList,
        'varCheckNoWork': varCheckNoWork,
        'varCheckNoAddWork': varCheckNoAddWork,
        'varEmploymentPreference' : varEmploymentPreference,
        'save_status': 1
      }
      console.log('resumePortfolioList :', varInputs);
  
      //2022-12-11 트랜젝션 처리 - KSR
      axios.put(`${CONFIG.SERVER_HOST}/_update_resume2`, sendData, CONFIG.header).then((res) => {
        if (res.data?.retvalue === 1) {
          var personal = res.data?.rpc_selfinfo;
          var education = res.data?.rpc_school;
          var career = res.data?.rpc_career;
          var selfdoc = res.data?.rpc_selfdoc;
          var completed = res.data?.perfection;
  
          var slasses = 'diagram';
          if (completed > 0) slasses = 'diagram step' + parseInt(completed / 25);
  
          //이미지 파일 업로드
          if (curUploadFile?.name !== undefined) {
            console.log('파일저장!!');
            file_upload1('resume', curUploadFile, re_idx, 1)
          }
  
          //포트폴리오 파일 업로드 및 DB 등록--------
          //저장피일을 임시파일로 복사
  
          if (resumePortfolioFiles.length) {
            for (var j = 0; resumePortfolioFiles.length > j; j++) {
              if (resumePortfolioFiles[j].file !== null) {
                file_upload(resumePortfolioFiles[j].file, re_idx, 1);
              }
            }
          }
        }
        setLoadText(res.data?.message);
        navigator(`/MyResume`);
      });
    }

    if(loadPage2){
      onUpdateResume2();
    }
  }, [loadPage2]);

  //이력서 - 삭제
  const onDeleteResume = e => {
    e.preventDefault();
    if (!window.confirm('선택된 이력서를 삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("re_idx", curResumeIndex);
    axios.post(`${CONFIG.SERVER_HOST}/_delete_resume`, params).then((res) => {
      window.alert(res.data?.message);
      navigator(`/MyResume`);
    });
  }

  //개인정보 변경---
  const onChangeHandler = (e) => {

    var { value, name } = e.currentTarget;

    console.log(name, value);
    if (name === "income_last" || name === "income_this" || name === "income_hope") {
      value = value.toString().replace(/[^0-9]/g, "");
      //console.log(svalue);
      //value = addThousandPoint(svalue);
    } else if (name === "mobile") {
      value = changeMobileFormat(value.replace(/-/g, ""));
    } else if (name === "gender") {
      value = Number(value)
      //move_hope
    } else if (name === "move_hope") {
      value = Number(value)
    } else if (name === "after_meet") {
      if (e.currentTarget.checked) value = 1;
      else value = 0;
      //console.log(e.currentTarget.checked);
      if (name === "after_meet") {  //희망연봉 - 면접수 결정
        const incomeHope = document.getElementById('income_hope');
        // if (value === 1) {
        //   incomeHope?.setAttribute('style', 'background-color:#eee;pointer-events:none;');
        // } else {
        //   incomeHope?.setAttribute('style', 'background-color:#fff;');
        // }
      }
    }
    setInputs({ ...varInputs, [name]: value });
    //console.log('value :' + value, 'name :' + name);
  }

  //대표이력서 수정
  const ChangeOfRepresentativeResume = (e) => {
    var { value, name, checked } = e.target;

    if(listCount <= 1){
      alert('이력서가 1개인 경우 대표이력서 설정 변경이 불가합니다.');
      return false;
    }

    if (name === "fix") {
      const sendData = { 'mem_idx': user_idx };
      //대표이력서 체크
      axios.post(`${CONFIG.SERVER_HOST}/countRefix`, sendData, CONFIG.header).then((res) => {

        if (res.data.retvalue === 1) { //대표이력서 존재
          if (!window.confirm(res.data.message)) {
            return false;
          };
          if (re_idx !== 0) {
            const sendData2 = { 'mem_idx': user_idx, 're_idx': curResumeIndex, 're_fix': checked === true ? 1 : 0 };
            //changeRefix 대표이력서 수정 api
            axios.post(`${CONFIG.SERVER_HOST}/changeRefix`, sendData2, CONFIG.header).then((res) => {
              if (res.data.retvalue === 1) { //대표이력서 수정완료
                if (name === "fix") {
                  if (checked) value = 1;
                  else value = 0;
                }
                setInputs({ ...varInputs, [name]: value });

              } else {//대표이력서 변경 실패
                console.log("/data3::", res.data);
              }

            });
          } else {
            if (name === "fix") {
              console.log('e.target.checked :', e.target.checked);
              if (checked) value = 1;
              else value = 0;
            }
            setInputs({ ...varInputs, [name]: value });
          }

        } else if (res.data.retvalue === 2) { //대표이력서 미존재
          if (!window.confirm(res.data.message)) {
            return false;
          };
          if (re_idx !== 0) {
            const sendData2 = { 'mem_idx': user_idx, 're_idx': curResumeIndex, 're_fix': checked === true ? 1 : 0 };

            //changeRefix 대표이력서 수정 api
            axios.post(`${CONFIG.SERVER_HOST}/changeRefix`, sendData2, CONFIG.header).then((res) => {
              if (res.data.retvalue === 1) { //대표이력서 수정완료
                if (name === "fix") {
                  if (checked) value = 1;
                  else value = 0;
                }
                setInputs({ ...varInputs, [name]: value });
              } else {//대표이력서 변경 실패
                console.log("/data3::", res.data);
              }

            });
          } else {
            if (name === "fix") {
              console.log('e.target.checked :', e.target.checked);
              if (checked) value = 1;
              else value = 0;
            }
            setInputs({ ...varInputs, [name]: value });
          }

        } else {//조회 중 오류
          console.log("/data3::", res.data);
        }

      });
    }


  }

  //간편인증 팝업창 열기 함수
  const openPopup = (val)=>{
    setType(val);
    setPopup(true);

  }
  //간편인증 팝업창 닫기 함수
  const closePopup = () => {
    setPopup(!varPopup);
  };

  //인증경력시 신입여부값 변환
  const chkCareerTF = () => {
    setCheckNoWork(false);
    setCheckNoAddWork(false);
  };

  const changePortfolioFiles =(NewList)=>{
    forceUpdate();
    setResumePortfolioFiles(NewList);
  }

  // console.log(varInputs);

  //이력서 TIP 조회
  const getCareerTipList = async () => {
    try {
      await axios
        .post(`${CONFIG.SERVER_HOST}/resume_career_tip_lists`)
        .then((res) => {
          const datas = res.data.results.map((data, i) => ({
            order: i + 1,
            career_idx: data.career_idx,
            career_tip_title: data.career_tip_title,
            career_tip_content: data.career_tip_content,
            career_order: data.career_order,
            career_tip_status: data.career_tip_status,
          }));
          setCareerTipList(datas)
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCareerTipList();
  },[]);

  console.log(varInputs);

  console.log(resumeCareerList);
  return (
    isMine ?
    (Object.keys(varInputs).length === 0 && varInputs.constructor === Object) ? null :
      <>
        <div id="sub_wrap" className="page_myresume_write center_wrap">
          <div className="container">
            <section className="top_area">
              <h2 className="sub_content_title">헤딩 이력서 작성</h2>
              <input type="checkbox" name="fix" id="fixs" className="chk" onChange={e => ChangeOfRepresentativeResume(e)} checked={varInputs.fix === 1 ? true : false} />
              <label htmlFor="fixs">대표 이력서 지정</label>
              <button className="button" onClick={e => onDeleteResume(e)} style={{cursor:"pointer"}}><img src={deleteImage} alt="" />삭제</button>
            </section>
            <div className="myresume_write">
              {/* 개인정보 */}
              <ResumPersonal
                varPhotoFile={varPhotoFile}
                setPhotoFile={setPhotoFile}
                curResumeIndex={curResumeIndex}
                varInputs={varInputs}
                setInputs={setInputs}
                setUploadFile={setUploadFile}
                onChangeHandler={onChangeHandler}
                openfc={openPopup}
              />
              {/* 인증경력 */}
              <ResumCareer
                varSaveMode={varSaveMode}
                curResumeIndex={curResumeIndex}
                setPerfection={setPerfection}
                varPerfection={varPerfection}
                varCheckNoWork={varCheckNoWork}
                setCheckNoWork={setCheckNoWork}
                varInputs={varInputs}
                setInputs={setInputs}
                varPosition={varPosition}
                resumeCareerList={resumeCareerList}
                setResumeCareerList={setResumeCareerList}
                openfc={openPopup}
                careerTipList={careerTipList}
              />
              {/* 추가경력 */}
              <ResumAddCareer
                varSaveMode={varSaveMode}
                curResumeIndex={curResumeIndex}
                varCheckNoAddWork={varCheckNoAddWork}
                setCheckNoAddWork={setCheckNoAddWork}
                varInputs={varInputs} setInputs={setInputs}
                varPosition={varPosition}
                resumeAddCareerList={resumeAddCareerList}
                setResumeAddCareerList={setResumeAddCareerList}
                careerTipList={careerTipList}
              />
              {/* 학력 */}
              <ResumSchool
                varSaveMode={varSaveMode}
                curResumeIndex={curResumeIndex}
                setPerfection={setPerfection}
                varPerfection={varPerfection}
                setSchoolIndex={setSchoolIndex}
                setPopSchool={setPopSchool}
                schoolLevelList={schoolLevelList}
                setSchoolLevelList={setSchoolLevelList}
                resumeSchoolList={resumeSchoolList}
                setResumeSchoolList={setResumeSchoolList}
              />
              {/*어학 */}
              <ResumLanguageStudy
                curResumeIndex={curResumeIndex}
                setPopLanguage={setPopLanguage}
                languageStudyList={languageStudyList}
                setLanguageStudyList={setLanguageStudyList}
                careerTipList={careerTipList}
              />

              {/*자격증 certificateList  */}

              <ResumCertificate
                certificateList={certificateList}
                setCertificateList={setCertificateList}
                careerTipList={careerTipList}
              />

              {/*수상경력 certificateList  */}
              <ResumAwards
                awardList={awardList}
                setAwardsList={setAwardsList}
                careerTipList={careerTipList}
              />
               {/* 경력기술서 */}
              <ResumCareerDescription 
              resumeCareerDescription={resumeCareerDescription}
              setResumeCareerDescription={setResumeCareerDescription}
              careerTipList={careerTipList}
              />

              {/* 자기소개서 */}
              <ResumIntroduce
                varSaveMode={varSaveMode}
                curResumeIndex={curResumeIndex}
                setPerfection={setPerfection}
                varPerfection={varPerfection}
                resumeSelfDocument={resumeSelfDocument}
                setResumeSelfDocument={setResumeSelfDocument}
              />

              {/* 포트폴리오 */}
              <ResumPortfolio
                curResumeIndex={curResumeIndex}
                varInputs={varInputs}
                onChangeHandler={onChangeHandler}
                resumePortfolioList={resumePortfolioList}
                setResumePortfolioList={setResumePortfolioList}
                resumeTmpPortfolioList={resumeTmpPortfolioList}
                setResumeTmpPortfolioList={setResumeTmpPortfolioList}
                resumePortfolioFiles={resumePortfolioFiles}
                setResumePortfolioFiles={setResumePortfolioFiles}
                changePortfolioFiles={changePortfolioFiles}
              />
              {/* 근무희망조건 ~ 취업우대사항까지 */}
              <ResumWorkingCondition
                varInputs={varInputs}
                setInputs={setInputs}
                setPopCompany={setPopCompany}
                setPopJob={setPopJob}
                setPopTown={setPopTown}
                onChangeHandler={onChangeHandler}
                varEmploymentPreference={varEmploymentPreference}
                setEmploymentPreference={setEmploymentPreference}
                careerTipList={careerTipList}
              />
            </div>
          </div>
          {/* 이력서 완성도 */}
          <ResumeAside
            curResumeIndex={curResumeIndex}
            varPerfection={varPerfection}
            InsertIndex={InsertIndex}
            onSaveResume={onSaveResume}
            onUpdateResume={onUpdateResume}
            loadPage={loadPage}
            loadPage2={loadPage2}
          />
        </div>
        <PopSchool
          varSchoolIndex={varSchoolIndex}
          schoolLevelList={schoolLevelList}
          varPopSchool={varPopSchool}
          setPopSchool={setPopSchool}
          resumeSchoolList={resumeSchoolList}
          setResumeSchoolList={setResumeSchoolList}
        />

        {!varPopup ? null :
          <PopCertify
            closePopup={closePopup}
            resumeCareerList={resumeCareerList}
            setResumeCareerList={setResumeCareerList}
            setInputs={setInputs}
            varInputs={varInputs}
            chkCareerTF={chkCareerTF}
            setSaeobjaMyeongching={setSaeobjaMyeongching}
            typ={Type}
          />
        }


        <PopTown
          varPopTown={varPopTown}
          setPopTown={setPopTown}
          varInputs={varInputs}
          setInputs={setInputs}
        />{/*희망근무지추가*/}
        <PopJob varPopJob={varPopJob} setPopJob={setPopJob} varInputs={varInputs} setInputs={setInputs} />{/*희망직무추가*/}
        <PopCompany varPopCompany={varPopCompany} setPopCompany={setPopCompany} varInputs={varInputs} setInputs={setInputs}/>{/*희망회사추가*/}
        <PopLanguage varPopLanguage={varPopLanguage} setPopLanguage={setPopLanguage} languageStudyList={languageStudyList} setLanguageStudyList={setLanguageStudyList} />
        <PopAccessRestrictions popAccessRestrictions={varPopAccessRestrictions} setPopAccessRestrictions={setPopAccessRestrictions} saeobja={SaeobjaMyeongching} />
        {LoadText ?
          <>
            <Loading text={LoadText} />
          </>
          :
          <></>
        }
      </>
    : <></>
  );
}

export default ResumeView;


