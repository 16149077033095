/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import { addThousandPoint, include_num } from '../../../components/Common/Common'

import './Variables.css';

function EmployVariables() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  //심사상태--------------------------------------------------------------------------------------------
  const [varEmployStatus, setVarEmployStatus] = useState([]);
  const [varEmployStatusAdd, setVarEmployStatusAdd] = useState("");
  const [selectedEmployStatus, setSelectedEmployStatus] = useState("");
  //항목선택시
  const handleEmployStatus = (e) => {
    setSelectedEmployStatus(e.target.value);
  };
  //항목입력시
  const handleEmployStatusAdd = (e) => {
    setVarEmployStatusAdd(e.target.value);
  }
  //로그인 여부 확인
  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //최초에 실행
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_employ_status`).then((res) => {
        setVarEmployStatus(res.data);
    });
  },[]);
  //선택항목 삭제
  const deleteEmployStatus = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('ems_idx', selectedEmployStatus);
   /*  const idx = varEmployStatus.filter(data => (data.vhs_name == selectedEmployStatus).map(data => data.vhs_idx)); */
    axios.post(`${CONFIG.SERVER_HOST}/variable_employ_status_delete`,params).then((res) => {
      setVarEmployStatus(res.data);
      setSelectedEmployStatus("");
    }); 
  }
  //선택항목 추가
  const addEmployStatus = (e) => {
    if(varEmployStatusAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('ems_name', varEmployStatusAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_employ_status_add`,params).then((res) => {
      console.log("res.data",res.data);
      if(res.data.retvalue === 1){//성공
        setVarEmployStatusAdd("");
        setVarEmployStatus(res.data.results);
        setSelectedEmployStatus(res.data.newid);
        //alert(res.data.message);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }
  //고용형태--------------------------------------------------------------------------------------------
  const [varEmployType, setVarEmployType] = useState([]);
  const [varEmployTypeAdd, setVarEmployTypeAdd] = useState("");
  const [selectedEmployType, setSelectedEmployType] = useState("");

  const handleEmployType = (e) => {
    setSelectedEmployType(e.target.value);
  };
  const handleEmployTypeAdd = (e) => {
    setVarEmployTypeAdd(e.target.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type`).then((res) => {
        setVarEmployType(res.data);
    });
  },[]);  
  //선택항목 삭제-------------------------------
  const deleteEmployType = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('emt_idx', selectedEmployType);
    axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type_delete`,params).then((res) => {
      setVarEmployType(res.data);
      setSelectedEmployType("");
    }); 
  }
  //선택항목 추가--------------------------------
  const addEmployType = (e) => {
    if(varEmployTypeAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('emt_name', varEmployTypeAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarEmployTypeAdd("");
        setVarEmployType(res.data.results);
        setSelectedEmployType(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }

  //포지션 2022-08-07 KSR ---------------------------------------------------------------------------
  const [varPosionType, setVarPosionType] = useState([]);
  const [varPosionTypeAdd, setVarPosionTypeAdd] = useState("");
  const [selectedPosionType, setSelectedPosionType] = useState("");

  const handlePosionType = (e) => {
    setSelectedPosionType(e.target.value);
  };
  const handlePosionTypeAdd = (e) => {
    setVarPosionTypeAdd(e.target.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_position`).then((res) => {
        setVarPosionType(res.data);
    });
  },[]);  
  //선택항목 삭제-------------------------------
  const deletePosionType = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vps_idx', selectedPosionType);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_position_delete`,params).then((res) => {
      setVarPosionType(res.data);
      setSelectedPosionType("");
    }); 
  }
  //선택항목 추가--------------------------------
  const addPosionType = (e) => {
    if(varPosionTypeAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vps_name', varPosionTypeAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_position_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarPosionTypeAdd("");
        setVarPosionType(res.data.results);
        setSelectedPosionType(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }

  //헤딩머니지급사유--------------------------------------------------------------------------------------------
  const [varHMSerious, setVarHMSerious] = useState([]);
  const [varHMSeriousAdd, setVarHMSeriousAdd] = useState([]);
  const [selectedHMSerious, setSelectedHMSerious] = useState("");
  const handleHMSerious = (e) => {
    setSelectedHMSerious(e.target.value);
  };
  const handleHMSeriousAdd = (e) => {
    setVarHMSeriousAdd(e.target.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_hm_serious`).then((res) => {
        setVarHMSerious(res.data);
    });
  },[]); 
  //선택항목 삭제
  const deleteHMSerious = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vhs_idx', selectedHMSerious);
    axios.post(`${CONFIG.SERVER_HOST}/variable_hm_serious_delete`,params).then((res) => {
      setVarHMSerious(res.data);
      setSelectedHMSerious("");
    }); 
  }
  //선택항목 추가
  const addHMSerious = (e) => {
    if(varHMSeriousAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vhs_name', varHMSeriousAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_hm_serious_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarHMSeriousAdd("");
        setVarHMSerious(res.data.results);
        setSelectedHMSerious(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }
  //합격자보상금선택항목관리--------------------------------------------------------------------------------------------
  // const [varRewardEmployed, setVarRewardEmployed] = useState([]);
  // const [varRewardEmployedAdd, setVarRewardEmployedAdd] = useState([]);
  // const [selectedRewardEmployed, setSelectedRewardEmployed] = useState("");
  // const handleRewardEmployed = (e) => {
  //   setSelectedRewardEmployed(e.target.value);
  // };
  // const handleRewardEmployedAdd = (e) => {
  //   const num = e.currentTarget.value.replace(/,/g,'');
  //   if(include_num(num)){
  //     alert('숫자만 입력해주세요.');
  //     setSelectedRewardEmployed("");
  //     return;
  //   }
  //   setVarRewardEmployedAdd(num);
  // }
  // useEffect(() => {
  //   axios.post(`${CONFIG.SERVER_HOST}/variable_reward_employed`).then((res) => {
  //       setVarRewardEmployed(res.data);
  //   });
  // },[]); 
  //선택항목 삭제
  // const deleteRewardEmployed = (e) => {
  //   if(!window.confirm('삭제하시겠습니까?')) return;
  //   const params = new URLSearchParams();
  //   params.append('vre_idx', selectedRewardEmployed);
  //   axios.post(`${CONFIG.SERVER_HOST}/variable_reward_employed_delete`,params).then((res) => {
  //     setVarRewardEmployed(res.data);
  //     setSelectedRewardEmployed("");
  //   }); 
  // }
  //선택항목 추가
  // const addRewardEmployed = (e) => {
  //   if(varRewardEmployedAdd === ""){
  //     alert("항목을 입력하세요.");
  //     return;
  //   }
  //   if(!window.confirm('추가하시겠습니까?')) return;
  //   const params = new URLSearchParams();
  //   params.append('vre_price', varRewardEmployedAdd);
  //   axios.post(`${CONFIG.SERVER_HOST}/variable_reward_employed_add`,params).then((res) => {
  //     if(res.data.retvalue === 1){//성공
  //       setVarRewardEmployedAdd("");
  //       setVarRewardEmployed(res.data.results);
  //       setSelectedRewardEmployed(res.data.newid);
  //     }else{//실패
  //       alert(res.data.message);
  //     }
  //   }); 
  // } 
  //추천인보상금선택항목관리--------------------------------------------------------------------------------------------
  const [varRewardRecommend, setVarRewardRecommend] = useState([]);
  const [varRewardRecommendAdd, setVarRewardRecommendAdd] = useState([]);
  const [selectedRewardRecommend, setSelectedRewardRecommend] = useState("");
  const handleRewardRecommend = (e) => {
    setSelectedRewardRecommend(e.target.value);
  };
  const handleRewardRecommendAdd = (e) => {
    const num = e.currentTarget.value.replace(/,/g,'');
    if(include_num(num)){
      alert('숫자만 입력해주세요.');
      // setSelectedRewardEmployed("");
      return;
    }
    setVarRewardRecommendAdd(num);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_reward_recommend`).then((res) => {
        setVarRewardRecommend(res.data);
    });
  },[]);  
  //선택항목 삭제
  const deleteRewardRecommend = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vrr_idx', selectedRewardRecommend);
    axios.post(`${CONFIG.SERVER_HOST}/variable_reward_recommend_delete`,params).then((res) => {
      setVarRewardRecommend(res.data);
      setSelectedRewardRecommend("");
    }); 
  }
  //선택항목 추가
  const addRewardRecommend = (e) => {
    if(varRewardRecommendAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    //console.log(varRewardRecommendAdd);
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vrr_price', varRewardRecommendAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_reward_recommend_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarRewardRecommendAdd("");
        setVarRewardRecommend(res.data.results);
        setSelectedRewardRecommend(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  } 
  //표시
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="variables" />
      <Menu menuType="variables" menu="employvariables" />
      <div id="contents" className="ad_contents">
        <div className="tit">채용 항목 관리
          <div className="path">
              <img src={iconhome} alt=""/>
              HOME<img src={arrImage} alt=""/>선택항목관리<img src={arrImage} alt=""/>채용 항목 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
              <table className="select-list">
                <tr>
                  <th>심사상태</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleEmployStatus(e)} value={selectedEmployStatus}>
                      { varEmployStatus.length > 0 ? varEmployStatus.map((data,i) => (
                        <option key={'chkm'+i} value={data.ems_idx}>{data.ems_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteEmployStatus(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleEmployStatusAdd} value={varEmployStatusAdd} onKeyDown={(e) => { if (e.key === 'Enter') addEmployStatus(e)}}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addEmployStatus(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                <tr>
                  <th>고용형태</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleEmployType(e)} value={selectedEmployType}>
                      { varEmployType.length > 0 ? varEmployType.map((data,i) => (
                        <option key={'emp'+i} value={data.emt_idx}>{data.emt_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteEmployType(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleEmployTypeAdd} value={varEmployTypeAdd} onKeyDown={(e) => { if (e.key === 'Enter') addEmployType(e)}}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addEmployType(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                <tr>
                  <th>포지션</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handlePosionType(e)} value={selectedPosionType}>
                      { varPosionType.length > 0 ? varPosionType.map((data,i) => (
                        <option key={'vps_'+i} value={data.vps_idx}>{data.vps_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deletePosionType(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handlePosionTypeAdd} value={varPosionTypeAdd} onKeyDown={(e) => { if (e.key === 'Enter') addPosionType(e)}}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addPosionType(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                <tr>
                  <th>헤딩머니지급사유</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleHMSerious(e)} value={selectedHMSerious}>
                      { varHMSerious.length > 0 ? varHMSerious.map((data,i) => (
                        <option key={'hem'+i} value={data.vhs_idx}>{data.vhs_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteHMSerious(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={(e)=>handleHMSeriousAdd(e)} onKeyDown={(e) => { if (e.key === 'Enter') addHMSerious(e)}}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addHMSerious(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                {/* <tr>
                  <th>합격자보상금</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleRewardEmployed(e)} value={selectedRewardEmployed}>
                      { varRewardEmployed.length > 0 ? varRewardEmployed.map((data,i) => (
                        <option key={'appl'+i} value={data.vre_idx}>{addThousandPoint(data.vre_price)}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteRewardEmployed(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleRewardEmployedAdd} value={varRewardEmployedAdd} />
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addRewardEmployed(e)} className="select-btn-add">추가하기</button></td>
                </tr>  */}

                <tr>
                  <th>총보상금</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleRewardRecommend(e)} value={selectedRewardRecommend}>
                      { varEmployStatus ? varRewardRecommend.map((data,i) => (
                        <option key={'recom'+i} value={data.vrr_idx}>{addThousandPoint(data.vrr_price)}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteRewardRecommend(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleRewardRecommendAdd} value={varRewardRecommendAdd} onKeyDown={(e) => { if (e.key === 'Enter') addRewardRecommend(e)}}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addRewardRecommend(e)} className="select-btn-add">추가하기</button></td>
                </tr>
              </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmployVariables;