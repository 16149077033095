/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import SearchButtonImg from '../../img/sub/btn_search2.png';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import axios from 'axios';
//import CONFIG from '../../configs/configs';
import { common_select_function, AppSendIdx} from '../Common/Common';


/*채팅*/ 
function ChatList({ set_Send_Mem_Info, param_idx }) {
    const mem_idx = localStorage.getItem('mem_idx');//2022-04-05 KSR
    const mem_mode = Number(localStorage.getItem('mem_mode')); //회원구분 0 : 일반회원 , 1: 기업회원 , 2: 헤드헌터
    const [Chat_Room_List, set_Chat_Room_List] = useState([]);
    const [Chat_Read, set_Chat_Read] = useState(0);
    const [SearchOption, setSearchOption] = useState({
        search_area : ''
    });

    const navigate = useNavigate();

    const getChatList = async() =>{
        const column = ` * `;
        const table = ` ( SELECT cr.room_idx, cr.last_date, cr.send_idx, cr.propose_idx, 'cr.corp_mem_idx', 'cr.user_mem_idx', c.typ, c.read_yn, c.send_msg, ca.corp_msg, c.chat_idx, c.send_id, c.msg_idx, ${mem_mode === 0 ? 'cm.mcm_name' : 'm.mem_name'}, cs.crs_status, ${mem_mode === 0 ? 'cm.mcm_ufile' : 'm.mem_ufile'} as mem_ufile, m.mem_nickname as your_nickname, m.mem_id as your_mem_id
        FROM chat_room as cr
        left join chat as c on c.room_no = cr.room_idx
        left join member as m on ${mem_mode === 0 ? 'cr.corp_mem_idx' : 'cr.user_mem_idx'} = m.mem_idx
        left join company_suggestion cs on cs.crs_re_idx = cr.propose_idx
        left join chat_auto ca on c.msg_idx = ca.auto_idx 
        ${mem_mode === 0 ? ' left join company cm on cm.com_idx=cr.corp_mem_idx ' : ''}
        `;
        const where = ` cr.${mem_mode === 0 ? 'user_mem_idx' : 'corp_mem_idx'}=${mem_idx} ${SearchOption.search_area === '' ? `` : ` and m.mem_name like '%${SearchOption.search_area}%'` }`;
        const option = ` ORDER BY chat_idx desc
        LIMIT 18446744073709551615
        ) as order_book_desc
            GROUP BY room_idx`;
        await common_select_function(column, table, where, option).then(function (res) {
            const List = res.reverse();
            set_Chat_Room_List(List);
            //회원 idx 앱으로 전송
            AppSendIdx();
            //const lastValue = List[0];
            // if(lastValue !== null){
            //     if(!param_idx){
            //         click_chat_room(
            //             lastValue.room_idx,     //채팅방 idx
            //             lastValue.propose_idx,  //채용공고 idx
            //             lastValue.chat_idx,      //채팅 idx
            //             lastValue.corp_mem_idx,   //회사 회원 idx
            //             lastValue.user_mem_idx,   //일반 회원 idx
            //             lastValue.your_nickname,      //상대방 ID
            //             lastValue.crs_status,
            //             lastValue.mem_ufile        //프로필 사진 2022-10-08 KSR
            //         );
            //     }
            // }
            
        }).catch(function (err) {
            console.error(err); // Error 출력  
        });
    }
    //채팅방 조회 useEffect
    useEffect(async () => {
        getChatList();
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //set_Chat_Read 이벤트 감지 useEffect
    useEffect(() => {
        //Chat_Room_List 에서 변화가 일어난 항목의 값을 변경처리
        set_Chat_Room_List(
            Chat_Room_List?.map(room =>
              room?.room_idx === Chat_Read ? { ...room, read_yn: 'Y' } : room,
            ),
        );
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Chat_Read]);

    //채팅방 클릭 함수
    /* 2022-11-01 KSR
    const click_chat_room = useCallback(async (roomIdx, proposeIdx, chat_idx, corp_mem_idx, user_mem_idx, your_nickname, crs_status,mem_ufile) => {
        const column = `*`;
        const table = ' chat';
        const where = ` send_id !=${mem_idx}`;
        const option = ' ORDER BY chat_idx DESC LIMIT 1';
        await common_select_function(column, table, where, option).then( async(data) => {
            console.log(data); // response 값 출력

            //업데이트 처리 부분 필요
            //chat_idx 현재 클릭한 채팅방의 새로운 메시지의 idx 값
            const param = new URLSearchParams();
            param.append('chat_idx', chat_idx); //chat idx

            //메시지 읽음 처리 api
            await axios.post(`${CONFIG.SERVER_HOST}/_set_chat_read`, param)
            .then((res) => {
               //채팅읽음 처리 성공시 
              if(res.data.retvalue === 1) {
                set_Chat_Read(roomIdx); //채팀방 목록의 N표시 끄기
                set_Send_Mem_Info({
                    send_idx: data[0].send_id, // 상대방 mem_idx
                    room_idx: roomIdx,         // 채팅방 idx
                    propose_idx: proposeIdx,   // 채용공고 idx
                    mem_idx: Number(mem_idx),   //2022-04-05 KSR
                    corp_mem_idx: Number(corp_mem_idx),
                    user_mem_idx: Number(user_mem_idx),
                    your_nickname: your_nickname,
                    crs_status: Number(crs_status),
                    profile_img : mem_ufile     //프로필 이미지 2022-10-08 KSR
                });
              //채팅읽음 처리 성공시 
              }else{
                console.log('채팅읽기 실패');
              }
            });

           
            //send_id
        }).catch(function (err) {
            console.error(err); // Error 출력
        });
    });
    */
   const click_chat_room = (roomIdx) => {
    set_Chat_Read(roomIdx);
    if(mem_mode === 0){
        navigate(`/MyHeding/MyHeding4/${roomIdx}`);
    }else{
        navigate(`/Company/ComMessage/${roomIdx}`);
    }
    
   }

    const onChangeName =(e) =>{
        const {name, value} = e.target;
        setSearchOption({...SearchOption,
                [name] : value
        });
    }   

  //필터링- 검색버튼 클릭시
  const filteringId = () => {
    let copyData = [...Chat_Room_List];
   
    //검색부분
    if (SearchOption.search_area !== "") {
        copyData = copyData.filter((element) =>
          element.your_nickname?.toUpperCase().includes(SearchOption.search_area.toUpperCase())
        );
        set_Chat_Room_List(copyData);
    }else{
      getChatList();
    }
  };

  useEffect(() => {
    filteringId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


    return (
        <div className="chat_list_area">
            <div className="search_area">
                <input type="text" placeholder="아이디를 입력하세요." name="search_area" value={SearchOption.search_area} onChange={(e) => onChangeName(e)} onKeyDown={(e) => { if (e.key === 'Enter') filteringId(e) }}/><button className="button" onClick={()=> filteringId() }><img src={SearchButtonImg} alt="" /></button>
            </div>
            {/* 채팅방 목록 */}
            <section className="chat_list scroll">
            {mem_mode === 0 ? //일반회원
                Chat_Room_List ?
                    Chat_Room_List?.map((room,i) => (
                      <article key={'chat_'+i} className="item" onClick={() => 
                      click_chat_room(room?.room_idx, room?.propose_idx, room?.chat_idx, room?.corp_mem_idx, room?.user_mem_idx, room?.your_nickname, room?.crs_status, room?.mem_ufile)}>
                          <p className="name">
                           {/* {mem_mode === 0 ? room?.mcm_name : room?.your_nickname}  */}
                           {room?.your_mem_id} 
                           {room?.read_yn === 'N' ? <span className="icon_new">N</span> : ""}</p>
                          <p className="date">{room?.last_date}</p>
                          <p className="preveiw">
                              {room?.typ === "AUTO" ? room?.corp_msg : room?.send_msg}
                          </p>
                      </article>
                    )) : null
                ://기업회원
                Chat_Room_List ?
                Chat_Room_List?.map((room,i) => (
                  <article key={'chat_'+i} className="item" onClick={() => 
                    click_chat_room(room?.room_idx, room?.propose_idx, room?.chat_idx, room?.corp_mem_idx, room?.user_mem_idx, room?.your_nickname, room?.crs_status, room?.mem_ufile)}>
                      <p className="name">{room?.your_nickname} {room?.read_yn === 'N' ? <span className="icon_new">N</span> : ""}</p>
                      <p className="date">{room?.last_date}</p>
                      <p className="preveiw">
                        {room?.typ === "AUTO" ? room?.corp_msg : room?.send_msg}
                      </p>
                  </article>
                )) : null   
                }
            </section>
        </div>
    );
}

export default ChatList;