/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {useParams} from 'react-router-dom';

import axios from 'axios';
import CONFIG from '../../../configs/configs';
import Paging from '../../../components/Layout/Paging'

import CertificationCareerView from "./CertificationCareer";
import AddCareerView from "./AddCareer";

function UserRecruitmentsupport({ handleCategory }) {
    const { em_idx } = useParams();

    //페이징
    const [varTotalCount, setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum, setPageNum] = useState(1);

    const [Recruitment, setRecruitment] = useState([]);
    const [varFilterlist, setFilterlist] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    useEffect(async () => {
        console.log("em_idx :", em_idx);
        const params = new URLSearchParams();
        params.append("em_idx", em_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/employ_admin_list`, params).then((res) => {
            console.log(res);
            if(res.data.retvalue === 1 && res.data.results.length > 0) {  
                setTotalCount(res.data.totalcount);
                const data = res.data.results.map((data, i) => (
                    {
                        order: i + 1,
                        mem_idx: data.mem_idx,
                        mem_id: data.mem_id,
                        mem_name: data.mem_name,
                        re_career_year: data.re_career_year,
                        recommed_mem_id: data.recommed_mem_id,
                        ea_checking: data.ea_checking,
                        ems_name: data.ems_name,
                        ea_rdate: data.ea_rdate,
                        ea_idate: data.ea_idate,
                        certification_experience_year: data.certification_experience_year,
                        certification_experience_date: data.certification_experience_date,
                        additional_career_year: data.additional_career_year,
                        additional_career_date: data.additional_career_date
    
                    }
                ));
                setRecruitment(data);
                setFilterlist(data);
            }else{ 
            }
        });
    }, []);

    console.log(Recruitment)

    //페이지별 표시
    const getPageLists = () => {
        const startNum = (varPageNum - 1) * varPageSize;
        const endNum = startNum + varPageSize;
        const copyData = varFilterlist.slice(startNum, endNum);
        setPagelist(copyData);
    }

    useEffect(() => {
        getPageLists();
    }, [varPageNum, varFilterlist]);

    // const Recruitment_list = Recruitment.filter((user) => {
    //     if (startDate !== "" || searchInput !== "") {
    //         if (startDate !== "" && endDate === "") {
    //             return moment(user.mem_r_date).format(CONFIG.formatStr) === moment(startDate).format(CONFIG.formatStr);

    //         } else if (startDate !== "" && endDate !== "") {
    //             return moment(user.mem_r_date).format(CONFIG.formatStr) >= moment(startDate).format(CONFIG.formatStr) && moment(user.mem_r_date).format(CONFIG.formatStr) <= moment(endDate).format(CONFIG.formatStr);
    //         } else if (search !== "") {

    //             if (search === "district") {
    //                 return user.mem_name.includes(searchInput);
    //             } else {
    //                 return user.mem_name.includes(searchInput) + user.mem_id.includes(searchInput);
    //             }

    //         }

    //     } else {
    //         return Recruitment;
    //     }
    // });

    //   미열람 unread 
    //   검토중 under review
    //   서류합격 documents
    //   면접합격 interview
    //   최종합격 pass
    //   불합격 fail
    //   입사 join
    //   조기퇴사 early retirement

    const unread = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === 0) {
            return true
        }
    });
    const review = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === 1) {
            return true
        }
    });
    const documents = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === 2) {
            return true
        }
    });
    const interview = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === 3) {
            return true
        }
    });
    const pass = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === 4) {
            return true
        }
    });
    const fail = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === -2) {
            return true
        }
    });
    const join = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === 5) {
            return true
        }
    });
    const early = varPagelist.filter((recruitment) => {
        if (recruitment.ea_checking === -3) {
            return true
        }
    });

    console.log(join.length);
    return (
        <React.Fragment>

        <p className="table_title" style={{marginTop:"60px"}}>- 지원자 정보</p>
        <div className="main-box" >
            <table className="list bg_gray">
                <colgroup>
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                    <col style={{width:"11.11%"}} />
                </colgroup>
                <thead>
                    <th>총지원자</th>
                    <th>미열람</th>
                    <th>검토중</th>
                    <th>서류합격</th>
                    <th>면접합격</th>
                    <th>최종합격</th>
                    <th>불합격</th>
                    <th>입사</th>
                    <th>조기퇴사</th>
                </thead>
                <tbody>
                        <tr>
                            <td><p>{Recruitment.length}</p></td>
                            <td><p>{unread.length}</p></td>
                            <td><p>{review.length}</p></td>
                            <td><p>{documents.length}</p></td>
                            <td><p>{interview.length}</p></td>
                            <td><p>{pass.length}</p></td>
                            <td><p>{fail.length}</p></td>
                            <td><p>{join.length}</p></td>
                            <td><p>{early.length}</p></td>
                        </tr>
                </tbody>
            </table>
        </div>
        <div className="main-box ">

            <form name="f" method="post">
                <input type="hidden" name="typ" value="" />
                <input type="hidden" name="delchk" value="0" />


                <table className="list">
                    <colgroup>
                        <col style={{width:"8%"}} />
                        <col style={{width:"14%"}} />
                        <col style={{width:"14%"}} />
                        <col style={{width:"14%"}} />
                        <col style={{width:"14%"}} />
                        <col style={{width:"8%"}} />
                        <col style={{width:"10%"}} />
                        <col style={{width:"10%"}} />
                        <col style={{width:"8%"}} />
                    </colgroup>
                    <thead>
                        <th>번호</th>
                        <th>아이디</th>
                        <th>지원자 이름</th>
                        <th>총 경력</th>
                        <th>추천인</th>
                        <th>심사상태</th>
                        <th>지원일자</th>
                        <th>입사일자</th>
                        <th>관리</th>
                    </thead>
                    <tbody>
                        {varPagelist.map((resume, i) => (
                            <tr key={i}>
                                <td><p>{resume.order}</p></td>
                                <td className="ellipsis"><p>{resume.mem_id}</p></td>
                                <td className="ellipsis"><p>{resume.mem_name}</p></td>
                                {/* <td className="ellipsis"><p>{resume.re_career_year}</p></td> */}
                                <td className="ellipsis"><p>{
                      !resume?.certification_experience_year && !resume?.certification_experience_year ?
                        <AddCareerView highLookup={resume} />
                        :
                        <CertificationCareerView highLookup={resume} />
                    }</p></td>
                                <td className="ellipsis"><p>{resume.recommed_mem_id}</p></td>
                                <td className="ellipsis"><p>{resume.ems_name}</p></td>
                                <td className="ellipsis"><p>{resume.ea_rdate}</p></td>
                                <td className="ellipsis"><p>{resume.ea_idate}</p></td>
                                <td><p><a className="btn line black" href={`/admin/userinfo/${resume.mem_idx}`} >관리</a></p></td> 
                            </tr>
                        ))}
                    </tbody>
                </table>
            </form>
            </div>
            <div>
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                {/* <Pagination
                    itemsCount={Recruitment.length}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPgaeChange={onPgaeChange}
                    start={start}
                    end={end}
                    handleMovePage={handleMovePage}
                /> */}
            </div>


        </React.Fragment>
    )

}

export default UserRecruitmentsupport;