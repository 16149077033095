import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { Link } from "react-router-dom";

//image
import blackStarImage from "../../img/sub/detail_star.png";
import whiteStarImage from "../../img/sub/detail_star2.png";
import blackEyeImage from "../../img/sub/detail_eye.png";
import whiteEysImage from "../../img/sub/detail_eye2.png";

const RecruitViewInfo = ({ HedingPage,varEmployInfo }) => {
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const user_idx = localStorage.getItem("mem_idx");
  const [varInterestEmploy, setInterestEmploy] = useState(0);
  const [varLookupEmploy, setLookupEmploy] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    axios.post(`${CONFIG.SERVER_HOST}/_count_access_employ`, params).then((res) => {
        if (res.data.retvalue === 1) {
          //console.log(res.data.interests[0].tcount);
          setInterestEmploy(res.data.interests[0].tcount);
          setLookupEmploy(res.data.lookups[0].tcount);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
     {mem_mode === 0 ? 
      <ul className="right_wrap">
      {/* 관심기업 */}
     
        <li>
          <div className="icon_wrap star">
            <img className="view_pc" src={varEmployInfo.em_headhunter === 0 ? blackStarImage : whiteStarImage} 
              /* srcSet={varHeadHunter===0?
                "../../img/sub/detail_star_m.png 1x, ../../img/sub/detail_star_m@2x.png 2x"
              :
                "../../img/sub/detail_star_m2.png 1x, ../../img/sub/detail_star_m2@2x.png 2x"
              } */ alt=""/>
            <b>{varInterestEmploy}</b>
          </div>
          {/* 관심기업등록목록으로 이동 */}
          <h2 className="title_field">
            <Link to={`${HedingPage}`} state={{ pmode: 0 }}>내 관심기업</Link>
          </h2>
        </li>
        {/* 최근 본 채용공고  */}
        <li>
          <div className="icon_wrap eye">
            <img className="view_pc" src={varEmployInfo.em_headhunter === 0 ? blackEyeImage : whiteEysImage} 
              /* srcSet={varHeadHunter===0?
                "../../img/sub/detail_eye_m.png 1x, ../../img/sub/detail_eye_m@2x.png 2x"
              :
              "../../img/sub/detail_eye_m2.png 1x, ../../img/sub/detail_eye_m2@2x.png 2x"
              } */ alt=""/>
            <b>{varLookupEmploy}</b>
          </div>
           {/* 최근 본 채용공고로 이동 */}
          <h2 className="title_field">
            <Link to={`${HedingPage}`} state={{ pmode: 1 }}>최근 본 채용공고</Link>
          </h2>
        </li>
      </ul> : ""}
    </>
  );
};

export default RecruitViewInfo;
