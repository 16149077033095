/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import CONFIG from '../../configs/configs'

import icon_notice from "img/common/icon_notice.png"
/* import btnbarImage from "img/common/btn_bar.png" */

const LoginMenuCompany = ({ LogoutHandler, varIsLogin,NumberReset, setNumberReset }) => {
  const user_idx = Number(localStorage.getItem('mem_idx'));
  const [varNickname,setNickname] = useState();
  const [varTotalCount,setTotalCount] = useState(0);
  
  useEffect(() => {
    if(varIsLogin) {
      const mem_nickname = localStorage.getItem("mem_nickname");
      setNickname(mem_nickname);
    } else {
      setNickname('Nobody');
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varIsLogin]);

  //알림숫자 가져오기
  const getNoticeCounter = async () => {
    try {
        const params = new URLSearchParams();
        params.append("user_idx", user_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/_get_myMessage_count`, params, CONFIG.header).then((res) => {
          if(res.data.retvalue === 1) {
            setTotalCount(res.data.tcount);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getNoticeCounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{

    if(NumberReset){
      setNumberReset(false);
      getNoticeCounter();
    }
  }, [NumberReset]);

  return (
    <>
        {varIsLogin? 
          <>
            <Link to="/Company/ComHeding/MyHeding7" className="icon_notice">
              <i className="icon"><img src={icon_notice} alt=""/></i>
              {varTotalCount > 0?<i className="num">{varTotalCount}</i>:null}
            </Link> 
            <p className="id_field"><span className="id">{varNickname}</span>님</p>
            <button className="link login" onClick={()=>LogoutHandler()} style={{ cursor: "pointer" }}>로그아웃</button>        
            {/* <button className="btn_bar"><img src={btnbarImage} alt="btnbarImage"/></button> */}
          </>
        : <Link to={"/Login/Company"} state={"Company"} className="">로그인</Link>
        }
    </>
  );
};

export default LoginMenuCompany;
