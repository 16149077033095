/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//css

//components
import { addThousandPoint } from "../Common/Common"
//이미지

/*이용권구매*/
function VoucherPayList({ varSelectedVoucher }) {

  return (
    <ul className="table table_list01">
      <li className="tr">
        <div className="th row_kind">이용권 종류</div>
        <div className="th row_date">이용기간</div>
        <div className="th row_send">제안 발송</div>
        <div className="th row_price">가격</div>
        <div className="th row_amount">구매 수량</div>
      </li>
      <li className="tr">
        <div className="td row_kind">{varSelectedVoucher.vo_name}</div>
        <div className="td row_date"><span className="m_th">이용기간 :&nbsp;</span>{varSelectedVoucher.vo_terms+" 일"}</div>
        <div className="td row_send"><span className="m_th">제안 발송 :&nbsp;</span>{varSelectedVoucher.vo_sending+" 건"}</div>
        <div className="td row_price">{addThousandPoint(varSelectedVoucher.vo_amount)+" 원"}</div>
        <div className="td row_amount"><span className="m_th">구매 수량 :&nbsp;</span>1 개</div>
      </li>
    </ul>
  );
}

export default VoucherPayList;
