/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
//functions
import { ChangeSearchingDateFormat } from "../Common/Common";
//image
import plusImage from "../../img/sub/btn_plus.png";
import minusImage from "../../img/sub/btn_minus.png";
//datepickeer
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko'; // 한국어적용
// import _ from "lodash"
registerLocale("ko", ko) // 한국어적용

function CareerHistory({ index, career, varPosition, resumeCareerList, setResumeCareerList }) {
  const [startDate, setStartDate] = useState(career.rec_sdate === null || career.rec_sdate === "" ? null : new Date(career.rec_sdate));
  const [endDate, setEndDate] = useState(career.rec_edate === null || career.rec_edate === "" ? null : new Date(career.rec_edate));
  //경력조합체

  const [varInfoNumber, setInfoNumber] = useState(0); //eslint-disable-line no-unused-vars
  const [varInfoNumberList, setInfoNumberList] = useState([]); //eslint-disable-line no-unused-vars

  useEffect(() => {
    let newArray = [];
    career?.rec_descript.map((item) => (item.note ? newArray.push(item.id) : null));
    setInfoNumber(newArray.length);
    setInfoNumberList(newArray);
  }, [career]);

  //입력창 변경
  const onChangeHandler = (e) => {


    var { value, name } = e.target;
    setResumeCareerList(
      resumeCareerList.map(resume =>
        resume.idx === career?.idx ? {
          ...resume,
          [name]: name === 'rec_state' ? Number(value) : value
        } : resume
      ));
  }

  //입력창 - 주요업무 & 업무상세
  const onChangeNoteHandler = (e, id) => {
    var { value, name } = e.target;
    //console.log(e.target.className);//bg_on
    //if (event.target.className != 'remove-button') return;


    const Array = career?.rec_descript;
    const findIndex = Array.findIndex(element => element.id === id);
    let copyArray = [...Array];
    if (findIndex !== -1) {
      copyArray[findIndex] = { ...copyArray[findIndex], [name]: value };
    }
    setResumeCareerList(
      resumeCareerList.map(resume =>
        resume.idx === career.idx ? {
          ...resume,
          rec_descript: copyArray
        } : resume
      ));
  }

  //날짜변경
  const onChangeDate = (i, date) => {
    const ndate = ChangeSearchingDateFormat(date); //날짜 변경----configs.js
    if (i === 0) {
      setStartDate(date);
      setResumeCareerList(
        resumeCareerList.map(resume =>
          resume.idx === career?.idx ? {
            ...resume,
            rec_sdate: ndate
          } : resume
        ));
    } else {
      setEndDate(date);
      setResumeCareerList(
        resumeCareerList.map(resume =>
          resume.idx === career?.idx ? {
            ...resume,
            rec_edate: ndate
          } : resume
        ));
    }
  }

  const onAddWorkingHandler = () => {
    if (career.rec_descript.length > Number(varInfoNumber)) {
      let z = 0;
      let x = 0;

      const newArr1 = [];
      const newArr2 = [];

      career.rec_descript.map((item, i) => {
        if (item.note !== null) {
          newArr1.push({ id: z, note: item.note, detail: item.detail });
          z += 1;
        }
      });

      career.rec_descript.map((item, i) => {
        if (item.note === null) {
          if (x === 0) {
            newArr2.push({ id: z, note: '', detail: null });
          } else {
            newArr2.push({ id: z, note: item.note, detail: item.detail });
          }
          x += 1;
          z += 1;
        }
      });

      const newArr = newArr1.concat(newArr2).map((item, i) => {
        return { id: i, note: item.note, detail: item.detail };
      });

      setInfoNumber(Number(varInfoNumber + 1));
      setInfoNumberList(newArr);
      setResumeCareerList(
        resumeCareerList.map(resume =>
          resume.idx === career.idx ? {
            ...resume,
            rec_descript: newArr
          } : resume
        ));
    }
  }

  const onDeleteWorkingHandler = (idx) => {
    let z = 0;

    const newArr1 = [];
    const newArr2 = [];

    career.rec_descript.map((item, i) => {
      if (item.id !== idx) {
        if (item.note !== null) {
          newArr1.push({ id: z, note: item.note, detail: null });
        }
        z += 1;
      }
    });

    career.rec_descript.map((item, i) => {
      if (item.id === idx) {
        newArr2.push({ id: z, note: null, detail: null });
        z += 1;

      } else {
        if (item.note === null) {
          newArr2.push({ id: z, note: null, detail: null });
          z += 1;
        }
      }
    });

    const newArr = newArr1.concat(newArr2).map((item, i) => {
      return { id: i, note: item.note, detail: item.detail };
    });


    // const Array = career?.rec_descript;
    // const findIndex = Array.findIndex(element => element.id === idx);
    // let copyArray = [...Array];
    // if (findIndex !== -1) {
    //   copyArray[findIndex] = { ...copyArray[findIndex], note: null, detail: null };
    // }
    setResumeCareerList(
      resumeCareerList.map(resume =>
        resume.idx === career.idx ? {
          ...resume,
          rec_descript: newArr
        } : resume
      ));

  }


  const showList = career.rec_descript.map((el, idx) => {
    if (el.note !== null) {
      return (
        <>
          <div key={'careerd_' + idx} className="career-descriptions">
            {/* <input type="text" className={"input_subtitle"+(el.note ? ' bg_on':'')} id="input_subtitle" name="note" placeholder="주요 업무 내용을 입력해주세요."
              value={el.note === null ? "" : el.note} onChange={(e) => onChangeNoteHandler(e, el.id)} /> */}
            <textarea
              className={"content" + (el.note ? ' bg_on' : '')} id="content" name="note" placeholder="주요 업무 내용을 입력해주세요."
              value={el.note === null ? "" : el.note}
              onChange={(e) => onChangeNoteHandler(e, el.id)}
            ></textarea>
          </div>
          <button className="btn_add" onClick={() => onDeleteWorkingHandler(el.id)}><img src={minusImage} alt="" />삭제하기</button>
        </>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="input_area" key={"rec_" + index}>
      <input type="text" className="input_title color_readOnly" name="rec_company" placeholder="회사명" readOnly value={career?.rec_company} onChange={onChangeHandler} />
      <input type="text" className={"input_part" + (career?.rec_department ? ' bg_on' : '')} name="rec_department" placeholder="부서명" value={career?.rec_department === 'null' || career?.rec_department === null ? '' : career?.rec_department} onChange={(e) => onChangeHandler(e)} />
      <input type="text" className={"input_part2" + (career?.rec_position ? ' bg_on' : '')} name="rec_position" placeholder="직급명" value={career?.rec_position === 'null' || career?.rec_position === null ? '' : career?.rec_position} onChange={(e) => onChangeHandler(e)} />

      <DatePicker className="input_date datepicker color_readOnly" readOnly locale={ko} showMonthYearPicker /* withPortal */
        dateFormat="yyyy.MM" selected={startDate} selectStart onChange={(date) => onChangeDate(0, date)}
      />
      {career.rec_edate === null || career.rec_edate === "" ?
        <input type="text" className="input_date color_readOnly" readOnly value={'재직 중'} />
        :
        <DatePicker className="input_date datepicker color_readOnly" readOnly locale={ko} showMonthYearPicker minDate={startDate}
          dateFormat="yyyy.MM" selected={endDate} selectEnd onChange={(date) => onChangeDate(1, date)}
        />
      }
      <input type="text" className="input_state color_readOnly" name="rec_state" readOnly value={career?.rec_state === 0 ? "퇴사" : "재직"} />
      <button className="btn_add" onClick={() => onAddWorkingHandler()}><img src={plusImage} alt="" />업무내용 추가하기</button>
      {showList}
    </div>
  );
}

export default CareerHistory;