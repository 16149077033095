/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react"; // basic
import SwiperCore, { Navigation, Pagination } from "swiper";

//이미지
/* import visual01 from "img/main/visual01.jpg" */
/* import companyLogoImage from '../../img/sub/@thumnail.png' */

//css
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

//css
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'


function MainCompanyBanner() {
  const [varLoading, setLoading] = useState(true);
  const [varCompanyLogo, setCompanyLogo] = useState([]);
  const [swiper, setSwiper] = useState(null);// eslint-disable-line no-unused-vars
  const [mainImageIndex, setMainImageIndex] = useState(0);
  SwiperCore.use([Navigation, Pagination]);
  // const imageFolder = `${CONFIG.SERVER_HOST}/mcm_image/`;


  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const swiperParams = {
    navigation: { prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = navigationPrevRef.current;
      swiper.params.navigation.nextEl = navigationNextRef.current;
      swiper.activeIndex = mainImageIndex;
      swiper.navigation.update();
    },
    loop: true,
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndex(e.activeIndex)
  }
  const readCompanyLogos = async () => {
    try {
      setLoading(true);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_company_logo`).then((res) => {
        console.log(res.data.results);
        if (res.data.retvalue === 1) {
          const datas = res.data.results;
          //채용공고전체
          setCompanyLogo(datas);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    readCompanyLogos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    varLoading ? null :
      <section className="main_company_banner" >
        <div className="center_wrap">
          <Swiper className="swiper main_company_banner_slider swipper-container" {...swiperParams} ref={setSwiper}
            breakpoints={{ 1024: { slidesPerView: 10, spaceBetween: 30, }, 768: { slidesPerView: 7, spaceBetween: 10, }, 280: { slidesPerView: 5, spaceBetween: 5, } }}>
            <ul className="swiper-wrapper">
              {varCompanyLogo.map((cLogo, i) => (
                <SwiperSlide className="swiper-slide" key={"comlogo_" + i}>
                  <Link to={`/MyRecruit/MainView/${cLogo.em_idx}/${cLogo.em_headhunter}`} state={{ from: "main" }} >
                    {cLogo.em_image === 1 ?
                      <img src={`${CONFIG.SERVER_HOST}/em_image/${cLogo.em_ufile}`} alt="" />
                      :
                      <img src={`${CONFIG.SERVER_HOST}/mcm_image/${cLogo.mcm_ufile}`} alt="" />}
                  </Link>
                </SwiperSlide>
              ))}
            </ul>
          </Swiper>
          <div className="swiper-button-prev" ref={navigationPrevRef}></div>
          <div className="swiper-button-next" ref={navigationNextRef}></div>
        </div>
      </section>
  );
}

export default MainCompanyBanner;
