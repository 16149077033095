/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import CONFIG from "../../configs/configs";
import { useState, useCallback, useContext, useRef, useEffect } from "react";

//이미지
import ButtonAddImg from '../../img/sub/btn_add_img.png';

import { SocketContext, SOCKET_EVENT, makeMessage, makeImgMessage } from "../Chat/socket";

function ChatForm({ roomno, nickname }) {

  console.log('전역???');
  const [typingMessage, setTypingMessage] = useState("");
  // const [imgFile, setImgFile] = useState("");
  const [imgURL, setImgURL] = useState("");
  const socket = useContext(SocketContext);
  const chatInput = useRef();

  if(!socket.connected){
    console.log("소켓-재연결");
    socket.connect();
  }

  //텍스트 입력시 typingMessage state 변경
  const handleChangeTypingMessage = useCallback((event) => {
    setTypingMessage(event.target.value);
  }, []);

  //메시지 전송
  const handleSendMesssage = useCallback(() => {
    const noContent = typingMessage.trim() === "";
    if (noContent) {
      return;
    }
    const sData = { roomno, nickname, typingMessage}
    const reqData = makeMessage(
      //{roomno: roomno, nickname: nickname, msg: typingMessage}
      //const{roomno, nickname, typingMessage}
      sData
    );

    socket.emit(SOCKET_EVENT.UPDATE_MESSAGE, reqData);
    setTypingMessage("");
  }, [socket, roomno, nickname, typingMessage]);

  const onChangeInput = useCallback((event) => {
    let file = event.currentTarget.files[0];

    //setImgFile(file);
    //서버로 저장---
    const formData = new FormData();
    formData.append('chat', file)

    axios.post(`${CONFIG.SERVER_HOST}/chat_file`, formData).then(res => {
      setImgURL(res.data.fileName);
    });

  }, []);

  const handleSendImgMesssage = useCallback(() => {
    const noImg = imgURL.trim() === "";
    if (noImg) {
      return;
    }
    const ImsData = { roomno, nickname, typingMessage, imgURL }
    const reqData = makeImgMessage(
      ImsData
    );

    socket.emit(SOCKET_EVENT.UPDATE_MESSAGE, reqData);
    setImgURL("");
  }, [socket, roomno, nickname, imgURL]);


  useEffect(()=>{
    handleSendImgMesssage();
  }, [imgURL]);

  const ButtonAddImgClick = () => {

    chatInput.current.click();
  }

  return (
    <section className="field_write">
      <a><img src={ButtonAddImg} alt="ButtonAddImg" onClick={() => ButtonAddImgClick()} /> <input type='file' ref={chatInput} name="ChatFile" id="ChatFile" onChange={onChangeInput} style={{ display: 'none' }} /></a>
      <input type="text" placeholder="채팅 내용을 입력하세요." name='message' value={typingMessage} onChange={handleChangeTypingMessage} /*onPressEnter={handleSendMesssage}*/ onKeyDown={(e) => { if (e.key === 'Enter') handleSendMesssage(e) }}/>
      <button className="btn" onClick={handleSendMesssage}>전송</button>
    </section>
  )
}

export default ChatForm;