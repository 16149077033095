import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CONFIG from "../../configs/configs";
import { Certification, changeMobileFormat } from "../Common/Common";
import axios from "axios";

const FindIDC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mem_mode = location.state.mem_mode;

  //인증
  const [varAuth, setAuth] = useState({
      mobile: false,
    });

  const [varInputs, setInputs] = useState({
    name: "",
    mobile: "",
  });

  //입력정보 저장
  const onChangeInputHandler = (e) => {
    const { value, name } = e.currentTarget;
    setInputs({ ...varInputs, [name]: value });
  };

  //아이디찾기
  const onSubmitHandler = async () => {
    if (!varInputs.name) {
      alert("이름을 입력해주세요.");
    } else if (varAuth.mobile === false) {
      alert("휴대폰 인증을 해주세요.");
    } else {
      const param = new URLSearchParams();
      param.append("mem_name", varInputs.name);
      param.append("mem_mobile", varInputs.mobile);
      param.append("mem_mode", mem_mode);
      //서버 데이타베이스로 등록
      await axios.post(`${CONFIG.SERVER_HOST}/findId`, param).then((res) => {
        if (res.data.retvalue === 0) {
          alert(res.data.message);
        } else {
          console.log(res.data.mem_id);
          //localStorage.setItem("found_id", res.data[0].mem_id);
          navigate("/Member/FindIDOK", {
            state: { found_id: res.data.mem_id },
          });
        }
      });
    }
  };

  //핸드폰 인증
  const onMobileCertification = (e) => {
      Certification(varInputs,setInputs,varAuth, setAuth,0);

    }

  return (
    <div id="sub_wrap" className="center_wrap_member">
      <h2 className="sub_content_title">아이디 찾기</h2>
      <p className="sub_content_text">
        본인인증 회원가입 시 등록했던 정보를 입력하세요.
      </p>
      <div className="box member_form">
        <p className="title_field">이름</p>
        <input
          type="text"
          name="name"
          placeholder="이름을 입력해주세요"
          value={varInputs.name}
          onChange={(e) => onChangeInputHandler(e)}
        />
        <p className="title_field">휴대폰 번호</p>
        <div className="input_wrap">
          <input
            type="text"
            name="mobile"
            maxLength="13"
            value={changeMobileFormat(varInputs.mobile)}
            onChange={(e) => onChangeInputHandler(e)}
            readOnly
          />
          <div className="btn white" onClick={() => onMobileCertification()}>
            휴대폰본인인증
          </div>
        </div>
        <div className="bottom_btn_area">
          <div className="btn" onClick={() => onSubmitHandler()}>
            확인
          </div>
          <Link to={"/main"} className="btn gray">
            메인으로
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FindIDC;
