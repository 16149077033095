import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import ResumeList from '../Resume/ResumeList';
import ResumeView from '../Resume/ResumeView';
import ResumeDetail from '../Resume/ResumeDetail';
import ResumeLimit from '../Resume/ResumeLimit';
import BannerBottom from "components/Banner/BannerBottom";


function MyResume() {
    const PageFolder = '/MyResume/';
     const [varShowResume,setShowResume] = useState(false);  //대표이력서 검색 가능유무

  return (
    <div className="p_resume">
        <Header />
        {/* <SubVisual PageTitle="HEDING 이력서" SubVisual_className="type2"/> */}
        <div>
            <Routes>
              <Route path="/" element={<ResumeList PageFolder={PageFolder} varShowResume={varShowResume} setShowResume={setShowResume}/>} /> 
              <Route path="/ResumeList" element={<ResumeList PageFolder={PageFolder} />} /> 
              <Route path="/ResumeView/:re_idx" element={<ResumeView PageFolder={PageFolder}/>} /> 
              <Route path="/ResumeDetail/:re_idx/:type" element={<ResumeDetail/>} PageFolder={PageFolder} /> 
              <Route path="/ResumeLimit" element={<ResumeLimit/>} /> 
            </Routes>
        </div>
        <BannerBottom />
        <Footer/>
    </div>
  );
}

export default MyResume;
