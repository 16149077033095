import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { resetNavMenu } from "../../components/Common/Common";
import AnnouncementModifyC from '../../components/Announcement/AnnouncementModifyC'

//css

const AnnouncementCopy = () => {
  const { em_idx } = useParams();
  // const [varEmpIndex,setEmpIndex] = useState(em_idx);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async() => {
    /* setPageTitle('공고/지원자 관리'); */
    resetNavMenu(1);
    // if(em_idx !== undefined && em_idx !== null) {
    //   const params = new URLSearchParams();
    //   params.append("em_idx", em_idx);
    //   await axios.post(`${CONFIG.SERVER_HOST}/_copy_announcement_employ`,params).then((res) => {
    //     if(res.data.retvalue === 1) {
    //       alert(res.data.message);
    //       console.log('new',res.data.em_idx);
    //       setEmpIndex(res.data.em_idx);
    //     }
    //   });
    // }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    em_idx ===0?null:
    <div className="container">
      <AnnouncementModifyC type={'copy'} em_idx={em_idx} />
    </div>
  )
}

export default AnnouncementCopy
