/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';
//import moment from 'moment';

import { addThousandPoint } from '../../../components/Common/Common'
import Paging from '../../../components/Layout/Paging'

function PaymentList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    //검색부-------------------------------------------------------------------------------------------
    const [startDate, setStartDate] = useState(null); //date picker 날짜
    const [endDate, setEndDate] = useState(null);     //date picker 날짜

    const [varSearch, setSearch] = useState("all");
    const [varSearchInput, setSearchInput] = useState("");

    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    const [varMode,setVarMode] = useState(0);

    //목록표시부-------------------------------------------------------------------------------------------
    const [varPaymentList, setPaymentList] = useState([]);
    const [varCheckedList, setCheckedList] = useState([]);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function readPaymentList(iMode,cPage) {
        const sendData = {
            'mode': iMode,
            'currentPage': cPage,
            'pageSize': varPageSize,
            'pageMode': 1,
            'sdate': startDate,
            'edate': endDate,
            'search_option': varSearch,
            'search_string': varSearchInput
        }
        const config = {"Content-Type": 'application/json'};
        axios.post(`${CONFIG.SERVER_HOST}/paymentlist_admin`,sendData, config).then((res) => {
            console.log("/paymentlist::", res.data);
            if(res.data.retvalue === 1) {
                setTotalCount(res.data.totalcount);
                //console.log(res.data);
                const data = res.data.results.map((data,i)=>(                
                    {  
                        or_idx: data.or_idx,
                        order: res.data.totalcount-(varPageNum-1)*varPageSize - i,
                        mem_id:data.mem_id,
                        mem_name:data.mem_name,
                        mcm_name:data.mcm_name,
                        vo_name:data.vo_name,
                        or_amount:addThousandPoint(data.or_amount)+"원",
                        ops_name:data.ops_name,
                        or_pdate:data.or_pdate,
                        or_rdate:data.or_rdate,
                        ors_name:data.ors_name
                    }
                ))
                setPaymentList(data);
            } else {
                setTotalCount(0);
                setPaymentList([]);
                window.alert(res.data.message);
            }
            
        });
    }

    //페이지가 바뀌면
    useEffect(() => {
        readPaymentList(varMode,varPageNum);
    },[varPageNum]);

    //검색-------------------------------------------------------------------------------------------
    const handleType = (e) =>{
        setSearch(e.target.value);
    };
    const handleInput = (e) =>{
        setSearchInput(e.target.value);
    };    
    const handleStartDate =(e)=>{
        setStartDate(e.target.value);
    }
    const handleEndDate =(e)=>{
        setEndDate(e.target.value);
    }
    //초기화
    const handleSearchPaymentClear =()=>{       
        setStartDate("");
        setEndDate("");
        setSearch("all");
        setSearchInput("");

        setVarMode(0);
        setPageNum(1);
        readPaymentList(0,varPageNum);
    }
    //검색
    const handleSearchPaymentList = () =>{
        setVarMode(1);
        readPaymentList(1,varPageNum);
    }

    //전체 선택
    const onCheckedAll = (e) => {
      if (e.currentTarget.checked) {
        const checkedListArray = [];
        varPaymentList.forEach((payment) => checkedListArray.push(payment));
        setCheckedList(checkedListArray);
      } else {
        setCheckedList([]);
      }
    }
    //개별선택 
    const onCheckedElement = (e, payment) => {
      if (e.currentTarget.checked) {
          setCheckedList([...varCheckedList, payment]);
      } else {
          setCheckedList(varCheckedList.filter((el) => el !== payment));
      }
    }
    //선택 항목 삭제
    const deletePaymentList = (e) =>{
        const delitems = varCheckedList.length;
        if(delitems === 0) {
            window.alert('삭제할 정보가 선택되어 있지 않습니다.'); return;
        }
        if(!window.confirm(delitems+'개의 선택된 정보를 삭제합니까?')) return;

        //console.log(varCheckedList);
        const params = new URLSearchParams();
        params.append("delitems",JSON.stringify(varCheckedList));
        axios.post(`${CONFIG.SERVER_HOST}/payment_delete`,params).then((res) => {
            if(res.data.retvalue === 1) {
                readPaymentList(varMode,varPageNum);              
            } else {
                window.alert('오류가 발생했습니다.');
            }
        });
    }

    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="using_pass"/>
        <Menu menuType="using_pass" menu="paymentlist"/>
        <div id="contents" className="ad_contents">
            <div className="tit">결제관리
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>이용권 구매관리<img src={arrImage} alt=""/>결제관리
                </div>
            </div>
            <div className="content">
                <div className="main-box">		
                    <div className="search-box">
                        <div className="day-box">
                            <p>등록일자</p>
                            <input type="date" name="startdate" value={startDate} onChange={handleStartDate} id="datePicker" />
                            ~ 
                            <input type="date" name="enddate" value={endDate} onChange={handleEndDate} id="datePicker2" />
                        </div>
                        <div className="f-right">								
                            <select style={{width:"120px", marginRight:"5px"}} name="search" value={varSearch} onChange={handleType}> 
                                <option value="all">전체</option>
                                <option value="b.mem_id">아이디</option>
                                <option value="b.mem_name">회원명</option>
                                <option value="cc.mcm_name">회사명</option>
                                <option value="e.vo_name">이용권종류</option>
                                <option value="d.ops_name">결제방식</option>
                                <option value="c.ors_name">결제상태</option>
                            </select>
                            <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px", marginRight:"5px"}}  onKeyDown={(e) => { if (e.key === 'Enter') handleSearchPaymentList(e)}}/>
                            <button onClick={handleSearchPaymentList} className="btn">검색</button>
                            <button onClick={handleSearchPaymentClear} className="btn black" >초기화</button>				
                        </div>
                    </div>
                </div>           
                <div className="main-box2">
                    <div className="btn-right">
                        {/* <Link to="/admin/paymentdetail/0" className="btn">등록</Link> */}
                        <button onClick={deletePaymentList} className="btn">삭제</button>
                    </div>
                </div>                
                <div className="main-box ">       
                    <table className="list">
                        <colgroup>
                          <col style={{width:"3%"}} />
                          <col style={{width:"8%"}} />
                          <col style={{width:"8%"}} />
                          <col style={{width:"10%"}}/>
                          <col style={{width:"12%"}}/>
                          <col style={{width:"8%"}} />
                          <col style={{width:"10%"}} />
                          <col style={{width:"8%"}}/>
                          <col style={{width:"8%"}}/>
                          <col style={{width:"8%"}}/>
                          <col style={{width:"8%"}}/>
                          <col style={{width:"8%"}}/>
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="board-list">
                              <input type="checkbox" onChange={(e)=>onCheckedAll(e)} id="deleteItems"
                              checked={varCheckedList.length===0?false:varCheckedList.length===varPaymentList.length?true:false} />
                            </th>
                            <th>번호</th>
                            <th>아이디</th>
                            <th>회원명</th>
                            <th>회사명</th>
                            <th>이용권종류</th>
                            <th>등록일</th>
                            <th>결제금액</th>
                            <th>결제방식</th>
                            <th>결제일자</th>
                            <th>결제상태</th>
                            <th>관리</th>
                          </tr>
                        </thead>
                        <tbody>
                          {varPaymentList.map((payment,i) => (
                              <tr key={payment.or_idx}>
                                  <td className="board-list">
                                    <input type="checkbox" 
                                    id={"deleteItem"+i}
                                    onChange={(e)=>onCheckedElement(e,payment)} 
                                    checked={varCheckedList.includes(payment)?true:false}/>
                                  </td>
                                  <td><p>{payment.order}</p></td>
                                  <td className="ellipsis"><p>{payment.mem_id}</p></td>
                                  <td className="ellipsis"><p>{payment.mem_name}</p></td>
                                  <td className="ellipsis"><p>{payment.mcm_name}</p></td>
                                  <td className="ellipsis"><p>{payment.vo_name}</p></td>
                                  <td className="ellipsis"><p>{payment.or_rdate !== null ? payment.or_rdate.substr(0,10) : ''}</p></td>
                                  <td className="ellipsis"><p>{payment.or_amount}</p></td>
                                  <td className="ellipsis"><p>{payment.ops_name}</p></td>
                                  <td className="ellipsis"><p>{payment.or_pdate !== null ? payment.or_pdate.substr(0,10) : '--'}</p></td>
                                  <td className="ellipsis"><p>{payment.ors_name}</p></td>
                                  <td><Link to={`/admin/paymentdetail/${payment.or_idx}`} className="btn line black">상세</Link></td>
                              </tr>
                          ))}
                        </tbody> 
                    </table>
                </div>
                <div>
                  <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                </div>
            </div>        
        </div>
        </React.Fragment>
    )
}

export default PaymentList;