/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import Paging from "components/Layout/Paging";
import { usePrevState } from 'components/Common/usePrevState';

const JoinCompanyInfo = ({ varInputs2, setInputs2, setPopupSearchCompany }) => {

  //기업 - 검색문자 & 검색된 기업정보
  const [varSearchString, setSearchString] = useState("");
  const [varSearchingCompanyData, setSearchingCompanyData] = useState([]); //검색된 회사목록
  const [varSelectedString, setSelectedString] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(5);
  const [varPageNum, setPageNum] = useState(1);


  const [varChkSearch, setChkSearch] = useState(false);
  const [SPAGE, setSPAGE] = useState(1);
  const [EPAGE, setEPAGE] = useState(10);
  const prevCount = usePrevState(varPageNum);

  //팝업창---------
  //회사찾기 - 팝업창 호출
  const onChangeOpenCompanyHandler = (e, bmode) => {
    e.preventDefault();
    setSearchString("");
    setSearchingCompanyData([]);
    setPopupSearchCompany(bmode);
  };
  //기업이름 검색문자 입력
  const onSearchStringHandler = (e) => {
    setSearchString(e.currentTarget.value);
  };


  useEffect(() => {
    if (varChkSearch) {
      onSearchingCompanyHandler();
    }
  }, [varChkSearch]);

  // 기업 검색.
  const onSearchingCompanyHandler = async (e) => {
    e?.preventDefault();
    if (varSearchString === "") {
      alert("검색할 문자를 먼저 입력하십시오.");
      return false;
    }

    setSearchingCompanyData([]);
    setSearchLoading(true);
    await axios
      .get(`${CONFIG.SERVER_HOST}/_search_companies_all/${encodeURIComponent(varSearchString)}/${SPAGE}/${EPAGE}`)
      .then((r) => {
        console.log(r.data);
        //const resData = r.data;
        if (r.data.retvalue === 1) {
          const results = r.data.results;
          setTotalCount(r.data.totalcount);
          setSearchingCompanyData(results.map((company) => {
            return {
              corpName: company.TRCO_CMNM,
              bizNo: company.BIZ_NO,
              address: company.ADDRESS,
            }
          }));
          // 결과값 없을 경우 page state 변경
          if (results.length === 0) {
            setPageNum(0)
          }
        }
        // setSearchingCompanyData(resData.map((company) => {
        //   return {
        //     corpName: company.TRCO_CMNM,
        //     bizNo: company.BIZ_NO,
        //     address: company.ADDRESS,
        //   }
        // }));
        // setSearchLoading(false);
      }).catch((e) => {
        const errData = e;
        setSearchingCompanyData([]);
        setSearchLoading(false);
        console.debug('error ', errData);
      });
    setSearchLoading(false);
    setChkSearch(false);
  };

  //회사선택
  const onSelectCompanyHandler = (comp) => {
    const { corpName, bizNo } = comp;
    setSelectedString(corpName);
    setInputs2({
      ...varInputs2,
      taxId: bizNo,
      comName: corpName
    });
  };

  //회사이름 지정
  const onSelectedCompanyHandler = (e) => {
    setInputs2({ ...varInputs2, comName: varSelectedString });
    setPopupSearchCompany(false);
  };
  // 회사이름 직접입력
  const onDirectCompanyHandler = () => {
    setInputs2({ ...varInputs2, comName: varSearchString })
    setPopupSearchCompany(false);
  }

  const onSearchingString = (e) => {
    if (varSearchString) {
      setPageNum(1);
      setSPAGE(1);
      setEPAGE(10);
      setChkSearch(true);
    } else {
      alert('검색어를 입력해주세요.');
    }

  }

  useEffect(() => {
    if (searchLoading === false) {
      if (prevCount !== varPageNum) {
        setSPAGE(Number(`${varPageNum === 1 ? `` : varPageNum - 1}1`));
        setEPAGE(Number(`${varPageNum}0`));
        setChkSearch(true);
      }
    }
  }, [varPageNum, searchLoading]);


  return (
    <div
      className="popup"
      style={{ display: "block" }}
    >
      <article className="popup_wrap popup01">
        <div className="title_wrap">
          <h1>기업검색</h1>
        </div>
        <div className="content_wrap company_search">
          <section
            className="search_area"
            style={{ display: "flex", justifyContent: "space_between" }}
          >
            <input
              type="text"
              name="search_name"
              value={varSearchString}
              onChange={(e) => onSearchStringHandler(e)}
              placeholder="회사명을 입력해주세요."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearchingString(e)
                }
              }}
            />
            <button
              className="btn"
              onClick={(e) => onSearchingString(e)}
              style={{ width: "90px" }}
            >
              {searchLoading ? `조회중` : `조회`}
            </button>
          </section>
          <section className="company_list_area style_scroll">
            {varSearchingCompanyData?.length === 0 ? (
              <div className="nodata">{varChkSearch === false ? '검색된 회사가 없습니다.' : '회사를 조회중입니다.'} </div>
            ) : (
              varSearchingCompanyData.map((company, i) => (
                <div key={"cmo_" + i}>
                  <input
                    type="radio"
                    name="radio_company"
                    id={`radio_company${i + 1}`}
                    onClick={() => onSelectCompanyHandler(company)}
                    disabled={searchLoading}
                  />
                  <label htmlFor={`radio_company${i + 1}`}>
                    {`${company.corpName} - ${company.address}`}
                  </label>
                </div>
              ))
            )}
          </section>
          <div className="paging">
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
          </div>
        </div>
        <div className="button_wrap">
          <div className="big_button_wrap one">
            {
              varPageNum === 0 ?
                <button className="btn" onClick={(e) => onDirectCompanyHandler(e)}>
                  직접 입력
                </button>
                :
                <button className="btn" onClick={(e) => onSelectedCompanyHandler(e)}>
                  선택하기
                </button>
            }
          </div>
          <div className="close_button_wrap">
            <button onClick={(e) => onChangeOpenCompanyHandler(e, false)}>
              닫기 X
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default JoinCompanyInfo;
