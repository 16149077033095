import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { resetNavMenu } from "../../components/Common/Common";
import AnnouncementModifyC from '../../components/Announcement/AnnouncementModifyC';

//css

const AnnouncementModify = () => {
  const { em_idx } = useParams();
  console.log('AnnouncementModify',em_idx);
  useEffect(() => {
    resetNavMenu(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <AnnouncementModifyC type={em_idx === 0 ? 'write':'modify'} em_idx={em_idx} />
    </div>
  )
}

export default AnnouncementModify
