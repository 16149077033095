/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from 'configs/configs';
import { addThousandPoint, year_over_year, getDatesStartToLast, ChangeDateTimeFormat } from "../Common/Common";
import Chart from '../Chart/ReactApexChart ';
import PopCertify from 'components/PopUp/PopCertify';
import { Link } from "react-router-dom";

const CareerIncomeViewC = ({ varIncomeMode }) => {

  const mem_name = localStorage.getItem('mem_name'); //회원 이름
  const mem_idx = localStorage.getItem('mem_idx'); //회원 번호
  const today = new Date(); //현재 날짜
  const lastYear = today.getFullYear() - 1; //년도 추출
  //소득조회 팝업창 타입
  const Type = 1;

  //생애 총소득, 세금 DATA
  const [IncomTax, setIncomTax] = useState({
    idx: 0,
    lifetime_income: 0,
    mli_idx: 0,
    total_tax: 0
  });

  //선택한 년도의 DATA
  const [SelectItem, setSelectItem] = useState({
    mei_idx: 0,
    mei_year: 0,
    mei_income: 0,
    mei_tax: 0
  });

  //선택년도 기준 전년도의 DATA
  const [PreviousYear, setPreviousYear] = useState({
    mei_idx: 0,
    mei_year: 0,
    mei_income: 0,
    mei_tax: 0
  });

  //선택한 년도의 배열 index DATA
  const [SelectIndex, setSelectIndex] = useState(3);
  //년도 DATA LIST DATA
  const [Year, setYear] = useState([]);
  //년도 별 소득 DATA LIST DATA
  const [Incom, setIncom] = useState([]);
  //년도 별 세금 DATA LIST DATA
  const [Tax, setTax] = useState([]);
  //그래프 LIST DATA
  const [GraphList, setGraphList] = useState();

  //그래프 년도 이동시 index 세팅 STATE 
  const [Num1, setNum1] = useState(1);
  const [Num2, setNum2] = useState(1);
  const [Num3, setNum3] = useState(2);

  //년도 별 DATA LIST DATE
  const [ResList, setResList] = useState([]);

  //경력목록 - 인증
  const [resumeCareerList, setResumeCareerList] = useState([]);

  //근로소득, 인증경력 팝업창 출력 유무
  const [varPopup, setPopup] = useState(false);

  //팝업창용 state (건강보험 용 )
  const [varInputs, setInputs] = useState({
    title: '',
    code: '',
    id: 0,
    name: '',
    gender: 0,
    birthday: null,
    mobile: '',
    income_lyear: lastYear,
    income_last: 0,
    income_this: 0,
    income_hope: 0,
    after_meet: 0,
    employ_hope: 0,
    place_hope: '',
    task_hope: '',
    fix: 0,
    lookup: 0,
    rdate: ChangeDateTimeFormat(new Date()),
    perfection: 0,
    career_year: 0.0,
    careeradd_year: 0.0,
    company_hope: '',
    email1: '',
    email2: '',
    site: '',
    re_ufile: '',
    mem_ufile: '',
    re_photo: 0,
    move_hope: 1
  });

  //년도별 소득 및 총소득 조회 함수
  const getIncomeData = () => {
    const sendData = {
      'mei_mem_idx': mem_idx,
      'mem_idx': mem_idx
    }

    //소득 정보 조회 api
    axios.post(`${CONFIG.SERVER_HOST_INCOME}/income`, sendData, CONFIG.header).then((res) => {
      const val = res.data.data;
      if (val.length > 0) {
        const maxObjArr = val.reduce((prev, value) => {
          return Number(prev.mei_year) >= Number(value.mei_year) ? prev : value
        });
        //지정한 년도 만큼 배열생성
        const years = getDatesStartToLast(1900, Number(maxObjArr.mei_year));

        const data = years.map((year, i) => {
          const in_data = val.filter(element => Number(element.mei_year) === year);
          if (in_data.length > 0) {
            return ({
              mei_idx: Number(in_data[0]?.mei_idx),
              mei_year: Number(in_data[0]?.mei_year),
              mei_income: Number(in_data[0]?.mei_income),
              mei_tax: Number(in_data[0]?.mei_tax)
            });

          } else {
            return ({
              mei_idx: 0,
              mei_year: Number(year),
              mei_income: 0,
              mei_tax: 0
            });
          }
        });

        setGraphList(data);
        setResList(val);
      } else {
        setGraphList([]);
        setResList([]);
      }
    });

    //생애 총 소득과 세금 조회 api
    axios.post(`${CONFIG.SERVER_HOST_INCOME}/income/IncomTax`, sendData, CONFIG.header).then((res) => {
      console.log(res.data.data[0]);
      if(res.data){
        setIncomTax(res.data.data[0])
      }
      
    });
  }

  //처음 실행
  useEffect(() => {
    getIncomeData();
  }, []);

  //소득 데이터가 들어오면 그래프 컴포넌트에 보내줄 데이터 정형 함수 실행
  useEffect(() => {
    makeGraphData(GraphList, Num1, Num2, Num3);
  }, [GraphList]);

  //소득 조회시 
  useEffect(() => {
    if (varInputs.income_last > 0) {
      getIncomeData();
    }
  }, [varInputs]);

  //그래프용 데이터 생성 함수
  const makeGraphData = (data, num1, num2, num3) => {
    let arr = data;
    let result = [];
    let i = 0;
    const orderedDate = arr?.sort((a, b) => new Date(b.mei_year) - new Date(a.mei_year))
    //배열을 4개씩 묶음
    for (i = 0; i < orderedDate?.length; i += 4) result.push(orderedDate.slice(i, i + 4));
    let mei_year_list = [];
    let mei_income_list = [];
    let mei_tax_list = [];

    if (orderedDate?.length > 0) {
      const orderedDate2 = result?.sort((a, b) => new Date(b.mei_year) - new Date(a.mei_year));
      orderedDate2.reverse();
      const select_result = orderedDate2[orderedDate2.length - num1];
      select_result.reverse();
      select_result.map((item) => {
        const mei_year = Number(item.mei_year);
        const mei_income = Number(item.mei_income);
        const mei_tax = Number(item.mei_tax);
        mei_year_list.push(mei_year);
        mei_income_list.push(mei_income);
        mei_tax_list.push(mei_tax);
      });

      setSelectItem({
        mei_idx: Number(select_result[select_result.length - num2].mei_idx),
        mei_year: Number(select_result[select_result.length - num2].mei_year),
        mei_income: Number(select_result[select_result.length - num2].mei_income),
        mei_tax: Number(select_result[select_result.length - num2].mei_tax)
      });


      if (num2 < 4) {
        setPreviousYear({
          mei_idx: Number(select_result[select_result.length - num3]?.mei_idx),
          mei_year: Number(select_result[select_result.length - num3]?.mei_year),
          mei_income: Number(select_result[select_result.length - num3]?.mei_income),
          mei_tax: Number(select_result[select_result.length - num3]?.mei_tax)
        });
        setSelectIndex(select_result.length - num2);
      } else if (num2 >= 4) {
        const select_result2 = orderedDate2[orderedDate2.length - 2];
        select_result2.reverse();
        setPreviousYear({
          mei_idx: Number(select_result2[select_result2.length - 1]?.mei_idx),
          mei_year: Number(select_result2[select_result2.length - 1]?.mei_year),
          mei_income: Number(select_result2[select_result2.length - 1]?.mei_income),
          mei_tax: Number(select_result2[select_result2.length - 1]?.mei_tax)
        });
        setSelectIndex(0);
      }

    }

    setYear(mei_year_list);
    setIncom(mei_income_list);
    setTax(mei_tax_list);
  }

  //다음 버튼
  const nextBtn = () => {
    if (Num1 === 1 && Num2 === 1 && Num3 === 2) {
      return false;
    }
    const N1 = Num2 <= 1 ? Num1 - 1 : Num1;
    const N2 = Num2 <= 1 ? 4 : Num2 - 1;
    const N3 = Num2 <= 1 ? 5 : Num3 - 1;
    setNum1(N1);
    setNum2(N2);
    setNum3(N3);
    makeGraphData(GraphList, N1, N2, N3);
  }

  //이전버튼
  const prevBtn = () => {
    if (GraphList.length === Num1) {
      return false;
    }
    const N1 = Num2 >= 4 ? Num1 + 1 : Num1;
    const N2 = Num2 >= 4 ? 1 : Num2 + 1;
    const N3 = Num2 >= 4 ? 2 : Num3 + 1;
    setNum1(N1);
    setNum2(N2);
    setNum3(N3);
    makeGraphData(GraphList, N1, N2, N3);
  }

  //그래프 선택 함수
  const selectData = (num1, num2) => {

    console.log(num1, num2);
    const N1 = Num1;
    const N2 = num1 === 3 ? 1 : num1 === 2 ? 2 : num1 === 1 ? 3 : 4;
    const N3 = num1 === 3 ? 2 : num1 === 2 ? 3 : num1 === 1 ? 4 : 5;

    setNum1(N1);
    setNum2(N2);
    setNum3(N3);
    makeGraphData(GraphList, N1, N2, N3);
  }

  //생애 총 소득과 세금 삭제
  const deleteIncome = () => {
    //
    if (IncomTax?.lifetime_income === 0 || !IncomTax?.lifetime_income) {
      // alert('삭제할 평생소득이 없습니다.');
      alert('삭제할 소득이 없습니다.');
      return;
    }
    // if (!window.confirm(`평생소득을 삭제하시겠습니까?`)) return;
    if (!window.confirm(`소득을 삭제하시겠습니까?`)) return;

    //생애 총 소득과 세금 삭제 api
    axios.delete(`${CONFIG.SERVER_HOST_INCOME}/income/del/${mem_idx}`, CONFIG.header).then((res) => {
      if (res.data.code === "0000") {
        getIncomeData();
      }
    });
  }

  //년도별 삭제 함수
  const deleteYearData = (year) => {
    if (ResList.length === 0) {
      alert('삭제할 연봉내역이 없습니다.');
      return;
    }
    if (!window.confirm(`연봉 내역을 삭제하시겠습니까?`)) return;

    //년도 소득 삭제 api
    axios.delete(`${CONFIG.SERVER_HOST_INCOME}/income/${mem_idx}/all`, CONFIG.header).then((res) => {
      if (res.data.code === "0000") {
        getIncomeData();
      }
    });
  }

  //인증경력시 신입여부값 변환 (사용 x)
  const chkCareerTF = () => {

  };

  //소득조회 팝업창 오픈
  const openPop = () => {
    setPopup(true);
  }

  //간편인증 팝업창 닫기 함수
  const closePopup = () => {
    setPopup(!varPopup);
  };
  return (
    <div className="CareerIncomWrap View center_wrap">
      {varIncomeMode === 0 ?
        <>
          {IncomTax?.lifetime_income > 0 || IncomTax?.total_tax > 0 ?
            <>
              <div className="WboxCons">
                <div className="BoxTit">
                  {/* <div className="iconTxt"><span className="icon"><i className="icon3"></i></span><b>{mem_name}</b>님의 평생 소득 내역 입니다.</div> */}
                  <div className="iconTxt"><span className="icon"><i className="icon3"></i></span><b>{mem_name}</b>님의 5년치 소득 내역 입니다.</div>
                  <div className="rightBtns">
                    <button type="button" onClick={() => openPop()}><i className="reload"></i>소득 업데이트</button>
                    <button type="button" onClick={() => deleteIncome()}><i className="del">X</i>소득 내역 삭제</button>
                  </div>
                </div>
                <div className="Cons">
                  <ul className="ListBox">
                    <li>
                      <div className="iconTxt">
                        <span className="icon"><i className="icon1"></i></span>
                        {/* 평생 소득 금액<b className="right1">{addThousandPoint(IncomTax?.lifetime_income)} 원</b> */}
                        소득 금액<b className="right1">{addThousandPoint(IncomTax?.lifetime_income)} 원</b>
                      </div>
                    </li>
                    <li>
                      <div className="iconTxt">
                        <span className="icon"><i className="icon4"></i></span>
                        {/* 평생 세금 금액<b className="right1">{addThousandPoint(IncomTax?.total_tax)} 원</b> */}
                        세금 금액<b className="right1">{addThousandPoint(IncomTax?.total_tax)} 원</b>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

            </>
            :
            <div className="WBox">
              {/* <div className="BTxt"><span className="icon"><i className="icon1"></i></span><p>간편 인증 후&nbsp;<b>나의 평생 소득</b>을 <br className="view_m" />확인하실 수 있습니다.</p></div> */}
              <div className="BTxt"><span className="icon"><i className="icon1"></i></span><p>간편 인증 후&nbsp;<b>나의 5년치 소득</b>을 <br className="view_m" />확인하실 수 있습니다.</p></div>
              <div className="CenterBtn">
                {/* <Link to={'/MyCareer/CareerIncome'} onClick={() => openPop()} className="BtnBlue">평생 소득 불러오기</Link> */}
                <Link to={'/MyCareer/CareerIncome'} onClick={() => openPop()} className="BtnBlue">5년치 소득 불러오기</Link>
              </div>
            </div>
          }


          {ResList.length > 0 ?
            <div className="WboxCons">
              <div className="BoxTit">
                <div className="iconTxt"><span className="icon"><i className="icon2"></i></span><b>{mem_name}</b>님의 연도별 연봉 내역 입니다.</div>
                <div className="rightBtns">
                  <button type="button" onClick={() => openPop()}><i className="reload"></i>연봉 업데이트</button>
                  <button type="button" onClick={() => deleteYearData(SelectItem?.mei_year)}><i className="del">X</i>연봉 내역 삭제</button>
                </div>
              </div>
              <div className="Cons">
                <div className="graphBoxTop">
                  <div className="YearBox">
                    <button type="button" onClick={prevBtn}><span>&lt;</span></button>
                    <b>{SelectItem.mei_year}</b>
                    <button type="button" onClick={nextBtn}><span>&gt;</span></button>
                  </div>
                  <ul className="ListBox WithGraph">
                    <li>
                      <div className="iconTxt">
                        <span className="icon"><i className="icon5"></i></span>
                        연소득 금액<b className="right2">{addThousandPoint(SelectItem?.mei_income)} 원</b>
                      </div>
                    </li>
                    <li>
                      <div className="iconTxt">
                        <span className="icon"><i className="icon6"></i></span>
                        전년대비<b className="right2">{PreviousYear.mei_income === 0 ? `전년 소득정보 없음` : year_over_year(PreviousYear.mei_income, SelectItem?.mei_income)}</b>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="GraphBox">
                  <Chart
                    Year={Year}
                    Incom={Incom}
                    Tax={Tax}
                    selectData={selectData}
                    SelectIndex={SelectIndex}
                  />
                </div>
              </div>
            </div>
            :
            <div className="WBox">
              <div className="BTxt"><span className="icon"><i className="icon2"></i></span><p>간편 인증 후&nbsp;<b>나의 연도별 연봉</b>을 <br className="view_m" />확인하실 수 있습니다.</p></div>
              <div className="CenterBtn">
                <Link to={'/MyCareer/CareerIncome'} onClick={() => openPop()} className="BtnBlue">연봉 불러오기</Link>
              </div>
            </div>
          }
          {/* </div> */}
          {varPopup === true ?
            <PopCertify
              closePopup={closePopup}
              resumeCareerList={resumeCareerList}
              setResumeCareerList={setResumeCareerList}
              setInputs={setInputs}
              varInputs={varInputs}
              chkCareerTF={chkCareerTF}
              typ={Type}
            />
            :
            null

          }


        </>
        :
        <></>
      }
    </div>
  )
}

export default CareerIncomeViewC
