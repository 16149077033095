/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import CONFIG from '../../../configs/configs'

import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'

import moment from 'moment'

function PopUp() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varInitData, setInitData] = useState([]);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/popup`).then(res => {
      console.log(res)
      const data = res.data.map((data, i) => ({
        order: i + 1,
        pop_idx: data.pop_idx,
        pop_name: data.pop_name,
        pop_sdate: data.pop_sdate,
        pop_edate: data.pop_edate,
        pop_comment: data.pop_comment,
        pop_use: data.pop_use
      }))
      setInitData([...data].reverse());
    })
  }, [])

  const deleteHandler = idx => {
    const confirm = window.confirm('삭제하시겠습니까?');
    if (confirm === true) {
      axios.post(`${CONFIG.SERVER_HOST}/popup_delete?pop_idx=${idx}`).then(res => {
        console.log('delete :: result :: ', res);
        alert('삭제되었습니다.');
      })
    }
  }

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="popup" />
      <div id="contents" className="ad_contents">
        <div className="tit">
        팝업 관리
          <div className="path">
            <img src={iconhome} alt=""/>HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt=""/>디자인 관리<img src={arrImage} alt=""/>팝업 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">팝업 관리</div> */}
          <div className="main-box2">
            <div className="btn-right">
              <button className="btn-danger">
                <Link to={`/admin/popup/register/${0}`} style={{ color: '#fff', width: '100%' }} >등록</Link>
              </button>
            </div>
          </div>
          <div className="main-box">
              <table className="list">
                <colgroup>
                  <col width="8%"></col>
                  <col width="47%"></col>
                  <col width="24%"></col>
                  <col width="8%"></col>
                  <col width="13%"></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>팝업명</th>
                    <th>게시기간</th>
                    <th>사용여부</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  {varInitData.map((data, i) => (
                    <tr key={data.pop_idx}>
                      <td><p>{data.order}</p></td>
                      <td className="ellipsis"><p>{data.pop_name}</p></td>
                      <td>
                        <p>
                          {moment(data.pop_sdate).format(CONFIG.formatStr)} ~
                          {moment(data.pop_edate).format(CONFIG.formatStr)}
                        </p>
                      </td>
                      <td>
                        {data.pop_use === 0 ? <p>Y</p> : ''}
                        {data.pop_use === 1 ? <p>N</p> : ''}
                      </td>
                      <td>
                        <Link to={`/admin/popup/register/${data.pop_idx}`} className="btn line black">수정</Link>
                        <button  className="btn line black" onClick={() => deleteHandler(data.pop_idx)}>삭제</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PopUp
