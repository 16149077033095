import React from "react";
import { Routes, Route } from "react-router-dom";
import CareerReputationPdf from '../Career/CareerReputationPdf';  //동료평판 > PDF
import FriendsReputationPdf from '../Career/FriendsReputationPdf';  //지인평판 > PDF

const Reputation = () => {
	return (
    <Routes>
      {/* 동료 평판 - 전체 > PDF 생성용 */}
      <Route path="/career/:userIdx" element={<CareerReputationPdf />} />
      {/* 동료 평판 - 전체 > PDF 생성용 */}
      <Route path="/friends/:userIdx" element={<FriendsReputationPdf />} />
    </Routes>
	)
}

export default Reputation
