import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
//import { useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from 'configs/configs';

import { resetNavSubMenuForHeding, addThousandPoint, ChangeDateStringOnlyDate } from "components/Common/Common";
import HedingRecommendTop from 'components/Company/HedingRecommendTop';
import CompanyRecommendSearch from './CompanyRecommendSearch';
import HedingRecommendList from 'components/Company/HedingRecommendList';
import Paging from "../Layout/Paging";

//기업-추천현황
function MyHeding10({ getSubItem }) {
  const user_idx = localStorage.getItem('mem_idx');
  const [varRecommendList, setRecommendList] = useState([]);

  //메뉴(네비게이터) Active 표기 관련
  useEffect(() => {
    getSubItem(10);
    resetNavSubMenuForHeding(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const uNavigate = useNavigate();
  // const uri_path = window.location.pathname;
  // useEffect(() => {
  //   if (!user_idx || Number(user_idx)===0) {
  //     localStorage.setItem("re_url", uri_path);
  //     alert("로그인 후 이용 가능합니다.");
  //     uNavigate("/Login");
  //   }
  // },[user_idx, uri_path, uNavigate]);

  const [varRecommendCounter, setRecommendCounter] = useState(0);
  const [varRecommendApplyCounter, setRecommendApplyCounter] = useState(0);
  const [varTotalCount, setTotalCount] = useState(0);

  const [varPageSize] = useState(10);           //페이지당 레코드 수
  const [varPageNum, setPageNum] = useState(1);  //현재 페이지

  //검색 인자(변수)객체
  const [varSearchData, setSearchData] = useState({
    sdate: null,
    edate: null,
    searchoption: "all",
    searchstring: null
  });

  const readRecommendList = () => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("pageUnit", varPageSize);  //페이지당 레코드 수
    params.append("pageNum", varPageNum);  //페이지당 레코드 수
    params.append("stDate", varSearchData.sdate);  //시작일
    params.append("edDate", varSearchData.edate);  //종료일
    params.append("shOpt", varSearchData.searchoption);  //검색 조건
    params.append("shStr", varSearchData.searchstring);  //검색어
    axios.post(`${CONFIG.SERVER_HOST}/_get_employ_state_by_hunter`, params).then((res) => {
      if (res.data.retvalue === 1) {
        //setTotalCount(res.data.totalcount);
        setRecommendCounter(res.data.rcount);
        setRecommendApplyCounter(res.data.pcount);
        setTotalCount(res.data.totCount);
        const data = res.data.candidates.map((data, i) => (
          {
            er_idx: data.er_idx, //추천
            em_idx: data.em_idx, //채용공고
            ea_idx: data.ea_idx, //2022-11-05 공고지원 idx KSR
            em_headhunter: data.em_headhunter, //2022-11-05 헤드헌터 사용여부 KSR
            title: data.em_title,
            cname: data.com_name,
            tasks: data.vwc_name + ' > ' + data.vwd_name,
            vwc_name: data.vwc_name, //2022-11-05 직무 KSR
            vwd_name: data.vwd_name, //2022-11-05 직무 상세 KSR
            er_rdate: data.er_rdate, //2022-11-05 추천일 KSR
            ea_rdate: data.ea_rdate, //2022-11-05 지원일 KSR
            ems_name: data.ems_name, //2022-11-05 지원상태 KSR
            em_reward_recommend: data.em_reward_recommend, //2022-11-05 보상금 KSR
            emh_reward_recommend: data.emh_reward_recommend, //2022-11-05 보상금(헤드헌트) KSR
            em_reward_applicant: data.em_reward_applicant, // 2022-11-24 합격자 보상금 WEM
            emh_reward_applicant: data.emh_reward_applicant, // 2022-11-24 합격자 보상금(헤드헌터 전용관) => 신규 공고 등록 시에는 보상금 컬럼(em,emh) 동시 입력되지만 기존 DB 데이터 표시 위해서 사용 WEM
            reward: addThousandPoint(data.emh_reward_recommend) + " 원",
            //em_reward_recommend:addThousandPoint(data.em_reward_recommend)+" 원",//2022-11-05 KSR
            recommend: data.mem_name == null ? data.er_name : data.mem_name,
            regdate: ChangeDateStringOnlyDate(data.er_rdate),
            applydate: data.ea_idx === null ? "미지원" : ChangeDateStringOnlyDate(data.ea_rdate),
            //ems_name:data.ea_idx===null?"회원미가입":data.ems_name // 2022-11-05 ksr
          }
        ))
        console.log(data);
        setRecommendList(data); //원본 - 전체
      }
    });
  }

  useEffect(() => {
    readRecommendList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum]);

  return (
    <div className="container page_recommend">
      <HedingRecommendTop varRecommendCounter={varRecommendCounter} varRecommendApplyCounter={varRecommendApplyCounter} />
      <CompanyRecommendSearch varSearchData={varSearchData} setSearchData={setSearchData} readRecommendList={readRecommendList} />
      <HedingRecommendList varRecommendList={varRecommendList} setRecommendList={setRecommendList} />
      <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
    </div>
  )
}

export default MyHeding10;