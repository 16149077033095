/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Routes, Route } from "react-router-dom";

import AnnouncementWrite from "../Announcement/AnnouncementWrite";
import AnnouncementList from "../Announcement/AnnouncementList";
import AnnouncementModify from "../Announcement/AnnouncementModify";
import AnnouncementCopy from "../Announcement/AnnouncementCopy";
import SupportList from "../Announcement/SupportList";
import SupportView from "../Announcement/SupportView";
import ResumeView from "../Announcement/ResumeView";
import BannerBottom from "components/Banner/BannerBottom";

import HeaderCompany from "../../components/Layout/HeaderCompany";
import Footer from "../../components/Layout/Footer";

//페이지 접근 권한 체크
import RequireAuth from "../../components/Auth/RequireAuth";

import "css/Announcement.css";
import "css/Common.css";
import "css/Board.css";
import "css/Company.css";
import "css/MyCareer.css";
import "css/Sub.css";
import "css/Davehan.css";

const ComAnnouncement = () => {
  /* const location = useLocation(); */
  /*   const [varComAnnouncement, setComAnnouncement] = useState(0);
  
    useEffect(async () => {
      if (
        location !== null &&
        location.state !== null &&
        location.state.ComAnnouncement !== null
      ) {
        setComAnnouncement(location.state.ComAnnouncement);
      }
    }, [location]); */

  return (
    <>
      <HeaderCompany />
      <div id="SubWrap">
        <Routes>
          <Route path="/" element={
            <RequireAuth chkLevel={1} chkDepth={0}>
              < AnnouncementWrite />
            </RequireAuth>} />

          <Route path="/AnnouncementWrite" element={
            <RequireAuth chkLevel={1} chkDepth={0}>
              <AnnouncementWrite />
            </RequireAuth>} />

          {/* <Route path="/AnnouncementList" element={<AnnouncementList/>}/> */}
          <Route path="/AnnouncementList" element={
            <RequireAuth chkLevel={1} chkDepth={0}>
              <AnnouncementList />
            </RequireAuth>} />

          {/* <Route path="/AnnouncementModify/:em_idx" element={<AnnouncementModify/>}/> */}
          <Route path="/AnnouncementModify/:em_idx" element={
            <RequireAuth chkLevel={1} chkDepth={3}>
              <AnnouncementModify />
            </RequireAuth>} />

          {/* 공고복사 */}
          <Route path="/AnnouncementCopy/:em_idx" element={<AnnouncementCopy />} />


          {/* <Route path="/SupportList" element={<SupportList/>}/> */}
          <Route path="/SupportList" element={
            <RequireAuth chkLevel={1} chkDepth={0}>
              <SupportList />
            </RequireAuth>} />

          {/* <Route path="/SupportView" element={<SupportView/>}/> */}
          <Route path="/SupportView" element={
            <RequireAuth chkLevel={1} chkDepth={0}>
              <SupportView />
            </RequireAuth>} />

          {/* <Route path="/ResumeView" element={<ResumeView/>}/> */}
          <Route path="/ResumeView" element={
            <RequireAuth chkLevel={1} chkDepth={0}>
              <ResumeView />
            </RequireAuth>} />
        </Routes>
      </div>
      <BannerBottom />
      <Footer />
    </>
  );
};

export default ComAnnouncement;
