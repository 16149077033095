import { createContext } from "react";
import socketIo from "socket.io-client";

export const socket = socketIo(String(process.env.REACT_APP_CHAT_SERVER), {
  withCredentials: true,
});

export const SocketContext = createContext(socket);

export const SOCKET_EVENT = {
  JOIN_ROOM: "JOIN_ROOM",
  UPDATE_MESSAGE: "UPDATE_MESSAGE"
};

export const makeMessage = props => {
	const { roomno, nickname, typingMessage } = props;
	console.log("makeMessage", typingMessage);
  return {
		to: 0,
    roomno : roomno,
		type: 'CHAT',
    nickname: nickname,
    content: typingMessage,
		time: new Date().toLocaleString('ko-KR'),
		readYN: 'N'
  };
};

export const makeImgMessage = props => {
	const { roomno, nickname, typingMessage, imgURL } = props;
	console.log("makeImgMessage", typingMessage);
  return {
		to: 0,
    roomno : roomno,
		type: 'IMG',
    nickname: nickname,
    content: typingMessage,
    imgURL: imgURL,
		time: new Date().toLocaleString('ko-KR'),
		readYN: 'N'
  };
};

socket.on("connect", () => {
  console.log("CHAT : Server connected.");
});

socket.on("disconnect", () => {
  console.log("CHAT : Server disconnected.");
});

socket.on(SOCKET_EVENT.JOIN_ROOM, () => {
    console.log("CHAT : Joined room.");
});
