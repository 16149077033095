/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';
import CONFIG from '../../../configs/configs' ;
import axios from 'axios';
import './UsingPass.css';

function VoucherDetail() {
  const {vo_idx} = useParams();
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  //심사상태--------------------------------------------------------------------------------------------
  const [varVoucherDetailIdx, setVarVoucherDetailIdx] = useState(vo_idx);
  const [VoucherData, setVoucherData] = useState({
    vo_idx: 0,
    vo_index: 0,
    vo_name: "",
    vo_terms: 0,
    vo_sending: 0,
    vo_price: "0",
    vo_discount: "0",
    vo_amount: "0",
    vo_rdate: "",
    vo_status: 1  
  });

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  //최초에 실행
  useEffect(() => {
    if(vo_idx > 0) {
      const params = new URLSearchParams();
      params.append('vo_idx', vo_idx); 
      axios.post(`${CONFIG.SERVER_HOST}/voucher_detail`,params).then((res) => {
        console.log(res.data);
        console.log(res.data.results);
        if(res.data.retvalue === 1) {          
          setVoucherData({
              vo_idx: res.data.results[0].vo_idx,
              vo_index: res.data.results[0].vo_index,
              vo_name: res.data.results[0].vo_name,
              vo_terms: res.data.results[0].vo_terms,
              vo_sending: res.data.results[0].vo_sending,
              vo_price: res.data.results[0].vo_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              vo_amount: res.data.results[0].vo_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              vo_discount: res.data.results[0].vo_discount,
              vo_rdate: res.data.results[0].vo_rdate.substr(0,10),
              vo_status: res.data.results[0].vo_status
          });
          setVarVoucherDetailIdx(res.data.results[0].vo_idx);
        }
      });
    }
  },[]);

  //저장하기
  const updateVoucherDetail = () => {
    axios.post(`${CONFIG.SERVER_HOST}/voucher_update`,{
      vo_idx:varVoucherDetailIdx,
      vo_data:VoucherData
    }).then((res) => {
      if(res.data.retvalue === 1) document.location.href="/admin/voucherlist";
      else window.alert('정보 저장중에 오류가 발생했습니다.');
    });  
  }

  //상태변경
  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    var new_price, new_amount = 0;
    if(name === "vo_discount") {
      new_price = Number(VoucherData.vo_price.replace(/,/g,''));
      new_amount = (new_price-(new_price*value/100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      //console.log(VoucherData.vo_price,(Number(VoucherData.vo_price.replace(/,/g,'')) * value / 100),new_amount,value);
      setVoucherData(prevState => ({
        ...prevState,
        vo_amount: new_amount,
        vo_discount: value
      }));
    } else if(name === "vo_price") {
      var cur_price = Number(value.replace(/,/g,''));
      var cur_discount = Number(VoucherData.vo_discount);
      new_price = cur_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      new_amount = (cur_price - (cur_price * cur_discount/100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setVoucherData(prevState => ({
        ...prevState,
        vo_price: new_price,
        vo_amount: new_amount
      }));
    } else {
      setVoucherData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  }

  //표시-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="using_pass" />
      <Menu menuType="using_pass" menu="voucherdetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">이용권 등록
          <div className="path">
              <img src={iconhome} alt=""/>
              HOME<img src={arrImage} alt=""/>이용권 구매관리<img src={arrImage} alt=""/>이용권 등록
          </div>
        </div>
        <div className="content">
          <div className="main-box">
              <table className="write">
                <thead>
                </thead>
                <tbody>
                  <tr>
                    <th>이용권명</th>
                    <td className="write-input">
                      <input type="text" name="vo_name" onChange={handleChangeValue} value={VoucherData.vo_name} style={{width:"50%"}}/>  
                    </td>
                  </tr>
                  <tr>
                    <th>이용기간</th>
                    <td className="write-input">
                      <input type="text" name="vo_terms" onChange={handleChangeValue} value={VoucherData.vo_terms} style={{width:"20%"}}/> 일 
                    </td>
                  </tr>
                  <tr>
                    <th>제안발송</th>
                    <td className="write-input">
                      <input type="text" name="vo_sending" onChange={handleChangeValue} value={VoucherData.vo_sending} style={{width:"20%"}}/> 건 
                    </td>
                  </tr>
                  <tr>
                    <th>표준가격</th>
                    <td className="write-input">
                      <input type="text" name="vo_price" onChange={handleChangeValue} value={VoucherData.vo_price} style={{width:"20%"}}/> 원 
                    </td>
                  </tr>
                  <tr>
                    <th>할인가격</th>
                    <td className="write-input">
                      <input type="text" name="vo_amount" onChange={handleChangeValue} value={VoucherData.vo_amount} style={{width:"20%"}}/> 원 
                    </td>                  
                  </tr>
                  <tr>
                    <th>할인률</th>
                    <td className="write-input">
                      <input type="text" name="vo_discount" onChange={handleChangeValue} value={VoucherData.vo_discount} style={{width:"20%"}}/> % 
                    </td>                  
                  </tr>
                  <tr>
                    <th>노출순서</th>
                    <td className="write-input">
                      <input type="text" name="vo_index" onChange={handleChangeValue} value={VoucherData.vo_index} style={{width:"20%"}}/>  
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div className="main-box2">
              <div className="btn-center">                  
                  <Link to="/admin/voucherlist" className="btn line">취소</Link>
                  <button onClick={updateVoucherDetail} className="btn">등록</button>
              </div>
          </div>   
        </div>
      </div>
    </React.Fragment>
  )
}

export default VoucherDetail;