import React, { useEffect, useState }from "react";
import { addThousandPoint } from '../Common/Common';
import axios from 'axios';
//css
//components
import CONFIG from 'configs/configs';
//이미지

/*헤딩머니*/
function HedingMoney() {
    const user_idx = Number(localStorage.getItem('mem_idx'))
    const [varHMoneyBalance, setHMoneyBalance] = useState(0);
    const [varHMoneyExchange, setHMoneyExchange] = useState(0);
    useEffect(() => {
        console.log('user_idx',user_idx);
        const params = new URLSearchParams();
        params.append("user_idx", user_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_hedingmoney_balance2`,params).then((res) => {
            if(res.data.results?.length > 0){
              setHMoneyBalance(res.data.results[0]?.mh_paid);
            }  else {
              setHMoneyBalance(0);
            }
        });  
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

    //환전가능 헤딩머니 가져오는 api
    useEffect(()=>{
      const params = new URLSearchParams();
        params.append("mem_idx",user_idx)
        axios.post(`${CONFIG.SERVER_HOST}/_can_exchange`, params).then((res) => {
          if (res.data.retvalue === 1) {
            setHMoneyExchange(res.data.result?.mh_paid)
          }else{
            console.log(res.data.message)
          }
        });// eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
  return (
    <section className="my_money">
        <h3 className="title">헤딩머니</h3>
        <ul className="money_list">
            <li className="item">
                <h4 className="title_field">보유중인 헤딩머니</h4>
                <div className="point">{addThousandPoint(varHMoneyBalance)}<span className="won">원</span></div>
            </li>
            <li className="item">
                <h4 className="title_field">환전가능 헤딩머니</h4>
                <div className="point">{varHMoneyExchange >= 100000 ? addThousandPoint(varHMoneyExchange) : 0}<span className="won">원</span></div>
            </li>
        </ul>
    </section>
  );
}

export default HedingMoney;
