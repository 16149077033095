/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link } from "react-router-dom";
//css
import "css/Main.css";
import "css/Common.css";
// import "css/MyRecruit.css";
import "css/swiper-bundle.min.css";
import "css/Sub.css";
import "css/Davehan.css";

//이미지
import arrow_right from "img/sub/arrow_right.png";
/* import btn_stop from "img/main/btn_stop.png"  */

//components
/* import HeaderHeadHunter from "components/Layout/HeaderHeadHunter"; */
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import MainVisual from "components/Main/Visual";
import MainLink from "components/Main/Link";
import MainBookmarkList from "components/Main/MainBookmarkList";
import RecruitmanListTop from "components/Main/RecruitmanListTop.js";
import MainContentTitle from "components/Main/MainContentTitle";
import BannerBottom from "components/Banner/BannerBottom";
import MainTalkLists from "components/Main/MainTalkLists";
import MainIconBanner from "components/Main/MainIconBanner";
import MainNotice from "components/Main/MainNotice";
import MainCompanyBanner from "components/Main/MainCompanyBanner";
import MainSlide from "components/Main/MainSlide";

/*마이페이지>정보관리(개인)*/
function Main() {
  //검색할 채용공고형식 (1:공개/2:비공개/3:파견,알바,계약)
  const [varEmployType, setEmployType] = useState(0);
  //검색어
  const [varSearchString, setSearchString] = useState("");

  // 검색 실행
  const [varExecuteSearch, setExecuteSearch] = useState(0);

  //앱 팝업창 관리 스테이트
  const [appPopupShow, setAppPopupShow] = useState(false);

  const onSearchingHandler = (e) => {
    setExecuteSearch(1);
  };
  const onInputSearchString = (e) => {
    setSearchString(e.currentTarget.value);
  };



  return (
    <div id="main_wrap">
      <Header />
      <div id="main">
        <MainVisual />
        <MainLink />
        {/* 채용공고목록 */}
        <section className="main_recruit center_wrap">
          <RecruitmanListTop
            varEmployType={varEmployType}
            setEmployType={setEmployType}
            varSearchString={varSearchString}
            setSearchString={setSearchString}
            onInputSearchString={onInputSearchString}
            onSearchingHandler={onSearchingHandler}
          />
          <MainBookmarkList
            varHeadHunting={0}
            varEmployType={varEmployType}
            varSearchString={varSearchString}
            varExecuteSearch={varExecuteSearch}
            setExecuteSearch={setExecuteSearch}
          />
          <article className="btn_area">
            <Link
              to={`/MyRecruit/MainList2/${varEmployType > 0 ? varEmployType : -1}/1/20/0`}
              state={{ em_headhunter: 0, sString: "", em_type: varEmployType === 0 ? 1 : varEmployType }}
              className="button_more"
            >
              더보기
              <img src={arrow_right} alt="arrow_right" />
            </Link>
          </article>
        </section>
        {/* 슬라이드 */}
        <MainSlide />
        {/* 채용공고목록 - 해드헌터 */}
        <section className="main_recruit center_wrap">
          <MainContentTitle
            MainTitle="헤드"
            MainTitle02="헌터"
            MainTitle03="전용관"
            MainTitleLink="/MyRecruit/MainList/HeadHunter"
            MainStatusKey="sMode"
            MainStatusValue="0"
          />
          <MainBookmarkList
            varHeadHunting={1}
            // varEmployType={varEmployType}
            varSearchString={varSearchString}
          />
          <article className="btn_area">
            <Link
              to={"/MyRecruit/MainList2/4/1/20/0"}
              state={{ em_headhunter: 1, sString: "", em_type: 4 }}
              className="button_more"
            >
              더보기
              <img src={arrow_right} alt="arrow_right" />
            </Link>
          </article>
        </section>
        {/* 배너 */}
        <BannerBottom />
        {/* 이직톡 */}
        <section className="main_talk center_wrap">
          <MainContentTitle
            MainTitle="이직"
            MainTitle01="/구인"
            MainTitle02="톡"
            MainTitleLink="/MyTalk"
            MainStatusKey="mwt_idx"
            MainStatusValue="0"
          />
          <MainTalkLists />
          <article className="btn_area">
            <Link to={"/MyTalk/TalkList"} className="button_more">
              더보기
              <img src={arrow_right} alt="arrow_right" />
            </Link>
          </article>
        </section>
        <section className="main_text_list center_wrap">
          <MainNotice Title="공지" />
          <MainNotice Title="FAQ" />
        </section>
        <MainIconBanner setAppPopupShow={setAppPopupShow} appPopupShow={appPopupShow} />
        <MainCompanyBanner />
        <Footer />
      </div>
    </div>
  );
}

export default Main;
