/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import './Variables.css';

function ResumeVariables() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  //직장-부서종류--------------------------------------------------------------------------------------------
  const [varCompDepartment, setVarCompDepartment] = useState([]);
  const [varCompDepartmentAdd, setVarCompDepartmentAdd] = useState("");
  const [selectedCompDepartment, setSelectedCompDepartment] = useState("");
  
  //항목선택시
  const handleCompDepartment = (e) => {
    setSelectedCompDepartment(e.currentTarget.value);
  };
  //항목입력시
  const handleCompDepartmentAdd = (e) => {
    setVarCompDepartmentAdd(e.currentTarget.value);
  }
  //로그인 여부 확인
  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //최초에 실행
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_department`).then((res) => {
        setVarCompDepartment(res.data);
    });
  },[]);
  //선택항목 삭제
  const deleteCompDepartment = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vcd_idx', selectedCompDepartment);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_department_delete`,params).then((res) => {
      setVarCompDepartment(res.data);
      setSelectedCompDepartment("");
    }); 
  }
  //선택항목 추가
  const addCompDepartment = (e) => {
    if(varCompDepartmentAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vcd_name', varCompDepartmentAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_department_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarCompDepartmentAdd("");
        setVarCompDepartment(res.data.results);
        setSelectedCompDepartment(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }
  //직장-직책종류--------------------------------------------------------------------------------------------
  const [varCompPosition, setVarCompPosition] = useState([]);
  const [varCompPositionAdd, setVarCompPositionAdd] = useState("");
  const [selectedCompPosition, setSelectedCompPosition] = useState("");

  const handleCompPosition = (e) => {
    setSelectedCompPosition(e.currentTarget.value);
  };
  const handleCompPositionAdd = (e) => {
    setVarCompPositionAdd(e.currentTarget.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_position`).then((res) => {
        setVarCompPosition(res.data);
    });
  },[]);  
  //선택항목 삭제
  const deleteCompPosition = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vps_idx', selectedCompPosition);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_position_delete`,params).then((res) => {
      setVarCompPosition(res.data);
      setSelectedCompPosition("");
    }); 
  }
  //선택항목 추가
  const addCompPosition = (e) => {
    if(varCompPositionAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vps_name', varCompPositionAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_position_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarCompPositionAdd("");
        setVarCompPosition(res.data.results);
        setSelectedCompPosition(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }
  //기업유형--------------------------------------------------------------------------------------------
  const [varCompType, setVarCompType] = useState([]);
  const [varCompTypeAdd, setVarCompTypeAdd] = useState("");
  const [selectedCompType, setSelectedCompType] = useState("");
  const handleCompType = (e) => {
    setSelectedCompType(e.currentTarget.value);
  };
  const handleCompTypeAdd = (e) => {
    setVarCompTypeAdd(e.currentTarget.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_type`).then((res) => {
        setVarCompType(res.data);
    });
  },[]); 
  //선택항목 삭제
  const deleteCompType = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vct_idx', selectedCompType);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_type_delete`,params).then((res) => {
      setVarCompType(res.data);
      setSelectedCompType("");
    }); 
  }
  //선택항목 추가
  const addCompType = (e) => {
    if(varCompTypeAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vct_name', varCompTypeAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_type_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarCompTypeAdd("");
        setVarCompType(res.data.results);
        setSelectedCompType(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }
  //학력종류--------------------------------------------------------------------------------------------
  const [varEducationLevel, setVarEducationLevel] = useState([]);
  const [varEducationLevelAdd, setVarEducationLevelAdd] = useState("");
  const [selectedEducationLevel, setSelectedEducationLevel] = useState("");
  const handleEducationLevel = (e) => {
    setSelectedEducationLevel(e.currentTarget.value);
  };
  const handleEducationLevelAdd = (e) => {
    setVarEducationLevelAdd(e.currentTarget.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_education_level`).then((res) => {
        setVarEducationLevel(res.data);
    });
  },[]); 
  //선택항목 삭제
  const deleteEducationLevel = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vel_idx', selectedEducationLevel);
    axios.post(`${CONFIG.SERVER_HOST}/variable_education_level_delete`,params).then((res) => {
      setVarEducationLevel(res.data);
      setSelectedEducationLevel("");
    }); 
  }
  //선택항목 추가
  const addEducationLevel = (e) => {
    if(varEducationLevelAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vel_name', varEducationLevelAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_education_level_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarEducationLevelAdd("");
        setVarEducationLevel(res.data.results);
        setSelectedEducationLevel(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  } 
  //경력종류--------------------------------------------------------------------------------------------
  const [varWorkCareer, setVarWorkCareer] = useState([]);
  const [varWorkCareerAdd, setVarWorkCareerAdd] = useState("");
  const [selectedWorkCareer, setSelectedWorkCareer] = useState("");
  const handleWorkCareer = (e) => {
    setSelectedWorkCareer(e.currentTarget.value);
  };
  const handleWorkCareerAdd = (e) => {
    setVarWorkCareerAdd(e.currentTarget.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_career`).then((res) => {
        setVarWorkCareer(res.data);
    });
  },[]);  
  //선택항목 삭제
  const deleteWorkCareer = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vwa_idx', selectedWorkCareer);
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_career_delete`,params).then((res) => {
      setVarWorkCareer(res.data);
      setSelectedWorkCareer("");
    }); 
  }
  //선택항목 추가
  const addWorkCareer = (e) => {
    if(varWorkCareerAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vwa_name', varWorkCareerAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_career_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarWorkCareerAdd("");
        setVarWorkCareer(res.data.results);
        setSelectedWorkCareer(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  } 
  //직무종류--------------------------------------------------------------------------------------------
  const [varWorkCategory, setVarWorkCategory] = useState([]);
  const [varWorkCategoryAdd, setVarWorkCategoryAdd] = useState("");
  const [selectedWorkCategory, setSelectedWorkCategory] = useState("");
  const handleWorkCategory = async(e) => {//onChange
    const vwd_idx = Number(e.currentTarget.value);
    setSelectedWorkCategory(vwd_idx);
  };
  const handleWorkCategoryAdd = (e) => {
    setVarWorkCategoryAdd(e.currentTarget.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_category`).then((res) => {
        setVarWorkCategory(res.data);
    });
  },[]);  
  //선택항목 삭제
  const deleteWorkCategory = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vwc_idx', selectedWorkCategory);
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_category_delete`,params).then((res) => {
      setVarWorkCategory(res.data);
      setSelectedWorkCategory("");
    }); 
  }
  //선택항목 추가
  const addWorkCategory = (e) => {
    if(varWorkCategoryAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vwc_name', varWorkCategoryAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_category_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarWorkCategoryAdd("");
        setVarWorkCategory(res.data.results);
        setSelectedWorkCategory(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  } 

  //직무종류 클릭 -> 세부직무종류 불러오기
  const setWorkCategory = async(e) => {//click
    console.log("세부직무종류 불러오기");
    const vwc_idx = Number(e.currentTarget.value);
    await axios.post(`${CONFIG.SERVER_HOST}/variable_work_category_detail`,{vwc_idx:vwc_idx}).then((res) => {
      setVarWorkCategoryDetail(res.data);
    });
    
  };
  //직무세부종류--------------------------------------------------------------------------------------------
  const [varWorkCategoryDetail, setVarWorkCategoryDetail] = useState([]);
  const [varWorkCategoryDetailAdd, setVarWorkCategoryDetailAdd] = useState("");
  const [selectedWorkCategoryDetail, setSelectedWorkCategoryDetail] = useState("");
  const handleWorkCategoryDetail = async(e) => {
    setSelectedWorkCategoryDetail(e.currentTarget.value);
  };
  const handleWorkCategoryDetailAdd = (e) => {
    setVarWorkCategoryDetailAdd(e.currentTarget.value);
  }
  useEffect(() => {
    const params = new URLSearchParams();
    params.append('vwc_idx', selectedWorkCategory);
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_category_detail`,params).then((res) => {
        setVarWorkCategoryDetail(res.data);
    });
  },[]);  

  //선택항목 삭제
  const deleteWorkCategoryDetail = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vwc_idx', selectedWorkCategory);
    params.append('vwd_idx', selectedWorkCategoryDetail);
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_category_detail_delete`,params).then((res) => {
      setVarWorkCategoryDetail(res.data);
      setSelectedWorkCategoryDetail("");
    }); 
  }
  //선택항목 추가
  const addWorkCategoryDetail = (e) => {
    if(selectedWorkCategory === ""){
      alert("상위 직무종류를 먼저 선택하세요.");
      return;
    }
    if(varWorkCategoryDetailAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vwc_idx', selectedWorkCategory);
    params.append('vwd_name', varWorkCategoryDetailAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_work_category_detail_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setVarWorkCategoryDetailAdd("");
        setVarWorkCategoryDetail(res.data.results);
        setSelectedWorkCategoryDetail(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
      /*
      if(res.data === '') {
        alert('이미 존재하므로 추가할 수 없습니다.'); return;
      }
      setVarWorkCategoryDetailAdd("");
      setVarWorkCategoryDetail(res.data[1].results);
      setSelectedWorkCategoryDetail(res.data[0].newid);
      */
    }); 
  }  
  
  //2022-08-07 KSR 하단에 3pepth 직무종류 있었던 같으나, 프론트쪽 보니 2depth까지만 사용하는 것 확인
  // ...220807_han_old.js로 백업하고 해당내용 삭제

  //variable_comp_employment_preference
  //취업우대사항 종류--------------------------------------------------------------------------------------------
  const [varCompEmploymentPreference, setvarCompEmploymentPreference] = useState([]);
  const [varCompEmploymentPreferenceAdd, setvarCompEmploymentPreferenceAdd] = useState("");
  const [selectedCompEmploymentPreference, setSelectedCompEmploymentPreference] = useState("");
  const handleCompEmploymentPreference = async(e) => {
    // alert(e.currentTarget.value);
    setSelectedCompEmploymentPreference(e.currentTarget.value);
  };
  const handleCompEmploymentPreferenceAdd = (e) => {
    setvarCompEmploymentPreferenceAdd(e.currentTarget.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_employment_preference`).then((res) => {
      setvarCompEmploymentPreference(res.data);
      setSelectedCompEmploymentPreference(res.data[0]?.vcep_idx > 0 ? res.data[0]?.vcep_idx : "");
    });
  },[]);  

  //취업우대사항 선택항목 삭제
  const deleteCompEmploymentPreference = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('vcep_idx', selectedCompEmploymentPreference);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_employment_preference_delete`,params).then((res) => {
      setvarCompEmploymentPreference(res.data);
      setSelectedCompEmploymentPreference(varCompEmploymentPreference[0]?.vcep_idx > 0 ? varCompEmploymentPreference[0]?.vcep_idx : "");
      //setSelectedCompEmploymentPreference(varCompEmploymentPreference[0]?.vcep_idx);
    }); 
  }
  //취업우대사항 선택항목 추가
  const addCompEmploymentPreference = (e) => {
    if(varCompEmploymentPreferenceAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    //params.append('vcep_idx', selectedCompEmploymentPreference);
    params.append('vcep_content', varCompEmploymentPreferenceAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_comp_employment_preference_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setvarCompEmploymentPreferenceAdd("");
        setvarCompEmploymentPreference(res.data.results);
        setSelectedCompEmploymentPreference(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }  
  
  /*------------------------------------------------------------------------------*/
  //언어레벨 종류--------------------------------------------------------------------------------------------
  const [varLanguageLevel, setLanguageLevel] = useState([]);
  const [varLanguageLevelAdd, setLanguageLevelAdd] = useState("");
  const [selectedLanguageLevel, setSelectedLanguageLevel] = useState("");
  const handleLanguageLevel = async(e) => {
    setSelectedLanguageLevel(e.currentTarget.value);
  };
  const handleLanguageLevelAdd = (e) => {
    setLanguageLevelAdd(e.currentTarget.value);
  }
  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/var_language_level`).then((res) => {
      setLanguageLevel(res.data);
      setSelectedLanguageLevel(res.data[0]?.lal_idx > 0 ? res.data[0]?.lal_idx : "");
    });
  },[]);  

  //언어레벨 선택항목 삭제
  const deleteLanguageLevel = (e) => {
    if(!window.confirm('삭제하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('lal_idx', selectedLanguageLevel);
    axios.post(`${CONFIG.SERVER_HOST}/var_language_level_delete`,params).then((res) => {
      setLanguageLevel(res.data);
      setSelectedLanguageLevel(varLanguageLevel[0]?.lal_idx > 0 ? varLanguageLevel[0]?.lal_idx : "");
      //setSelectedCompEmploymentPreference(varCompEmploymentPreference[0]?.vcep_idx);
    }); 
  }
  //언어레벨 선택항목 추가
  const addLanguageLevel = (e) => {
    if(varLanguageLevelAdd === ""){
      alert("항목을 입력하세요.");
      return;
    }
    if(!window.confirm('추가하시겠습니까?')) return;
    const params = new URLSearchParams();
    params.append('lal_name', varLanguageLevelAdd);
    axios.post(`${CONFIG.SERVER_HOST}/variable_language_level_add`,params).then((res) => {
      if(res.data.retvalue === 1){//성공
        setLanguageLevelAdd("");
        setLanguageLevel(res.data.results);
        setSelectedLanguageLevel(res.data.newid);
      }else{//실패
        alert(res.data.message);
      }
    }); 
  }  

  //  //언어레벨 종류--------------------------------------------------------------------------------------------
  //  const [careerTip, setCareerTip] = useState([]);
  //  const [careerTipAdd, setCareerTipAdd] = useState("");
  //  const [selectedCareerTip, setSelectedCareerTip] = useState("");
  //  const handleCareerTip = async(e) => {
  //   setSelectedCareerTip(e.currentTarget.value);
  //  };
  //  const handleCareerTipAdd = (e) => {
  //   setCareerTipAdd(e.currentTarget.value);
  //  }
  //  useEffect(() => {
  //    axios.post(`${CONFIG.SERVER_HOST}/careertip`).then((res) => {
  //      setCareerTip(res.data);
  //      setSelectedCareerTip(res.data[0]?.career_idx > 0 ? res.data[0]?.career_idx : "");
  //    });
  //  },[]);  
 
  //  //이력서 TIP 선택항목 삭제
  //  const deleteCareerTip = (e) => {
  //    if(!window.confirm('삭제하시겠습니까?')) return;
  //    const params = new URLSearchParams();
  //    params.append('career_idx', selectedCareerTip);
  //    axios.post(`${CONFIG.SERVER_HOST}/career_tip_delete`,params).then((res) => {
  //      setCareerTip(res.data);
  //      setSelectedCareerTip(careerTip[0]?.lal_idx > 0 ? careerTip[0]?.lal_idx : "");
  //    }); 
  //  }
  //  //언어레벨 선택항목 추가
  //  const addCareerTip = () => {
  //    if(careerTipAdd === ""){
  //      alert("항목을 입력하세요.");
  //      return;
  //    }
  //    if(!window.confirm('추가하시겠습니까?')) return;
  //    const params = new URLSearchParams();
  //    params.append('career_tip_title', careerTipAdd);
  //    axios.post(`${CONFIG.SERVER_HOST}/variable_careertip_add`,params).then((res) => {
  //      if(res.data.retvalue === 1){//성공
  //        setCareerTipAdd("");
  //        setCareerTip(res.data.results);
  //        setSelectedCareerTip(res.data.newid);
  //      }else{//실패
  //        alert(res.data.message);
  //      }
  //    }); 
  //  }  
  
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="variables" />
      <Menu menuType="variables" menu="resumevariables" />
      <div id="contents" className="ad_contents">
        <div className="tit">이력 항목 관리
          <div className="path">
              <img src={iconhome} alt=""/>
              HOME<img src={arrImage} alt=""/>선택항목관리<img src={arrImage} alt=""/>이력 항목 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
              <table className="select-list">
                <tr>
                  <th>직장-부서종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleCompDepartment(e)} value={selectedCompDepartment}>
                      { varCompDepartment.length > 0 ? varCompDepartment.map((data,i) => (
                        <option key={"depart"+i} value={data.vcd_idx}>{data.vcd_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteCompDepartment(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input"><input type="text" onChange={handleCompDepartmentAdd} value={varCompDepartmentAdd} onKeyDown={(e) => { if (e.key === 'Enter') addCompDepartment(e) }}/></td>
                  <td className="select-addition"><button onClick={(e)=>addCompDepartment(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                <tr>
                  <th>직장-직책종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleCompPosition(e)} value={selectedCompPosition}>
                      { varCompPosition.length > 0 ? varCompPosition.map((data,i) => (
                        <option key={"pos"+i} value={data.vps_idx}>{data.vps_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteCompPosition(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input"><input type="text" onChange={handleCompPositionAdd} value={varCompPositionAdd} onKeyDown={(e) => { if (e.key === 'Enter') addCompPosition(e) }}/></td>
                  <td className="select-addition"><button onClick={(e)=>addCompPosition(e)} className="select-btn-add">추가하기</button></td>
                </tr> 

                <tr>
                  <th>기업유형</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleCompType(e)} value={selectedCompType}>
                      { varCompType.length > 0 ? varCompType.map((data,i) => (
                        <option key={"comtp"+i} value={data.vct_idx}>{data.vct_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteCompType(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={(e)=>handleCompTypeAdd(e)} value={varCompTypeAdd} onKeyDown={(e) => { if (e.key === 'Enter') addCompType(e) }}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addCompType(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                <tr>
                  <th>학력종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleEducationLevel(e)} value={selectedEducationLevel}>
                      { varEducationLevel.length > 0 ? varEducationLevel.map((data,i) => (
                        <option key={"edu"+i} value={data.vel_idx}>{data.vel_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteEducationLevel(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleEducationLevelAdd} value={varEducationLevelAdd} onKeyDown={(e) => { if (e.key === 'Enter') addEducationLevel(e) }}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addEducationLevel(e)} className="select-btn-add">추가하기</button></td>
                </tr> 

                <tr>
                  <th>경력 종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleWorkCareer(e)} value={selectedWorkCareer}>
                      { varWorkCareer.length > 0 ? varWorkCareer.map((data,i) => (
                        <option key={"career"+i} value={data.vwa_idx}>{data.vwa_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteWorkCareer(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleWorkCareerAdd} value={varWorkCareerAdd} onKeyDown={(e) => { if (e.key === 'Enter') addWorkCareer(e) }}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addWorkCareer(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                <tr>
                  <th>직무 종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleWorkCategory(e)} value={selectedWorkCategory}>
                      { varWorkCategory.length > 0 ? varWorkCategory.map((data,i) => (
                        <option key={"work"+i} value={data.vwc_idx} onClick={(e) => setWorkCategory(e)}>{data.vwc_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteWorkCategory(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleWorkCategoryAdd} value={varWorkCategoryAdd} onKeyDown={(e) => { if (e.key === 'Enter') addWorkCategory(e) }}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addWorkCategory(e)} className="select-btn-add">추가하기</button></td>
                </tr>

                <tr>
                  <th>세부직무 종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleWorkCategoryDetail(e)} value={selectedWorkCategoryDetail}>
                      { varWorkCategoryDetail.length > 0 ? varWorkCategoryDetail.map((data,i) => (
                        <option key={"workd"+i} value={data.vwd_idx} >{data.vwd_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteWorkCategoryDetail(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleWorkCategoryDetailAdd} value={varWorkCategoryDetailAdd} onKeyDown={(e) => { if (e.key === 'Enter') addWorkCategoryDetail(e) }}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addWorkCategoryDetail(e)} className="select-btn-add">추가하기</button></td>
                </tr> 
                <tr>
                  <th>취업우대사항 종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleCompEmploymentPreference(e)} value={selectedCompEmploymentPreference}>
                      { varCompEmploymentPreference.length > 0 ? varCompEmploymentPreference.map((data,i) => (
                        <option key={"workd"+i} value={data.vcep_idx} >{data.vcep_content}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteCompEmploymentPreference(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleCompEmploymentPreferenceAdd} value={varCompEmploymentPreferenceAdd} onKeyDown={(e) => { if (e.key === 'Enter') addCompEmploymentPreference(e) }}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addCompEmploymentPreference(e)} className="select-btn-add">추가하기</button></td>
                </tr>
                <tr>
                  <th>어학레벨 종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleLanguageLevel(e)} value={selectedLanguageLevel}>
                      { varLanguageLevel.length > 0 ? varLanguageLevel.map((data,i) => (
                        <option key={"workd"+i} value={data.lal_idx} >{data.lal_name}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteLanguageLevel(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleLanguageLevelAdd} value={varLanguageLevelAdd} onKeyDown={(e) => { if (e.key === 'Enter') addLanguageLevel(e) }}/>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addLanguageLevel(e)} className="select-btn-add">추가하기</button></td>
                </tr>
                {/* <tr>
                  <th>이력서TIP 종류</th>
                  <td className="select-items">
                    <select size="5" onChange={(e)=>handleCareerTip(e)} value={selectedCareerTip}>
                      { careerTip.length > 0 ? careerTip.map((data,i) => (
                        <option key={"workd"+i} value={data.career_idx} >{data.career_tip_title}</option>     
                      )) : null }
                    </select>
                  </td>
                  <td className="select-addition"><button onClick={(e)=>deleteCareerTip(e)} className="select-btn-delete">삭제</button></td>
                  <td className="select-addition sel-label"><label>추가항목</label></td>
                  <td className="select-input">
                    <input type="text" onChange={handleCareerTipAdd} value={careerTipAdd} onKeyDown={(e) => { if (e.key === 'Enter') addCareerTip(e) }} />
                  </td>
                  <td className="select-addition"><button onClick={(e)=>addCareerTip(e)} className="select-btn-add">추가하기</button></td>
                </tr> */}
              </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResumeVariables;