import React, { useState, useEffect } from 'react';

//components
import LeaveInput from 'components/My/LeaveInput';
import axios from 'axios';
import CONFIG from 'configs/configs';
import { useNavigate } from 'react-router-dom';

import 'css/Common.css';
import 'css/Sub.css';
import 'css/My.css';
// import BannerBottom from 'components/Banner/BannerBottom';


/*마이페이지>정보관리(개인)>회원탈퇴*/
function Leave({ onSetIsLeave }) {
  const navigate = useNavigate();

  const user_idx = localStorage.getItem("mem_idx");
  const user_type = localStorage.getItem("mem_type");
  const [varInputs, setInputs] = useState({reason: '',password: ''});
  const [showPasswordInput, setShowPasswordInput] = useState(true);

  //입력창 정보 재저장
  const onChangeHandler = e => {
    const { name, value } = e.currentTarget;
    setInputs({...varInputs,[name]: value});
    console.log(varInputs.reason, varInputs.password);
  }

  //회원탈퇴 시행
  const expireSubmitHandler = (e) => {
    e.preventDefault();

    if (!varInputs.reason) {
      alert('탈퇴 사유를 입력해주세요.');
      return;
    }

    // sns 회원은 비밀번호가 없기 때문에 그냥 탈퇴 처리.
    if(user_type !== '0' && !showPasswordInput) {
      //회원 탈퇴 저장
      const param = new URLSearchParams();
      param.append('mem_reason', varInputs.reason);
      param.append('mem_idx', user_idx);
      axios.post(`${CONFIG.SERVER_HOST}/_expire_member`, param).then(res => {
        alert(res.data.message);
        if(res.data.retvalue === 1) {
          localStorage.clear(); //로그인회원정보 클리어
          navigate('/main');
        }
      }).catch(err => {
          console.log(err);
      })
      return;
    }

    if (!varInputs.password) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    //비밀번호 점검
    const param = new URLSearchParams();
    param.append('mem_pwd', varInputs.password);
    param.append('mem_idx', user_idx);
    axios.post(`${CONFIG.SERVER_HOST}/passwordCheck`, param).then(res => {
      console.log(res.data.tcount);
      if (res.data.tcount === 0) {
        alert('입력하신 비밀번호가 일치하지 않습니다.');
      } else {
        //회원 탈퇴 저장
        const param = new URLSearchParams();
        param.append('mem_reason', varInputs.reason);
        param.append('mem_idx', user_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_expire_member`, param).then(res => {
          alert(res.data.message);
          if(res.data.retvalue === 1) {
            localStorage.clear(); //로그인회원정보 클리어
            navigate('/main');
          }
        }).catch(err => {
            console.log(err);
        })
      }
    })
  }

  useEffect(() => {
    if(user_type !== '0') {
      setShowPasswordInput(false);
    }
  }, [user_type]);

  return (
    <>
        <section className="container page_leave">
            <article className="box box_content">
            {/*회원탈퇴*/}
                <h3 className="my_title">회원탈퇴안내 {typeof user_type}</h3>
                <ul className="list_dot text_type">
                    <li>
                        회원탈퇴 후 개인정보 및 활동내역은 개인정보처리방침에 따라
                        삭제됩니다.
                    </li>
                    <li>삭제된 정보는 복구가 불가능합니다.</li>
                    <li>같은 SNS아이디로 재가입시 신규회원으로 등록됩니다.</li>
                    <li>
                        회원 탈퇴 시 적립된 헤딩머니는 보상하지 않으니, 탈퇴 전에 환전하시기
                        바랍니다.
                    </li>
                </ul>
                {/*입력테이블*/}
                <LeaveInput varInputs={varInputs} showPasswordFlag={showPasswordInput} onChangeHandler={(e)=>onChangeHandler(e)} />
                <p className="tip_field">※ 탈퇴하기 선택 시 회원정보에 등록된 SNS 재 인증 후 탈퇴 처리됩니다. 탈퇴 후에는 메인 화면으로 이동합니다.</p>
            </article>
            {/*버튼*/}
            <div className="btn_bottom_area center_wrap">
                <div onClick={(e)=>onSetIsLeave(e,false)} className="btn white big">취소</div>
                <div onClick={(e)=>expireSubmitHandler(e)} className="btn big">탈퇴하기</div>
            </div>
        </section>
    </>
  )
}

export default Leave
