import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from 'configs/configs';
import { addThousandPoint } from '../Common/Common';


function MyMoneyTop({ varHMoneyBalance, setSubMemberIndex, varSubMemberIndex, varSubMemberId, user_idx }) {
  const mem_nickname = localStorage.getItem('mem_nickname');
  const mcm_idx = Number(localStorage.getItem('mcm_idx'));
  const mem_mode = Number(localStorage.getItem('mem_mode'));
  const [varHMoneyExchange, setHMoneyExchange] = useState(0); 
  const [varSubMemberList, setSubMemberList] = useState([]);


  //기업 서브회원 목록 가져오기
  const getSubMembers = async () => {
    const params = new URLSearchParams();
    params.append("mcm_idx", mcm_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_submember_list`, params).then((res) => {
      if (res.data.retvalue === 1) {
        if (res.data.results?.length > 0) {
          const datas = res.data.results;
          setSubMemberList(datas);
        }
      }
    });
  }

  //서브회원목록보기
  const onShowSubMember = (e) => {
    const submemList = document.querySelector('.select_div');
    if (submemList.classList.contains('hide')) submemList.classList.remove('hide');
  }

  //서브회원 선택
  const onSelectSubMember = (e, svalue) => {
    const submemList = document.querySelector('.select_div');
    const valueArray = svalue.split('|');
    if (!submemList.classList.contains('hide')) submemList.classList.add('hide')
    setSubMemberIndex(Number(valueArray[0]));
    document.getElementById('subman_id').innerText = valueArray[1] + "님";
  }

  //서브회원목록보기 - 기업 또는 써치폼 회원 => 2022-12-15 기업, 헤드헌터인 경우에만 호출 WEM
  useEffect(() => {
    if (mem_mode === 1 || mem_mode === 2) {
      getSubMembers();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExchangeClick = () => {
    if (addThousandPoint(varHMoneyExchange) === 0) {
      window.alert("환전 가능한 헤딩머니가 0원입니다.");
    }
  };

  //환전가능 헤딩머니 가져오는 api
  useEffect(()=>{
    const params = new URLSearchParams();
      mem_mode === 0 ? params.append("mem_idx",user_idx) : params.append("mem_idx",varSubMemberIndex)
      axios.post(`${CONFIG.SERVER_HOST}/_can_exchange`, params).then((res) => {
        if (res.data.retvalue === 1) {
          setHMoneyExchange(res.data.result?.mh_paid)
        }else{
          console.log(res.data.message)
        }
      });// eslint-disable-next-line react-hooks/exhaustive-deps
  },[varSubMemberIndex])

  return (
    <section className="box box_content top">
      {/* 일반사용자 또는 프리랜서 => 2022-12-15 일반사용자인 경우만(프린랜서 없음)*/}
      {mem_mode === 0 ? (
        <h3 className="my_title">{mem_nickname}님<span className="info_txt">적립한 헤딩머니 내역입니다.</span></h3>
      ) : /* 기업사용자 또는 써치폼 */ (
        <article className="my_title">
          <div className="select_wrap">
            <button className="selected" id="subman_id" onClick={(e) => onShowSubMember(e)}>{varSubMemberId}님</button>
            <ul className="select_div hide">
              {varSubMemberList.map((subman, index) => (
                <li key={"sm_" + index}><button className="item" onClick={(e) => onSelectSubMember(e, subman.mem_idx + '|' + subman.mem_id)}>{subman.mem_id}</button></li>
              ))}
            </ul>
          </div>
          <span className="info_txt">적립한 헤딩머니 내역입니다.</span>
        </article>
      )}
      <ul className="state_wrap">
        <li className="state_box">
          <h4 className="title">보유중인 헤딩머니</h4>
          <div className="num_wrap">
            <p className="num">{addThousandPoint(varHMoneyBalance)}<span className="won">원</span></p>
          </div>
        </li>
        <li className="state_box">
          <h4 className="title">환전가능 헤딩머니</h4>
          <div className="num_wrap">
            <p className="num">{addThousandPoint(varHMoneyExchange)}<span className="won">원</span></p>
            {addThousandPoint(varHMoneyExchange) === 0 ? (
            <button className="btn"
               onClick={handleExchangeClick}>
               환전하기
               </button>
            ) : (
              <Link
                to="../MyMoneyChangeWrite"
                state={{ varHMoneyBalance: { varHMoneyBalance }, varHMoneyExchange: {varHMoneyExchange} }}
                className="btn"
              >
                환전하기
              </Link>
            )}
          </div>
        </li>
      </ul>
    </section>
  );
}

export default MyMoneyTop;