/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';

const TossPaySuccess = () => {
  const navigator = useNavigate();
  const [varPayments, setPayments] = useState({
    orderId: "",
    paymentKey: "",
    amount: 0
  });

  const [varCode, setCode] = useState(null);
  const [varDebug, setDebug] = useState([]);
  console.log(window.location.href);

  useEffect(() => {
    if (window.location !== undefined && window.location.href !== null) {
      const getUrl = window.location.href;
      //console.log(getUrl);
      setDebug([...varDebug,{getUrl: setDebug}]);
      let temp = getUrl.split('?'); //전달값 전체
      let data = temp[1].split('&');  //전달변수들 항목으로 분리
      console.log(data);
      let newForms = {};
      let orderCode = "";
      for (let i = 0; i < data.length; i++) {
        let opt = data[i].split('=');

        if (opt[0] === "orderId") {
          orderCode = opt[1];
          Object.assign(newForms, { orderId: opt[1] });
        } else if (opt[0] === "paymentKey") {
          Object.assign(newForms, { paymentKey: opt[1] });
        } else if (opt[0] === "amount") {
          Object.assign(newForms, { amount: opt[1] });
        }
      }
      setPayments(newForms);  //orderID, paymentKey, amount
      setCode(orderCode);     //주문번호
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);
  console.log(varPayments);

  useEffect(() => {
    if (varCode !== null) {
      approvalPayment(varCode);//승인요청
    }
  }, [varCode])

  //PG :: 승인요청
  const approvalPayment = (orderCode) => {
    //2022-11-16 KSR
    //let auth_code = 'test_sk_ADpexMgkW36WXm21bkEVGbR5ozO0';
    let auth_code = CONFIG.tossSecretKey;

    const params = new URLSearchParams();
    params.append("or_code", orderCode);
    params.append("or_status", 1);
    //이용권구매 - 결제완료 처리

    fetch('https://api.tosspayments.com/v1/payments/confirm', {
      method: 'POST',
      headers: {
        'Authorization': `Basic ${Buffer.from(auth_code + ":").toString("base64")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        'amount': varPayments.amount,
        'orderId': varPayments.orderId,
        'paymentKey': varPayments.paymentKey
      }),
    }).then(function (response) {
      if(response.status === 200){//PG사 승인 성공
        axios.post(`${CONFIG.SERVER_HOST}/_update_voucher_order`, params).then((res) => {
          if(res.data.retvalue === 1) {//이용권 결제 완료처리
            console.log("res.data", res.data);
            window.alert('결제가 완료되었습니다!');
            navigator('/Company/ComHeding/MyHeding5');
          }else{//PG사 승인 실패
            alert('PG사 결제에 성공하였으나, 결제완료 처리에 실패하였습니다.\n고객센터에 문의하세요.');
            navigator('/Company/ComVoucher');
          }
        });
      }else{//승인실패.
        alert('PG사 결제 승인에 실패하였습니다.');
        navigator('/Company/ComVoucher');
      }
    }).catch(function (error) {
      console.error(error);
      alert('PG사 승인 요청에 실패하였습니다.\n고객센터에 문의하세요.');
      navigator('/Company/ComVoucher');
    });
  }

  return (
    <div>
      {/* <button onclick="javascript:approvalPayment()">결제인증</button>  */}
      <p id="output">Loading...</p>
    </div>
  );
}

export default TossPaySuccess;