import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import stopImg from 'img/main/btn_stop.png';
import btn_arrow_off from 'img/main/btn_arrow_off.png';
import axios from 'axios'
import CONFIG from 'configs/configs'
// import banner01 from "img/main/banner01.png"
// import banner02 from "img/main/banner02.png"
// import banner03 from "img/main/banner03.png"
// import banner04 from "img/main/banner04.png"


// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
//import {Swiper, SwiperSlide} from 'swiper/swiper-bundle.min.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.min.css'

SwiperCore.use([Autoplay, Pagination]);

function MainSlide() {
  const [Loding, setLoading] = useState(false);// eslint-disable-line no-unused-vars
  const slideFolder = `${CONFIG.SERVER_HOST}/banner/`;
    const [banners,setBanners] = useState([]);
    const swiperRef = useRef(null);
    const [BtnState, setBtnState] = useState(false);
    const [varPage, setPage] = useState(0);

    const swiper_start= () => {
        swiperRef.current.swiper.autoplay.start();
        setBtnState(false);
    };

    const swiper_stop= () => {
        swiperRef.current.swiper.autoplay.stop();
        setBtnState(true);
    };

    //현재 슬라이드 페이지 표시
    const onChangeSlidePage = () => {
      if(varPage === 4) setPage(1);
      else setPage(varPage+1);
    }

    const getMainBanner = async () => {
      try {
        setLoading(true);
        await axios.post(`${CONFIG.SERVER_HOST}/_get_main_banners`).then((res) => {
          console.log(res.data);
          if (res.data.retvalue === 1) {
            const datas = res.data.results;
            const sliders = datas.filter(element => element.slide_ufile !== "");
            //채용공고전체
            setBanners(sliders);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      getMainBanner();
      return () => setLoading(false); // cleanup function을 이용
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="main_banner center_wrap">
            <div className="main_banner_slider">
                <ul className="swiper-wrapper">
                    <Swiper 
                        // grabCursor={true}
                        // effect={"creative"}
                        // creativeEffect={{
                        //   prev: {
                        //     shadow: true,
                        //     translate: ["-120%", 0, -500],
                        //   },
                        //   next: {
                        //     shadow: true,
                        //     translate: ["120%", 0, -500],
                        //   },
                        // }}
                        ref={swiperRef}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        pagination={{el: '.swiper-pagination', clickable: true, renderBullet: function (index, className) {
                            return '<a className="' + className + '">' + (index) + '</a>';}}}
                        // modules={[EffectCreative]}
                        // navigation={{nextEl: '.swiper-button-next',prevEl: '.swiper-button-prev',}}
                        onSlideChange={() => onChangeSlidePage()}
                        className="mySwiper2"
                    >
                      {banners.map((el, i) => (
                        <SwiperSlide key={i}>
                          {!el.banner_link.includes("http") || !el.banner_link.includes("https")? 
                          <Link className="button" to={el.banner_link} state={{ em_headhunter: 0, em_type: 1, sString:"" }}> <img src={slideFolder+el.banner_ufile} style={{ width: '100%' }} alt="" /></Link> 
                          : <a className="button" href={el.banner_link === "" ? "/main" : el.banner_link}><img src={slideFolder+el.banner_ufile} style={{ width: '100%' }} alt="" /></a>}
                        </SwiperSlide>
                      ))}
                        {/* <SwiperSlide><img src={banner02} style={{ width: '100%' }} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={banner03} style={{ width: '100%' }} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={banner04} style={{ width: '100%' }} alt="" /></SwiperSlide> */}
                    </Swiper>
                </ul>
            </div>
            <div className="btn_area">
                <div className="pagination swiper-pagination" style={{fontSize:"14px"}}>
                  <span className="paging_on"></span>
                </div>&nbsp;&nbsp;
                {BtnState === false ?
                  <button className="btn_stop"><img src={stopImg} onClick={()=> swiper_stop()} alt="" /></button>
                :
                  <img src={btn_arrow_off} onClick={()=> swiper_start()} alt="" />
                }
            
            </div>
        </section>
    );
}

export default MainSlide;
