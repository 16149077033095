/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CONFIG from '../../configs/configs';
import { useNavigate } from "react-router-dom";
//css
import "css/Board.css";

//컴포넌트
import Paging from '../../components/Layout/Paging';
import { ChangeDate } from '../Common/Common';

function MyNoticeList({ NumReset }) {
  const user_idx = localStorage.getItem('mem_idx');
  const mem_mode = localStorage.getItem('mem_mode')  //회원구분
  const navigation = useNavigate();
  const [varInitData, setInitData] = useState([]);
  const [varPageList, setPageList] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //최초
  const getMessageData = async () => {
    try {
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("mem_mode", mem_mode);
      await axios.post(`${CONFIG.SERVER_HOST}/myMessage`, params).then(res => {
        if (res.data.retvalue === 1) {
          setTotalCount(res.data.results.length);
          setInitData(res.data.results.reverse());
        } else {
          setInitData([]);
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getMessageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varInitData.length === 0) {
      setPageList([]);
      return;
    }
    var copyData = [...varInitData];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    setPageList(copyData);
  }
  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varInitData]);

  const onShownMessage = async (e, ms_idx, ms_url) => {
    e.preventDefault();
    // if(!window.confirm('선택하신 항목을 열람으로 처리합니까?')) {
    //   return;
    // }
    try {
      const params = new URLSearchParams();
      params.append("ms_idx", ms_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/myMessage_shown`, params).then(res => {
        if (res.data.retvalue === 1) {
          NumReset();
          if (isNaN(Number(ms_url))) {
            if (ms_url !== "") {
              navigation(ms_url);
            }
          } else {
            const finsIndex = varPageList.findIndex(element => element.ms_idx === ms_idx);
            let copyData = [...varPageList];
            copyData[finsIndex] = { ...copyData[finsIndex], ms_shown: 1 };
            setPageList(copyData);
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }


  const onPageMove = async (ms_url) => {
    try {
      if (isNaN(Number(ms_url))) {
        if (ms_url !== "") {
          navigation(ms_url);
        }
      }

    } catch (error) {
      console.log(error)
    }
  };
  return (
    <>
      <table className="table_list01">
        <thead>
          <tr>
            <th className="th_title">알림내용</th>
            <th className="th_date">발송일자</th>
          </tr>
        </thead>
        <tbody>
          {varTotalCount === 0 ? <tr><td colSpan="2">알림내역이 없습니다.</td></tr>
            : varPageList?.map((data, i) => (
              <tr key={'notice_' + i}>
                {data?.ms_shown === 0 ?
                  <td className="td_title td_pointer " onClick={(e) => onShownMessage(e, data?.ms_idx, data?.ms_url)}>{data?.ms_title}</td>
                  : <td className="td_title td_default gray " onClick={() => onPageMove(data?.ms_url)} >{data?.ms_title}</td>
                }
                <td className="td_info last"><span className="field_tit">발송일자 : </span>{ChangeDate(data?.ms_rdate)}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
    </>
  )
}

export default MyNoticeList
