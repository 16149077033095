/* eslint-disable jsx-a11y/anchor-is-valid */

/*관심채용관리 탭*/
function HedingBookmarkTab({ varTabs,setTabs }) {
  const onSetTabs = (e,index) => {
    e.preventDefault();
    setTabs(index);
  }
  return (
    <ul className="tab01">
        <li className={varTabs === 0?"active":""}><a onClick={(e)=>onSetTabs(e,0)}>관심 공고</a></li>
        <li className={varTabs === 1?"active":""}><a onClick={(e)=>onSetTabs(e,1)}>최근 본 채용 공고</a></li>
    </ul>
  );
}

export default HedingBookmarkTab;
