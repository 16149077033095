/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import BannerBottom from "components/Banner/BannerBottom";
import Common_Inquiry from "components/Common/Common_Inquiry";

const Common_InquiryOK = () => {
  return (
    <>
      <Header />
      <div className="p_myinfo">
        <div id="SubWrap">
          <div className="CenterWrap">
            <main id="sub_wrap" className="center_wrap_member page_join_ok">
              <section className="box member_form">
                <main id="sub_wrap" className="center_wrap_member page_join_ok">
                  <h2 className="sub_content_title">경력증명/ 근로소득 조회</h2>
                  <section className="box member_form">
                    <Common_Inquiry />
                  </section>
                </main>
              </section>
            </main>
          </div>
        </div>
      </div>
      <BannerBottom />
      <Footer />
    </>
  );
};

export default Common_InquiryOK;