import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CONFIG from "../../configs/configs";
import axios from "axios";

import { TalkWriteEditor } from './TalkWriteEditor';

//css
import "../../css/Common.css";
import "../../css/Board.css";
import "../../css/MyTalk.css";

function TalkUpdate({ varInitData, setInitData, varTalkInfo, setTalkInfo }) {
  const user_idx = localStorage.getItem("mem_idx");
  const location = useLocation();
  const navigator = useNavigate();
 
  let mem_idx = localStorage.getItem("mem_idx");

  //전달받은 이직톡 번호 저장
  useEffect(() => {

    const getData = async (mwt_idx) => {
      try {
        const params = new URLSearchParams();
        params.append("mwt_idx", mwt_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/_get_talk_information`,params).then((res) => {
          if(res.data.retvalue === 1 && res.data.results.length > 0) {
            console.log(res.data.results);
            const datas = res.data.results[0];
            setTalkInfo({
              mwt_idx: datas.mwt_idx,
              mwt_title: datas.mwt_title,
              mem_nickname: datas.mem_nickname,
              mwt_comment:datas.mwt_comment, //댓글
              mwt_hits: datas.mwt_hits,
              mwt_recommend: datas.recommend,
              mwt_rdate: datas.mwt_rdate,
              mwt_mem_idx: datas.mem_idx
            });
            // setStartMode(true);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    if(!mem_idx || mem_idx===undefined || mem_idx===""){
      alert("로그인 후 이용 가능합니다.");
      navigator(-1);
    }


    if (location !== null && location.state !== null && location.state.mwt_idx !== null && location.state.before !== null && location.state.before === `myheding`) {
      getData(location.state.mwt_idx)
    } else if (location !== null && location.state !== null) {
      const mwt_idx = location.state.mwt_idx;
      setTalkInfo({ ...varTalkInfo, mwt_idx: mwt_idx });
    }

    return () => {
      setTalkInfo({
        mwt_idx: 0,
        mwt_title: "",
        mem_nickname: "",
        mwt_comment: "",
        mwt_hits: 0,
        mwt_rdate: "",
        mwt_mem_idx: 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  
  //이직톡의 제목과 내용 저장
  const onChangeHandler = ({ name, value}) => {
    setTalkInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    //console.log(name, value)
  };

  //이직톡 저장
  const onSubmitHandler = async () => {
    try {
      console.log(varTalkInfo.mwt_title);
      if (varTalkInfo.mwt_title === "") {
        alert("제목을 입력해주세요.");
      } else if (varTalkInfo.mwt_comment === "") {
        alert("내용을 입력해주세요.");
      } else {
        console.log(varTalkInfo.mwt_title);
        const param = new URLSearchParams();
        param.append("mwt_title", varTalkInfo.mwt_title);
        param.append("mwt_mem_idx", user_idx);
        param.append("mwt_comment", varTalkInfo.mwt_comment);

        axios
          .post(`${CONFIG.SERVER_HOST}/_save_talk_comment`, param)
          .then((res) => {
            //alert(res.data.message);
            if (res.data.retvalue === 1) {
              //새로운 이직톡 배열에 추가
              console.log(res.data.results[0]);
              setInitData(varInitData.concat(res.data.results[0]));
              navigator("/MyHeding/MyHeding12");
            }else{
              alert(res.data.message);
            }
          });
      }
    } catch (error) {
      alert("글 등록 실패");
      console.log(error);
    }
  };
  //이지톡 수정사항 저장하기
  const onUpdateHandler = async () => {
    try {
      if (!varTalkInfo.mwt_title) {
        alert("제목을 입력해주세요.");
      } else if (!varTalkInfo.mwt_comment) {
        alert("내용을 입력해주세요.");
      } else {
        const param = new URLSearchParams();
        param.append("mwt_title", varTalkInfo.mwt_title);
        param.append("mwt_comment", varTalkInfo.mwt_comment);
        param.append("mwt_idx", varTalkInfo.mwt_idx);

        axios
          .post(`${CONFIG.SERVER_HOST}/_update_talk_comment`, param)
          .then((res) => {
            //alert(res.data.message);
            if (res.data.retvalue === 1) {
              //변경된 정보를 배열에 적용
              const finsIndex = varInitData.findIndex(
                (element) => element.mwt_idx === varTalkInfo.mwt_idx
              );
              let copyData = [...varInitData];
              copyData[finsIndex] = {
                ...copyData[finsIndex],
                mwt_title: varTalkInfo.mwt_title,
                mwt_comment: varTalkInfo.mwt_comment,
              };
              setInitData(copyData);
              navigator("/MyHeding/MyHeding12");
            }else{
              alert(res.data.message);
            }
          });
      }
    } catch (error) {
      alert("이직톡의 글을 수정하는 중에 오류가 발생했습니다.");
      console.log(error);
    }
  };

  return (
    <div className="center_wrap page_mytalk_write board_write">
      <h2 className="sub_content_title indent">등록하기</h2>
      {/* <Tab Menu1="on" /> */}
      <div className="box write">
        <section className="section">
          <div className="title_field">제목</div>
          <div className="input_field">
            <input
              type="text"
              name="mwt_title"
              value={varTalkInfo.mwt_title}
              onChange={(e) => onChangeHandler({name: "mwt_title", value: e.target.value})}
            />
          </div>
        </section>
        <section className="section">
          <div className="title_field">상세내용</div>
          <div className="input_field">
            <TalkWriteEditor
              defaultValue={varTalkInfo.mwt_comment}
              handleOnChange={(text) => onChangeHandler({name: "mwt_comment", value: text})}
            />
          </div>
        </section>
      </div>
      <div className="btn_bottom_area">
        <Link to="/MyHeding/MyHeding12" className="btn white">
          취소
        </Link>
        {varTalkInfo.mwt_idx > 0 ? (
          <div onClick={(e) => onUpdateHandler(e)} className="btn">
            수정
          </div>
        ) : (
          <div onClick={() => onSubmitHandler()} className="btn">
            등록
          </div>
        )}
      </div>
    </div>
  );
}

export default TalkUpdate;
