import React from "react";
import { Link, useParams } from "react-router-dom";
import OKIcon from "img/sub/icon_ok.jpg";

const JoinCompanyOKC = () => {
  const { mem_typ } = useParams(); 
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  
  return (
    <>
      <article className="notice_area">
        <img src={OKIcon} alt="" />
        <p className="title_field">
          <span className="point">
            {mem_typ === "1" ? "기업 회원" : ""}
            {/* {mem_typ === "21" ? "헤드헌터 회원 (프리랜서)" : ""} */}
            {mem_typ === "22" ? "헤드헌터 회원" : ""}
          </span> 가입요청을 완료했습니다.
        </p>
        <p className="text_field">관리자 승인 후 서비스 이용이 가능합니다.<br/>
          승인 전까지 HEDING 기능을 살펴보실 수 있습니다.
        </p>
      </article>
      <article className="bottom_btn_area">
        <Link to={"/Company"} className="btn">
          {mem_mode === 1 ? "기업서비스 홈으로" : "헤드헌터 회원 홈으로"}
        </Link>
        <Link to={"/main"} className="btn gray">
          메인으로
        </Link>
      </article>
    </>
  );
};

export default JoinCompanyOKC;
