import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from 'configs/configs';

//컴포넌트
import { addThousandPoint } from '../Common/Common';
import Paging from 'components/Layout/Paging';
import MyMoneySearch from './MyMoneySearch';

/*채용 지원 현황*/
function MyMoneyList({ varSubMemberIndex }) {
    //const user_idx = localStorage.getItem('mem_idx');
    //검색관련 변수값 저장
    const [varHedingMoney,setHedingMoney] = useState({
        type:0,
        sdate:null,
        edate:null,
        company:''
    });
    //전체목록
    const [varHedingMoneyLists,setHedingMoneyLists] = useState([]);
    //필터링된 목록
    const [varFilterHedingMoneyLists,setFilterHedingMoneyLists] = useState([]);
    //보여줄 페이지 목록
    const [varShowPageLists,setShowPageLists] = useState([]);
    //페이지관련
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

     //최초 - 헤딩머니 입출금내역 가져오기
  const getHedingMoneyList = async () => {
    try {
      console.log(varSubMemberIndex);
      const params = new URLSearchParams();
      params.append("user_idx", varSubMemberIndex);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_hedingmoney_list`,params).then((res) => {
          if(res.data.retvalue === 1) {
              console.log(res.data.results);
              //변수값
              setHedingMoneyLists(res.data.results);
              setFilterHedingMoneyLists(res.data.results);
              setTotalCount(res.data.results.length);
          }
      });
    } catch (error) {
      console.log(error);
    }
  }

    useEffect(() => {
      getHedingMoneyList();// eslint-disable-next-line react-hooks/exhaustive-deps
    },[varSubMemberIndex]); 

    //필터링--검색옵션
    const filterHedingMoneyList = () => {
        var copyData = [...varHedingMoneyLists];
        if(varHedingMoney.type > 0) {
            copyData = copyData.filter(element=>element?.mh_type===varHedingMoney?.type);
        }

        //날짜 비교
        if(varHedingMoney.sdate !== null && varHedingMoney.sdate !== "") {
          copyData = copyData.filter(element=>new Date(element?.mh_rdate)>=new Date(varHedingMoney?.sdate));
        }
        if(varHedingMoney.edate !== null && varHedingMoney.edate !== "") {
          copyData = copyData.filter(element=>new Date(element?.mh_rdate).getDate()<=new Date(varHedingMoney?.edate).getDate());
        }

        //회사명
        if(varHedingMoney.vhs_name !== '') {
            console.log(varHedingMoney.vhs_name);
            copyData = copyData.filter(element=>element?.vhs_name?.toUpperCase().includes(varHedingMoney?.company.toUpperCase()));
        }
        setFilterHedingMoneyLists(copyData);
        setTotalCount(copyData.length);
    }
    useEffect(() => {
        filterHedingMoneyList();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[varHedingMoney,varHedingMoneyLists]); 

    //페이지 표시 목록 배열 재저장
    const getPageLists = () => {
        const startNum = (varPageNum-1)*varPageSize;
        const endNum = startNum + varPageSize;
        const copyData = varFilterHedingMoneyLists.slice(startNum,endNum);
        setShowPageLists(copyData);
    }
    useEffect(() => {
        getPageLists();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[varPageNum,varPageSize,varFilterHedingMoneyLists]); 

  return (
    <>
        <MyMoneySearch varHedingMoney={varHedingMoney} setHedingMoney={setHedingMoney}/>
        <table className="table_list01">
          <colgroup>
            <col style={{ width: "40%" }} />
            <col style={{ width: "40%" }} />
            <col style={{ width: "20%" }}/>
          </colgroup>
            <thead>
              <tr>
                <th className="th_num">변동일시</th>
                <th className="th_title">변동내용</th>
                <th>남은머니</th>
              </tr>
            </thead>
            <tbody>
            {varTotalCount !== 0 ? varShowPageLists?.map((hedingMoney,index) => (
              <tr key={"hmoney_"+index}>
                    <td className="td_title">
                        <Link to={hedingMoney?.mh_type === 2 ?"../MyMoneyChangeView":"../MyMoneyView"} state={{mh_idx:hedingMoney?.mh_idx}}>{hedingMoney?.mh_rdate.replace(/-/g,'.')}</Link>
                    </td>
                    <td className="td_info">{hedingMoney?.mh_type === 2 ? "환전 " + (hedingMoney?.mh_status === 1 ? "(환전신청)" : hedingMoney.mh_status === 2 ? "(승인완료)" : hedingMoney.mh_status === 3 ? "(환전완료)" : hedingMoney.mh_status === -1 ? "(환전취소)" : "-"): hedingMoney?.vhs_name}{hedingMoney?.mh_type === 3 ? " (회수)" : ""}</td>
                    <td className="td_money">
                        <p className={hedingMoney?.mh_type===2 || hedingMoney?.mh_type===3?"state2":"state3"}>{hedingMoney?.mh_type===2 ? (hedingMoney?.mh_status === 1 ? "환전신청" : hedingMoney.mh_status === 2 ? "승인완료" : hedingMoney.mh_status === 3 ? "환전완료" : hedingMoney.mh_status === -1 ? "환전취소" : "-"): hedingMoney?.mh_type === 3 ? "회수" : "적립" } {addThousandPoint(Math.abs(hedingMoney?.mh_paid))}원</p>
                        <p>잔액 {addThousandPoint(Math.abs(hedingMoney?.mh_balance))}원</p>
                    </td>
                </tr>
            ))
            :
            <tr>
              <td className="no_data" colSpan={3}>불러올 데이터가 없습니다.</td>
            </tr>   
          }
            </tbody>
        </table>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
    </>
  );
}

export default MyMoneyList;
