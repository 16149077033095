/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import { changeMobileFormat } from '../../../components/Common/Common'

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import Paging from '../../../components/Layout/Paging'
import ExcelButton from 'admin/pages/board/ExcelDownloadButton';

function UserList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜

    const [varSearch, setSearch] = useState({
        resume:0,
        exposure:0,
        option:'all'
    });
    const [varSearchInput, setSearchInput] = useState("");

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize, setPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    const [startPageNum,setStartPageNum] = useState(1);
    const [endPageNum,setEndPageNum] = useState(10);

    const [varPagelist, setPagelist] = useState([]);
    const [selectChange,setSelectChange] = useState(false);
    const [varReset, setReset] = useState(false);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
      getUserList();
    },[]);

    useEffect(() => {
      getUserList();
    }, [varPageSize]);

    const getUserList = async() =>{
      const sendData = {
        'startDate':startDate,
        'endDate':endDate,
        'option':varSearch.option,
        'resume' : varSearch.resume,
        'exposure' : varSearch.exposure,
        'varSearchInput':varSearchInput,
        'pageSize':varPageSize ,
        'startPageNum':startPageNum === 1 ? 0 : startPageNum,
        'endPageNum':endPageNum
      }
      await axios.post(`${CONFIG.SERVER_HOST}/userlist`, sendData, CONFIG.header).then((res) => {
        //console.log(res);
        setTotalCount(res.data.totalcount);
        const datas = res.data.results.map((data,i)=>(
            {  
                ROWNUM:data.ROWNUM,
                mem_idx:data.mem_idx,
                mem_id:data.mem_id,
                mem_name:data.mem_name,
                mem_nickname:data.mem_nickname,
                mem_type:data.mem_type,
                mem_mobile:changeMobileFormat(data.mem_mobile),
                mem_email:data.mem_email,
                mem_ldate:data.mem_ldate,
                mem_rdate:data.mem_rdate,
                mem_resume: data.mem_resume,
                count_r: data.count_r,
                count_p:data.count_p
            }
        ))
        setPagelist(datas);
        setReset(false);
        console.log(selectChange === true);
        if(selectChange === true){
          setSelectChange(false);
        }
    });
    }

    const handleType = (e) =>{
      const {value, name} = e.currentTarget;
        setSearch({...varSearch,
          [name] : value

        });
    };
    const handleInput = (e) =>{
        setSearchInput(e.currentTarget.value);
    };

    //검색-초기화
    const handleSearchClear =()=>{
        setStartDate("");
        setEndDate("");
        setSearch({
            resume:0,
            exposure:0,
            option:0
        });
        setSearchInput("");
        setPageNum(1);
        setReset(true);
    }

    useEffect(()=>{
      if(varReset){
        getUserList();
      }
    }, [varReset]);

    //검색
    const handleSearchExecute = () => {
      
      setSelectChange(true);
      
    }

    useEffect(() => {
      getUserList();
    }, []);

    useEffect(() => {
      if(selectChange){
        getUserList();
        setPageNum(1);
      }
    }, [selectChange]);

    useEffect(() => {
      getUserList();
    }, [startPageNum]);

    useEffect(() => {
      console.log(selectChange);
      if(selectChange === false){
        setStartPageNum((varPageNum-1)*varPageSize === 1 ? 10 : varPageSize* (varPageNum-1));
        setEndPageNum((varPageNum-1)*varPageSize === 1 ? varPageSize : varPageSize*varPageNum);
      }
    },[varPageNum]);
    
    const handlestartdate =(e)=>{
        setStartDate(e.currentTarget.value);
    }
    const handleenddate =(e)=>{
        setEndDate(e.currentTarget.value);
    }

    const settingPageSize = (e) =>{
      const {value} = e.target;
      setPageSize(Number(value));
      setSelectChange(true);
    }


    console.log(startPageNum, endPageNum);
    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="users"/>
        <Menu menuType="users" menu="freeuserList"/>
        <div id="contents" className="ad_contents">
        <div className="tit">일반 회원 관리
            <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>회원목록<img src={arrImage} alt=""/>일반회원관리
            </div>
        </div>
        <div className="content">
            <div className="main-box">      
                <div className="search-box">
                    {/* <p>검색건수 : {varTotalCount}
                    <select style={{width:"120px", marginLeft: '15px'}} name="option" value={varPageSize} onChange={settingPageSize}> 
                            <option value="10">10개</option>
                            <option value="30">30개</option>
                            <option value="50">50개</option>
                            <option value="100">100개</option>
                        </select>
                    </p> */}
                    
                    <div className="f-right">   
                        <div className="day-box">
                            <p>기간검색</p>
                            <input type="date"  name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                            ~ 
                            <input type="date"  name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
                        </div>
                        <div>
                            <p className="ml20">이력서 작성여부</p>
                            <select style={{width:"120px"}} name="resume" value={varSearch.resume} onChange={handleType}> 
                                <option value={0}>전체</option>
                                <option value={1}>X</option>
                                <option value={2}>O</option>
                            </select>
                        </div>
                        <div>
                            <p className="ml20">인재 검색 노출 여부</p>
                            <select style={{width:"120px"}} name="exposure" value={varSearch.exposure} onChange={handleType}> 
                                <option value={0}>전체</option>
                                <option value={1}>Y</option>
                                <option value={2}>N</option>
                            </select>
                        </div>   
                    </div>
                    <div className="wd100">
                        <select style={{width:"120px"}} name="option" value={varSearch.option} onChange={handleType}> 
                            <option value="all">전체</option>
                            <option value="mem_id">아이디</option>
                            <option value="mem_name">이름</option>
                            <option value="mem_email">이메일</option>
                        </select>
                        <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e)}}/>
                        <button onClick={handleSearchExecute} className="btn">검색</button>
                        <button onClick={handleSearchClear} className="btn black">초기화</button>            
                    </div>
                    {/* <button  onClick={get_tilko}>건강보험</button> */}
                </div>
            </div>
            <div className="list_top">
              <p>검색건수 : {varTotalCount}
                <select style={{width:"120px", marginLeft: '15px'}} name="option" value={varPageSize} onChange={settingPageSize}> 
                      <option value="10">10개</option>
                      <option value="30">30개</option>
                      <option value="50">50개</option>
                      <option value="100">100개</option>
                  </select>
              </p>
              <ExcelButton 
              startDate={startDate}
              endDate={endDate}
              option={varSearch.option}
              resume={varSearch.resume}
              exposure={varSearch.exposure}
              varSearchInput={varSearchInput}
              type={1}
              />
            </div>
            <div className="main-box ">
                <table className="list">
                    <colgroup>
                      <col style={{width:"8%"}} />
                      <col style={{width:"12%"}} />
                      <col style={{width:"10%"}} />
                      <col style={{width:"10%"}}/>
                      <col style={{width:"12%"}}/>
                      <col style={{width:"18%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"14%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"8%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>아이디</th>
                            <th>이름</th>
                            <th>가입유형</th>
                            <th>휴대폰</th>
                            <th>이메일</th>
                            <th>이력서 작성여부</th>
                            <th>동료평판 개수</th>
                            <th>인재 검색 노출 여부</th>
                            <th>최근접속일</th>
                            <th>가입일</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody>
                    {varPagelist.map((user,i) => (
                        <tr key={i}>
                            <td>{varTotalCount-(varPageNum-1)*varPageSize -i}</td>
                            <td className="ellipsis">{user.mem_nickname}</td>
                            <td>{user.mem_name}</td>
                            <td>{isNaN(user.mem_type) ? user.mem_type : user.mem_type === 0 ? "일반" : user.mem_type === 1 ? "네이버" : user.mem_type === 2 ? "카카오" : user.mem_type === 3 ? "구글" : "IOS"}</td>
                            <td className="ellipsis">{user.mem_mobile}</td>
                            <td className="ellipsis">{user.mem_email}</td>
                            <td>{user.count_r===0?"X":"O"}</td>
                            <td>{user.count_p}</td>
                            <td>{user.mem_resume===0?"N":"Y"}</td>
                            <td>{user.mem_ldate===null?"":user.mem_ldate.substr(0,10)}</td>
                            <td>{user.mem_rdate===null?"":user.mem_rdate.substr(0,10)}</td>
                            <td><Link to={`/admin/userinfo/${user.mem_idx}`} className="btn line black">상세</Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </div>
    </div>
    </React.Fragment>

    )

}

export default UserList;