import React, { useEffect } from "react";
import MyNoticeList from "../Board/MyNoticeList";
import { resetNavSubMenuForHeding } from '../Common/Common';

function MyHeding7({ getSubItem, NumReset }) {
  useEffect(() => {
    getSubItem(7);
    resetNavSubMenuForHeding(7);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container">
      <MyNoticeList NumReset={NumReset} />
    </div>
  );
}

export default MyHeding7;
