import React from 'react'
import { Link } from "react-router-dom";
import { remainDays } from '../Common/Common'
//image
import voucher1Image from "../../img/sub/icon_voucher01.png";
import voucher2Image from "../../img/sub/icon_voucher02.png";
import voucher3Image from "../../img/sub/icon_voucher03.png";

const SearchLeftUsingPass = ({ varUsingPass}) => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_sub = Number(localStorage.getItem("mem_sub"));

  return (
    <>
      <ul className="icon_banner">
        <li>
            <i className="icon"><img src={voucher1Image} alt=""/></i>
            {mem_idx === mem_sub ? <Link to={'/Company/ComVoucher'} className="button point">이용권</Link> : <div className="button point">이용권</div>}
            
        </li>
        <li>
            <i className="icon"><img src={voucher2Image} alt=""/></i>
            <p className="title_field">잔여 제안</p>
            <p className="point">{varUsingPass.mcc_sending+" 건"}</p>
        </li>
        <li>
            <i className="icon"><img src={voucher3Image} alt=""/></i>
            <p className="title_field">잔여 이용기간</p>
            <p className="point">{remainDays(varUsingPass.mcc_edate)+" 일"}</p>
        </li>
      </ul>
    </>
  )
}

export default SearchLeftUsingPass
