/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { resetNavMenu,resetNavSubMenu } from "../../components/Common/Common";
import SupportListC from "../../components/Announcement/SupportListC";
//css

const SupportList = (/* { setPageTitle } */) => {
  useEffect(() => {
    /* setPageTitle("공고/지원자 관리"); */
    resetNavMenu(1);
    resetNavSubMenu(1,1);
  }, []);

  return (
    <div className="center_wrap company">
      <section className="page_announcement list support">
        <h2 className="sub_content_title indent">공고/지원자 관리</h2>
        <ul className="tab01 blue col2">
          <li>
            <Link
              to={"/Company/comAnnouncement/AnnouncementList"}
              state={{ ComAnnouncement: 0 }}
            >
              공고관리
            </Link>
          </li>
          <li className="active">
            <a className="on">지원자 관리</a>
          </li>
        </ul>
        <SupportListC />
      </section>
    </div>
  );
};

export default SupportList;
