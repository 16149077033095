/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/configs';

import { ChangeMoneyUnit } from '../../../components/Common/Common'
import Paging from '../../../components/Layout/Paging';

function Headhunter_Application_Management({ setCategory }) {
  const { mem_idx } = useParams();

  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  const [varRecruitment, setRecruitment] = useState([]);
  const [varPagelist, setPagelist] = useState([]);

  const [varCounts, setCounts] = useState({
    progress: 0,
    waiting: 0,
    finish: 0
  });

  useEffect(async () => {
    setCategory(3);
    if (Number(mem_idx) > 0) {
      console.log(mem_idx);
      const params = new URLSearchParams();
      params.append("mem_idx", mem_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/getemploy_user_list`, params).then((res) => {
        if (res.data.retvalue === 1) {
          const datas = res.data.results;
          console.log(datas);
          setTotalCount(datas.length);
          setRecruitment(datas);
          setCountering(datas);
        }
      });
    }
  }, [mem_idx]);

  //진행사항 카운팅
  const setCountering = (datas) => {
    const progress = datas.filter(recruitment => recruitment.em_status === 1);
    const waiting = datas.filter(recruitment => recruitment.em_status === 0);
    const finish = datas.filter(recruitment => recruitment.em_status === 2);
    setCounts({ progress: progress.length, waiting: waiting.length, finish: finish.length });
  }

  //페이지별 표시 - 결제내역
  const getPageLists = () => {
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    const copyData = varRecruitment.slice(startNum, endNum);
    setPagelist(copyData);
  }

  useEffect(() => {
    getPageLists();
  }, [varPageNum, varRecruitment]);

  return (
    <React.Fragment>
      <p className="table_title" style={{ marginTop: "20px" }}>- 공고 신청 관리</p>
      <div className="main-box" >
        <table className="list">
          <thead>
            <tr>
              <th>전체</th>
              <th>진행중</th>
              <th>대기중</th>
              <th>마감</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{varTotalCount}</td>
              <td>{varCounts.progress}</td>
              <td>{varCounts.waiting}</td>
              <td>{varCounts.finish}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="main-box ">
        <table className="list">
          <colgroup>
            <col style={{ width: "8%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "8%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>채용제목</th>
              <th>회사명</th>
              <th>직무</th>
              <th>총 보상금</th>
              <th>작성자 아이디</th>
              <th>작성자 이름</th>
              <th>진행상태</th>
              <th>작성일자</th>
              <th>채용 상세</th>
            </tr>
          </thead>
          <tbody>
            {varPagelist.map((resume, i) => (
              <tr key={i}>
                <td>{varTotalCount - (varPageNum - 1) * varPageSize - i}</td>
                <td className="ellipsis">{resume.em_title}</td>
                <td className="ellipsis">{resume.com_name}</td>
                <td className="ellipsis">{resume.em_tasks_info2}</td>
                <td className="ellipsis">{ChangeMoneyUnit(resume.em_reward_applicant / 10000)}</td>
                <td className="ellipsis">{resume.mem_id}</td>
                <td className="ellipsis">{resume.mem_name}</td>
                <td>{resume.em_status === -1 ? "삭제"
                  : resume.em_status === 0 ? "대기중"
                    : resume.em_status === 1 ? "진행중"
                      : resume.em_status === 2 ? "마감" : ""}
                </td>
                <td className="ellipsis">{resume.em_rdate}</td>
                <td><Link className="btn line black" to={`/admin/announcement_modify/${resume.em_idx}`}>상세</Link></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
      </div>
    </React.Fragment>
  )

}

export default Headhunter_Application_Management;