/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import CONFIG from "../../../configs/configs";
import axios from "axios";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import arrImage from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";
import Paging from "../../../components/Layout/Paging";
import {ChangeMoneyUnit} from "../../../components/Common/Common";
import ExcelButton from 'admin/pages/board/ExcelDownloadButton';

function SupportList() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varSearch, setSearch] = useState({
    em_status: -1,
    search:'none'
  });
  const [varSearchInput, setSearchInput] = useState("");

  //페이징
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize, setPageSize] = useState(10);
  const [selectChange, setSelectChange] = useState(false);
  const [varPageNum, setPageNum] = useState(1);

  const [varUserlist, setUserlist] = useState([]);
  const [startPageNum,setStartPageNum] = useState(1);
  const [endPageNum,setEndPageNum] = useState(10);
  const [varReset, setReset] = useState(false);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SupportManagementList();
  }, []);

  const SupportManagementList= async() =>{
    const sendData = {
      'em_status':varSearch.em_status,
      'search' : varSearch.search,
      'varSearchInput':varSearchInput,
      'pageSize':varPageSize,
      'startPageNum':startPageNum === 1 ? 0 : startPageNum,
      'endPageNum':endPageNum
    }

    await axios.post(`${CONFIG.SERVER_HOST}/get_AllSupport_list`, sendData, CONFIG.header).then((res) => {
      console.log(res.data);
      setTotalCount(res.data.totalcount);
      const datas = res.data.results.map((data, i) => ({
        ROWNUM: data.ROWNUM,
        mem_idx: data.em_idx,
        mcm_name: data.mcm_name,
        em_title: data.em_title,
        em_reward_applicant: data.em_reward_applicant,
        em_reward_recommend: data.em_reward_recommend,
        total: data._total,
        unread: data._unread,
        under_review: data._under_review,
        pass_the_documents: data._pass_the_documents,
        interview_pass: data._interview_pass,
        final_pass: data._final_pass,
        fail: data._fail,
        join: data._join,
      }));
      setUserlist(datas);
      setReset(false);
      if(selectChange === true){
        setSelectChange(false);
      }
    });
  }
  console.log(varUserlist);

  const handleType = (e) => {
    const {name, value} = e.target;
    setSearch({...varSearch,
      [name]: value
    });
  };
  const handleInput = (e) => {
    setSearchInput(e.currentTarget.value);
  };

  //검색-초기화
  const handleSearchClear = () => {
    // setSearch("");
    // setSearchInput("");
    // setPageNum(1);
    // setTotalCount(varUserlist.length);
    // setFilterlist(varUserlist);
    setSearch({
      em_status: -1,
      search:'none'
    });
    setSearchInput("");
    setPageNum(1);
    setReset(true);
  };

  useEffect(()=>{
    if(varReset){
      SupportManagementList();
    }
  }, [varReset]);

  //검색
  const handleSearchExecute = () => {
    setSelectChange(true);
  };



  useEffect(() => {
    if(selectChange){
      SupportManagementList();
      setPageNum(1);
    }
  }, [selectChange]);
  
  useEffect(() => {
    SupportManagementList();
  }, [startPageNum]);

  useEffect(() => {
    console.log(selectChange);
    if(selectChange === false){
      setStartPageNum((varPageNum-1)*varPageSize === 1 ? 10 : varPageSize* (varPageNum-1));
      setEndPageNum((varPageNum-1)*varPageSize === 1 ? varPageSize : varPageSize*varPageNum);
    }
  },[varPageNum]);

  const settingPageSize = (e) =>{
    const {value} = e.target;
    setPageSize(Number(value));
    setSelectChange(true);
  };


  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="announcement" />
      <Menu menuType="announcement" menu="supportlist" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          지원 관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arrImage} alt="" />
            공고 관리
            <img src={arrImage} alt="" />
            지원 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <div className="search-box">
              <div>
                <p>공고 상태 : 
                  <select
                    style={{ width: "120px" }}
                    name="em_status"
                    value={varSearch.em_status}
                    onChange={handleType}
                  >
                    <option value={-1}>전체</option>
                    <option value={1}>진행중</option>
                    <option value={0}>대기중</option>
                    <option value={2}>마감</option>
                  </select>
                </p>
              </div>
              <div className="f-right">
                <select
                  style={{ width: "120px" }}
                  name="search"
                  value={varSearch.search}
                  onChange={handleType}
                >
                  <option value="none">전체</option>
                  <option value="em_title">채용제목</option>
                  <option value="mcm_name">회사명</option>
                </select>
                <input
                  type="text"
                  name="strsearch"
                  value={varSearchInput}
                  onChange={handleInput}
                  style={{ width: "270px" }}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e)}}
                />
                <button onClick={handleSearchExecute} className="btn">
                  검색
                </button>
                <button onClick={handleSearchClear} className="btn black">
                  초기화
                </button>
              </div>
            </div>
          </div>
          <div className="list_top">
           <p>검색건수 : {varTotalCount}
              <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                  <option value="10">10개</option>
                  <option value="30">30개</option>
                  <option value="50">50개</option>
                  <option value="100">100개</option>
              </select>
            </p>
            <ExcelButton 
                em_status={varSearch.em_status}
                search={varSearch.search}
                varSearchInput={varSearchInput}
                type={8}
                />
          </div>
          <div className="main-box ">
            <table className="list">
              <colgroup>
                <col width="7%"></col>
                <col width="14%"></col>
                <col width="19%"></col>
                <col width="10%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>채용제목</th>
                  <th>회사명</th>
                  <th>총보상금</th>
                  <th>총 지원자</th>
                  <th>미열람</th>
                  <th>검토중</th>
                  <th>서류합격</th>
                  <th>면접합격</th>
                  <th>최종합격</th>
                  <th>불합격</th>
                  <th>입사</th>
                </tr>
              </thead>
              <tbody>
                {varTotalCount !== 0 ? (
                  varUserlist.map((user, i) => (
                    <tr key={i}>
                      <td>
                        {user.ROWNUM}
                      </td>
                      <td className="ellipsis">{user.em_title}</td>
                      <td className="ellipsis">{user.mcm_name}</td>
                      <td>{ChangeMoneyUnit(user.em_reward_recommend/10000)}</td>
                      <td>{user.total}</td>
                      <td>{user.unread}</td>
                      <td>{user.under_review}</td>
                      <td>{user.pass_the_documents}</td>
                      <td>{user.interview_pass}</td>
                      <td>{user.final_pass}</td>
                      <td>{user.fail}</td>
                      <td>{user.join}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td className="ellipsis"></td>
                    <td className="ellipsis"></td>
                    <td></td>
                    <td colSpan={3}>불러올 데이터가 없습니다</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div>
            <Paging
              totalCount={varTotalCount}
              pageSize={varPageSize}
              curPage={varPageNum}
              setPage={setPageNum}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SupportList;
