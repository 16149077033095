import React, { useEffect, useState } from "react";

//css
import "css/Board.css";
import "css/My.css";

import { resetNavSubMenuForHeding } from "../../components/Common/Common";
import MyTalkList from '../../components/My/MyTalkList';

function MyHeding12({ getSubItem }) {
  useEffect(() => {
    getSubItem(12);
    resetNavSubMenuForHeding(12);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [varReloading, setReloading] = useState(true);

  const onSetPopup = (e, bpop) => {
    e.preventDefault();
  }

  return (
    <div className="container page_suggest">
      <MyTalkList onSetPopup={onSetPopup} varReloading={varReloading} setReloading={setReloading} />
    </div>
  );
}

export default MyHeding12;