/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs"; 
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import {ChangeMoneyUnit, changeMobileFormat} from "../../../components/Common/Common";

function SuggestionInfo({ history }) {
  const { user_idx, em_idx } = useParams();
  const [varUserData, setUserData] = useState({});

  // console.log("em_idx ",em_idx,"user_idx ",user_idx)

  //회원정보 가져오기
  useEffect(async () => { 
    if (user_idx > 0) {
        const params = new URLSearchParams();
        params.append('mem_idx', user_idx);
        params.append('em_idx', em_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/get_suggestion_detail`, params).then((res) => {
          if(res.data.retvalue === 1) {
            console.log(res.data)
            //회원정보
            let data = res.data.result;
            //data.change_mem_pwd = "";
            setUserData(data); 
          }
        });
    }
  }, [user_idx]);

    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="announcement"/>
            <Menu menuType="announcement" menu="suggestionlist"/>
            <div id="contents" className="ad_contents">
              <div className="tit">추천 관리
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>공고관리<img src={arrImage} alt=""/>추천 관리 
                </div>
              </div>
                <div className="content">
                    {/* <div className="tit">추천관리</div> */}
                    {/* 내부 메뉴 */}
                    <div className="main-box">
                      <table className="write"> 
                        <colgroup>
                        <col style={{width:"15%"}}/>
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>채용명</th>
                            <td style={{ textAlign: "left" }}>{varUserData.em_title}</td> 
                          </tr>
                          {/* <tr>
                            <th>추천방법</th>
                            <td style={{ textAlign: "left" }}>
                              {}
                            </td>
                          </tr>   */}
                          <tr>
                            <th>총보상금</th>
                            <td style={{ textAlign: "left" }}>{ChangeMoneyUnit(varUserData.em_reward_recommend/10000)}
                            </td>
                          </tr>
                          <tr>
                            <th>추천인 아이디</th>
                            <td  style={{ textAlign: "left" }}>{varUserData.mem_id}</td>
                          </tr> 
                          <tr>
                            <th>추천인 휴대폰</th>
                            <td style={{ textAlign: "left" }}> 
                            {changeMobileFormat(varUserData.mem_mobile)}
                            </td>
                          </tr>
                          <tr>
                            <th>후보자명</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData.er_name}
                            </td>
                          </tr>
                          <tr>
                            <th>후보자 휴대폰</th>
                            <td style={{ textAlign: "left" }}> 
                            {changeMobileFormat(varUserData.er_mobile)}
                            </td>
                          </tr>
                          <tr>
                            <th>추천일</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData.er_rdate}
                            </td>
                          </tr>
                          <tr>
                            <th>지원 여부</th>
                            <td style={{ textAlign: "left" }}>
                            {varUserData.ea_rdate === null ? "미지원" : "지원"}
                            </td>
                          </tr>
                          <tr>
                            <th>지원일</th>
                            <td style={{ textAlign: "left" }}>{varUserData.ea_rdate === null ? "-" : varUserData.ea_rdate }</td>
                          </tr> 
                        </tbody>
                      </table>
                    </div> 
                    <div className="btn-center">
                      <button className="btn-danger" ><Link to={"/admin/suggestionlist"} style={{ color: "#fff" }}>목록</Link></button>
                  </div>                    
                    {/* <div children={comp} /> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default SuggestionInfo;