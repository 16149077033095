import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { Link } from "react-router-dom";
//css

//components
import Paging from "../Layout/Paging";
import { remainDays } from "../Common/Common";
//이미지
import LogoCompany from "../../img/sub/@thumnail.png";
import IconNew from "../../img/sub/icon_new.png";
import IconBg from "../../img/sub/icon_gray_img01.png";


  /*채용 지원 현황*/

function MyBookmarkList({ varTabs, varEmployList, setEmployList }) {
  const user_idx = localStorage.getItem("mem_idx");
  //const mcm_idx = localStorage.getItem("mcm_idx");
  const imgCompanyFolder = `${CONFIG.SERVER_HOST}/mcm_image/`;

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //지원목록
  const [varPageEmployList, setPageEmployList] = useState([]);

  const readEmployList = () => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("mode", varTabs);
    axios.post(`${CONFIG.SERVER_HOST}/_get_my_interest_employ`, params).then((res) => {
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.results.length);
        console.log(res.data.results);
        setEmployList(res.data.results); //원본 - 전체
        setPageEmployList(res.data.results); //필터링 배열
        //setPageNum(1);
        //getDataPerPage();
      }
    });
  };

  useEffect(() => {
    readEmployList();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabs]);

  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varEmployList.length === 0) return;
    var copyData = [...varEmployList];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    console.log(copyData);
    setPageEmployList(copyData);
  };

  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varEmployList]);

  //관심채용공고등록
  const onSelectEmployInterestHandler = (e, em_idx) => {
    const ichecked = Number(e.currentTarget.checked);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("em_idx", em_idx);
    params.append("checked", ichecked);
    axios.post(`${CONFIG.SERVER_HOST}/_update_my_interest_employ`, params).then((res) => {
        window.alert(res.data.message);
        if(res.data.retvalue === 1) {
          //원본배열
          console.log(varEmployList);
          if (varTabs === 0 && !ichecked) {
            //마이헤딩-관심채용공고관리-관심채용을 빼면
            const copyData = varEmployList.filter(element => element.em_idx !== em_idx);
            setEmployList(copyData);
            console.log(copyData);
          } else {
            const finsIndex = varEmployList.findIndex(element => element.em_idx === em_idx);
            console.log(finsIndex);
            let copyData = [...varEmployList];
            if(res.data.inserMode === 1) {
              copyData[finsIndex] = { ...copyData[finsIndex], mie_idx:res.data.insert_id, mie_status: ichecked };
            } else {
              copyData[finsIndex] = { ...copyData[finsIndex], mie_status: ichecked };
            }
            setEmployList(copyData);
            console.log(copyData);
          }
        }
    });

  };

  return (
    <div className="recruitmanlist" style={{ marginTop: "20px" }}>
      <ul>
        {varPageEmployList.map((jobPosting, index) => (
          <li key={"posting_" + index}>
            <Link to={`/MyRecruit/MainView/${jobPosting.em_idx}/${jobPosting.em_headhunter}`}>
              <div className="logobox">
                <img src={ jobPosting.mcm_image===0 && jobPosting.mcm_ufile===undefined ? LogoCompany : imgCompanyFolder + jobPosting.mcm_ufile } alt="" />
              </div>
              {/* 10일까지만 new 표시 */}
              {remainDays(jobPosting.mie_rdate) < 10 ? <div className="newicon"><img src={IconNew} alt="" /></div>: null}
              <div className="righticon">
                <img src={IconBg} className="imgtop" alt="" />
                <div className="imgcons">
                  {jobPosting.em_type === 1 ? "공개채용":jobPosting.em_type === 2 ? "비공개채용":"실시간 채용관"}
                </div>
              </div>
              <div className="cons">
                <div className="companyname">{jobPosting.mcm_name}</div>
                <div className="tit">{jobPosting.em_title}</div>
                <div className="binfo">
                  <div className="area">
                    <span>{jobPosting.vr_name}</span>
                    <span>{jobPosting.em_careers === 0?"경력무관":jobPosting.em_careers === 1?"신입":"경력"}</span>
                  </div>
                  <div className="won">총 보상금<b>{jobPosting.em_reward_applicant / 10000} 만원</b>
                  </div>
                </div>
              </div>
            </Link>
            <div className="btnstar">
              <input
                type="checkbox"
                name="chk1"
                id={"agreechk" + index}
                className="chk1"
                onChange={(e) => {
                  onSelectEmployInterestHandler(e, jobPosting.em_idx);
                }}
                checked={jobPosting.mie_status === 1 ? true : false}
              />
              <i></i>
              <label htmlFor={"agreechk" + index}></label>
            </div>
          </li>
        ))}
      </ul>
      <div>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
      </div>
    </div>
  );
}

export default MyBookmarkList;
