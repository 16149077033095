/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';

import axios from 'axios';
import CONFIG from '../../../configs/configs';
import { ChangeMoneyUnit } from '../../../components/Common/Common';
import Paging from '../../../components/Layout/Paging';

function UserProposal_Received({ setCategory }) {
    const { user_idx } = useParams();

    const [varRecruitment, setRecruitment] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    useEffect(async () => {
      setCategory(4);
      if(user_idx > 0) {
          const params = new URLSearchParams();
          params.append('user_idx', user_idx);
          await axios.post(`${CONFIG.SERVER_HOST}/_get_received_suggestion`, params).then((res) => {
            console.log(res.data)
            if(res.data.retvalue === 1) {
              const datas = res.data.results;
              console.log(datas);
              setTotalCount(datas.length);
              setRecruitment(datas);      
            } else {
              alert(res.data.message);
            }
        });
      }
    }, []);

    //페이지별 표시
    const getPageLists = () => {
      const startNum = (varPageNum-1)*varPageSize;
      const endNum = startNum + varPageSize;
      const copyData = varRecruitment.slice(startNum,endNum);
      setPagelist(copyData);
    }

    useEffect(() => {
      getPageLists();
    },[varPageNum,varRecruitment]);

    // 세부직무만 분리 
    const onlyDetailTasks = (tasks) => {
      return tasks.map((el) => el.split(">")[1] + " ").join()
    }

    return (
      <React.Fragment>
        <p className="table_title" style={{marginTop:"20px"}}>- 받은 제안 현황</p>
        <div className="main-box">
          {/* <div className="content"> */}
            <table className="list">
              <colgroup>
                <col width="8%"></col>
                <col width="26%"></col>
                <col width="12%"></col>
                <col width="10%"></col>
                <col width="8%"></col>
                <col width="12%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
                <col width="8%"></col>
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>채용제목</th>
                  <th>회사명</th>
                  <th>직무</th>
                  <th>보상금</th>
                  <th>담당자</th>
                  <th>지원일자</th>
                  <th>상태</th>
                  <th>기업상세</th>
                </tr>
              </thead>
              <tbody>
              {varPagelist.map((resume, i) => (
                <tr key={i}>
                  <td>{varTotalCount-(varPageNum-1)*varPageSize-i}</td>
                  <td>{resume.em_title}</td>
                  <td>{resume.mcm_name}</td>
                  <td>{resume?.crs_em_tasks === "" ? "-" : onlyDetailTasks(resume?.crs_em_tasks.split(","))}</td>
                  <td>{ChangeMoneyUnit(resume.em_reward_recommend/10000)}</td>
                  <td>{resume.mem_id}</td>
                  <td>{resume.crs_rdate === null||resume.er_rdate===""?"-":resume.crs_rdate.substr(0, 10)}</td>
                  <td>{resume.crs_status===2?"제안수락":"제안거절"}</td>
                  <td>
                    <Link to={resume.em_headhunter === 0 ? `/admin/announcement_modify/${resume.em_idx}`:`/admin/headhunter_dedicated_hall_modify/${resume.em_idx}`}>
                    <button style={{padding:"10px 15px"}}>관리</button>
                    </Link>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          {/* </div> */}
        </div>
        <div>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} /> 
        </div>
      </React.Fragment>
    )

}

export default UserProposal_Received;