import React from 'react';

//컴포넌트
import HeaderCompany from '../../components/Layout/HeaderCompany';
import FAQList from '../../components/Board/FAQList';
import BannerBottom from 'components/Banner/BannerBottom';
import Footer from '../../components/Layout/Footer';


function Faq() {

  return (
    <div>
      <HeaderCompany />
      <div className="center_wrap page_my_faq">
        <FAQList />
      </div>
      <BannerBottom />
      <Footer />
    </div>
  )
}

export default Faq;