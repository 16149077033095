/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import axios from 'axios';
import CONFIG from '../../../configs/configs';
import { ChangeMoneyUnit } from '../../../components/Common/Common';
import Paging from '../../../components/Layout/Paging'

function UserRecommend_Acquaintance({ setCategory }) {
    const { user_idx } = useParams();

    const [varRecruitment, setRecruitment] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    const [varCountRecommend, setCountRecommend] = useState(0);
    const [varCountApply, setCountApply] = useState(0);

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);


    useEffect(async () => {
      setCategory(5);
      if(user_idx > 0) {
        const params = new URLSearchParams();
        params.append('mem_idx', user_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/get_recommend_acquaintance`, params).then((res) => {
          if(res.data.retvalue === 1) {
            const datas = res.data.emlist;
            console.log(datas);
            setTotalCount(res.data.emlist.length);
            setRecruitment(datas);    
            setCountRecommend(res.data.emlist.length);
            setCountApply(res.data.eacount.length);
            setPagelist(res.data.results);
          }
        });
      }
    }, []);

    //페이지별 표시
    const getPageLists = () => {
      const startNum = (varPageNum-1)*varPageSize;
      const endNum = startNum + varPageSize;
      const copyData = varRecruitment.slice(startNum,endNum);
      setPagelist(copyData);
    }

    useEffect(() => {
      getPageLists();
    },[varPageNum,varRecruitment]);

    return (
        <React.Fragment>
            <p className="table_title" style={{marginTop:"20px"}}>- 지인 추천 현황</p>
            <div className="main-box">
              <table className="list">
                <colgroup>
                  <col style={{width:"50%"}} />
                  <col style={{width:"50%"}} />
                </colgroup>
                <thead>
                  <tr>
                    <th>추천 발송 수</th>
                    <th>회사 지원한 수</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{varCountRecommend}</td>
                    <td>{varCountApply}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="main-box ">
              <table className="list">
                <colgroup>
                  <col style={{width:"8%"}} />
                  <col style={{width:"24%"}} />
                  <col style={{width:"12%"}} />
                  <col style={{width:"12%"}} />
                  <col style={{width:"10%"}} />
                  <col style={{width:"8%"}} />
                  <col style={{width:"9%"}} />
                  <col style={{width:"9%"}} />
                  <col style={{width:"8%"}} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>채용제목</th>
                    <th>회사명</th>
                    <th>직무</th>
                    <th>보상금</th>
                    <th>후보자 명</th>
                    <th>추천일자</th>
                    <th>지원일자</th>
                    <th>진행상태</th>
                  </tr>
                </thead>
                <tbody>
                {varPagelist.map((resume, i) => (
                  <tr key={i}>
                      <td>{varTotalCount-(varPageNum-1)*varPageSize-i}</td>
                      <td className="ellipsis">{resume.em_title}</td>
                      <td className="ellipsis">{resume.mcm_name}</td>
                      <td className="ellipsis">{resume.em_tasks_info === null ? "-" : resume.em_tasks_info}</td>
                      <td className="ellipsis">{ChangeMoneyUnit(resume.em_reward_applicant/10000)}</td>
                      <td>{resume.er_name}</td>
                      <td>{resume.er_rdate===null||resume.er_rdate===""?"-":resume.er_rdate.substr(0,10)}</td>
                      <td>{resume.ea_rdate===null||resume.ea_rdate===""?"-":resume.ea_rdate.substr(0,10)}</td>
                      <td>{resume.ems_name === null ? "지원안함" : resume.ems_name}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} /> 
            </div>
        </React.Fragment>
    )
}

export default UserRecommend_Acquaintance;