/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

/*채용 지원 현황*/
function HedingRecommendTop({ varRecommendCounter,varRecommendApplyCounter }) {
  return (
    <section className="box box_content">
        <h3 className="my_title">추천 현황</h3>
        <ul className="state_wrap">
            <li className="state_box">
                <h4 className="title">추천자 수</h4>
                <p className="num">{varRecommendCounter}</p>
            </li>
            <li className="state_box">
                <h4 className="title">지원자 수</h4>
                <p className="num" >{varRecommendApplyCounter}</p>
            </li>
        </ul>
    </section>
  );
}

export default HedingRecommendTop;
