import React from "react";
import EditPWC from "../../components/Member/EditPWC";

// css
import "css/Common.css";

const EditPW = () => {
  return (
    <main id="sub_wrap" className="center_wrap_member">
      <EditPWC />
    </main>
  );
};

export default EditPW;
