/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import RecruitMainList from "../../components/Recruit/RecruitMainList";
import RecruitTopSearch from "../../components/Recruit/RecruitTopSearch";

import { resetNavMenu, resetNavSubMenu } from "../../components/Common/Common";
import axios from "axios";
import CONFIG from "configs/configs";

const MainList = ({ varHeadHunter, setHeadHunter, PageFolder }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user_idx = localStorage.getItem("mem_idx");
  const { cate, page, shIdx } = useParams();//채용구분,페이지,검색조건
  const sesseionParams = JSON.parse(sessionStorage.getItem("params"));
  const [paramsArr, setParamsArr] = useState([sesseionParams]); //검색 param 담고 있는 배열
  // console.log("sesseionParams", sesseionParams);

  const [boolAxio, setBoolAxio] = useState(true);  //검색 실행여부

  //검색조건
  const [varWorkingList, setWorkingList] = useState([]);
  const [varRegionList, setRegionList] = useState([]);
  const [varCareerList, setCareerList] = useState([]);
  const [varCareerDirectList, setCareerDirectList] = useState([]);
  const [totalList, setTotalList] = useState(false);

  //검색문자
  const [varSearchOption, setSearchOption] = useState("");
  const [varSearchString, setSearchString] = useState("");

  // 검색옵션 
  // 2022/11/18 링크 전달 시 (/MyRecruit/MainList) 공개채용 리스트 기본으로 표시 => 에러 발생시 초기화 
  const [varEmployType, setEmployType] = useState(cate ? cate : 1); //채용구분
  const [varSortMode, setSortMode] = useState(0);     //정렬조건
  const [varPageSize, setPageSize] = useState(20);    //페이지당 레코드 수

  //검색실행
  const [varExecuteSearch, setExecuteSearch] = useState(0);

  const [employSearchLists, setEmploySearchLists] = useState([]);
  const [employSearchListsTotalCount, setEmploySearchListsTotalCount] = useState(0);

  const [varPageNum, setPageNum] = useState(page ? page : 1); //현재 페이지
  //2022-11-20 페이지 변경 시 네이게이션
  useEffect(() => {
    navigate(`/MyRecruit/MainList/${varEmployType}/${varPageNum}/0`);
  },[navigate, varPageNum, varEmployType]);
  
  const getEmploySearchLists = useCallback(async () => {
    if (varEmployType === 0) {
      return;
    }

    const params = {
      user_idx: user_idx,
      em_headhunter: varHeadHunter,
      employType: varEmployType,
      pageNum: varPageNum,
      pageSize: varPageSize,
      sortMode: varSortMode,
      searchString: varSearchString,
      workingList: varWorkingList,
      regionList: varRegionList,
      careerList: varCareerList,
      varCareerDirectList: varCareerDirectList,
    }
    setParamsArr([...paramsArr, params]);
    sessionStorage.setItem("params", JSON.stringify(paramsArr));

    let goParms = "";
    if(shIdx>0){
      goParms = paramsArr[shIdx];
    }else{
      goParms = params;
    }
    
    if(boolAxio){
      // console.log("Axio~~~~");
      await axios
      .post(`${CONFIG.SERVER_HOST}/_get_employ_search_lists`, goParms)
      .then((res) => {
        // console.log(res.data)
        const resultList = res.data.list;
        setEmploySearchLists(resultList);
        setEmploySearchListsTotalCount(res.data.totalCount);
      }).catch((err) => {
        console.debug(err);
      });
    }else{
      // console.log("Navigate~~~~");
      navigate(`/MyRecruit/MainList/${varEmployType}/${varPageNum}/${paramsArr.length}`);
    }
    /*
    await axios
      .post(`${CONFIG.SERVER_HOST}/_get_employ_search_lists`, params)
      .then((res) => {
        console.log(res.data)
        const resultList = res.data.list;
        setEmploySearchLists(resultList);
        setEmploySearchListsTotalCount(res.data.totalCount);
      }).catch((err) => {
        console.debug(err);
      });
    */
  }, [user_idx, varCareerDirectList, varCareerList, varEmployType, varHeadHunter, varPageNum, varPageSize, varRegionList, varSearchString, varSortMode, varWorkingList, paramsArr, boolAxio, shIdx, navigate]);


  //검색버튼 클릭
  const onSearchingHandler = (e) => {
    // console.log("검색 클릭");
    setBoolAxio(false);
    getEmploySearchLists();
  };

  const handleTotalSearch = useCallback((string) => {
    setTotalList(true);
    setEmployType(-1);
    setSearchString(string);
  }, []);

  useEffect(() => {
    if (location !== undefined && location !== null && location.state !== null) {

      if (location.state.em_type !== undefined && location.state.em_type !== null) {
        setTotalList(false);
        setEmployType(location.state.em_type);
      }

      if (location.state.em_headhunter !== undefined && location.state.em_headhunter !== null) {
        setTotalList(false);
        setHeadHunter(location.state.em_headhunter);
      }

      if (location.state.sString !== undefined && location.state.sString !== null && location.state.sString !== "") {
        handleTotalSearch(location.state.sString);
      }

      //Nav 조정
      if (location.state.em_headhunter === 0) {
        resetNavMenu(0);
      } else {
        resetNavMenu(0);
      }
    }
  }, [handleTotalSearch, location, setHeadHunter]);

  useEffect(() => {
    if (location.state?.sString) {
      handleTotalSearch(location.state.sString);
      getEmploySearchLists();
    }
  }, [getEmploySearchLists, handleTotalSearch, location.state?.sString]);

  useEffect(() => {
    const mainBlock = document.querySelector('.myrecruit');
    if (varHeadHunter === 0) {
      if (mainBlock.classList.contains('myheadhunter')) mainBlock.classList.remove('myheadhunter');
      resetNavSubMenu(0, varEmployType - 1);
    } else {
      if (!mainBlock.classList.contains('myheadhunter')) mainBlock.classList.add('myheadhunter');
      resetNavSubMenu(0, 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varHeadHunter, varEmployType]);

  useEffect(() => {
    setHeadHunter(varHeadHunter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varHeadHunter, setHeadHunter]);

  //검색 실행 - axio

  useEffect(() => {
    getEmploySearchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varSortMode, varPageSize, varWorkingList, varRegionList, varCareerList, varCareerDirectList]);

  return (
    <div className="center_wrap myrecruit">
      
      <section className="page_myrecruit">
        <RecruitTopSearch
          varWorkingList={varWorkingList} setWorkingList={setWorkingList}
          varRegionList={varRegionList} setRegionList={setRegionList}
          varCareerList={varCareerList} setCareerList={setCareerList}
          varCareerDirectList={varCareerDirectList} setCareerDirectList={setCareerDirectList}
          varSearchOption={varSearchOption} setSearchOption={setSearchOption}
          varSearchString={varSearchString} setSearchString={setSearchString}
          varEmployType={varEmployType} setEmployType={setEmployType}
          varSortMode={varSortMode} setSortMode={setSortMode}
          varPageSize={varPageSize} setPageSize={setPageSize}
          onSearchingHandler={onSearchingHandler}
          varHeadHunter={varHeadHunter}
          getEmploySearchLists={getEmploySearchLists}
          varPageNum={varPageNum}
        />
        <RecruitMainList
          varHeadHunter={varHeadHunter}
          PageFolder={PageFolder}
          varWorkingList={varWorkingList}
          varRegionList={varRegionList}
          varCareerList={varCareerList}
          varSearchOption={varSearchOption}
          varSearchString={varSearchString}
          varEmployType={varEmployType}
          varSortMode={varSortMode}
          varPageSize={varPageSize}
          varExecuteSearch={varExecuteSearch}
          setExecuteSearch={setExecuteSearch}
          totalList={totalList}
          setTotalList={setTotalList}
          employSearchLists={employSearchLists}
          employSearchListsTotalCount={employSearchListsTotalCount}
          setPageNum={setPageNum}
          varPageNum={page}
          getEmploySearchLists={getEmploySearchLists}
        />
      </section>
    </div>
  );
};

export default MainList;