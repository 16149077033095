/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import _ from 'lodash';
import moment from "moment";
import { monthDiff2 } from '../Common/Common'


const careerupdate = () => {


  const [ced, setCED] = useState({
    certification_experience_year: '',
    certification_experience_date: ''
  });

  const [aed, setAED] = useState({
    additional_career_year: '',
    additional_career_date: ''
  });



  useEffect(() => {



    getResumeInformation();

  }, []);



  //최초 모든 이력서 가져오기
  const getResumeInformation = () => {
    let totalArr = [];
    axios.post(`${CONFIG.SERVER_HOST}/select_mem_idx`, CONFIG.header)
      .then((response) => {
        const { data } = response;
        console.log(data);
        if (data.result.length > 0) {
          const result = data.result;
          result?.map((el) => {
            //mem_idx
            
            const params = new URLSearchParams();
            params.append("mem_idx", el.mem_idx);
            axios.post(`${CONFIG.SERVER_HOST}/select_resume_idx`, params, CONFIG.header)
              .then((response) => {
                const { data } = response;
                if (data.result.length > 0) {
                  const result = data.result;
                  result?.map((el) => {
                    if (el.re_idx) {
                      //console.log(el.re_idx);
                      //select_career
                      let re_idx = el.re_idx;
                      const params = new URLSearchParams();
                      params.append("re_idx", re_idx);
                      axios.post(`${CONFIG.SERVER_HOST}/select_career`, params, CONFIG.header)
                        .then((response) => {
                          const { data } = response;
                          console.log(data);
                          let data1 = '';
                          let data2 = '';
                          if (data.result1.length > 0) {
                            data1 = monthDiff_1(data.result1);
                          }
                          if (data.result2.length > 0) {
                            data2 = monthDiff_2(data.result2);
                          }
                          const dataArr1 = data1.split('.');
                          const dataArr2 = data2.split('.');

                          // console.log(re_idx);
                          // console.log(data1);
                          // console.log(dataArr2[0], dataArr2[0]);
                          

                          Update(re_idx, dataArr1[0], dataArr1[1], dataArr2[0], dataArr2[1]);

                          // const params = new URLSearchParams();
                          // params.append("re_idx", el.re_idx);
                          // params.append("certification_experience_year", !dataArr1[0] ? 0:Number(dataArr1[0]));
                          // params.append("certification_experience_date", !dataArr1[1] ? 0:Number(dataArr1[1]));
                          // params.append("additional_career_year", !dataArr2[0] ? 0:Number(dataArr2[0]));
                          // params.append("additional_career_date", !dataArr2[1] ? 0:Number(dataArr2[1]));

                          // // totalArr.push({"re_idx": el.re_idx, "certification_experience_year": !dataArr1[0] ? 0:Number(dataArr1[0]), "certification_experience_date": !dataArr1[1] ? 0:Number(dataArr1[1]), "additional_career_year": !dataArr2[0] ? 0:Number(dataArr2[0]), "additional_career_date": !dataArr2[1] ? 0:Number(dataArr2[1])});
                          
                          // // console.log(totalArr);
                          // axios.post(`${CONFIG.SERVER_HOST}/career_update`, params, CONFIG.header)
                          //   .then((response) => {
                          //     const { data } = response;
                          //     console.log(data);
                    
                          //   }).catch((err) => {
                          //     console.log(err);
                          //     // const { message } = err.response;
                          //   });

                        }).catch((err) => {
                          console.log(err);
                          // const { message } = err.response;
                        });
                    }

                  })
                }

              }).catch((err) => {
                const { message } = err.response.data;
              });
          })
        }

      }).catch((err) => {
        const { message } = err.response.data;
      });
  };

  const monthDiff_1 = (arr) => {
    let monthArr = [];
    let total = 0;
    arr.map(item => {
      if (item.rec_sdate !== null) {
        const data1 = item.rec_sdate.split('-');
        const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
        const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        //console.log('t1 : ', item.rec_sdate, item.rec_edate, t1);
        total += t1;
      }

    });
    const quotient = parseInt(total / 12); //몫
    const remainder = total % 12; //나머지
    const t2 = total / 12;
    // const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient, remainder)

    let strCareer = ``;
    if (Number(monthArr[0]) > 0) {
      strCareer = `${monthArr[0]}년`;
    };
    if (Number(monthArr[1]) > 0) {
      strCareer += ` ${monthArr[1]}개월`;
    };

    //console.log(quotient+"."+remainder);
    return `${quotient}.${remainder}`;
    // setCED({
    //   certification_experience_year : quotient,
    //   certification_experience_date : remainder
    // });
  };

  const monthDiff_2 = (arr) => {
    let monthArr = [];
    let total = 0;
    arr.map(item => {
      if (item.rac_sdate !== null) {
        const data1 = item.rac_sdate.split('-');
        const data2 = item.rac_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rac_edate.split('-');
        const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        //console.log('t1 : ', item.rac_sdate, item.rac_edate, t1);
        total += t1;
      }

    });
    const quotient = parseInt(total / 12); //몫
    const remainder = total % 12; //나머지
    const t2 = total / 12;
    // const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient, remainder)

    let strCareer = ``;
    if (Number(monthArr[0]) > 0) {
      strCareer = `${monthArr[0]}년`;
    };
    if (Number(monthArr[1]) > 0) {
      strCareer += ` ${monthArr[1]}개월`;
    };

    //console.log(quotient+"."+remainder);

    return `${quotient}.${remainder}`;
    // setAED({
    //   additional_career_year : quotient,
    //   additional_career_date : remainder
    // });

  };


  const Update =(re_idx, dataArr1, dataArr2, dataArr3, dataArr4 ) => {

    console.log(re_idx);
    const params = new URLSearchParams();
    params.append("re_idx", re_idx);
    params.append("certification_experience_year", !dataArr1 ? 0:Number(dataArr1));
    params.append("certification_experience_date", !dataArr2 ? 0:Number(dataArr2));
    params.append("additional_career_year", !dataArr3 ? 0:Number(dataArr3));
    params.append("additional_career_date", !dataArr4 ? 0:Number(dataArr4));

    // totalArr.push({"re_idx": el.re_idx, "certification_experience_year": !dataArr1[0] ? 0:Number(dataArr1[0]), "certification_experience_date": !dataArr1[1] ? 0:Number(dataArr1[1]), "additional_career_year": !dataArr2[0] ? 0:Number(dataArr2[0]), "additional_career_date": !dataArr2[1] ? 0:Number(dataArr2[1])});
    
    // console.log(totalArr);
    axios.post(`${CONFIG.SERVER_HOST}/career_update`, params, CONFIG.header)
      .then((response) => {
        const { data } = response;
        console.log(data);

      }).catch((err) => {
        console.log(err);
        // const { message } = err.response;
      });
  }
  // cons

  return (<></>);
};

export default careerupdate;
