import React, { useEffect, useState } from "react";
import ReactApexChart  from 'react-apexcharts';
import { numberToKorean, numberToKorean3, isReactNative } from "../Common/Common";

function ApexChart({ Year, Incom, Tax, selectData, SelectIndex }) {

	const osChk = isReactNative();
	const [YearList, setYearList] = useState([]);
	const [IncomList, setIncomList] = useState([]);
	const [TaxList, setTaxList] = useState([]);
	const [Index, setIndex] = useState([]);

	//console.log(SelectIndex);

	useEffect(() => {
		if (Year) {
			setYearList(Year)
		}
	}, [Year]);
	useEffect(() => {
		if (Incom) {
			setIncomList(Incom)
		}
	}, [Incom]);
	useEffect(() => {
		if (Tax) {
			setTaxList(Tax)
		}
	}, [Tax]);
	useEffect(() => {
		setIndex(SelectIndex);
	}, [SelectIndex]);


	const chart = {
		options: {
			chart: {
				
				type: 'bar',
				toolbar: {
					show: true,
					tools: {
						download: false
					}
				},
				events: {
					dataPointSelection: function (event, chartContext, config) {
						console.log(config);

						selectData(Number(config.dataPointIndex), Number(config.seriesIndex));
					},
				},
				// animations: {
				// 	enabled: true,
				// 	easing: 'easeinout',
				// 	speed: 800,
				// 	animateGradually: {
				// 			enabled: true,
				// 			delay: 150
				// 	},
				// 	dynamicAnimation: {
				// 			enabled: true,
				// 			speed: 350
				// 	}
				// }
			},
			plotOptions: {
				bar: {
					borderRadius: 0,
					columnWidth: 36,
					colors: {
						backgroundBarOpacity: 1,
					},
					dataLabels: {
						position: 'top', // top, center, bottom
					},
				}
			},
			dataLabels: { //차트에 붙는 라벨 값
				enabled: true,
				formatter: function (val) {
					// return val > 0 ? `${numberToKorean(val)}원` : `${val}원`;
					return val > 0 ? `${numberToKorean3(val)}원` : `${val}원`;
				},
				offsetY: -20,
				style: {
					fontSize: '14px',
					fontWeight: '400',
					fontFamily: 'Noto Sans KR',
					colors: ["#000000"],
					cssClass: 'apexcharts-xaxis-label',
				},
				background: {
					padding: 5,
				},
				itemMargin: {
					vartical: 5,
				}
			},
			xaxis: {
				categories: YearList,
				position: 'bottom',
				labels: {
					style: {
						fontSize: '17px',
						fontWeight: '500',
						fontFamily: 'Noto Sans KR',
						colors: ["#000000"],
					},
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
				crosshairs: {
					fill: {
						colors :'#c3d2e3',
						type: 'gradient',
						gradient: {
							colorFrom: '#c3d2e3',
							colorTo: '#578bc9',
							stops: [0, 100],
							opacityFrom: 0.0,
							opacityTo: 0.0,
							colorStops: 3
						}
					}
				},
				tooltip: {
					enabled: false,
				}
			},
			colors: [
				function ({ value, seriesIndex, dataPointIndex, w }) {
					//console.log(value, seriesIndex, dataPointIndex, w, Index);
					if (seriesIndex === 0) {
						if (dataPointIndex === Index) {
							return "#193b65";
						} else {
							return '#91c2cf';
						}
					} else {
						if (dataPointIndex === Index) {
							return "#9f9f9f";
						} else {
							return '#c4b1b1';
						}
					}
				}
			],
			fill: {
				opacity: 0.95,
			},
			yaxis: {
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					formatter: function (val) {
						return val > 0 ? `${numberToKorean(val)}원` : `${val}원`;
					}
				}
			},
			legend: { //항목 라벨
				show: false,
			}
		},
		series: [{
			name: '소득',
			data: IncomList
		}, {
			name: '세금',
			data: TaxList
		}
		]
	};


	return (
		<ReactApexChart  options={chart.options} series={chart.series} type="bar" width={'100%'}  height={osChk ? 300 : 300}/>
	) 

}

export default ApexChart;