/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { isEmpty } from 'lodash';
import $ from 'jquery';

// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
// import required modules
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import {
  changeMobileFormat,
  checkEmailString,
  ChangeDateString,
  ChangeMoneyUnit,
  remainDays,
  em_titleLength
} from "../Common/Common";

const RecruitViewCompany = ({
  varEmIdx,
  varEmployInfo,
  varHeadHunter,
  onSelectEmployInterestHandler,
  ResumePage
}) => {
  const inputEmail2 = useRef();

  const defaultCompanyLogo = `${CONFIG.SERVER_HOST}/mcm_image/default_company.png`; // 디폴트 이미지.
  //console.log(varEmployInfo);
  const mem_mode = Number(localStorage.getItem("mem_mode"));//회원 구분 (0:일반/1:기업/2:헤드헌터)
  const mem_hunter_type = Number(localStorage.getItem("mem_hunter_type"));//회원 구분 (0:일반/1:기업/2:헤드헌터)
  //2022-08-20 비회원도 접근 가능 - KSR
  //const user_idx = localStorage.getItem("mem_idx");
  let user_idx = 0;
  if (localStorage.getItem("mem_idx") !== undefined && localStorage.getItem("mem_idx") !== null) {
    user_idx = Number(localStorage.getItem("mem_idx"));
  }
  const navigate = useNavigate();
  const companyFolder = `${CONFIG.SERVER_HOST}/mcm_image/`;

  const [varPopupResumeList, setPopupResumeList] = useState([]);
  const [varSelectedResume, setSelectedResume] = useState(0);
  const [varEmployName, setEmployName] = useState("");
  const [varEmployMobile, setEmployMobile] = useState("");
  const [varEmployEmail1, setEmployEmail1] = useState("");
  const [varEmployEmail2, setEmployEmail2] = useState("");
  // email 입력창 추가
  const [recommendOpen, setRecommendOpen] = useState(false);
  const [resumeOpen, setResumeOpen] = useState(false);

  const [taskString, setTaskString] = useState('');

  const [active, setAtive] = useState(false);

  // 지원하기 팝업창 페이징
  const [varCurrentPage, setCurrentPage] = useState(1); // 첫 페이지
  const [varPageSize] = useState(4); // 리스트 글 개수
  const indexOfLastList = varCurrentPage * varPageSize;
  const indexOfFirstList = indexOfLastList - varPageSize;
  const ResumeList = varPopupResumeList.slice(
    indexOfFirstList,
    indexOfLastList
  );

  let sortResumeList = ResumeList.sort((a, b) => b.re_fix - a.re_fix);


  useEffect(() => {
    $(window).resize(function () {
      if ($(window).width() > 1024) {
        $('.fly2').attr("style", "top:347px");
      }
    });
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); //clean up
    };
  }, []);

  const handleScroll = () => {
    $(window).scroll(function () {
      let scrollTop = $(this).scrollTop();
      let divTop = !document.querySelector(".aside") ? 0 : document.querySelector(".aside")?.offsetTop;
      let fullTop = scrollTop - divTop;

      if (scrollTop < divTop) {
        scrollTop = divTop;
      }

      let stdPos = $(this).scrollTop() + $(".fly2").height();
      let sbbGap = $(this).scrollTop() - document.querySelector(".sub_bottom_banner")?.offsetTop;

      // console.log(".sub_bottom_banner.offsetTop : " + document.querySelector(".sub_bottom_banner").offsetTop);
      // console.log("stdPos : " + stdPos);
      // console.log("sbbGap : " + sbbGap);
      if (stdPos > (document.querySelector(".sub_bottom_banner")?.offsetTop - 130)) {
        $('.fly2').stop().animate({ top: scrollTop - sbbGap - $(".fly2").height() - 130 }, 1000); //130은 배너 상단 margin값
      } else {
        $('.fly2').stop().animate({ top: scrollTop }, 1000);
      }

    });
  };

  const PrevResumePage = () => {
    if (varCurrentPage === 1) return;
    setCurrentPage(varCurrentPage - 1);
  };
  const NextResumePage = () => {
    const totalCount = Math.ceil(varPopupResumeList.length / varPageSize);
    if (varCurrentPage === totalCount) return;
    setCurrentPage(varCurrentPage + 1);
  };

  useEffect(() => {
    //console.log(user_idx);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    axios
      .post(`${CONFIG.SERVER_HOST}/_get_mysavedresume_list`, params)
      .then((res) => {
        // if (res.data.retvalue === 1) {
        console.log(res.data.results);
        const data = res.data.results;
        setPopupResumeList(data);
        // }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (varEmployInfo) {
      if (varEmployInfo && varEmployInfo.em_task && varEmployInfo.em_task.length > 0) {
        const tmpString = varEmployInfo.em_task.map((e) => {
          return `${e.vwc_name} > ${e.vwd_name}`
        });
        setTaskString(tmpString.join(`, `));
      }
      // console.log('148!!!!!!!!!!!', varEmployInfo?.mie_idx, varEmployInfo?.mie_status);
      setAtive(varEmployInfo?.mie_status === 1 ? true : false);
    }

  }, [varEmployInfo]);

  //서버로부터 정보가 넘어오기전이면
  if (varEmployInfo.length === 0) {
    return null;
  }

  //추천----------------------------------------------------------------
  //팝업창 호출
  const onSuggestHandler = () => {
    // e.preventDefault();
    if (user_idx > 0) {
      setEmployName("");
      setEmployMobile("");
      setEmployEmail1("");
      setEmployEmail2("");
      setRecommendOpen(true);
      document.getElementById("select_email").value = "";
    } else {
      alert("로그인  후 이용가능합니다.");
      navigate("/Login");
    }
  };
  //이름입력
  const onChangeEmployName = (e) => {
    setEmployName(e.target.value);
  };
  //휴대폰번호
  const onChangeEmployMobile = (e) => {
    const smobile = e.target.value.replace(/[^0-9]/g, "");
    const nmobile = changeMobileFormat(smobile);
    //console.log(smobile,nmobile);
    setEmployMobile(nmobile);
    e.target.value = nmobile;
  };
  //이메일
  const onChangeEmployEmail1 = (e) => {
    setEmployEmail1(e.target.value);
  };

  //이메일
  const onChangeEmployEmail2 = (e) => {
    setEmployEmail2(e.target.value);
  };

  //메일서버선택
  const onChangeMailServerHandler = (e) => {
    e.preventDefault();
    let eserver = e.currentTarget.value;
    if (eserver === '') {
      inputEmail2.current.focus();
    }
    setEmployEmail2(eserver);
  };

  const onCheckEmployEmail = () => {
    if (varEmployEmail1 === '' || varEmployEmail2 === '') {
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(`${varEmployEmail1}@${varEmployEmail2}`)) {
      setEmployEmail2('');
      inputEmail2.current.focus();
      alert("입력한 이메일주소가 형식에 맞지 않습니다.");
    }
  };

  //팝업창 닫기
  const onCloseSuggestHandler = () => {
    setRecommendOpen(false);
  };

  //추천하기 - proc. --------------------------------
  const onExecuteSuggestHandler = () => {
    var selectEmail = "";

    if (varEmployName === "") {
      alert("후보자명을 입력하세요.");
      return;
    }
    if (varEmployMobile === "") {
      alert("휴대폰 번호를 입력하세요.");
      return;
    }

    // 이메일 필수값 해제.
    // if (!checkEmailString(varEmployEmail1 + "@" + varEmployEmail2)) {
    //   alert("입력한 이메일주소가 형식에 맞지 않습니다.");
    //   return;
    // }

    if (!isEmpty(varEmployEmail1) || !isEmpty(varEmployEmail2)) {
      selectEmail = `${varEmployEmail1}@${varEmployEmail2}`

      if (!checkEmailString(selectEmail)) {
        alert("입력한 이메일주소가 형식에 맞지 않습니다.");
        return;
      }

    }

    const suggestPopup = document.querySelector(".popup");
    if (suggestPopup.classList.contains("active")) {
      suggestPopup.classList.remove("active");
    }
    var er_mem_idx = 0;
    let ourCode = '1013';
    //데이타베이스에 추청하기 저장
    //추천인이 회원인지 유무확인
    const params = new URLSearchParams();
    params.append("sname", varEmployName);
    params.append("smobile", varEmployMobile);
    params.append("semail", selectEmail);
    axios.post(`${CONFIG.SERVER_HOST}/_get_member_suggest_employ`, params).then((res) => {
      //회원이면 회원고유번호 전달
      if (res.data.retvalue === 1) {
        er_mem_idx = res.data.er_mem_idx;
        ourCode = '1005'
      }

      //추천정보 저장
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("er_mem_idx", er_mem_idx);
      params.append("em_idx", varEmIdx);
      params.append("sname", varEmployName);
      params.append("smobile", varEmployMobile);
      params.append("semail", selectEmail);
      params.append("user_name", localStorage.getItem('mem_name'));
      params.append("task", taskString);
      params.append("mcm_name", varEmployInfo.com_name); // mcm_name -> com_name, 2022.11.14 jskim
      params.append("our_code", ourCode);
      params.append("job_posting", varEmployInfo.em_title);
      params.append("url", `${CONFIG.URL}/MyRecruit/MainView/${varEmIdx}/0`);
      // params.append("url", `https://heading.awsome-app.kr/MyRecruit/MainView/${varEmIdx}/0`);

      axios
        .post(`${CONFIG.SERVER_HOST}/_update_suggest_employ`, params)
        .then((res) => {
          window.alert(res.data.message);
        });
    });
    setRecommendOpen(false);
  };//끝. 추천하기 proc.

  //지원----------------------------------------------------------------
  //지원하기  클릭 - 이력서 목록 팝업 호출
  const onApplyHandler = () => {
    if (user_idx > 0) {
      setResumeOpen(true);
    } else {
      alert("로그인  후 이용가능합니다.");
      navigate("/Login");
    }
  };

  //지원할 이력서 선택
  const onSelectedResume = (re_idx) => {
    setSelectedResume(re_idx);
  };

  //팝업창 닫기
  const onCloseApplyHandler = (e) => {
    // e.preventDefault();
    setSelectedResume("");
    setResumeOpen(false);
    setCurrentPage(1);
  };

  //지원하기-----
  const onExecuteApplyHandler = (e) => {
    //기업회원=자기채용공고 이면 제외
    if (varEmployInfo.mcm_idx === localStorage.getItem("mcm_idx")) {
      alert("귀하의 회사에서 공고한 채용입니다. 귀하는 지원할 수 없습니다.");
      return false;
    }
    //이력서 선택 유무 확인
    if (varSelectedResume <= 0) {
      alert("지원할 이력서를 선택해주세요.");
      return false;
    }
    setResumeOpen(false);

    //데이타베이스에 저장
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("em_idx", varEmIdx);
    params.append("re_idx", varSelectedResume);
    params.append("user_name", localStorage.getItem('mem_name'));
    params.append("job_posting", varEmployInfo.em_title);
    params.append("em_mem_idx", varEmployInfo.em_mem_idx);
    axios
      .post(`${CONFIG.SERVER_HOST}/_update_apply_employ`, params)
      .then((res) => {
        if (res.data.retvalue === 1) {
          console.log(res.data.message);
          window.alert(res.data.message);
        } else {
          window.alert(res.data.message);
        }

      });
  };

  return varEmployInfo === undefined ||
    varEmployInfo === null ||
    varEmployInfo.mcm_image === null ? null : (
    <>
      <aside className="myrecruit_view_right aside">
        <div className="fly2">

          <article className="recruit_card_big" style={varEmployInfo.em_type === 3 ? { borderBottom: "1px solid #dedede" } : { borderBottom: "none" }}>
            <div
              className={
                active === false
                  ? "btn_bookmark m_resize"
                  : "btn_bookmark m_resize active"
              }
              onClick={(e) =>
                onSelectEmployInterestHandler(e, 0, varEmployInfo?.em_idx)
              }
            ></div>
            <div className="image_wrap">

              {/* 비공개 채용일 경우 이미지를 기본 이미지로 보이게. */}
              {(function () {
                if (varEmployInfo.em_image === 1 && varEmployInfo.em_type !== 2) {
                  return <img src={`${CONFIG.SERVER_HOST}/em_image/${varEmployInfo.em_ufile}`} alt="" />
                } else {
                  if (varEmployInfo.mcm_image === 0) {
                    return <img src={`${defaultCompanyLogo}`} alt="" />
                  } else if (varEmployInfo.em_type === 2 || varEmployInfo.mcm_image === 1) {
                    return <img src={`${CONFIG.SERVER_HOST}/mcm_image/${varEmployInfo.mcm_ufile}`} alt="" />
                  }
                }
              })()}
            </div>
            <div className="content_wrap">
              <div className="type_wrap">
                <span className="type">
                  {varEmployInfo.em_type === 1 ? "공개채용" : ""}
                  {varEmployInfo.em_type === 2 ? "비공개채용" : ""}
                  {varEmployInfo.em_type === 3 ? "실시간 채용관" : ""}
                  {varEmployInfo.em_type === 4 ? "헤드헌터 전용관" : ""}
                </span>
                <span className="new_badge"></span>
              </div>
              <h2 className="title">{varEmployInfo?.em_title}</h2>
              <table className="recruit_info">
                <tr>
                  <th>직무</th>
                  <td>{taskString}</td>
                </tr>
                {/* <tr>
                <th>세부직무</th>
                <td>{varEmployInfo?.vwd_name}</td>
              </tr> */}
                {/* <tr>
                <th>채용 포지션</th>
                <td>기획자</td>
              </tr> */}
                {/* <tr>
                  <th>기업형태</th>
                  <td>{varEmployInfo?.vct_name}</td>
                </tr> */}
                <tr>
                  <th>홈페이지</th>
                  <td>
                    {varEmployInfo.com_homepage === "" || varEmployInfo.com_homepage === "null" ? "-" : varEmployInfo.com_homepage}
                  </td>
                </tr>
                <tr>
                  <th>지원기간</th>
                  <td>
                    {ChangeDateString(varEmployInfo?.em_sdate)}
                    &nbsp;&nbsp;~&nbsp;&nbsp;
                    {varEmployInfo?.em_edate === null ? "채용시 마감" : ChangeDateString(varEmployInfo?.em_edate)}
                  </td>
                </tr>
              </table>
            </div>
          </article>
          {varEmployInfo.em_type === 3 ? null :
            <div className="compensation">
              <ul>
                <li>
                  <span className="compensation_title">추천인 보상금</span>
                  <b>{varEmployInfo.em_type === 4 ? ChangeMoneyUnit(varEmployInfo?.emh_reward_recommend / 10000 - varEmployInfo?.emh_reward_applicant / 10000) : ChangeMoneyUnit(varEmployInfo?.em_reward_recommend / 10000 - varEmployInfo?.em_reward_applicant / 10000)}</b>
                </li>
                <li>
                  <span className="compensation_title">합격자 보상금</span>
                  <b>{varEmployInfo.em_type === 4 ? ChangeMoneyUnit(varEmployInfo?.emh_reward_applicant / 10000) : ChangeMoneyUnit(varEmployInfo?.em_reward_applicant / 10000)}</b>
                </li>
                {/* <li>
              <span className="compensation_title">보상금 지급 조건</span>
              <b>{varEmployInfo?.vrp_name || "없음"}</b>
            </li> */}
              </ul>
            </div>
          }
          {/* } */}
          {//기간 상관없이 '상태'만으로 노출 - KSR
            //varEmployInfo.em_status === 1 && remainDays(varEmployInfo.em_edate) >= 0 ? (
            varEmployInfo.em_status === 1 ? (
              <div className="button_wrap">
                {/*2022-09-25 추천하기 기능은 '헤드헌터'회원만, 기존 프리랜서, 서치펌 기준 없어짐 - KSR*/}
                {//((varHeadHunter === 0 && mem_mode === 1) || (varHeadHunter === 1 && mem_mode !== 2)) ? null : (
                  varEmployInfo.em_type === 4 ? (//헤드헌터 전용관
                    mem_mode === 2 && mem_hunter_type === 2 ? (
                      <button className="btn white" onClick={() => onSuggestHandler()}>
                        추천하기
                      </button>
                    ) : null
                  ) : //---- 일반(공개/비공개/파견,알바,계약)
                    (
                      //mem_mode !== 0 ? (//2022-09-28 일반 채용공고 '추천'은 누구나 - KSR
                      //2022-11-09 '추천'은 기업회원은 안됨 - KSR
                      mem_mode !== 1 &&
                      <button className="btn white" onClick={() => onSuggestHandler()}>
                        추천하기
                      </button>
                      //) : null
                    )}
                {//지원하기는 '일반'회원만 - KSR
                  //((varHeadHunter === 0 && mem_mode === 1) || (varHeadHunter === 0 && mem_mode === 2)) ? null : (
                  mem_mode === 0 ? (
                    <button className="btn" onClick={() => onApplyHandler()}>
                      지원하기
                    </button>
                  ) : null}
              </div>
            ) : null}
        </div>
      </aside>

      {/* 나의 이력서목록 가져오기 - POP *******************************************************/}
      <section className={`popup${resumeOpen === true ? " active" : ""}`}>
        <article className="popup_wrap popup01">
          <div className="title_wrap">
            <h1>헤딩 이력서 선택</h1>
            {/* 새 디자인 적용한 부분 */}
            <div className="right_wrap">
              <div className="arrow_button_wrap">
                <button className="btn_arrow" onClick={() => PrevResumePage()}></button>
                <button className="btn_arrow" onClick={() => NextResumePage()}></button>
              </div>
            </div>
          </div>
          <div className="content_wrap resume_style">
            {sortResumeList.map((resumes, i) => (
              <article className="radio_wrap" key={"resume_" + i}>
                <input
                  type="radio"
                  name="radio"
                  id={resumes.re_idx}
                  onChange={() => onSelectedResume(resumes.re_idx)}
                />

                <label
                  htmlFor={resumes.re_idx}
                  className="resume_box"
                  checked={varSelectedResume === resumes.title ? "checked" : ""}
                >
                  <section className="v_box_wrap">
                    <i className="v_box"></i>
                  </section>
                  <section className="resume_box_content">
                    <div className="resume_box_top">
                      <p className="title_field">{resumes.re_title}</p>
                      {resumes.re_fix === 1 ? (
                        <span className="table_notice_badge">대표</span>
                      ) : null}
                    </div>
                    <div
                      className={
                        resumes.re_perfection === 0
                          ? "resume_box_bottom"
                          : "" || resumes.re_perfection === 25
                            ? "resume_box_bottom step1"
                            : "" || resumes.re_perfection === 50
                              ? "resume_box_bottom step2"
                              : "" || resumes.re_perfection === 75
                                ? "resume_box_bottom step3"
                                : "" || resumes.re_perfection === 100
                                  ? "resume_box_bottom step4"
                                  : ""
                      }
                    >
                      <article className="resume_progress">
                        <div className="resume_progress_bar"></div>
                      </article>
                    </div>
                    <div className="resume_box_button">
                      <p className="title_field">
                        이력서 완성 <b>{resumes.re_perfection}</b>
                      </p>
                      <Link
                        className="btn2 black"
                        to={`/MyResume/ResumeDetail/${resumes.re_idx}/web`}
                        state={{ re_idx: resumes.re_idx }}
                      >
                        미리보기
                      </Link>
                    </div>
                  </section>
                </label>
              </article>
            ))}
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button className="btn" onClick={(e) => onExecuteApplyHandler(e)}>
                지원하기
              </button>
            </div>
            <div
              className="close_button_wrap"
              onClick={() => onCloseApplyHandler()}
              style={{cursor:"pointer"}}
            >
              닫기 X
            </div>
          </div>
          {/* 새 디자인 적용한 부분 */}
        </article>
      </section>
      {/*끝. 지원하기-POP *************************************************************/}

      {/* 추천하기 - POP **************************************************************/}
      <section className={`popup${recommendOpen === true ? " active" : ""}`}>
        <article className="popup_wrap popup01">
          <div className="title_wrap">
            <h1>추천하기</h1>
          </div>
          <div className="content_wrap">
            <article className="field">
              <label className="label">후보자명</label>
              <input
                type="text"
                name="suggest_name"
                value={varEmployName}
                onChange={onChangeEmployName}
              />
            </article>
            <article className="field">
              <label className="label">휴대폰 번호</label>
              <input
                type="text"
                name="suggest_mobile"
                value={varEmployMobile}
                onChange={onChangeEmployMobile}
                placeholder="-는 제외하고 입력해주세요."
              />
            </article>
            <article className="field">
              <label className="label">이메일(선택)</label>
              <div className="input_wrap email_field">
                <input
                  type="text"
                  name="email1"
                  id="email1"
                  value={varEmployEmail1}
                  // onBlur={() => onCheckEmployEmail()}
                  onChange={onChangeEmployEmail1}
                />
                <span className="text">@</span>
                <input
                  type="text"
                  name="email2"
                  id="email2"
                  value={varEmployEmail2}
                  onChange={onChangeEmployEmail2}
                  onBlur={() => onCheckEmployEmail()}
                  ref={inputEmail2}
                />
                <select id="select_email" onChange={onChangeMailServerHandler}>
                  <option value={""}>직접입력</option>
                  <option value={"hotmail.com"}>hotmail.com</option>
                  <option value={"hanmail.net"}>hanmail.net</option>
                  <option value={"icloud.com"}>icloud.com</option>
                  <option value={"naver.com"}>naver.com</option>
                  <option value={"google.com"}>google.com</option>
                </select>
              </div>
            </article>
            <p className="recommen_comment">
              해당 공고에 딱 맞는 후보자의 이름과 연락처를 입력해주세요.
              <br />
              추천하기를 누르면 후보자에게 채용공고 링크가 전달됩니다.
            </p>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button
                className="btn white"
                onClick={() => onExecuteSuggestHandler()}
              >
                추천하기
              </button>
            </div>
            <div
              className="close_button_wrap"
              onClick={() => onCloseSuggestHandler()}
            >
              <a>닫기 X</a>
            </div>
          </div>
        </article>
      </section>
      {/*끝. 추천하기-POP ***********************************************************************/}
    </>
  );
};

export default RecruitViewCompany;
