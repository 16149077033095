/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from '../../configs/configs';
//component
import Paging from "../../components/Layout/Paging";
import { Link } from 'react-router-dom'

const ResumeLimitCompanies = ({ varLimitCompanyList,setLimitCompanyList,varChangeLimitList,setChangeLimitList, userName }) => {
  const user_idx = Number(localStorage.getItem('mem_idx'));
  //pages
  const [varTotalCount,setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //열람제한 기업목록
  const getLimitCompany = async () => {
    setChangeLimitList(false);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("pageSize", varPageSize);
    params.append("pageNum", varPageNum);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_limit_companies`, params).then((res) => {
      console.log(res.data);
      if(res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        setLimitCompanyList(res.data.results);
      }
    });
  }
  
  useEffect(() => {
    getLimitCompany();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLimitCompany();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varChangeLimitList,varPageNum]);

/*   //기업목록 저장
  const onSaveLimitCompany = (e) => {

  } */
  //열람제한 기업목록에서 삭제
  const onDeleteLimitCompany = async (e,rlc_idx) => {
    const params = new URLSearchParams();
    params.append("rlc_idx", rlc_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_limit_company`, params).then((res) => {
      console.log(res.data);
      //alert(res.data.message);
      if(res.data.retvalue === 1) {
        //리미트 재표시
        getLimitCompany();
      }
    });
  }

  return (
    <>
      <section className="list_top">
        <h2 className="sub_content_title">열람 제한 기업 리스트</h2>
        {/* <a href={() => false} className="btn" onClick={(e)=>onSaveLimitCompany(e)}>저장하기</a> */}
      </section>
      <table className="table_list01">
        <tbody>
        {varLimitCompanyList.length===0?<tr><td className="td_title with_btn">{'열람이 제한된 기업이 없습니다.'}</td></tr>:
          varLimitCompanyList.map((company,i) => (
            <tr key={"limitcomp_"+i}>
              <td className="td_title with_btn">{company.rlc_mcm_name}</td>
              <td className="td_button">
                <a className="btn line gray" onClick={(e)=>onDeleteLimitCompany(e,company.rlc_idx)}>삭제하기</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="paging">
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
      </div>
      <div className="restricted_setting">
        <p className="title_field">열람 제한 기업 설정</p>
        <div className="wrap">
          <p className="text">
            열람 제한 기업 설정 시 해당 기업에서 <span className="point">{userName}</span>님이 검색되지 않습니다.<br/>
            <span className="point">{userName}</span>님의 이력서 등록 여부 확인이 불가능하며, 이력서 및 이직 희망 여부를 확인할 수 없습니다. <br/>
            현재 재직중인 기업에 이직 희망 여부를 알게 하고 싶지 않을 때 사용할 수 있습니다.
          </p>
        </div>
      </div>
    </>
  );
}

export default ResumeLimitCompanies