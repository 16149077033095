import React, { useEffect } from "react";

//컴포넌트
import MyNoticeList from 'components/My/MyNoticeList';
import { resetNavSubMenu } from "../Common/Common";

function MyHeding8({ getSubItem, NumReset }) {
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  useEffect(() => {
    getSubItem(8);
    if(mem_mode=== 0){
      resetNavSubMenu(4,8);
    }else{
      resetNavSubMenu(2,8);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div className="container">
      <MyNoticeList NumReset={NumReset} />
    </div>
  );
}

export default MyHeding8;