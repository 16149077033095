import React, { useEffect } from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';
import { remainDays } from '../Common/Common'

function CompanyVoucherTop({ varVoucherHaving,setVoucherHaving }) {
 
  const getId = localStorage.getItem("mem_id");
  const mcm_idx = localStorage.getItem('mcm_idx');

  //보유현황
  const getVoucherHavings = async () => {
    try {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      //2022-10-30 주문서(voucher_orders) 테이블에서 관리 - KSR
      await axios.post(`${CONFIG.SERVER_HOST}/voucherRemainList2`, params).then((res) => {
        if (res.data.retvalue === 1) {
          console.log(res.data.results);
          let datas = res.data.results;
          const remainday = remainDays(datas.mcc_edate);
          datas.remaindays = remainday;
          setVoucherHaving(datas);
        } else {
          console.log(res.data.message);
        }
      });
      /* 2022-10-30 기존, 합산 계산방식 사용 안함
      await axios.post(`${CONFIG.SERVER_HOST}/voucherRemainList`, params).then((res) => {
        if (res.data.retvalue === 1) {
          console.log(res.data.results);
          let datas = res.data.results;
          const remainday = remainDays(datas.mcc_edate);
          datas.remaindays = remainday;
          setVoucherHaving(datas);
        } else {
          console.log(res.data.message);
        }
      });
      */
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVoucherHavings();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="box box_content subtop_info_area">
        <ul>
            <li><i className="gray_icon voucher"></i><b>{getId}</b>님의 남은 이용권 상태입니다.</li>
            <li className="remain_wrap">
            <div><i className="gray_icon remain01"></i>잔여 제안<b>{varVoucherHaving?.mcc_sending+" 건"}</b></div>
            <div>
              <i className="gray_icon remain02"></i>잔여 이용기간<b>
              ({(varVoucherHaving?.mcc_sending > 0 && varVoucherHaving?.remaindays >= 0)?`사용중 : ${varVoucherHaving?.remaindays}일`:"사용종료"})</b>
            </div>
            </li>
        </ul>
    </section>
  );
}

export default CompanyVoucherTop;
