/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import CONFIG from "../../configs/configs";
import Paging from "../Layout/Paging";

import DownloadIcon from "img/sub/pdf_download.png";
import { mobileWrapSearchTalent, em_titleLength } from "../Common/Common";

const SupportListC = () => {
  // css script 구문 추가
  useEffect(() => {
    mobileWrapSearchTalent();
  }, []);

  // const mem_idx = localStorage.getItem('mem_idx');
  // const com_idx = localStorage.getItem('com_idx');
  const mcm_idx = localStorage.getItem("mcm_idx");
  const mem_idx = localStorage.getItem("mem_idx");
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const mem_hunter_type = Number(localStorage.getItem("mem_hunter_type"));

  //헤드헌터 - 써치폼 확인
  let SearchForm = 0;
  if (mem_mode === 2 && mem_hunter_type >= 1) {
    SearchForm = 1;
  }

  //표시할 배열
  const [varRecruitment, setRecruitment] = useState([]);
  const [varFilterRecruitment, setFilterRecruitment] = useState([]);
  const [varPageRecruitment, setPageRecruitment] = useState([]);
  const [varWorkers, setWorkers] = useState([]);
  //공고 진행상태 숫자
  const [varCounts, setCounts] = useState({
    _all_count: 0,
    _all_proceeding: 0,
    _all_waiting: 0,
    _all_close: 0,
  });
  const [varTabEmployStatus, setTabEmployStatus] = useState(4);

  //검색
  const [varMemberIndex, setMemberIndex] = useState(0);
  const [varSearchOption, setSearchOption] = useState("");
  const [varSearchText, setSearchText] = useState("");
  const [varSearching, setSearching] = useState(true);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  const [onExcel, setOnExcel] = useState(false);
  const getSupporterLists = async () => {
    if (varSearching) {
      const sendData = {
        'mcm_idx': mcm_idx,
        'mem_idx': mem_idx,
        'SearchForm': SearchForm,
        'varMemberIndex': varMemberIndex, /* 작성자 선택 */
        'varSearchOption': varSearchOption, /* 검색 옵션 */
        'varSearchText': varSearchText /* 검색어  */
      };
      await axios.post(`${CONFIG.SERVER_HOST}/_get_employ_support_list`, sendData, CONFIG.header).then((res) => {
        if (res.data.employList?.length > 0) {
          setRecruitment(res.data.employList);
        } else {
          setRecruitment([]);
        }
        //채용공고 관련 상태별 수량
        if (res.data.employCount?.length > 0) {
          setCounts({
            _all_count: res.data.employCount[0]._all_count,
            _all_proceeding: res.data.employCount[0]._all_proceeding,
            _all_waiting: res.data.employCount[0]._all_waiting,
            _all_close: res.data.employCount[0]._all_close,
          });
        }
        //기업직원 명부
        if (res.data.workers?.length > 0) {
          setWorkers(res.data.workers);
        }
      });
      setSearching(false);
    }

  };


  useEffect(() => {
    getSupporterLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSearching]);

  //공고상태 탭 선택시
  const ResetFilteredRecruitment = () => {
    let copyLists = [...varRecruitment];
    if (varTabEmployStatus < 4) {
      copyLists = copyLists.filter(
        (element) => element.em_status === varTabEmployStatus
      );
    }

    //작성자
    if (varMemberIndex > 0) {
      copyLists = copyLists.filter(
        (element) => element.mem_idx === Number(varMemberIndex)
      );
    }

    setFilterRecruitment(copyLists);
    setTotalCount(copyLists.length);
    setPageNum(1);
    //필터링된 배열이 없으면 하부의 페이지필터링이 작동되지 않습니다.
    if (copyLists.length === 0) {
      setPageRecruitment([]);
    }

  };
  useEffect(() => {
    ResetFilteredRecruitment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabEmployStatus, varRecruitment, varMemberIndex]);

  //표시할 페이지가 바뀌면
  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varFilterRecruitment.length === 0) {
      setPageRecruitment([]);
      return;
    }
    if (onExcel) {
      setPageRecruitment(varFilterRecruitment);
    } else {
      let copyData = [...varFilterRecruitment];
      const startNum = (varPageNum - 1) * varPageSize;
      const endNum = startNum + varPageSize;
      copyData = copyData.slice(startNum, endNum);
      setPageRecruitment(copyData);
    }
  };

  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varFilterRecruitment, onExcel]);

  //검색을 위한 작성자 선택
  const onChangeWriteMemberHandler = (e) => {
    setMemberIndex(e.currentTarget.value);
    setSearching(true);
  };
  //검색옵션
  const onSearchOptionHandler = (e) => {
    setSearchOption(e.currentTarget.value);
  };
  //검색문자
  const onSearchStringHandler = (e) => {
    setSearchText(e.currentTarget.value);
  };
  //검색시작
  const onExecuteSerchString = (e) => {
    e.preventDefault();
    setSearching(true);
  };

  //엑셀파일로 다운로드
  const onDownloadExcel = (e) => {
    // e.preventDefault();
    setOnExcel(true)
    if (varPageRecruitment.length === varFilterRecruitment.length) {
      var tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
      tab_text += '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
      tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
      tab_text += '<x:Name>Products</x:Name>';
      tab_text += '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
      tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
      tab_text += "<table border='1px'>";

      var exportTable = $('.table_list01').clone(true);  //테이블 전체를 복제
      $(exportTable).find('.sort_wrap').remove();	//불필요한 정보 삭제 (화살표)
      $(exportTable).find('span').remove(); //span 삭제
      console.log($(exportTable).find('span'))
      var trlines = $(exportTable).find('.tr');
      for (var i = 0; i < trlines.length; i++) {
        tab_text += "<tr>";
        if (i === 0) { //th
          var thlines = $(trlines[i]).find('.th');
          for (var j = 0; j < thlines.length; j++) {
            tab_text += "<th>" + $(thlines[j]).html() + "</th>";
          }
        } else {  //td
          var tdlines = $(trlines[i]).find('.td');
          for (var k = 0; k < tdlines.length; k++) {
            //a check
            if (k === 2) {
              var alines = $(tdlines[k]).find('a');
              console.log($(alines).html());
              tab_text += "<td>" + $(alines).html() + "</td>";
            } else {
              tab_text += "<td>" + $(tdlines[k]).html() + "</td>";
            }
          }
        }
        tab_text += "</tr>";
      }
      $(exportTable).remove();

      tab_text += '</table></body></html>';
      //var data_type = 'data:application/vnd.ms-excel';
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
      var fileName = '채용공고지원자관리.xls';

      // IE 환경에서 다운로드
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {  //*rv\: 에서 \ 제거
        if (window.navigator.msSaveBlob) {
          var blob = new Blob([tab_text], {
            type: "application/csv;charset=utf-8;"
          });
          navigator.msSaveBlob(blob, fileName);
        }
      } else {
        var blob2 = new Blob([tab_text], {
          type: "application/csv;charset=utf-8;"
        });
        var filename = fileName;
        var elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob2);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
      setOnExcel(false)
    };


  }
  useEffect(() => {
    if (onExcel) {
      onDownloadExcel()
    }
  })
  const onAscSortingHandler = (e, key) => {
    e.preventDefault();
    let arrayCopy = [...varFilterRecruitment];
    arrayCopy.sort(ASC(key));
    setFilterRecruitment(arrayCopy);
    setPageNum(1);
  };

  const onDescSortingHandler = (e, key) => {
    e.preventDefault();
    let arrayCopy = [...varFilterRecruitment];
    arrayCopy.sort(DESC(key));
    setFilterRecruitment(arrayCopy);
    setPageNum(1);
  };

  const ASC = (key) => {
    return function (a, b) {
      var x = parseInt(a[key]);
      var y = parseInt(b[key]);

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    };
  };

  const DESC = (key) => {
    return function (a, b) {
      var x = parseInt(a[key]);
      var y = parseInt(b[key]);

      if (x > y) return -1;
      if (x < y) return 1;
      return 0;
    };
  };

  return (
    <>
      <section className="list_top">
        <ul className="state_area">
          <li>
            <input
              type="radio"
              name="chk1"
              id="check_last_info1"
              className="chk1"
              checked={varTabEmployStatus === 4 ? true : false}
              onChange={() => setTabEmployStatus(4)}
            />
            <label htmlFor="check_last_info1">전체 <span>{varCounts._all_count === null ? 0 : varCounts._all_count}</span></label>
          </li>
          <li>
            <input
              type="radio"
              name="chk1"
              id="check_last_info2"
              className="chk1"
              checked={varTabEmployStatus === 1 ? true : false}
              onChange={() => setTabEmployStatus(1)}
            />
            <label htmlFor="check_last_info2">진행중 <span>{varCounts._all_proceeding === null ? 0 : varCounts._all_proceeding}</span></label>
          </li>
          <li>
            <input
              type="radio"
              name="chk1"
              id="check_last_info3"
              className="chk1"
              checked={varTabEmployStatus === 0 ? true : false}
              onChange={() => setTabEmployStatus(0)}
            />
            <label htmlFor="check_last_info3">대기중 <span>{varCounts._all_waiting === null ? 0 : varCounts._all_waiting}</span></label>
          </li>
          <li>
            <input
              type="radio"
              name="chk1"
              id="check_last_info4"
              className="chk1"
              checked={varTabEmployStatus === 2 ? true : false}
              onChange={() => setTabEmployStatus(2)}
            />
            <label htmlFor="check_last_info4">마감 <span>{varCounts._all_close === null ? 0 : varCounts._all_close}</span></label>
          </li>
        </ul>
        {/* 검색조건 */}
        {/* <div className="BoardSearch"> */}
        <article className="middle_wrap">
          <p className="select_label">작성자<span>&nbsp;&nbsp;</span></p>
          <select className="style_select" value={varMemberIndex} onChange={(e) => onChangeWriteMemberHandler(e)}>
            <option value="0">전체</option>
            {varWorkers.map((worker, i) => (
              <option key={"worker_" + i} value={worker.mem_idx}>{worker.mem_id}</option>
            ))}
          </select>
        </article>
        <article className="last_wrap">
          <div className="list_search_area">
            <select className="style_select" value={varSearchOption} onChange={(e) => onSearchOptionHandler(e)}>
              <option value="">전체</option>
              <option value="em_title">공고제목</option>
              <option value="mem_id">아이디</option>
              <option value="mem_name">이름</option>
            </select>
            <div className="search">
              <input
                type="text"
                name="SearchText"
                value={varSearchText}
                placeholder="검색어를 입력해주세요"
                onChange={(e) => onSearchStringHandler(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onExecuteSerchString(e)
                  }
                }}
              />
            </div>
            <button className="btn" onClick={(e) => onExecuteSerchString(e)}>조회</button>
            {/* </div> */}
          </div>
        </article>
      </section>
      <section className="support_list_area">
        <ul className="table table_list01">
          {/* <div className="List pc"> */}
          <li className="tr">
            <article className="th row_num">번호</article>
            <article className="th row_state">상태</article>
            <article className="th row_title">공고제목</article>
            <article className="th row_name">작성자 <br /> (이름)</article>
            <article className="th row_sort sort1">총 지원자
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_total")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_total")}
                ></i>
              </div>
            </article>
            <article className="th row_sort sort2">미열람
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_unread")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_unread")}
                ></i>
              </div>
            </article>
            <article className="th row_sort sort3">
              검토중
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_under_review")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_under_review")}
                ></i>
              </div>
            </article>
            <article className="th row_sort sort4">서류합격
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_pass_the_documents")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) =>
                    onDescSortingHandler(e, "_pass_the_documents")
                  }
                ></i>
              </div>
            </article>
            <article className="th row_sort sort5">면접합격
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_interview_pass")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_interview_pass")}
                ></i>
              </div>
            </article>
            <article className="th row_sort sort6">
              최종합격
              <div className="sort_wrap">
                <i
                  className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_final_pass")}
                ></i>
                <i
                  className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_final_pass")}
                ></i>
              </div>
            </article>
            <article className="th row_sort sort7">불합격
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_fail")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_fail")}
                ></i>
              </div>
            </article>
            <article className="th row_sort sort8">입사
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_join")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_join")}
                ></i>
              </div>
            </article>
            <article className="th row_sort sort9">보증기간 내 퇴사
              <div className="sort_wrap">
                <i className="icon_sort_arrow up"
                  onClick={(e) => onAscSortingHandler(e, "_early_retirement")}
                ></i>
                <i className="icon_sort_arrow down"
                  onClick={(e) => onDescSortingHandler(e, "_early_retirement")}
                ></i>
              </div>
            </article>
          </li>
          {varPageRecruitment.length === 0 ?
            <li className="tr" >
              <article className="td no_data">데이터가 없습니다</article>
            </li>
            : varPageRecruitment.map((support, i) => (
              <li className="tr" key={"support_" + i}>
                <article className="td row_num">
                  {onExcel ? i + 1 : varTotalCount - (varPageNum - 1) * varPageSize - i}
                </article>
                <article className={
                  support.em_status === 0
                    ? "td row_state txt_blue m_group"
                    : "td row_state txt_red m_group"
                }
                >
                  {support.em_status === 0
                    ? "대기중"
                    : support.em_status === 1
                      ? "진행중"
                      : "마감"}
                </article>
                <article className="td row_title m_group">
                  <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}
                    style={{ fontSize: "inherit", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                    {em_titleLength(support.em_title, 24, '...')}
                  </Link>
                </article>
                <article className="td row_name m_group">
                  {support.mem_id}
                  <br />({support.mem_name})
                </article>
                <article className="td row_sort sort1">
                  <span className="m_th">총 지원자</span>
                  {support._total === 0 ? support._total : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._total}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort2 txt_red">
                  <span className="m_th">미열람</span>
                  {support._unread === 0 ? support._unread : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._unread}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort3">
                  <span className="m_th">검토중</span>
                  {support._under_review === 0 ? support._under_review : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._under_review}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort4">
                  <span className="m_th">서류 합격</span>
                  {support._pass_the_documents === 0 ? support._pass_the_documents : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._pass_the_documents}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort5">
                  <span className="m_th">면접 합격</span>
                  {support._interview_pass === 0 ? support._interview_pass : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._interview_pass}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort6 txt_blue">
                  <span className="m_th">최종 합격</span>
                  {support._final_pass === 0 ? support._final_pass : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._final_pass}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort7">
                  <span className="m_th">불합격</span>
                  {support._fail === 0 ? support._fail : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._fail}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort8">
                  <span className="m_th">입사</span>
                  {support._join === 0 ? support._join : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._join}
                    </Link>
                  )}
                </article>
                <article className="td row_sort sort9">
                  <span className="m_th">보증기간 내 퇴사</span>
                  {support._early_retirement === 0 ? support._early_retirement : (
                    <Link to={"/Company/comAnnouncement/SupportView"} state={{ em_idx: support.em_idx }}>
                      {support._early_retirement}
                    </Link>
                  )}
                </article>
              </li>
            ))}
        </ul>
        <div className="supportListC_guide txt_red">※ 지원자를 보시려면 공고명을 클릭 하세요.</div>
      </section>
      <div className="list_bottom" style={{ marginBottom: "20px" }}>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        <div className="right_button_wrap">
          <button type="button" className="btn_excel_download" onClick={(e) => onDownloadExcel(e)}>
            엑셀 다운로드 <img src={DownloadIcon} alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default SupportListC;
