import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import axios from 'axios';
import CONFIG from '../../../configs/configs';

function AdminAccount() {
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [info, setInfo] = useState({
        id: "",
        name: "",
        pwd: ""
    })

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        axios.post(`${CONFIG.SERVER_HOST}/admininfo`).then((res) => {
            setName(res.data[0].admin_name);
            setEmail(res.data[0].admin_id)
           // setPassword(res.data[0].password);
            setInfo({
                id: res.data[0].admin_id,
                name: res.data[0].admin_name,
                pwd: res.data[0].password
            })
            // console.log("res", res);
        });
    }, []);

    const onSubmitHandler = () => {
        if (Email !== info.id || Password !== info.pwd || Name !== info.name) {
            // if (Password !== "") {
                if(Password !== ""){
                    if (Password !== confirmPassword) {
                        alert("비밀번호를 다시 확인해주세요");
                        return false;
                    }
                }
                    const param = new URLSearchParams();
                    param.append("admin_id", Email);
                    param.append("password", Password);
                    param.append("admin_name", Name);
                    axios.post(`${CONFIG.SERVER_HOST}/changeAdminInfo`, param).then((res) => {
                        // console.log(res);
                        if(res.data.retvalue === 1) {
                            alert(res.data.message);
                        }else{
                            alert(res.data.message);
                        }
                    });

            // } else {
            //     //비밀번호 제외 변경
            //     const param = new URLSearchParams();
            //     param.append("admin_id", Email);
            //     param.append("admin_name", Name);
            //     axios.post(`${CONFIG.SERVER_HOST}/changeAdminacc`, param).then((res) => {
            //         console.log(res);
            //         if(res.data.retvalue === 1) {
            //             alert(res.data.message);
            //         }else{
            //             alert(res.data.message);
            //         }
            //     });
            // }
        } else {
            alert("변경된 사항이 없습니다");
        }
    }

    const onEmailHandler = (e) => {
        setEmail(e.currentTarget.value);
    };
    const onPasswordHanlder = (e) => {
        setPassword(e.currentTarget.value);
    };
    const onConfirmPasswordHanlder = (e) => {
        setConfirmPassword(e.currentTarget.value);
    };
    const onNameHanlder = (e) => {
        setName(e.currentTarget.value);
    };

    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="pref" />
            <Menu menuType="pref" menu="account" />
            <div id="contents" className="ad_contents">
                <div className="tit">관리자 계정 관리
                    <div className="path">
                        <img src={iconhome} alt=""/>
                        HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>운영자관리<img src={arrImage} alt=""/>관리자 계정 관리
                    </div>
                </div>
                <div className="content">
                    <div className="main-box">
                        <table className="write">
                            <tbody>
                                <tr>
                                    <th>아이디</th>
                                    <td><input type="text" name="mem_id" value={Email} onChange={onEmailHandler} maxLength="20" /></td>
                                </tr>
                                <tr>
                                    <th>이름</th>
                                    <td><input type="text" name="mem_name" value={Name} onChange={onNameHanlder} maxLength="20" /></td>
                                </tr>
                                <tr>
                                    <th>비밀번호</th>
                                    <td> <input type="password" name="mem_pwd" value={Password} onChange={onPasswordHanlder} maxLength="20" /></td>
                                </tr>
                                <tr>
                                    <th>비밀번호 재설정</th>
                                    <td><input type="password" name="changePwd" value={confirmPassword} onChange={onConfirmPasswordHanlder} maxLength="20" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="btn-center">
                        <button className="btn" onClick={onSubmitHandler}>수정</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AdminAccount;