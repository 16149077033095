/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CONFIG from "../../configs/configs";
import axios from "axios";
import GoogleLogin from "react-google-login";

import JoinMember from "./JoinMember";
import Term from "./Term";

import { isPassword, getIPData, AppLoginSuccess , googleSignOut } from "../Common/Common";

const JoinNormalC = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const location = useLocation();
  const [varSnsInfo, setSnsInfo] = useState({
    sns_id: '',
    sns_type: '',
    email: ''
  });

  //SNS 첫로그인의 경우
  useEffect(() => {
    if (location.state !== null) {
      axios.get(`${CONFIG.SERVER_HOST}/sns_id_check/${location.state.sns_id}`)
        .then((res) => {
          // if (Number(res.data?.mem_idx) > 0) {
          //   alert('탈퇴한 이력이 있는 회원 입니다 \n 관리자에게 문의해 주세요.');
          //   navigatorURL("/main");

          // } else {
            setSnsInfo({
              sns_id: location.state.sns_id,
              sns_type: location.state.sns_type,
              email: location.state.email
            });

            // if(location.state.email !== ''){
            //   const email_arr = location.state.email.split('@');
            //   setInputs({
            //     ...varInputs,
            //     email1: email_arr[0],
            //     email2: email_arr[1],
            //   })

            // }
         // }
        })
        .catch((e) => {
          console.debug(e);
        });
    } else {
      console.log('로케이션 null');
    }
  }, [location]);

  let memid = "";
  let memtype = 0;

  //검사실행유무---
  const [varConfirms, setConfirms] = useState({
    checkID: false,
    checkNickname: false,
    checkEmail: false
  });

  //변수저장객체
  const [varInputs, setInputs] = useState({
    mode: 0,
    hunter_type: 0,
    type: memtype,
    id: memid,
    pwd: "",
    pwdCheck: "",
    nickname: "",
    name: "",
    birthDay: "",
    gender: 0,
    mobile: "",
    certification: false,
    email1: "",
    email2: "",
    condition: 1,
    privacy: 1,
    selfagree: 1,
    expiration: 0,
    rdate: "",
    ip: "",
    pushagree: 1
  });
  // console.log('JoinNormalC.....');
  const navigatorURL = useNavigate();

  //최초 - IP주소 획득
  useEffect(() => {
    googleSignOut();
    getIPData(varInputs, setInputs, 'ip');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //애플로그인 메일정보 스테이트 저장 (ip스테이트가 업데이트 되면서 메일정보 스테이트의 초기화가 일어남, ip 스테이트의 이벤트 발생시 이메일 스테이트에 저장)
  useEffect(() => {
    if(location.state?.email && location.state?.email !== ''){
      const email_arr = location.state?.email.split('@');
      setInputs({
        ...varInputs,
        email1: email_arr[0],
        email2: email_arr[1],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varInputs.ip]);

  //'가입완료' 버튼 클릭(전송-저장)
  const onSubmitHandler = async () => {
    try {
      if (varSnsInfo.sns_id !== '' && varSnsInfo.sns_type !== '') {
        if (!varInputs.nickname) {
          alert("닉네임을 입력해주세요.");
        } else if (!varConfirms.checkNickname) {
          alert("닉네임 중복확인을 해주세요.");
        } else if (!varInputs.name) {
          alert("이름을 입력해주세요.");
        } else if (varInputs.name.length <= 1) {
          alert("이름을 2자리 이상 입력해주세요");
        } else if (!varInputs.email1 || !varInputs.email2) {
          alert("이메일을 입력해주세요.");
        } else if (!varConfirms.checkEmail) {
          alert("이메일 중복확인을 해주세요.");
        } else if (!varInputs.certification) {
          alert("휴대폰인증을 해주세요");
        } else if (varInputs.condition !== 1 || varInputs.privacy !== 1 || varInputs.selfagree !== 1) {
          alert("약관에 모두 동의해주세요.");
        } else {
          //SNS LOGIN --> JOIN MEMBER
          //서버 데이타베이스로 등록


          const params = new URLSearchParams();
          params.append("certification", varInputs.certification);
          params.append("condition", varInputs.condition);
          params.append("email1", varInputs.email1);
          params.append("email2", varInputs.email2);
          params.append("expiration", varInputs.expiration);
          params.append("id", varSnsInfo.sns_id);
          params.append("ip", varInputs.ip);
          params.append("mobile", varInputs.mobile);
          params.append("name", varInputs.name);
          params.append("nickname", varInputs.nickname);
          params.append("privacy", varInputs.privacy);
          params.append("pwd", varSnsInfo.sns_id);
          params.append("selfagree", varInputs.selfagree);
          params.append("type", varSnsInfo.sns_type);
          params.append("hunter_type", varInputs.hunter_type);
          params.append("mode", varInputs.mode);
          params.append("gender", varInputs.gender);
          params.append("birthDay", varInputs.birthDay);
          params.append("userAgent", userAgent.indexOf('heding') !== -1 ? 1 : 0);
          params.append("pushagree", varInputs.pushagree);


          console.log(varInputs.pushagree);

          await axios.post(`${CONFIG.SERVER_HOST}/join_member`, params).then(async (res) => {

            if (res.data.retvalue === 1) {
              const datas = res.data;
              AppLoginSuccess(datas.mem_idx);
              localStorage.setItem("memlogin", true);
              localStorage.setItem("mem_id", varSnsInfo.sns_id);
              localStorage.setItem("mem_idx", datas.mem_idx); 
              localStorage.setItem("mem_nickname", varInputs.nickname);
              localStorage.setItem("mem_name", datas.mem_name); 
              localStorage.setItem("mem_mode", 0); //0:일반/1:기업/2:헤드헌터
              localStorage.setItem("mem_type", varSnsInfo.sns_type); //0:일반/1:Naver/2:Kakao/3:Google
              localStorage.setItem("mem_headhunter", 0);
              localStorage.setItem("mcm_idx", 0);
              localStorage.setItem("mem_sub", datas.mem_idx);
              localStorage.setItem("com_idx", 0);
              localStorage.setItem("mem_approval", 1);
              navigatorURL("/Member/JoinNormalOK");
            } else {
              alert(res.data.message);
            }
          });
        }
        //일반 로그인
      } else {
        if (!varInputs.id) {
          alert("아이디를 입력해주세요.");
        } else if (!varConfirms.checkID) {
          alert("아이디 중복확인을 해주세요.");
        } else if (!varInputs.pwd) {
          alert("비밀번호를 입력해주세요.");
        } else if (!isPassword(varInputs.pwd)) {
          alert("비밀번호 형식이 올바르지 않습니다. \n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.");
        } else if (!varInputs.pwdCheck) {
          alert("비밀번호 확인을 위해 한 번 더 입력해주세요.");
        } else if (varInputs.pwd !== varInputs.pwdCheck) {
          alert(" 비밀번호가 일치하지 않습니다.");
        } else if (!varInputs.nickname) {
          alert("닉네임을 입력해주세요.");
        } else if (!varConfirms.checkNickname) {
          alert("닉네임 중복확인을 해주세요.");
        } else if (!varInputs.name) {
          alert("이름을 입력해주세요.");
        } else if (varInputs.name.length <= 1) {
          alert("이름을 2자리 이상 입력해주세요");
        } else if (!varInputs.email1 || !varInputs.email2) {
          alert("이메일을 입력해주세요.");
        } else if (!varConfirms.checkEmail) {
          alert("이메일 중복확인을 해주세요.");
        } else if (!varInputs.certification) {
          alert("휴대폰인증을 해주세요");
        } else if (varInputs.condition !== 1 || varInputs.privacy !== 1 || varInputs.selfagree !== 1) {
          alert("약관에 모두 동의해주세요.");
          //일반회원 가입
        } else {
          //서버 데이타베이스로 등록
          const params3 = new URLSearchParams();
          // params3.append("options", JSON.stringify(varInputs));
          params3.append("certification", varInputs.certification);
          params3.append("condition", varInputs.condition);
          params3.append("email1", varInputs.email1);
          params3.append("email2", varInputs.email2);
          params3.append("expiration", varInputs.expiration);
          params3.append("id", varInputs.id);
          params3.append("ip", varInputs.ip);
          params3.append("mobile", varInputs.mobile);
          params3.append("name", varInputs.name);
          params3.append("nickname", varInputs.nickname);
          params3.append("privacy", varInputs.privacy);
          params3.append("pwd", varInputs.pwd);
          params3.append("selfagree", varInputs.selfagree);
          params3.append("type", varInputs.type);
          params3.append("hunter_type", varInputs.hunter_type);
          params3.append("mode", varInputs.mode);
          params3.append("gender", varInputs.gender);
          params3.append("birthDay", varInputs.birthDay);
          params3.append("userAgent", userAgent.indexOf('heding') !== -1 ? 1 : 0);
          params3.append("pushagree", varInputs.pushagree);


          console.log(varInputs.pushagree);

          await axios.post(`${CONFIG.SERVER_HOST}/join_member`, params3).then((res) => {
            if (res.data.retvalue === 1) {
              const datas = res.data;
              AppLoginSuccess(datas.mem_idx);
              localStorage.setItem("memlogin", true);
              localStorage.setItem("mem_id", varInputs.id);
              localStorage.setItem("mem_idx", datas.mem_idx);
              localStorage.setItem("mem_nickname", varInputs.nickname);
              localStorage.setItem("mem_name", varInputs.name);
              localStorage.setItem("mem_mode", 0); //0:일반/1:기업/2:헤드헌터
              localStorage.setItem("mem_hunter_type", 0); //0:프리랜서/1:서치폼 (헤드헌터)
              localStorage.setItem("mem_type", 0); //0:일반/1:Naver/2:Kakao/3:Google
              localStorage.setItem("mcm_idx", 0);
              localStorage.setItem("mem_sub", datas.mem_idx);
              localStorage.setItem("com_idx", 0);
              localStorage.setItem("mem_approval", 1);
              navigatorURL("/Member/JoinNormalOK");
            } else {
              alert(res.data.message);
            }
          });
        }
      }
    } catch (error) {
      alert("회원 등록에 실패하였습니다.");
      console.log(error);
    }
  };

  // //구글 스토리지 삭제 함수
  // const GoogleStorage = () => {
  //   console.log('동작!!!!!');
  //   if (window.gapi.auth2?.getAuthInstance()) {
  //     const auth2 = window.gapi.auth2?.getAuthInstance();
  //     if (auth2 !== null) {
  //       auth2
  //         .signOut()
  //         .then(auth2.disconnect().then((res) => console.log(res)))
  //         .catch(e => console.log(e));
  //     }
  //   }
  // };

  return (
    <div className="tab01_contents">
      {/*  {varInputs.certification} */}
      <JoinMember varInputs={varInputs} setInputs={setInputs} varConfirms={varConfirms} setConfirms={setConfirms} varSnsInfo={varSnsInfo} />
      <Term varInputs={varInputs} setInputs={setInputs} onSubmitHandler={onSubmitHandler} />
    </div>
  );
};
export default JoinNormalC;
