/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import stopImg from 'img/main/btn_stop.png';
import btn_arrow_off from 'img/main/btn_arrow_off.png';
import { Link } from "react-router-dom";
import CONFIG from "../../configs/configs";
import axios from "axios";

//css
import "../../css/Common.css";
import "../../css/Board.css";
import "../../css/MyTalk.css";

//image
import newImage from "../../img/sub/new.png";

//components
import TalkSearch from "../../components/Talk/TalkSearch";
import Paging from "../../components/Layout/Paging";
import { exceptTimeFromDate } from "../../components/Common/Common";
import moment from "moment";
import { Swiper, SwiperSlide } from 'swiper/react';

/*이직Talk 게시판 목록*/
function TalkList() {
  const [varPageList, setPageList] = useState([]);
  const [varSearchOption, setSearchOption] = useState("all"); //검색조건
  const [varSearchString, setSearchString] = useState(""); //검색어
// 추천 수 상위 4개
  const [varHighRecommendList, setHighRecommendList] = useState([])

  //페이지.
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [mustread_limit] = useState(4);
  const [MustreadOffset] = useState(0);
  
  const PreviosNum = usePrevios(varPageNum);

  function usePrevios(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const highRecommendList = async () => {
    await axios.get(`${CONFIG.SERVER_HOST}/_high_recommend_list`)
    .then((res) => {
      setHighRecommendList(res.data.result);
    })
  }
  const BoardList = async () => {
    try {
      const params = new URLSearchParams();
      params.append("PageSize", varPageSize);
      params.append("PageNum", varPageNum);
      params.append("SearchOption", varSearchOption);
      params.append("SearchString", varSearchString);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_move_working_talk_lists`, params)
        .then((res) => {
          const datas = res.data.results.map((data, i) => ({
            order: i + 1,
            mwt_idx: data.mwt_idx,
            mem_idx: data.mwt_mem_idx,
            mem_id: data.mem_id,
            mem_nickname: data.mem_nickname,
            mem_type: data.mem_type,
            mwt_title: data.mwt_title,
            mwt_rdate: data.mwt_rdate,
            mwt_read: data.mwt_read,
            mwt_hits: data.mwt_hits,
            replys: data.replys,
            recommend: data.recommend,
            tomorrow: getTomorow(new Date(data.mwt_rdate))
          }));
          setTotalCount(res.data.totalCount);
          setPageList(datas);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //표시할 페이지가 바뀌면
  useEffect(() => {
    //이전 varPageNum 값과 비교 값이 다를 경우 실행
    if (varPageNum !== PreviosNum) {
      BoardList();
      highRecommendList();
    }
  }, [varPageNum]);

  const getTomorow = (toD) => {

    var today = new Date();
    var today_ts = Date.parse(today);


    var tomorrow_ts = today_ts + (60 * 60 * 24 * 1000);
    var tomorrow = new Date(tomorrow_ts);
    return moment(tomorrow).format(CONFIG.DBFormatStr);

  }

  //검색 클릭
  const onFilteringHandler = (e) => {
    e.preventDefault();
    BoardList();
  };

  //검색항목 선택
  const onSelectSearchOptionHandler = (e) => {
    e.preventDefault();
    setSearchOption(e.currentTarget.value);
  };

  //검색문자 변경시
  const onSearchStringHandler = (e) => {
    e.preventDefault();
    setSearchString(e.currentTarget.value);
  };

  const makeOffset = (offset) => {
    if (offset === 0 || offset === 1) {
      return 0;
    } else {
      const nowOffset = varPageList.length;
      return nowOffset;
    }
  };

  const swiperRef = useRef(null);
  const [BtnState, setBtnState] = useState(false);
  const [varPage, setPage] = useState(0);

  const swiper_start= () => {
      swiperRef.current.swiper.autoplay.start();
      setBtnState(false);
  };

  const swiper_stop= () => {
      swiperRef.current.swiper.autoplay.stop();
      setBtnState(true);
  };

  //현재 슬라이드 페이지 표시
  const onChangeSlidePage = () => {
    if(varPage === 4) setPage(1);
    else setPage(varPage+1);
  }
  return (
    <div className="center_wrap page_mytalk_list">
      <h2 className="sub_content_title">TOP 추천 글</h2>
     
      <section className="top_recommend_area view_pc">
      {varHighRecommendList && varHighRecommendList.map((e, i) => (
          <Link to={"/MyTalk/TalkView"} state={{mwt_idx: e.mwt_idx}} className="top_recommend" key={i}>
            <h1 className="ellipsis line2">{e.mwt_title}</h1>
            <h2 className="writer">{e.mem_type === 0 ? e.mem_id : e.mem_nickname}</h2>
            <div className="date_count_wrap">
              <p className="date">{e.mem_rdate}</p>
              <p className="count">조회수 : {e.mwt_hits}<span>|</span>추천수 : {e.recommend}</p>
            </div>
          </Link>
        ))} 
      </section>
      
      <section className="top_recommend_area center_wrap view_m">
        <div className="top_recommend_slider">
          <ul className="swiper-wrapper">
            <Swiper
              ref={swiperRef} 
              loop={true}
              autoplay={{
                  delay: 3000,
                  disableOnInteraction: false
              }}
              pagination={{el: '.swiper-pagination', clickable: true, renderBullet: function (index, className) {
                  return '<a className="' + className + '">' + (index) + '</a>';}}}
              className="mySwiper2"
          >
              {varHighRecommendList && varHighRecommendList.map((e, i) => (
              <SwiperSlide key={i}>
                <Link to={"/MyTalk/TalkView"} state={{mwt_idx: e.mwt_idx}} className="top_recommend" >
                  <h1 className="ellipsis line2">{e.mwt_title}</h1>
                  <h2 className="writer">{e.mem_type === 0 ? e.mem_id : e.mem_nickname}</h2>
                  <div className="date_count_wrap">
                    <p className="date">{e.mem_rdate}</p>
                    <p className="count">조회수 : {e.mwt_hits}<span>|</span>추천수 : {e.recommend}</p>
                  </div>
                </Link>
              </SwiperSlide>
              ))}
            </Swiper>
          </ul>
        </div>
        <div className="btn_area">
            <div className="pagination swiper-pagination" style={{fontSize:"14px"}}>
              <span className="paging_on"></span>
            </div>&nbsp;&nbsp;
            {BtnState === false ?
              <a href={() => false} className="btn_stop"><img src={stopImg} onClick={()=> swiper_stop()} alt="" /></a>
            :
              <img src={btn_arrow_off} onClick={()=> swiper_start()} alt="" />
            }
        
        </div>
      </section>
      <TalkSearch
        totalCount={varTotalCount}
        varSearchString={varSearchString}
        varSearchOption={varSearchOption}
        onSelectSearchOptionHandler={onSelectSearchOptionHandler}
        onSearchStringHandler={onSearchStringHandler}
        onFilteringHandler={onFilteringHandler}
      />
      <table className="table_list01">
        <thead>
          <tr>
            <th className="th_num">번호</th>
            <th className="th_title">제목</th>
            <th className="th_writer">작성자</th>
            <th className="th_date">작성일</th>
            <th className="th_hit">조회수</th>
            <th className="th_hit">추천수</th>
          </tr>
        </thead>
        <tbody>
          {varPageList.map((data, i) => (
            <tr key={"isikt_" + i}>
              <td className="td_num">
                {data.mwt_read === 1 ? (
                  <span className="table_notice_badge">필독</span>
                ) : (
                  varTotalCount - (varPageNum - 1) * varPageSize - i
                )}
              </td>
              <td className="td_title">
                {data.mwt_read === 0 ? (
                  <div className="wrap">
                    <Link
                      to={"/MyTalk/TalkView"}
                      state={{ mwt_idx: data.mwt_idx }}
                    >
                      {data.mwt_title}
                    </Link>
                    <span className="re">[{data.replys}]</span>
                    {data.tomorrow < moment(new Date()).format(CONFIG.DBFormatStr) ?
                      <img src={newImage} className="icon_new" alt="" />
                      :
                      ''
                    }

                  </div>
                ) : (
                  <>
                    <span className="table_notice_badge">필독</span>
                    <div className="wrap">
                      <Link
                        to={"/MyTalk/TalkView"}
                        state={{ mwt_idx: data.mwt_idx }}
                      >
                        {data.mwt_title}
                      </Link>
                      <span className="re">[{data.replys}]</span>
                      {data.tomorrow < moment(new Date()).format(CONFIG.DBFormatStr) ?
                        <img src={newImage} className="icon_new" alt="" />
                        :
                        ''
                      }
                    </div>
                  </>
                )}
              </td>
              <td className="td_info">{data.mem_type === 0 ? data.mem_id : data.mem_nickname}</td>
              <td className="td_info">{exceptTimeFromDate(data.mwt_rdate)}</td>
              <td className="td_info">{data.mwt_hits}</td>
              <td className="td_info last">{data.recommend}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="paging">
        <Paging
          totalCount={varTotalCount}
          pageSize={varPageSize}
          curPage={varPageNum}
          setPage={setPageNum}
        />
      </div>
    </div>
  );
}

export default TalkList;
