/*import React, { useEffect, useState } from "react";*/
import { Routes, Route, /*Link, useLocation*/ } from "react-router-dom";
//import JoinHeadHunterC1 from "../../components/Member/JoinHeadHunterC1";
import JoinHeadHunterC2 from "../../components/Member/JoinHeadHunterC2";

const JoinHeadHunter = () => {

  return (
    <>
      <div className="tab01_contents">
        <div>
          <Routes>
            <Route path="/" element={<JoinHeadHunterC2 />} />
            <Route path="/SearchForm" element={<JoinHeadHunterC2 />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default JoinHeadHunter;
