/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from 'axios'
import CONFIG from '../../configs/configs'

//css
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/Company.css';
import '../../css/MyCareer.css';
import '../../css/TalentDB.css';

import { resetNavMenu } from "../../components/Common/Common";
import HeaderCompany from '../../components/Layout/HeaderCompany'

/* import BannerBottom from '../../components/Banner/BannerBottom' */
import Footer from '../../components/Layout/Footer'
import SearchMain from '../../components/Resources/SearchMain2'

import PopupSuggest from '../../components/Resources/PopupSuggest'
import PopupCandidate from '../../components/Resources/PopupCandidate'

import SearchViewC from '../../components/Resources/SearchViewC'
import CandidateC from '../../components/Resources/CandidateC'
import InterestC from '../../components/Resources/InterestC'
import LatestC from '../../components/Resources/LatestC'
import BannerBottom from "components/Banner/BannerBottom";

/* 인재검색 */
const ComResource = () => {
  const mcm_idx = Number(localStorage.getItem('mcm_idx'));
  let navigate = useNavigate();

  //이력서 번호 저장
  const [varResumeIndex, setResumeIndex] = useState(0);
  //제안 시 채용 idx
  const [varSugEmIndex, setSugEmIndex] = useState(0);//후보자관리 화면에서의 경우는 정해져 있어서...

  //표시할 페이지번호
  const [varTabPage, setTabPage] = useState(0);

  //시작모드/검색모드
  const [varPageMode, setPageMode] = useState(0);  //SearchC

  //사용중인 이용권
  const [varUsingPass, setUsingPass] = useState({ mcc_sending: 0, mcc_edate: 0 });

  //팝업------------------
  const [varShowPopupSuggest, setShowPopupSuggest] = useState('popup');
  const [varShowPopupCandidate, setShowPopupCandidate] = useState('popup');
  const [varPopup, setPopup] = useState(false);  //제안보내기 팝업창 여부 조건 추가 (기존의 경우 최초 실행시 api 동작 이후 동작이 안되는 이휴 발생)


  useEffect(()=>{
    if(varShowPopupSuggest.includes('active')){
      setPopup(true);
    }
  }, [varShowPopupSuggest]);
  //상부 탭 부분 조정
  const setTabHandler = (ipage) => {
    const tabOptionsBlock = document.querySelectorAll('.TabOptions');
    for (var i = 0; i < tabOptionsBlock.length; i++) {
      if (tabOptionsBlock[i].classList.contains('active')) {
        tabOptionsBlock[i].classList.remove('active');
      }
    }
    if (!tabOptionsBlock[ipage].classList.contains('active')) {
      tabOptionsBlock[ipage].classList.add('active');
    }
  }

  //2022-10-30 제안 유무.
  const [varSuggestionAllowed, setSuggestionAllowed] = useState(-1);

  useEffect(() => {
    setTabHandler(varTabPage);
    resetNavMenu(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabPage]);

  //1:1 채팅
  const onCallChating = async (mem_idx, re_idx) => {
    try {
      const params = new URLSearchParams();

      // if (info.com_name !== "" || info.com_name !== null || info.com_name !== undefined) {
      params.append('mem_idx', mem_idx); //메세지를 받을 상대방의 idx
      params.append('corp_mem_idx', localStorage.getItem('mem_idx')); //보내는 회원의 idx
      params.append('send_idx', localStorage.getItem('mem_idx')); //메시지 보내는 회원의 idx
      params.append('re_idx', re_idx);
      params.append('com_idx', localStorage.getItem('com_idx')); //기업회원의 회사명

      //채팅방 개설 api
      await axios.post(`${CONFIG.SERVER_HOST}/set_chatroom`, params)
        .then((res) => {
          // console.log(res);
          if (res.data.retvalue === 1) {
            navigate(`/Company/ComMessage/${res.data.room_no}`, {
              state: { roomIdx: res.data.room_no, proposeIdx: res.data.propose_idx, chat_idx: 0 }
            });
            //alert(res.data.message);
          } else {
            alert(res.data.message);
          }
        });
      // }



    } catch (error) {
      console.log(error);
    }
  };
  
  //이력서 보기
  const onViewResumePage = (e, re_idx, num) => {
    e.preventDefault();
    setResumeIndex(re_idx);
    navigate(`/Company/ComResource/SearchView/${re_idx}`, {state: num});
  }
  //목록으로
  const gotoLastListPage = (state) => {
    if (state === 1) navigate('/Company/ComResource/Candidate');
    else if (state === 2) navigate('/Company/ComResource/Interest');
    else if (state === 3) navigate('/Company/ComResource/Latest');
    else navigate('/Company/ComResource/SearchMain');
  }

  //사용권 이용정보 가져오기
  const ResetUsingPass = useCallback(() => {
    try {
      //setPageMode(0);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      //2022-10-30 API 변경 - KSR
      //axios.post(`${CONFIG.SERVER_HOST}/_get_company_voucher`, params).then((res) => {
      axios.post(`${CONFIG.SERVER_HOST}/voucherRemainList2`, params).then((res) => {
        if (res.data.retvalue === 1) {
          const datas = res.data.results;
          setUsingPass(datas);
        } else {
          setUsingPass({ mcc_sending: 0, mcc_edate: 0 });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [mcm_idx])

  useEffect(() => {
    ResetUsingPass();
  }, [ResetUsingPass]);
  return (
    <>
      <HeaderCompany />
      <div className="page_talent_search" id="sub_wrap">
        <div className="center_wrap">
          <h2 className="sub_content_title indent">인재검색</h2>
          <ul className="tab01 blue col4">
            <li className="TabOptions active"><Link to={'/Company/ComResource/SearchMain'}>인재 검색하기</Link></li>
            <li className="TabOptions"><Link to={'/Company/ComResource/Candidate'}>후보자 관리</Link></li>
            <li className="TabOptions"><Link to={'/Company/ComResource/Interest'}>관심 인재</Link></li>
            <li className="TabOptions"><Link to={'/Company/ComResource/Latest'}>최근조회 인재</Link></li>
          </ul>
          <Routes>
            <Route path="/SearchMain" element={<SearchMain setTabPage={setTabPage} varUsingPass={varUsingPass} setUsingPass={setUsingPass}
              varPageMode={varPageMode} setPageMode={setPageMode} setResumeIndex={setResumeIndex}
              setShowPopupCandidate={setShowPopupCandidate} onViewResumePage={onViewResumePage} ResetUsingPass={ResetUsingPass} />}
            />
            <Route path="/SearchView/:re_idx" element={
              <SearchViewC 
                varUsingPass={varUsingPass}
                varResumeIndex={varResumeIndex}
                setResumeIndex={setResumeIndex}
                ResetUsingPass={ResetUsingPass}
                varSuggestionAllowed={varSuggestionAllowed}
                setSuggestionAllowed={setSuggestionAllowed} 
                setShowPopupSuggest={setShowPopupSuggest}
                setShowPopupCandidate={setShowPopupCandidate}
                onCallChating={onCallChating}
                gotoLastListPage={gotoLastListPage}
                varTabPage={varTabPage}
                setSugEmIndex={setSugEmIndex}
              />}
            />
            <Route path="/Candidate" element={<CandidateC setTabPage={setTabPage} varUsingPass={varUsingPass} ResetUsingPass={ResetUsingPass}
              setShowPopupSuggest={setShowPopupSuggest} onViewResumePage={onViewResumePage} onCallChating={onCallChating} setResumeIndex={setResumeIndex} setSugEmIndex={setSugEmIndex} />}
            />
            <Route path="/Interest" element={<InterestC setTabPage={setTabPage} setResumeIndex={setResumeIndex} ResetUsingPass={ResetUsingPass}
              onViewResumePage={onViewResumePage} onCallChating={onCallChating} />}
            />
            <Route path="/Latest" element={<LatestC setTabPage={setTabPage} onViewResumePage={onViewResumePage} onCallChating={onCallChating} />} />
          </Routes>
          {/* 팝업 - 제안하기 */}
          
          {varPopup ?
            <PopupSuggest 
            varUsingPass={varUsingPass} 
            setUsingPass={setUsingPass} 
            varResumeIndex={varResumeIndex} 
            setSuggestionAllowed={setSuggestionAllowed}
            varShowPopupSuggest={varShowPopupSuggest} 
            setShowPopupSuggest={setShowPopupSuggest} 
            varSugEmIndex={varSugEmIndex}
            setPopup={setPopup}
          />
          :
          ``

          }
          
          {/* 팝업 - 후보자추가하기 */}
          <PopupCandidate 
            varResumeIndex={varResumeIndex}
            varShowPopupCandidate={varShowPopupCandidate} 
            setShowPopupCandidate={setShowPopupCandidate}
          />
        </div>
      </div>
      <BannerBottom />
      <Footer />
    </>
  )
}

export default ComResource
