/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import Paging from '../../../components/Layout/Paging'

function UserList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜

    const [varSearch, setSearch] = useState({
        resume:0,
        exposure:0,
        option:'all'
    });
    const [varSearchInput, setSearchInput] = useState("");

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize, setPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    const [startPageNum,setStartPageNum] = useState(1);
    const [endPageNum,setEndPageNum] = useState(10);

    const [varPagelist, setPagelist] = useState([]);
    const [selectChange,setSelectChange] = useState(false);
    const [varReset, setReset] = useState(false);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
      getUserList();
    },[]);

    useEffect(() => {
      getUserList();
    }, [varPageSize]);

    const getUserList = async() =>{
      const sendData = {
        'startDate':startDate,
        'endDate':endDate,
        'pageSize':varPageSize ,
        'startPageNum':startPageNum === 1 ? 0 : startPageNum,
        'endPageNum':endPageNum
      }

      //         axios.post(`${CONFIG.SERVER_HOST}/getPushList`).then((res) => {
//           console.log(res.data);
//           setPush(res.data)
//         });
      await axios.post(`${CONFIG.SERVER_HOST}/getPushList`, sendData, CONFIG.header).then((res) => {
        //console.log(res);
        setTotalCount(res.data.totalcount);
        const datas = res.data.results.map((data,i)=>(
            {  
                ROWNUM:data.ROWNUM,
                push_idx:data.push_idx,
                push_comment:data.push_comment,
                push_title:data.push_title,
                push_rdate:data.push_rdate
            }
        ))
        setPagelist(datas);
        setReset(false);
        console.log(selectChange === true);
        if(selectChange === true){
          setSelectChange(false);
        }
    });
    }

    const handleType = (e) =>{
      const {value, name} = e.currentTarget;
        setSearch({...varSearch,
          [name] : value

        });
    };
    const handleInput = (e) =>{
        setSearchInput(e.currentTarget.value);
    };

    //검색-초기화
    const handleSearchClear =()=>{
        setStartDate("");
        setEndDate("");
        setSearch({
            resume:0,
            exposure:0,
            option:0
        });
        setSearchInput("");
        setPageNum(1);
        setReset(true);
    }

    useEffect(()=>{
      if(varReset){
        getUserList();
      }
    }, [varReset]);

    //검색
    const handleSearchExecute = () => {
      
      setSelectChange(true);
      
    }

    useEffect(() => {
      getUserList();
    }, []);

    useEffect(() => {
      if(selectChange){
        getUserList();
        setPageNum(1);
      }
    }, [selectChange]);

    useEffect(() => {
      getUserList();
    }, [startPageNum]);

    useEffect(() => {
      console.log(selectChange);
      if(selectChange === false){
        setStartPageNum((varPageNum-1)*varPageSize === 1 ? 10 : varPageSize* (varPageNum-1));
        setEndPageNum((varPageNum-1)*varPageSize === 1 ? varPageSize : varPageSize*varPageNum);
      }
    },[varPageNum]);
    
    const handlestartdate =(e)=>{
        setStartDate(e.currentTarget.value);
    }
    const handleenddate =(e)=>{
        setEndDate(e.currentTarget.value);
    }

    const settingPageSize = (e) =>{
      const {value} = e.target;
      setPageSize(Number(value));
      setSelectChange(true);
    }


    console.log(startPageNum, endPageNum);
    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="pref"/>
        <Menu menuType="pref" menu="pushlist"/>
        <div id="contents" className="ad_contents">
        <div className="tit">PUSH 발송내역
            <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>PUSH 관리<img src={arrImage} alt=""/>PUSH 발송내역
            </div>
        </div>
        <div className="content">
            <div className="main-box">      
                <div className="search-box">
                    {/* <p>검색건수 : {varTotalCount}
                    <select style={{width:"120px", marginLeft: '15px'}} name="option" value={varPageSize} onChange={settingPageSize}> 
                            <option value="10">10개</option>
                            <option value="30">30개</option>
                            <option value="50">50개</option>
                            <option value="100">100개</option>
                        </select>
                    </p> */}
                    
                    <div className="f-right">   
                        <div className="day-box">
                            <p>기간검색</p>
                            <input type="date"  name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                            ~ 
                            <input type="date"  name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
                            <button onClick={handleSearchExecute} className="btn">조회</button>
                        </div>
                        {/* <div>
                            <p className="ml20">이력서 작성여부</p>
                            <select style={{width:"120px"}} name="resume" value={varSearch.resume} onChange={handleType}> 
                                <option value={0}>전체</option>
                                <option value={1}>X</option>
                                <option value={2}>O</option>
                            </select>
                        </div>
                        <div>
                            <p className="ml20">인재 검색 노출 여부</p>
                            <select style={{width:"120px"}} name="exposure" value={varSearch.exposure} onChange={handleType}> 
                                <option value={0}>전체</option>
                                <option value={1}>Y</option>
                                <option value={2}>N</option>
                            </select>
                        </div>    */}
                    </div>
                    {/* <div className="wd100">
                        <select style={{width:"120px"}} name="option" value={varSearch.option} onChange={handleType}> 
                            <option value="all">전체</option>
                            <option value="mem_id">아이디</option>
                            <option value="mem_name">이름</option>
                            <option value="mem_email">이메일</option>
                        </select>
                        <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e)}}/>
                        <button onClick={handleSearchExecute} className="btn">검색</button>
                        <button onClick={handleSearchClear} className="btn black">초기화</button>            
                    </div> */}
                    {/* <button  onClick={get_tilko}>건강보험</button> */}
                </div>
            </div>
            <div className="list_top">
              <p>검색건수 : {varTotalCount}
                <select style={{width:"120px", marginLeft: '15px'}} name="option" value={varPageSize} onChange={settingPageSize}> 
                      <option value="10">10개</option>
                      <option value="30">30개</option>
                      <option value="50">50개</option>
                      <option value="100">100개</option>
                  </select>
              </p>
            </div>
            <div className="main-box ">
                <table className="list">
                    <colgroup>
                     <col width="8%"></col>
                     <col width="72%"></col>
                     <col width="10%"></col>
                     <col width="10%"></col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>내용</th>
                        <th>발송방법</th>
                        <th>발송일자</th>
                      </tr>
                    </thead>
                    <tbody>
                    {varPagelist.map((data,i) => (
                      <tr key={data.push_idx}>
                        <td>{data.ROWNUM}</td>
                        <td className="ellipsis">{data.push_comment}</td>
                        <td>{data.push_title}</td>
                        <td>{data.push_rdate}</td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </div>
    </div>
    </React.Fragment>

    )

}

export default UserList;