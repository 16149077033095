/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import iconhome from "../../img/icon-home.png";
import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import arr from "../../img/arr.png";
import axios from "axios";
import CONFIG from "../../../configs/configs";
import Clock from "./Colck";
import moment from "moment";

function MemberStatisticsList() {
  const [DateData, setDateData] = useState({
    startDate: "",
    endDate: "",
  });
  const [JoinCountList, setJoinCountList] = useState([]);
  const [GeneralTot, setGeneralTot] = useState(0);
  const [CorporateTot, setCorporateTot] = useState(0);
  const [HeadhunterTot, setHeadhunterTot] = useState(0);
  const [Counts, setCounts] = useState({
    today_join_count: 0,
    week_count: 0,
    month_count: 0,
  });

  const timeout = () => {
    //현재 시간부터 자정까지 남은 시간 계산
    console.log(new Date());
    var a = moment().add(1, "days").hours("00").minutes("00").seconds("00");
    var b = moment();
    const time = a.diff(b); // 86400000

    console.log(a.diff(b));

    setTimeout(() => {
      reset();
    }, time);
  };

  useEffect(() => {
    timeout();
    return () => {
      // clear 해줌
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    axios
      .post(`${CONFIG.SERVER_HOST}/getDashboardCount`, CONFIG.header)
      .then((res) => {
        console.log(res);
        const { data } = res;
        setCounts({
          ...Counts,
          today_join_count: data.today_join_count,
          week_count: data.week_count,
          month_count: data.month_count,
        });
      });
  }, []);

  useEffect(() => {
    getJoinMemberDate('', '');
  }, []);

  const getJoinMemberDate = (startDate, endDate) => {
    const sendData = {
      startDate: startDate,
      endDate: endDate,
    };
    axios
      .post(`${CONFIG.SERVER_HOST}/getJoinMemberCount`, sendData, CONFIG.header)
      .then((res) => {
        console.log(res);
        const { data } = res;
        setJoinCountList(data.joinmemberlist);

        let general_list = [];
        let corporate_list = [];
        let headhunter_list = [];

        for (var i = 0; i < data.joinmemberlist.length; i++) {
          // 배열 arr의 모든 요소의 인덱스(index)를 출력함.
          if (data.joinmemberlist[i]) {
            general_list.push(data.joinmemberlist[i].general);
            corporate_list.push(data.joinmemberlist[i].corporate);
            headhunter_list.push(data.joinmemberlist[i].headhunter);
          }
        }

        setGeneralTot(
          general_list.reduce(function add(sum, currValue) {
            return sum + currValue;
          }, 0)
        );
        setCorporateTot(
          corporate_list.reduce(function add(sum, currValue) {
            return sum + currValue;
          }, 0)
        );
        setHeadhunterTot(
          headhunter_list.reduce(function add(sum, currValue) {
            return sum + currValue;
          }, 0)
        );
      });
  };

  const onChangeHandleler = (e) => {
    const { name, value } = e.target;
    setDateData({ ...DateData, [name]: value });
  };
  const getSerch = () => {
    if (!DateData.startDate) {
      alert("시작 날짜를 선택해 주세요");
      return false;
    } else if (!DateData.endDate) {
      alert("종료 날짜를 선택해 주세요");
      return false;
    }

    getJoinMemberDate(DateData.startDate, DateData.endDate);
  };
  const reset = () => {
    setDateData({
      startDate: "",
      endDate: "",
    });
    getJoinMemberDate("", "");
  };

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="dashboard" />
      <Menu menuType="dashboard" menu="member_statistics" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          가입회원 통계
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arr} alt="" />
            Dash board
            <img src={arr} alt="" />
            가입회원 통계
          </div>
        </div>
        <div className="content">
          <div className="date_sec">
            <span>Today :</span>
            <span className="date">
              <Clock />
            </span>
          </div>
          <div className="main-box">
            <table className="list">
              <colgroup>
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>오늘 가입한 회원</th>
                  <th>이번주 가입한 회원</th>
                  <th>이번달 가입한 회원</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{Counts.today_join_count}</td>
                  <td>{Counts.week_count}</td>
                  <td>{Counts.month_count}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box">
            <div className="search-box">
              <div className="day-box">
                <p>검색기간</p>
                <input
                  type="date"
                  name="startDate"
                  id="datePicker"
                  value={DateData.startDate}
                  onChange={onChangeHandleler}
                />
                ~
                <input
                  type="date"
                  name="endDate"
                  id="datePicker2"
                  value={DateData.endDate}
                  onChange={onChangeHandleler}
                />
              </div>
              <div className="f-right">
                <button className="btn" onClick={() => getSerch()}>
                  검색
                </button>
                <button className="btn black" onClick={() => reset()}>
                  초기화
                </button>
              </div>
            </div>
          </div>
          <div className="main-box">
            <table className="list">
              <colgroup>
                <col width="15%" />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>일자</th>
                  <th>일반회원</th>
                  <th>기업회원</th>
                  <th>헤드헌터</th>
                </tr>
              </thead>
            </table>
            <div className="scrollbox">
              <table className="list">
                <colgroup>
                  <col width="15%" />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  {JoinCountList &&
                    JoinCountList.map((el) => (
                      <tr>
                        <td>{el.date}</td>
                        <td>{el.general}</td>
                        <td>{el.corporate}</td>
                        <td>{el.headhunter}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <table className="list">
              <colgroup>
                <col width="15%" />
                <col />
                <col />
                <col />
              </colgroup>
              <tfoot className="total">
                <tr>
                  <td>합계</td>
                  <td>{GeneralTot}</td>
                  <td>{CorporateTot}</td>
                  <td>{HeadhunterTot}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MemberStatisticsList;
