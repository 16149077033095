/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { ChangeDateStringOnlyDate, ChangeMoneyUnit, em_titleLength, ChangeSearchingDateFormat } from "../Common/Common";
import Paging from "../Layout/Paging";

import WonLogoIcon_pc from "img/main/won.png";
import WonLogoIcon_m from "img/main/won_m.png";
const defaultCompanyLogo = `${CONFIG.SERVER_HOST}/mcm_image/default_company.png`;

const AnnouncementListC = () => {
  const mcm_idx = localStorage.getItem("mcm_idx");
  const mem_idx = localStorage.getItem("mem_idx");
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const mem_hunter_type = Number(localStorage.getItem("mem_hunter_type"));
  const today = ChangeSearchingDateFormat(new Date()); //eslint-disable-line no-unused-vars

  //서버의 저장폴더
  const imageFolder = `${CONFIG.SERVER_HOST}/mcm_image/`;

  //헤드헌터 - 써치폼 확인
  let SearchForm = 0;
  if (mem_mode === 2 && mem_hunter_type >= 1) {
    SearchForm = 1;
  }
  const location = useLocation();

  //표시할 배열
  const [varRecruitment, setRecruitment] = useState([]);
  const [varFilterRecruitment, setFilterRecruitment] = useState([]);
  const [varPageRecruitment, setPageRecruitment] = useState([]);
  const [varWorkers, setWorkers] = useState([]);
  //공고 진행상태 숫자
  const [varCounts, setCounts] = useState({
    _all_count: 0,
    _all_proceeding: 0,
    _all_waiting: 0,
    _all_close: 0
  });
  const [varTabEmployStatus, setTabEmployStatus] = useState(1);

  //검색
  const [varMemberIndex, setMemberIndex] = useState(0);
  const [varSearchOption, setSearchOption] = useState("");
  const [varSearchText, setSearchText] = useState("");
  const [varSearching, setSearching] = useState(true)

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  //최초 정보 가져오기 
  useEffect(async () => {
    if (varSearching) {
      const sendData = {
        'mcm_idx': mcm_idx,
        'mem_idx': mem_idx,
        'SearchForm': SearchForm,
        'varMemberIndex': varMemberIndex, /* 작성자 선택 */
        'varSearchOption': varSearchOption, /* 검색 옵션 */
        'varSearchText': varSearchText /* 검색어  */
      };

      await axios.post(`${CONFIG.SERVER_HOST}/_get_announcement_list`, sendData, CONFIG.header).then((res) => {
        //채용공고목록
        if (res.data.employList?.length > 0) {
          setRecruitment(res.data.employList);
          setTotalCount(res.data.employList?.length);
        } else {
          setRecruitment([]);
        }

        //채용공고 관련 상태별 수량
        if (res.data.employCount?.length > 0) {
          setCounts({
            _all_count: res.data.employCount[0]._all_count,
            _all_proceeding: res.data.employCount[0]._all_proceeding,
            _all_waiting: res.data.employCount[0]._all_waiting,
            _all_close: res.data.employCount[0]._all_close,
          });
        }
        //기업직원 명부
        if (res.data.workers?.length > 0) {
          setWorkers(res.data.workers);
        }

      });
      setSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMemberIndex, varSearching]);

  // 공고 등록 후 이동 시 상태 탭 대기중으로 => 방금 작성한 공고 확인 요청 
  useEffect(() => {
    if (location.state && location.state?.varTabEmployStatus === 0) {
      setTabEmployStatus(0)
    } else {
      setTabEmployStatus(1)
    }
  }, [])

  //공고상태 탭 선택시
  const ResetFilteredRecruitment = () => {
    let copyLists = [...varRecruitment];
    if (varTabEmployStatus < 4) {
      copyLists = copyLists.filter(
        (element) => element.em_status === varTabEmployStatus
      );

    }

    //작성자
    if (Number(varMemberIndex) > 0) {
      copyLists = copyLists.filter(
        (element) => element.mem_idx === Number(varMemberIndex)
      );
    }

    setFilterRecruitment(copyLists);
    setTotalCount(copyLists.length);
    setPageNum(1);
    //필터링된 배열이 없으면 하부의 페이지필터링이 작동되지 않습니다.
    if (copyLists.length === 0) {
      setPageRecruitment([]);
    }
  };

  useEffect(() => {
    ResetFilteredRecruitment();
  }, [varTabEmployStatus, varRecruitment, varMemberIndex]);

  //표시할 페이지가 바뀌면
  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varFilterRecruitment.length === 0) return;
    let copyData = [...varFilterRecruitment];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    //console.log(copyData);
    setPageRecruitment(copyData);
  };
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varFilterRecruitment]);

  //공고조기마감
  const onCloseAnnouncementHandler = async (e, em_idx) => {
    e.preventDefault();
    if (window.confirm("공고를 마감하시겠습니까?")) {
      //선택된 공고 상태 읽기
      const finsIndex = varRecruitment.findIndex(element => element.em_idx === em_idx);
      const countStatus = varRecruitment[finsIndex].em_status;
      //공고 조기 마감 처리
      const params = new URLSearchParams();
      params.append("em_idx", em_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_finish_announcement`, params).then((res) => {
        alert(res.data.message);
        if (res.data.retvalue === 1) {
          //공고목록에서 해당 정보 변경
          const copyData = varRecruitment.map(employ => employ.em_idx === em_idx ? { ...employ, em_status: 2 } : employ);
          setRecruitment(copyData);
          //카운팅 변경
          if (countStatus === 0) {
            const iwaiting = varCounts._all_waiting - 1;
            setCounts((prevState) => ({ ...prevState, _all_waiting: iwaiting }));
          } else if (countStatus === 1) {
            const iproceeding = varCounts._all_proceeding - 1;
            setCounts((prevState) => ({ ...prevState, _all_proceeding: iproceeding }));
          }
          const iclose = varCounts._all_close + 1;
          setCounts((prevState) => ({ ...prevState, _all_close: iclose }));
        }
      });
    }
  };
  //공고삭제
  const onDeleteAnnoucementHandler = async (e, em_idx) => {
    e.preventDefault();
    if (window.confirm("공고를 삭제하시겠습니까?")) {
      const finsIndex = varRecruitment.findIndex(element => element.em_idx === em_idx);
      const countStatus = varRecruitment[finsIndex].em_status;
      const params = new URLSearchParams();
      params.append("em_idx", em_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_delete_announcement`, params).then((res) => {
        alert(res.data.message);
        if (res.data.retvalue === 1) {
          const copyData = varRecruitment.filter(
            (employ) => employ.em_idx !== em_idx
          );
          setRecruitment(copyData);
          if (countStatus === 0) {
            const iwaiting = varCounts._all_waiting - 1;
            setCounts((prevState) => ({ ...prevState, _all_waiting: iwaiting }));
          } else if (countStatus === 1) {
            const iproceeding = varCounts._all_proceeding - 1;
            setCounts((prevState) => ({ ...prevState, _all_proceeding: iproceeding }));
          } else if (countStatus === 2) {
            const iclose = varCounts._all_close - 1;
            setCounts((prevState) => ({ ...prevState, _all_close: iclose }));
          }
          const iallcount = varCounts._all_count - 1;
          setCounts((prevState) => ({ ...prevState, _all_count: iallcount }));
        }
      });
    }
  };

  //검색을 위한 작성자 선택
  const onChangeWriteMemberHandler = (e) => {
    setMemberIndex(e.target.value);
    setSearching(true);
  };
  //검색옵션
  const onSearchOptionHandler = (e) => {
    setSearchOption(e.target.value);
  };
  //검색문자
  const onSearchStringHandler = (e) => {
    setSearchText(e.target.value);
  };
  //검색시작
  const onExecuteSerchString = (e) => {
    e.preventDefault();
    setSearching(true)
    ResetFilteredRecruitment();
  };

  return (
    <>
      <section className="list_top">
        <ul className="state_area">
          <li>
            <input type="radio" name="chk1" id="check_last_info1" className="chk1" checked={varTabEmployStatus === 4 ? true : false}
              onChange={() => setTabEmployStatus(4)}
            />
            <label htmlFor="check_last_info1">전체 <span>{varCounts._all_count === null ? 0 : varCounts._all_count}</span></label>
          </li>
          <li>
            <input
              type="radio" name="chk1" id="check_last_info2" className="chk1" checked={varTabEmployStatus === 1 ? true : false}
              onChange={() => setTabEmployStatus(1)}
            />
            <label htmlFor="check_last_info2">진행중 <span>{varCounts._all_proceeding === null ? 0 : varCounts._all_proceeding}</span></label>
          </li>
          <li>
            <input type="radio" name="chk1" id="check_last_info3" className="chk1" checked={varTabEmployStatus === 0 ? true : false}
              onChange={() => setTabEmployStatus(0)}
            />
            <label htmlFor="check_last_info3">대기중 <span>{varCounts._all_waiting === null ? 0 : varCounts._all_waiting}</span></label>
          </li>
          <li>
            <input type="radio" name="chk1" id="check_last_info4" className="chk1" checked={varTabEmployStatus === 2 ? true : false}
              onChange={() => setTabEmployStatus(2)}
            />
            <label htmlFor="check_last_info4" >마감 <span>{varCounts._all_close === null ? 0 : varCounts._all_close}</span></label>
          </li>
        </ul>

        {/* 검색조건 */}
        {/* <div className="BoardSearch"> */}
        <article className="middle_wrap">
          <p className="select_label">작성자<span>&nbsp;&nbsp;</span></p>
          <select className="style_select" onChange={(e) => onChangeWriteMemberHandler(e)} value={varMemberIndex}>
            <option value="0">전체</option>
            {varWorkers.map((worker, i) => (
              <option key={"worker_" + i} value={worker.mem_idx}>{worker.mem_id}</option>
            ))}
          </select>
        </article>
        <article className="last_wrap">
          <div className="list_search_area">
            <select className="style_select" value={varSearchOption} onChange={(e) => onSearchOptionHandler(e)}>
              <option value="">전체</option>
              <option value="em_title">공고제목</option>
              <option value="mem_id">아이디</option>
              <option value="mem_name">이름</option>
            </select>
            <div className="search">
              <input type="text" name="SearchText" value={varSearchText} placeholder="검색어를 입력해주세요" onChange={e => onSearchStringHandler(e)} onKeyDown={(e) => { if (e.key === 'Enter') onExecuteSerchString(e) }} />
            </div>
            <button className="btn" onClick={(e) => onExecuteSerchString(e)}>조회</button>
          </div>
        </article>
      </section>
      <section className="anno_list_area">
        {varPageRecruitment.length === 0 ?
          <article className="anno_list_card">
            <article className="left_wrap" style={{ textAlign: "center", width: "100vw" }}>데이터가 없습니다</article>
          </article>
          : varPageRecruitment.map((employs, i) => (
            <article className="anno_list_card" key={"employs_" + i}>
              <article className="left_wrap">
                <h1>
                  <span>{employs.com_name}</span>
                  <span>{employs.vr_name}</span>
                  <span>
                    {employs.em_careers === 0
                      ? "경력무관"
                      : employs.em_careers === 1
                        ? "신입"
                        : "경력"}
                    {employs.em_careers === 2 && employs.em_career_year !== 0
                      ? "(" + employs.em_career_year + "년)"
                      : ""}
                  </span>
                </h1>
                <Link to={`/Company/ComRecruit/MainView/${employs.em_idx}/${employs.em_headhunter}`}>
                  <h2>
                    <b className={employs.em_status === 0 ? "txt_blue" : "txt_red"}>
                      {employs.em_status === 0
                        ? "대기중"
                        : employs.em_status === 1
                          ? "진행중"
                          : "마감"}
                        {/* 프론트에서 일단 수정 2022-12-22 */}
                        {/* {employs.em_status === 0
                        ? "대기중"
                        : employs.em_edate === null || employs.em_edate <= today ? "진행중" : "마감"} */}
                    </b>
                    &nbsp;{em_titleLength(employs.em_title, 52, '...')}
                  </h2>
                </Link>
                <h3>
                  <span>
                    지원기간 : {ChangeDateStringOnlyDate(employs.em_sdate)} ~{" "}
                    {employs.em_deadline === 1 ? "채용시마감" : ChangeDateStringOnlyDate(employs.em_edate)}
                  </span>
                  <span>
                    <i className="dot"> ㆍ </i>
                    등록일 :{" "}
                    {ChangeDateStringOnlyDate(employs.em_rdate)}
                  </span>
                  <span>
                    <i className="dot">ㆍ</i>조회수 : {employs.em_hits}
                  </span>
                </h3>
                {employs.em_type === 3 ? null :
                  <h4>
                    <img className="view_pc" src={WonLogoIcon_pc} alt="" />
                    <img className="view_m" src={WonLogoIcon_m} alt="" />
                    총 보상금
                    <b className="txt_blue">{employs.em_type === 4 ? ChangeMoneyUnit(employs.emh_reward_recommend / 10000) : ChangeMoneyUnit(employs.em_reward_recommend / 10000)}</b>
                  </h4>
                }
              </article>
              <article className="right_wrap">
                <ul className="state_area">
                  <li>
                    <h1>총 지원자</h1>
                    <b>
                      {employs._total_support > 0 ?
                        <Link to={`../SupportView`} state={{ em_idx: employs.em_idx }}>
                          {employs._total_support}
                        </Link>
                        : employs._total_support}
                    </b>
                  </li>
                  <li>
                    <h1>미열람</h1>
                    <b className="txt_red">
                      {employs._unread > 0 ?
                        <Link to={`../SupportView`} state={{ em_idx: employs.em_idx }}>
                          {employs._unread}
                        </Link>
                        : employs._unread}
                    </b>
                  </li>
                  <li>
                    <h1>검토중</h1>
                    <b>
                      {employs._read > 0 ?
                        <Link to={`../SupportView`} state={{ em_idx: employs.em_idx }}>
                          {employs._read}
                        </Link>
                        : employs._read}
                    </b>
                  </li>
                  <li>
                    <h1>서류합격</h1>
                    <b className="txt_blue">
                      {employs._document_pass > 0 ?
                        <Link to={`../SupportView`} state={{ em_idx: employs.em_idx }}>
                          {employs._document_pass}
                        </Link>
                        : employs._document_pass}
                    </b>
                  </li>
                </ul>
              </article>
              {/* </div> */}
              <article className="bottom_wrap">
                <div className="writer">
                  {employs?.mcm_image === 1 ?
                    <figure className="bg_profile_img" style={{ backgroundImage: `url(${imageFolder + employs?.mcm_ufile})` }}></figure> : <figure className="bg_profile_img"><img src={defaultCompanyLogo} alt="" /></figure>
                  }
                  <h1>{employs.mem_id} ({employs.mem_name})</h1>
                </div>
                <div className="button_wrap">
                  <Link className="btn line gray" to={`../AnnouncementModify/${employs.em_idx}`}>수정</Link>
                  <Link className="btn line gray" to={`../AnnouncementCopy/${employs.em_idx}`}>복사</Link>
                  <button className="btn line gray" onClick={(e) => employs.em_status !== 2 ? onCloseAnnouncementHandler(e, employs.em_idx) : alert('마감되었습니다.')}>조기마감</button>
                  <button className="btn line gray" onClick={(e) => onDeleteAnnoucementHandler(e, employs.em_idx)}>삭제</button>
                </div>
              </article>
            </article>
          ))}
      </section>
      <div className="paging" style={{ marginBottom: "20px" }}>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
      </div>
    </>
  );
};

export default AnnouncementListC;
