/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";

import "../../css/admin.css";


function Menu({ menuType, menu }) {
  // const {menuType, menu} = props;
  const type = Number(localStorage.getItem("type"));

  if (menuType === "pref") {
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb">
          <ul className="depth1">
            {type === 0 ? (
              <>
                <li className={menu === "account" || menu === "subaccount" ? "selectedmenu" : ""}>
                  <a>운영자관리</a>
                  <ul className="depth2">
                    <li className="m03">
                      <Link to={`/admin/${localStorage.getItem("id")}`}>
                        <span
                          className={menu === "account" ? "selectedlimenu" : ""}
                        >
                          - 관리자 계정 관리
                        </span>
                      </Link>
                    </li>
                    <li className="m03">
                      <Link to="/admin/subadmin">
                        <span
                          className={
                            menu === "subaccount" ? "selectedlimenu" : ""
                          }
                        >
                          - 부관리자 계정 관리
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            <li className={menu === "popup" || menu === "slide" || menu === "banner" ? "selectedmenu" : ""}>
              <a>디자인 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/popup">
                    <span className={menu === "popup" ? "selectedlimenu" : ""}>
                      - 팝업 관리
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/main_slide">
                    <span className={menu === "slide" ? "selectedlimenu" : ""}>
                      - 메인슬라이드 관리
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/banner">
                    <span className={menu === "banner" ? "selectedlimenu" : ""}>
                      - 배너 관리
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                menu === "auto" || menu === "manual" || menu === "pushlist"|| menu === "personal"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>PUSH 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/push">
                    <span className={menu === "auto" ? "selectedlimenu" : ""}>
                      - 자동 PUSH관리
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/push_manual">
                    <span className={menu === "manual" ? "selectedlimenu" : ""}>
                      - 수동 PUSH관리
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/push_personal ">
                    <span className={menu === "personal" ? "selectedlimenu" : ""}>
                      - 개인 PUSH관리
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/pushlist">
                    <span
                      className={menu === "pushlist" ? "selectedlimenu" : ""}
                    >
                      - PUSH 발송내역
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={menu === "bank" || menu === "compensation" ? "selectedmenu" : ""}>
              <a>운영관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/account">
                    <span className={menu === "bank" ? "selectedlimenu" : ""}>
                      - 계좌 관리
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/reword">
                    <span
                      className={
                        menu === "compensation" ? "selectedlimenu" : ""
                      }
                    >
                      - 보상금 관리
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={menu === "condition" || menu === "privacy" || menu === "selfconfirm" || menu === "personalterm" ? "selectedmenu" : ""}>
              <a>약관 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/condition">
                    <span
                      className={menu === "condition" ? "selectedlimenu" : ""}
                    >
                      - 이용약관
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/privacy">
                    <span
                      className={menu === "privacy" ? "selectedlimenu" : ""}
                    >
                      - 개인정보처리방침
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/selfconfirm">
                    <span
                      className={
                        menu === "selfconfirm" ? "selectedlimenu" : ""
                      }
                    >
                      - 본인확인 서비스
                    </span>
                  </Link>
                </li>
                <li className="m04">
                  <Link to="/admin/personalterm">
                    <span
                      className={
                        menu === "personalterm" ? "selectedlimenu" : ""
                      }
                    >
                      - 개인정보 유효기간
                    </span>
                  </Link>
                </li>
                <li className="m05">
                  <Link to="/admin/AgreeTopush">
                    <span
                      className={
                        menu === "AgreeTopush" ? "selectedlimenu" : ""
                      }
                    >
                      - push 알림 동의
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={menu === "urllink" ? "selectedmenu" : ""}>
              <a>링크 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/urllink">
                    <span
                      className={menu === "urllink" ? "selectedlimenu" : ""}
                    >
                      - 링크 관리
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (menuType === "users") {
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb">
          <ul className="depth1">
            <li
              className={
                menu === "freeuserList" || menu === "corporate_member_list" || menu === "headhunter_list" || menu === "withdrawal_list"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>회원 목록</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/userlist">
                    <span
                      className={
                        menu === "freeuserList" ? "selectedlimenu" : ""
                      }
                    >
                      - 일반 회원 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/corporate_member_list">
                    <span
                      className={
                        menu === "corporate_member_list" ? "selectedlimenu" : ""
                      }
                    >
                      - 기업 회원 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/headhunter_list">
                    <span
                      className={
                        menu === "headhunter_list" ? "selectedlimenu" : ""
                      }
                    >
                      - 헤드헌터 회원 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/withdrawal_member_list">
                    <span
                      className={
                        menu === "withdrawal_list" ? "selectedlimenu" : ""
                      }
                    >
                      - 탈퇴 회원 관리
                    </span>{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                menu === "employee_list" || menu === "accumulateList" || menu === "exchangeList" || menu === "employee_detail" || menu === "quit_member_list"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>헤딩 머니</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/employee_list">
                    <span
                      className={
                        menu === "employee_list" || menu === "employee_detail" ? "selectedlimenu" : ""
                      }
                    >
                      - 입사자 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/quit_member_list">
                    <span
                      className={
                        menu === "quit_member_list" || menu === "quit_member_detail" ? "selectedlimenu" : ""
                      }
                    >
                      - 보증기간 내 퇴사 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/accumulatelist">
                    <span
                      className={
                        menu === "accumulateList" ? "selectedlimenu" : ""
                      }
                    >
                      - 적립내역
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/exchangelist">
                    <span
                      className={
                        menu === "exchangeList" ? "selectedlimenu" : ""
                      }
                    >
                      - 환전관리
                    </span>{" "}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (menuType === "announcement") {
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb">
          <ul className="depth1">
            <li className={menu === "announcement_list" || menu === "headhunter_dedicated_hall_list" ? "selectedmenu" : ""}>
              <a>공고 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/announcement_list">
                    <span
                      className={
                        menu === "announcement_list" ? "selectedlimenu" : ""
                      }
                    >
                      - 채용 공고 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/headhunter_dedicated_hall_list">
                    <span
                      className={
                        menu === "headhunter_dedicated_hall_list"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 헤드헌터전용관 관리
                    </span>{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li className={menu === "supportlist" ? "selectedmenu" : ""}>
              <Link to="/admin/supportlist">
                <span
                  className={
                    menu === "supportlist" ? "selectedlimenu" : ""
                  }
                >
                  지원 관리
                </span>{" "}
              </Link>
            </li>
            <li className={menu === "suggestionlist" ? "selectedmenu" : ""}>
              <Link to="/admin/suggestionlist">
                <span
                  className={
                    menu === "suggestionlist" ? "selectedlimenu" : ""
                  }
                >
                  추천 관리
                </span>{" "}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (menuType === "board") {
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb">
          <ul className="depth1">
            <li
              className={
                menu === "boardlist" ||
                  menu === "boarddetail" ||
                  menu === "consultlist" ||
                  menu === "consultdetail" ||
                  menu === "hrintrolist" ||
                  menu === "hrintrodetail"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>게시판 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/boardlist">
                    <span
                      className={
                        menu === "boardlist" || menu === "boarddetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 공지사항 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/consultlist">
                    <span
                      className={
                        menu === "consultlist" || menu === "consultdetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 헤딩 컨설팅 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/hrintrolist">
                    <span
                      className={
                        menu === "hrintrolist" || menu === "hrintrodetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - HR 담당자 소개 관리
                    </span>{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                menu === "faqseriouslist" ||
                  menu === "faqseriousdetail" ||
                  menu === "faqlist" ||
                  menu === "faqdetail"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>FAQ 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/faqseriouslist">
                    <span
                      className={
                        menu === "faqseriouslist" || menu === "faqseriousdetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - FAQ 유형 관리
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/faqlist">
                    <span
                      className={
                        menu === "faqlist" || menu === "faqdetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - FAQ 관리
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                menu === "talklist"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>이직/구인톡 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/talklist">
                    <span
                      className={
                        menu === "talklist" || menu === "talklistdetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 이직/구인톡 관리
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                menu === "resumetiplist" || menu === "resumetipdetail"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>이력서 TIP 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/resumetiplist">
                    <span
                      className={
                        menu === "resumetiplist" || menu === "resumetipdetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 이력서 TIP 관리
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (menuType === "using_pass") {
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb">
          <ul className="depth1">
            <li
              className={
                menu === "paymentlist" ||
                  menu === "refundlist" ||
                  menu === "voucherlist" ||
                  menu === "paymentdetail" ||
                  menu === "refunddetail" ||
                  menu === "voucherdetail"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>이용권 구매관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/paymentlist">
                    <span
                      className={
                        menu === "paymentlist" || menu === "paymentdetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 결제관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/refundlist">
                    <span
                      className={
                        menu === "refundlist" || menu === "refunddetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 환불내역
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/voucherlist">
                    <span
                      className={
                        menu === "voucherlist" || menu === "voucherdetail"
                          ? "selectedlimenu"
                          : ""
                      }
                    >
                      - 이용권 등록
                    </span>{" "}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (menuType === "variables") {
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb">
          <ul className="depth1">
            <li
              className={
                menu === "employvariables" ||
                  menu === "resumevariables" ||
                  menu === "othervariables"
                  ? "selectedmenu"
                  : ""
              }
            >
              <a>선택항목관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/employvariables">
                    <span
                      className={
                        menu === "employvariables" ? "selectedlimenu" : ""
                      }
                    >
                      - 채용 항목 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/resumevariables">
                    <span
                      className={
                        menu === "resumevariables" ? "selectedlimenu" : ""
                      }
                    >
                      - 이력 항목 관리
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/othervariables">
                    <span
                      className={
                        menu === "othervariables" ? "selectedlimenu" : ""
                      }
                    >
                      - 기타 항목 관리
                    </span>{" "}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (menuType === "") {
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb"></div>
      </div>
    );
  } else if (menuType === 'dashboard') {
    //dashboard_list
    return (
      <div id="left-wrap">
        <div className="logo">
          <p className="h1">헤딩관리시스템</p>
          <p className="h1-s">ADMINISTRATOR</p>
        </div>
        <div id="gnb">
          <ul className="depth1">
            <li
              className={
                menu === "dashboard_list" ? "selectedmenu" : ""
              }
            >
              <Link to="/admin/dashboard">
                Dash board
              </Link>
            </li>
            <li
              className={
                menu === "member_statistics" || menu === "job_posting_statistics" ? "selectedmenu" : ""
              }
            >
              <Link to="/admin/dashboard/member_statistics">
              세부 통계
              </Link>
              <ul className="depth2">
                <li className="m03">
                  <Link to="/admin/dashboard/member_statistics">
                    <span
                      className={
                        menu === "member_statistics" ? "selectedlimenu" : ""
                      }
                    >
                      - 가입회원 통계
                    </span>{" "}
                  </Link>
                </li>
                <li className="m03">
                  <Link to="/admin/dashboard/job_posting_statistics">
                    <span
                      className={
                        menu === "job_posting_statistics" ? "selectedlimenu" : ""
                      }
                    >
                      - 채용공고 통계
                    </span>{" "}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );

  }
}

export default Menu;
