/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React, {useState} from "react";

//component
import CertificateHistory from "./CertificateHistory";
//image
import addSchoolImage from "../../img/sub/btn_plus_blue.png";

function Certificate({
	certificateList,
	setCertificateList,
  careerTipList
}) {

	const [addNum, setAddNum] = useState(0);
	const onHandlerAddLanguageHistory = (e) => {
		e.preventDefault();
		// if (!window.confirm("자격증을 추가합니까?")) return;

		const certificate = {
			idx: addNum,
			rc_name: '',
			rc_gdate: '',
			rc_issuer: ''
		};
		setAddNum(addNum+1);
		setCertificateList([...certificateList, certificate]);

	};

  //자격증 작성 TIP
  let copyData = [...careerTipList]
  const tipData = copyData.filter((el) => el.career_order === 4)

	return (
		<section className="section">
      <div className="title_area has_guide_secret">
			  <h3 className="title">자격증</h3>
        <button
					className="btn txt"
					onClick={(e) => onHandlerAddLanguageHistory(e)}
				>
					<img src={addSchoolImage} alt="" />
					추가
				</button>
        {/*작성팁*/}
        <div className="guide">
          <p className="guide_btn"><strong className="tit">작성 TIP</strong><span className="icon" ></span></p>
          <div className="guide_secret">
            <p className="guide_secret_tit">{tipData[0]?.career_tip_title}</p>
            <div className="guide_secret_txt text-lines">{tipData[0]?.career_tip_content}</div>
          </div>
        </div>
      </div>
			{certificateList
				? certificateList.map(( certificate, index) => (
					<CertificateHistory
						key={"scharray_" + index}
						index={index}
						certificate={ certificate}
						certificateList={certificateList}
						setCertificateList={setCertificateList}
					/>
				))
				: null}
		</section>
	);
}

export default Certificate;
