/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import axios from 'axios';
import CONFIG from '../../configs/configs';
import DatePicker from "react-datepicker";

const Tillko_Api = () => {

  const [data, set_Data] = useState({
    PrivateAuthType: "",
    UserName: "",
    UserCellphoneNumber: "",
    PrivateAuthType2: "",
    UserName2: "",
    UserCellphoneNumber2: "",
  });

  const [BirthDate, setBirthDate] = useState(new Date());
  const [BirthDate2, setBirthDate2] = useState(new Date());

  const [R_Data, set_R_Data] = useState({
    CxId :"",
    ReqTxId :"",
    PrivateAuthType :"",
    UserName :"",
    BirthDate :"",
    UserCellphoneNumber :"",
    Token :"",
    TxId :""
  });

  const [btn_hidden, setBtn_hidden] = useState(false);
  const [btn_hidden2, setBtn_hidden2] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    set_Data(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const dateToString = (date) => {
    return date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0')
  }

  //get_tilko3
  const handleSubmit = async () => {
    if (window.confirm("실행 하시겠습니까?")) {

      const param = new URLSearchParams();
      param.append("PrivateAuthType", data.PrivateAuthType);
      param.append("UserName", data.UserName);
      param.append("BirthDate", dateToString(BirthDate));
      param.append("UserCellphoneNumber", data.UserCellphoneNumber);


      axios.post(`${CONFIG.SERVER_HOST}/get_tilko4`, param).then((res) => {
        console.log(res);

        const Message = res.data.Message;
        const data = res.data.ResultData;
        set_R_Data({
          CxId :data.CxId ,
          ReqTxId :data.ReqTxId ,
          PrivateAuthType :data.PrivateAuthType ,
          UserName :data.UserName ,
          BirthDate :data.BirthDate ,
          UserCellphoneNumber :data.UserCellphoneNumber ,
          Token :data.Token ,
          TxId :data.TxId
        });

        if(Message === "성공"){
          setBtn_hidden(!btn_hidden);
        }
        // CxId: '12a5724a08-0fb9-4c11-b811-8365f4cbca7b',
        // ReqTxId: '1b1853242c1e4968a05b1516e53e260funfunhbh',
        // PrivateAuthType: 'KakaoTalk',
        // UserName: '강혜성',
        // BirthDate: '19870609',
        // UserCellphoneNumber: '01085169973',
        // Token: 'eyJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiZE5jeVVvVjg0c3cxbmJBSHp6SVdTQT09IiwicGhvbmUiOiJOak1ZVnQ4a1NNSTZJZzJQZ2RtSFlnPT0iLCJzc24xIjoidmxiZWl2YWhFQU4waGtQYTc4MmFnZz09Iiwic3NuMiI6InZsYmVpdmFoRUFOMGhrUGE3ODJhZ2c9PSIsImJpcnRoZGF5IjoiU2Z0MmZqbmpudHJzMUpJZGVsa0VoQT09IiwiY3hJZCI6IjEyYTU3MjRhMDgtMGZiOS00YzExLWI4MTEtODM2NWY0Y2JjYTdiIiwianRpIjoiMWIxODUzMjQyYzFlNDk2OGEwNWIxNTE2ZTUzZTI2MGZ1bmZ1bmhiaCIsImlzcyI6ImhvbWV0YXgiLCJzdWIiOiJBRlRFUl9SRVFVRVNUIiwiaWF0IjoxNjQ1NzY1MTk4LCJleHAiOjE2NDU3NjU3OTgsInByaSI6Im13VER0TVFRQXJQM0pGcU02R1luZEpTMWJMbjhub3hQUmtodXNLcGtnUEU9In0.9D0XGikoqNLITFqF1FKik6hCAOFfGgOW5azlvUpXYZs',
        // TxId: '1b1853242c1e4968a05b1516e53e260funfunhbh'

      });

    }
  }

   //get_tilko3
   const handleSubmit2 = async () => {
    if (window.confirm("실행 하시겠습니까?")) {

      const param = new URLSearchParams();
      param.append("CxId", R_Data.CxId);
      param.append("ReqTxId", R_Data.ReqTxId);
      param.append("PrivateAuthType", R_Data.PrivateAuthType);
      param.append("UserName", R_Data.UserName);
      param.append("BirthDate", R_Data.BirthDate);
      param.append("UserCellphoneNumber", R_Data.UserCellphoneNumber);
      param.append("Token", R_Data.Token);
      param.append("TxId", R_Data.TxId);


      axios.post(`${CONFIG.SERVER_HOST}/get_tilko5`, param).then((res) => {
        console.log(res);
      });

    }
  }

  //get_tilko3
  const handleSubmit3 = async () => {
    if (window.confirm("실행 하시겠습니까?")) {

      const param = new URLSearchParams();
      param.append("PrivateAuthType", data.PrivateAuthType2);
      param.append("UserName", data.UserName2);
      param.append("BirthDate", dateToString(BirthDate2));
      param.append("UserCellphoneNumber", data.UserCellphoneNumber2);


      axios.post(`${CONFIG.SERVER_HOST}/get_tilko2`, param).then((res) => {
        console.log(res);

        const Message = res.data.Message;
        const data = res.data.ResultData;
        set_R_Data({
          CxId :data.CxId ,
          ReqTxId :data.ReqTxId ,
          PrivateAuthType :data.PrivateAuthType ,
          UserName :data.UserName ,
          BirthDate :data.BirthDate ,
          UserCellphoneNumber :data.UserCellphoneNumber ,
          Token :data.Token ,
          TxId :data.TxId
        });

        if(Message === "성공"){
          setBtn_hidden2(!btn_hidden2);
        }

      });

    }
  }
   //get_tilko3
   const handleSubmit4 = async () => {
    if (window.confirm("실행 하시겠습니까?")) {

      const param = new URLSearchParams();
      param.append("CxId", R_Data.CxId);
      param.append("ReqTxId", R_Data.ReqTxId);
      param.append("PrivateAuthType", R_Data.PrivateAuthType);
      param.append("UserName", R_Data.UserName);
      param.append("BirthDate", R_Data.BirthDate);
      param.append("UserCellphoneNumber", R_Data.UserCellphoneNumber);
      param.append("Token", R_Data.Token);
      param.append("TxId", R_Data.TxId);


      axios.post(`${CONFIG.SERVER_HOST}/get_tilko3`, param).then((res) => {
        console.log(res);
      });

    }
  }
  return (
    <>
      <div className="AnnouncementListWrap">
        <div className="WBox">
          <div className="MTit">홈택스</div>
          <ul>
            <li className="TTitle">sns</li>
            <li className="SConts">
              <input type="text" name="PrivateAuthType" id="PrivateAuthType" value={data.PrivateAuthType.title} onChange={handleChange} />
            </li>
          </ul>
          <ul>
            <li className="TTitle">이름</li>
            <li className="SConts">
              <input type="text" name="UserName" id="UserName" value={data.UserName} onChange={handleChange} />
            </li>
          </ul>
          <ul>
            <li className="TTitle">생년월일</li>
            <li className="SConts">
              <ul style={{ display: "flex" }}>
                <li><DatePicker dateFormat={"yyy.MM.dd"} id="BirthDate" selected={BirthDate} onChange={(date) => setBirthDate(date)} /></li>
              </ul>
            </li>
          </ul>
          <ul>
            <li className="TTitle">전화번호</li>
            <li className="SConts">
              <input type="text" name="UserCellphoneNumber" id="UserCellphoneNumber" value={data.UserCellphoneNumber} onChange={handleChange} />
            </li>
          </ul>

          <div className="CenterBtn">
            <button  className="BtnBlue" onClick={handleSubmit} >
              간편인증
            </button>
            {btn_hidden === true ?            
            <button className="BtnBlue" onClick={handleSubmit2} >
              소득내역 조회
            </button>:""}
          </div>
        </div>

      <div className="WBox">
        <div className="MTit">건강보험</div>
        <ul>
          <li className="TTitle">sns</li>
          <li className="SConts">
            <input type="text" name="PrivateAuthType2" id="PrivateAuthType2" value={data.PrivateAuthType2} onChange={handleChange} />
          </li>
        </ul>
        <ul>
          <li className="TTitle">이름</li>
          <li className="SConts">
            <input type="text" name="UserName2" id="UserName2" value={data.UserName2} onChange={handleChange} />
          </li>
        </ul>
        <ul>
          <li className="TTitle">생년월일</li>
          <li className="SConts">
            <ul style={{ display: "flex" }}>
              <li><DatePicker dateFormat={"yyy.MM.dd"} id="BirthDate2" selected={BirthDate2} onChange={(date) => setBirthDate2(date)} /></li>
            </ul>
          </li>
        </ul>
        <ul>
          <li className="TTitle">전화번호</li>
          <li className="SConts">
            <input type="text" name="UserCellphoneNumber2" id="UserCellphoneNumber2" value={data.UserCellphoneNumber2} onChange={handleChange} />
          </li>
        </ul>

        <div className="CenterBtn">
          <button className="BtnBlue" onClick={handleSubmit3} >
            간편인증
          </button>
          {btn_hidden2 === true ?            
            <button className="BtnBlue" onClick={handleSubmit4} >
              득실내역 조회
            </button>:""}
        </div>
      </div>
    </div>





      
    </>
  )
}
export default Tillko_Api
