import React, { useEffect } from "react";
import { useParams/*, useNavigate*/ } from 'react-router-dom';
//css
import "css/Message.css"; 
//컴포넌트
import Message from 'components/My/Message';
import { resetNavSubMenu } from "components/Common/Common";

function MyHeding4({ getSubItem }) {
  // const uNavigate = useNavigate();

  const { roomIdx } = useParams();
  const mem_mode = Number(localStorage.getItem('mem_mode'));
  const mem_idx = Number(localStorage.getItem('mem_idx'));


  useEffect(() => {

    // if (!mem_idx || Number(mem_idx)===0) {
    //   alert("로그인 후 이용 가능합니다.");
    //   uNavigate("/Login");
    // }

    console.log('roomIdx :', roomIdx);

    getSubItem(4);
    if(mem_mode=== 0){
      resetNavSubMenu(4,3);
    }else{
      resetNavSubMenu(2,3);
    }
    //resetNavSubMenu(4,3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

/*채팅*/
    return (
        <div className="container page_message">
          {mem_idx > 0 ?
            <Message roomIdx={roomIdx} /> : <></>
          }
        </div>
    );
}

export default MyHeding4;
