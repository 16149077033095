/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { checkFileName } from "../../components/Common/Common";
//image
import deleteImage1 from "../../img/sub/btn_delete01.png";
//image
import plusImage from "../../img/sub/btn_plus.png";

import $ from 'jquery';
import CONFIG from '../../configs/configs';

function PortfolioFileHistory({ index, idx, portfolioFile, resumePortfolioFiles, setResumePortfolioFiles, setNewList }) {
  const [varPortFile] = useState(portfolioFile);
  const [sidx, setSidx] = useState(-1);

  console.log('portfolioFile :', portfolioFile);

  //파일이 선택되면 --> 화면에 표시 --> 부모컴퍼넌트로 지정
  const onSelectPortfolioHandler = (e, xidx) => {


    const uploadFile = e.currentTarget.files[0];


    const chk_file_name = checkFileName(uploadFile.name);
    if (uploadFile.size > CONFIG.fileSize) {
      alert('첨부파일 용량이 너무 큽니다.');
      $(`#file_${index}`).val("");
      return false;

    }

    if (chk_file_name) {
      // //전체배열에서 선택된 배열을 제외한 배열로 저장
      // const newArr1 = [];

      // resumePortfolioFiles.filter((element) =>  {
      //   if(element.get('idx') !== idx){
      //     newArr1.push(element);
      //   }
      // });


      // //선택된 객체에 파일의 정보 저장
      // varPortFile.set('idx', idx);
      // varPortFile.set('filename',uploadFile.name);
      // varPortFile.set('selectFile',uploadFile);
      // const PortFile = [varPortFile];
      // const newArr = newArr1.concat(PortFile);

      // console.log(newArr);

      // const orderedList = newArr.sort((a, b) => Number(a.get('idx')) - Number(b.get('idx')))

      // resumePortfolioFiles.map((item)=>{
      //   console.log('파일 추가 :', item.get('idx'), item.get('filename'));
      // });


      // //변경돤 정보 교체
      // setResumePortfolioFiles(orderedList); //해당 set은 잘못사용처리되어있음
      // const PortFile = {id:0, file:null, filename:null};
      const arr =  resumePortfolioFiles.map(Portfolio =>
        Portfolio.id === xidx ? {
          ...Portfolio,
          file: uploadFile,
          filename: uploadFile.name,
          show: false
        } : Portfolio
      )
      setResumePortfolioFiles(arr);


    } else {
      alert("등록가능한 파일이 아닙니다.");
      //첨부 가능한 확장자가 아닌 경우 input val 초기화
      $(`#file_${index}`).val("");
      return false;
    }
  };

  //포트폴리오 삭제 --> 저장배열에서 삭제
  const onRemoveHandler = (nindex, nidx) => {
    if (!window.confirm('선택한 포트폴리오를 삭제합니까?')) return;

    console.log('nidx0000', nidx);
    setSidx(nidx);
  };

  useEffect(() => {
    if (sidx > -1) {
     setResumePortfolioFiles(resumePortfolioFiles => resumePortfolioFiles.filter((element) => element.id !== sidx));
      setSidx(-1);
    }

  }, [sidx]);

  //페이지표시---------------------------------------------------------------------------------------
  if (portfolioFile.show === false) {
      return (
        <div className="portfolio_file" key={'fp' + index} style={{ fontSize: '15px' }}>
          {portfolioFile.filename}
          <a className="btn_del" onClick={() => { onRemoveHandler(index, portfolioFile.id) }} style={{ cursor: "pointer" }}>
            <img src={deleteImage1} alt="" />
          </a>
        </div>
      );
    } else {  //선택된 파일 보여주기
      return (
        <div className="portfolio_file" key={'f' + index}>
          <input type="file" style={{ fontSize: '15px', cursor: 'pointer' }} id={`file_${index}`} onChange={(e) => { onSelectPortfolioHandler(e, portfolioFile.id) }} />
          <a className="btn_add" onClick={() => onRemoveHandler(index, portfolioFile.id)} ><img src={deleteImage1} alt="" style={{ cursor: "pointer" }} />삭제하기</a>
        </div>
      );
  }

}

export default PortfolioFileHistory;