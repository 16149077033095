import React, { useCallback, useEffect, useState } from "react";
// import resumeSearchButton from "../../img/sub/icon_tip2.png";
import Chart from "react-apexcharts";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { Link, useLocation } from "react-router-dom";
import "../../css/Reputation.css";
import { reputationMatchResultList } from '../../global/global_career'

const AcqRepuRFriend = () => {
  const location = useLocation();
  const [info, setInfo] = useState({});
  const [chartMy, setChartMy] = useState([{}]); // '내가 보는 나', 그래프 데이터
  const [chartFriend, setChartFriend] = useState([{}]); //'타인이 보는 나', 그래프 데이터
  const [options, setOptions] = useState({
    chart: {
      height: 200,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    title: {
      text: "",
    },
    stroke: {
      width: 1,
      //   colors: 'black'
    },
    fill: {
      opacity: 0.2,
      //   colors: ['#F44336', '#E91E63', '#9C27B0'] // rgb?
    },
    markers: {
      size: 5,
      //   colors: ['#ffd966', '#a9d08e', '#f4b084', '#00b0f0', '#c65911'],
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: ['#ffd966', '#a9d08e', '#f4b084', '#00b0f0', '#c65911'],
          fontSize: "15px",
          fontWeight: "700"
          //   fontFamily: 'Arial',
        }
      }
    },
    yaxis: {
      show: false,
      min: 0,
      max: 15,
      tickAmount: 3,
    },
    plotOptions: {
      radar: {
        polygons: {
          // strokeColors: 'purple',			
          fill: {
            // colors: ['yellow', 'green']				
          },
          // strokeWidth: 2,
          // connectorColors: 'black',
        }
      }
    },

  }); // 그래프 옵션
  const [modifyDate, setModifyDate] = useState(""); // '내가 보는 나', 수정일자
  const [matchPercentage, setMatchPercentage] = useState(0); // 타인과 나의 성향 일치도
  const [statusBarColor, setStatusBarColor] = useState("step1"); // 성향 일치도 progress bar color
  const [matchResult, setMatchResult] = useState(""); // 성향 일치도 결과 내용

  const setMyGraph = () => {
    const params = new URLSearchParams([
      ['mem_idx', location.state?.mem_idx],
      ['myself', 'Y'],
    ]);
    axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/graph`, { params }).then((res) => {
      if (res.data?.retvalue) {
        const result = res.data.scores;
        const myGraphData = result?.map((item) => item.score);
        setChartMy(prev => prev = [
          {
            name: "점수",
            data: myGraphData,
          },
        ]);
        setModifyDate(result[0]?.date);
        setOptions((prev) => {
          return { ...prev, xaxis: { categories: result?.map((item) => item.label) } };
        });
      }
    }).catch((e) => {
      console.debug(e);
    });
  };

  const calcMatchPercentage = useCallback(() => {
    if (chartMy[0]?.data?.length && chartFriend[0]?.data?.length) {
      // const my = myChart[0].data;
      // 단위 %
      // 항목 별   max 20   min 4
      // 모든 항목 max 100  min 20
      let extraversionDiff = 0; // 외향성
      let hardworkingDiff = 0; // 성실성
      let opennessDiff = 0; // 개방성
      let friendlyDiff = 0; // 친화성
      let sensitivityDiff = 0; // 신경성

      extraversionDiff = Math.abs(chartMy[0].data[0] - chartFriend[0].data[0])
      hardworkingDiff = Math.abs(chartMy[0].data[1] - chartFriend[0].data[1])
      opennessDiff = Math.abs(chartMy[0].data[2] - chartFriend[0].data[2])
      friendlyDiff = Math.abs(chartMy[0].data[3] - chartFriend[0].data[3])
      sensitivityDiff = Math.abs(chartMy[0].data[4] - chartFriend[0].data[4])

      const extraversion = (10 - extraversionDiff) * 2;
      const hardworking = (10 - hardworkingDiff) * 2;
      const openness = Math.round((15 - opennessDiff) * (4 / 3));
      const friendly = Math.round((15 - friendlyDiff) * (4 / 3));
      const sensitivity = (10 - sensitivityDiff) * 2;

      const total = (extraversion + hardworking + openness + friendly + sensitivity);
      let className = "";
      if (total <= 25) {
        className = "step1"
      } else if (total <= 50) {
        className = "step2"
      } else if (total <= 75) {
        className = "step3"
      } else {
        className = "step4"
      }
      setStatusBarColor(className);
      setMatchPercentage(total);
      if (total <= reputationMatchResultList[0].key) {
        setMatchResult(reputationMatchResultList[0].value);
      } else if (total < reputationMatchResultList[1].key) {
        setMatchResult(reputationMatchResultList[1].value);
      } else if (total < reputationMatchResultList[2].key) {
        setMatchResult(reputationMatchResultList[2].value);
      } else if (total < reputationMatchResultList[3].key) {
        setMatchResult(reputationMatchResultList[3].value);
      } else if (total < reputationMatchResultList[4].key) {
        setMatchResult(reputationMatchResultList[4].value);
      } else {
        setMatchResult(reputationMatchResultList[5].value);
      }
    }
    else {
      setMatchPercentage(0);
    }
  }, [chartMy, chartFriend]);

  const setFriendGraph = useCallback(() => {
    const params = new URLSearchParams([
      ['mem_idx', location.state?.mem_idx],
      ['myself', 'N'],
      ['answer_no', location.state?.answer_no],
    ]);
    axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/graph`, { params }).then((res) => {
      if (res.data?.retvalue) {
        const result = res.data.scores;
        const scores = result?.map((item) => item.score);
        setChartFriend((prev) => prev = [
          {
            name: "점수",
            data: scores,
          },
        ]);
      }
    }).catch((e) => {
      console.debug(e);
    });
  }, [location.state?.mem_idx, location.state?.answer_no]);

  useEffect(() => {
    setInfo({ me: location.state?.me, friend: location.state?.friend });
    setMyGraph();
    setFriendGraph();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calcMatchPercentage();
  }, [calcMatchPercentage])

  return (
    <section className="com_reputation com_repu_main p_acq_repu_r center_wrap">
      <article className="com_repu_gray_container">
        <section className="left_section">
          <div className="who_see_container">
            <div className="who_see_area">
              <span className="txt_blue">{info?.friend}</span>님이 보는 나
              {/* <i className="icon_arrow" onClick={activeClassName}></i> */}
            </div>
            <ul className="who_see_area_options" >
              {/* {friends.map((item) => { return (<li key={item.anwser_no} id={item.anwser_no} onClick={changeFriend}>{item.anwser_writer}</li>) })} */}
            </ul>
          </div>
          <div className="acq_repu_graph">
            <Chart options={options} series={chartFriend} type="radar" height={350} />
          </div>
        </section>
        <section className="right_section">
          <div className="who_see_area" style={{ width: "auto", padding: "0px 20px 0px 20px" }}>
            {/* <span className="txt_blue">{info?.me}</span>님이 보는 {info?.me}<span className="update_date">수정일자: {modifyDate}</span> */}
            내가 보는 나 <span className="update_date">수정일자: {modifyDate}</span>
          </div>
          <div className="acq_repu_graph">
            <Chart options={options} series={chartMy} type="radar" height={350} />
          </div>
        </section>
      </article>
      <article className="repu_compare_container">
        <div className="repu_compare_txt">
          <h1>
            {info?.me}님이 보는 {info?.me}, {info?.friend}님이 보는 {info?.me} : <span>일치도</span>{matchPercentage}%
          </h1>
        </div>
        <article className={`resume_progress ${statusBarColor}`}>
          <div className="resume_progress_bar" style={{ maxWidth: `${matchPercentage}%` }}></div>
        </article>
      </article>
      <article className="repu_review_container">
        <div className="oneline_review">
          <h1>{matchResult}</h1>
          <Link className="btn" to="../../JoinUs/User" >HEDING 가입 후 다른 지인 평판하기</Link>
          <p>본 결과는 뉴캐슬 대학에서 개발한 성격5요인 모델 기반 약식 성격 검사(NPA, Newcastle Personality Assessor)기반으로 작성되었습니다.</p>
        </div>
      </article>
      <article className="repu_review_explain">
        <h1>상황별 설명</h1>
        <table>
          <colgroup>
            <col width="12%" />
            <col width="38%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <tr>
            <th>요인</th>
            <th>측정내용</th>
            <th>낮은점수</th>
            <th>높은점수</th>
          </tr>
          <tr>
            <td>외향성</td>
            <td>대인관계에서의 상호작용 정도와 강도를 측정. 즉, 활동수준, 자극에 대한 욕구, 즐거움 능력 등을 측정</td>
            <td>사람들과 어울리지 않고 조용함</td>
            <td>사람들과 잘 어울리며 열정적임</td>
          </tr>
          <tr>
            <td>친화성</td>
            <td>사고, 감정, 행동측면에서 동정심 부터 적대감까지의 연속선상에 개인의 대인관계 지향성을 측정</td>
            <td>비협조적이며 타인에게 적대적임</td>
            <td>공감능력이 뛰어나며, 다른 사람들을 잘 믿는편임</td>
          </tr>
          <tr>
            <td>성실성</td>
            <td>목표에 대한 자발적 행동에서의 조직, 끈기, 동기를 부여하는 정도를 측정</td>
            <td>충동적인 경향이 강하며 부주의함</td>
            <td>일처리에 있어 체계적이며 자발적인 경향이 있음</td>
          </tr>
          <tr>
            <td>신경성</td>
            <td>적응과 정서적 불안정을 측정, 심리적 스트레스 비현실적인 이상을 측정</td>
            <td>안정적인 정서를 지니고 있으며 감정적으로 차분함</td>
            <td>스트레스를 잘 받고 걱정을 많이 함</td>
          </tr>
          <tr>
            <td>개방성</td>
            <td>자신의 경험을 주도적으로 추구하고 평라하는지 여부를 측정</td>
            <td>실용적이며 보수적이고 전통적임</td>
            <td>창조적이며 독창적임</td>
          </tr>
        </table>
      </article>
      {/* <article className="repu_tip_container">
        <div className="myresume_tip">
          <h3 className="title_area">
            <img src={resumeSearchButton} alt="" />
            지인 평판 노출 장점
          </h3>
          <ul className="list">
            <li>헤드헌터와 기업에게 나의 성향을 한번에 보여줘서 면접 시 성향을 어필할 수 있습니다.</li>
          </ul>
        </div>
        <div className="repu_setting_container">
          <p className="text">지인 평판이 기업회원과 헤드헌터에게 다운받아지는 것을 동의합니다.</p>
          <div className="setting">
            <input type="checkbox" id="btnToggle1" />
          </div>
        </div>
      </article> */}
    </section>
  );
};

export default AcqRepuRFriend;
