/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { checkEmailString, Certification, changeMobileFormat, checkIDString, checkNickString, blank_pattern, special_pattern, isPassword, include_num, name_pattern } from "../Common/Common";

const JoinMember = ({ varInputs, setInputs, varConfirms, setConfirms, varSnsInfo }) => {

  //인증
  const [varAuth, setAuth] = useState({
    mobile: false,
  });

  //입력값 변경 - 이벤트
  const onChangeHandler = (e) => {
    const { value, name } = e.currentTarget;
    setInputs({ ...varInputs, [name]: value });
  };

  //아이디 점검(중복확인)
  const onCheckIDHandler = (e) => {
    e.preventDefault();
    if (!checkIDString(varInputs.id)) {
      alert("아이디 형식에 맞지 않습니다.");
      return false;
    }
    const params = new URLSearchParams();
    params.append("mem_id", varInputs.id);
    axios.post(`${CONFIG.SERVER_HOST}/checkId`, params).then((res) => {
      console.log(res.data);
      if (res.data.tf === true) {
        alert("사용가능한 ID입니다.");
        if (!varConfirms.checkID) setConfirms({ ...varConfirms, checkID: true });
      } else {
        alert("다른 ID를 입력해주세요.");
        if (varConfirms.checkID) setConfirms({ ...varConfirms, checkID: false });
      }
    });
  };

  //닉네임 점검
  const onCheckNickNameHandler = (e) => {
    e.preventDefault();
    if (varInputs.nickname !== "") {
      const params = new URLSearchParams();
      params.append("mem_nickname", varInputs.nickname);
      axios.post(`${CONFIG.SERVER_HOST}/checkNick`, params).then((res) => {
        console.log(res.data);
        if (res.data.tf === true) {
          alert("사용가능한 닉네임입니다.");
          if (!varConfirms.checkNickname) setConfirms({ ...varConfirms, checkNickname: true });
        } else {
          alert("사용중인 닉네임 입니다. \n다른 닉네임을 입력해주세요.");
          if (varConfirms.checkNickname) setConfirms({ ...varConfirms, checkNickname: false });
        }
      });
    } else {
      alert("닉네임을 입력해주세요");
    }
  };

  //이메일주소 적합성 확인
  const onCheckEmailHandler = (e) => {
    e.preventDefault();
    if (!checkEmailString(varInputs.email1 + "@" + varInputs.email2)) {
      alert("이메일 형식에 맞지않습니다.");
      setInputs({ ...varInputs, email1: "", email2: "" })
      return false;
    }
    const params = new URLSearchParams();
    params.append("mem_email", varInputs.email1 + "@" + varInputs.email2);
    axios.post(`${CONFIG.SERVER_HOST}/member_check_email`, params).then((res) => {
      if (res.data.tf === true) {
        alert("사용가능한 이메일입니다.");
        if (!varConfirms.checkEmail) setConfirms({ ...varConfirms, checkEmail: true });
      } else {
        alert("이미 사용중인 이메일입니다. \n다른 이메일을 입력해주세요.");
        if (varConfirms.checkEmail) setConfirms({ ...varConfirms, checkEmail: false });
      }
    });
  };

  //메일서버선택
  const onChangeMailServerHandler = (e) => {
    let eserver = e.currentTarget.value;
    let serverAddress = document.getElementById("email2");
    if (eserver === "") {
      serverAddress.value = "";
      serverAddress.focus();
    } else {
      setInputs({ ...varInputs, email2: eserver });
    }
  };

  //휴대폰 본인인증 - 클릭
  const onCertification = (e) => {
    if (varInputs.certification === true) {
      alert("이미 휴대폰 인증이 완료되었습니다.");
    } else {
      Certification(varInputs, setInputs, varAuth, setAuth, 0);//../Common/Common
    }
  }

  const onMessageReceivedFromIframe = (event) => {
    var event_data = JSON.parse(event.data);
    if (event_data.type === "imp") {
      const imp_uid = event_data.imp_uid;
      axios({
        url: `${CONFIG.SERVER_HOST}/certifications`, // 예: https://www.myservice.com/certifications
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: { imp_uid: imp_uid },
      }).then((res) => {
        const mobile = res.data.phone;
        const name = res.data.name;
        const birthday = res.data.birthday;
        const gender = res.data.gender === "male" ? 0 : 1;
        setInputs({ ...varInputs, mobile: mobile, certification: true, name: name, gender: gender, birthDay: birthday });
      });
    }
  };

  //어플 본인인증 리턴값 처리 로직 시작
  useEffect(() => {

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    //안드로이드
    if (varUA.indexOf("android") > -1) {
      document.addEventListener("message", onMessageReceivedFromIframe);
    } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
      window.addEventListener("message", onMessageReceivedFromIframe);
    }
    // window.addEventListener("message", onMessageReceivedFromIframe);
    return () => {
      if (varUA.indexOf("android") > -1) {
        document.removeEventListener("message", onMessageReceivedFromIframe);
      } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
        window.removeEventListener("message", onMessageReceivedFromIframe);
      }
    }
  }, [onMessageReceivedFromIframe]);
  //어플 본인인증 리턴값 처리 로직 끝




  //input창 실시간 체크 - 포커스 이탈
  const onAfterChangeHandler = (e) => {
    const { name, value } = e.currentTarget;
    // console.log('name ', name, ' value ',value)
    if (value === "") {
      return false;
    }

    if (name === 'id') {//--------- 아이디
      if (!blank_pattern(value)) {
        alert("아이디에 공백이 포함되어 있습니다.\n영문 + 숫자 조합의 6자리 이상 25자 이하 아이디를 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (!special_pattern(value)) {
        alert("아이디에 특수문자가 포함되어 있습니다.\n영문 + 숫자 조합의 6자리 이상 25자 이하 아이디를 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (!checkIDString(value)) {
        alert("아이디 형식에 맞지 않습니다.\n영문 + 숫자 조합의 6자리 이상 25자 이하 아이디를 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      }
    } else if (name === 'pwd') {//--------- 비번
      if (!blank_pattern(value)) {
        alert("비밀번호에 공백이 포함되어 있습니다.\n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (!isPassword(value)) {
        alert("비밀번호 형식에 맞지 않습니다.\n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      }
    } else if (name === 'pwdCheck') {//--------- 비번 확인
      if (!blank_pattern(value)) {
        alert("비밀번호 확인에 공백이 포함되어 있습니다.\n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (varInputs.pwd !== varInputs.pwdCheck) {
        alert(" 비밀번호가 일치하지 않습니다.");
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (!isPassword(value)) {
        alert("비밀번호 형식에 맞지 않습니다.\n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      }
    } else if (name === 'nickname') {//--------- 닉네임
      if (!blank_pattern(value)) {
        alert("닉네임에 공백이 포함되어 있습니다.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (!checkNickString(value)) {//../Common/Common
        alert("닉네임 형식에 맞지 않습니다. \n3~25 자리의 한글, 영문, 숫자만 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      }
    } else if (name === 'name') {//--------- 이름
      if (!blank_pattern(value)) {
        alert("이름에 공백이 포함되어 있습니다. 다시 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (!special_pattern(value)) {
        alert("이름에 특수문자가 포함되어 있습니다. 다시 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (!include_num(value)) {
        alert("이름에 숫자가 포함되어 있습니다. 다시 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (value.length <= 1) {
        alert("이름이 2글자 미만입니다. 다시 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      } else if (name_pattern(value)) {
        alert("이름 형식에 맞지 않습니다. 다시 입력해주세요.")
        setInputs({ ...varInputs, [name]: "" })
        e.currentTarget.focus();
      }
    }
  }//끝. EV::포커스 이탈(input text)

  return (
    <div className="member_form">
      {varSnsInfo.sns_id !== '' && varSnsInfo.sns_type !== '' ? <></> : (
        <>
          <p className="title_field">아이디</p>
          <div className="input_wrap">
            <input
              type="text"
              name="id"
              placeholder="6~25 자리의 영문, 숫자만 입력해주세요."
              value={varInputs.id}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onAfterChangeHandler(e)}
            />
            <button className="btn white" onClick={(e) => onCheckIDHandler(e)}>
              중복확인
            </button>
          </div>
          <p className="title_field">비밀번호</p>
          <input
            type="password"
            name="pwd"
            placeholder="영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요."
            value={varInputs.pwd}
            onChange={(e) => onChangeHandler(e)}
            onBlur={(e) => onAfterChangeHandler(e)}
            maxLength={16}
          />
          <p className="title_field">비밀번호 확인</p>
          <input
            type="password"
            name="pwdCheck"
            placeholder="비밀번호를 다시 입력해주세요."
            value={varInputs.pwdCheck}
            onChange={(e) => onChangeHandler(e)}
            onBlur={(e) => onAfterChangeHandler(e)}
            maxLength={16}
          />
        </>
      )}
      <p className="title_field">닉네임</p>
      <div className="input_wrap">
        <input
          type="text"
          name="nickname"
          placeholder="3~25 자리의 한글, 영문, 숫자만 입력해주세요."
          value={varInputs.nickname}
          onChange={(e) => onChangeHandler(e)}
          onBlur={(e) => onAfterChangeHandler(e)}
        />
        <button className="btn white" onClick={(e) => onCheckNickNameHandler(e)}>
          중복확인
        </button>
      </div>
      <p className="title_field">휴대폰번호</p>
      <div className="input_wrap">
        <input
          type="text"
          name="mobile"
          placeholder="본인인증 후 자동으로 입력됩니다."
          value={changeMobileFormat(varInputs.mobile)}
          //onChange={(e) => onChangeHandler(e)}
          maxLength={"13"}
          style={{ pointerEvents: "none", backgroundColor: "#eee" }}
        />
        <button className="btn white" onClick={(e) => onCertification(e)}>
          휴대폰 본인인증
        </button>
      </div>
      <p className="title_field">이름</p>
      <input
        type="text"
        name="name"
        placeholder="본인인증 후 자동으로 입력됩니다."
        value={varInputs.name}
        //onChange={(e) => onChangeHandler(e)}
        //onBlur={(e) => onAfterChangeHandler(e)}
        style={{ pointerEvents: "none", backgroundColor: "#eee" }}
      />
      <p className="title_field">생년월일</p>
      <input
        type="text"
        name="birthday"
        placeholder="본인인증 후 자동으로 입력됩니다."
        value={varInputs.birthDay}
        //onChange={(e) => onChangeHandler(e)}
        //onBlur={(e) => onAfterChangeHandler(e)}
        style={{ pointerEvents: "none", backgroundColor: "#eee" }}
      />
      <p className="title_field">이메일</p>
      <div className="input_wrap email_field">
        <input
          type="text"
          name="email1"
          id="email1"
          value={varInputs.email1}
          onChange={(e) => onChangeHandler(e)}
          onBlur={(e) => onAfterChangeHandler(e)}
        />
        <span className="text">@</span>
        <input
          type="text"
          name="email2"
          id="email2"
          value={varInputs.email2}
          onChange={(e) => onChangeHandler(e)}
        />
        <select
          name="select_email"
          onChange={(e) => onChangeMailServerHandler(e)}
        >
          <option value={""}>직접입력</option>
          <option value={"naver.com"}>naver.com</option>
          <option value={"gmail.com"}>gmail.com</option>
          <option value={"hanmail.net"}>hanmail.net</option>
          <option value={"hotmail.com"}>hotmail.com</option>
          <option value={"icloud.com"}>icloud.com</option>
        </select>
        <button className="btn white" onClick={(e) => onCheckEmailHandler(e)}>
          중복확인
        </button>
      </div>
    </div>
  );
};

export default JoinMember;
