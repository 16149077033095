/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
// import KakaoLoginButton from "react-kakao-login";
import axios from "axios";
// import api from "axios/axios";
const { Kakao } = window;

const KakaoLogin = ({ onKakaoLogin }) => {

  const REST_API_KEY = "ff6cb9d911b5ce588f8be861cb73ef15";
  const REDIRECT_URI = `https://${window.location.host}/Login`;
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

  // const clientId = "ff6cb9d911b5ce588f8be861cb73ef15";
  // const loginWithKakao = async (response) => {
  //   // console.log(response);
  //   // sessionStorage.setItem("sns_id", response.profile.id);
  //   // sessionStorage.setItem("sns_type", 2);
  //   // localStorage.setItem("sns_id", response.profile.id);
  //   // localStorage.setItem("sns_type", 2);
  //   onKakaoLogin(response.profile.id, 2);
  // };
  // const failLogin = (err) => {
  //   console.log(err);
  // };
  const getToken = async () => {


    let params = new URL(document.location.toString()).searchParams;
    let code = params.get("code");
    let grant_type = "authorization_code";
    axios
      .post(
        `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${code}`,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log(res);
        Kakao.Auth.setAccessToken(res.data.access_token);
        Kakao.API.request({
          url: "/v2/user/me",
          success: function (response) {
            console.log(response);
            onKakaoLogin(response.id, 2, '');
          },
          fail: function (error) {
            console.log(error);
          },
        });
      });
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    // <KakaoLoginButton
    //   token={clientId}
    //   onSuccess={loginWithKakao}
    //   onFail={failLogin}
    //   onLogout={console.info}
    //   useLoginForm
    //   render={(renderProps) => (
    //     <button className="btn kakao" onClick={renderProps.onClick}>
    //       {/* <img src={require("../../img/sub/member_sns02.png").default} alt="" /> */}
    //       카카오 이용하기
    //     </button>
    //   )}
    // ></KakaoLoginButton>
    <>
      <a className="btn kakao" href={KAKAO_AUTH_URL}>
        {/* <img src={require("../../img/sub/member_sns02.png").default} alt="" /> */}
        카카오 이용하기
      </a>
    </>
  );
};

export default KakaoLogin;
