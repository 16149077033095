import React,{ useEffect} from "react";

//css
import '../../css/Voucher.css';
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/Company.css';

import { resetNavSubMenu,mobileWrap } from "../../components/Common/Common";
//components
import VoucherTop from '../../components/Voucher/VoucherTop';
import VoucherList from '../../components/Voucher/VoucherList';
import VoucherInfo from '../../components/Voucher/VoucherInfo';
//이미지

/*이용권 내역*/
function VoucherBuy({ varCompanyVoucher,varVoucherList }) {

  useEffect(() => {
    resetNavSubMenu(4,0);
    mobileWrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
    <div className="p_buy_voucher">
        <VoucherTop varCompanyVoucher={varCompanyVoucher}/>
        <div className="center_wrap company company_voucher">
          <section className="page_voucher buy">
            <VoucherList varVoucherList={varVoucherList} />
            <VoucherInfo/>
          </section>
        </div>
    </div>
  );
}

export default VoucherBuy;
