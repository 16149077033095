/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import CONFIG from "../../configs/configs";
import _ from 'lodash';
import profileImage from '../../img/sub/profile.gif';
import {
  changeMobileFormat,
  ChangeDateString,
  ChangeMoneyUnitNoText,
  ChangeDateMonth,
  isMobile,
  onDownloadFile,
  numberToKorean2,
  onDownloadFilePDF,
  onDownloadUrl,
  split_1,
  split_2,
  getMonthDiff,
  getMonthDiff2,
  monthDiff2 
} from "../Common/Common";

import qrImage from "../../img/sub/qr.png";
import DownloadIcon from "img/sub/pdf_download.png";
import MainLogoIcon from "img/common/logo.png";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from 'moment';
import Loading from 'components/Common/Loading';

//2022-11-22 PDF 변환 외부 API - KSR
import ConvertApi from 'convertapi-js';


// const FileDownload = require("js-file-download");

const resumeInitializ = {
  languageStudy: [],
  resume: [],
  resumeAdditionalCareer: [],
  resumeAwards: [],
  resumeCertificate: [],
  resumeCertificationCareer: [],
  resumeEducation: [],
  resumePerfection: [],
  resumePortfolio: [],
  employment_preference: []
};

const toDay2 = moment(new Date()).format('YYYY-MM-DD');
const file_path = `/pdf/${moment(new Date()).format('YYYYMM')}`;

const ResumeViewC = ({ re_idx }) => {

  const mcm_idx = localStorage.getItem("mcm_idx");
  // 목록으로 이동
  const location = useLocation();
  const em_idx = location.state.em_idx;

  // const imgPhotoFolder = `${CONFIG.SERVER_HOST}/resume/`; //서버의 회원사진 저장 폴더
  // const imgPortfolioFolder = ""; //서버의 포트폴리오 저장 폴더
  const [varWon, setWon] = useState();
  console.log(re_idx);
  const lastYear = new Date().getFullYear() - 1;
  const [varResumeIndex] = useState(re_idx);
  const [varQRImage, setQRImage] = useState(qrImage); //QR이미지

  //희망
  const [varHopePlace, setHopePlace] = useState([]);
  const [varHopeTasks, setHopeTasks] = useState([]);
  const [varHopeCompany, setHopeCompany] = useState([]);

  const [varCheckCareer, setCheckCareer] = useState(false);//eslint-disable-line no-unused-vars
  const [varCheckAddCareer, setCheckAddCareer] = useState(false);//eslint-disable-line no-unused-vars

  //서버로부터 읽어오는 정보
  const [varResumeInfo, setResumeInfo] = useState({});
  const [varResumeEducations, setResumeEducations] = useState([]);
  const [varResumeCareers, setResumeCareers] = useState([]);
  const [varResumeAddCareers, setResumeAddCareers] = useState([]);
  const [varResumePortfolios, setResumePortfolios] = useState([]);
  const [varResumeSelfDoc, setResumeSelfDoc] = useState("");
  const [varLanguageStudy, setLanguageStudy] = useState([]);
  //고용형태 조회
  const [variableEmployType, setVariableEmployType] = useState([]);
  const [varLanguageLevel, setLanguageLevel] = useState([]);

  const [Modal_status, setModal_status] = useState(false);
  const [varResumeAwards, setResumeAwards] = useState([]);
  const [varEmployment_preference, setEmployment_preference] = useState([]);
  //employment_preference

  const [resumeData, setResumeData] = useState(resumeInitializ);
  const [Load, setLoad] = useState(false);
  const [LoadText, setLoadText] = useState('다운로드 준비중입니다.');

  const mobile = isMobile();
  const toDay = moment(new Date()).format('YYYY_MM_DD');
  //const mem_name = localStorage.getItem('mem_name');


  //PDF 다운로드 선택 항목
  const [varPdfList, setPdfList] = useState([]);
  //const [bChecked, setChecked] = useState(false);

  const [memIdx, setMemIdx] = useState(null); //이력서 주인
  const [colleagueList, setColleagueList] = useState([]); //동료 평판
  const [friendsList, setFriendsList] = useState([]); //지인 평판
  const [friendsDnYn, setFriendsDnYn] = useState(false); //지인 평판 다운로드 허용 여부

  //PDF출력 - POP - 체크박스
  const ChkList = [{ id: 0, typ: '이력서' }, { id: 1, typ: '지인평판' }, { id: 2, typ: '동료평판' }];

  const [ced, setCED]  = useState({
		certification_experience_year :'',
		certification_experience_date :''
	});

	const [aed, setAED]  = useState({
		additional_career_year :'',
		additional_career_date :''
	});

  //최초 모든 이력서 가져오기
  const getResumeInformation = async () => {
    try {
      console.log(varResumeIndex);
      const params = new URLSearchParams();
      params.append("re_idx", varResumeIndex);
      params.append("mcm_idx", mcm_idx);

      axios.get(`${CONFIG.SERVER_HOST}/resumecom/${varResumeIndex}/detailcom/web`, {})
        .then((response) => {
          const { data } = response;
          console.log(data);
          setResumeData(data);
          setMemIdx(data?.resume?.mem_idx);
        }).catch((err) => {
          const { message } = err.response.data;
          // if (_.isEmpty(message)) {
          //   alert(`처리중 문제가 발생했습니다.`);
          // } else {
          //   alert(message);
          // }
        });

      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_resume_information`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            console.log(res.data.resumeinfo[0]);
            setResumeInfo(res.data.resumeinfo[0]);
            setResumeEducations(res.data.educations);
            setResumeCareers(res.data.careers);
            setResumeAddCareers(res.data.addcareers);
            setResumePortfolios(res.data.portfolios);
            setLanguageStudy(res.data.language);
            setResumeAwards(res.data.awards);
            setEmployment_preference();
            if (res.data.selfdoc?.length > 0) {
              setResumeSelfDoc(res.data.selfdoc[0].res_comment);
            }

            //인증경력
            if (res.data.careers.length === 0) {
              setCheckCareer(true);
            }
            //추가경력
            if (res.data.addcareers.length === 0) {
              setCheckAddCareer(true);
            }
            //희망근무지
            if (
              res.data.resumeinfo[0].re_place_hope !== null &&
              res.data.resumeinfo[0].re_place_hope !== ""
            ) {
              const placeHope = res.data.resumeinfo[0].re_place_hope.split(",");
              setHopePlace(placeHope);
            }
            //희망직무
            if (
              res.data.resumeinfo[0].re_task_hope !== null &&
              res.data.resumeinfo[0].re_task_hope !== ""
            ) {
              const taskHope = res.data.resumeinfo[0].re_task_hope.split(",");
              setHopeTasks(taskHope);
            }
            //희망기업
            if (
              res.data.resumeinfo[0].re_company_hope !== null &&
              res.data.resumeinfo[0].re_company_hope !== ""
            ) {
              const companyHope =
                res.data.resumeinfo[0].re_company_hope.split(",");
              setHopeCompany(companyHope);
            }
            //QR코드 생성 --> 대표 이력서 번호 = res.data.resumeinfo[0]].re_idx
            setQRImage(qrImage);
          }
        });

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type`).then((res) => {
      setVariableEmployType(res.data);
    });
    axios.post(`${CONFIG.SERVER_HOST}/var_language_level`).then((res) => {
      setLanguageLevel(res.data);
    });
  }, []);
  useEffect(() => {
    if (varResumeIndex > 0) {
      getResumeInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //2024-06-04 지인평판 목록(존재 여부 확인 위해)
	useEffect(async () => {
		//const tgField = buttonId === "your" ? "mem_idx" : "to_mem_idx";
		const params = new URLSearchParams([
			['tg_field', 'to_mem_idx'],
			['mem_idx', memIdx]
		]);

    let friendsDnYN = false;

    //동료 평판
		const getColleagueList = async() => {
			axios.get(`${CONFIG.SERVER_HOST}/career-reputation/colleagues`, { params })
				.then((res, err) => {
					if (!err) {
						if (res.data.colleage_list?.length) {
							setColleagueList(res.data.colleage_list);
						} else {
							setColleagueList([]);
						}
					}
				}).catch((e) => {
					console.debug('Exception at getColleagueList  ', e);
				})
		};

    //지인평판 다운로드 허용 여부
    const getFriendsDownYN = async() => {
      const params = new URLSearchParams([
        ['mem_idx', memIdx]
      ]);
      //.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/download`, { params });
      axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/download`, { params }).then((res) => {
        if (res?.data?.retvalue === 1) {
          // console.debug(response.data);
          const downloadYN = res?.data?.result?.download_YN || 'N';
          setFriendsDnYn(downloadYN === 'Y' ? true : false);
          friendsDnYN = (downloadYN === 'Y' ? true : false);
        }
      });

    }

    //지인평판
    const getFriendList = async() => {
      const params = new URLSearchParams([
          ['mem_idx', memIdx]
        ]);
        axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/friends`, { params }).then((res) => {
          if (res?.data?.retvalue) {
            const result = res?.data?.friends;
            setFriendsList(result);
            console.log('getFriendList', result);
          }
        }).catch((e) => {
          console.debug(e);
        });
    };

    if(memIdx > 0) {
      await getColleagueList();
      await getFriendsDownYN();
      await getFriendList();
    }
	}, [memIdx]);


  //2022-11-22 PDF 변환 외부 API - KSR
  const make_pdf = async () => {
    const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
    const params = convertApi.createParams();
    const pdfUri = `${CONFIG.PdfBaseUri}/${resumeData?.resume.re_code}/web`;
    //const pdfUri = "https://www.heding.co.kr/PdfView/SZ1oEDlF";
    params.add('url', pdfUri);
    params.add('ConversionDelay', '3');
		params.add('MarginTop', '15');
		params.add('MarginBottom', '15');
		params.add('MarginRight', '0');
		params.add('MarginLeft', '0');
		params.add('ViewportWidth', '1400');
		params.add('ShowElements', '.myresume_view');

    console.log("pdfUri", pdfUri);

    setLoad(true);
    console.log("PDF생성을 시작합니다.");
    convertApi
      .convert('web', 'pdf', params)
      .then((result) => {
        const url = result.files[0].Url;
        console.log("PDF URL", url);
        onDownloadUrl(url, `이력서_${resumeData.resume.mem_name}_${toDay}.pdf`);
      })
      .catch((err) => {
        alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
        console.log("PDF error", err);
        setLoad(false);
      })
      .finally(() => {
        setLoad(false);
        //document.documentElement.style.cursor = 'default'
      })
  }

  //2022-10-19 PDF 다운로드(이력서, 지인평판, 동료평판) 중 선택 한것만
  //우선, '이력서'만 - KSR
  const onDownLoadPDF = async (e) => {
    //e.preventDefault();
    //이력서 PDF
    if (varPdfList.includes('이력서')) {
      close_modal();
      setLoad(true);
      console.log('이력서 다운로드');
      const PDFcss = document.querySelectorAll(".myresume_view");
      PDFcss.forEach((item) => item.classList.add('PDF'))
      const paper = document.querySelector(".myresume_view");
      const canvas = await html2canvas(paper, {
        scale: 1,
        allowTaint: true,
        useCORS: true,
      });

      const leftMargin = 10;  //좌.우 각 여백
      const topMargin = 20;   //상.하 각 여백

      //A4 : 210 * 297(세로)
      const imageFile = canvas.toDataURL('image/png', 1.0);
      PDFcss.forEach((item) => item.classList.remove('PDF'));

      const doc = new jsPDF('p', 'mm', 'a4');
      const pageWidth = 210 - (leftMargin * 2);
      const pageHeight = 297 - (topMargin * 2);

      const widthRatio = pageWidth / canvas.width;
      const customHeight = canvas.height * widthRatio;


      // doc.addImage(MainLogoIcon, 'png', 170, 10, 29, 8);//상단 로고
      doc.addImage(MainLogoIcon, 'png', 179, 8, 20, 5.555);//상단 로고

      doc.addImage(imageFile, 'png', leftMargin, topMargin, pageWidth, customHeight);
      //하단 흰색 채우기
      doc.setDrawColor(255, 255, 255);
      doc.setFillColor(255, 255, 255);
      doc.rect(0, 277, 210, 20, "F");

      let heightLeft = customHeight;  //이미지의 남은 길이
      let pageY = -pageHeight + topMargin;

      while (heightLeft >= pageHeight) {
        doc.addPage();

        doc.addImage(imageFile, 'png', leftMargin, pageY, pageWidth, customHeight);
        doc.setDrawColor(255, 255, 255);
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, 210, 20, "F");//상단 여백(백색 채우기)
        doc.rect(0, 277, 210, 20, "F");//하단 여백(백색 채우기)
        // doc.addImage(MainLogoIcon, 'png', 170, 10, 29, 8);//상단 로고
        doc.addImage(MainLogoIcon, 'png', 179, 8, 20, 5.555);//상단 로고

        heightLeft -= pageHeight;
        pageY -= pageHeight;
      }

      // if (mobile) {
      //     const fileName = `${toDay}_${resumeData.resume.mem_name}.pdf`;
      //     var a = document.createElement("a");
      //     document.body.appendChild(a);
      //     a.style = "display: none";
      //     const blob = doc.output("blob");
      //     const url = window.URL.createObjectURL(blob);
      //     a.href = url;
      //     a.download = fileName;
      //     a.click();
      //     window.URL.revokeObjectURL(url);
      // } else {
      //   // window.open(doc.output("bloburl"));

      //   var temp = doc.save(`이력서_${resumeData.resume.mem_name}_${toDay}.pdf`);

      //   var post = new XMLHttpRequest();
      //   post.open("POST", "/pdf");
      //   post.send(temp);
      // }

      const fileName = `${toDay}_${resumeData.resume.mem_name}_이력서.pdf`;
      const formData = new FormData();
      const pdf = new File([doc.output("blob")], `${fileName}`, {
        type: "application/pdf",
      });

      formData.append("pdf", pdf);

      await axios
        .post(`${CONFIG.SERVER_HOST}/pdf`, formData)
        .then((res) => {
          console.log(res.data); //성공 or 실패에 대한 메시지 방출
          const file_name = res.data.filename;
          if (!window.ReactNativeWebView) {
            onDownloadFilePDF('pdf', `${file_name}`, `${fileName}`);
            setLoad(false);
          } else {
            //앱으로 전달
            const params = {
              file_name,
              file_path
            };
            const paramsToString = JSON.stringify(params);
            window.ReactNativeWebView.postMessage(paramsToString);
            setLoad(false);
          };
        })
        .catch((error) => {
          console.log(error);
          alert("파일 저장 실패!");
          // 예외 처리
        });

      // if (!window.ReactNativeWebView) {
      //   setLoad(false);
      // }

    }//끝. 이력서 PDF

  };

  //PDF다운로드-POP : 체크박스 선택
  const onCheckHandler = (checked, item) => {
    if (checked) {
      setPdfList([...varPdfList, item]);
    } else if (!checked) {
      setPdfList(varPdfList.filter(el => el !== item));
    }
  };


  const open_modal = (e) => {
    e.preventDefault();
    console.log("오픈");
    setModal_status(true);
  };

  const close_modal = () => {
    // e.preventDefault();
    console.log("클로즈");
    setModal_status(false);
  };

  //언어레벨 세팅 함수
  const getLanguageCode = (level) => {
    const LanguageLevel = varLanguageLevel.filter((language) => language.lal_code === Number(level));
    return LanguageLevel[0]?.lal_name;
  }

  // 사이트 링크 클릭.
  const handleSiteListClick = () => {
    //window.location.href = resumeData.resume.re_site;
    //새탭에서 열기
    window.open(`${varResumePortfolios.resume.re_site}`);
  }

  const text = (re_employ_hope) => {
    const textList = variableEmployType.filter((el) => el.emt_idx === re_employ_hope);

    console.log(textList);
    return !textList[0]?.emt_name ? '없음' : textList[0]?.emt_name;
  }


  useEffect(() => {

    console.log(typeof resumeData.resume.re_income_hope);
    console.log(resumeData.resume.re_income_hope);
    if (resumeData.resume.re_income_hope > 0) {
      const won = numberToKorean2(resumeData.resume.re_income_hope);
      setWon(won.indexOf('원') === -1 ? `${!won ? 0 : won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원` : `${won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
    } else {
      setWon('-');
    }

  }, [resumeData]);

  //다운로드 로딩화면 문구 세팅 시작
  let count = 0;
  useEffect(() => {
    if (Load) {
      const tick = setInterval(() => {
        if (Load === true) {
          if (count === CONFIG.DownLoadTextArray.length) {
            count = 0;
          }
          setLoadText(CONFIG.DownLoadTextArray[count]);
          count++;
        }
      }, 2000);

      return () => clearInterval(tick);
    } else {
      count = 0;
    }
    return undefined;
  }, [Load]);
  //다운로드 로딩화면 문구 세팅 끝

  useEffect(()=>{
		if(resumeData.resume){
			if(resumeData.resumeCertificationCareer.length > 0){
				if(!resumeData?.resume.certification_experience_year && !resumeData?.resume.certification_experience_date){
					monthDiff_1(resumeData.resumeCertificationCareer);
				}
			}
			if(resumeData.resumeAdditionalCareer.length > 0){
				if(!resumeData?.resume.additional_career_year && !resumeData?.resume.additional_career_date){
					monthDiff_2(resumeData.resumeAdditionalCareer);
				}
			}
		}
	}, [resumeData.resume]);
	

	const monthDiff_1 = (arr) => {
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rec_sdate !== null){
				const data1 = item.rec_sdate.split('-');
				const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        console.log('t1 : ', item.rec_sdate, item.rec_edate, t1);
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

    console.log(quotient+"."+remainder);
			setCED({
				certification_experience_year : quotient,
				certification_experience_date : remainder
			});
  };

	const monthDiff_2 = (arr) => {
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rac_sdate !== null){
				const data1 = item.rac_sdate.split('-');
				const data2 = item.rac_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rac_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        console.log('t1 : ', item.rac_sdate, item.rac_edate, t1);
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

    console.log(quotient+"."+remainder);
		setAED({
			additional_career_year : quotient,
			additional_career_date : remainder
		});

  };

  //2024-06-01 동료평판(PDF) 다운로드 외부 API 사용 - KSR
  const handleClickRepuDnPdf = async () => {
    console.log('handleClickRepuDnPdf');
    const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
    const params = convertApi.createParams();
    const pdfUri = `${CONFIG.PdfReputation1Uri}/${resumeData.resume.mem_idx}`;
    params.add('url', pdfUri);
    params.add('ConversionDelay', '3');
    params.add('MarginTop', '15');
    params.add('MarginBottom', '15');
    params.add('MarginRight', '0');
    params.add('MarginLeft', '0');
    params.add('ViewportWidth', '1400');
    //params.add('ShowElements', '.container');

    console.log("pdfUri", pdfUri);

    setLoad(true);
    console.log("PDF생성을 시작합니다.");
    convertApi
      .convert('web', 'pdf', params)
      .then((result) => {
        const url = result.files[0].Url;
        console.log("PDF URL", url);
        if (!window.ReactNativeWebView) {
          onDownloadUrl(url, `동료평판_${resumeData.resume.mem_name}_${toDay}.pdf`);
        } else {
          //앱으로 전달
          const file_name = `동료평판_${resumeData.resume.mem_name}_${toDay}.pdf`;
          const file_url = url;
          const params = {
            file_name,
            file_url
          };
          const paramsToString = JSON.stringify(params);
          window.ReactNativeWebView.postMessage(paramsToString);
        }
      })
      .catch((err) => {
        alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
        console.log("PDF error", err);
        setLoad(false);
      })
      .finally(() => {
        setLoad(false);
        //document.documentElement.style.cursor = 'default'
      })
  }

  //2024-06-02 지인평판(PDF) 다운로드 외부 API 사용 - KSR
  const handleClickRepuDnPdf2 = async () => {
    console.log('handleClickRepuDnPdf');
    const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
    const params = convertApi.createParams();
    const pdfUri = `${CONFIG.PdfReputation2Uri}/${resumeData?.resume?.mem_idx}`;
    params.add('url', pdfUri);
    params.add('ConversionDelay', '6');
    params.add('MarginTop', '15');
    params.add('MarginBottom', '15');
    params.add('MarginRight', '0');
    params.add('MarginLeft', '0');
    params.add('ViewportWidth', '1400');
    //params.add('ShowElements', '.container');

    console.log("pdfUri", pdfUri);

    setLoad(true);
    console.log("PDF생성을 시작합니다.");
    convertApi
      .convert('web', 'pdf', params)
      .then((result) => {
        const url = result.files[0].Url;
        console.log("PDF URL", url);
        if (!window.ReactNativeWebView) {
          onDownloadUrl(url, `지인평판_${resumeData?.resume?.mem_name}_${toDay}.pdf`);
        } else {
          //앱으로 전달
          const file_name = `지인평판_${resumeData?.resume?.mem_name}_${toDay}.pdf`;
          const file_url = url;
          const params = {
            file_name,
            file_url
          };
          const paramsToString = JSON.stringify(params);
          window.ReactNativeWebView.postMessage(paramsToString);
        }
      })
      .catch((err) => {
        alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
        console.log("PDF error", err);
        setLoad(false);
      })
      .finally(() => {
        setLoad(false);
        //document.documentElement.style.cursor = 'default'
      })
  }

  return (
    <>
      {Load === true ? <Loading text={LoadText} /> : null}
      <main id="sub_wrap" className="page_myresume_view">
        <div className="center_wrap company">
          <section className="page_announcement view_sec support_carrer">
            <h2 className="sub_content_title indent">공고/지원자 관리</h2>
            <ul className="tab01 blue col2">
              <li>
                <Link to={"/Company/comAnnouncement/AnnouncementList"}>
                  공고관리
                </Link>
              </li>
              <li className="active">
                <Link
                  to={"/Company/comAnnouncement/SupportList"}
                  state={{ ComAnnouncement: 1 }}
                >
                  지원자 관리
                </Link>
              </li>
            </ul>
            <div className="pdf_down_wrap">
              {/* 2022-11-23 기존 PDF 다운 사용 안함. 하기 외부 API로 변경 - KSR
              <button
                type="button"
                className="btn_excel_download"
                onClick={(e) => open_modal(e)}
              >
                PDF 다운로드
                <img src={DownloadIcon} alt="" />
              </button>&nbsp;
              */}
              <button type="button" className="btn_excel_download"
                onClick={(e) => make_pdf(e)}>
                이력서 다운로드
                <img src={DownloadIcon} alt="" />
              </button>&nbsp;

              {colleagueList?.length > 0 && 
                <>
                  <button type="button" className="btn_excel_download"
                    onClick={() => handleClickRepuDnPdf()}>
                    동료평판 다운로드
                    <img src={DownloadIcon} alt="" />
                  </button>&nbsp;
                </>
              }

              {friendsList?.length > 0 && friendsDnYn &&
                <>
                  <button type="button" className="btn_excel_download"
                    onClick={() => handleClickRepuDnPdf2()}>
                    지인평판 다운로드
                    <img src={DownloadIcon} alt="" />
                  </button>&nbsp;
                  </>
              }
            </div>
          </section>
        </div>
        <div id="resume_page" className="myresume_view center_wrap">
          {/* <section className="resume_title">
					<h2 className="title_field">{resumeData.resume.re_title}</h2>
					{type === 'web' ?
						<p className="qr"><img className="qr-image" src={varQRCode} alt="" /></p>
						:
						null
					}
				</section> */}
          <section className="resume_title">
            <article className="info_area">
						  <h2 className="title_field">{resumeData.resume.re_title}</h2>
            </article>
					</section>
          <section className="profile_area">
            <article className="profile_img" >
              <img src={(resumeData.resume.re_photo === 0 || resumeData.resume.re_ufile === undefined ? profileImage : `${CONFIG.SERVER_HOST}/resume/${resumeData.resume.re_ufile}`)} alt="이력서 대표사진" />
            </article>
            {/* ▼▼▼ 작은 이미지 확인용 */}
            {/* <article className="profile_img" style={{backgroundImage:"url(https://picsum.photos/100/100/?image=58)"}}>
						</article> */}
            <div className="right_wrap">
              <p className="name_field">{resumeData.resume.mem_name}</p>
              <article className="info_area">
                <ul>
                  <li><span className="title_field">생년월일</span>{ChangeDateString(resumeData.resume.re_birthday)}</li>
                  <li><span className="title_field">이메일</span>{resumeData.resume.re_email}</li>
                  <li><span className="title_field">근로소득[{resumeData.resume.re_income_lyear === null ? lastYear : resumeData.resume.re_income_lyear}]</span>&nbsp;{
                    resumeData.resume.re_income_last === 0 ? "-" : ChangeMoneyUnitNoText(resumeData.resume.re_income_last)}
                  </li>
                </ul>
                <ul>
                  <li><span className="title_field">성별</span>{resumeData.resume.re_gender === 0 ? "남성" : "여성"}</li>
                  <li><span className="title_field">연락처</span>{changeMobileFormat(resumeData.resume.mem_mobile)}</li>
                  <li><span className="title_field">현재연봉</span>&nbsp;{resumeData.resume.re_income_this === 0 ? "-" : ChangeMoneyUnitNoText(resumeData.resume.re_income_this)}</li>
                </ul>
              </article>
            </div>
            <p className="tip_field c_red">※ 근로소득은 국세청 원천징수영수증 기준으로 인증된 금액입니다.</p>
          </section>
          {resumeData.resumeEducation.length > 0 ?
            <section className="section school">
              <h3 className="title_area">학력</h3>
              {resumeData.resumeEducation.map((education, index) => (
                <article className="info_area" key={'edu_' + index}>
                  <p className="title_field">{education?.reh_name} <span className="part_field">{education?.reh_major}  {education?.reh_level === 10 ? CONFIG.Master : education?.reh_level === 11 ? CONFIG.Doc : ''}</span></p>
                  <p className="date_field">{education?.reh_state === -2 ? "" : ChangeDateMonth(education?.reh_sdate, 1)}{education?.reh_edate === null ? ""
                    : " ~ " + ChangeDateMonth(education?.reh_edate, 1)}
                    {education?.reh_state === -2 ? "" :
                      ((education?.reh_name === null || education?.reh_major === null) ? "" : education?.reh_state === 1 ? " (졸업)" : education?.reh_state === 2 ? " (졸업예정)" : education?.reh_state === 3 ? " (재학중)" : education?.reh_state === 4 ? " (중퇴)" : education?.reh_state === 5 ? " (수료중)" : education?.reh_state === 6 ? " (휴학)" : "")
                    }
                  </p>
                </article>
              ))}
            </section>
            : null}
          {resumeData.resumeCertificationCareer.length ?
            <section className="section sec_career">
              <p className="tip_field c_red">
                ※ 인증경력은 국민건강보험공단 자격득실확인서 기준으로 인증된 경력입니다.
              </p>
              {/* {resumeData?.re_career_year ?
                  <p className="align_end c_red total_career_text">
                    총 경력 : {split_1(resumeData?.re_career_year)}년{" "}{split_2(resumeData?.re_career_year) === undefined ? "" : split_2(resumeData?.re_career_year) + "개월"}
                  </p>
                :
                null
              } */}
              							{ced.certification_experience_year || ced.certification_experience_date ?
							<p className="align_end c_red total_career_text">
								총 경력 : {`${ced.certification_experience_year} 년 `}{`${ced.certification_experience_date}개월`}
							</p>
							:
							resumeData?.resume.certification_experience_year || resumeData?.resume.certification_experience_date ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {`${resumeData?.resume.certification_experience_year} 년 `}{`${resumeData?.resume.certification_experience_date}개월`}
                </p>
								:
								null
							}
              <h3 className="title_area">인증 경력</h3>
              {resumeData.resumeCertificationCareer.length > 0 && resumeData.resumeCertificationCareer.map((career, index) => {
                const recDetail = [];
                let tmpLoop = [1, 2, 3, 4, 5];
                const tempCareer = Object.assign({}, career);
                tmpLoop.forEach(function (value) {
                  if (!_.isEmpty(tempCareer[`rec_note${value}`])) {
                    recDetail.push({
                      note: tempCareer[`rec_note${value}`],
                      detail: tempCareer[`rec_detail${value}`],
                    });
                  }
                });
                return (
                  <article className="info_area" key={"career_" + index}>
                    <p className="title_field">
                      {career?.rec_company}
                      <span className="part_field">
                        {!career?.rec_department || career?.rec_department === 'null' ? '' : career?.rec_department} {!career?.rec_position || career?.rec_position === 'null' ? '' : career?.rec_position}
                      </span>
                    </p>
                    <p className="date_field">
                    <span className="left_wrap">{ChangeDateMonth(career?.rec_sdate, 1) + ' ~ ' + ChangeDateMonth(career?.rec_edate, 1)}
                      {career?.rec_state === 1 ? " (재직중)" : " (퇴사)"}</span>
                      {career?.rec_sdate ?
                        <span className="align_end">
                          {getMonthDiff2(career?.rec_sdate, career?.rec_state === 1 ? toDay2:career?.rec_edate)}
                        </span>
                      :
                        null
                      }
                    </p>
                    {recDetail.map((elements, ii) => {
                      return (elements.note === null || elements.note === '' ? null :
                        <div key={"careerdes_" + index + "_" + ii} className="career_detail">
                          <p className="subtitle_field">{elements.note}</p>
                          {/* <p className="text">{elements.detail===null||elements.detail===''?'':'(업무 상세 설명)'+elements.detail}</p> */}
                        </div>
                      )
                    })}
                  </article>
                );
              })}
            </section>
            : null}
          {resumeData.resumeAdditionalCareer.length ?
            <section className="section">
              {/* {resumeData?.re_careeradd_year ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {split_1(resumeData?.re_careeradd_year)}년{" "}{split_2(resumeData?.re_careeradd_year) === undefined ? "" : split_2(resumeData?.re_careeradd_year) + "개월"}
                </p>
                :
                null
              } */}
              {aed.additional_career_year || aed.additional_career_date ?
								<p className="align_end c_red total_career_text">
									총 경력 : {`${aed.additional_career_year} 년 `}{`${aed.additional_career_date}개월`}
								</p>
							:
							resumeData?.resume.additional_career_year || resumeData?.resume.additional_career_date ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {`${resumeData?.resume.additional_career_year} 년 `}{`${resumeData?.resume.additional_career_date}개월`}
                </p>
								:
								null
							}
              <h3 className="title_area">추가 경력</h3>
              {resumeData.resumeAdditionalCareer.map((career, index) => {
                const recDetail = [];
                let tmpLoop = [1, 2, 3, 4, 5];
                const tempCareer = Object.assign({}, career);
                tmpLoop.forEach(function (value) {
                  if (!_.isEmpty(tempCareer[`rac_note${value}`])) {
                    recDetail.push({
                      note: tempCareer[`rac_note${value}`],
                      detail: tempCareer[`rac_detail${value}`],
                    });
                  }
                });
                return (
                  <article className="info_area" key={"careeradd_" + index}>
                    <p className="title_field">{career?.rac_company}<span className="part_field">{career?.rac_department + ' ' + career?.rac_position}</span></p>
                    <p className="date_field">
                      <span className="left_wrap">
                        {ChangeDateMonth(career?.rac_sdate, 1) + `${career?.rac_edate ? ` ~ ` : ``}` + ChangeDateMonth(career?.rac_edate, 1)}
                        {career?.rec_state === 1 ? " (재직중)" : " (퇴사)"}
                      </span>
                      {career?.rac_sdate ?
                        <span className="align_end">
                          {getMonthDiff(career?.rac_sdate, career?.rac_state === 1 ? toDay2:career?.rac_edate)}
                        </span>
                      :
                        null
                      }

                    </p>
                    {recDetail.map((elements, ii) => {
                      return (elements.note === null || elements.note === '' ? null :
                        <div key={"careeradddes_" + index + "_" + ii}>
                          <p className="subtitle_field">{elements.note}</p>
                          {/* <p className="text">{elements.detail===null||elements.detail===''?'':'(업무 상세 설명)'+elements.detail}</p> */}
                        </div>
                      )
                    })}
                  </article>
                )
              })}
            </section>
            : null}
          {resumeData.languageStudy.length ?
            <section className="section">
              <h3 className="title_area">어학</h3>
              {resumeData.languageStudy.map((study, index) => (
                <article className="info_area" key={'edu_' + index}>
                  <p className="title_field">{study.language_name} <span className="part_field">{getLanguageCode(study.language_level)}</span></p>
                </article>
              ))}
            </section>
            : null}
          {resumeData.resumeCertificate.length ?
            <section className="section">
              <h3 className="title_area">자격증</h3>
              {resumeData.resumeCertificate.map((element, index) => (
                <article className="info_area" key={'edu_' + index}>
                  <p className="title_field">{element.rc_name} <span className="part_field">{element.rc_issuer}</span></p>
                  <p className="date_field">{element?.rc_gdate === null ? "" : ChangeDateMonth(element?.rc_gdate, 1)}
                  </p>
                </article>
              ))}
            </section>
            : null}
          {resumeData.resumeAwards.length ?
            <section className="section">
              <h3 className="title_area">수상이력</h3>
              {resumeData.resumeAwards.map((element, index) => (
                <article className="info_area" key={'edu_' + index}>
                  {console.log("11 ", element.ra_comment)}
                  <p className="title_field">{element.ra_comment} <span className="part_field">{element.ra_issuer === "NULL" || element.ra_issuer === null ? "" : element.ra_issuer}</span></p>
                  <p className="date_field">{element?.ra_gdate === null ? "" : ChangeDateMonth(element?.ra_gdate, 1)}
                  </p>
                </article>
              ))}
            </section>
            : null}
          {resumeData.resume.re_career_description ?
            <section className="section">
              <h3 className="title_area">경력기술서</h3>
              <article className="info_area text-lines ql-editor">
                <div dangerouslySetInnerHTML={{ __html: resumeData.resume?.re_career_description }}></div>
              </article>
            </section>
            : null}
          {resumeData.resume.re_comment ?
            <section className="section">
              <h3 className="title_area">자기소개서</h3>
              <article className="info_area text-lines ql-editor">
                <div dangerouslySetInnerHTML={{ __html: resumeData.resume.re_comment }}></div>
              </article>
            </section>
            : null}
          {resumeData.resumePortfolio.length || resumeData.resume.re_site ?
            <section className="section portfolio">
              <h3 className="title_area">포트폴리오</h3>
              <article className="info_area">
                <p><span className="title_cell">첨부파일</span>
                  {resumeData.resumePortfolio.map((portfolio, index) => (
                    <button key={"port_" + index} onClick={() => onDownloadFile('port_folio', portfolio.rcp_ufile)} className="link">{portfolio.rcp_ufile}</button>
                  ))}
                </p>
                <p><span className="title_cell">사이트 링크</span><button onClick={() => handleSiteListClick()} style={{ fontSize: '15px', marginLeft: '10px', cursor: 'pointer' }}>{resumeData.resume.re_site}</button></p>
              </article>
            </section>
            : null}
          <section className="section work_condition">
            <h3 className="title_area">근무희망조건</h3>
            <article className="info_area">
              <p><span className="title_field2">고용형태</span> : {text(resumeData.resume.re_employ_hope)}</p>
              <p><span className="title_field2">희망연봉</span> : {resumeData.resume.re_after_meet === 1 ? "면접후 결정" : varWon}</p>
              {/* <p><span className="title_field2">희망 근무지</span> : {resumeData.resume?.re_place_hope ? resumeData.resume?.re_place_hope?.replaceAll(/,/g, ', ') : "선택안함"}</p>
              <p><span className="title_field2">희망 직종</span> : {resumeData.resume?.re_task_hope ? resumeData.resume?.re_task_hope?.replaceAll(/,/g, ', ') : "선택안함"} </p>
              <p><span className="title_field2">관심 기업</span> : {resumeData.resume?.re_company_hope ? resumeData.resume?.re_company_hope?.replaceAll(/,/g, ', ') : "선택안함"}</p> */}
              <p><span className="title_field2">희망 근무지</span> : {resumeData.resume?.re_place_hope ? resumeData.resume?.re_place_hope?.replaceAll(/,/g, ', ') : "-"}</p>
              <p><span className="title_field2">희망 직종</span> : {resumeData.resume?.re_task_hope ? resumeData.resume?.re_task_hope?.replaceAll(/,/g, ', ') : "-"} </p>
              <p><span className="title_field2">관심 기업</span> : {resumeData.resume?.re_company_hope ? resumeData.resume?.re_company_hope?.replaceAll(/,/g, ', ') : "-"}</p>
            </article>
          </section>
          {resumeData.employment_preference?.length ?
            <section className="section">
              <h3 className="title_area">취업우대사항</h3>
              {resumeData.employment_preference.map((element, index) => (
                <article className="info_area" key={'edu_' + index}>
                  <p className="title_field"> {element.vcep_content} <span className="part_field">{element.content}</span></p>
                  {/* <p className="date_field">{element?.ra_gdate === null ? "" : ChangeDateMonth(element?.ra_gdate, 1)}
								</p> */}
                </article>
              ))}
            </section>
            : null}
        </div>
        <div className="btn_bottom_area center_wrap one">
          <Link
            to={"/Company/ComAnnouncement/SupportView"}
            className="btn line"
            state={{ em_idx: em_idx }}
          >
            목록으로
          </Link>
        </div>
      </main>
      <section
        id="popCareerDownload"
        className="popup"
        style={
          Modal_status === true ? { display: "block" } : { display: "none" }
        }
      >
        {/***** POP - 다운로드(선택) ******************************************************/}
        <article className="popup_wrap popup01">
          <div className="title_wrap">
            <h1>헤딩 - PDF 다운로드</h1>
          </div>
          <div className="content_wrap">
            <ul>
              {ChkList.map(item => {
                return (
                  <li key={item.id}>
                    <input type="checkbox" className="chk" value={item.typ} id={`career${item.id}`}
                      onChange={e => { onCheckHandler(e.target.checked, e.target.value) }}
                      checked={varPdfList.includes(item.typ) ? true : false} />
                    <label htmlFor={`career${item.id}`}>{item.typ}</label>
                  </li>
                )
              })}
              {/*
              <li>
                <input type="checkbox" className="chk" id="career1" value="a" onChange={(e) => checkHandler(e)} />
                <label htmlFor="career1">이력서</label>
              </li>
              <li>
                <input type="checkbox" className="chk" id="career2" value="b" onChange={(e) => checkHandler(e)} />
                <label htmlFor="career2">지인평판</label>
              </li>
              <li>
                <input type="checkbox" className="chk" id="career3" value="c" onChange={(e) => checkHandler(e)} />
                <label htmlFor="career3">동료평판</label>
              </li>
            */}
            </ul>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <button className="btn" onClick={(e) => onDownLoadPDF(e)}>선택 항목 다운로드</button>
            </div>
            <div className="close_button_wrap">
              <button onClick={() => close_modal()}>닫기 X</button>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default ResumeViewC;
