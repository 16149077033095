/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import axios from 'axios'
import CONFIG from '../../configs/configs'
import Paging from '../Layout/Paging'
import { useNavigate, createSearchParams } from "react-router-dom";
import CertificationCareerView from './CertificationCareer';
import AddCareerView from './AddCareer';
//image
import showResumeImage from "../../img/sub/icon_arrow.png";
import ScrollToTop from 'components/Layout/ScrollToTop';
import moment from 'moment';

const SerachResultC = ({
  setShowPopupCandidate,
  onViewResumePage,
  searchCategoryParams,
  searchString,
  setResumeIndex,
  param3,
  param4,
  param5,
  setLoad,
  setLoadText
}) => {
  const focusRef = useRef();
  const navigate = useNavigate();
  const mcm_idx = Number(localStorage.getItem('mcm_idx'));
  const mcm_name = localStorage.getItem('mcm_name');
  const mcm_user_idx = Number(localStorage.getItem('mem_idx'));
  //서버의 저장폴더
  const ImageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(5);
  const [varPageNum, setPageNum] = useState(0);
  const [varResumeResultLists, setResumeResultLists] = useState([]);

  const getCategoryResumeResultLists = useCallback(async () => {
    try {
      //ScrollToTop();
      const params = {
        pageNum: param5,
        pageSize: varPageSize,
        mcm_idx: mcm_idx,
        mcm_name: mcm_name,
        mcm_user_idx: mcm_user_idx,
        searchString: JSON.parse(searchString),
        searchCategory: JSON.parse(searchCategoryParams),
      }
      setLoad(true);
      setLoadText('인재를 검색하고 있습니다.')
      await axios.post(`${CONFIG.SERVER_HOST}/_get_competent_person`, params, CONFIG.header).then((res) => {

        console.log(res.data);
        if (res.data.list) {
          const list = res.data.list.map((e) => {
            const tempName = e.rec_company ? e.rec_company : e.rac_company ? e.rac_company : '';
            return {
              ...e,
              rec_company_name: tempName.split(',')[0]
            };
          });
          setResumeResultLists(list); //원본
          setTotalCount(res.data.totalcount);
        } else {
          setResumeResultLists([]); //원본
          setTotalCount(0);
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoad(false);
  }, [searchCategoryParams, searchString, param5, varPageSize, mcm_idx, mcm_name, setLoad, setLoadText, mcm_user_idx]);
  



  useEffect(() => {
    getCategoryResumeResultLists();
  }, [getCategoryResumeResultLists]);

  const onAddCandidate = (e, res_idx) => {
    e.preventDefault();
    setShowPopupCandidate('popup active');
    setResumeIndex(res_idx);
  }

  useEffect(() => {
    if (param5 !== null && varPageNum) {
      navigate({
        pathname: "/Company/ComResource/SearchMain",
        search: createSearchParams({
          param1: `${searchString}`,
          param2: `${searchCategoryParams}`,
          param3: `${param3}`,
          param4: `${param4}`,
          param5: `${JSON.stringify(varPageNum)}`,
        }).toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum]);

  const ShowView =(certification_experience_year , certification_experience_date, highLookup)=>{
    if(certification_experience_year > 0){
      return  <CertificationCareerView highLookup={highLookup} />;
    }else{
      if(certification_experience_date > 0){
        return  <CertificationCareerView highLookup={highLookup} />;
      }else{
        return <AddCareerView highLookup={highLookup} />;
      }
    }
  };

  const [position, setPosition] = useState(0);
  function onScroll() {
    setPosition(window.scrollY);
  }
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []); 

  useEffect(()=>{
    if(varResumeResultLists.length > 0){
      if(sessionStorage.getItem('key')){
        const key = sessionStorage.getItem('key');
        focusRef.current.children[key]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        sessionStorage.removeItem('key');
      }
    }
  }, [varResumeResultLists]);


  return (
    <section className="talent_result center_wrap">
      <ScrollToTop position={position}/>
      <h3 className="title_area">
        인재 검색결과
        <p className="total">총 <span className="point">{varTotalCount}</span> 명의 인재가 검색 되었습니다.</p>
      </h3>
      <div id="items" ref={focusRef}>
      {varResumeResultLists.length > 0 ? varResumeResultLists.map((searchResult, index) => (
        <article className={`item`} id={`key_${index}`} key={'sr_' + index}>
          <div className="btn_area">
            <a href={() => false} className="btn white" onClick={(e) => onAddCandidate(e, searchResult.re_idx)}>후보자 추가하기</a>
            <a href={() => false} className="btn gray" onClick={(e) => {
              sessionStorage.setItem('key', index);
              onViewResumePage(e, searchResult.re_idx)}}>이력서 보기<img src={showResumeImage} alt="" /></a>
          </div>
            
          <div className="profile">
            {searchResult.mem_photo === 0 ? <p className="img"></p>
              : <p className="img" style={{ backgroundImage: `url(${ImageFoler + searchResult.mem_ufile})` }}></p>
            }
          </div>
          
          <div className="info_area">
            <p className="name">
              {searchResult.mem_name}
              {/* <span className="text">{searchResult.re_move_hope===0?'(이직 : 의향 없음)':'(이직 : 의향 있음)'}</span> */}
            </p>
            
            <ul className="info_field">
              <li className="career">
                {/* {
                  !searchResult?.certification_experience_year && !searchResult?.certification_experience_year ?
                    <AddCareerView highLookup={searchResult} />
                    :
                    <CertificationCareerView highLookup={searchResult} />
                } */}
                {/* {resetCareerForSearching(searchResult.re_career_year)} */}
                {
                  ShowView(searchResult?.certification_experience_year, searchResult?.certification_experience_date, searchResult)
                }
              </li>
              {searchResult.rec_company_name ?
                <><li className="company">{searchResult.rec_company_name}</li></>
                : null
              }
              {searchResult.rec_department ?
                <><li>{searchResult.rec_department}&nbsp;{searchResult.rec_position}</li></>
                : null
              }
              {searchResult.reh_name ?
                <li className="school">{searchResult.reh_name}&nbsp;{searchResult.reh_major}</li>
                : null
              }

            </ul>
            
          </div>
          <div className='write_date'>
          등록일 : {searchResult.re_udate ? moment(searchResult.re_udate).format('YYYY-MM-DD') : moment(searchResult.re_rdate).format('YYYY-MM-DD')}
            
          </div>
          <ul className="add_info_area">
            <li><span className="title">희망 근무지</span> : {searchResult.re_place_hope === null ? "없음" : searchResult.re_place_hope.replaceAll(',', ', ')}</li>
            <li><span className="title">관심 기업</span> : {searchResult.re_company_hope === null ? "없음" : searchResult.re_company_hope.replaceAll(',', ', ')}</li>
          </ul>
        </article>
      ))
        :
        <article className="item">
          검색된 인재가 없습니다.
        </article>
      }
      </div>
      <div className="paging">
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum === 0 ? Number(param5) : varPageNum !== Number(param5) ? param5 : varPageNum} setPage={setPageNum} />
      </div>
    </section>
  )
}

export default SerachResultC
