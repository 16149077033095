import React, { useEffect, useState } from "react";

//css
import "css/Board.css";
import "css/My.css";

import { resetNavSubMenu } from "../../components/Common/Common";
import MyTalkList from '../../components/My/MyTalkList';

function MyHeding12({ getSubItem }) {
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  useEffect(() => {
    getSubItem(12);
    if(mem_mode=== 0){
      resetNavSubMenu(4,6);
    }else{
      resetNavSubMenu(2,6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [varReloading, setReloading] = useState(true);

  const onSetPopup = (e, bpop) => {
    e.preventDefault();
  }

  return (
    <div className="container page_suggest">
      <MyTalkList onSetPopup={onSetPopup} varReloading={varReloading} setReloading={setReloading} />
    </div>
  );
}

export default MyHeding12;