/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { IfNotNull, addThousandPoint } from "../Common/Common";
import deleteImage01 from '../../img/sub/btn_delete01.png';
import addLineBtnImage from "../../img/sub/btn_plus_blue.png";
import axios from "axios";
import CONFIG from '../../configs/configs';
import EmploymentPreference from './EmploymentPreferenceHistory';

function WorkingCondition({varEmploymentPreference, setEmploymentPreference, varInputs, setInputs, setPopJob, setPopTown, setPopCompany, onChangeHandler, careerTipList }) {
	const [varHopePlace, setHopePlace] = useState([]);
	const [varHopeTask, setHopeTask] = useState([]);
	const [varHopeCompany, setHopeCompany] = useState([]);
	const [varEPNum, setEPNum] = useState(0);

	useEffect(() => {
		if (varInputs?.after_meet === 1) {

			setInputs({
				...varInputs,
				income_hope: 0
			})
			//varInputs?.income_hope
		}

	}, [varInputs?.after_meet]);


	useEffect(() => {
		if (IfNotNull(varInputs?.place_hope)) {
			setHopePlace(varInputs?.place_hope.split(','));
		}
		if (IfNotNull(varInputs?.task_hope)) {
			setHopeTask(varInputs?.task_hope.split(','));
		}
		if (IfNotNull(varInputs?.company_hope)) {
			setHopeCompany(varInputs?.company_hope.split(','));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [varInputs?.place_hope, varInputs?.task_hope, varInputs?.company_hope]);

	//희망근무지 삭제
	const onDeleteHopePlaceHandler = (e, place) => {
		e.preventDefault();
		const newList = varHopePlace.filter(element => element !== place);
		setHopePlace(newList);
		const varHopePlaceString = newList.join(',');
		setInputs({ ...varInputs, place_hope: varHopePlaceString });
	}
	//희망직무 삭제
	const onDeleteHopeTaskHandler = (e, task) => {
		e.preventDefault();
		const newList = varHopeTask.filter(element => element !== task);
		setHopeTask(newList);
		const varHopeTaskString = newList.join(',');
		setInputs({ ...varInputs, task_hope: varHopeTaskString });
	}
	//관심 기업 삭제
	const onDeleteHopeCompanyHandler = (e, company) => {
		e.preventDefault();
		const newList = varHopeCompany.filter(element => element !== company);
		setHopeCompany(newList);
		const varHopeCompanyString = newList.join(',');
		setInputs({ ...varInputs, company_hope: varHopeCompanyString });
	}
	const onSetPopTown = (e, stext) => {
		e.preventDefault();
		if (varHopePlace.length >= 3) {
			alert('최대 3개의 근무지를 추가할 수 있습니다.');
			return false;
		}
		setPopTown(stext);
	}
	const onSetPopJob = (e, stext) => {
		e.preventDefault();
		if (varHopeTask.length >= 3) {
			alert('최대 3개의 직무를 추가할 수 있습니다.');
			return false;
		}
		setPopJob(stext);
	}
	const onSetPopCompany = (e, stext) => {
		e.preventDefault();
		if (varHopeCompany.length >= 3) {
			alert('최대 3개의 관심기업을 추가할 수 있습니다.');
			return false;
		}
		setPopCompany(stext);
	}

	//고용형태 조회
	const [variableEmployType, setVariableEmployType] = useState([]);
	//취업우대사항 조회
	const [varCompEmploymentPreference, setvarCompEmploymentPreference] = useState([]);

	useEffect(() => {
		
		axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type`).then((res) => {
			setVariableEmployType(res.data);
		});

		axios.post(`${CONFIG.SERVER_HOST}/variable_comp_employment_preference`).then((res) => {
			setvarCompEmploymentPreference(res.data);
		});
	}, []);


	//setEmploymentPreference
	const setEmploymentPreferenceList = ()=>{

		let num = varEmploymentPreference.length> 0 ? varEmploymentPreference.length:varEPNum;
		const newData = {idx : num ,vcep_idx : 0, content: ''};
		setEPNum(num+1);
		setEmploymentPreference([...varEmploymentPreference, newData]);
	}

  //추가경력 작성 TIP
  let copyData = [...careerTipList]
  const tipData = copyData.filter((el) => el.career_order === 7)

	return (
		<>
			<section className="section work_condition">
				<p className="title_area">근무희망조건</p>
				<div className="input_area">
					<select className={"style_select"+(varInputs?.employ_hope ? ' bg_on' : '')} name="employ_hope" value={varInputs?.employ_hope} onChange={onChangeHandler}>
						<option value="0">고용형태</option>
						{variableEmployType.map((el, i)=>(
							<option value={el.emt_idx} key={i}>{el.emt_name}</option>
						))}
					</select>
					<input 
						type="text" 
						name="income_hope" 
						className={varInputs?.income_hope ? ' bg_on' : ''} 
						id="income_hope" 
						placeholder="희망연봉"
						value={varInputs?.income_hope === 0 ? "" : addThousandPoint(varInputs?.income_hope)}
						// style={{ textAlign: "right" }} 
						onChange={onChangeHandler}
						readOnly={varInputs?.after_meet === 1 ? true : false}
					/>
					<label htmlFor="income_hope">원</label>
					<input type="checkbox" name="after_meet" id="after_meet" className="chk" onChange={onChangeHandler} checked={varInputs?.after_meet === 1 ? true : false} />
					<label htmlFor="after_meet">면접 후 결정</label>
				</div>
				<article className="select_area">
					<button className="btn" onClick={(e) => onSetPopTown(e, 'popup active')}>희망근무지 추가</button>
					<p className="tip_field">최대 3개의 근무지를 <br className="view_m" />추가할 수 있습니다.</p>
					<ul className="selected">
						{varHopePlace.map((place, index) => (
							<li key={'pl_' + index}>{place}
								<button className="btn_del" onClick={(e) => onDeleteHopePlaceHandler(e, place)}><img src={deleteImage01} alt="" style={{cursor:"pointer"}}/></button>
							</li>
						))}
					</ul>
				</article>
				<article className="select_area">
					<button className="btn" onClick={(e) => onSetPopJob(e, 'popup active')}>희망 직무 추가</button>
					<p className="tip_field">최대 3개의 직무를 <br className="view_m" />추가할 수 있습니다.</p>
					<ul className="selected">
						{varHopeTask.map((task, index) => (
							<li key={'tk_' + index}>{task}
								<button className="btn_del" onClick={(e) => onDeleteHopeTaskHandler(e, task)}><img src={deleteImage01} alt="" style={{cursor:"pointer"}}/></button>
							</li>
						))}
					</ul>
				</article>
				<article className="select_area">
					<button className="btn" onClick={(e) => onSetPopCompany(e, 'popup active')}>관심 기업 추가</button>
					<p className="tip_field">최대 3개의 관심기업을 <br className="view_m" />추가할 수 있습니다.</p>
					<ul className="selected">
						{varHopeCompany.map((company, index) => (
							<li key={'cm_' + index}>{company}
								<button className="btn_del" onClick={(e) => onDeleteHopeCompanyHandler(e, company)}><img src={deleteImage01} alt="" style={{cursor:"pointer"}}/></button>
							</li>
						))}
					</ul>
				</article>
			</section>

			{/* 2022-09-22 KHJ: 취업 우대사항 추가 */}
			<section className="section preference">
				<div className="title_area has_guide_secret">
          <h3 className="title">취업 우대사항</h3>
          <button className="btn txt"  onClick={() => setEmploymentPreferenceList()}>
						<img src={addLineBtnImage} alt="" />
						추가
					</button>
          <div className="guide">
          <p className="guide_btn"><strong className="tit">작성 TIP</strong><span className="icon" ></span></p>
          <div className="guide_secret">
            <p className="guide_secret_tit">{tipData[0]?.career_tip_title}</p>
            <div className="guide_secret_txt text-lines">{tipData[0]?.career_tip_content}</div>
          </div>
        </div>
        </div>
				{varEmploymentPreference.length ? varEmploymentPreference.map((data, i) => (
					<EmploymentPreference
					idx={i}
					data={data}
					varInputs={varInputs} 
					setInputs={setInputs} 
					varEmploymentPreference={varEmploymentPreference}
					setEmploymentPreference={setEmploymentPreference}
					varCompEmploymentPreference={varCompEmploymentPreference} 
					/>
				)) : null}
			</section>

			{/* 2022-09-22 KHJ: 이직 희망 여부 삭제 요청으로 주석 처리 */}
			{/* <section className="section change_jobs">
				<p className="title_area">이직 희망 여부</p>
				<article className="input_area">
						<input type="radio" className="chk square" name="move_hope" id="move_hope1" value={1}
										checked={varInputs?.move_hope===1 ? true: false} onChange={onChangeHandler}/>
						<label htmlFor="move_hope1">의향 있음</label> 
						<input type="radio" className="chk square" name="move_hope" id="move_hope0" value={0}
										checked={varInputs?.move_hope===0 ? true: false} onChange={onChangeHandler}/>
						<label htmlFor="move_hope0">의향 없음</label> 
				</article>
			</section> */}
		</>
	);
}

export default WorkingCondition;
