import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';
import CertificationCareerView from "../Resources/CertificationCareer";
import AddCareerView from "../Resources/AddCareer";

/*메인(기업)>새로등록한 인재*/
function NewResources() {
  const [varNewResumeList, setNewResumeList] = useState([]);
  //서버의 저장폴더
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;

  //새로운 지원자 
  const getNewResumeList = async () => {
    try {
      const mcm_idx = localStorage.getItem('mcm_idx');
      const sendData = {
        'mcm_idx': mcm_idx
      };
      await axios.post(`${CONFIG.SERVER_HOST}/_get_new_resume`, sendData, CONFIG.header).then((res) => {
        if (res.data.retvalue === 1) {
          const datas = res.data.results;
          console.log(datas);
          setNewResumeList(datas);
        } else {
          setNewResumeList([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getNewResumeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article>
      <hgroup className="sub_content_title_wrap">
        <h1 className="sub_content_title indent">새로 등록된 인재를 확인해보세요</h1>
      </hgroup>
      <div className="list_box">
        <ul className="talent_list wide">
          {varNewResumeList.map((newResume, index) => (
            <li key={"new_resume_" + index} className="talent_card">
              <Link to={`/Company/ComResource/SearchView/${newResume.re_idx}`} state={{ re_idx: newResume.re_idx }} >
                <hgroup>
                  <div className="pic_name_wrap">
                    {newResume.mem_photo === 0 ? <figure></figure>
                      : <figure style={{ backgroundImage: `url(${imageFoler + newResume.mem_ufile})` }}></figure>
                    }
                    <b>{newResume.mem_name}</b>
                  </div>
                  <div>
                    <h1 className="txt_blue ellipsis">
                      {/* {resetCareerForSearching(newResume.re_career_year)} */}
                      {
                        !newResume?.certification_experience_year && !newResume?.certification_experience_year ?
                          <AddCareerView highLookup={newResume} />
                          :
                          <CertificationCareerView highLookup={newResume} />
                      }

                    </h1>
                    {newResume.rec_company ?
                      <h2 className="txt_black ellipsis">{newResume.rec_company}</h2>
                      : null
                    }
                    {newResume.rec_department ?
                      <h3 className="ellipsis">{newResume.rec_department}&nbsp;{newResume.vps_name}</h3>
                      : null
                    }
                    {newResume.reh_name ?
                      <h2 className="ellipsis">{newResume.reh_name}&nbsp;{newResume.reh_major}</h2>
                      : null
                    }
                  </div>
                </hgroup>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </article>
  )
}
export default NewResources;
