/* eslint-disable jsx-a11y/anchor-is-valid */
//css
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CONFIG from "configs/configs";
import $ from "jquery";
import "css/Head.css";

import NavMenu from './NavMenu';

import btn_home_m from "img/common/btn_home.png";
import btn_close from "img/common/btn_close.png";

function Nav({ varIsLogin, varPersonal, varCompanyMode, LogoutHandler }) {
  const headObject = document.getElementById("head");
  const depth1Boxes = document.querySelectorAll(".depth1");
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  //console.log(depth1Boxes)
  // const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;

  const [varNickname, setNickname] = useState(
    localStorage.getItem("mem_nickname")
  );
  useEffect(() => {
    if (varIsLogin) {
      const mem_nickname = localStorage.getItem("mem_nickname");
      setNickname(mem_nickname);
    } else {
      setNickname("Nobody");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varIsLogin]);

  useEffect(() => {
    (() => {
      for (var i = 0; i < depth1Boxes.length; i++) {
        depth1Boxes[i].addEventListener("mouseenter", () => {
          if (!headObject.classList.contains("on"))
            headObject.classList.add("on");
        });
        depth1Boxes[i].addEventListener("mouseleave", () => {
          if (headObject.classList.contains("on"))
            headObject.classList.remove("on");
        });
      }
    })();
    return () => { };
  });

  //모바일 메뉴 닫기
  const onCloseMenuUser = () => {
    $("#head .nav_wrap").hide();
    console.log(1111);
  };
  // 모바일 체크
  let isMobile = /Mobi|iPhone|Android/i.test(navigator.userAgent.toLowerCase());

  return (
    <nav className="nav_wrap">
      {/* 모바일 */}
      <section className="mobile_content">
        <article className="top_area">
          <h2 className="title_field">전체메뉴</h2>
          <a href="/main" className="button">
            <img src={btn_home_m} alt="" />
          </a>
          <button
            className="BtnClose button"
            onClick={() => onCloseMenuUser()}
          >
            <img src={btn_close} alt="btn_close" />
          </button>
        </article>
        {varIsLogin ? (
          <article className="profile_area">
            {(mem_mode === 0 && varPersonal[0]?.mem_photo === 0) || (mem_mode > 0 && varPersonal[0]?.mcm_image === 0) || !varIsLogin ?
              <div
                className="profile_img"
              /> :
              mem_mode > 0 && varPersonal[0]?.mcm_image === 1 ?
                <div
                  className="profile_img"
                  style={{ backgroundImage: `url(${CONFIG.SERVER_HOST}/mcm_image/${varPersonal[0]?.mcm_ufile})` }}
                />
                :
                <div
                  className="profile_img"
                  style={{ backgroundImage: `url(${CONFIG.SERVER_HOST}/user_photo/${varPersonal[0]?.mem_ufile})` }}
                />
            }
            {!varIsLogin ? null : (
              <p className="id_field">{varNickname}님</p>
            )}
            {varIsLogin ? (
              <button className="button" onClick={() => LogoutHandler()}>
                로그아웃
              </button>
            ) : null}
          </article>
        ) : null}

        <article className="member_area">
          {varIsLogin ? null : (
            <p className="title_field">
              <span className="point">로그인</span> 후 나의 정보를 확인해보세요!
            </p>
          )}
          {/* {varCompanyMode ? (
            <Link to={"/Company"} className="button">
              헤딩 기업 서비스
            </Link>
          ) : null} */}
        </article>
        <article className="btn_area">
          {varIsLogin ? null : (
            <Link
              to={"/Login"}
              className="btn"
              onClick={() => onCloseMenuUser()}
            >
              로그인
            </Link>
          )}
          {varIsLogin ? null : (
            <Link
              to={"/JoinUs"}
              className="btn white"
              onClick={() => onCloseMenuUser()}
            >
              회원가입
            </Link>
          )}
        </article>
      </section>
      <NavMenu
        varIsLogin={varIsLogin}
        onCloseMenuUser={onCloseMenuUser}
        isMobile={isMobile}
      />
    </nav>
  );
}

export default Nav;
