/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

//컴포넌트
import CONFIG from 'configs/configs';
import { changeMobileNumber, changeMobileFormat, isPhone, numHyphen, numEnglish } from '../Common/Common';
import HRTop from 'components/Talk/HRTop';

function HRWrite() {
  const navigator = useNavigate();
  const [inputs, setInputs] = useState({
    companyName: '',
    name: '',
    mobile: '',
    email1: '',
    email2: ''
  })

  const onChangeHandler = e => {
    const { value, name } = e.currentTarget
    let valueString = value;
    if (name === "mobile") {
      if(!numHyphen(value)){
        alert('숫자만 입력해주세요.');
        setInputs({...inputs,[name]:''})
        return;
      }else if(value.length > 13){
        alert('핸드폰 번호 형식에 맞지 않습니다.');
        return;
      }
      valueString = changeMobileFormat(changeMobileNumber(value));
      setInputs({...inputs,[name]: valueString})
    }
    if (name === "email1" || name === "email2" ){
      if(numEnglish(value)){
        alert('영어와 숫자만 입력해주세요.');
        setInputs({...inputs,[name]:''})
        return;
      }
    }
    setInputs({
      ...inputs,
      [name]: valueString
    })
    // console.log(name, valueString)
  }

  //메일서버선택
  const onChangeMailServerHandler = (e) => {
    let eserver = e.currentTarget.value;
    let serverAddress = document.getElementById("email2");
    if (eserver === "") {
      serverAddress.value = "";
      serverAddress.focus();
    } else {
      setInputs({ ...inputs, email2: eserver });
    }
  };

  const onSubmitHandler = (e) => {
    if (!inputs.companyName) {
      alert('회사명을 입력해주세요.');
      return;
    }
    if (!inputs.name) {
      alert('담당자 이름을 입력해주세요.');
      return;
    }
    if (!inputs.mobile) {
      alert('연락처를 입력해주세요.');
      return;
    }
    if (!isPhone(inputs.mobile)) {
      alert('연락처 양식에 맞지않습니다.');
      return;
    }

    const param = new URLSearchParams()
    param.append('mem_idx', localStorage.getItem('mem_idx'));
    param.append('board_company', inputs.companyName);
    param.append('board_name', inputs.name);
    param.append('board_mobile', changeMobileNumber(inputs.mobile));
    param.append("board_email", `${inputs.email1}@${inputs.email2}`);
    param.append('mem_name', localStorage.getItem('mem_name'));
    param.append('url', `${CONFIG.URL}`);

    axios.post(`${CONFIG.SERVER_HOST}/hrInfo`, param).then((res) => {
      if (res.data.retvalue === 1) {
        alert(res.data.message);
        navigator("/main");
      }else{
        alert(res.data.message);
      }
    }).catch(e => {
      console.debug('Error  ', e);
      alert('제출에 실패하였습니다.');
    });
  }


  return (
    <>
      <section className="box box_content">
        <HRTop />
        <ul className="table_view02">
          <li className="full">
            <p className="title_field">회사명</p>
            <p className="info_field">
              <input type="text" placeholder="회사명을 입력해주세요." name="companyName" value={inputs?.companyName} onChange={(e) => onChangeHandler(e)} />
            </p>
          </li>
          <li className="full">
            <p className="title_field">담당자</p>
            <p className="info_field">
              <input type="text" placeholder="담당자 이름을 입력해주세요." name="name" value={inputs?.name} onChange={(e) => onChangeHandler(e)} />
            </p>
          </li>
          <li className="full">
            <p className="title_field">연락처</p>
            <p className="info_field">
              <input type="text" placeholder="-는 제외하고 입력해주세요." name="mobile" value={inputs?.mobile} onChange={(e) => onChangeHandler(e)} />
            </p>
          </li>
          <li className="full">
            <p className="title_field">이메일(선택)</p>
            <div className="info_field input_wrap email_field">
              <input type="text" name="email1" value={inputs?.email1} onChange={(e) => onChangeHandler(e)}/><span className="text">@</span><input type="text" name="email2" value={inputs?.email2} onChange={(e) => onChangeHandler(e)}/>
              <select name="select_email" onChange={(e) => onChangeMailServerHandler(e)}>
              <option value={""}>직접입력</option>
              <option value={"naver.com"}>naver.com</option>
              <option value={"gmail.com"}>gmail.com</option>
              <option value={"hanmail.net"}>hanmail.net</option>
              <option value={"hotmail.com"}>hotmail.com</option>
              <option value={"icloud.com"}>icloud.com</option>
              </select>
            </div>
          </li>
        </ul>
        <p className="tip_field">※ 담당자 정보는 영업이 진행 안될 시 즉시 삭제 처리합니다.</p>
      </section>
      <section className="box_out_button_area one">
        <button className="btn" onClick={(e) => onSubmitHandler(e)}>신청하기</button>
      </section>
    </>
  )
}

export default HRWrite
