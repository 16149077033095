/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
//css
import ProfileImg from 'img/sub/profile.jpg';
//components
import Paging from "../Layout/Paging";
//이미지
import logo from 'img/common/logo.png';

const TalkReplys = ({ varCurrentView, setCurrentView }) => {
  const user_idx = localStorage.getItem("mem_idx");
  const photoFolder = `${CONFIG.SERVER_HOST}/user_photo/`;

  //console.log(user_idx);
  const [varTalkReplyComment, setTalkReplyComment] = useState("");
  const [varTalkReplys, setTalkReplys] = useState([]);
  const [varPageList, setPageList] = useState([]);

  const [varModifyReply, setModifyReply] = useState("");
  const [varModifyReplyIDX, setModifyReplyIDX] = useState(0);
  const [varModifyReplyBool, setModifyReplyBool] = useState(false);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //댓글 목록 조회

  const getReplyList = async () => {
    try {
      const params = new URLSearchParams();
      params.append("mwt_idx", varCurrentView);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_talk_replys`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            const talkReplys = res.data.results;
            setTalkReplys(talkReplys);
            setTotalCount(talkReplys.length);
          }
        });

      // const params2 = new URLSearchParams();
      params.append("filename", "11111.jpeg");
      params.append("rcp_re_idx", 1);
      params.append("toWhere", 0);

      await axios
        .post(`${CONFIG.SERVER_HOST}/move_file_to`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            console.log(res);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(async () => {
    if (varCurrentView > 0) {
      getReplyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentView]);

  //페이지 설정
  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varTalkReplys.length === 0) {
      setPageList([]);
      return;
    }
    var copyData = [...varTalkReplys];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    setPageList(copyData);
  };
  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varTalkReplys]);

  //댓글
  const onAddCommentHandler = (e) => {
    setTalkReplyComment(e.target.value);
  };
  const onModifyCommentHandler = (e) => {
    const { value } = e.target;
    setModifyReply(value);

    //setTalkReplys(copyData);
  };
  //댓글 수정창 오픈
  const onChangeReplyCommentHandler = (e, mwtr_idx) => {
    e.preventDefault();
    /*     setTalkReplyIndex(mwtr_idx); */
    /*     document.getElementById('show_'+mwtr_idx).style.display = "none"; */
    if (varModifyReplyBool !== true) {
      let editTalkReply = document.getElementById("modify_" + mwtr_idx);
      if (editTalkReply.classList.contains("hide"))
        editTalkReply.classList.remove("hide");

      const finsIndex = varTalkReplys.filter(
        (element) => element.mwtr_idx === mwtr_idx
      );
      if (varModifyReply === "") {
        setModifyReplyBool(true);
        setModifyReplyIDX(mwtr_idx);
        setModifyReply(finsIndex[0].mwtr_comment);
      }
    } else {
      //alert("열려있는 수정창을 닫아주세요");
    }
  };
  // //댓글 수정창 취소
  // const onCancelReplyHandler = (e, mwtr_idx) => {
  //   e.preventDefault();
  //   /*     setTalkReplyIndex(mwtr_idx); */
  //   /*     document.getElementById('show_'+mwtr_idx).style.display = ""; */
  //   let editTalkReply = document.getElementById("modify_" + mwtr_idx);
  //   if (!editTalkReply.classList.contains("hide"))
  //     editTalkReply.classList.add("hide");
  //   setModifyReply("");
  //   setModifyReplyIDX(0);
  //   setModifyReplyBool(false);
  // };
  //댓글 저장
  const onUpdateReplyCommentHandler = (mwtr_idx, num) => {
    if((num === 0 && varTalkReplyComment === "") || (num === 1 && varModifyReply==="" )){
        alert("댓글을 입력해주세요.")
        return false;
    }
    let comments = "";
    if (mwtr_idx === 0) {
      comments = varTalkReplyComment;
    } else {
      const finsIndex = varTalkReplys.findIndex(
        (element) => element.mwtr_idx === mwtr_idx
      );
      comments = varTalkReplys[finsIndex].mwtr_comment;
    }
    //console.log(mwt_idx,mwtr_idx,comments,user_idx);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("mwt_idx", varCurrentView);
    params.append(
      "mwtr_idx",
      varModifyReplyIDX === 0 ? mwtr_idx : varModifyReplyIDX
    );
    params.append(
      "mwtr_comment",
      varModifyReply === "" ? comments : varModifyReply
    );

    axios
      .post(`${CONFIG.SERVER_HOST}/_update_talk_replys`, params)
      .then((res) => {
        if (res.data.retvalue === 1) {
          if (mwtr_idx === 0) {
            const talkReply = res.data.results[0];
            let copyData = varTalkReplys.concat(talkReply);
            copyData.sort(function (a, b) {
              return new Date(b.mwtr_rdate) - new Date(a.mwtr_rdate);
            });
            setTalkReplys(copyData);
            setTalkReplyComment("");
            setModifyReplyBool(false);
          } else {
            /*           document.getElementById('show_'+mwtr_idx).style.display = ""; */
            let editTalkReply = document.getElementById("modify_" + mwtr_idx);
            if (!editTalkReply.classList.contains("hide"))
              editTalkReply.classList.add("hide");
            //const finsIndex = varTalkReplys.findIndex(element=>element.mwtr_idx==mwtr_idx);
            let copyData = [...varTalkReplys];
            //copyData[finsIndex]={...copyData[finsIndex],mwtr_comment:varTalkReplyComment};
            copyData.sort(function (a, b) {
              return new Date(b.mwtr_rdate) - new Date(a.mwtr_rdate);
            });
            setTalkReplys(copyData);
            setTalkReplyComment("");

            setModifyReplyBool(false);
          }
          getReplyList();
        }
      });
  };

  //댓글삭제
  const onDeleteReplyHandler = (mwtr_idx) => {
    if (varModifyReplyBool === false) {
      const params = new URLSearchParams();
      params.append("mwtr_idx", mwtr_idx);
      axios
        .post(`${CONFIG.SERVER_HOST}/_delete_talk_replys`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            const copyData = varTalkReplys.filter(
              (element) => element.mwtr_idx !== mwtr_idx
            );
            setTalkReplys(copyData);
          }
        });
    } else {
      //alert("열려있는 수정창을 닫아주세요");
    }
  };

  return (
    <section className="comment">
      {user_idx !== null ? (
        <article className="write_area">
          <textarea
            placeholder="댓글을 남겨보세요.(최대 2000자까지 입력이 가능합니다.)"
            value={varTalkReplyComment}
            onChange={onAddCommentHandler}
          ></textarea>
          <button className="button" onClick={() => onUpdateReplyCommentHandler(0, 0)}>
            등록하기
          </button>
        </article>
      ) : null}

      <article className="comment_area">
        {varTalkReplys
          ? varPageList.map((reply, i) => (
              <div className="item" key={"reply_" + i}>
                <ul className="info_area">
                  <li className="field_profile">
                    <img
                      src={
                        reply.mem_photo === 1
                          ? `${photoFolder}${reply.mem_ufile}`
                          : reply.admin_name === null ? ProfileImg : logo
                      }
                      alt={reply.mem_ufile}
                    />
                  </li>
                  <li className="field_writer">{reply.admin_name === null ? (reply.mem_type === 0 ? reply.mem_id : reply.mem_nickname) : reply.admin_name}</li>
                  <li className="field_date">{reply.mwtr_rdate}</li>
                  {Number(user_idx) === reply.mwtr_mem_idx ? (
                    <li className="field_btn">
                      <button
                        className="btn"
                        onClick={(e) =>
                          onChangeReplyCommentHandler(e, reply.mwtr_idx)
                        }
                      >
                        수정
                      </button>
                      <button
                        className="btn"
                        onClick={() => onDeleteReplyHandler(reply.mwtr_idx)}
                      >
                        삭제
                      </button>
                      {/* &nbsp;&nbsp;|&nbsp;&nbsp;
                      <a
                        className="btn"
                        onClick={(e) => onCancelReplyHandler(e, reply.mwtr_idx)}
                      >
                        취소
                      </a> */}
                    </li>
                  ) : null}
                </ul>
                <div className="content" style={{ whiteSpace: "pre-line" }}>
                  {reply.mwtr_comment}
                </div>
                <div
                  className="edit_talk_reply hide"
                  id={"modify_" + reply.mwtr_idx}
                >
                  <textarea
                    placeholder="댓글을 남겨보세요.(최대 2000자까지 입력이 가능합니다.)"
                    value={varModifyReply}
                    onChange={onModifyCommentHandler}
                  ></textarea>
                  <button
                    className="button"
                    onClick={() => onUpdateReplyCommentHandler(reply.mwtr_idx, 1)}
                  >
                    변경등록하기
                  </button>
                </div>
              </div>
            ))
          : null}
      </article>
      <div className="paging">
        <Paging
          totalCount={varTotalCount}
          pageSize={varPageSize}
          curPage={varPageNum}
          setPage={setPageNum}
        />
      </div>
    </section>
  );
};

export default TalkReplys;
